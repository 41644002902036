import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 删除角色
 * @param roleId
 * @returns
 */
export const fetchDelRole = async (roleId: string | number): Promise<ApiResp> => {
  const { data } = await httpClient.delete<ApiResp>(`/bg/role/delete/${roleId}`)

  return serialize(data)
}
