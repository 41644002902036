import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {
  data: string
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 授权fb账号，返回OAuth登录所需url及参数
 * @returns
 */
export const fetchFacebookOauth = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/channel/facebook/oauth/url`)

  return serialize(data)
}
