import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * UserSearchDTO
 */
export interface IUserPageDTO {
  /**
   * 姓名或账号
   */
  keyWord?: string
  pageNum?: number
  pageSize?: number
  /**
   * 团队编号
   */
  teamId?: number
}

/**
 * PageUserVO
 */
export interface IUserPageRes {
  countId?: string
  current?: number
  pages?: number
  records?: IUserPageDetail[]
  size?: number
  total?: number
}

/**
 * UserVO
 */
export interface IUserPageDetail {
  id: string | number
  /**
   * 账号
   */
  account?: string
  /**
   * 手机号
   */
  phone?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 加入时间
   */
  createTime?: Date
  /**
   * 离开时间
   */
  departDate?: Date
  /**
   * 加入时间
   */
  hireDate?: Date
  /**
   * 用户姓名
   */
  nickname?: string
  /**
   * 会话接收人
   */
  transferUser?: string
  /**
   * 转交用户id
   */
  transferUserId?: string
  /**
   * 状态
   */
  inviteStatus?: number
  /**
   * 角色
   */
  roleName?: string
  /**
   * 小组
   */
  teamName?: string
  /**
   * 用户编号
   */
  userId?: number
  /**
   * 会话数
   */
  sessionCount?: number
}

interface ApiResp extends ClientResponse {
  data: IUserPageRes
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 话术启用状态配置
 * @param param
 * @returns
 */
export const fetchUserPage = async (param: IUserPageDTO): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/user/page`, param)

  return serialize(data)
}
