export const inputIconClassName = 'absolute left-2 top-1/2 -translate-y-1/2'

export const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/

export enum ConversitionFrom {
  WEBSITE = 'WEBSITE',
  FACEBOOK = 'FACEBOOK',
  TELEGRAM = 'TELEGRAM',
  WHATSAPP = 'WHATSAPP'
}

export const LANGUAGES = [
  { code: 'zh-CN', name: '简体中文', region: '中国' },
  { code: 'zh-TW', name: '繁體中文（台灣）', region: '台湾' },
  { code: 'en-US', name: '美国英语', region: '美国' },
  { code: 'ar-SA', name: 'العربية', region: '沙特阿拉伯' }

  // { code: 'zh-HK', name: '繁體中文（香港）', region: '香港' },
  // { code: 'zh-MO', name: '繁體中文（澳門）', region: '澳门' },
  // { code: 'ja-JP', name: '日本语', region: '日本' },
  // { code: 'ko-KR', name: '한국어', region: '韩国' },
  // { code: 'fr-FR', name: 'Français', region: '法国' },
  // { code: 'de-DE', name: 'Deutsch', region: '德国' },
  // { code: 'es-ES', name: 'Español', region: '西班牙' },
  // { code: 'pt-BR', name: 'Português', region: '巴西' },
  // { code: 'ru-RU', name: 'Русский', region: '俄罗斯' },
  // { code: 'it-IT', name: 'Italiano', region: '意大利' },
  // { code: 'nl-NL', name: 'Nederlands', region: '荷兰' },
  // { code: 'sv-SE', name: 'Svenska', region: '瑞典' },
  // { code: 'da-DK', name: 'Dansk', region: '丹麦' },
  // { code: 'fi-FI', name: 'Suomi', region: '芬兰' },
  // { code: 'no-NO', name: 'Norsk', region: '挪威' },
  // { code: 'pl-PL', name: 'Polski', region: '波兰' },
  // { code: 'ro-RO', name: 'Română', region: '罗马尼亚' },
  // { code: 'tr-TR', name: 'Türkçe', region: '土耳其' },
  // { code: 'el-GR', name: 'Ελληνικά', region: '希腊' },
  // { code: 'cs-CZ', name: 'Čeština', region: '捷克' },
  // { code: 'sk-SK', name: 'Slovenčina', region: '斯洛伐克' },
  // { code: 'hu-HU', name: 'Magyar', region: '匈牙利' },
  // { code: 'th-TH', name: 'ไทย', region: '泰国' },
  // { code: 'id-ID', name: 'Bahasa Indonesia', region: '印度尼西亚' },
  // { code: 'vi-VN', name: 'Tiếng Việt', region: '越南' },
  // { code: 'he-IL', name: 'עברית', region: '以色列' },
  // { code: 'hi-IN', name: 'हिन्दी', region: '印度' }
]

export const POSITION = [
  {
    value: 'BOTTOM-LEFT',
    name: '网站左下角'
  },
  {
    value: 'BOTTOM-RIGHT',
    name: '网站右下角'
  }
]

export interface IParamForPage {
  pageNum?: number
  pageSize?: number
}

/**
 * 渠道来源
 */
export const CHANNAL_SOURCE = [
  {
    label: 'webSite',
    value: 0
  },
  {
    label: 'Telegram',
    value: 1
  }
]

export const FORMAT_LANGUAGE = {
  'zh-CN': 'zh_CN',
  zh: 'zh_CN',
  'zh-TW': 'zh_TW',
  tc: 'zh_TW',
  'en-US': 'en_US',
  en: 'en_US',
  'ar-SA': 'ar_SA',
  ar: 'ar_SA'
}

// 文件类型检查
export const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
export const allowedFileTypes = [
  // 图片文件类型
  'image/jpeg', // .jpg, .jpeg
  'image/png', // .png
  'image/gif', // .gif
  'image/bmp', // .bmp
  'image/webp', // .webp

  // 文档文件类型
  'application/pdf', // .pdf
  'text/plain', // .txt
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx

  // Excel文件类型
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.ms-excel.sheet.macroEnabled.12', // .xlsm

  // PowerPoint文件类型
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12' // .pptm
]

// 检查文件大小
export const maxImageSize = 5 * 1024 * 1024 // 5MB
export const maxFileSize = 20 * 1024 * 1024 // 50MB

/** 最大输入字符数 */
export const MAX_INP_LENGTH = 500
