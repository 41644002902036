import { FC, memo, useEffect, useState } from 'react'
import { Assets } from '../../../helpers'
import { Controller, useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import captacha from '../../../assets/svg/captacha.svg'
import { inputIconClassName, passwordRules } from '@renderer/utils/consts'
import { NoFocusInput } from '@renderer/pages/sign/components/NoFocusInput'
import { Button, cn, PasswordInput } from '@brisk/ui'
import { useCaptchaCountdown } from '@renderer/hooks/useCaptchaCountdown'
import {
  fetchSendTempCaptcha,
  mutationAgreeInvite,
  mutationTempSignInByCaptcha
} from '@renderer/api'
import { useMutation } from '@tanstack/react-query'
import { AccountType, TerminalType } from '@renderer/api/model'
import { ReloadIcon } from '@radix-ui/react-icons'
import { IInviteInfo, RESPONSE_CODE } from '@brisk/api'
import { LockKeyhole } from 'lucide-react'
import { inconLeftClassName } from '@renderer/pages/sign/CompanyInfo'
import { object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import inviteSuccess from '../../../assets/svg/inviteSuccess.svg'
import inviteTips from '../../../assets/svg/inviteTips.svg'
import { useCurrentOrigin } from '@renderer/hooks/useCurrentOrigin'
import androidDowload from '@renderer/assets/imgs/androidDowload.png'
import iOSDownload from '@renderer/assets/imgs/iOSDownload.png'
import { APP_ROUTER_PATH } from '@renderer/router'

export interface IInvite {
  inviteInfo: IInviteInfo & { token: string }
}

export interface IFormData {
  captcha: string
  password: string
  confirmPassword: string
}

export const defaultFormValues = {
  captcha: '',
  password: '',
  confirmPassword: ''
}

export const inviteSchema = object({
  captcha: string().min(1, '请输入验证码'),
  password: string().min(8, '密码长度为8-16位').max(16, '密码长度为8-16位'),
  confirmPassword: string().min(8, '密码长度为8-16位').max(16, '密码长度为8-16位')
})

const InvitePc: FC<IInvite> = ({ inviteInfo }) => {
  const [step, setStep] = useState(0)
  const { isCounting, startCount, count, reset, isSentCaptcha } = useCaptchaCountdown()
  const currentOrigin = useCurrentOrigin()?.origin

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormData>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(inviteSchema),
    mode: 'onChange'
  })

  // 验证码临时登录
  const { mutateAsync: maSendTempCaptcha, isPending } = useMutation({
    mutationFn: fetchSendTempCaptcha
  })

  /** 临时tempToken */
  const { mutateAsync: maTempSignInByCaptcha } = useMutation({
    mutationFn: mutationTempSignInByCaptcha
  })

  /** 确认加入 */
  const { mutateAsync: agreeInvite } = useMutation({
    mutationFn: mutationAgreeInvite
  })

  const handleClickSendCaptcha = async (): Promise<void> => {
    if (isCounting) {
      return
    }
    const res = await maSendTempCaptcha({
      account: inviteInfo?.account,
      type: inviteInfo?.accountType as AccountType
    })
    if (res?.code !== RESPONSE_CODE.success) {
      reset()
      return
    }
    startCount()
  }

  const onSubmit = async (formData: IFormData): Promise<void> => {
    if (formData?.password !== formData?.confirmPassword) {
      control.setError('confirmPassword', { message: '两次输入的密码不一致' })
      return
    }

    try {
      const res = await maTempSignInByCaptcha({
        type: inviteInfo?.accountType as AccountType,
        account: inviteInfo?.account,
        captcha: formData.captcha,
        terminalType: TerminalType.BROWSER
      })

      if (!res.data.tempToken) {
        control.setError('captcha', { message: '验证码错误，请重新输入' })
        return
      }

      const agreeRes = await agreeInvite({
        tempToken: res?.data?.tempToken,
        token: inviteInfo?.token,
        password: formData?.confirmPassword
      })
      console.log('agreeRes', agreeRes)
      if (agreeRes?.code === RESPONSE_CODE.success) setStep(1)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (inviteInfo?.joined) setStep(2)
  }, [inviteInfo])

  return (
    <div className="w-full h-screen flex justify-around items-center lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="hidden lg:block">
        <div className="w-[640px] h-[860px] bg-muted">
          <img
            src={Assets.illustration_login}
            alt="Image"
            className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center w-[486px] min-w-[420px] text-sm p-16">
        <h1 className="w-full text-[32px] text-left font-semibold mb-12">欢迎使用 AIOChat</h1>

        {step === 0 && (
          <form className="w-full flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
            <p className="w-full text-lingheTextGray [&_span]:text-black mb-12">
              您的账号 <span>{inviteInfo?.account}</span>, 正在加入
              <span>{inviteInfo?.tenantName}</span>,请您完成以下信息
            </p>

            <div className="grid gap-2 mb-6">
              <Controller
                name="captcha"
                control={control}
                render={({ field }) => (
                  <div className="border w-full h-12 radios rounded-sm flex relative">
                    <ReactSVG src={captacha} className={inputIconClassName} />
                    <NoFocusInput
                      placeholder="请输入验证码"
                      className={cn(
                        'flex-1 h-full pl-9 text-sm border-none outline-none',
                        errors.captcha
                          ? 'focus-visible:ring-lingheError'
                          : 'focus-visible:ring-ring'
                      )}
                      required
                      {...field}
                    />
                    <Button
                      variant="link"
                      className="h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline"
                      onClick={(e) => {
                        e.preventDefault()
                        handleClickSendCaptcha()
                      }}
                      disabled={isPending}
                    >
                      {isPending && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
                      <span className="absolute left-0 text-border">|</span>
                      {isCounting ? `${count}s` : isSentCaptcha ? '重新发送' : '发送验证码'}
                    </Button>
                  </div>
                )}
              />
              {errors.captcha && (
                <p className="bottom-8 text-lingheError text-sm">{errors?.captcha?.message}</p>
              )}
              {isSentCaptcha && (
                <p className="text-sm text-lingheGray">
                  已向{inviteInfo?.account}发送验证码，有效期15分钟
                </p>
              )}
            </div>

            <p className="text-base">请设置企业密码</p>
            <p className="text-lingheTextGray mb-2">密码长度为8-16位</p>

            <div className="grid gap-2 mb-4">
              <div className="relative mb-2">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <PasswordInput
                      placeholder="请输入密码"
                      className="pl-10 pr-10 h-12"
                      required
                      {...field}
                    />
                  )}
                />
                <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
              </div>
              <div className="relative">
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <PasswordInput
                      placeholder="请确认密码"
                      className="pl-10 pr-10 h-12"
                      required
                      {...field}
                    />
                  )}
                />
                <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
              </div>
              {(errors.password || errors.confirmPassword) && (
                <p className="text-lingheError text-sm ">
                  {errors?.password?.message || errors?.confirmPassword?.message}
                </p>
              )}
            </div>

            <Button type="submit" className="h-[52px]">
              立即加入
            </Button>
          </form>
        )}

        {step === 1 && (
          <div className="w-full flex flex-col items-center gap-2 [&_button]:w-full [&_button]:h-[52px] [&_p]:text-lingheTextGray">
            <ReactSVG src={inviteSuccess} className="mb-2" />
            <p className="text-[20px] font-semibold text-center mb-2" style={{ color: 'black' }}>
              加入成功
            </p>
            <p className="mb-8">
              您已加入<span className="text-black">{inviteInfo?.tenantName}</span>,快进入 AIO
              Chat,跟小伙伴们打个招呼吧！
            </p>
            <Button
              className="mb-2"
              onClick={() => window.open(`https://admin.aiochat.im${APP_ROUTER_PATH.MANAGER_HOME}`)}
            >
              立即登录
            </Button>
            <Button variant="secondary" className="relative group">
              下载APP
              <div
                className="h-0 overflow-hidden group-hover:h-40 transition-all absolute w-[248px] rounded-md flex items-center justify-center gap-4 
              shadow left-1/2 top-10 bg-white -translate-x-1/2 [&_.QrBox]:flex [&_.QrBox]:flex-col [&_.QrBox]:items-center [&_.QrBox]:gap-3"
              >
                <div className="QrBox">
                  <div className="w-[100px] h-[100px] border rounded-md overflow-hidden">
                    <img src={androidDowload} alt="" className="w-full h-full rounded-md" />
                  </div>
                  <span>Android</span>
                </div>
                <div className="QrBox">
                  <div className="w-[100px] h-[100px] border rounded-md overflow-hidden">
                    <img src={iOSDownload} alt="" className="w-full h-full rounded-md" />
                  </div>
                  <span>iOS</span>
                </div>
              </div>
            </Button>
          </div>
        )}

        {step === 2 && (
          <div className="w-full flex flex-col items-center gap-2 [&_button]:w-full [&_button]:h-[52px] [&_p]:text-lingheTextGray">
            <ReactSVG src={inviteTips} className="mb-2" />
            <p className="text-[20px] font-semibold text-center mb-2" style={{ color: 'black' }}>
              温馨提示
            </p>
            <p className="mb-8">
              您已成功加入<span className="text-black">{inviteInfo?.tenantName}</span>
              无需重复操作。
            </p>
            <Button className="mb-2" onClick={() => window.open(currentOrigin)}>
              登录
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(InvitePc)
