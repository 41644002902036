import { useEffect } from "react"
import * as React from "react"

import { cn } from "../lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, any>(
  ({ maxLength = 100,className, type, handleOnPaste,...props }, ref) => {
    useEffect(() => {
      window.addEventListener('paste', handleOnPaste);
      return () => {
        window.removeEventListener('paste', handleOnPaste);
      };
    }, [])
    return (
      <input
        maxLength={maxLength}
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input px-3 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-[#A5A7B1] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:bg-[rgba(248,250,252,1)] disabled:cursor-not-allowed disabled:text-[rgba(165,167,177,1)] disabled:border-[#ECECEC] disabled:border",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
