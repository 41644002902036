import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * ChannelWebsiteSaveDTO
 */
export interface IAddOrEditScriptReq {
  /**
   * 渠道编号 为空时新增，不为空时修改
   */
  channelId?: number
  /**
   * 语言 zh-CN en-US
   */
  language: string
  /**
   * 渠道名称
   */
  name: string
  /**
   * 位置 BOTTOM-LEFT,BOTTOM-RIGHT
   */
  position: string
  /**
   * 备注
   */
  remark: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 聊天插件-保存渠道配置
 * @param param
 * @returns
 */
export const fetchAddOrEditScript = async (param: IAddOrEditScriptReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/website/save`, param)

  return serialize(data)
}
