import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * KeywordContent，快捷语内容
 */
export interface IKeywordContent {
  /**
   * 回复文本内容
   */
  content?: string;
  /**
   * 文件类型: word、excel、ppt、zip
   */
  fileType?: string;
  /**
   * 附件高度
   */
  height?: number;
  /**
   * 文件的概述，文字个数不超过 100 个，可用于 UI 展示文件简介
   */
  intro?: string;
  /**
   * 附件名
   */
  name?: string;
  /**
   * 附件大小
   */
  size?: number;
  /**
   * 附件(图片缩略图)url
   */
  thumbnailUrl?: string;
  /**
   * 附件类型：GENERIC_TEXT:文本、GENERIC_FILE：文件、GENERIC_IMAGE：图片、GENERIC_VOICE：音频、GENERIC_VIDEO：视频
   */
  type?: string;
  /**
   * 附件url
   */
  url?: string;
  /**
   * 附件宽度
   */
  width?: number;
  messageType?: string;
}

/**
 * KeywordSaveDTO
 */
export interface KeyWordsSaveRequest {
  /**
   * 应答图片
   */
  // imagesUrlList?: string[];
  contents?: IKeywordContent[];
  /**
   * 关键词ID 新增时不传
   */
  keywordId?: number;
  /**
   * 关键词
   */
  keywordList: string[];
  /**
   * 应答语
   */
  response: string;
}
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 保存关键词
 * @param param
 * @returns
 */
export const fetchKeyWordsSave = async (
  param: KeyWordsSaveRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/kb/keyword/save`, param);

  return serialize(data);
};
