import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface RequestBody {
  tempToken: string
  token: string
  password?: string
}

interface ApiResp extends ClientResponse {
  data: {}
}
// interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}
// 确认加入租户
export const mutationAgreeInvite = async (payload: RequestBody): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/user/invite/confirm`, {
    ...payload
  })

  return serialize(data)
}
