import React, { forwardRef, memo, useRef, useState } from 'react'
import { User } from 'lucide-react'
import showUserInfo from '../../../assets/svg/showUserInfo.svg'
import { ReactSVG } from 'react-svg'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@brisk/ui'
import manageAccount from '../../../assets/svg/manageAccount.svg'
import manageEarth from '../../../assets/svg/manageEarth.svg'
import manageSingOut from '../../../assets/svg/manageSingOut.svg'
import manageAccount2 from '../../../assets/svg/manageAccount2.svg'
import manageEarth2 from '../../../assets/svg/manageEarth2.svg'
import manageSingOut2 from '../../../assets/svg/manageSingOut2.svg'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useNavigate } from 'react-router-dom'
import DelCustolerModal from '../Components/DelCustolerModal'
import { useSignStore } from '../../../store/useSignStore'
import { useTranslation } from 'react-i18next'
import chinese from '../../../assets/svg/chinese.svg'
import united_states from '../../../assets/svg/united-states.svg'
import { isElectron } from '../../../utils'

interface IManageHeaderProps {
  avatar: string
  name: string
  typeName?: string
  singOut?: () => void
}

const index = forwardRef(({ avatar, name, singOut }: IManageHeaderProps) => {
  const [show, setShow] = useState(false)
  const [mouse, setMouse] = useState(false)
  const { t, i18n } = useTranslation()
  const i18nextLng = localStorage.getItem('i18nextLng')
  const triggerRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)

  const { setRedirectUrl } = useSignStore()
  const navigate = useNavigate()
  function onclick(type: string) {
    switch (type) {
      case 'AccountInformation':
        navigate(APP_ROUTER_PATH.MANAGER_SETTINGS)
        break
    }
  }
  const onLanguageClick = (event: React.MouseEvent, language: string) => {
    event.preventDefault()
    i18n.changeLanguage(language)
    !isElectron && location.reload()
  }
  const onMouseEnter = () => setMouse(true)
  const onMouseLeave = () => setMouse(false)
  return (
    <Popover
      onOpenChange={(status) => {
        if (status) {
          iconRef.current?.classList.add('rotate-90')
        } else {
          iconRef.current?.classList.remove('rotate-90')
        }
      }}
    >
      <PopoverTrigger asChild>
        <div ref={triggerRef} className="max-w-40 h-9 flex items-center gap-2 mr-6">
          <Avatar className="h-8 w-8 sm:flex">
            <AvatarImage alt="Avatar" src={avatar} className="rounded-md" />
            <AvatarFallback>
              <User />
            </AvatarFallback>
          </Avatar>
          <div className="shrink-0 relative">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-28 cursor-pointer">
              {name}
            </div>

            {/* <span className="pl-1 pr-1 bg-lingheBtnHover text-primary inline-block text-[10px]">
          {typeName}
        </span> */}
            <div
              ref={iconRef}
              className="cursor-pointer absolute -right-4 top-[50%] -translate-y-1/2 transition-all"
            >
              <ReactSVG src={showUserInfo} />
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        sideOffset={10}
        className="p-2 rounded-lg relative flex flex-col gap-1 [&_.group]:flex [&_.group]:h-8 [&_.group]:rounded-md [&_.group_span:hover]:text-primary
       [&_.group:hover]:bg-lingheBtnHover [&_.group] [&_.group]:px-3 [&_.group]:items-center [&_.group]:cursor-pointer [&_.group]:gap-[6px]"
        style={{
          transform: 'translateX(-16px)',
          zIndex: 9999,
          width: 'fit-content'
        }}
      >
        <span className="group" onClick={() => onclick('AccountInformation')}>
          <ReactSVG src={manageAccount} className="block group-hover:hidden" />
          <ReactSVG src={manageAccount2} className="hidden group-hover:block" />
          <span>{t('common.accountinformation')}</span>
        </span>
        <div className="group" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <ReactSVG src={manageEarth} className="block group-hover:hidden" />
          <ReactSVG src={manageEarth2} className="hidden group-hover:block" />
          <span>{t('common.language')}</span>
          <section
            className={cn(
              'absolute section_language bg-[#FFFFFF] p-[0.5rem] rounded-lg border-[1px] gap-1 flex flex-col shadow-md w-fit z-[99999] translate-x-[-120px] translate-y-[32px] [&_span]:flex [&_span]:h-8 [&_span]:rounded-md [&_span:hover]:text-primary [&_span:hover]:bg-lingheBtnHover [&_span]:px-3 [&_span]:items-center [&_span]:cursor-pointer [&_span]:gap-[6px]',
              mouse ? 'block' : 'hidden'
            )}
          >
            <span onClick={(e) => onLanguageClick(e, 'en')} className={cn('cursor-pointer', i18nextLng === 'en' && 'bg-lingheBtnHover')}>
              <ReactSVG src={united_states} />
              English
            </span>
            <span onClick={(e) => onLanguageClick(e, 'zh')} className={cn('cursor-pointer', i18nextLng === 'zh' || i18nextLng === 'zh-CN' && 'bg-lingheBtnHover')}>
              <ReactSVG src={chinese} />
              中文
            </span>
          </section>
        </div>
        <span className="group" onClick={() => setShow(true)}>
          <ReactSVG src={manageSingOut} className="block group-hover:hidden" />
          <ReactSVG src={manageSingOut2} className="hidden group-hover:block" />
          <span>{t('common.quit')}</span>
        </span>
        <DelCustolerModal
          title={t('common.WarmTips')}
          open={show}
          onClose={() => setShow(false)}
          onConfirm={() => {
            singOut()
            // todo set redirectUrl
            setRedirectUrl(APP_ROUTER_PATH.MANAGER)
            navigate(APP_ROUTER_PATH.INPUT_ACCOUNT, {
              replace: true
            })
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center px-4">
              {t('common.Areyousurewantlogoutyourcurrentaccount')}
            </div>
          }
        />
      </PopoverContent>
    </Popover>
  )
})

index.displayName = 'ManageHeader'

export default memo(index)
