import { filter, includes, map, uniq } from 'lodash'
import { FC, forwardRef, useImperativeHandle, useState } from 'react'
import open from '../assets/svg/open.svg'
import { ReactSVG } from 'react-svg'
import { Avatar, AvatarFallback, AvatarImage, cn } from '@brisk/ui'
import { User } from 'lucide-react'
import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'
import { EmployeeStatus } from '@renderer/api/model'
import { ITeamResponse, TeamUser } from '@renderer/api'
import { highlightText } from '@renderer/pages/home/MessageMenu/MessageItem'

interface ILevelSelectProps {
  data: ITeamResponse[]
  tag: number
  searchVal?: string
  handleSelect: (item: ITeamResponse) => void
  selected: TeamUser & ITeamResponse
}

interface ITeamUserListProps {
  data: TeamUser[]
  onSelect: (item: TeamUser & ITeamResponse) => void
  searchVal?: string
  selected: TeamUser
}

const TeamUserList: FC<ITeamUserListProps> = ({ data, onSelect, searchVal, selected }) => {
  return (
    <div className="w-full flex flex-col gap-1">
      {map(data, (item) => (
        <div
          key={'' + item?.userId}
          className={cn(
            'w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-9 rounded-md hover:bg-lingheBtnHover',
            selected?.userId === item?.userId && 'bg-lingheBtnHover'
          )}
          onClick={() => {
            onSelect && onSelect(item)
          }}
        >
          <ReactSVG src={open} className={'opacity-0'} />
          <div className="relative">
            <Avatar className="hidden h-6 w-6 sm:flex flex items-center">
              <AvatarImage alt="Avatar" src={item?.avatarUrl} className="rounded-full" />
              <AvatarFallback>
                <User className="" />
              </AvatarFallback>
            </Avatar>
            <div
              className={cn(
                'size-[6px] border-white border rounded-full absolute bottom-0 right-0',
                statusColor[EmployeeStatus[item?.status || '']] || ''
              )}
            />
          </div>
          <span>{highlightText(item?.nickname, searchVal)}</span>
        </div>
      ))}
    </div>
  )
}

const LevelSelect = forwardRef(
  ({ data = [], handleSelect, tag = 0, searchVal, selected }: ILevelSelectProps, ref) => {
    const [openedList, setOpenedList] = useState<(string | number)[]>([])

    const handleSetOpendList = (arr: (string | number)[]): void => {
      setOpenedList(uniq([...openedList, ...arr]))
    }

    useImperativeHandle(ref, () => ({
      handleSetOpendList
    }))

    return (
      <div className="text-sm max-h-[200px] w-[288px]">
        {map(data, (item) => {
          const children = item?.userList
          const teamId = '' + item?.teamId || ''
          const onlineCount =
            filter(children, (child) => child.status === EmployeeStatus.ONLINE).length || 0
          return (
            <div
              key={'' + teamId}
              className="w-full h-full flex flex-col gap-1 text-sm mb-2 pl-3 pr-3"
            >
              <div
                className={cn(
                  'w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-9 rounded-md hover:bg-lingheBtnHover',
                  tag !== 0 ? selected?.teamId === teamId && 'bg-lingheBtnHover' : ''
                )}
                onClick={() => {
                  if (tag !== 0) {
                    handleSelect && handleSelect(item)
                    return
                  }
                  if (!includes(openedList, teamId)) setOpenedList([...openedList, teamId])
                  else setOpenedList(filter(openedList, (it) => it !== teamId))
                }}
              >
                <ReactSVG
                  src={open}
                  className={cn('transition', (!children?.length || tag !== 0) && 'hidden')}
                  style={{
                    transform: `rotate( ${includes(openedList, teamId) ? '0deg' : '-90deg'})`
                  }}
                />
                {/* <div className="relative">
                  <Avatar className="hidden h-6 w-6 sm:flex flex items-center">
                    <AvatarImage alt="Avatar" src={item?.groupAvatar} className="rounded-full" />
                    <AvatarFallback>
                      <User className="" />
                    </AvatarFallback>
                  </Avatar>
                </div> */}
                <span>
                  {highlightText(item?.teamName, searchVal)}
                  {`(${onlineCount}/${children?.length || 0})`}
                </span>
              </div>
              {tag === 0 && children?.length ? (
                <div
                  className={cn(
                    'transition-all duration-300 ease-in-out mb-0',
                    includes(openedList, teamId) ? 'block' : 'hidden'
                  )}
                >
                  <TeamUserList
                    searchVal={searchVal}
                    data={children}
                    selected={selected}
                    onSelect={(item) => {
                      handleSelect && handleSelect(item)
                    }}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          )
        })}
      </div>
    )
  }
)

LevelSelect.displayName = 'LevelSelect'

export default LevelSelect
