import { toast } from "sonner";
import { X } from "lucide-react";
import { ReactNode } from "react";
import { v1 } from "uuid";

const closeAction = (): { action: ReactNode; id: string | number } => {
  const id = v1();

  return {
    id,
    action: (
      <button
        className="aio-absolute aio-top-0 aio-right-2 aio-h-[24px] aio-w-24[px] aio-flex aio-justify-center aio-items-center "
        onClick={() => toast.dismiss(id)}
      >
        <X width="14" height="14" />
      </button>
    ),
  };
};

type MessageType = "error" | "success" | "info";

export const message = (
  message: string,
  type: MessageType = "info",
): string | number | undefined => {
  if (!message) {
    return;
  }
  switch (type) {
    case "error":
      return toast.error(message, {
        duration: 1500,
        // ...closeAction()
      });
    case "success":
      return toast.success(message, {
        duration: 1500,
        // ...closeAction()
      });
    case "info":
      return toast.info(message, {
        duration: 1500,
        // ...closeAction()
      });
    default:
      return toast.info(message, {
        duration: 1500,
        // ...closeAction()
      });
  }
};
