import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface RequestBody {
  pageSize?: number
  pageNum?: number
  orgId?: number
  channelId?: number
  status: number
}

export interface IContent {
  conversationType: string // 会话类型
  conversationId: string // 会话 Id
  name: string // 消息名称
  content: any // 消息内容
  conversationExts: {
    channelIconUrl?: string
    channelName?: string
    channelType?: string
  }
  messageId: string // 服务端生成的消息 Id
  tid: string // 本地生成的消息临时 Id
  messageIndex: string // 会话内消息唯一索引
  isSender: boolean // 是否为发送方
  isUpdated: boolean // 消息是否被修改过
  isRead: boolean // 消息是否已读
  sentState: number // 消息的发送状态
  sentTime: number // 消息的发送时间戳（毫秒）
  senderId?: number | string
  sender: {
    id: string // 发送者 Id
    name: string // 发送者名称
    portrait: string // 发送者头像
    exts: any // 发送者扩展字段
  }
  undisturb?: number // 免打扰类型，可选
  attribute?: string // Electron 中生效的本地消息扩展属性，可选
  draft?: string // Electron 中生效的本地消息草稿属性，可选
  [key: string]: any
}

export interface IChatList extends IContent {
  conversationType: string //	会话类型	1.0.0
  conversationId: string //	会话 Id，会话类型是 PRIVATE 时，会话 Id 是接收方的 userId，会话类型是 GROUP 时是群组 Id	1.0.0
  conversationTitle: string //	会话名称，修改会话名称请直接修改用户或群组名称	1.0.0
  conversationPortrait: string //	会话头像，修改会话头像请直接修改用户或群组头像	1.0.0
  conversationExts: {
    channelIconUrl?: string
    channelName?: string
    channelType?: string
  } //	会话的扩展，修改会话会话扩展请直接修改用户或群组扩展	1.0.0
  latestMessage: IContent //	会话的最后一条消息，详细请查看 Message	1.0.0
  unreadCount: number //	当前会话的未读数	1.0.0
  latestReadTime: number //	最后一次消息阅读时间，精确到毫秒的时间戳	1.0.0
  draft: string //	会话草稿，存储在本地，不会多端同步	1.0.0
  undisturb: number //	免打扰类型	1.0.0
  sortTime: number //	会话排序时间
  syncTime: number
  startTime?: number
  sessionId?: string | number
  status: number
  userId: number
  orgId: number
  sessionType?: string
  [key: string]: any
}

/**
 * 接口返回的数据结构
 */
export interface IContentByApi {
  /**
   * 渠道的icon图片地址
   */
  channelIconUrl?: string
  /**
   * 渠道编号
   */
  channelId?: number
  /**
   * 渠道名称
   */
  channelName?: string
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string
  /**
   * 客户头像
   */
  customerAvatar?: string
  /**
   * 客户编号
   */
  customerId?: number
  /**
   * 客户名称
   */
  customerName?: string
  /**
   * 群编号
   */
  imGroupId?: number | string
  /**
   * 最后一次消息内容
   */
  lastTalkContent?: string
  /**
   * 最后一次消息时间
   */
  lastTalkTime?: Date
  /**
   * 分配给某个小组
   */
  orgId?: number
  /**
   * 组织架构名称
   */
  orgName?: string
  /**
   * 会话编号
   */
  sessionId?: number
  /**
   * 会话发起时间
   */
  startTime?: Date
  /**
   * 状态：0-待分配 1-已分配 10-完结
   */
  status?: number
  /**
   * 当前接待的客服编号
   */
  userId?: number
  [property: string]: any
}

export interface ResponseBody {
  records: IChatList[]
  total: number
  size: number
  current: number
  pages: number
}

interface ApiResp extends ClientResponse {
  data: ResponseBody
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

export const fetchChatList = async (payload: RequestBody): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/session/page`, {
    ...payload
  })

  return serialize(data)
}
