import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button
} from '@brisk/ui'
import { FC, memo, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { IScriptDetail } from '@brisk/api'
import { useTranslation } from 'react-i18next'

interface ICreateScriptToWebProps {
  data: IScriptDetail | null
  open: boolean
  onClose: () => void
  onConfirm: (script: string) => void
}
const CreateScriptToWeb: FC<ICreateScriptToWebProps> = ({ data, open, onClose, onConfirm }) => {
  const [script, setScript] = useState(``)
  const { t } = useTranslation()
  useEffect(() => {
    if (data) {
      const targetScript = `<script id="AIOChat-script" type="module" crossorigin
      src="${import.meta.env.VITE_PREVIEW_URL}assets/sdk.js?token=${data?.token}"></script>`
      setScript(targetScript)
    }
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />

          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {t('socialMediaAccount.Install')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <div className="flex flex-col gap-3 p-6">
          <div className="whitespace-pre-line">
            {t('socialMediaAccount.Pleasecontactyourteamsdevelopmentengineerperformfollowingsteps')}
          </div>
          <div className="bg-lingheTalkingBg pl-2 pr-2 pt-3 pb-3 rounded-md text-lingheTextGray">
            {script}
          </div>
        </div>
        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm(script)
            }}
          >
            {t('socialMediaAccount.Copy')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(CreateScriptToWeb)
