import { FC, memo, useEffect, useMemo } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import { ReactSVG } from 'react-svg'
import chatScript from '../../../assets/svg/chatScript.svg'
import telegramBot from '../../../assets/svg/telegramBot.svg'
import messenger from '../../../assets/svg/messenger.svg'
// import whatsAppManage from '../../../assets/svg/whatsAppManage.svg'
import { map } from 'lodash'
import { cn } from '@brisk/ui'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useTranslation } from 'react-i18next'

const index: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const channelList = useMemo(
    () => [
      {
        title: t('socialMediaAccount.Website'),
        icon: chatScript,
        key: 'chatScript',
        path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_CHAT
      },
      {
        title: 'Telegram Bot',
        icon: telegramBot,
        key: 'telegramBot',
        path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_TELEGRAM
      },
      {
        title: 'Messenger',
        icon: messenger,
        key: 'messenger',
        path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_MESSENGER
      }
      // {
      //   title: 'WhatsApp',
      //   icon: whatsAppManage,
      //   key: 'whatsAppManage',
      //   path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_WHATSAPP
      // }
    ],
    [APP_ROUTER_PATH]
  )

  useEffect(() => {
    if (pathname === APP_ROUTER_PATH.MANAGER_CHANNELMANAGE)
      navigate(APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_CHAT)
  }, [pathname])

  return (
    <NavBodyLayout title={[t('socialMediaAccount.SocialMediaAccountTitle')]}>
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center gap-3 shrink-0 p-3 h-[54px] border-b">
          <span className="text-base font-medium">{t('socialMediaAccount.MessageSource')}</span>
          <div className="flex h-full items-center gap-1.5">
            <ReactSVG src={wordsTips} />
            <span className="text-sm text-lingheTextTips">
              {t('socialMediaAccount.InAIOChatyoucanchatwithusersfrommultiplesocialmediaaccounts')}
            </span>
          </div>
        </div>
        <div className="flex-1 shrink-0 overflow-hidden flex">
          <div className="w-[252px] shrink-0 h-full overflow-hidden border-r flex flex-col">
            <div className="h-12 shrink-0 font-semibold p-3 mt-3">
              {t('socialMediaAccount.Supported')}
            </div>
            <div className="flex-1 w-full flex flex-col gap-0.5 shrink-0 overflow-y-auto pl-3 pr-3">
              {map(channelList, (item) => (
                <div
                  className={cn(
                    'w-full h-12 rounded-md cursor-pointer hover:bg-lingheBtnHover flex items-center gap-1.5 pl-3 pr-3',
                    pathname === item.path ? 'bg-lingheBtnHover' : ''
                  )}
                  onClick={() => navigate(item.path)}
                  key={item.key}
                >
                  <ReactSVG src={item.icon} />
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 h-full shrink-0 overflow-hidden">
            <Outlet />
          </div>
        </div>
      </div>
    </NavBodyLayout>
  )
}

export default memo(index)
