import en from '../en.json'
import zh from '../zh.json'
// import tc from '../tc.json'
// import ar from '../ar.json'

export const resources = {
  'zh-CN': {
    translation: zh
  },
  zh: {
    translation: zh
  },
  en: {
    translation: en
  }
  //   tc: {
  //     translation: tc
  //   },
  //   ar: {
  //     translation: ar
  //   }
}
