export enum MESSAGE_TYPE {
  TEXT = "jg:text",
  CSWELCOM = "cs:welcome", // 欢迎语
  CSNOTICE = "cs:notice", // 系统消息
  CSROBOT = "cs:robot", // 系统消息
  CSSESSION = "cs:sessionNotice", // 系统消息

  GENERIC_TEXT = "GENERIC_TEXT",
  GENERIC_IMAGE = "GENERIC_IMAGE",
  GENERIC_FILE = "GENERIC_FILE",
  GENERIC_VOICE = "GENERIC_VOICE",
  GENERIC_VIDEO = "GENERIC_VIDEO",

  TIM_TEXT = "TIMTextElem",
  TIM_IMAGE = "TIMImageElem",
  TIM_AUDIO = "TIMSoundElem",
  TIM_FILE = "TIMFileElem",
  TIM_VIDEO = "TIMFileElem",

  CUSTOMIZE_SESSION_NOTICE = "CUSTOMIZE_SESSION_NOTICE",
  QUEUING_PROMPT = "QUEUING_PROMPT",
  USER_ROLE_CHANGE = "USER_ROLE_CHANGE",
  USER_PROACTIVELY_RECEIVE = "USER_PROACTIVELY_RECEIVE",
  CUSTOMIZE_ROBOT_NOTICE = "CUSTOMIZE_ROBOT_NOTICE",

  SESSION_USER_RESTART = "SESSION_USER_RESTART",
  SESSION_USER_COMPLETE = "SESSION_USER_COMPLETE",
  SESSION_NOTICE_IN = "SESSION_NOTICE_IN",
  USER_ALLOCATION = "USER_ALLOCATION",

  SQUEEZE_OUT_OF_LINE = "SQUEEZE_OUT_OF_LINE",

  USER_STATUS_SWITCH = "USER_STATUS_SWITCH",
  CUSTOMIZE_SYSTEM_NOTICE = "CUSTOMIZE_SYSTEM_NOTICE",

  IMAGE = "jg:img",
  AUDIO = "jg:voice",
  VIDEO = "jg:video",
  FILE = "jg:file",
  MERGE = "jg:merge",
  RECALL = "jg:recall",
  RECALL_INFO = "jg:recallinfo",
  READ_MSG = "jg:readntf",
  READ_GROUP_MSG = "jg:grpreadntf",
  MODIFY = "jg:modify",
  CLEAR_MSG = "jg:cleanmsg",
  CLEAR_UNREAD = "jg:clearunread",
  COMMAND_DELETE_MSGS = "jg:delmsgs",
  COMMAND_UNDISTURB = "jg:undisturb",
  COMMAND_TOPCONVERS = "jg:topconvers",
  COMMAND_REMOVE_CONVERS = "jg:delconvers",
  // CLIENT_* 约定为客户端定义适用
  CLIENT_REMOVE_MSGS = "jgc:removemsgs",
  CLIENT_RECALL_MSG = "jgc:recallmsg",
}

export enum SEND_ROLE {
  USER = "USER", // 客服（包含管理员）
  VISITOR = "VISITOR", // 访客
  ROBOT = "ROBOT", // 机器人
  SYSTEM = "SYSTEM", // 系统消息
}

export enum TRANSLATE_ERROR {
  ERROR = "CODE:50001:translateError",
}

export enum SESSION_TYPE {
  TG_GROUP = "TG_GROUP", // 群
  TG_CHANNEL = "TG_CHANNEL", // 渠道
}
