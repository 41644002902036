import { Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import { FC } from 'react'
import emoji from '../../../assets/svg/emoji.svg'
import emojiHoverIcon from '../../../assets/svg/emojiHoverIcon.svg'
import { ReactSVG } from 'react-svg'
import { map } from 'lodash'
import { emojiToIcon, emojis } from '@renderer/utils/emojiData'

interface EmojiPopoverProps {
  onClick: (emoji: string) => void
}

const EmojiPopover: FC<EmojiPopoverProps> = ({ onClick }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <div className="flex w-8 h-8 items-center justify-center rounded-md hover:bg-lingheBtnHover group">
          <ReactSVG src={emoji} className="group-hover:hidden" />
          <ReactSVG src={emojiHoverIcon} className="hidden group-hover:block" />
        </div>
      </PopoverTrigger>
      <PopoverContent side="top">
        <div className="flex flex-wrap gap-2 [&>img]:cursor-pointer">
          {map(emojis, (emoji) => (
            <img
              className="w-6 h-6"
              src={emojiToIcon(emoji)}
              alt={emoji}
              key={emoji}
              onClick={() => onClick(emoji)}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default EmojiPopover
