import { IChatList } from '@brisk/api'
import { IContentByApi } from '@renderer/api/chatList'
import { map } from 'lodash'
export const handleFormatTalkListByApi = (list: IContentByApi[]): IChatList[] => {
  return map(list, (item) => {
    const {
      conversationType,
      imGroupId,
      customerName,
      conversationTitle,
      customerAvatar,
      conversationPortrait,
      conversationExts,
      unreadCount = 0,
      latestReadTime,
      lastTalkTime,
      draft = '',
      sortTime,
      startTime = 0,
      sessionId,
      latestMessage
    } = item

    return {
      ...item,
      conversationType,
      conversationId: imGroupId,
      conversationTitle: customerName || conversationTitle,
      conversationPortrait: customerAvatar || conversationPortrait,
      conversationExts: {
        ...conversationExts,
        channelIconUrl: item?.channelIconUrl,
        channelName: item?.channelName,
        channelType: item?.channelType
      },
      unreadCount,
      latestReadTime: latestReadTime || lastTalkTime,
      draft,
      undisturb: draft || -1,
      sortTime: sortTime || lastTalkTime,
      startTime,
      sessionId,
      latestMessage: {
        ...latestMessage,
        content: {
          ...latestMessage?.content,
          content: item?.lastTalkContent || latestMessage?.content?.content
        },
        // sentState: latestMessage?.sentState || 2,
        name: latestMessage?.name || 'text'
      }
    } as IChatList
  })
}
