import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * WelcomeConfigDTO
 */
export interface IChangeWelcomeConfigRequestBody {
  /**
   * 发送频次 单位：小时
   */
  firstIntervalTime?: number
  /**
   * 触发场景 欢迎语配置开关，1：开启状态，0：关闭状态
   */
  firstState?: number
  /**
   * 转接后发送发送欢迎语配置，1：开启状态，0：关闭状态
   */
  referralState?: number
  /**
   * 欢迎语配置开关，1：开启状态，0：关闭状态
   */
  welcomeState?: number
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 话术配置
 * @returns
 */
export const fetchChangeWelcomConfig = async (
  param: IChangeWelcomeConfigRequestBody
): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(`/bg/welcome/config`, param)

  return serialize(data)
}
