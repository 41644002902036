import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageGroupVO
 */
export interface PersonWordsGroupResponse {
  groupId?: number;
  groupName?: string;
}

interface ApiResp extends ClientResponse {
  data: PersonWordsGroupResponse[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 查询快捷短语分组
 * @returns
 */
export const fetchPersonWordsGroup = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/preset/message/group/list`);

  return serialize(data);
};
