import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { Button } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { ReactSVG } from 'react-svg'
import noCustomer from '../../../assets/svg/noCustomer.svg'
import CustomerTips from '../Components/CustomerTips'
import AddCustomerModal from '../Components/AddCustomerModal'
import DelCustolerModal from '../Components/DelCustolerModal'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IAddCustomerTableData, IInviteResponse, fetchInvitePage } from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import dayjs from 'dayjs'
import { formatDurationHDMin } from '@renderer/utils'
import { fetchDelInvite } from '@renderer/api/manage/fetchDelInvite'
import { IAddInviteRequestBody, fetchAddInvite } from '@renderer/api/manage/fetchAddInvite'
import { message } from '@renderer/components/message'
import { fetchTeamList } from '@renderer/api/team/fetchTeamList'
import { fetchRoleList } from '@renderer/api/manage/fetchRoleList'
import { fetchReInvite } from '@renderer/api/manage/fetchReInvite'
import { ColumnDef } from '@tanstack/react-table'
import { IParamForPage } from '@renderer/utils/consts'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'

export interface IPageationRef {
  currentPage: number
  itemsPerPage: number
}

const AddCustomer: FC = () => {
  const [listData, setListData] = useState<IInviteResponse>({})
  const [showCustomerTips, setShowCustomerTips] = useState(true)
  const [open, setOpen] = useState(false)
  const [openDel, setOpenDel] = useState(false)
  const [roleData, setRoleData] = useState<IAddCustomerTableData | null>(null)
  const { t } = useTranslation()

  /** 直接ref当参数传 */
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: fetchList, isPending } = useMutation({
    mutationFn: fetchInvitePage
  })

  const { mutateAsync: addInvite, isPending: addLoading } = useMutation({
    mutationFn: fetchAddInvite
  })

  const { mutateAsync: reInvite, isPending: reinviteLoading } = useMutation({
    mutationFn: fetchReInvite
  })

  const { mutateAsync: delInvite, isPending: delLoading } = useMutation({
    mutationFn: fetchDelInvite
  })

  /** 组 | 团队 */
  const { data: teamData } = useQuery({
    queryKey: [fetchTeamList.name],
    queryFn: fetchTeamList
  })

  /** 角色 */
  const { data: roleList } = useQuery({
    queryKey: [fetchRoleList.name],
    queryFn: fetchRoleList
  })

  const handleAddInvite = (param: IAddInviteRequestBody): void => {
    try {
      addInvite(param).then((res) => {
        if (res?.code === RESPONSE_CODE.success)
          message(t('businessManagement.Memberaddedsuccessfully'), 'success')
        setOpen(false)
        handleFetchList({
          pageNum: pageationRef?.current?.currentPage || 1,
          pageSize: pageationRef?.current?.itemsPerPage || 10
        })
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleReInvite = (params: IAddInviteRequestBody): void => {
    try {
      reInvite(params).then((res) => {
        if (res?.code === RESPONSE_CODE.success)
          message(t('businessManagement.ReInviteSuccessful'), 'success')
        setOpen(false)
        handleFetchList({
          pageNum: pageationRef?.current?.currentPage || 1,
          pageSize: pageationRef?.current?.itemsPerPage || 10
        })
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleFetchList = async (param: IParamForPage): Promise<void> => {
    const res = await fetchList(param)
    if (res?.code === RESPONSE_CODE.success) setListData(res?.data)
  }

  useEffect(() => {
    handleFetchList({
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10
    })
  }, [])

  const activationCodeColumns: ColumnDef<IAddCustomerTableData>[] = useMemo(
    () => [
      {
        id: 'userName',
        header: (): JSX.Element => <div>{t('businessManagement.Name')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.userName}</div>
        }
      },
      {
        id: 'userAccount',
        header: (): JSX.Element => <div>{t('businessManagement.AccountNumber')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.userAccount}</div>
        }
      },
      {
        id: 'preRoleName',
        header: (): JSX.Element => <div>{t('businessManagement.Role')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.preRoleName}</div>
        }
      },
      {
        id: 'preTeamName',
        header: (): JSX.Element => <div>{t('businessManagement.Group')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="flex items-center gap-1 h-full">{original?.preTeamName}</div>
        }
      },
      {
        id: 'inviteTime',
        header: (): JSX.Element => <div>{t('businessManagement.Invitationtime')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-1 h-full">
              {dayjs(original?.inviteTime).format('YYYY-MM-DD HH:MM')}
            </div>
          )
        }
      },
      {
        id: 'duration',
        header: (): JSX.Element => <div>{t('businessManagement.Invitedhours')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-1 h-full">
              <span
                className={original?.timeout && original.status === 0 ? 'text-lingheError' : ''}
              >
                {original?.status === 1
                  ? formatDurationHDMin((original?.confirmTime - original?.inviteTime) / 1000)
                  : formatDurationHDMin((original?.duration || 0) * 60)}
              </span>
            </div>
          )
        }
      },
      {
        id: 'edit',
        header: (): JSX.Element => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-2 [&_button]:p-0">
              <Button
                disabled={original?.status === 1}
                variant="link"
                className="hover:no-underline"
                onClick={() => {
                  setRoleData(original)
                  setOpen(true)
                }}
              >
                {original?.status === 1
                  ? t('businessManagement.Joined')
                  : t('businessManagement.Reinvite')}
              </Button>
              <span className="text-[#ECECEC]">|</span>
              <Button
                disabled={original?.status === 1}
                variant="link"
                className="hover:no-underline"
                onClick={() => {
                  setRoleData(original)
                  setOpenDel(true)
                }}
              >
                {t('businessManagement.removing')}
              </Button>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <NavBodyLayout
      title={[t('businessManagement.businessManagementTitle'), t('businessManagement.AddMember')]}
      loading={[isPending, addLoading, reinviteLoading, delLoading]}
    >
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center justify-between shrink-0 p-3 h-[54px] border-b">
          <span>{t('businessManagement.ListofInvitedMembers')}</span>
          <Button
            onClick={() => {
              setRoleData(null)
              setOpen(true)
            }}
          >
            <Plus strokeWidth={1.5} />
            {t('businessManagement.AddMember')}
          </Button>
        </div>
        <div
          className="flex-1 shrink-0 pt-4"
          style={{
            overflow: 'hidden'
          }}
        >
          <ManagerTable<IAddCustomerTableData>
            pageationRef={pageationRef}
            data={listData?.records || []}
            columns={activationCodeColumns}
            rowCount={listData?.total || 0}
            onPageChange={(pageNum, pageSize) => {
              handleFetchList({
                pageNum,
                pageSize
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2 pt-[72px]">
                  <ReactSVG src={noCustomer} />
                  <span className="mb-4 text-sm text-lingheTextGray">
                    {t('businessManagement.Therearecurrentlycustomers')}
                  </span>
                  <Button
                    onClick={() => {
                      setRoleData(null)
                      setOpen(true)
                    }}
                  >
                    <Plus strokeWidth={1.5} />
                    {t('businessManagement.AddMember')}
                  </Button>
                </div>
              </div>
            )}
          />
        </div>

        {showCustomerTips && (
          <div className="w-full absolute bottom-0 left-0 p-3 z-20">
            <CustomerTips
              onClose={() => setShowCustomerTips(false)}
              onAddCustomer={() => {
                setRoleData(null)
                setOpen(true)
              }}
            />
          </div>
        )}
      </div>

      <AddCustomerModal
        roleList={roleList?.data}
        teamData={teamData?.data}
        data={roleData}
        title={
          roleData
            ? t('businessManagement.accounttojoinyourorganization')
            : t('businessManagement.Addmemberstoyourbusiness')
        }
        open={open}
        onClose={() => {
          setRoleData(null)
          setOpen(false)
        }}
        onConfirm={(val, type) => {
          type === 'create' ? handleAddInvite(val) : handleReInvite(val)
        }}
      />

      <DelCustolerModal
        open={openDel}
        onClose={() => {
          setRoleData(null)
          setOpenDel(false)
        }}
        onConfirm={async () => {
          const res = await delInvite(roleData?.inviteId || '')
          if (res?.code === RESPONSE_CODE.success)
            message(t('businessManagement.Deletedsuccessfully'), 'success')
          handleFetchList({
            pageNum: pageationRef?.current?.currentPage || 1,
            pageSize: pageationRef?.current?.itemsPerPage || 10
          })
          setOpenDel(false)
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center">
            {t('businessManagement.Areyousureyouwantdeletethismember')}
            <br />
            {t('businessManagement.Deletioncannotbeundone')}
          </div>
        }
      />
    </NavBodyLayout>
  )
}

export default memo(AddCustomer)
