import { FC, useRef, useState } from 'react'
import SearchComp from '../SearchComp'
import {
  Button,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@brisk/ui'
import { Plus } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactSVG } from 'react-svg'
import resetSvg from '@renderer/assets/svg/reset.svg'

interface Props {
  handleInput?: (value: string) => void
  handleOpen?: (value: boolean) => void
  handleChange?: (value: number) => void
  handleReset?: () => void
}

const NavBarTools: FC<Props> = ({ handleInput, handleOpen, handleChange, handleReset }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('-1')
  const searchRef = useRef(null)
  const CHANNAL_SOURCE = [
    {
      label: t('chatFrame.FullStatus'),
      value: '-1'
    },
    {
      label: t('chatFrame.NotJoined'),
      value: '0'
    },
    {
      label: t('chatFrame.Joined'),
      value: '1'
    }
  ]

  const onReset = () => {
    setValue('-1')
    searchRef.current.onReset()
    handleReset()
  }
  const onChange = (value: string) => {
    setValue(value)
    handleChange(Number(value))
  }
  return (
    <section className="flex mb-4 flex-row justify-between px-4">
      <div className="flex">
        <SearchComp ref={searchRef} handleInput={handleInput} />
        <Select value={value} onValueChange={onChange}>
          <SelectTrigger className="w-[200px] h-8">
            <SelectValue defaultValue={-1} placeholder={t('chatFrame.FullStatus')} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {map(CHANNAL_SOURCE, (item) => (
                <SelectItem key={item.label + item?.value} value={String(item.value)}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center gap-2">
        <Button className="pl-7 pr-7 h-8" variant="outline" onClick={onReset}>
          <ReactSVG src={resetSvg} />
          <span className="pl-1 font-normal">{t('clientManagement.Reset')}</span>
        </Button>
        <Button
          className="pl-3 pr-3 h-8"
          onClick={() => {
            handleOpen(true)
          }}
        >
          <Plus strokeWidth={1.5} />
          {t('businessManagement.AddMember')}
        </Button>
      </div>
    </section>
  )
}

export default NavBarTools
