import { message } from '@renderer/components/message'
import { useUploadFileManageOptions } from '@renderer/hooks/manager/useUploadFileManageOptions'
import { filter, findIndex, map, some } from 'lodash'
import { Plus } from 'lucide-react'
import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { v4 } from 'uuid'
import uploadingFile from '@renderer/assets/svg/uploadingFile.svg'
import uploadError from '@renderer/assets/svg/uploadError.svg'
import uploadFileEdit from '@renderer/assets/svg/uploadFileEdit.svg'
import uploadFileIcon from '@renderer/assets/svg/uploadFileIcon.svg'
import uploadFileDelete from '@renderer/assets/imgs/uploadFileDelete.png'
import { ReactSVG } from 'react-svg'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@brisk/ui'
import { useTranslation } from 'react-i18next'
import {
  allowedFileTypes,
  allowedImageTypes,
  maxFileSize,
  maxImageSize
} from '@renderer/utils/consts'

export interface IFileValue {
  url: string
  width: number
  height: number
  type: string
  name: string
  id: string
  size: number
  state?: string
  localUrl?: string
  errFile?: File
}

interface IKeyWordsUploadFileProps {
  limit?: number
  fileValue: Array<IFileValue>
  onChange: (value: Array<IFileValue>) => void
}

/** 临时存储已上传过的文件 */
const uploadedFile = []

let current: string

const KeyWordsUploadFile = forwardRef(
  ({ limit = 3, fileValue, onChange }: IKeyWordsUploadFileProps, ref) => {
    const [value, setValue] = useState<Array<IFileValue>>(
      fileValue ? map(fileValue, (it) => ({ ...it, id: it?.id ? it?.id : v4() })) : []
    )
    const { uploadFileOption, isPending } = useUploadFileManageOptions()
    const imgInputUploadRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      isPending
    }))

    // const handleSetValue = (fileData) => {
    //   setValue((prev) => {
    //     const existingIndex = findIndex(prev, (item) => item.id === fileData.id)
    //     if (existingIndex !== -1) {
    //       // 如果存在，则替换
    //       const newValue = [...prev]
    //       newValue[existingIndex] = { ...fileData }
    //       return newValue
    //     } else {
    //       // 如果不存在，则添加
    //       return [...prev, fileData]
    //     }
    //   })
    // }

    const handleFileChange = async (file) => {
      if (!file) return
      imgInputUploadRef.current.value = ''

      const { name, size, type } = file
      const isAlreadyHas = some(value, (item) => item.name === name && item.type === type)
      const isImage = type.startsWith('image/')
      if (isAlreadyHas) {
        message('文件已存在', 'info')
        return
      }

      // 检查文件类型
      if (!allowedImageTypes.includes(type) && !allowedFileTypes.includes(type)) {
        message('不支持的文件类型！', 'info')
        return
      }

      if (isImage && size > maxImageSize) {
        message(t('knowledgeBase.uploadImgLimit'), 'info')
        return
      }

      if (!isImage && size > maxFileSize) {
        message(t('knowledgeBase.uploadFileLimit'), 'info')
        return
      }

      // const uploaded = find(uploadedFile, (item) => item.name === name && item.type === type)
      // if (uploaded) {
      //   handleSetValue(uploaded)
      //   return
      // }

      const id = current ? current : v4()

      let fileData
      if (isImage) {
        const localUrl = URL.createObjectURL(file as Blob | MediaSource)
        fileData = {
          localUrl,
          url: localUrl,
          type,
          name,
          id
        }
      } else fileData = { url: '', type, name, id }
      !current && setValue((pre) => [...pre, fileData])
      current = id
      const res = await uploadFileOption(file, 'tenant/')
      current = ''
      uploadedFile.push({ ...fileData, ...res })
      // handleSetValue(fileData, res, id)
      setValue((prev) => {
        const existingIndex = findIndex(prev, (item) => item.id === id)
        if (existingIndex !== -1) {
          // 如果存在，则替换
          const newValue = [...prev]
          newValue[existingIndex] = { ...newValue[existingIndex], ...res }
          return newValue
        } else {
          // 如果不存在，则添加
          return [...prev, { ...fileData, ...res }]
        }
      })
    }

    useEffect(() => {
      onChange(value)
    }, [value])

    return (
      <div
        className="w-full flex items-center gap-3 flex-wrap
    [&_.itemBox]:w-[120px] [&_.itemBox]:h-[120px] [&_.itemBox]:border [&_.itemBox]:rounded-md [&_.itemBox]:cursor-pointer [&_.itemBox]:flex
     [&_.itemBox]:items-center [&_.itemBox]:justify-center"
      >
        <style>{`
      @keyframes loadingAnimationUpdate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .loadingTalk {
        animation: loadingAnimationUpdate 1s linear infinite;
      }
    `}</style>
        <input
          type="file"
          ref={imgInputUploadRef}
          accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .doc, .docx, .xls, .xlsx, .xlsm, .ppt, .pptx, .pptm, .pdf, .txt"
          className="hidden"
          onChange={(e) => {
            const file = e?.target?.files[0]
            handleFileChange(file)
          }}
        />
        {value?.length
          ? map(value, (item) => {
              const isImage = item?.type?.startsWith('image/')
              return (
                <div
                  key={item.id}
                  className="flex flex-col items-center justify-center gap-2 w-[120px]"
                >
                  <div className="itemBox relative group">
                    {isImage ? (
                      current === item.id ? (
                        <div className="w-full h-full rounded-md object-cover bg-lingheBtnHover b-0" />
                      ) : (
                        <img
                          src={item.url}
                          className="w-full h-full rounded-md object-cover bg-lingheBtnHover b-0"
                          alt=""
                        />
                      )
                    ) : (
                      <div className="w-full h-full rounded-md bg-lingheBtnHover flex items-center justify-center">
                        {current !== item.id && <ReactSVG src={uploadFileIcon} />}
                      </div>
                    )}

                    {/* edit */}
                    {current !== item.id && (
                      <div
                        className="hidden opacity-0 group-hover:flex group-hover:opacity-100 z-[10] w-full h-full absolute left-0 top-0 bg-[rgba(0,0,0,0.4)]
                 rounded-md items-center justify-center"
                      >
                        <ReactSVG
                          src={uploadFileEdit}
                          onClick={() => {
                            if (isPending) {
                              message(t('knowledgeBase.uploaddingFile'), 'info')
                              return
                            }
                            current = item.id
                            imgInputUploadRef?.current?.click()
                          }}
                        />
                        <img
                          src={uploadFileDelete}
                          className="w-4 h-4 absolute -top-1 -right-1"
                          alt=""
                          onClick={() => {
                            if (isPending) {
                              message(t('knowledgeBase.uploaddingFile'), 'info')
                              return
                            }
                            setValue((pre) => filter(pre, (it) => it.id !== item.id))
                            current = ''
                          }}
                        />
                      </div>
                    )}

                    {current === item.id && isPending && (
                      <div className="flex flex-col gap-2 items-center justify-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[20]">
                        <ReactSVG src={uploadingFile} className="loadingTalk" />
                        <span className="text-primary">{t('knowledgeBase.uploaddingFile')}</span>
                      </div>
                    )}
                    {current === item.id && item.state === 'fail' && (
                      <div
                        onClick={() => {
                          setValue((pre) => filter(pre, (it) => it.id !== item.id))
                          current = ''
                          item?.errFile && handleFileChange(item.errFile)
                        }}
                        className="flex flex-col gap-2 items-center justify-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[20]"
                      >
                        <ReactSVG src={uploadError} />
                        <span className="text-lingheError">
                          {t('knowledgeBase.uploaddingFileError')}
                        </span>
                      </div>
                    )}
                  </div>

                  <TooltipProvider>
                    <Tooltip delayDuration={200}>
                      <TooltipTrigger className="w-[120px] truncate text-lingheGray">
                        {item.name}
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[150px] text-wrap bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
                        {item.name}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )
            })
          : false}
        {value?.length < limit && (
          <div className="flex flex-col gap-2 items-center justify-center w-[120px]">
            <div
              className="itemBox"
              onClick={() => {
                if (isPending) {
                  message(t('knowledgeBase.uploaddingFile'), 'info')
                  return
                }
                current = ''
                imgInputUploadRef?.current?.click()
              }}
            >
              <Plus size={24} className="text-lingheGray" />
            </div>
            <div className="h-[20px]" />
          </div>
        )}
      </div>
    )
  }
)
KeyWordsUploadFile.displayName = 'KeyWordsUploadFile'

export default memo(KeyWordsUploadFile)
