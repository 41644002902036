import { FC, useEffect, useState } from 'react'
import { RotateCw } from 'lucide-react'
import { TerminalType } from '../api/model'
import { useQuery } from '@tanstack/react-query'
import { fetchScanResult, fetchSignInQRCode } from '../api'
import { useUserStore } from '../store/useUserStore'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '../router'

const QRCodeWithRefresh: FC = () => {
  const [shouldShowRefresh, setShouldShowRefresh] = useState(false)
  const { setUser } = useUserStore()
  const navigate = useNavigate()
  const { data, refetch } = useQuery({
    queryKey: [fetchSignInQRCode.name],
    queryFn: () => fetchSignInQRCode({ terminalType: TerminalType.PC })
  })
  useQuery({
    queryKey: [fetchScanResult.name],
    queryFn: () =>
      fetchScanResult({ tempToken: data?.data.tempToken as string }).then((data) => {
        if (data.data) {
          setUser(data.data)
          navigate(APP_ROUTER_PATH.HOME_CHATTING)
        }
        return data
      }),
    enabled: !!data,
    refetchInterval: 2_000
  })

  useEffect(() => {
    if (!data) {
      return
    }
  }, [data])
  const handleRefresh = (): void => {
    refetch()
    setShouldShowRefresh(false)
  }

  useEffect(() => {
    // 以下代码模拟二维码过期检查
    const intervalometer = setInterval(() => {
      // 如果二维码未过期，更新二维码
      setShouldShowRefresh(true)
    }, 10_000)

    return () => {
      clearInterval(intervalometer)
    }
  }, [])

  return (
    <div className="relative w-[200px] h-[200px]">
      {/*<QRCodeCanvas value={data} size={200} />*/}
      <img alt="登录二维码" src={data?.data.base64} className="w-full h-full" />

      {shouldShowRefresh && (
        <div className="absolute top-0 left-0 bg-opacity-50 bg-black w-full h-full grid justify-center items-center">
          <div className=" text-white cursor-pointer" onClick={handleRefresh}>
            <RotateCw size={35} />
          </div>
        </div>
      )}
    </div>
  )
}

export default QRCodeWithRefresh
