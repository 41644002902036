import { ApiReqFn, httpClient, ApiRespSerializeFn, ClientResponse,} from '../../http-client/httpClient'

interface RequestBody {
    avatarUrl: string
    nickname: string
}

interface ApiResp extends ClientResponse {
    data: string
}
interface Data extends ClientResponse {
    data: string;
}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
    return {
        ...resp
    }
}

// 修改头像
export const fetchAvatar: ApiReqFn<RequestBody, Data> = async (payload) => {
    const { data } = await httpClient.put<ApiResp>('/bg/account/avatar', { ...payload })

    return serialize(data)
}
