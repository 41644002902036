import { cn, Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import {
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import messageMore from '@renderer/assets/svg/messageMore.svg'
import translating01 from '@renderer/assets/imgs/translating01.png'
import translating02 from '@renderer/assets/imgs/translating02.png'
import translateRetry01 from '@renderer/assets/svg/translateRetry01.svg'
import translateRetry02 from '@renderer/assets/svg/translateRetry02.svg'
import defauteUser from '@renderer/assets/svg/defauteUser.svg'
// import unRead from '@renderer/assets/svg/unRead.svg'
// import readed from '@renderer/assets/svg/readed.svg'
import talkingLoad from '@renderer/assets/svg/talkingLoad.svg'
// import ansowerMsg from '@renderer/assets/svg/ansowerMsg.svg'
// import copyMsg from '@renderer/assets/svg/copyMsg.svg'
import talkingWarning from '@renderer/assets/svg/talkingWarning.svg'
import { ReactSVG } from 'react-svg'
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import dayjs from 'dayjs'
import { replaceEmojiMark } from '@renderer/utils/emojiData'
// import { copyTextToClipboard } from '@renderer/utils'
// import { message } from '@renderer/components/message'
import { Undo2 } from 'lucide-react'
import { typeOfMessage } from './ChatTextAreal'
import { IChatList, IContent, ISessionTranslateConfig } from '@brisk/api'
import { MESSAGE_TYPE, SEND_ROLE } from '@brisk/utils'
import ChatListImage from './components/ChatListImage'
import ChatListFile from './components/ChatListFile'
import ChatListVideo from './components/ChatListVideo'
import ChatListAudio from './components/ChatListAudio'
// import { saveTalkingMsg, sendMessageThen } from '@renderer/utils/juggleHandles'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '../../../store/useUserStore'
import { FORMAT_LANGUAGE } from '@renderer/utils/consts'
import { msgRevoke } from '../../../utils/im-sdk/ipcTimSdk'
import { translateMessage } from '@renderer/utils/im-sdk/formateData'
import './style.css'
import { whiteSpaceStyle } from './ChattingWindow'
import { useSingleChatStore } from '@renderer/store/useSingleChatStore'

const AvatarCom: FC<{ url: string }> = ({ url }) => (
  <Avatar className="hidden h-8 w-8 sm:flex">
    <AvatarImage alt="Avatar" className="rounded-full w-full h-full" src={url} />
    <AvatarFallback>
      <ReactSVG src={defauteUser} />
    </AvatarFallback>
  </Avatar>
)

const TranslattingCom: FC<{
  fromSender: boolean
  content: string
}> = ({ fromSender, content }) => {
  return (
    <div className="w-full flex items-center">
      <div
        className={cn(
          'flex items-center gap-2',
          fromSender ? 'text-white opacity-60' : 'text-lingheGray'
        )}
      >
        <img
          className="w-[14px] h-[14px] translating"
          src={fromSender ? translating01 : translating02}
        />
        {content}
      </div>
    </div>
  )
}

const ReadedIconCom: FC<{
  sentState: number
  fromSender: boolean
  isRead: boolean
}> = ({
  sentState = 1,
  fromSender = true
  //  isRead = false
}) => {
  if (sentState === 1) {
    return (
      <>
        <style>{`
      @keyframes loadingAnimationTalk {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .loadingTalk {
        animation: loadingAnimationTalk 1s linear infinite;
      }
    `}</style>
        <ReactSVG src={talkingLoad} className="loadingTalk absolute bottom-0 -left-5" />
      </>
    )
  } else if (sentState === 3) {
    return <ReactSVG src={talkingWarning} className="absolute bottom-0 -left-5" />
  } else if (fromSender) {
    // return <ReactSVG src={!isRead ? unRead : readed} className="absolute bottom-0 -left-5" />
    return false
  } else {
    return null
  }
}

const ChatListItem = forwardRef(
  (
    props: {
      msgIndex: number
      onReCallMessage: (data) => void
      onReCallItemClick: (data) => void
      onReEditMessage: (str) => void
      shouldShowMoreIcon: boolean
      isCustemer: boolean
      sessionTranslateConfig: ISessionTranslateConfig
      isUpdate: boolean
      [key: string]: any
    },
    ref
  ) => {
    const {
      onReEditMessage,
      onReCallMessage,
      onReCallItemClick,
      msgIndex,
      shouldShowMoreIcon,
      sessionTranslateConfig,
      isUpdate,
      ...data
    } = props

    const { t } = useTranslation()
    const { user } = useUserStore()
    if (data?.name === MESSAGE_TYPE?.CSNOTICE) {
      return null
    }
    const itemRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => ({
      itemRef: itemRef.current,
      content: data?.content?.content
    }))

    const fromSender = useMemo(() => {
      const role = data?.content?.senderRole
      if ((role && role !== SEND_ROLE.VISITOR) || data?.senderId === '-2') return true

      return (
        data?.isSender ||
        [
          MESSAGE_TYPE.CSNOTICE,
          MESSAGE_TYPE.CSROBOT,
          MESSAGE_TYPE.CSSESSION,
          MESSAGE_TYPE.CSWELCOM
        ].includes(data?.name)
      )
    }, [data])

    const wrapStyle = cn(
      'flex text-base w-full p-3 pt-0 items-start gap-2',
      !fromSender ? 'justify-start' : 'justify-end text-white'
    )

    const imagSrc = useMemo(() => {
      return (
        (data?.content?.file ? URL.createObjectURL(data?.content?.file) : data?.content?.url) || ''
      )
    }, [data?.content?.file, data?.content?.url])

    const needTranslate = useMemo(() => {
      const { customerEnableStatus, userEnableStatus } = sessionTranslateConfig
      return fromSender
        ? Boolean(userEnableStatus) && data?.content?.translateRes // 右侧的不翻译 有就显示
        : Boolean(customerEnableStatus)
    }, [sessionTranslateConfig, fromSender])

    useEffect(() => {
      return () => {
        if (data?.content?.file) {
          URL.revokeObjectURL(imagSrc)
        }
      }
    }, [imagSrc, data?.content?.url])

    const MoreIconComp = useCallback(() => {
      const isCanRecall = useMemo(() => {
        const sentTime = dayjs(data?.sentTime)
        const oneHourAgo = dayjs(Date.now()).subtract(2, 'minute')
        return (
          data?.sender?.id === user?.userId && sentTime.isValid() && sentTime.isAfter(oneHourAgo)
        )
      }, [data, user])
      const onRecallMessage = (data): void => {
        const param = {
          ...data,
          name: MESSAGE_TYPE.RECALL_INFO,
          content: { exts: { history_message: data?.content?.content } }
        }
        msgRevoke(param)
      }

      return (
        <Popover>
          <PopoverTrigger>
            <ReactSVG src={messageMore} />
          </PopoverTrigger>
          <PopoverContent
            className="p-3 w-44 translate-y-[40%]"
            side={fromSender ? 'left' : 'right'}
            sideOffset={3}
          >
            <div className="text-sm flex flex-col [&>div]:p-2 hover:[&>div]:bg-[#EDF6FF] [&>div]:rounded-md [&>div]:cursor-pointer [&>div]:flex [&>div]:items-center [&>div]:gap-1">
              {/* <div onClick={() => onReCallMessage && onReCallMessage({ ...data, msgIndex })}>
                <ReactSVG src={ansowerMsg} />
                <span>{t('chatFrame.replythismessage')}</span>
              </div>
              <div
                onClick={async () => {
                  if (data?.name !== MESSAGE_TYPE.TEXT) {
                    message(t('chatFrame.copyingsuchmessagessupportedthistime'), 'info')
                    return
                  }
                  await copyTextToClipboard(data?.content?.content)
                }}
              >
                <ReactSVG src={copyMsg} />
                <span>{t('chatFrame.copy')}</span>
              </div> */}
              {/* <div>
                <ReactSVG src={translateMsg} />
                <span>{t('chatFrame.translators')}</span>
              </div> */}
              {fromSender && (
                <div
                  style={{
                    cursor: !isCanRecall ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() => {
                    if (!isCanRecall) return
                    onRecallMessage(data)
                  }}
                >
                  <Undo2 size={16} />
                  <span>{t('chatFrame.revocation')}</span>
                </div>
              )}
            </div>
          </PopoverContent>
        </Popover>
      )
    }, [data, fromSender, msgIndex, onReCallMessage])

    if (
      data?.content?.messageType === MESSAGE_TYPE.CUSTOMIZE_SESSION_NOTICE &&
      data?.content?.noticeType !== MESSAGE_TYPE.QUEUING_PROMPT
    ) {
      const i18nLanguage = localStorage.getItem('i18nextLng') || 'en-US'

      return (
        <div className="flex items-center justify-center text-xs text-lingheGray pb-3">
          {data?.content?.notice?.i18nContent?.[FORMAT_LANGUAGE[i18nLanguage]]}
        </div>
      )
    } else if (data?.name === MESSAGE_TYPE.RECALL_INFO) {
      const canNotEdit = data?.content?.url || !data?.content?.exts?.history_message
      return (
        <div className="text-center pb-3">
          <span className="text-gray-400 text-sm cursor-pointer">
            {t('chatFrame.youwithdrewmessage')}
          </span>
          {!canNotEdit && (
            <span
              className="text-blue-500 text-sm cursor-pointer"
              onClick={() => {
                onReEditMessage && onReEditMessage(data?.content?.exts?.history_message)
              }}
            >
              {t('chatFrame.reedit')}
            </span>
          )}
        </div>
      )
    }

    /** 展示文本消息 */
    const isTextMsg =
      data?.name === MESSAGE_TYPE.TEXT ||
      data?.name === MESSAGE_TYPE.GENERIC_TEXT ||
      data?.name === MESSAGE_TYPE.TIM_TEXT ||
      data?.name === MESSAGE_TYPE.TIM_IMAGE ||
      data?.name === MESSAGE_TYPE.CUSTOMIZE_SESSION_NOTICE ||
      data?.name === MESSAGE_TYPE.CUSTOMIZE_ROBOT_NOTICE ||
      data?.name === MESSAGE_TYPE.CSWELCOM ||
      data?.name === MESSAGE_TYPE.CSSESSION ||
      data?.name === MESSAGE_TYPE.CSROBOT

    const avatarUrl = data?.sender?.portrait

    return (
      <div ref={itemRef} className={wrapStyle} id={data?.tid}>
        <style>{`
        @keyframes loadingTranslate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-360deg);
          }
        }
        .translating {
          animation: loadingTranslate 1s linear infinite;
        }
      `}</style>
        {!fromSender && <AvatarCom url={avatarUrl} />}
        <div className="flex flex-col justify-end gap-[2px]">
          <span
            className={cn(
              'flex text-xs text-lingheGray items-center',
              fromSender ? 'justify-end' : ' justify-start'
            )}
          >
            {data?.sender?.name}
          </span>
          <div
            className={cn(
              'flex flex-col relative p-3 gap-1.5 max-w-[400px] lg:max-w-[550px] rounded-lg',
              fromSender ? 'bg-lingheChatItemBg' : 'bg-white'
            )}
          >
            {data?.referMsg?.content && (
              <div
                className="flex items-center gap-1 opacity-70 max-w-[100%] text-sm cursor-pointer"
                onClick={() => {
                  onReCallItemClick && onReCallItemClick(data?.referMsg)
                }}
              >
                <span className="shrink-0">|</span>
                <span className="shrink-0">回复</span>
                <span className="shrink-0">
                  {data?.referMsg?.sender?.name ||
                    data?.referMsg?.conversationTitle ||
                    data?.conversationTitle ||
                    '未知用户'}
                </span>
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  {typeOfMessage({
                    ...data,
                    name: data?.referMsg?.name || 'jg:text'
                  } as unknown as IChatList)}
                </span>
              </div>
            )}
            {isTextMsg && (
              <pre
                className="whitespace-pre-wrap min-h-[20px] max-w-[400px] lg:max-w-[550px] text-sm"
                style={whiteSpaceStyle}
                dangerouslySetInnerHTML={{
                  __html: replaceEmojiMark(
                    data?.content?.content ||
                      data?.content?.notice?.content ||
                      data?.content?.content?.content
                  )
                }}
              ></pre>
            )}
            {(data?.name === MESSAGE_TYPE.GENERIC_IMAGE || data?.name === MESSAGE_TYPE.IMAGE) && (
              <ChatListImage imagSrc={imagSrc} data={data.content} />
            )}
            {(data?.name === MESSAGE_TYPE.GENERIC_VOICE || data?.name === MESSAGE_TYPE.AUDIO) && (
              <ChatListAudio />
            )}
            {(data?.name === MESSAGE_TYPE.GENERIC_VIDEO || data?.name === MESSAGE_TYPE.VIDEO) && (
              <ChatListVideo data={data as unknown as IContent} />
            )}
            {(data?.name === MESSAGE_TYPE.GENERIC_FILE || data?.name === MESSAGE_TYPE.FILE) && (
              <ChatListFile
                data={data as unknown as IContent}
                sentTime={data?.sentTime}
                fromSender={fromSender}
              />
            )}
            {shouldShowMoreIcon && fromSender && (
              <div
                className={cn(
                  'absolute top-0 cursor-pointer',
                  !fromSender ? '-right-5' : '-left-5'
                )}
              >
                <MoreIconComp />
              </div>
            )}
            {/* 已读未读标识 */}
            <ReadedIconCom
              sentState={data?.sentState}
              fromSender={fromSender}
              isRead={data?.isRead}
            />
            {data?.name !== MESSAGE_TYPE.GENERIC_FILE && (
              <div className={cn('text-xs text-lingheGray', fromSender && 'text-white opacity-60')}>
                {dayjs(data?.sentTime).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            )}
            {isTextMsg &&
            needTranslate &&
            ((isUpdate && !fromSender) ||
              (data?.translateState === 1 && !data?.content?.translateRes)) ? (
              <div className="flex flex-col w-full text-xs">
                {/*  翻译中 */}
                <div
                  className={cn(
                    'w-full h-[5px] mb-1 relative',
                    fromSender ? 'custom-background01' : 'custom-background02'
                  )}
                />
                <TranslattingCom fromSender={fromSender} content={t('knowledgeBase.translating')} />
              </div>
            ) : (
              isTextMsg &&
              needTranslate && (
                <div className="flex flex-col w-full text-xs">
                  <div
                    className={cn(
                      'w-full h-[5px] mb-1 relative',
                      fromSender ? 'custom-background01' : 'custom-background02'
                    )}
                  />

                  {/* 翻译成功 */}
                  {data?.translateState === 2 && data?.content?.translateRes && (
                    <div
                      className={cn(
                        'w-full',
                        fromSender ? 'text-white opacity-60' : 'text-lingheGray'
                      )}
                      style={whiteSpaceStyle}
                    >
                      {data?.content?.translateRes}
                    </div>
                  )}

                  {/* 翻译失败 */}
                  {data?.translateState === 3 && (
                    <div
                      className="w-full flex items-center gap-2 cursor-pointer"
                      onClick={() => {
                        const reTranslateData = {
                          ...data,
                          translateState: 1,
                          content: { ...data?.content, translateRes: '' }
                        } as unknown as IContent
                        useSingleChatStore
                          .getState()
                          .setTransLateResMsgData([reTranslateData], data?.conversationId)
                        fromSender
                          ? translateMessage([], [data as unknown as IContent])
                          : translateMessage([data as unknown as IContent], [])
                      }}
                    >
                      <div className={cn(fromSender ? 'text-white opacity-60' : 'text-lingheGray')}>
                        {t('knowledgeBase.translateError')}
                      </div>
                      <div
                        className={cn(
                          'flex items-center gap-1',
                          fromSender ? 'text-white' : 'text-primary'
                        )}
                      >
                        {t('knowledgeBase.translateRetry')}
                        <ReactSVG src={fromSender ? translateRetry02 : translateRetry01} />
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
        {fromSender && <AvatarCom url={avatarUrl} />}
      </div>
    )
  }
)

ChatListItem.displayName = 'ChatListItem'
export default memo(ChatListItem)
