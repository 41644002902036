import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import navOpen from '../../../assets/svg/navOpen.svg'
import { debounce, find, map } from 'lodash'
import { cn } from '@brisk/ui'
import { NAVWIDTHSM } from '.'
import { useLocation } from 'react-router-dom'
import { IMenuTreeDTO } from '@renderer/api'
import { APP_ROUTER_PATH } from '@renderer/router'

const NavItemDom: FC<{
  data: IMenuTreeDTO
  handleClick: (el: IMenuTreeDTO) => void
  navWidth: string
  active: boolean
  pathname: string
  lowWidthShowChildrenId: string
  setLowWidthShowChildrenId: (id: string) => void
}> = ({
  data,
  handleClick,
  navWidth,
  active,
  pathname,
  lowWidthShowChildrenId,
  setLowWidthShowChildrenId
}) => {
  const [showChidlren, setShowChildren] = useState(false)
  const childrenRef = useRef<HTMLDivElement>(null)

  const hasChildren = data?.children?.length > 0

  const isActive =
    active ||
    (data?.path?.includes(APP_ROUTER_PATH.MANAGER_CHANNELMANAGE) &&
      pathname.includes(APP_ROUTER_PATH.MANAGER_CHANNELMANAGE))

  const childrenActive = useMemo(
    () => data?.children?.some((item) => item?.path === pathname) && navWidth === NAVWIDTHSM,
    [data, pathname, navWidth]
  )

  useEffect(() => {
    if (navWidth === NAVWIDTHSM) setShowChildren(false)
  }, [navWidth])

  useEffect(() => {
    if (find(data?.children, (item) => item?.path === pathname)) setShowChildren(true)
    else setShowChildren(false)
  }, [pathname])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (childrenRef.current && !childrenRef.current.contains(event.target as Node)) {
        setLowWidthShowChildrenId('')
      }
    }
    if (lowWidthShowChildrenId) document.addEventListener('mousedown', handleClickOutside)
    else document.removeEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [lowWidthShowChildrenId])

  return (
    <div className={'manangerItem-' + data.menuId}>
      <div
        className={cn(
          'pl-2 pr-2 cursor-pointer w-full h-12 hover:bg-lingheBtnHover [&:hover_.fatherMenu]:text-primary rounded-md  mb-1 relative',
          (isActive || childrenActive) && 'bg-lingheBtnHover [&_.menu]:text-primary'
        )}
        onMouseEnter={debounce(() => {
          setLowWidthShowChildrenId(String(data.menuId))
        }, 200)}
        onClick={() => {
          if (hasChildren) {
            navWidth !== NAVWIDTHSM
              ? setShowChildren(!showChidlren)
              : setLowWidthShowChildrenId(String(data.menuId))
            return
          }
          setLowWidthShowChildrenId('')
          handleClick && handleClick(data)
        }}
      >
        <div
          className={cn(
            'menu w-full h-full flex items-center gap-2 group ',
            navWidth === NAVWIDTHSM && 'justify-center'
          )}
        >
          <div className="h-full flex items-center justify-center">
            {isActive || childrenActive
              ? data?.icon &&
                data?.icon !== '#' && (
                  <img src={data?.icon?.replace('01.svg', '02.svg')} className="w-4 h-4" />
                )
              : data?.icon &&
                data?.icon !== '#' && (
                  <div className="h-full flex items-center justify-center">
                    <img src={data?.icon} className="w-4 h-4 group-hover:hidden" />
                    <img
                      src={data?.icon?.replace('01.svg', '02.svg')}
                      className="w-4 h-4 hidden group-hover:block"
                    />
                  </div>
                )}
          </div>
          <div
            className={cn(
              'flex flex-1 shrink-0 h-full items-center justify-between',
              navWidth === NAVWIDTHSM && 'hidden',
              !data?.icon || (data?.icon === '#' && 'ml-4')
            )}
          >
            <div className="fatherMenu">{data?.menuName}</div>
            {hasChildren ? (
              <ReactSVG
                className={cn('transition-all', showChidlren && 'rotate-90')}
                src={navOpen}
              />
            ) : (
              false
            )}
          </div>
        </div>
        {/* 收起 */}
        {hasChildren &&
          navWidth === NAVWIDTHSM &&
          String(data.menuId) === lowWidthShowChildrenId && (
            <div
              ref={childrenRef}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onMouseLeave={() => setLowWidthShowChildrenId('')}
              className={cn(
                `absolute top-0 left-[54px] flex flex-col gap-[1px] [&_.item]:h-8 [&_.item]:rounded-md [&_.item:hover]:bg-lingheBtnHover bg-white rounded-md shadow p-2
            [&_.item]:px-3 [&_.item]:flex [&_.item]:items-center [&_.item]:cursor-pointer [&_.item]:whitespace-nowrap`
              )}
              style={{
                zIndex: 99999
              }}
            >
              <div className="text-lingheTextGray h-8 px-3 flex items-center">{data?.menuName}</div>
              {map(data.children, (it, i) => {
                return (
                  <div
                    className={cn(
                      'item hover:text-primary',
                      pathname === it.path && 'bg-lingheBtnHover text-primary'
                    )}
                    onClick={() => {
                      handleClick && handleClick(it)
                    }}
                    key={it.menuId + i}
                    style={{
                      width: 'fit-content'
                    }}
                  >
                    {it?.menuName}
                  </div>
                )
              })}
            </div>
          )}
      </div>
      <div className={cn(showChidlren && navWidth !== NAVWIDTHSM ? 'block' : 'hidden')}>
        {hasChildren && (
          <NavItem navData={data?.children} navWidth={navWidth} handleClick={handleClick} />
        )}
      </div>
    </div>
  )
}

const NavItem: FC<{
  navData: IMenuTreeDTO[]
  handleClick: (el: IMenuTreeDTO) => void
  navWidth: string
}> = ({ navData, navWidth, handleClick }) => {
  const [lowWidthShowChildrenId, setLowWidthShowChildrenId] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    setLowWidthShowChildrenId('')
  }, [navWidth])

  return (
    <div className="">
      {map(navData, (item) => {
        const active = pathname === item.path
        return (
          <NavItemDom
            key={item?.menuId}
            data={item}
            navWidth={navWidth}
            active={active}
            pathname={pathname}
            lowWidthShowChildrenId={lowWidthShowChildrenId}
            setLowWidthShowChildrenId={(id: string) => setLowWidthShowChildrenId(id)}
            handleClick={(el) => {
              handleClick && handleClick(el)
            }}
          />
        )
      })}
    </div>
  )
}

export default memo(NavItem)
