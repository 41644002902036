import queryString from "query-string";
import heic2any from "heic2any";
export * from "./types";
export * from "./parseAsync";
export * from "./pSignal";

export const trimAll = (str: string): string => {
  return str.replace(/\s+/g, "");
};

export const generateUrlWithQueryString = (
  path: string,
  queryParams: Record<string, unknown>,
): string => {
  const query = queryString.stringify(queryParams);

  return `${path}?${query}`;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// 判断图片是否为heic类型
export const onJudgeImageIsHeic = (heicFile: File): boolean => {
  return (
    heicFile.type === "image/heic" ||
    heicFile.name.split(".").pop()?.toLocaleLowerCase() === "heic"
  );
};

// 将HEIC文件转换为PNG文件
export async function convertHEICtoPNG(heicFile: File) {
  // 判断文件是否为heic文件，否则直接返回
  if (!onJudgeImageIsHeic(heicFile)) return heicFile;

  try {
    const pngData = await heic2any({
      blob: new Blob([heicFile], { type: "image/heic" }),
      toType: "image/png",
    });
    const name =
      heicFile.name.split(".").shift() || new Date().getTime().toString();

    // 返回转换后的PNG文件
    return new File([pngData as BlobPart], name + ".png", {
      type: "image/png",
    });
  } catch (error) {
    console.error("转换失败:", error);

    return Promise.reject(error);
  }
}

/**
 * 复制文本
 * @param text
 */
export const copyTextToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error("复制到剪贴板失败", err);
    return Promise.reject(err);
  }
};

// 过滤字符串中特殊符号，防止渲染为 html 节点
export const filterHtmlEntities = (str: string): string => {
  return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
};

// 获取目标节点纯文本内容
export const getNodeTextContent = (ele: HTMLElement): string => {
  let res = "";
  let nodeArr = Array.from(ele.childNodes) as Node[];

  if (
    nodeArr[nodeArr.length - 1] &&
    nodeArr[nodeArr.length - 1]?.nodeName === "BR" &&
    nodeArr.length > 1
  ) {
    nodeArr = nodeArr.splice(0, nodeArr.length - 1);
  }

  nodeArr.forEach((child) => {
    if (child.nodeName === "#text") {
      res += child.nodeValue;
    } else if (child.nodeName === "BR") {
      res += "\n";
    } else if (child.nodeName === "P") {
      res += "\n" + getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "SPAN") {
      res += getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "BUTTON") {
      res += getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "IMG") {
      res += (child as HTMLImageElement).alt;
    } else if (child.nodeName === "DIV") {
      const childNodes = Array.from(child.childNodes);

      if (
        (childNodes.length === 1 && childNodes[0]?.nodeName === "BR") ||
        (child.previousSibling && child.previousSibling.nodeName === "BR")
      ) {
        // 处理复制粘贴内容多换行问题
        res += getNodeTextContent(child as HTMLElement);
      } else {
        res += "\n" + getNodeTextContent(child as HTMLElement);
      }
    }
  });
  return filterHtmlEntities(res);
};

/**
 * 过滤文本中的 html 标签
 * @param str
 * @returns
 */
export const replaceHtmlElement = (str: string): string => {
  if (!str) return "";
  const regex = /<[^>]+>/g;
  return str.replace(regex, "");
};

/**
 * 定义读取文件为Base64的箭头函数
 * @param file
 * @returns
 */
export const readFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getFileExtension = (fileName: string): string => {
  const parts = fileName.split(".");
  const fileEext = parts.pop();
  if (!fileEext) {
    return "";
  }
  return "." + fileEext;
};
export const formatDurationHDMin = (seconds: number): string => {
  if (seconds <= 0) return "0min";

  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  const result: string[] = [];
  if (days > 0) result.push(`${days}d`);
  if (hours > 0 || result.length > 0) result.push(`${hours}h`);
  if (minutes > 0 || result.length === 0) result.push(`${minutes}min`);

  return result.join("");
};

export const replaceImgWithAlt = (text: string): string => {
  // 正则表达式匹配<img>标签
  const imgTagPattern = /<img[^>]*alt\s*=\s*["']([^"']*)["'][^>]*>/g;

  // 使用正则表达式替换<img>标签为alt属性的值
  const result = text.replace(imgTagPattern, "$1");

  return result;
};

interface ImgOption {
  width: number; // 图片宽度
  height: number; // 图片高度
  type: string;
  name: string;
  size: number;
}

/**
 * 获取图片宽高
 * @param file - 要获取宽高的图片文件
 * @returns {Promise<ImgOption>} - 返回一个 Promise，解析为图片的宽高
 */
export const getImgOption = (file: File): Promise<ImgOption> => {
  const { type, name, size } = file;
  return new Promise((resolve, reject) => {
    // 图片宽高
    let imageWidth = 0;
    let imageHeight = 0;

    // 如果是图片文件，获取其宽高
    if (file.type.startsWith("image/")) {
      const img = new Image();
      const url = URL.createObjectURL(file); // 创建一个临时的 URL

      img.onload = () => {
        imageWidth = img.width;
        imageHeight = img.height;
        console.log("Image Width:", imageWidth, "Image Height:", imageHeight);
        URL.revokeObjectURL(url); // 释放临时 URL
        resolve({
          width: imageWidth,
          height: imageHeight,
          type,
          name,
          size,
        }); // 返回宽高
      };

      img.onerror = (error) => {
        URL.revokeObjectURL(url); // 释放临时 URL
        reject(new Error("Failed to load image")); // 处理加载错误
      };

      img.src = url; // 设置图片的 src
    } else {
      reject(new Error("Not an image file")); // 处理非图片文件
      resolve({ width: null, height: null, type, name, size });
    }
  });
};

/**
 * 获取地址栏参数
 * @param param
 * @returns
 */
export const getQueryParam = (param) => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  return params.get(param);
};

/**
 * 是否是json字符串
 * @param str
 * @returns
 */
export const isJsonString = (str) => {
  if (typeof str !== "string") return false;

  try {
    const parsed = JSON.parse(str);
    // 检查解析结果是否为对象或数组
    return typeof parsed === "object" && parsed !== null;
  } catch (e) {
    return false;
  }
};
