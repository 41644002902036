import React, { useEffect, useRef } from 'react'
import {
  SortingState,
  getCoreRowModel,
  useReactTable,
  RowSelectionState
} from '@tanstack/react-table'
import { CustomTableProps, DataWithId } from '@renderer/components/ManagerTable'

/**
 * 更新 ref 的值。
 *
 * @param ref - 要更新的 ref 对象。
 * @param value - 新的值或返回新值的函数。
 */
const updateRef = <T>(ref: React.MutableRefObject<T>, value: T | ((prev: T) => T)): void => {
  ref.current = typeof value === 'function' ? (value as (prev: T) => T)(ref.current) : value
}

/**
 * 检查数据 ID 是否发生变化的优化版本，使用Set来提高性能。
 *
 * @param newData - 新数据数组。
 * @param oldDataIds - 旧数据的 ID 集合。
 * @returns {boolean} 数据 ID 是否变化。
 */
const hasDataIdsChanged = <T extends DataWithId>(
  newData: T[],
  oldDataIds: Set<React.Key>
): boolean => {
  const newDataIds = new Set(newData.map((item) => item.id))
  if (newDataIds.size !== oldDataIds.size) return true
  newDataIds.forEach((id) => {
    if (!oldDataIds.has(id)) return true
  })
  return false
}

/**
 * 自定义表格 Hook，用于管理表格的排序和行选择状态。
 *
 * @template T - 表格数据的类型。
 *
 * @param {Object} params - 参数对象。
 * @param {T[]} params.data - 表格数据。
 * @param {ColumnDef<T>[]} params.columns - 列定义。
 * @param {number} params.rowCount - 行数。
 * @param {Function} params.onRowSelectionChange - 当行选择更改时的回调函数。
 *
 * @returns {Object} 表格实例对象。
 */
export const useCustomTable = <T extends DataWithId>({
  data,
  columns,
  rowCount,
  onRowSelectionChange
}: Pick<CustomTableProps<T>, 'data' | 'columns' | 'rowCount' | 'onRowSelectionChange'>) => {
  const sortingRef = useRef<SortingState>([])
  const rowSelectionRef = useRef<RowSelectionState>({})
  const prevDataIds = useRef<Set<React.Key>>(new Set(data.map((item) => item.id)))

  const table = useReactTable<T>({
    data,
    columns,
    onSortingChange: (newSorting) => updateRef(sortingRef, newSorting),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: (updaterOrValue) => {
      try {
        updateRef(rowSelectionRef, updaterOrValue)

        // 确保rowSelectionRef.current是对象类型
        const selectedRows = Object.keys(rowSelectionRef.current)
          .filter(
            (key) =>
              typeof rowSelectionRef.current[key] === 'boolean' && rowSelectionRef.current[key]
          )
          .map((key) => table.getRowModel().rowsById[key]?.original)

        onRowSelectionChange(selectedRows)
      } catch (error) {
        console.error('Error updating row selection:', error)
      }
    },
    state: {
      sorting: sortingRef.current,
      rowSelection: rowSelectionRef.current,
      columnPinning: {}
    },
    enableColumnPinning: true,
    manualPagination: true,
    enableRowSelection: true,
    rowCount
  })

  useEffect(() => {
    const newDataIds = new Set(data.map((item) => item.id))
    if (hasDataIdsChanged(data, prevDataIds.current)) {
      rowSelectionRef.current = {}
      table.setState((prevState) => ({
        ...prevState,
        rowSelection: {}
      }))
    }
    prevDataIds.current = newDataIds
  }, [data, hasDataIdsChanged, table])

  return table
}
