import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import customerTips from '../../../assets/svg/customerTips.svg'
import closeCustomerTips from '../../../assets/svg/closeCustomerTips.svg'
import { useTranslation } from 'react-i18next'

interface ICustomerTipsProps {
  onClose?: () => void
  onAddCustomer?: () => void
}

const CustomerTips: FC<ICustomerTipsProps> = ({ onClose, onAddCustomer }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full bg-[#F8F9FA] relative p-8 rounded-lg flex items-center gap-20">
      <div className="flex flex-col gap-2 [&_p]:text-sm [&_p]:text-lingheTextGray">
        <div className="mb-2 text-xl font-semibold">{t('common.WarmTips')}</div>
        <p>1、{t('businessManagement.Membersassociatedinquiries')}</p>
        <p>2、{t('businessManagement.Themembersmessages')}</p>
        <p>
          3、{t('businessManagement.Youcanaddmemberstoyourorganizationstart')}
          <span
            className="text-primary cursor-pointer"
            onClick={() => onAddCustomer && onAddCustomer()}
          >
            {t('businessManagement.AddMember')}
          </span>
          {t('businessManagement.Youcanaddmemberstoyourorganizationend')}
        </p>
      </div>
      <div className="flex-1 shrink-0 flex items-center justify-end pr-40">
        <ReactSVG src={customerTips} />
      </div>

      <ReactSVG
        src={closeCustomerTips}
        className="absolute cursor-pointer top-8 right-8"
        onClick={() => {
          onClose && onClose()
        }}
      />
    </div>
  )
}

export default memo(CustomerTips)
