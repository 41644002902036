/**
 * useDlUpdate
 *
 * @desc 描述：下载更新
 */

/* =================================================== */

import type { UpdateDownloadedEvent } from 'electron-updater'
import type { Options } from 'ahooks/lib/useRequest/src/types'
import { useMemoizedFn, useRequest } from 'ahooks'
import { useRef } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import type { DlProgressListen } from '../../../../preload/appUpdate/downloadUpdate'
import { transformInvoke } from '@renderer/utils/transformInvoke'
import { isAbortError } from '../../pages/home/Settings/components/withRegard/utils'
import { message } from '@renderer/components/message'

export interface IDlUpdateOp extends Options<UpdateDownloadedEvent, []> {
  // 是否显示提示信息，默认值：true
  isTip?: boolean
  // 监听下载进度回调函数
  dlProgressListen?: DlProgressListen
}

/**
 * 下载更新
 * @param op 基于 useRequest.options 扩展 isTip、dlProgressListen
 * @desc 返回元组类型，便于消费时取名别名
 */
export function useDlUpdate(op?: IDlUpdateOp) {
  const { t } = useTranslation()

  const { onSuccess, onError, isTip, dlProgressListen, ...other } = {
    isTip: true,
    ...op
  }

  // 装取消下载的容器
  const onCancelRef = useRef<() => void>()

  /**
   * 取消下载
   * @desc 不直接返回 onCancelRef，使用不符合直觉
   * @desc 同时保证函数稳定
   */
  const dlCancel = useMemoizedFn(() => {
    if (!onCancelRef.current) return

    onCancelRef.current()
    onCancelRef.current = undefined
  })

  // 加工 downloadUpdate，获取取消方法
  function dlUpdate() {
    const [_promise, onCancel] = window.appUpdate.downloadUpdate(dlProgressListen)
    onCancelRef.current = onCancel

    return transformInvoke(() => _promise)()
  }

  const resultReq = useRequest(dlUpdate, {
    onSuccess: (...args) => {
      onSuccess?.(...args)

      isTip && message(t('systemSetting.下载完成'), 'success')
    },
    onError: (...args) => {
      onError?.(...args)

      isTip && isAbortError(get(args, '[0]'))
        ? message(t('systemSetting.下载取消'), 'error')
        : message(t('systemSetting.下载失败'), 'error')
    },
    ...other
  })

  return [resultReq, dlCancel] as const
}
