import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { User } from '../../model/data/user'
import { TerminalType } from '../model'
import { trimAll } from '../../utils'

export interface RequestBodyByPassword {
  // tenantId: string
  account: string
  password: string
  terminalType: TerminalType
  tempToken?: string
  captchaCode?: string
}

interface ApiResp extends ClientResponse {
  data: User
}
// interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}
// 密码登录
export const mutationSignInByPassword = async (
  payload: RequestBodyByPassword
): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/login/login-password`, {
    ...payload,
    imageCaptcha: payload.captchaCode,
    account: trimAll(payload.account)
  })

  return serialize(data)
}
