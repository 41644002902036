import { ClientResponse, httpClient } from '../../http-client/httpClient'
/**
 * SessionReferralDTO
 */
export interface ITurnOutChat {
  /**
   * 备注
   */
  remark?: string
  /**
   * 当前会话编号
   */
  sessionId?: number
  /**
   * 转介给某个组
   */
  targetOrgId?: number
  /**
   * 转介给某个客服
   */
  targetUserId?: number
}

interface ApiResp extends ClientResponse {
  // TODO:
}
interface Serialize {
  (resp: ApiResp): ApiResp
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data
  return resp
}

// 会话转介
export const fetchTurnOutChat = async (param: ITurnOutChat): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/session/referral`, { ...param })

  return serialize(data)
}
