import { FC } from 'react'
import { NoFocusInput } from './NoFocusInput'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'
import { inputIconClassName } from '@renderer/utils/consts'
import { NoFocusPasswordInput } from './NoFocusPasswordInput'
import { FieldError } from 'react-hook-form'
import { Field } from './GetCaptchaInp'

interface IProps {
  type?: 'text' | 'password' | 'email' | 'number'
  field: Field
  errors: FieldError | undefined
  placeholder: string
  svg?: string
  className?: string
  errorMsg?: string
  [key: string]: boolean | string | number | undefined | null | object | []
}

const MyInputComps: FC<IProps> = ({
  type = 'text',
  field,
  errors,
  className,
  placeholder,
  svg = '',
  errorMsg = '',
  ...other
}) => {
  return (
    <>
      <div className="relative w-full h-12">
        {type !== 'password' && (
          <NoFocusInput
            placeholder={placeholder}
            required
            maxLength={50}
            className={cn(
              'flex-1 h-full pl-9 text-sm  outline-none',
              errors ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring',
              className
            )}
            {...field}
            {...other}
          />
        )}
        {type === 'password' && (
          <NoFocusPasswordInput
            maxLength={16}
            placeholder={placeholder}
            className={cn(
              'pl-10 pr-10 h-12',
              errors ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring',
              className
            )}
            {...field}
            {...other}
          />
        )}
        {svg && <ReactSVG src={svg} className={cn(inputIconClassName)} />}
      </div>
      {errorMsg && errors && <p className="mt-2 text-lingheError text-sm">{errorMsg}</p>}
    </>
  )
}

export default MyInputComps
