import React, { FC, useState } from 'react'
import { AlertDialog, AlertDialogPortal, AlertDialogContent, AlertDialogTitle, cn } from '@brisk/ui'
import { SettingDialogInterface } from './type'
import { ReactSVG } from 'react-svg'
import sClose from '../../assets/svg/s_close.svg'
import './style/style.css'
import { PhoneEdit } from './phoneEdit'
import { EmailEdit } from './emailEdit'
import { PasswordEdit } from './passwordEdit'
import { useTranslation } from 'react-i18next'

/**
 * allComponent 函数转换
 * @param Component
 */
const allComponent = (Component: React.FunctionComponent) => {
  return (props) => <Component {...props} />
}
//tags标签标题组件
export const TagsTitle: FC<{ title: string }> = ({ title }) => {
  return <span className="text-[#A5A7B1] font-bold[500] block pb-4 text-sm">{title}</span>
}
/**
 * footer 组件
 * @param len
 * @param onsubmit
 * @param onCancel
 * @constructor
 */
export const FooterButton: FC<{ len: number; onsubmit: (any) => void; onCancel: () => void }> = ({
  len,
  onsubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <footer className="w-full pt-3 inline-flex gap-2 footer-button">
      <button
        onClick={onCancel}
        className="text-sm rounded border-[#ECECE] cursor-pointer border ml-auto text-[#777A8A]"
      >
        {t('common.cancel')}
      </button>
      <button
        onClick={onsubmit}
        className={cn(
          'rounded text-[#FFFFFF] text-sm',
          len <= 0 ? 'pointer-events-none bg-[#A7BFFF]' : 'cursor-pointer bg-[#247CFF]'
        )}
      >
        {t('common.complete')}
      </button>
    </footer>
  )
}
// 昵称设置组件
const NicknameSettings: FC<{ value: string; onsubmit: (any) => void; onCancel: () => void }> = ({
  value,
  onsubmit,
  onCancel
}) => {
  const [values, setValue] = useState(value)
  const { t } = useTranslation()
  const onChange = (event) => {
    setValue(event.target.value)
  }
  function handleSubmit() {
    onsubmit({ type: 'nickname', value: values })
  }
  function handleCancel() {
    onCancel()
  }
  return (
    <section>
      <TagsTitle title={t('accountInformation.Pleaseenternewnickname')} />
      <input
        maxLength={20}
        onChange={onChange}
        type="text"
        value={values}
        className="Input"
        id="nickname"
        placeholder={t('accountInformation.pleaseenteranickname')}
      />
      <FooterButton onCancel={handleCancel} onsubmit={handleSubmit} len={values.length} />
    </section>
  )
}

const SettingDialog: FC<SettingDialogInterface> = ({
  type,
  value,
  open,
  onOpenChange,
  onsubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  const title = {
    nickname: t('accountInformation.pleaseenteranickname'),
    phone: t('accountInformation.Bindmobilephonenumber'),
    email: t('accountInformation.Bindmailbox'),
    password: t('accountInformation.ChangingLoginPassword')
  } // 定义标题

  // 分配不同的设置类型
  const objComponents = {
    nickname: NicknameSettings,
    phone: PhoneEdit,
    email: EmailEdit,
    password: PasswordEdit
  }

  const WithComponents = allComponent(objComponents[type])
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogPortal>
        <AlertDialogContent className="focus:outline-none">
          <AlertDialogTitle className="flex relative pb-3.5 border-b border-[#ECECEC] items-center justify-between">
            <span className="text-sm text-[#1D223C] font-normal">{title[type]}</span>
            <ReactSVG onClick={onCancel} src={sClose} className="cursor-pointer" />
          </AlertDialogTitle>
          <WithComponents value={value} onsubmit={onsubmit} onCancel={onCancel} />
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  )
}

export default SettingDialog
