import illustration_login from '@renderer/assets/imgs/login.png'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'
import i18nIsoCountries from 'i18n-iso-countries'
// import { CountryOption } from '../pages/sign/InputAccount'
import online from '../assets/svg/online.svg'
import busy from '../assets/svg/busy.svg'
import offline from '../assets/svg/offline.svg'

export const Assets = {
  // illustration_login: import('@renderer/assets/illustration_login.png')
  illustration_login,
  online,
  busy,
  offline
}

/**
 * Source: https://grafikart.fr/tutoriels/drapeau-emoji-fonction-2152
 * @param code fr, en, de...
 * @returns the emoji flag (🇫🇷, 🇬🇧, 🇩🇪)
 */
export function isoToEmoji(code: string) {
  return code
    .split('')
    .map((letter) => (letter.charCodeAt(0) % 32) + 0x1f1e5)
    .map((emojiCode) => String.fromCodePoint(emojiCode))
    .join('')
}

/**
 * Get all countries options
 * @returns array of countries options
 *
 * @example
 * getCountriesOptions() // [{value: "DE", label: "Germany", indicatif: "+49"}, ...]
 */
export function getCountriesOptions() {
  const countries = getCountries()

  // Type inference is not working here
  const options = countries
    .map((country) => ({
      value: country,
      label: i18nIsoCountries.getName(country.toUpperCase(), 'en', {
        select: 'official'
      }),
      indicatif: `+${getCountryCallingCode(country)}`
    }))
    .filter((option) => option.label)

  return options
}

/**
 *
 * @param phoneNumber international phone number
 * @returns phone number with digits replaced with zeros
 *
 * @example
 * replaceNumbersWithZeros("+1 123 456 7890") // +1 000 000 0000
 */
export function replaceNumbersWithZeros(phoneNumber: string): string {
  // Split the phone number into country code and the rest of the number
  const [countryCode, ...restOfNumber] = phoneNumber.split(/\s+/)

  // Replace digits in the rest of the number with zeros
  const replacedRestOfNumber = restOfNumber.map((num) => num.replace(/\d/g, '0')).join(' ')

  // Concatenate the country code and the replaced number
  const replacedPhoneNumber = (countryCode as string) + ' ' + replacedRestOfNumber

  return replacedPhoneNumber
}
