import { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { MessageInfoContext } from '../../Home'
import { debounce, filter, flatMap, map, uniqBy } from 'lodash'
import { PresetMessageItem } from '@brisk/api'
import { highlightText } from '../../MessageMenu/MessageItem'
import { ReactSVG } from 'react-svg'
import noSearchResult from '@renderer/assets/svg/noSearchResult.svg'
import { cn } from '@brisk/ui'
import { ChattingWindowContext, defaltFastModalData } from '../ChattingWindow'
import { useTranslation } from 'react-i18next'

// 获取最后一个斜杠后的字符
const getLastKeyword = (str) => {
  if (!str) return ''
  const lastSlashIndex = str.lastIndexOf('/')
  return lastSlashIndex !== -1 ? str.substring(lastSlashIndex + 1) : str
}

const FastWrodsModal: FC<{
  keyWords?: string
  onSelect: (item: PresetMessageItem) => void
  inpEnd: boolean
}> = ({ keyWords, onSelect, inpEnd }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [searchList, setSearchList] = useState<PresetMessageItem[]>([])
  const { t } = useTranslation()
  const wrapRef = useRef<HTMLDivElement>(null)

  const { wordsDataList, personWordsDataList } = useContext(MessageInfoContext)
  const { setFastWordsModalData } = useContext(ChattingWindowContext)
  const searchKeyWords = useMemo(() => getLastKeyword(keyWords), [keyWords])

  const list = useMemo(() => {
    const personWords = flatMap(personWordsDataList, (item) => item.itemList)

    const words = flatMap(wordsDataList, (item) => item.itemList)

    const combinedList = [...personWords, ...words]
    return uniqBy(combinedList, 'preMessageId')
  }, [wordsDataList, personWordsDataList])

  const renderList = searchKeyWords ? searchList : list

  const handleSearch = useCallback(
    debounce((val) => {
      const searchRes = filter(list, (item) => {
        return item?.textContent?.indexOf(val) !== -1 || item?.shortcutKey?.indexOf(val) !== -1
      })

      setSearchList(searchRes)
    }, 300),
    [list, searchList]
  )

  useEffect(() => {
    if (!searchKeyWords || !inpEnd) {
      return
    }

    handleSearch(searchKeyWords)
    return () => {
      handleSearch.cancel()
    }
  }, [searchKeyWords, keyWords, inpEnd])

  useEffect(() => {
    setActiveIndex(0)
  }, [renderList])

  // 键盘事件处理
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setFastWordsModalData(defaltFastModalData)
      }
      if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.key !== 'Enter') return
      event.preventDefault()
      event.stopPropagation()
      if (event.key === 'ArrowUp') {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? renderList.length - 1 : prevIndex - 1))
      } else if (event.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => (prevIndex === renderList.length - 1 ? 0 : prevIndex + 1))
      } else if (event.key === 'Enter') {
        const selectedItem = renderList[activeIndex]
        if (selectedItem && onSelect) {
          setFastWordsModalData(defaltFastModalData)
          onSelect(selectedItem)
        }
      }
    },
    [renderList, activeIndex, onSelect, setFastWordsModalData]
  )

  const handleClickOutside = useCallback(
    (event) => {
      const modalElement = document.getElementById('fast-words-modal')
      if (modalElement && !modalElement.contains(event.target)) {
        setFastWordsModalData(defaltFastModalData)
      }
    },
    [setFastWordsModalData]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleKeyDown, handleClickOutside])

  useEffect(() => {
    if (!renderList.length) {
      setFastWordsModalData(defaltFastModalData)
      return
    }
  }, [searchList, list])

  useEffect(() => {
    const dom = document.getElementById(`fast-words-${activeIndex}`)
    if (dom) {
      dom.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
    }
  }, [activeIndex])

  return (
    <div
      ref={wrapRef}
      id="fast-words-modal"
      className={cn(
        `w-[334px] max-h-[296px] text-sm bg-white rounded-md shadow-lg overflow-y-auto flex flex-col gap-0.5 p-2 drop-shadow-md
   [&_.item]:flex [&_.item]:shrink-0 [&_.item]:h-7 [&_.item]:items-center [&_.item]:gap-1 [&_.item>span]:shrink-0 [&_.item]:pl-2 [&_.item]:pr-2 [&_.item]:rounded-md [&_.item]:cursor-pointer
   [&_.item:hover]:bg-lingheBtnHover z-999`,
        !renderList.length && 'hidden'
      )}
    >
      {renderList?.length ? (
        map(renderList, (item, index) => (
          <div
            id={`fast-words-${index}`}
            className={cn(
              'item max-w-full overflow-hidden',
              index === activeIndex && 'bg-lingheBtnHover'
            )}
            key={item?.preMessageId}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setFastWordsModalData(defaltFastModalData)
              onSelect(item)
            }}
          >
            <span className="text-primary flex items-center">
              {'['}
              <span className="inline-block max-w-[160px] whitespace-nowrap overflow-hidden text-ellipsis">
                {highlightText(item?.shortcutKey, searchKeyWords)}
              </span>
              {']'}
            </span>
            <span className="flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
              {highlightText(item?.textContent, searchKeyWords)}
            </span>
            {item?.imageContent && item?.imageContent?.length ? (
              <img src={item?.imageContent[0]} className="w-5 h-5 rounded" />
            ) : (
              false
            )}
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center min-h-[200px]">
          <ReactSVG src={noSearchResult} />
          {t('common.noDataAvailable')}
        </div>
      )}
    </div>
  )
}

export default memo(FastWrodsModal)
