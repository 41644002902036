import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'

interface IProps {
  time: number | string
}

const TimeCount: FC<IProps> = ({ time }) => {
  const [duration, setDuration] = useState(dayjs.duration(0))

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs()
      const then = dayjs(time)
      const diff = now.diff(then)
      setDuration(dayjs.duration(diff))
    }, 1000)

    return (): void => {
      clearInterval(interval)
    }
  }, [time])

  const formatTimeDifference = (duration): string => {
    if (duration.asHours() >= 99) {
      return '99:59:59'
    } else if (duration.asHours() >= 1) {
      return duration.format('HH:mm:ss')
    } else {
      return duration.format('mm:ss')
    }
  }

  return (
    <div className="rounded-full bg-lingheError text-white pl-1 pr-1 text-xs">
      {formatTimeDifference(duration)}
    </div>
  )
}

export default TimeCount
