import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { IChangeFacebookRemarkReq } from './fetchChangeFacebookRemark'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * facebook账号备注
 * @param param
 * @returns
 */
export const fetchChangeFacebookAccountRemark = async (
  param: IChangeFacebookRemarkReq
): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(`/bg/channel/facebook/remake`, param)

  return serialize(data)
}
