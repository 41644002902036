import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { ILanguageList } from "./fetchLanguageList";
import { ISessionTranslateConfig } from "./fetchSessionTranslateConfig";

export interface ISessionTranslateEngine {
  /**
   * 接收消息翻译语言
   */
  customerLanguageCode?: string;
  /**
   * 接收消息通用语言码,选择翻译线路后必传
   */
  customerUniversalLanguage?: string;
  /**
   * 翻译线路
   */
  engine?: string;
  /**
   * 发送消翻译语言
   */
  userLanguageCode?: string;
  /**
   * 发送消息通用语言码,选择翻译线路后必传
   */
  userUniversalLanguage?: string;
}

interface ApiResp extends ClientResponse {
  data: ISessionTranslateConfig;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 用户消息翻译线路配置
 * @param sessionId
 * @returns
 */
export const fetchSessionTranslateEngine = async (params: {
  sessionId: string;
  data: ISessionTranslateEngine;
}): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(
    `/translate/session/config/${params.sessionId}/engine`,
    { ...params.data },
  );

  return serialize(data);
};
