import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageGroupVO
 */
export interface KbTeamGroupListResponse {
  groupId?: number;
  groupName?: string;
}
interface ApiResp extends ClientResponse {
  data: KbTeamGroupListResponse[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术组列表查询
 * @returns 
 */
export const fetchKbTeamGroupList = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/kb/team/group/list`);

  return serialize(data);
};
