export const isLoginPath = (path: string) => {
  return [
    '/',
    '/enterprise_sign',
    '/checkInfo-login',
    '/select-tenant',
    '/create-tenant',
    '/signin-by-passport',
    '/signin-by-invite',
    '/signin-by-qrcode',
    '/signup',
    '/captcha-valid',
    '/reset-password',
    '/registration-account',
    '/create-company',
    '/company-info'
  ].includes(path)
}
