import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerPageListRequest } from "./fetchDataCustomerPageList";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * CustomerStatisticsQueryDTO
 */
export interface IDataChannelPageRequest {
  /**
   * 渠道id，渠道数据分页使用
   */
  channelId?: number | string;
  /**
   * 结束时间
   */
  endDate: Date;
  pageNum?: number;
  pageSize?: number;
  /**
   * 开始时间
   */
  startDate: Date;
  /**
   * 用户id，实时数据、坐席数据分页使用
   */
  userId?: number | string;
}

/**
 * PageChannelStatisticsPageVO
 */
export interface IDataChannelPageResponse {
  countId?: string;
  current?: number;
  pages?: number;
  records?: IDataChannelList[];
  searchCount?: Response;
  size?: number;
  total?: number;
}

/**
 * ChannelStatisticsPageVO
 */
export interface IDataChannelList {
  id: number;
  /**
   * 渠道id
   */
  channelId?: number | string;
  /**
   * 渠道类型
   */
  channelType?: string;
  /**
   * 访客数量
   */
  customerNum?: number;
  /**
   * 渠道name
   */
  name?: string;
  /**
   * 新客数
   */
  newCustomerNum?: number;
  /**
   * 常客数
   */
  oldCustomerNum?: number;
  /**
   * 会话量
   */
  sessionNum?: number;
}

interface ApiResp extends ClientResponse {
  data: IDataChannelPageResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 渠道咨询明细page
 * @param param
 * @returns
 */
export const fetchDataChannelPage = async (
  param: IDataChannelPageRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/channel/statistics/page`,
    param,
  );

  return serialize(data);
};
