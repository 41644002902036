import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { Button, Input, Switch, cn } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { ReactSVG } from 'react-svg'
import { Controller, useForm } from 'react-hook-form'
import { inputIconClassName } from '@renderer/utils/consts'
import search from '../../../assets/svg/search.svg'
import {
  IRolePageListReq,
  IRoleRecords,
  fetchAddOrEditRole,
  fetchChangeRoleStatus,
  fetchDelRole,
  fetchRoleDetail,
  fetchRoleListPage
} from '@renderer/api'
import { useMutation } from '@tanstack/react-query'
import { IPageationRef } from '../AddCustomer'
import noCustomer from '../../../assets/svg/noCustomer.svg'
import { ColumnDef } from '@tanstack/react-table'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import AddRolesSheet, { IAddRolesData } from '../Components/AddRolesSheet'
import { useManageRouteMenu } from '@renderer/hooks/manager/useManageRouteMenu'
import DelCustolerModal from '../Components/DelCustolerModal'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'

const index: FC = () => {
  const [roleSheetData, setRoleSheetData] = useState<IAddRolesData | null>({
    open: false,
    type: 'add',
    roleDetail: null
  })
  const [delData, setDelData] = useState<{
    open: boolean
    roleId: string
  }>({ open: false, roleId: '' })
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })
  const { handleSubmit, control, getValues } = useForm<IRolePageListReq>({
    defaultValues: {
      roleName: '',
      pageNum: 1,
      pageSize: 10
    }
  })

  const { menuData } = useManageRouteMenu()
  const { t } = useTranslation()

  const { data: roleData, mutateAsync: fetchRoleList } = useMutation({
    mutationFn: fetchRoleListPage
  })

  const { mutateAsync: changeRoleStatus } = useMutation({
    mutationFn: fetchChangeRoleStatus
  })

  const { mutateAsync: getRoleDetail } = useMutation({
    mutationFn: fetchRoleDetail
  })

  const { mutateAsync: addOrEditRole } = useMutation({
    mutationFn: fetchAddOrEditRole
  })

  const { mutateAsync: delRole } = useMutation({
    mutationFn: fetchDelRole
  })

  const onSubmit = (formData): void => {
    fetchRoleList(formData)
  }

  const columns: ColumnDef<IRoleRecords>[] = useMemo(
    () => [
      {
        id: 'roleName',
        header: (): JSX.Element => <div>{t('businessManagement.RoleName')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.roleName}</div>
        }
      },
      {
        id: 'remark',
        header: (): JSX.Element => <div>{t('businessManagement.Description')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="w-[400px] whitespace-pre-wrap">{original?.remark}</div>
        }
      },
      {
        id: 'status',
        header: (): JSX.Element => <div>{t('businessManagement.Enableornot')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <Switch
              disabled={Boolean(original?.isDefault)}
              checked={Boolean(original?.status)}
              onCheckedChange={async (checked) => {
                try {
                  const res = await changeRoleStatus({
                    roleId: original?.roleId,
                    status: Number(checked)
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    message(t('businessManagement.modifiedsuccessfully'), 'success')
                    fetchRoleList({
                      ...getValues(),
                      pageNum: pageationRef?.current?.currentPage || 1,
                      pageSize: pageationRef?.current?.itemsPerPage || 10
                    })
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            />
          )
        }
      },
      {
        id: 'edit',
        header: (): JSX.Element => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-2 [&_button]:p-0">
              <Button
                variant="link"
                className="hover:no-underline"
                onClick={async (): Promise<void> => {
                  const res = await getRoleDetail(original?.roleId)
                  setRoleSheetData({
                    open: true,
                    type: 'check',
                    roleDetail: res?.data
                  })
                }}
              >
                {t('businessManagement.View')}
              </Button>
              {+original?.isDefault === 0 && (
                <>
                  <span className="text-[#ECECEC]">|</span>
                  <Button
                    variant="link"
                    className="hover:no-underline"
                    onClick={async (): Promise<void> => {
                      const res = await getRoleDetail(original?.roleId)
                      setRoleSheetData({
                        open: true,
                        type: 'edit',
                        roleDetail: res?.data
                      })
                    }}
                  >
                    {t('login.Edit')}
                  </Button>
                  <span className="text-[#ECECEC]">|</span>
                  <Button
                    variant="link"
                    className="hover:no-underline text-lingheError"
                    onClick={() => {
                      setDelData({
                        open: true,
                        roleId: original?.roleId + ''
                      })
                    }}
                  >
                    {t('businessManagement.removing')}
                  </Button>
                </>
              )}
            </div>
          )
        }
      }
    ],
    []
  )

  useEffect(() => {
    fetchRoleList({})
  }, [])

  return (
    <NavBodyLayout
      title={[
        t('businessManagement.businessManagementTitle'),
        t('businessManagement.role_Management')
      ]}
    >
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center justify-between shrink-0 p-3 h-[54px] border-b">
          <span>{t('businessManagement.role_Management')}</span>
          <Button
            onClick={() => {
              setRoleSheetData({
                open: true,
                type: 'add',
                roleDetail: null
              })
            }}
          >
            <Plus strokeWidth={1.5} />
            {t('businessManagement.AddRole')}
          </Button>
        </div>

        <form
          className="h-16 shrink-0 flex items-center pl-3 pr-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="roleName"
            control={control}
            render={({ field }) => (
              <div className="relative">
                <Input
                  {...field}
                  placeholder={t('businessManagement.Pleaseenteracharactername')}
                  className={cn('pl-6 h-8 w-[280px]')}
                />
                <ReactSVG src={search} className={cn(inputIconClassName)} />
              </div>
            )}
          />
        </form>

        <div className="flex-1 shrink-0 overflow-hidden">
          <ManagerTable<IRoleRecords>
            pageationRef={pageationRef}
            data={roleData?.data?.records || []}
            columns={columns}
            rowCount={roleData?.data?.total || 0}
            onPageChange={(pageNum, pageSize) => {
              fetchRoleList({
                pageNum,
                pageSize,
                ...getValues()
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={noCustomer} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('businessManagement.Therecurrentlyroleinformationavailable')}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <AddRolesSheet
        roleData={roleSheetData}
        menuData={menuData}
        onClose={() => setRoleSheetData({ open: false, type: 'add', roleDetail: null })}
        onComfirn={async (param): Promise<void> => {
          try {
            const res = await addOrEditRole(param)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Theoperationwassuccessful'), 'success')
              setRoleSheetData({ open: false, type: 'add', roleDetail: null })
              fetchRoleList({
                ...getValues(),
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      <DelCustolerModal
        title={t('businessManagement.DeleteRole')}
        open={delData.open}
        onClose={() => {
          setDelData({
            open: false,
            roleId: ''
          })
        }}
        onConfirm={async () => {
          const res = await delRole(delData?.roleId)
          if (res?.code === RESPONSE_CODE.success)
            message(t('businessManagement.Deletedsuccessfully'), 'success')
          fetchRoleList({
            ...getValues(),
            pageNum: pageationRef?.current?.currentPage || 1,
            pageSize: pageationRef?.current?.itemsPerPage || 10
          })
          setDelData({
            open: false,
            roleId: ''
          })
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center whitespace-pre-line">
            {t('businessManagement.DeleteRoleContent')}
          </div>
        }
      />
    </NavBodyLayout>
  )
}

export default memo(index)
