import {
  AddPersonWordsGroupRequest,
  IChatList,
  ISessionTranslateConfig,
  PersonWordsGroupResponse,
  SavePersonWordsRequest,
  TeamWordsFromPcRequest,
  TeamWordsFromPcResponse,
  UserDetailResponse
} from '@brisk/api'
import { ITagList } from '../../api/manage/fetchTagList'
import { ActionMessage, StateMessage } from '../../store/useCurrentAndSearch'
import { ActionAssigned, StateAssigned } from '../../store/useAssignedStore'
import { User } from '@renderer/model/data/user'

export type IMessageContext = {
  user: Partial<User>
  chatItemActive: number | string
  setChatItemActive: (val: number | string) => void
  itemData: IChatList
  userDetailData: UserDetailResponse
  handleTranslateIn: (id: string) => void
  handleGetUserDetail: (id) => void
  tagList: ITagList[]
  connectStatus: boolean
  connectStatusCode: number
  wordsDataList: TeamWordsFromPcResponse[]
  personWordsDataList: TeamWordsFromPcResponse[]
  isFetch: boolean
  handleGetTeamWordsData: (param?: TeamWordsFromPcRequest) => void
  personGroupList: PersonWordsGroupResponse[]
  handleAddPersonGroup: (param: AddPersonWordsGroupRequest, callBack?: () => void) => void
  handleSavePersonWords: (param: SavePersonWordsRequest, callBack?: () => void) => void
  handleDelPersonWords: (id: number) => void
  handleDelPersonWordsGroup: (id: number) => void
  setLoading: (loading: boolean) => void
  refetchTagList: () => void
  TALKSTATUS: {
    // 会话是否是自己的
    isSelf: boolean
    // 是否已结束
    isEnd: boolean
    // 是否在排队中
    isWaiting: boolean
  }
  isManager: boolean
  sessionTranslateConfig: ISessionTranslateConfig
  handleGetSessinTranslateConfig: (sessionId: string) => void
}

export type CombinedMessage = StateMessage &
  ActionMessage &
  StateAssigned &
  ActionAssigned &
  IMessageContext

const defautData = {
  total: 0,
  size: 10,
  current: 1,
  pages: 1
}

export const defaultMessageInfoContext = {
  user: null,
  chatItemActive: '',
  setChatItemActive: () => {},
  itemData: null,
  userDetailData: null,
  active: 0,
  currentTab: 0,
  tagList: [],
  connectStatus: false,
  connectStatusCode: 0,
  reCallMessage: [],
  searchResult: { resultList: [], value: '' },
  setActive: () => {},
  setCurrentTab: () => {},
  setSearchResult: () => {},
  handleTranslateIn: () => {},
  assignedData: defautData,
  unAssignedData: defautData,
  finishedData: defautData,
  assignedList: [],
  allConversations: [],
  unAssignedList: [],
  finishedList: [],
  setAssignedList: () => {},
  setAssignedData: () => {},
  setUnAssignedList: () => {},
  setUnAssignedData: () => {},
  setFinishedList: () => {},
  setfinishedData: () => {},
  handleGetUserDetail: () => {},
  setReCallMessage: () => {},
  setAllConversations: () => {},
  delReCallMessage: () => {},
  clearChatListInfo: () => {},
  clearCurrent: () => {},
  shouldUpdate: false,

  wordsDataList: [],
  personWordsDataList: [],
  isFetch: false,
  handleGetTeamWordsData: () => {},
  personGroupList: [],
  handleAddPersonGroup: () => {},
  handleSavePersonWords: () => {},
  setLoading: () => {},
  handleDelPersonWords: () => {},
  handleDelPersonWordsGroup: () => {},
  refetchTagList: () => {},
  setShouldUpdate: () => {},
  TALKSTATUS: {
    isSelf: false,
    isEnd: false,
    isWaiting: false
  },
  isManager: false,
  sessionTranslateConfig: {},
  handleGetSessinTranslateConfig: () => {},

  shoudScrollWhenSearch: false,
  setShouldScrollWhenSearch: () => {}
}
