import { IContent } from '@renderer/api/chatList'
import VideoComp from '@renderer/components/VideoComp'
import { FC, memo } from 'react'

const ChatListVideo: FC<{
  data: IContent
}> = ({ data }) => {
  return (
    <>
      <div className="max-w-72">
        <VideoComp src={data?.content?.url} />
      </div>
    </>
  )
}

export default memo(ChatListVideo)
