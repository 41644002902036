/**
 * getTranslateConfig
 *
 * @desc 描述：获取全局翻译设置
 * @desc 描述：/translate/config
 */

/* =================================================== */

import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

export const API_PATH = '/translate/config'

/**
 * 获取全局翻译设置
 */
export async function getTranslateConfig(): Promise<ITranslateConfig> {
  const res = await httpClient.get(API_PATH)

  return get(res, 'data.data')
}

export interface ITranslateConfig {
  translateConfigId: number
  translateEngine: string
  customerEnableStatus: number
  customerLanguageCode?: string
  customerUniversalLanguage?: string
  customerLanguage?: string
  userEnableStatus: number
  userLanguageCode?: string
  userLanguage?: string
  userUniversalLanguage?: string
}
