import {
  Button,
  CardContent,
  CardFooter,
  cn,
  Input,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@brisk/ui'
import { FC, useContext, useEffect, useId, useRef, useState } from 'react'
import { PhoneInput } from '../../components/phone-input'
import { useNavigate } from 'react-router-dom'
import { SignInHeader } from './components/SignInHeader'
// import { SignInSwitcher } from './components/SignInSwitcher'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
// import { object, string } from 'zod'
import { useSignStore } from '../../store/useSignStore'
import { AccountType } from '../../api/model'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useErrorHinter } from '../../hooks/useErrorHinter'
import { Mail } from 'lucide-react'
import { inputIconClassName } from '@renderer/utils/consts'
// import { zodResolver } from '@hookform/resolvers/zod'
import { message } from '@renderer/components/message'
import { ReactSVG } from 'react-svg'
// import { NoFocusInput } from './components/NoFocusInput'
// import captacha from '../../assets/svg/captacha.svg'
import { useMutation } from '@tanstack/react-query'
import { fetchUserExist } from '@brisk/api'
import { Tenant } from '@renderer/model'
import { SignInContext } from '.'
import { useTranslation } from 'react-i18next'
import LoginSwitchLanguage from './components/LoginSwitchLanguage'
// import { isEmpty } from 'lodash'
import { parseUrlParams } from '@renderer/utils/parseUrlParams'
import * as Checkbox from '@radix-ui/react-checkbox'
import loginCheckbox from '../../assets/svg/loginCheckbox.svg'
import './style/index.css'
import { trimAll } from 'css-sdk/src/utils'
import { useGraphicalVerification } from './hooks/graphicalVerification'
import GraphicalVerification from './components/graphicalVerification/graphicalVerification'

export type IFormInput = {
  phone?: string
  email: string
  // phoneCaptchaImage: string
  // phoneCaptchaCode: string
  // phoneCaptchaTempToken: string
  //
  // emailCaptchaImage: string
  // emailCaptchaCode: string
  // emailCaptchaTempToken: string
}
export type trackDataType = {
  captchaId?: string
  captchaTrack?: object
}
const queryParams = parseUrlParams(window.location.href) as { type: AccountType; content: string }
export const defaultFormValues: IFormInput = {
  phone: queryParams.content,
  email: queryParams.content || '' // 给个初始值
  // phoneCaptchaImage: '',
  // phoneCaptchaCode: '',
  // phoneCaptchaTempToken: '',
  //
  // emailCaptchaImage: '',
  // emailCaptchaCode: '',
  // emailCaptchaTempToken: ''
}
let useFormInput: IFormInput = defaultFormValues
export const InputAccount: FC = () => {
  const readId = useId()
  const autoSiginId = useId()
  const navigate = useNavigate()
  const [currentTabValue, setCurrentTabValue] = useState(AccountType.SMS)
  const [read, setRead] = useState(true)
  const [checked, setChecked] = useState(true)
  const { setTenantList, setAccount, setAccountType, setFifteenAutoSingin } = useSignStore()
  const { setLoading } = useContext(SignInContext)
  const [phoneCode, setPhoneCode] = useState('1')
  const valueRef = useRef({ val: '+86', phone: '' })
  const {
    setCaptchaStatus,
    status,
    setStatus,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    callOpen,
    initFetchSliderCaptcha
  } = useGraphicalVerification()
  // const { mutateAsync: onFetchImgCaptcha } = useMutation({
  //   mutationFn: fetchImgCaptcha
  // })
  const { mutateAsync: onFetchUserExist, isPending } = useMutation({
    mutationFn: fetchUserExist
  })
  const { t } = useTranslation()
  //-----------------------------------------------------------------------
  // valid
  //-----------------------------------------------------------------------
  // const phoneSchema = object({
  //   phone: string().min(5, t('login.pleaseenteryourmobilephonenumber')),
  //   phoneCaptchaImage: string(),
  //   phoneCaptchaCode: string(),
  //   phoneCaptchaTempToken: string()
  // })
  // const emailSchema = object({
  //   email: string({})
  //     .email(t('login.pleaseenterthecorrectemailaddress'))
  //     .min(1, t('login.pleaseenteryouremailaddress')),
  //   emailCaptchaImage: string(),
  //   emailCaptchaCode: string(),
  //   emailCaptchaTempToken: string()
  // })
  // const getSchema = (tab: AccountType) =>
  //   tab === AccountType.SMS
  //     ? phoneSchema.partial({
  //         phoneCaptchaImage: true,
  //         phoneCaptchaCode: true,
  //         phoneCaptchaTempToken: true
  //       })
  //     : emailSchema.partial({
  //         emailCaptchaImage: true,
  //         emailCaptchaCode: true,
  //         emailCaptchaTempToken: true
  //       })
  //-----------------------------------------------------------------------
  // form
  //-----------------------------------------------------------------------
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues
  })
  useErrorHinter([currentTabValue === AccountType.SMS ? errors?.phone : errors?.email])

  useEffect(() => {
    let accountType = queryParams.type
    const account = queryParams.content

    if (!account || !accountType) return

    // 处理 PHONE 类型转换为 SMS
    if (accountType === AccountType.PHONE) {
      accountType = AccountType.SMS
    }

    // 更新状态
    setAccount(account)
    setAccountType(accountType)
    setCurrentTabValue(accountType)
  }, [])

  // const makeFetchCompanyListData = (formData: {
  //   phone?: string
  //   trackData?: trackDataType
  //   email?: string
  // }): { account: string } => {
  //   const apiPayload = {
  //     account: ''
  //   }
  //   if (currentTabValue === AccountType.SMS) {
  //     apiPayload.account = formData.phone
  //   } else {
  //     apiPayload.account = formData.email
  //   }
  //
  //   return apiPayload
  // }

  // const setCaptcha = async (): Promise<void> => {
  //   const { data } = await onFetchImgCaptcha()
  //   if (currentTabValue === AccountType.SMS) {
  //     setValue('phoneCaptchaImage', data.base64)
  //     setValue('phoneCaptchaTempToken', data.tempToken)
  //   } else {
  //     setValue('emailCaptchaImage', data.base64)
  //     setValue('emailCaptchaTempToken', data.tempToken)
  //   }
  // }
  // 刷新
  const trackRefresh = async () => {
    if (callOpen) {
      await initFetchSliderCaptcha()
      return
    }
    setCallOpen(true)
    await initFetchSliderCaptcha()
  }
  /**
   * 滑动图形验证监听
   * @param formData
   * @param trackData
   */
  const handleSlidingMovement = async (formData: IFormInput, trackData?: trackDataType) => {
    if (!read) {
      message(t('login.checkbox2'), 'error')
      return
    }
    const account =
      currentTabValue === AccountType.SMS
        ? '+' + phoneCode + trimAll(formData.phone)
        : formData.email
    if (!account) {
      return
    }
    // if (currentTabValue === AccountType.SMS && formData.phoneCaptchaImage) {
    //   // TODO: 一定需要 手机图片验证嘛
    //   if (isEmpty(formData.phoneCaptchaCode)) {
    //     message(t('login.graphicverificationcodeerror'), 'error')
    //     return
    //   }
    // } else if (currentTabValue === AccountType.EMAIL && formData.emailCaptchaImage) {
    //   // TODO: 一定需要 邮件图片验证嘛
    //   if (isEmpty(formData.emailCaptchaCode)) {
    //     message(t('login.graphicverificationcodeerror'), 'error')
    //     return
    //   }
    // }
    setAccount(account)
    setAccountType(currentTabValue)
    try {
      const data = await onFetchUserExist({ account, ...trackData })
      // valid captcha
      if (data.code == 10007 || data.code == 10012) {
        setStatus('refreshError')
        message(data.msg, 'error')
        return
      }
      if (data.code == 10010) {
        setStatus('initialise')
        trackRefresh()
        return
      }
      if (data?.data.exist) {
        // success
        setStatus('success')
        setTenantList(data.data as unknown as Tenant[])
        setTimeout(() => {
          setCallOpen(false)
          navigate({
            pathname: APP_ROUTER_PATH.SIGNIN_BY_PASSPORT,
            search: `?tenantName=${data.data.tenantName}`
          })
        }, 500)
      } else {
        const account =
          currentTabValue === AccountType.SMS ? trimAll(formData.phone) : formData.email
        const areaCode =
          currentTabValue === AccountType.SMS
            ? '&areaCode=' + encodeURIComponent('+' + phoneCode)
            : ''
        navigate({
          pathname: APP_ROUTER_PATH.COMPANY_INFO,
          search: `?account=${encodeURIComponent(account)}&type=${currentTabValue}${areaCode}`
        })
      }
    } catch (e) {
      // await setCaptcha()
    }
  }
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    setFifteenAutoSingin(checked)
    useFormInput = formData
    await handleSlidingMovement(formData)
  }
  const onPhoneInput = (value: string, len: string[]) => {
    const o_len = len.splice(0, 1)
    let str = ''
    len.map((item) => {
      str += item
    })
    valueRef.current = { val: o_len[0], phone: str }
  }
  // 关闭滑动弹窗
  const handleFail = () => {
    setCallOpen(false)
    setCaptchaStatus(false)
  }
  useEffect(() => setLoading(isPending), [isPending])

  return (
    <>
      {/*{ redirectUrl != '/manager' && <SignInSwitcher /> }*/}
      <SignInHeader title={t('common.welcome_useAIOChat')} showBack={false} />
      <CardContent className="flex-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <GraphicalVerification
            onSuccess={(trackData) => handleSlidingMovement(useFormInput, trackData)}
            onFail={handleFail}
            callOpen={callOpen}
            onRefresh={trackRefresh}
            {...backgroundImage}
            {...jigsawImage}
            status={status}
          />
          <Tabs
            value={currentTabValue}
            className="w-full"
            onValueChange={(value) => {
              setCurrentTabValue(value as AccountType)
            }}
          >
            <TabsList className="mb-5 shadow-none gap-[2rem] w-full bg-inherit h-10">
              <TabsTrigger
                className="TabsTrigger data-[state=active]:bg-[none !important]"
                value={AccountType.SMS}
              >
                <span
                  data-state={currentTabValue === AccountType.SMS ? 'active' : 'inactive'}
                  className="flex pb-[0.5rem] font-normal cursor-pointer TabsTrigger-span px-1 text-xl"
                >
                  {t('common.mobilePhoneNumber')}
                </span>
              </TabsTrigger>
              <TabsTrigger
                className="TabsTrigger data-[state=active]:bg-[none !important]"
                value={AccountType.EMAIL}
              >
                <span
                  data-state={currentTabValue === AccountType.EMAIL ? 'active' : 'inactive'}
                  className="flex pb-[0.5rem] font-normal cursor-pointer TabsTrigger-span px-1 text-xl"
                >
                  {t('common.mailbox')}
                </span>
              </TabsTrigger>
            </TabsList>
            <TabsContent value={AccountType.SMS}>
              <div className="grid gap-4 h-12 mb-[20px]">
                <div className="grid gap-2">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        selectedCountryFun={(value) => setPhoneCode(value)}
                        onPhoneInput={onPhoneInput}
                        {...field}
                        required
                        className="h-full"
                      />
                    )}
                  />
                </div>
              </div>
              {/*{watch('phoneCaptchaImage') && (*/}
              {/*  <Controller*/}
              {/*    name="phoneCaptchaCode"*/}
              {/*    control={control}*/}
              {/*    render={({ field }) => (*/}
              {/*      <div className="grid gap-4">*/}
              {/*        <div className="border w-full h-12 radios rounded-sm flex relative">*/}
              {/*          {errors.phoneCaptchaCode && (*/}
              {/*            <p className="absolute -top-6 text-lingheError text-sm">*/}
              {/*              {t('login.graphicverificationcodeerror')}*/}
              {/*            </p>*/}
              {/*          )}*/}

              {/*          <>*/}
              {/*            <ReactSVG*/}
              {/*              src={captacha as string}*/}
              {/*              className="absolute left-2 top-1/2 -translate-y-1/2"*/}
              {/*            />*/}
              {/*            <NoFocusInput*/}
              {/*              placeholder={t('common.pleaseEnterGraphic_verificationCode')}*/}
              {/*              className={cn(*/}
              {/*                'flex-1 h-full pl-10 pr-10 w-full text-sm border-none outline-none',*/}
              {/*                errors.phoneCaptchaCode*/}
              {/*                  ? 'focus-visible:ring-lingheError'*/}
              {/*                  : 'focus-visible:ring-ring'*/}
              {/*              )}*/}
              {/*              {...field}*/}
              {/*            />*/}
              {/*            <img*/}
              {/*              onClick={() => setCaptcha()}*/}
              {/*              className="w-20 h-[95%] absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"*/}
              {/*              src={`data:image/png;base64,${watch('phoneCaptchaImage')}`}*/}
              {/*              alt=""*/}
              {/*            />*/}
              {/*          </>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  />*/}
              {/*)}*/}
            </TabsContent>
            <TabsContent value={AccountType.EMAIL}>
              <div className="grid gap-4 h-12 mb-[20px]">
                <div className="grid gap-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="relative">
                          <Input
                            maxLength={40}
                            placeholder="m@example.com"
                            className="h-full pl-9"
                            {...field}
                            style={{
                              background:
                                'linear-gradient(rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 0%)'
                            }}
                          />
                          <Mail strokeWidth={1.15} size={20} className={inputIconClassName} />
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
              {/*{watch('emailCaptchaImage') && (*/}
              {/*  <Controller*/}
              {/*    name="emailCaptchaCode"*/}
              {/*    control={control}*/}
              {/*    render={({ field }) => (*/}
              {/*      <div className="grid gap-4">*/}
              {/*        <div className="border w-full h-12 radios rounded-sm flex relative">*/}
              {/*          {errors.emailCaptchaCode && (*/}
              {/*            <p className="absolute -top-6 text-lingheError text-sm">*/}
              {/*              {t('login.graphicverificationcodeerror')}*/}
              {/*            </p>*/}
              {/*          )}*/}

              {/*          <>*/}
              {/*            <ReactSVG*/}
              {/*              src={captacha as string}*/}
              {/*              className="absolute left-2 top-1/2 -translate-y-1/2"*/}
              {/*            />*/}
              {/*            <NoFocusInput*/}
              {/*              placeholder={t('common.pleaseEnterGraphic_verificationCode')}*/}
              {/*              className={cn(*/}
              {/*                'flex-1 h-full pl-10 pr-10 w-full text-sm border-none outline-none',*/}
              {/*                errors.emailCaptchaCode*/}
              {/*                  ? 'focus-visible:ring-lingheError'*/}
              {/*                  : 'focus-visible:ring-ring'*/}
              {/*              )}*/}
              {/*              {...field}*/}
              {/*            />*/}
              {/*            <img*/}
              {/*              onClick={() => setCaptcha()}*/}
              {/*              className="w-20 h-[95%] absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"*/}
              {/*              src={`data:image/png;base64,${watch('emailCaptchaImage')}`}*/}
              {/*              alt=""*/}
              {/*            />*/}
              {/*          </>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  />*/}
              {/*)}*/}
            </TabsContent>
          </Tabs>
          <Button type="submit" className="w-full my-4 h-12">
            {t('login.next')}
          </Button>
          <div className="flex items-center space-x-2  text-sm text-gray-500">
            <Checkbox.Root
              className={cn(
                'peer h-4 w-4 shrink-0 rounded-sm border border-solid border-[rgba(236,236,236,1)] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
              )}
              checked={checked}
              onCheckedChange={(e: boolean) => {
                setChecked(e)
                setFifteenAutoSingin(e)
              }}
              id={autoSiginId}
            >
              <Checkbox.Indicator
                className={cn('flex pt-0.5 items-center justify-center text-current')}
              >
                <ReactSVG src={loginCheckbox} />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <Label
              htmlFor={autoSiginId}
              className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              <span className="text-[#777A8A]">{t('login.checkbox1')}</span>
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox.Root
              className={cn(
                'peer h-4 w-4 shrink-0 rounded-sm border border-solid border-[rgba(236,236,236,1)] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
              )}
              id={readId}
              checked={read}
              onCheckedChange={(e: boolean) => setRead(e)}
            >
              <Checkbox.Indicator
                className={cn('flex pt-0.5 items-center justify-center text-current')}
              >
                <ReactSVG src={loginCheckbox} />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <Label
              htmlFor={readId}
              className="text-sm text-[#777A8A] font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t('login.checkbox3-1')}
              <Button
                variant="link"
                className="p-0 m-0"
                type="reset"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  window.open('https://www.aiochat.im/privacy_policy', '_blank')
                }}
              >
                &nbsp;
                <span>{t('login.checkbox3-2')}</span>
              </Button>
            </Label>
          </div>
        </form>
      </CardContent>
      <CardFooter>
        <div className="w-full text-grsay-500 text-sm font-normal">
          <div className="flex justify-between">
            <LoginSwitchLanguage />
            {/*<div className="text-right chevron-down">*/}
            {/*  <Link to={APP_ROUTER_PATH.ENTERPRISE_SIGN}>OSS登录</Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </CardFooter>
    </>
  )
}
