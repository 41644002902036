/**
 * getEngineDetails
 *
 * @desc 描述：获取引擎详情
 * @desc 描述：接口文档名称 - 语言列表
 * @desc 描述：/language/{engine}/list
 */

/* =================================================== */

import { get } from 'lodash'
import { httpClient } from '../../http-client/httpClient'

/**
 * 获取引擎详情
 * @param engine 引擎名称
 */
export async function getEngineDetails(engine: string): Promise<IEngineDetail[]> {
  const res = await httpClient.get(`/language/${engine}/list`)

  return get(res, 'data.data')
}

// 引擎详情
export interface IEngineDetail {
  languageId: string
  translateEngine: string
  languageCode: string
  language: string
  universalLanguage: string
}
