import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button
} from '@brisk/ui'
import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import delWarning from '../../../assets/svg/delWarning.svg'
import { useTranslation } from 'react-i18next'

interface IDelCustolerModalProps {
  title?: string
  buttonType?: string
  buttonText?: string
  sessionCount?: number
  open: boolean
  onClose: () => void
  onConfirm: () => void
  content: JSX.Element
}

const DelCustolerModal: FC<IDelCustolerModalProps> = ({
  title = '删除客服',
  buttonType = '',
  buttonText = '',
  sessionCount,
  open,
  onClose,
  onConfirm,
  content
}) => {
  const { t } = useTranslation()
  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent
        className="p-0 w-[320px] focus:outline-none"
        style={{
          zIndex: 9999
        }}
      >
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription />
          <div className="flex flex-col items-center justify-center pt-8 pb-8">
            <ReactSVG src={delWarning} />
            {content}
          </div>
        </div>
        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm()
            }}
          >
            {buttonText ? buttonText : t('common.verify')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(DelCustolerModal)
