import { apiClient, ClientResponse } from "../../apiClient";

// interface RequestParams {}

interface ApiResp extends ClientResponse {
    // data: boolean
}
interface Serialize {
    (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
    // TODO: 处理data

    return resp;
};

// 注销所有登录用户
export const fetchUserDetails = async (userId: string | number): Promise<ApiResp> => {
    const { data } = await apiClient.get<ApiResp>(`/bg/user/detail/${userId}`, {});

    return serialize(data);
};
