import { FC, memo, useEffect, useState } from 'react'
import appLogo from '../../../assets/imgs/appLogo.png'
import inviteMobileBg from '../../../assets/imgs/inviteMobileBg.png'
import { Button, cn, Input, PasswordInput } from '@brisk/ui'
import { defaultFormValues, IFormData, IInvite, inviteSchema } from './InvitePc'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import {
  fetchSendTempCaptcha,
  mutationAgreeInvite,
  mutationTempSignInByCaptcha
} from '@renderer/api'
import { useCaptchaCountdown } from '@renderer/hooks/useCaptchaCountdown'
import { useCurrentOrigin } from '@renderer/hooks/useCurrentOrigin'
import { AccountType, TerminalType } from '@renderer/api/model'
import { RESPONSE_CODE } from '@brisk/api'
import { ReloadIcon } from '@radix-ui/react-icons'
import { inputIconClassName } from '@renderer/utils/consts'
import captacha from '../../../assets/imgs/captacha.png'
import inviteLock from '../../../assets/imgs/inviteLock.png'
import inviteSuccess from '../../../assets/imgs/inviteSuccess.png'
import inviteTips from '../../../assets/imgs/inviteTips.png'
import { message } from '@renderer/components/message'
import { getDeviceType } from '@renderer/utils'

/**
 * 当前页rem适配
 * @param pxValue
 * @param baseFontSize
 * @returns
 */
const pxToRem = (pxValue: number, baseFontSize: number = 14): string => {
  return `${(pxValue / baseFontSize).toFixed(2)}rem`
}

const InviteMobile: FC<IInvite> = ({ inviteInfo }) => {
  const [step, setStep] = useState(0)

  const { isCounting, startCount, count, reset, isSentCaptcha } = useCaptchaCountdown()
  const currentOrigin = useCurrentOrigin()?.origin

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormData>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(inviteSchema),
    shouldFocusError: false
  })

  const setRem = (): void => {
    const designWidth = 375
    const currentWidth = document.documentElement.clientWidth || document.body.clientWidth
    const scale = currentWidth / designWidth
    document.documentElement.style.fontSize = `${scale * 14}px`
  }

  // 验证码临时登录
  const { mutateAsync: maSendTempCaptcha, isPending } = useMutation({
    mutationFn: fetchSendTempCaptcha
  })

  /** 临时tempToken */
  const { mutateAsync: maTempSignInByCaptcha } = useMutation({
    mutationFn: mutationTempSignInByCaptcha
  })

  /** 确认加入 */
  const { mutateAsync: agreeInvite } = useMutation({
    mutationFn: mutationAgreeInvite
  })

  const handleClickSendCaptcha = async (): Promise<void> => {
    if (isCounting) {
      return
    }
    const res = await maSendTempCaptcha({
      account: inviteInfo?.account,
      type: inviteInfo?.accountType as AccountType
    })
    if (res?.code !== RESPONSE_CODE.success) {
      reset()
      return
    }
    startCount()
  }

  const onSubmit = async (formData: IFormData): Promise<void> => {
    if (formData?.password !== formData?.confirmPassword) {
      control.setError('confirmPassword', { message: '两次输入的密码不一致' })
      return
    }

    try {
      const res = await maTempSignInByCaptcha({
        type: inviteInfo?.accountType as AccountType,
        account: inviteInfo?.account,
        captcha: formData.captcha,
        terminalType: TerminalType.BROWSER
      })

      if (!res.data.tempToken) {
        control.setError('captcha', { message: '验证码错误，请重新输入' })
        return
      }

      const agreeRes = await agreeInvite({
        tempToken: res?.data?.tempToken,
        token: inviteInfo?.token,
        password: formData?.confirmPassword
      })
      if (agreeRes?.code === RESPONSE_CODE.success) setStep(1)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setRem()
  }, [])

  useEffect(() => {
    if (errors?.captcha) {
      message(errors?.captcha?.message, 'error')
      return
    }
    if (errors?.password) {
      message(errors?.password?.message, 'error')
      return
    }
    if (errors?.confirmPassword) {
      message(errors?.confirmPassword?.message, 'error')
      return
    }
  }, [errors])

  useEffect(() => {
    if (inviteInfo?.joined) setStep(2)
  }, [inviteInfo])

  return (
    <div className="w-full h-screen">
      <div
        className={cn(' w-full pl-3 pr-3 relative')}
        style={{
          paddingTop: pxToRem(44),
          backgroundImage: `url(${inviteMobileBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: pxToRem(280)
        }}
      >
        <div className="w-full flex items-center justify-around">
          <div
            className="flex flex-col font-semibold"
            style={{
              fontSize: pxToRem(20)
            }}
          >
            <span>您好！</span>
            <span>欢迎使用 AIOChat</span>
          </div>
          <img
            src={appLogo}
            style={{
              width: pxToRem(100),
              height: pxToRem(100)
            }}
            alt=""
          />
        </div>
        <div
          className={`w-full shadow-t-lg flex items-center justify-center bg-gradient-to-b from-transparent via-transparent to-white`}
          style={{
            height: pxToRem(88),
            borderTop: `${pxToRem(1)} solid #fff`,
            position: 'absolute',
            left: 0,
            bottom: 0,
            fontSize: pxToRem(14),
            borderRadius: `${pxToRem(34)} ${pxToRem(34)} 0 0`
          }}
        >
          {step === 0 ? (
            <p
              className="text-center text-lingheTextGray [&_span]:text-black"
              style={{
                width: pxToRem(283)
              }}
            >
              您的账号<span>{inviteInfo?.account}</span>,正在加入
              <span>{inviteInfo?.tenantName}</span>,请您完成以下信息
            </p>
          ) : (
            false
          )}
        </div>

        {step === 0 && (
          <form
            className="w-full"
            onSubmit={handleSubmit(onSubmit)}
            style={{
              paddingLeft: pxToRem(20),
              paddingRight: pxToRem(20),
              marginTop: pxToRem(128),
              fontSize: pxToRem(14)
            }}
          >
            <Controller
              name="captcha"
              control={control}
              render={({ field }) => (
                <div
                  className="border w-full radios rounded-sm flex relative"
                  style={{
                    height: pxToRem(52),
                    marginBottom: pxToRem(28)
                  }}
                >
                  <img
                    src={captacha}
                    className={inputIconClassName}
                    style={{
                      width: pxToRem(18),
                      height: pxToRem(18)
                    }}
                  />
                  <Input
                    placeholder="请输入验证码"
                    className={cn(
                      'flex-1 h-full pl-9 text-sm border-none outline-none',
                      errors.captcha ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring'
                    )}
                    required
                    {...field}
                  />
                  <Button
                    variant="link"
                    className="h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline"
                    onClick={(e) => {
                      e.preventDefault()
                      handleClickSendCaptcha()
                    }}
                    disabled={isPending}
                  >
                    {isPending && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
                    <span className="absolute left-0 text-border">|</span>
                    {isCounting ? `${count}s` : isSentCaptcha ? '重新发送' : '发送验证码'}
                  </Button>
                </div>
              )}
            />

            <p style={{ fontSize: pxToRem(16), marginBottom: pxToRem(8) }}>请设置企业密码</p>
            <p
              className="text-lingheTextGray"
              style={{ fontSize: pxToRem(14), marginBottom: pxToRem(16) }}
            >
              密码必须是8-16位的英文字母、数字组合
            </p>

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <div className="relative" style={{ marginBottom: pxToRem(16) }}>
                  <PasswordInput
                    placeholder="请输入密码"
                    className="pl-10 pr-10"
                    style={{ height: pxToRem(52) }}
                    required
                    {...field}
                  />
                  <img
                    src={inviteLock}
                    className={inputIconClassName}
                    style={{
                      width: pxToRem(18),
                      height: pxToRem(18)
                    }}
                  />
                </div>
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <div className="relative" style={{ marginBottom: pxToRem(24) }}>
                  <PasswordInput
                    placeholder="请输入密码"
                    className="pl-10 pr-10"
                    style={{ height: pxToRem(52) }}
                    required
                    {...field}
                  />
                  <img
                    src={inviteLock}
                    className={inputIconClassName}
                    style={{
                      width: pxToRem(18),
                      height: pxToRem(18)
                    }}
                  />
                </div>
              )}
            />

            <Button
              type="submit"
              className="w-full"
              style={{
                height: pxToRem(52)
              }}
            >
              立即加入
            </Button>
          </form>
        )}

        {step === 1 && (
          <div
            className="w-full flex flex-col items-center gap-2 [&_button]:w-full [&_p]:text-center [&_p]:text-lingheTextGray"
            style={{
              marginTop: pxToRem(148),
              paddingLeft: pxToRem(20),
              paddingRight: pxToRem(20),
              fontSize: pxToRem(14)
            }}
          >
            <img
              src={inviteSuccess}
              className="mb-2"
              style={{
                width: pxToRem(106),
                height: pxToRem(106),
                marginBottom: pxToRem(16)
              }}
            />
            <p
              className=" font-semibold text-center"
              style={{ color: 'black', fontSize: pxToRem(20), marginBottom: pxToRem(8) }}
            >
              加入成功
            </p>
            <p style={{ marginBottom: pxToRem(56) }}>
              您已加入<span className="text-black">{inviteInfo?.tenantName}</span>,快进入 AIO
              Chat,跟小伙伴们打个招呼吧！
            </p>
            <Button
              style={{ height: pxToRem(52), marginBottom: pxToRem(20) }}
              onClick={() => {
                if (getDeviceType() === 'Android')
                  window.open('https://play.google.com/store/apps/details?id=com.csserver.lite')
                if (getDeviceType() === 'iOS')
                  window.open('https://itunes.apple.com/cn/app/6557054321')
              }}
            >
              立即下载
            </Button>
          </div>
        )}

        {step === 2 && (
          <div
            className="w-full flex flex-col items-center gap-2 [&_button]:w-full [&_p]:text-center [&_p]:text-lingheTextGray"
            style={{
              marginTop: pxToRem(148),
              paddingLeft: pxToRem(20),
              paddingRight: pxToRem(20),
              fontSize: pxToRem(14)
            }}
          >
            <img
              src={inviteTips}
              style={{
                width: pxToRem(106),
                height: pxToRem(106),
                marginBottom: pxToRem(16)
              }}
            />
            <p
              className="font-semibold text-center2"
              style={{ color: 'black', fontSize: pxToRem(20), marginBottom: pxToRem(8) }}
            >
              温馨提示
            </p>
            <p style={{ marginBottom: pxToRem(56) }}>
              您已成功加入<span className="text-black">{inviteInfo?.tenantName}</span>
              无需重复操作。
            </p>
            <Button
              style={{ height: pxToRem(52), marginBottom: pxToRem(20) }}
              onClick={() => window.open(currentOrigin)}
            >
              登录
            </Button>
            <p>
              已有AIOChat ？
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  message('等待客户端提供链接', 'info')
                }}
              >
                打开
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(InviteMobile)
