import { FC, useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup
} from '@brisk/ui'
import { fetchBgUserOnJobList } from '@brisk/api'
import { map } from 'lodash'
import { useMutation } from '@tanstack/react-query'

interface Props {
  handleChange?: (value: string) => void
  userId: number
}

const SelectGroupPerson: FC<Props> = ({ handleChange, userId }) => {
  const [groupList, setGroupList] = useState([])
  const { mutateAsync: getBgUserOnJobList } = useMutation({
    mutationFn: fetchBgUserOnJobList
  })
  useEffect(() => {
    initialisationBgUserOnJobList()
  }, [])
  const initialisationBgUserOnJobList = async () => {
    const { data } = await getBgUserOnJobList(String(userId))
    setGroupList(data)
  }
  return (
    <>
      <Select onValueChange={(e) => handleChange(e)}>
        <SelectTrigger className="w-[200px] h-8">
          <SelectValue defaultValue={-1} placeholder={`请选择`} />
        </SelectTrigger>
        <SelectContent className="z-[99999]">
          <SelectGroup>
            {map(groupList, (item) => (
              <SelectItem key={item.userId} value={item.userId}>
                {item.nickname}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}

export default SelectGroupPerson
