import { IContent } from '@brisk/api'
import { getImgOption, SEND_ROLE } from '@brisk/utils'
import { uploadFile } from '@renderer/api'
import { juggle } from '@renderer/lib/sdk/juggle'
import { Upload } from '@renderer/misc/upload'
import { useUserStore } from '@renderer/store/useUserStore'
import { getFileExtension } from '@renderer/utils'
import { sendImageMsg } from '@renderer/utils/im-sdk/ipcTimSdk'
import { saveTalkingMsg, sendMessageThen } from '@renderer/utils/juggleHandles'
import { useMutation } from '@tanstack/react-query'
import { v4 } from 'uuid'
const { ConversationType } = juggle

export const useUploadFile = () => {
  const { mutateAsync: getS3Data } = useMutation({
    mutationFn: uploadFile
  })

  const { user } = useUserStore.getState()
  /**
   * 上传文件
   * @param file 文件
   * @param conversationId 组id
   * @param name 上传类型
   */
  const handleUploadFile = async (file, conversationId, name): Promise<void> => {
    const fileExtension = getFileExtension(file.name)
    const { data: ossPassport } = await getS3Data()

    // 另外聊天文件的objectkey定成 im/message/{tenantId}/{yyyyMMdd}/{uuid}.{文件名后缀}
    // File to upload
    const filepath = `im/message/${user?.tenantId}/${v4()}${fileExtension}`
    const uploader = new Upload({
      expirationTime: (ossPassport?.expirationTime || '').toString(),
      storageType: 'minio',
      accessKeyId: ossPassport?.accessKey || '',
      accessKeySecret: ossPassport?.secretKey || '',
      securityToken: ossPassport?.sessionToken || '',
      bucket: ossPassport?.bucket || '',
      endpoint: ossPassport?.endpoint || '',
      region: ossPassport?.region
      // 分片上传 size
      // minShardSize?: number;
      // oss 刷新临时访问凭证。
      // refreshSTSToken?: () => Promise<OssConfig>;
    })
    const param = {
      tid: v4(),
      conversationType: ConversationType.GROUP,
      conversationId,
      name,
      isSender: true,
      sender: {
        portrait: user?.avatarUrl,
        id: user?.userId
      },
      content: {
        url: URL.createObjectURL(file as Blob | MediaSource),
        size: file?.size,
        senderRole: SEND_ROLE.USER
      }
    } as unknown as IContent
    // 更新view
    sendMessageThen(param, 1)
    saveTalkingMsg({ ...param, sentState: 1 })
    const url = await uploader.put(filepath, file) // 已上传到minIO
    if (url) {
      getImgOption(file)
        .then((res) => {
          sendImageMsg(
            {
              ...param,
              content: {
                ...param.content,
                url,
                size: file?.size,
                width: res?.width,
                height: res?.height
              }
            } as unknown as IContent,
            'image'
          )
        })
        .catch((error) => {
          console.log('error', error)
          sendMessageThen(param, 3)
          saveTalkingMsg({ ...param, sentState: 3 })
        })
    }
  }

  return {
    handleUploadFile
  }
}
