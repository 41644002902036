import {CardHeader, CardTitle, cn} from '@brisk/ui'
import { ArrowLeft } from 'lucide-react'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface HeaderNavProps {
  fn?: () => void
  title?: string
  customClass?: string
  len?: number
}

const HeaderNav: FC<HeaderNavProps> = ({ fn, title, customClass, len }) => {
  const navigate = useNavigate()
  return (
    <CardHeader className="pb-0">
      <div
        onClick={() => {
          fn ? fn() : navigate(-1)
        }}
        className="mb-2.5 rounded-full  flex center items-center cursor-pointer"
      >
        <ArrowLeft className="w-5 h-5" />
      </div>
      <CardTitle className={cn(`text-left text-[2rem] ${customClass}`, len >= 0 ? 'pb-6' : 'pb-4')}>{title}</CardTitle>
    </CardHeader>
  )
}

export default HeaderNav
