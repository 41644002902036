/**
 * UpdateDialog
 *
 * @desc 描述：qpp 更新弹窗
 */

/* =================================================== */

import type { FC, ComponentPropsWithoutRef } from 'react'
import { memo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean, useCreation, useRafState } from 'ahooks'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Progress
} from '@brisk/ui'

import UpdatePopupsBg from '@renderer/assets/imgs/updatePopupsBg.png'
import type { IDialogDerive } from './types'
import { DialogStateEnum, UpdateBtnEnum } from './types'
import { useDlUpdate } from '@renderer/hooks/appUpdate/useDlUpdate'
import { message } from '@renderer/components/message'

export interface IUpdateDialogProps
  extends Omit<ComponentPropsWithoutRef<typeof AlertDialog>, 'children'> {
  setOpen?: (is: boolean) => void
  version: string
}

const UpdateDialog: FC<IUpdateDialogProps> = ({ open, setOpen, version, ...otherProps }) => {
  const { t } = useTranslation()

  // 弹窗状态
  const [dialogState, setDialogState] = useState(DialogStateEnum.Discover)

  // 下载进度
  const [dlProgress, setDlProgress] = useRafState(0)

  const dialogDerive = useCreation<IDialogDerive>(() => {
    // 发现、更新通用
    const _obj = {
      textBtn: t(UpdateBtnEnum.Update),
      onClick: onUp
    }

    // 策略集合
    const _map = {
      // 发现
      [DialogStateEnum.Discover]: {
        ..._obj,
        details: (
          <div className="text-sm text-[#777A8A]">
            {t('systemSetting.检测到有新版本 v{version}，是否下载更新', { version })}
          </div>
        )
      },

      // 更新
      [DialogStateEnum.Update]: {
        ..._obj,
        details: (
          <div>
            <div className="font-medium mb-1">loading...</div>

            <Progress
              value={dlProgress}
              className="bg-[#F6F7F9]"
              indicatorClass="rounded-md bg-gradient-to-r from-[#247CFF] to-[#24BAFF]"
            />
          </div>
        )
      },

      // 重启
      [DialogStateEnum.Reboot]: {
        textBtn: t(UpdateBtnEnum.RstUpdate),
        onClick: onInstUpdates,
        details: (
          <div className="text-sm text-[#777A8A]">
            {t('systemSetting.新版本已下载完成，是否重启安装')}
          </div>
        )
      }
    }

    return _map[dialogState] ?? _obj
  }, [dialogState, dlProgress, t, version])

  // 是否点击安装
  const [isClickInstall, { setTrue: clickInstall, setFalse: notClickInstall }] = useBoolean(false)

  // 关闭时重置弹窗状态
  useEffect(() => {
    if (open) return

    setDialogState(DialogStateEnum.Discover)
    setDlProgress(0)
    notClickInstall()
  }, [open])

  // 点击更新
  function onUp() {
    setDialogState(DialogStateEnum.Update)
    dlUpdateR()
  }

  // 点击安装更新
  function onInstUpdates() {
    message(t('systemSetting.程序即将重启'), 'success')
    clickInstall()
    window.appUpdate.quitAndInstall()
  }

  // 取消
  function onCancel() {
    dlCancel()
    setOpen?.(false)
  }

  // 下载更新
  const [{ loading: dlUpdateL, run: dlUpdateR }, dlCancel] = useDlUpdate({
    manual: true,
    onSuccess: () => setDialogState(DialogStateEnum.Reboot),
    dlProgressListen: ({ percent }) => setDlProgress(percent)
  })

  return (
    <AlertDialog open={open} {...otherProps}>
      <AlertDialogContent
        className="gap-y-5 bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${UpdatePopupsBg})` }}
      >
        <AlertDialogHeader>
          <AlertDialogTitle>{t('systemSetting.发现新版本')}</AlertDialogTitle>
        </AlertDialogHeader>

        {/* 内容 */}
        {dialogDerive?.details}

        {/* 按钮区 */}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>{t('common.cancel')}</AlertDialogCancel>

          <AlertDialogAction disabled={dlUpdateL || isClickInstall} onClick={dialogDerive.onClick}>
            {dialogDerive.textBtn}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(UpdateDialog)
