import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PageQuery
 */
export interface KeyWordsPageRequest {
  pageNum?: number;
  pageSize?: number;
}

/**
 * PageKeywordVO
 */
export interface KeyWordsPageResponse {
  countId?: string;
  current?: number;
  records?: KeyWordsPage[];
  searchCount?: Response;
  size?: number;
  total?: number;
}

/**
 * KeywordVO
 */
export interface KeyWordsPage {
  id: number;
  /**
   * 应答图片
   */
  imagesUrlList?: string[];
  contents?: string[];
  /**
   * 关键词ID
   */
  keywordId?: number;
  /**
   * 关键词
   */
  keywordList: string[];
  /**
   * 应答语
   */
  response?: string;
}

interface ApiResp extends ClientResponse {
  data: KeyWordsPageResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 分页查询关键词
 * @param param
 * @returns
 */
export const fetchKeyWordsPage = async (
  param: KeyWordsPageRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/kb/keyword/page`, param);

  return serialize(data);
};
