import { useRef, useState, useEffect, memo } from 'react'
import { AlertDialog, AlertDialogPortal, AlertDialogContent, AlertDialogTitle } from '@brisk/ui'
import './index.less'
import { graphicalVerificationProps } from './type/type'
import { Cross2Icon } from '@radix-ui/react-icons'
import arrows from '@renderer/assets/svg/arrows.svg'
import { ReactSVG } from 'react-svg'
import loadings from '@renderer/assets/svg/loadings.svg'
import refresh from '@renderer/assets/svg/refresh.svg'
import tickSuccessfully from '@renderer/assets/svg/tickSuccessfully.svg'
import code_bg from '@renderer/assets/imgs/code_bg.png'

export default memo(
  ({
    id,
    width = 320,
    height = 160,
    l = 42,
    r = 9,
    imgUrl,
    image,
    image_height,
    image_width,
    status,
    // refreshIcon = 'http://cdn.dooring.cn/dr/icon12.png',
    visible = true,
    onDraw,
    captchaStatus,
    callOpen,
    onSuccess,
    onFail,
    onRefresh,
    onCaptchaSuccess
  }: graphicalVerificationProps) => {
    const [isLoading, setLoading] = useState(false)
    const [sliderLeft, setSliderLeft] = useState(1)
    const [open, setOpen] = useState(false)
    const [sliderClass, setSliderClass] = useState('sliderContainer')
    const [tipStatus, setTipStatus] = useState(status)
    const canvasRef = useRef<any>(null)
    const blockRef = useRef<any>(null)
    const imgRef = useRef<any>(null)
    const isMouseDownRef = useRef<boolean>(false)
    const trailRef = useRef<number[]>([])
    const originXRef = useRef<number>(0)
    const originYRef = useRef<number>(0)
    const [startTime, setStartTime] = useState(null)
    const [trackList, setTrackList] = useState([])
    const PI = Math.PI
    const L = l + r * 2 + 3 // 滑块实际边长
    const tipStatusObj = {
      loading: '加载中...',
      initialise: '向右拖动滑块填充拼图',
      success: 'success',
      refreshError: '验证失败'
    }
    const drawPath = (ctx: any, x: number, y: number, operation: 'fill' | 'clip') => {
      ctx.beginPath()
      ctx.moveTo(x, y)
      ctx.arc(x + l / 2, y - r + 2, r, 0.72 * PI, 2.26 * PI)
      ctx.lineTo(x + l, y)
      ctx.arc(x + l + r - 2, y + l / 2, r, 1.21 * PI, 2.78 * PI)
      ctx.lineTo(x + l, y + l)
      ctx.lineTo(x, y + l)
      ctx.arc(x + r - 2, y + l / 2, r + 0.4, 2.76 * PI, 1.24 * PI, true)
      ctx.lineTo(x, y)
      ctx.lineWidth = 2
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)'
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)'
      ctx.stroke()
      ctx.globalCompositeOperation = 'destination-over'
      operation === 'fill' ? ctx.fill() : ctx.clip()
    }
    useEffect(() => {
      if (imgUrl) {
        initImg()
      }
    }, [imgUrl])
    const createImg = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(img)
        img.onerror = () => reject(new Error(`FAIled to load image error`))
      })
    }

    const draw = (images: any) => {
      const canvasCtx = canvasRef?.current?.getContext('2d')
      const blockCtx = blockRef?.current?.getContext('2d')
      // 随机位置创建拼图形状
      // xRef.current = getRandomNumberByRange(L + 10, width - (L + 10));
      // yRef.current = getRandomNumberByRange(10 + r * 2, height - (L + 10));
      // drawPath(canvasCtx, xRef.current, yRef.current, 'fill');
      // drawPath(blockCtx, xRef.current, yRef.current, 'clip');
      // 画入图片
      canvasCtx.drawImage(images[0], 0, 0, width, height)
      blockCtx.drawImage(images[1], 0, 0, image_width, image_height)

      // 提取滑块并放到最左边
      const y1 = r * 2 - 1
      const ImageData = blockCtx.getImageData(3, y1, L, L)
      blockCtx.putImageData(ImageData, 0, y1)
    }

    const initImg = async () => {
      Promise.all([await createImg(imgUrl), await createImg(image)]).then((images) => {
        setLoading(false)
        draw(images)
        imgRef.current = images[0]
      })
    }

    const reset = () => {
      const canvasCtx = canvasRef?.current?.getContext('2d')
      const blockCtx = blockRef?.current?.getContext('2d')
      // 重置样式
      setSliderLeft(0)
      setSliderClass('sliderContainer')
      blockRef.current.width = image_width
      blockRef.current.style.left = 0 + 'px'

      // 清空画布
      canvasCtx.clearRect(0, 0, width, height)
      blockCtx.clearRect(0, 0, image_width, image_height)
    }

    const handleDragStart = function (e: any) {
      originXRef.current = e.clientX || e.touches[0].clientX
      originYRef.current = e.clientY || e.touches[0].clientY
      isMouseDownRef.current = true
      setStartTime(new Date())
      setTrackList([{ x: 0, y: 0, t: 0, type: 'down' }]) // 初始化轨迹
    }

    const handleDragMove = (e: any) => {
      if (!isMouseDownRef.current) return false // 忽略无效拖动
      e.preventDefault()
      const currentTime: any = new Date()
      const elapsedTime = currentTime - startTime // 计算时间差
      const eventX = e.clientX || e.touches[0].clientX
      const eventY = e.clientY || e.touches[0].clientY
      const moveX = eventX - originXRef.current
      const moveY = eventY - originYRef.current
      if (moveX < 0 || moveX + 30 >= width) return false
      setSliderLeft(moveX)
      const blockLeft = ((width - 30 - 20) / (width - 30)) * moveX
      blockRef.current.style.left = blockLeft + 'px'
      const moveLeft = blockLeft.toFixed(2)
      setTrackList((prevTrackList) => [
        ...prevTrackList,
        { x: Number(moveLeft), y: 0, t: elapsedTime, type: 'move' }
      ])
      setSliderClass('sliderContainer sliderContainer_toward')
      trailRef.current.push(moveY)
      onDraw && onDraw(blockLeft)
    }

    const handleDragEnd = (e: any) => {
      if (!isMouseDownRef.current) return false
      const endX = e.clientX // 记录滑块结束位置
      const currentTime: any = new Date()
      const elapsedTime = currentTime - startTime // 计算总时间
      isMouseDownRef.current = false
      const eventX = e.clientX || e.changedTouches[0].clientX
      if (eventX === originXRef.current) return false
      setSliderClass('sliderContainer sliderContainer_toward')
      setTrackList((prevTrackList) => [
        ...prevTrackList,
        { x: endX - originXRef.current, y: 0, t: elapsedTime, type: 'up' }
      ])
      const trackData = {
        captchaId: id,
        captchaTrack: {
          bgImageWidth: width, // 从后台获取背景图宽度
          bgImageHeight: height, // 从后台获取背景图高度
          startTime: startTime.toISOString(),
          stopTime: currentTime.toISOString(),
          trackList: trackList
        }
      }
      // 判断当前是否是获取验证码状态
      if (captchaStatus) {
        onCaptchaSuccess(trackData)
      } else {
        onSuccess(trackData)
      }
    }
    // 延迟刷新
    const delayedLoading = () => {
      setTimeout(() => {
        reset()
      }, 500)
    }
    const handleRefresh = () => {
      reset()
      onRefresh()
    }
    useEffect(() => {
      if (status === 'success') {
        setSliderClass('sliderContainer sliderContainer_success')
        delayedLoading()
      }
      if (status === 'refreshError') {
        setSliderClass('sliderContainer sliderContainer_error')
        delayedLoading()
      }

      setTipStatus(status)
    }, [status])
    // 监听打开状态
    useEffect(() => {
      setOpen(callOpen)
    }, [callOpen])

    return (
      <AlertDialog
        open={open}
        onOpenChange={(e) => {
          console.info(e)
          // onOpenChange && onOpenChange(e)
        }}
      >
        <AlertDialogPortal>
          <AlertDialogContent className="w-[358px] p-0 gap-0 border-0">
            <AlertDialogTitle
              className="flex px-4 pt-[1.13rem] pb-[0.808rem] items-center justify-between border-b border-[#ECECEC]"
              style={{ background: `url(${code_bg}) no-repeat`, backgroundSize: 'cover' }}
            >
              <h1 className="text-[#1D223C] text-sm">请完成安全验证</h1>
              <Cross2Icon onClick={onFail} className="h-4 w-4 cursor-pointer" />
            </AlertDialogTitle>
            <div
              className="vertifyWrap w-full"
              style={{
                // width: width + 'px',
                display: visible ? '' : 'none'
              }}
              onMouseMove={handleDragMove}
              onMouseUp={handleDragEnd}
              onTouchMove={handleDragMove}
              onTouchEnd={handleDragEnd}
            >
              <div className="canvasArea relative">
                <article
                  onClick={handleRefresh}
                  className="refresh absolute w-[32px] z-[999] h-[32px] bg-[rgba(255,255,255,0.4)] top-[4px] right-[4px] flex items-center justify-center rounded-md"
                >
                  <ReactSVG src={refresh} />
                </article>
                {tipStatus === 'loading' && (
                  <div
                    className="flex items-center flex-col justify-center absolute"
                    style={{ width: width, height: height }}
                  >
                    <ReactSVG src={loadings} />
                    <p className="text-[#777A8A] text-sm pt-4">{tipStatusObj[tipStatus]}</p>
                  </div>
                )}
                <canvas ref={canvasRef} width={width} height={height}></canvas>
                <canvas
                  ref={blockRef}
                  className="block"
                  width={width}
                  height={height}
                  onMouseDown={handleDragStart}
                  onTouchStart={handleDragStart}
                ></canvas>
              </div>
              <div
                className={sliderClass}
                style={{
                  pointerEvents: isLoading ? 'none' : 'auto',
                  width: width + 'px'
                }}
              >
                <div className="sliderMask" style={{ width: sliderLeft + 'px' }}>
                  <div
                    className="slider"
                    style={{ left: sliderLeft + 'px' }}
                    onMouseDown={handleDragStart}
                    onTouchStart={handleDragStart}
                  >
                    <ReactSVG
                      className="absolute"
                      src={tipStatusObj[tipStatus] === 'success' ? tickSuccessfully : arrows}
                    />
                  </div>
                </div>
                <div className="sliderText text-sm h-full text-center pl-[3rem]">
                  {tipStatusObj[tipStatus]}
                </div>
              </div>
              {/*<div*/}
              {/*  className="refreshIcon"*/}
              {/*  onClick={handleRefresh}*/}
              {/*  style={{ backgroundImage: `url(${refreshIcon})` }}*/}
              {/*></div>*/}
            </div>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    )
  }
)
