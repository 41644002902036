import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 批量删除facebook主页
 * @param param
 * @returns
 */
export const fetchDeleteFacebookHome = async (param): Promise<ApiResp> => {
  const { data } = await httpClient.delete<ApiResp>(`/bg/messenger/business/ids`, {
    data: param
  })

  return serialize(data)
}
