import { Button, CardContent, CardFooter, Input, PasswordInput } from '@brisk/ui'
import { FC, useContext, useEffect } from 'react'
import { SignInHeader } from './components/SignInHeader'
import { useSignStore } from '../../store/useSignStore'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { mutationCreateTenant } from '../../api'
import { omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '../../router'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string } from 'zod'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'
import { RESPONSE_CODE } from '@brisk/api'
import { SignInContext } from '.'
import { useTranslation } from 'react-i18next'
import building from '../../assets/svg/building.svg'
import nickName from '../../assets/svg/nickName.svg'
import newPassword from '../../assets/svg/newPassword.svg'
import { ReactSVG } from 'react-svg'

interface IFormInput {
  tenantName: string
  nickName: string
  password: string
  validPassword: string
}

const defaultFormValues: IFormInput = {
  tenantName: '',
  nickName: '',
  password: '',
  validPassword: ''
}

export const CreateTenant: FC = () => {
  const navigate = useNavigate()
  const { tempToken } = useSignStore()
  const { t } = useTranslation()

  const { setLoading } = useContext(SignInContext)

  const { mutateAsync: mutateAsyncCreateTenant, isPending } = useMutation({
    mutationFn: mutationCreateTenant
  })
  const schema = object({
    tenantName: string().min(1, t('login.pleaseenterthecompanyabbreviation')),
    nickName: string().min(1, t('login.pleaseenteryourname')),
    password: string()
      .min(1, t('login.pleaseenteryourpassword'))
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters')),
    validPassword: string()
      .min(1, t('login.pleaseenteryourpassword'))
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters'))
  })
  //-----------------------------------------------------------------------
  // form
  //-----------------------------------------------------------------------
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    mode: 'onSubmit'
  })

  useErrorHinter([errors.tenantName, errors.nickName, errors.password, errors.validPassword])

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    if (formData?.password !== formData?.validPassword) {
      control.setError('validPassword', { message: t('login.twopasswordsaredifferent') })
      return
    }
    try {
      const res = await mutateAsyncCreateTenant({
        nickName: formData.nickName,
        password: formData.password,
        tempToken: tempToken!,
        tenantName: formData.tenantName
      })
      if (res?.code === RESPONSE_CODE.success) {
        useSignStore.getState().setCurrentTenant({
          ...useSignStore.getState().currentTenant,
          tenantId: res?.data?.tenantId,
          tenantName: formData.tenantName
        })

        navigate(APP_ROUTER_PATH.SIGNIN_BY_PASSPORT)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => setLoading(isPending), [isPending])

  return (
    <>
      <SignInHeader title={t('login.improveEnterpriseinformation')} />
      <CardContent className="">
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <div className="relative">
            <Controller
              name="tenantName"
              control={control}
              render={({ field }) => (
                <Input
                  maxLength={50}
                  className="h-12 pl-10 relative"
                  type="text"
                  placeholder={t('login.pleaseenterthecompanyabbreviation')}
                  {...field}
                />
              )}
            />
            <ReactSVG className="absolute top-3.5 left-3.5" src={building} />
          </div>
          <div className="relative">
            <Controller
              name="nickName"
              control={control}
              render={({ field }) => (
                <Input
                  maxLength={20}
                  className="h-12 pl-10"
                  type="text"
                  placeholder={t('login.pleaseenteryourname')}
                  {...omit(field, 'value')}
                />
              )}
            />
            <ReactSVG className="absolute top-3.5 left-3.5" src={nickName} />
          </div>
          <div className="relative">
            <div className="text-base pb-2 font-normal">
              {t('login.pleasesettheenterprisepassword')}
            </div>
            <div className="text-gray-400 text-sm">{t('common.passwordcontainscharacters')}</div>
          </div>
          <div className="relative">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  className="h-12 pl-10"
                  placeholder={t('login.pleasesetpassword')}
                  {...field}
                />
              )}
            />
            <ReactSVG className="absolute top-3.5 left-3.5" src={newPassword} />
          </div>
          <div className="relative">
            <Controller
              name="validPassword"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  className="h-12 pl-10"
                  placeholder={t('login.confirmTenantPassword')}
                  {...field}
                />
              )}
            />
            <ReactSVG className="absolute top-3.5 left-3.5" src={newPassword} />
          </div>

          <Button type="submit" className="w-full mt-4 h-12">
            {t('login.newtenant')}
          </Button>
        </form>
      </CardContent>

      <CardFooter className="flex justify-between"></CardFooter>
    </>
  )
}
