import {FC} from 'react'
import {TextInputInterface} from "./type";
import {ReactSVG} from "react-svg";
import edit from '../../assets/svg/edit_i.svg';
import {cn} from "@brisk/ui";
import {useTranslation} from "react-i18next";

const TextInput: FC<TextInputInterface> = ({
  label,
  value = '未绑定',
  modalities = 'noEditable',
  type,
  editStatus = false,
  onClick
}) => {
  const { t } = useTranslation()
  function onEditClick() {
    onClick(type)
  }

  return (
    <div className="w-full pb-6">
      <p className="pb-2 font-medium text-sm text-[rgba(29,34,60,1)]">{label}</p>
      <div className="flex items-center">
        {modalities == 'noEditable' ?
          <div><span className='pr-2 text-sm text-[rgba(165,167,177,1)]'>{value ? value : t('accountInformation.unbound')}</span>{editStatus ? <ReactSVG onClick={onEditClick} src={edit}/> : <></>}</div> :
          <div><span className={cn('pr-2 text-sm', !value ? 'text-[rgba(165,167,177,1)]' : 'text-[rgba(29,34,60,1)]')}>{value ? value : t('accountInformation.unbound')}</span>{editStatus ? <ReactSVG onClick={onEditClick} src={edit} /> : <></>}</div>
        }
      </div>
    </div>
  )
}

export default TextInput
