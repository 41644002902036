import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { TerminalType } from '../model'
import { User } from '../../model/data/user'
import { trimAll } from '../../utils'

export interface RequestBodyCaptcha {
  // tenantId: string
  account: string
  captcha?: string
  terminalType: TerminalType
  tempToken?: string
  imageCaptcha?: string
}

interface ApiResp extends ClientResponse {
  data: User
}
// interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}
// 验证码登录
export const mutationSignInByCaptcha = async (payload: RequestBodyCaptcha): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/login/login-captcha`, {
    ...payload,
    account: trimAll(payload.account)
  })

  return serialize(data)
}
