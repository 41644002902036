import { IChatList } from '@renderer/api/chatList'
import { juggle } from '@renderer/lib/sdk/juggle'
// import { useQuery } from '@tanstack/react-query'
import { filter, find, findIndex, forEach, set } from 'lodash'

/**
 *当前用户未读总数
 * @returns
 */
export const useUnreadCount = (): number | undefined => {
  // const { data: unreadCount } = useQuery<number>({
  //   queryKey: [juggle.getTotalUnreadcount.name],
  //   queryFn: () => juggle.getTotalUnreadcount().then(({ count }) => count),
  //   refetchInterval: 5_000
  // })
  // return unreadCount
  return undefined
}

/**
 * 获取全部会话
 */
export const getConversations = async (
  option = {}
): Promise<{ conversations: IChatList[]; isFinishd: boolean }> => {
  const { conversations, isFinishd } = await juggle.getConversations(option)
  const filterConversations: IChatList[] = []
  forEach(conversations, (item) => {
    const already = find(filterConversations, (it) => it.conversationId === item.conversationId)
    if (already) {
      const i = findIndex(filterConversations, (it) => it.conversationId === item.conversationId)
      if (already.latestMessage.sentTime > item.latestMessage.sentTime) {
        set(filterConversations, i, {
          ...item,
          latestMessage: {
            ...filterConversations[i].latestMessage,
            ...already.latestMessage
          }
        })
      } else {
        set(filterConversations, i, {
          ...filterConversations[i],
          latestMessage: {
            ...already.latestMessage,
            ...filterConversations[i].latestMessage
          }
        })
      }
    } else {
      filterConversations.push(item)
    }
  })
  return {
    conversations: filter(filterConversations, (item) => item?.conversationId !== '-1'), // 系统消息
    isFinishd
  }
}
