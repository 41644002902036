import { create } from 'zustand'

type State = {
  connectStatusCode?: number
  connectStatus?: boolean
}

type Action = {
  setConnectStatus: (status: boolean) => void
  setConnectStatusCode: (code: number) => void
}

export const useConnectStatus = create<State & Action>((set, get) => ({
  connectStatus: false,
  connectStatusCode: 0,
  setConnectStatus: (connectStatus: boolean): void => {
    set({ connectStatus })
  },
  setConnectStatusCode: (connectStatusCode: number): void => {
    set({ connectStatusCode })
  }
}))
