import { FC, useContext, useEffect } from 'react'
import { Button, CardContent, cn } from '@brisk/ui'
import HeaderNav from './components/HeaderNav'
import { inputIconClassName } from '../../utils/consts'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { message } from '../../components/message'
import { object, string } from 'zod'
import { useMutation } from '@tanstack/react-query'
import {
  fetchSendResetPasswordCaptcha,
  fetchSendTempCaptcha,
  mutationTempSignInByCaptcha,
  mutationValidResetPasswordCaptcha
} from '../../api'
import { useSignStore } from '../../store/useSignStore'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useCaptchaCountdown } from '../../hooks/useCaptchaCountdown'
import { ClientResponse } from '../../http-client/httpClient'
import { TerminalType } from '../../api/model'
import { NoFocusInput } from './components/NoFocusInput'
import { ReloadIcon } from '@radix-ui/react-icons'
import { ReactSVG } from 'react-svg'
import captacha from '../../assets/svg/captacha.svg'
import { SignInContext } from '.'
import { useTranslation } from 'react-i18next'
import { regEmail } from '../../utils'
import { useGraphicalVerification } from './hooks/graphicalVerification'
import { fetchLoginCaptchaCheck } from '@brisk/api'
import { trackDataType } from './InputAccount'
import GraphicalVerification from './components/graphicalVerification/graphicalVerification'

export enum TCaptchaValidType {
  'RESET_PASSWORD' = 'RESET_PASSWORD',
  'TEMP_SIGN_IN' = 'TEMP_SIGN_IN'
}

type IFormInput = {
  captcha: string
}

const defaultFormValues: IFormInput = {
  captcha: ''
}

//-----------------------------------------------------------------------
// valid
//-----------------------------------------------------------------------

interface Resp extends ClientResponse {
  data: {
    tempToken?: string
  }
}
export const CaptchaValid: FC = () => {
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')
  const captchaValidType = searchParams.get('captchaValidType')
  const { setLoading } = useContext(SignInContext)
  const { account, accountType, setTempToken } = useSignStore()
  const navigate = useNavigate()
  const { isCounting, startCount, count, isSentCaptcha, reset } = useCaptchaCountdown()
  const {
    setCaptchaStatus,
    status,
    setStatus,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    callOpen,
    initFetchSliderCaptcha
  } = useGraphicalVerification()
  //-----------------------------------------------------------------------
  // define query
  //-----------------------------------------------------------------------
  const { mutateAsync: maSendResetPasswordCaptcha, isPending: isPendingSendCaptcha } = useMutation({
    mutationFn: fetchSendResetPasswordCaptcha
  })
  const { mutateAsync: maValidResetPasswordCaptcha, isPending: passwordLoading } = useMutation({
    mutationFn: mutationValidResetPasswordCaptcha
  })
  const { mutateAsync: fetchAsyncLoginCaptchaCheck } = useMutation({
    mutationFn: fetchLoginCaptchaCheck
  })
  // 验证码临时登录
  const { mutateAsync: maSendTempCaptcha, isPending: isPendingSendTempCaptcha } = useMutation({
    mutationFn: fetchSendTempCaptcha
  })
  const { mutateAsync: maTempSignInByCaptcha, isPending: captchaLoading } = useMutation({
    mutationFn: mutationTempSignInByCaptcha
  })

  const { t } = useTranslation()
  const schema = object({
    captcha: string().min(6, t('login.pleaseenterverificationcode'))
  })
  //-----------------------------------------------------------------------
  // form
  //-----------------------------------------------------------------------
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema)
  })
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    let data: Resp

    if (captchaValidType === TCaptchaValidType.RESET_PASSWORD) {
      data = await maValidResetPasswordCaptcha({
        type: accountType,
        account: account,
        captcha: formData.captcha
      })
    } else {
      data = await maTempSignInByCaptcha({
        type: accountType,
        account: account,
        captcha: formData.captcha,
        terminalType: TerminalType.PC
      })
    }

    if (!data.data.tempToken) {
      message(t('login.graphicverificationcodeerror'), 'error')
      return
    }

    setTempToken(data.data.tempToken)
    console.log(redirect)
    navigate(redirect!)
  }
  // 刷新
  const trackRefresh = async () => {
    if (callOpen) {
      await initFetchSliderCaptcha()
      return
    }
    setCallOpen(true)
    await initFetchSliderCaptcha()
  }
  const sendResetPassword = async (sendResetPasswordCaptcha) => {
    try {
      let resp: ClientResponse
      if (captchaValidType === TCaptchaValidType.RESET_PASSWORD) {
        resp = await maSendResetPasswordCaptcha(sendResetPasswordCaptcha)
      } else {
        resp = await maSendTempCaptcha(sendResetPasswordCaptcha)
      }
      if (resp.code !== 10000) {
        message(resp.msg, 'error')
        reset()
        return
      }
      startCount()
    } catch (e) {
      setCaptchaStatus(false)
    }
  }
  // 手机验证码调用
  const onCaptchaSuccess = (tackData: trackDataType) => {
    handleClickSendCaptcha(tackData)
  }
  // 验证码
  const handleClickSendCaptcha = async (tackData?: trackDataType): Promise<void> => {
    if (isCounting) {
      return
    }
    const sendResetPasswordCaptcha = {
      account: account,
      type: accountType,
      token: ''
    }
    if (!regEmail.test(account)) {
      setStatus('initialise')
      trackRefresh()
      setCaptchaStatus(true)
      const data = await fetchAsyncLoginCaptchaCheck({ phone: account, ...tackData })
      if (data?.code === 10012) {
        setStatus('refreshError')
        message(data?.msg, 'error')
      }
      if (data?.code === 10000) {
        setStatus('success')
        sendResetPasswordCaptcha.token = data?.data
        setTimeout(() => {
          setCallOpen(false)
        }, 500)
        sendResetPassword(sendResetPasswordCaptcha)
      }
    } else {
      delete sendResetPasswordCaptcha.token
      sendResetPassword(sendResetPasswordCaptcha)
    }
  }
  // 关闭滑动弹窗
    const handleFail = () => {
    setCallOpen(false)
    setCaptchaStatus(false)
  }
  useEffect(() => setLoading(passwordLoading || captchaLoading), [passwordLoading, captchaLoading])

  return (
    <>
      <GraphicalVerification
        captchaStatus={true}
        onCaptchaSuccess={onCaptchaSuccess}
        onFail={handleFail}
        onRefresh={trackRefresh}
        callOpen={callOpen}
        {...backgroundImage}
        {...jigsawImage}
        status={status}
      />
      <HeaderNav title={t('login.pleaseenterverificationcode')} />
      <CardContent className="flex-1 justify-start flex flex-col">
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <p
            className="text-sm text-lingheGray text-left mb-4 max-w-[327px]"
            style={{
              wordWrap: 'break-word'
            }}
          >
            {t('login.pleaseentervalidverificationcode', { msg: account })}
          </p>

          <Controller
            name="captcha"
            control={control}
            render={({ field }) => (
              <div className="border w-full h-12 radios rounded-sm flex relative">
                <ReactSVG src={captacha} className={inputIconClassName} />
                <NoFocusInput
                  maxLength={6}
                  placeholder={t('login.pleaseenterverificationcode')}
                  type="text"
                  pattern="^(\+)?[0-9\s]*$"
                  className={cn(
                    'flex-1 h-full pl-9 text-sm border-none outline-none',
                    errors.captcha ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring'
                  )}
                  {...field}
                />
                <Button
                  variant="link"
                  type="button"
                  className="h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline"
                  onClick={handleClickSendCaptcha}
                  disabled={isPendingSendTempCaptcha || isPendingSendCaptcha}
                >
                  {(isPendingSendTempCaptcha || isPendingSendCaptcha) && (
                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  )}

                  <span className="absolute left-0 text-border">|</span>
                  <span>
                    {isCounting
                      ? `${count}s`
                      : isSentCaptcha
                        ? t('common.resend')
                        : t('login.sendverificationcod')}
                  </span>
                </Button>
              </div>
            )}
          />
          {errors.captcha && (
            <p className="bottom-8 text-lingheError text-sm">
              {t('login.verificationcodeincorrect')}
            </p>
          )}

          <Button type="submit" className="w-full mt-4 h-12">
            {t('login.next')}
          </Button>
        </form>
      </CardContent>
    </>
  )
}
