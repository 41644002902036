import {
  FaceBookHomeBusinessVO,
  IFaceboockHomeResp,
  fetchChangeFacebookRemark,
  fetchDeleteFacebookHome,
  fetchFacebookEnable,
  fetchFacebookHomePageList
} from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { IPageationRef } from '../AddCustomer'
import { useMutation } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { IParamForPage } from '@renderer/utils/consts'
import { Avatar, AvatarFallback, AvatarImage, Button, Checkbox, Switch } from '@brisk/ui'
import { Plus, User } from 'lucide-react'
import dayjs from 'dayjs'
import { ReactSVG } from 'react-svg'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { message } from '@renderer/components/message'
import UpdateRemark from './UpdateRemark'
import DelCustolerModal from '../Components/DelCustolerModal'
import { map } from 'lodash'
import Loading from '@renderer/components/Loading'
import { ManagerTable, ManagerTableRef } from '@renderer/components/ManagerTable'
import {useTranslation} from "react-i18next";

interface IHomeProps {
  rowSelect: FaceBookHomeBusinessVO[]
  setRowSelect: (rows: FaceBookHomeBusinessVO[]) => void
  show: boolean
  setShow: (v: boolean) => void
  oauthUrl: string
}

const FacebookHomePage: FC<IHomeProps> = ({ rowSelect, setRowSelect, show, setShow, oauthUrl }) => {
  const [listData, setListData] = useState<IFaceboockHomeResp>()
  const { t } = useTranslation()
  const [updateData, setUpdateData] = useState<{
    data: FaceBookHomeBusinessVO | null
    open: boolean
  }>({
    open: false,
    data: null
  })

  const tableRef = useRef<ManagerTableRef>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getFacebookHomeList, isPending } = useMutation({
    mutationFn: fetchFacebookHomePageList
  })

  const { mutateAsync: changeFacebookRemark } = useMutation({
    mutationFn: fetchChangeFacebookRemark
  })

  const { mutateAsync: changeFacebookEnable } = useMutation({
    mutationFn: fetchFacebookEnable
  })

  const { mutateAsync: deleteFacebook, isPending: delLoading } = useMutation({
    mutationFn: fetchDeleteFacebookHome
  })

  const handleGetFacebookHomeList = async (param: IParamForPage): Promise<void> => {
    try {
      const res = await getFacebookHomeList(param)
      if (res?.code === RESPONSE_CODE.success) {
        setListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const columns: ColumnDef<FaceBookHomeBusinessVO>[] = useMemo(
    () => [
      {
        id: 'businessName',
        header: ({ table }) => (
          <div className="flex items-center gap-3">
            <Checkbox
              id="Select all"
              checked={table.getIsAllPageRowsSelected()}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
            <label htmlFor="Select all">{t('socialMediaAccount.PageName')}</label>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-3 h-full text-ellipsis overflow-hidden whitespace-nowrap">
            <Checkbox
              id={row?.original?.configId + ''}
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
            <label htmlFor={row?.original?.configId + ''} className="flex items-center gap-0.5">
              <Avatar className="hidden h-7 w-7 sm:flex flex items-center">
                <AvatarImage
                  alt="Avatar"
                  src={row?.original?.businessAvatar}
                  className="rounded-full"
                />
                <AvatarFallback>
                  <User className="" />
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col ">
                <span>{row?.original?.businessName}</span>
                <span className="text-lingheGray">{row?.original?.businessId}</span>
              </div>
            </label>
          </div>
        ),
        fixed: 'left'
      },
      {
        id: 'accountName',
        style: {
          width: 300
        },
        header: () => <div>{t('socialMediaAccount.AccountName')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex flex-col">
            <span>{original?.accountName}</span>
            <span className="text-lingheGray">{original?.accountId}</span>
          </div>
        )
      },
      {
        id: 'remark',
        header: () => <div>{t('socialMediaAccount.Notes')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[300px] line-clamp-2 break-words overflow-hidden text-ellipsis">
            {original?.remark}
          </div>
        )
      },
      {
        id: 'lastConsultingTime',
        header: () => <div>{t('socialMediaAccount.LastContact')}</div>,
        cell: ({ row: { original } }) => (
          <div>{dayjs(original?.lastConsultingTime).format('YYYY-MM-DD HH:mm:ss')}</div>
        )
      },
      {
        id: 'edit',
        style: {
          width: 148
        },
        header: () => (
          <div className="flex items-center justify-between w-[148px]">
            <span>{t('socialMediaAccount.Enable')}</span>
            <span>{t('socialMediaAccount.Actions')}</span>
          </div>
        ),
        cell: ({ row: { original } }) => (
          <div className="flex items-center justify-between w-[148px]">
            <Switch
              checked={Boolean(+original?.state)}
              onCheckedChange={async (e) => {
                try {
                  const res = await changeFacebookEnable({
                    state: e ? '1' : '0',
                    id: original?.businessPageId + ''
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    message(`${e ? t('socialMediaAccount.Done') : t('socialMediaAccount.disableDone')}`, 'success')
                    handleGetFacebookHomeList({
                      pageNum: pageationRef?.current?.currentPage || 1,
                      pageSize: pageationRef?.current?.itemsPerPage || 10
                    })
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            />
            <Button
              variant="link"
              className="hover:no-underline p-0"
              onClick={() => setUpdateData({ open: true, data: original })}
            >
                {t('socialMediaAccount.Notes')}
            </Button>
          </div>
        ),
        fixed: 'right'
      }
    ],
    []
  )

  useEffect(() => {
    handleGetFacebookHomeList({
      pageNum: 1,
      pageSize: 10
    })
  }, [])

  return (
    <div className="w-full h-full overflow-hidden text-sm">
      {(isPending || delLoading) && <Loading />}
      <ManagerTable<FaceBookHomeBusinessVO>
        ref={tableRef}
        pageationRef={pageationRef}
        data={listData?.records || []}
        columns={columns}
        rowCount={listData?.total || 0}
        onPageChange={(pageNum, pageSize) => handleGetFacebookHomeList({ pageNum, pageSize })}
        onRowSelectionChange={(rows) => setRowSelect(rows)}
        emptyElement={() => (
          <div className="flex items-center justify-center pt-20">
            <div className="flex flex-col items-center justify-center gap-2">
              <ReactSVG src={noSearchResult} />
              <span className="mb-2 text-sm text-lingheTextGray">{t('socialMediaAccount.NoFacebookPageyetpleaseaddonefirst')}</span>
              <Button onClick={() => window.open(oauthUrl, '__blank')}>
                <Plus size={16} />
                  {t('socialMediaAccount.AuthorizeFBAccount')}
              </Button>
              {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
                如何授权 FB messenger？
              </Button> */}
            </div>
          </div>
        )}
      />

      <UpdateRemark
        open={updateData?.open}
        data={updateData?.data}
        onClose={() => setUpdateData({ open: false, data: null })}
        onConfirm={async (remark) => {
          try {
            const res = await changeFacebookRemark({
              id: updateData?.data?.businessPageId + '',
              remake: remark
            })
            if (res?.code === RESPONSE_CODE.success) {
              message(t('accountInformation.modifiedsuccessfully'), 'success')
              handleGetFacebookHomeList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setUpdateData({ open: false, data: null })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      <DelCustolerModal
        title={t('common.Prompt')}
        open={show}
        onClose={() => setShow(false)}
        onConfirm={async () => {
          try {
            const res = await deleteFacebook(map(rowSelect, (it) => it?.businessPageId))
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Deletedsuccessfully'), 'success')
              handleGetFacebookHomeList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setShow(false)
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center">
              {t('socialMediaAccount.Afterdeletionyourwebsitewilllongerbeableinitiateonlineconsultations')}
            <br />
              {t('socialMediaAccount.Werecommendthatyou')}
            <Button
              variant="link"
              className="hover:no-underline p-0"
              onClick={async () => {
                try {
                  const res = await changeFacebookEnable({
                    state: '0',
                    id: rowSelect[0]?.businessPageId + ''
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    message(t('socialMediaAccount.disableDone'), 'success')
                    handleGetFacebookHomeList({
                      pageNum: pageationRef?.current?.currentPage || 1,
                      pageSize: pageationRef?.current?.itemsPerPage || 10
                    })
                    setShow(false)
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            >
              ”{t('socialMediaAccount.Deactivate')}“
            </Button>
              {t('socialMediaAccount.Deactivate')}
            <br />
          </div>
        }
      />
    </div>
  )
}

export default memo(FacebookHomePage)
