import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * ReceptionConfigVO
 */
export interface IAgentConfig {
  /**
   * 单个坐席最大接待数
   */
  maxReception?: number
  /**
   * 排队提醒话术
   */
  queuingPrompt?: string
}

interface ApiResp extends ClientResponse {
  data: IAgentConfig
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取坐席接待配置
 * @returns
 */
export const fetchAgentConfig = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/config/reception`)

  return serialize(data)
}
