import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * CustomerStatisticsQueryDTO
 */
export interface IDataCustomerPageListRequest {
  /**
   * 结束时间
   */
  endDate?: Date;
  pageNum?: number;
  pageSize?: number;
  /**
   * 开始时间
   */
  startDate?: Date;
  /**
   * 用户id，page搜索时才需要
   */
  userId?: number | string;
}

/**
 * PageReceptionStatisticsPageVO
 */
export interface IDataCustomerPageListRes {
  countId?: string;
  current?: number;
  pages?: number;
  records?: IDataCustomerPageList[];
  size?: number;
  total?: number;
}

/**
 * ReceptionStatisticsPageVO
 */
export interface IDataCustomerPageList {
  id: number
  /**
   * 被分配会话数
   */
  allocationSessionNum?: number;
  /**
   * 坐席头像
   */
  avatarUrl?: string;
  /**
   * 转入会话数
   */
  intoSessionNum?: number;
  /**
   * 坐席名称
   */
  nickname?: string;
  /**
   * 新访客数
   */
  receptionNewNum?: number;
  /**
   * 接待访客数
   */
  receptionNum?: number;
  /**
   * 常访客数
   */
  receptionOldNum?: number;
  /**
   * 主动接待会话数
   */
  receptionSessionNum?: number;
  /**
   * 接待会话数
   */
  sessionNum?: number;
  /**
   * 坐席状态
   */
  status?: string;
  /**
   * 转出会话数
   */
  transferSessionNum?: number;
  /**
   * userId
   */
  userId?: number | string;
}

interface ApiResp extends ClientResponse {
  data: IDataCustomerPageListRes;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 坐席接待明细分页查询
 * @param param
 * @returns
 */
export const fetchDataCustomerPageList = async (
  param: IDataCustomerPageListRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/customer/statistics/reception/page`,
    param,
  );

  return serialize(data);
};
