import { ClientResponse, httpTenantClient } from '../../http-client/httpTenantClient'

// interface RequestParams {}

interface ApiResp extends ClientResponse {
  data: {
    encryptTenantCode: string
    tenantAddressList: { domainAddress: string; addressType: number }[]
    tenantCode: string
    tenantId: number
    tenantName: string
  }
}
interface Serialize {
  (resp: ApiResp): ApiResp
}

interface RequestParams {
  tenantCode: string
  productCode: string
  signature: string
  timestamp: number
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data

  return resp
}

// 注销所有登录用户
export const fetchPublicSign = async (param: RequestParams): Promise<ApiResp> => {
  const { data } = await httpTenantClient.get<ApiResp>(
    `/tenant/findTenant?tenantCode=${param.tenantCode}`,
    {
      headers: {
        productCode: param.productCode,
        signature: param.signature,
        timestamp: param.timestamp
      }
    }
  )

  return serialize(data)
}
