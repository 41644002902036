import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * SessionCompleteDTO
 */
export interface IShutdownChatRequest {
  /**
   * 会话编号
   */
  sessionId?: number;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 完结会话-客服
 * @param param
 * @returns
 */
export const fetchShutdownChat = async (
  param: IShutdownChatRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/session/complete`, param);

  return serialize(data);
};
