import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { fetchSliderCaptcha } from '@brisk/api'

export function useGraphicalVerification() {
  const [backgroundImage, setBackgroundImage] = useState({
    id: '',
    imgUrl: '',
    width: 303,
    height: 160
  })
  const [jigsawImage, setTemplateImage] = useState({
    image: '',
    image_width: 55,
    image_height: 160
  })
  const [status, setStatus] = useState('loading')
  const { mutateAsync: onFetchSliderCaptcha } = useMutation({
    mutationFn: fetchSliderCaptcha
  })
  const [callOpen, setCallOpen] = useState(false)
  const [captchaStatus, setCaptchaStatus] = useState(false) // 是否验证码状态

  useEffect(() => {
    if (status === 'refreshError') {
      setTimeout(() => {
        setStatus('loading')
        initFetchSliderCaptcha()
      }, 500)
    }
  }, [status])

  const initFetchSliderCaptcha = async () => {
    const data = await onFetchSliderCaptcha()
    const captcha = data.data.captcha
    const width =
      captcha.backgroundImageWidth / parseInt(String(captcha.backgroundImageWidth / 302))
    const height =
      captcha.backgroundImageHeight / parseInt(String(captcha.backgroundImageHeight / 160))
    const image_width =
      captcha.templateImageWidth / parseInt(String(captcha.templateImageWidth / 55))
    const image_height =
      captcha.templateImageHeight / parseInt(String(captcha.templateImageHeight / 160))
    setBackgroundImage({
      id: data.data.id,
      imgUrl: captcha.backgroundImage,
      width: width,
      height: height
    })
    setTemplateImage({
      image: captcha.templateImage,
      image_width: image_width,
      image_height: image_height
    })
    setStatus('initialise')
  }
  return {
    captchaStatus,
    setCaptchaStatus,
    setStatus,
    status,
    callOpen,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    initFetchSliderCaptcha
  }
}
