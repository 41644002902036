import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 删除标签
 * @param params
 * @returns
 */
export const fetchDelTag = async (id: number | string): Promise<ApiResp> => {
  const { data } = await apiClient.delete<ApiResp>(`/bg/tag/${id}`);

  return serialize(data);
};
