import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea
} from '@brisk/ui'
import { FC, memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Controller, useForm } from 'react-hook-form'
import close from '@renderer/assets/svg/close.svg'
import deleteImg from '@renderer/assets/svg/deleteImg.svg'
import { find, map } from 'lodash'
import { Plus } from 'lucide-react'
import { useUploadFileManage } from '@renderer/hooks/manager/useUploadFileManage'
import { message } from '@renderer/components/message'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'
import { SavePersonWordsRequest } from '@brisk/api'
import { MessageInfoContext } from '../../Home'
import { useTranslation } from 'react-i18next'
import { MAX_INP_LENGTH, maxImageSize } from '@renderer/utils/consts'

interface IAddWords {
  data: SavePersonWordsRequest | null
  open: boolean
  onClose?: () => void
  onConfirm?: (data: SavePersonWordsRequest, type?: 'add' | 'edit') => void
}

const defaultFormValue: SavePersonWordsRequest = {
  groupId: null,
  shortcutKey: '',
  textContent: '',
  imageContent: []
}
const AddPersonWords: FC<IAddWords> = ({ open, data, onClose }) => {
  const [showAddGroup, setShowAddGroup] = useState(false)
  const [fileName, setFileName] = useState('')
  const [checkedGroupName, setCheckedGroupName] = useState('')

  const { personGroupList, handleAddPersonGroup, handleSavePersonWords, setLoading } =
    useContext(MessageInfoContext)

  const addGroupInpRef = useRef<HTMLInputElement>(null)
  const imgInputUploadRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const { handleUploadFileManage, isPending } = useUploadFileManage()

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<SavePersonWordsRequest>({
    defaultValues: defaultFormValue
  })

  useErrorHinter([errors.textContent, errors.shortcutKey])

  const handleAddGroup = useCallback(async () => {
    try {
      const groupName = addGroupInpRef?.current?.value
      if (!groupName) return
      handleAddPersonGroup({ groupName }, () => {
        message(t('chatFrame.succeededaddinggroup'), 'success')
        addGroupInpRef.current.value = ''
        setShowAddGroup(false)
        setCheckedGroupName(groupName)
      })
    } catch (error) {
      console.log('error', error)
    }
  }, [addGroupInpRef])
  const onSubmit = (formData): void => {
    if (isPending) {
      message(t('chatFrame.pictureuploaded'), 'info')
      return
    }
    if (!formData.groupId) {
      message(t('chatFrame.pleaseselectgroup'), 'error')
      return
    }
    let param = { ...formData }
    if (data) param = { ...formData, preMessageId: data?.preMessageId }

    handleSavePersonWords(param)
    reset(defaultFormValue)
    setFileName('')
    onClose && onClose()
  }

  useEffect(() => {
    if (checkedGroupName)
      setValue(
        'groupId',
        find(personGroupList, (it) => it?.groupName === checkedGroupName)?.groupId
      )
  }, [checkedGroupName, personGroupList])

  useEffect(() => {
    if (data) reset(data)
  }, [data])

  useEffect(() => {
    setLoading(isPending)
  }, [isPending])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[496px] focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              reset(defaultFormValue)
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {data ? t('chatFrame.editpersonalspeech') : t('chatFrame.addedpersonalspeechskills')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full pt-6">
          <div
            className="flex flex-col gap-3 [&_.itemWrap]:relative [&_.itemWrap]:flex [&_.itemWrap]:items-center [&_.itemWrap>span]:shrink-0 
          [&_.itemWrap>span]:w-[58px] [&_.itemWrap>span]:text-right [&_.itemWrap]:gap-2 pl-4 pr-4 mb-6"
          >
            <Controller
              name="groupId"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('chatFrame.selectivegrouping')}</span>
                  <div className="flex items-center h-9 gap-2">
                    <Select
                      onValueChange={(e) => field?.onChange(e)}
                      defaultValue={data?.groupId + ''}
                    >
                      <SelectTrigger className="w-[158px] shrink-0 h-full">
                        <SelectValue placeholder={t('chatFrame.pleaseselectgroup')} />
                      </SelectTrigger>
                      <SelectContent {...field}>
                        <SelectGroup>
                          {map(personGroupList, (item) => (
                            <SelectItem key={item?.groupId} value={item?.groupId + ''}>
                              {item?.groupName}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    {showAddGroup && (
                      <Input
                        ref={addGroupInpRef}
                        placeholder={t('common.pleaseEnter')}
                        className="w-[158px] h-full"
                      />
                    )}

                    <Button
                      className="min-w-[52px] shrink-0"
                      onClick={(e) => {
                        e.preventDefault()
                        showAddGroup ? handleAddGroup() : setShowAddGroup(true)
                      }}
                    >
                      {showAddGroup ? t('chatFrame.oK') : t('chatFrame.append')}
                    </Button>
                  </div>
                </div>
              )}
            />

            <Controller
              name="shortcutKey"
              control={control}
              // rules={{ required: t('chatFrame.pleaseentershortcut') }}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('chatFrame.shortcut')}</span>
                  <Input
                    placeholder={t('common.pleaseEnter')}
                    className="w-full h-9"
                    maxLength={30}
                    {...field}
                  />
                  <span className="text-lingheTextGray absolute right-2 top-1/2 -translate-y-1/2">
                    {field?.value?.length || 0}/30
                  </span>
                </div>
              )}
            />

            <Controller
              name="textContent"
              control={control}
              rules={{
                required: t('chatFrame.pleaseenterthespeechcontent')
              }}
              render={({ field }) => (
                <div
                  className="itemWrap"
                  style={{
                    alignItems: 'start'
                  }}
                >
                  <span className="whitespace-pre-line">
                    <span className="text-lingheError">*</span>
                    {t('chatFrame.speechcontent')}
                  </span>
                  <Textarea
                    className="w-full h-[182px]"
                    maxLength={MAX_INP_LENGTH}
                    {...field}
                    style={{
                      resize: 'none'
                    }}
                  />
                  <span className="text-lingheTextGray absolute right-3 bottom-2">
                    {field?.value?.length || 0}/{MAX_INP_LENGTH}
                  </span>
                </div>
              )}
            />

            <Controller
              name="imageContent"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div
                  style={{
                    alignItems: 'start'
                  }}
                  className="itemWrap [&_.itemBox]:w-[120px] [&_.itemBox]:h-[120px] [&_.itemBox]:border [&_.itemBox]:rounded-md [&_.itemBox]:cursor-pointer [&_.itemBox]:flex [&_.itemBox]:items-center [&_.itemBox]:justify-center"
                >
                  <span>{t('chatFrame.relatedpictures')}</span>
                  <input
                    type="file"
                    ref={imgInputUploadRef}
                    accept=".jpg, .jpeg, .png, .gif"
                    className="hidden"
                    onChange={async (event) => {
                      const file = event?.target?.files[0]
                      if (file.size > maxImageSize) {
                        message(t('knowledgeBase.uploadImgLimit'), 'info')
                        return
                      }
                      setFileName(file?.name || '')
                      const res = await handleUploadFileManage(file)
                      onChange([res])
                    }}
                  />
                  <div>
                    {value?.length ? (
                      <div className="itemBox">
                        <div className="group/contentBox relative w-full h-full">
                          <img
                            src={value && value?.length && value[0]}
                            alt=""
                            className="w-full h-full rounded-md object-cover group-hover/contentBox:block"
                          />
                          <div className="w-full h-full bg-lingheGrayBg absolute left-0 top-0 rounded-md flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover/contentBox:opacity-100">
                            <ReactSVG
                              src={deleteImg}
                              className="cursor-pointer"
                              onClick={() => {
                                setFileName('')
                                onChange(null)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="itemBox" onClick={() => imgInputUploadRef?.current?.click()}>
                        <Plus size={24} className="text-lingheGray" />
                      </div>
                    )}
                    {fileName ? (
                      <div className="flex items-center mt-2 text-lingheTextGray">
                        {fileName}{' '}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            setFileName('')
                            onChange(null)
                            imgInputUploadRef?.current?.click()
                          }}
                        >
                          {t('chatFrame.reupload')}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center mt-2 text-lingheTextGray">
                        {t('chatFrame.onlyfilewithincanadded')}
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          </div>
          <AlertDialogFooter
            style={{ flexDirection: 'row' }}
            className="flex items-center gap-2 border-t h-[54px] pr-3"
          >
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultFormValue)
                onClose && onClose()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit"> {t('common.save')} </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddPersonWords)
