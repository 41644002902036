import { FC } from 'react'
import { CardDescription, CardHeader, CardTitle, cn } from '@brisk/ui'
import { ArrowLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

interface Props {
  title?: string | (() => JSX.Element) // 修改为 JSX.Element
  showBack?: boolean
  description?: string
  fn?: () => void
}

export const SignInHeader: FC<Props> = ({
  title = '欢迎使用 AIOChat',
  showBack = true,
  description,
  fn
}) => {
  const navigate = useNavigate()

  const renderTitle = () => {
    if (typeof title === 'function') {
      return title() // 如果是函数，调用它并返回 JSX
    }
    return <span>{title}</span> // 否则返回字符串
  }

  return (
    <CardHeader className={cn('mb-4', showBack && 'mt-0')}>
      {showBack && (
        <div
          onClick={() => {
            fn ? fn() : navigate(-1)
          }}
          className="mb-10 rounded-full flex center items-center cursor-pointer"
        >
          <ArrowLeft className="w-6 h-6 relative" />
        </div>
      )}
      <CardTitle className="text-left text-[32px]">
        {renderTitle()}
      </CardTitle>
      <div className="mb-3" />
      {description && (
        <CardDescription className="text-center text-sm text-lingheGray mt-3">
          {description}
        </CardDescription>
      )}
    </CardHeader>
  )
}
