import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * UserInviteDetailVO
 */
export interface IInviteInfo {
  /**
   * 账号(邮箱或手机号)
   */
  account: string;
  /**
   * 账号类型 PHONE:手机 EMAIL:邮箱
   */
  accountType: string;
  /**
   * 租户名称
   */
  tenantName: string;
  joined: boolean;
}

interface ApiResp extends ClientResponse {
  data: IInviteInfo;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 根据token获取邀请信息
 * @param param
 * @returns
 */
export const fetchInviteInfoByToken = async (
  token: string,
): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/user/invite/detail/${token}`);

  return serialize(data);
};
