import { Button, cn } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { FC, memo, useEffect, useState } from 'react'
import FacebookHomePage from './FacebookHomePage'
import {
  FaceBookAccountBusinessVO,
  FaceBookHomeBusinessVO,
  fetchFacebookOauth
} from '@renderer/api'
import FacebookAccount from './FacebookAccount'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getQueryParam } from '@brisk/utils'
import { message } from '@renderer/components/message'

const Messenger: FC = () => {
  const [active, setActive] = useState(0)
  const [rowSelectHome, setRowSelectHome] = useState<FaceBookHomeBusinessVO[]>([])
  const [rowSelectAccount, setRowSelectAccount] = useState<FaceBookAccountBusinessVO[]>([])
  const [showHomeDel, setShowHomeDel] = useState(false)
  const [showAccountDel, setShowAccountDel] = useState(false)
  const { t } = useTranslation()

  const { data: oauthUrlData } = useQuery({
    queryKey: [fetchFacebookOauth.name],
    queryFn: async () => {
      const res = await fetchFacebookOauth()
      return res
    }
  })

  useEffect(() => {
    const errorMsg = getQueryParam('error')
    let timer

    if (errorMsg) {
      timer = setTimeout(() => {
        message(decodeURIComponent(errorMsg), 'error')
      }, 0)
    }

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="w-full h-full overflow-hidden flex flex-col text-sm">
      <div
        className="h-[54px] shrink-0 border-b pl-3 pr-3 flex items-center gap-4 text-base [&_span]:cursor-pointer [&_span]:relative  [&_.active]:text-primary [&_.active]:after:block [&_.active]:after:conten-[''] [&_.active]:after:
      [&_.active]:after:absolute [&_.active]:after:h-1 [&_.active]:after:w-full [&_.active]:after:bg-primary [&_.active]:after:rounded-md [&_.active]:after:left-[50%] [&_.active]:after:-translate-x-1/2 
      [&_.active]:after:-bottom-4"
      >
        <span
          className={cn(active === 0 ? 'active' : '')}
          onClick={() => {
            if (active === 0) return
            setActive(0)
            setRowSelectAccount([])
          }}
        >
          {t('socialMediaAccount.FacebookPage')}
        </span>
        <span
          className={cn(active === 1 ? 'active' : '')}
          onClick={() => {
            if (active === 1) return
            setActive(1)
            setRowSelectHome([])
          }}
        >
          {t('socialMediaAccount.FacebookAccount')}
        </span>
      </div>
      <div className="h-16 shrink-0 flex items-center justify-between pl-3 pr-3 [&_button]:h-8">
        <div className="flex items-center h-full gap-3">
          <Button onClick={() => window.open(oauthUrlData?.data, '__blank')}>
            <Plus size={16} />
            {t('socialMediaAccount.AuthorizeFBAccount')}
          </Button>
          {/* <Button variant="link" className="hover:no-underline p-0">
            如何授权Facebook账号？
          </Button> */}
        </div>
        <Button
          variant="destructive"
          disabled={active === 0 ? !rowSelectHome?.length : !rowSelectAccount?.length}
          onClick={() => (active === 0 ? setShowHomeDel(true) : setShowAccountDel(true))}
        >
          {t('socialMediaAccount.Delete')}
        </Button>
      </div>

      <div className="flex-1 w-full overflow-hidden shrink-0">
        {active === 0 ? (
          <FacebookHomePage
            rowSelect={rowSelectHome}
            setRowSelect={(rows) => setRowSelectHome(rows)}
            show={showHomeDel}
            setShow={(e) => setShowHomeDel(e)}
            oauthUrl={oauthUrlData?.data}
          />
        ) : (
          <FacebookAccount
            rowSelect={rowSelectAccount}
            setRowSelect={(rows) => setRowSelectAccount(rows)}
            show={showAccountDel}
            setShow={(e) => setShowAccountDel(e)}
            oauthUrl={oauthUrlData?.data}
          />
        )}
      </div>
    </div>
  )
}

export default memo(Messenger)
