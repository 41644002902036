import { Popover, Switch } from '@brisk/ui'
import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import wordsEdit from '../../../assets/svg/wordsEdit.svg'
import { replaceEmojiMark } from '@renderer/utils/emojiData'
import { Plus } from 'lucide-react'
import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { WelcomeContentsVO } from '@renderer/api/manage/fetchWelcomConfig'
import { useTranslation } from 'react-i18next'

interface IAddWelcomeWordsProps {
  data: WelcomeContentsVO
  index: number
  originList: WelcomeContentsVO[]
  addClick: () => void
  editClick: (data: WelcomeContentsVO) => void
  delClick: (data: WelcomeContentsVO) => void
  type: 'firstChat' | 'turnChat'
  onStateChange: (params: { id: string | number; state: string | number }) => void
}

const AddWelcomeWords: FC<IAddWelcomeWordsProps> = ({
  data,
  index,
  originList,
  addClick,
  editClick,
  delClick,
  onStateChange
}) => {
  const { t } = useTranslation()
  return (
    <div className="w-[355px] h-[168px] bg-lingheTalkingBg rounded-md relative pb-2 flex flex-col">
      <div className="p-3 flex items-center justify-between">
        <div className="flex items-center gap-2">
          {data?.title}
          <Switch
            id="airplane-mode"
            checked={Boolean(data.state)}
            onCheckedChange={(e) => {
              onStateChange && onStateChange({ id: data?.welcomeContentId || '', state: Number(e) })
            }}
          />
        </div>
        <Popover>
          <PopoverTrigger>
            <ReactSVG src={wordsEdit} className="cursor-pointer" />
          </PopoverTrigger>
          <PopoverContent
            side="bottom"
            className="bg-white w-[68px] flex flex-col gap-1 text-sm p-2 shadow rounded-md -translate-x-10 [&_span]:cursor-pointer [&_span:hover]:bg-lingheBtnHover [&_span]:w-[100%] [&_span]:flex [&_span]:items-center [&_span]:justify-center [&_span]:pt-1 [&_span]:pb-1 [&_span]:rounded-md"
          >
            <span onClick={() => editClick && editClick(data)}>{t('autoReception.edit')}</span>
            <span onClick={() => delClick && delClick(data)} className="text-lingheError">
              {t('chatFrame.removing')}
            </span>
          </PopoverContent>
        </Popover>
      </div>
      <div className="pl-3 pr-3 overflow-y-auto flex-1 shrink-0 text-lingheTextGray">
        <pre
          className="whitespace-pre-wrap"
          style={{
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-wrap'
          }}
          dangerouslySetInnerHTML={{ __html: replaceEmojiMark(data?.content || '') }}
        ></pre>
      </div>
      {index === originList.length - 1 && (
        <div
          onClick={() => addClick && addClick()}
          className="w-[90px] h-7 flex items-center justify-center gap-1 cursor-pointer border bg-white border-primary rounded-md text-primary absolute -top-9 right-0"
        >
          <Plus size={16} /> {t('autoReception.add')}
        </div>
      )}
    </div>
  )
}

export default memo(AddWelcomeWords)
