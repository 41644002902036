import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import connectError from '../../../assets/svg/connectError.svg'
import connectingIcon from '../../../assets/svg/connectingIcon.svg'
import { debounce } from 'lodash'
// import { connectIM, disconnectIM } from '@renderer/lib/sdk/juggle'
import { useTranslation } from 'react-i18next'
import { initSdk } from '@renderer/utils/im-sdk/ipcTimSdk'

const ConnectError: FC<{
  code: number
}> = ({ code }) => {
  const handleReconnect = debounce(() => {
    // // 断开连接
    // disconnectIM()
    // // 重新连接
    // connectIM()
    initSdk()
  }, 1000)
  const { t } = useTranslation()
  return (
    <>
      <style>{`
        @keyframes connectAnimation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .connect {
          animation: connectAnimation 1s linear infinite;
        }
      `}</style>
      {code === 3 && (
        <div className="w-full h-full bg-[#FFF4F2] flex items-center pl-[27px]">
          <ReactSVG src={connectError} />

          <div className="ml-[23px] flex items-center text-base text-lingheTextGray">
            <span>{t('common.currentmessagenotifiesconnectionisabnormalpleaseclickhere')}</span>
            <span className="text-primary cursor-pointer" onClick={handleReconnect}>
              {t('common.reconnect')}
            </span>
          </div>
        </div>
      )}
      {code === 2 && (
        <div className="w-full h-full bg-[#EDF6FF] flex items-center pl-[27px]">
          <ReactSVG src={connectingIcon} className="connect" />

          <div className="ml-[23px] flex items-center text-base text-lingheTextGray">
            <span>正在重新连接...</span>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(ConnectError)
