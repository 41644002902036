import dayjs from 'dayjs'
import { FC, memo, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import fileIcon from '@renderer/assets/svg/fileIcon.svg'
import fileIconLeft from '@renderer/assets/svg/fileIconLeft.svg'
import fileIconLoadding from '@renderer/assets/svg/fileIconLoadding.svg'
import fileIconLoaddingLeft from '@renderer/assets/svg/fileIconLoaddingLeft.svg'
import fileDownload from '@renderer/assets/svg/fileDownload.svg'
import fileDownloadLeft from '@renderer/assets/svg/fileDownloadLeft.svg'
import fileOpen from '@renderer/assets/svg/fileOpen.svg'
import fileOpenLeft from '@renderer/assets/svg/fileOpenLeft.svg'
import fileLoading from '@renderer/assets/imgs/fileLoading.png'
import fileLoadingLeft from '@renderer/assets/imgs/fileLoadingLeft.png'
import { cn } from '@brisk/ui'
import { IContent } from '@brisk/api'
import { formatFileSize } from '@renderer/utils'
import { checkFileExist, downloadFile, openFileFolder } from '@renderer/utils/ipcHandler'
import { useSingleChatStore } from '@renderer/store/useSingleChatStore'

const ChatListFile: FC<{ data: IContent; sentTime: number; fromSender: boolean }> = ({
  data,
  sentTime,
  fromSender
}) => {
  const [alreadyHas, setAlreadyHas] = useState(false)

  const {
    sentState = 1,
    content: { name, size, url },
    downloadState
  } = data
  const fileType = '.' + name.split('.').pop()
  const download = async () => {
    const { setMessageDataListInfo } = useSingleChatStore.getState()
    setMessageDataListInfo({ ...data, downloadState: 1 })
    const downlaodState = await downloadFile(url, name)
    if (downlaodState) {
      setMessageDataListInfo({ ...data, downloadState: 2 })
      setAlreadyHas(true)
    } else setMessageDataListInfo({ ...data, downloadState: 3 })
  }

  useEffect(() => {
    checkFileExist(name, fileType).then((res) => {
      setAlreadyHas(res)
    })
  }, [downloadState])

  return (
    <>
      <style>{`
      @keyframes loadingSendFile {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .fileLoading {
        animation: loadingSendFile 1s linear infinite;
      }
    `}</style>
      <div className="flex items-center gap-2">
        {sentState === 2 && downloadState !== 1 ? (
          <ReactSVG src={fromSender ? fileIcon : fileIconLeft} />
        ) : (
          false
        )}
        {sentState === 1 || downloadState === 1 ? (
          <div
            className="relative w-10 h-10 rounded-md overflow-hidden flex items-center justify-center"
            style={{
              backgroundImage: `url(${fromSender ? fileIconLoadding : fileIconLoaddingLeft})`
            }}
          >
            <img
              src={fromSender ? fileLoading : fileLoadingLeft}
              className="w-[22px] w-[22px] fileLoading "
            />
          </div>
        ) : (
          false
        )}
        <div className="overflow-hidden text-ellipsis line-clamp-2 text-sm">{name}</div>
      </div>
      <div
        className={cn(
          'flex items-center justify-between text-xs text-lingheGray min-w-72',
          fromSender && 'text-white'
        )}
      >
        <div className={cn(fromSender && 'opacity-60')}>
          <span>{dayjs(sentTime).format('YYYY-MM-DD HH:mm:ss')}</span>
          <span> / </span>
          <span>{formatFileSize(size)}</span>
        </div>
        {sentState === 1 ? <div className="text-[#23F28F]">上传中...</div> : false}
        {/* 下载失败 */}
        {downloadState === 3 ? (
          <div className="cursor-pointer" onClick={download}>
            失败,重试
          </div>
        ) : (
          false
        )}
        {sentState === 2 && downloadState !== 1 && downloadState !== 3 ? (
          alreadyHas ? (
            <div
              className="hover:opacity-80 w-5 h-5 cursor-pointer flex items-center justify-center"
              onClick={async () => {
                const isHas = await checkFileExist(name, fileType)
                if (!isHas) {
                  setAlreadyHas(false)
                  return
                }
                openFileFolder(name)
              }}
            >
              <ReactSVG src={fromSender ? fileOpen : fileOpenLeft} />
            </div>
          ) : (
            <div
              className="hover:opacity-80 w-5 h-5 cursor-pointer flex items-center justify-center"
              onClick={download}
            >
              <ReactSVG src={fromSender ? fileDownload : fileDownloadLeft} />
            </div>
          )
        ) : (
          false
        )}
        {sentState === 2 && downloadState === 1 && <div className="text-[#23F28F]">下载中...</div>}
      </div>
    </>
  )
}

export default memo(ChatListFile)
