import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * ChartNumVO
 */
export interface IReceptionChatEcharts {
  /**
   * x轴数据
   */
  keyStr?: string;
  /**
   * 数量
   */
  num?: number;
}

interface ApiResp extends ClientResponse {
  data: IReceptionChatEcharts[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 会话统计柱状图
 * @param param
 * @returns
 */
export const fetchReceptionChatEcharts = async (
  param: IDataCustomerRequestBody,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/customer/statistics/session/chart`,
    param,
  );

  return serialize(data);
};
