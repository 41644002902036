import { IChatList } from '@renderer/api/chatList'
import { forwardRef, useContext, useImperativeHandle, useRef } from 'react'
import { ReactSVG } from 'react-svg'
// import noMsg from '../../../assets/svg/noMsg.svg'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import noConversasionList from '../../../assets/svg/noConversasionList.svg'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '@renderer/router'
import MessageItem from './MessageItem'
import { MessageData } from '@renderer/store/useSingleChatStore'
import { useTranslation } from 'react-i18next'
import { cn } from '@brisk/ui'
import { Virtuoso } from 'react-virtuoso'
import { MessageInfoContext } from '../Home'

interface IScrollWrap {
  list?: IChatList[]
  searchResultList?: { resultList: IChatList[]; value: string }
  active?: number | string
  handleItemClick?: (index: number | string) => void
  handleToFetch?: () => void
  searchInputRef?: React.RefObject<HTMLInputElement>
  hasTalkHistory?: MessageData[]
}

export const ScrollWrap = forwardRef(
  (
    { list, searchResultList, active, handleItemClick, handleToFetch, ...others }: IScrollWrap,
    ref
  ) => {
    const navigate = useNavigate()
    const virtuosoRef = useRef(null)
    const { t } = useTranslation()
    const { setShouldScrollWhenSearch } = useContext(MessageInfoContext)
    useImperativeHandle(ref, () => ({}))

    const handleScroll = (event): void => {
      const { scrollTop, clientHeight, scrollHeight } = event.target
      if (scrollHeight - scrollTop <= clientHeight + 100) handleToFetch && handleToFetch()
    }

    const renderList = searchResultList?.value ? searchResultList?.resultList : list

    return (
      <div className={cn('w-full h-full overflow-hidden')}>
        {/* 搜索无数据 */}
        {searchResultList?.value && !searchResultList?.resultList?.length && (
          <div className="w-full flex flex-col items-center justify-center text-sm text-[#777A8A] min-h-[400px]">
            <ReactSVG src={noSearchResult} />
            {t('common.noDataAvailable')}
          </div>
        )}
        {!list?.length && !searchResultList?.value && (
          <div className="w-full flex flex-col items-center justify-center min-h-[400px] font-medium text-lingheTextGray text-sm">
            <ReactSVG src={noConversasionList} />
            {t('common.noDataAvailable')}
          </div>
        )}
        <Virtuoso
          ref={virtuosoRef}
          data={renderList}
          atTopStateChange={() => {}}
          itemContent={(index, item) => {
            return (
              <div
                className={
                  active === item?.conversationId
                    ? 'bg-lingheTalkingBg relative after:content[""] after:block after:w-0.5 after:h-full after:bg-primary after:absolute -after:left-0.5 after:top-0'
                    : ''
                }
                key={item?.conversationId + item?.sortTime}
                onClick={() => {
                  handleItemClick && handleItemClick(item?.conversationId || 0)
                  setShouldScrollWhenSearch()
                  navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${item?.conversationId}`, {
                    replace: true
                  })
                }}
              >
                <MessageItem {...item} {...others} />
              </div>
            )
          }}
          onScroll={handleScroll}
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    )
  }
)
ScrollWrap.displayName = 'ScrollWrap'
