import { IMenuTreeDTO, fetchAllMenuSource, fetchUserAuthorizedMenu } from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { useRoutePathStore } from '@renderer/store/manageStore/useRoutePathStore'
import { useNewBieGuide } from '@renderer/store/manageStore/useNewBieGuide'
import { QueryObserverResult, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * 映射当前权限菜单
 * @param menuIds
 * @param tree
 * @returns
 */
function extractMenuItemsWithAncestors(menuIds: number[], tree: IMenuTreeDTO[]): IMenuTreeDTO[] {
  const menuIdsSet = new Set(menuIds)
  const filteredTree = tree.reduce((acc, node) => {
    const shouldIncludeNode = menuIdsSet.has(node.menuId) || hasDescendantWithId(node, menuIdsSet)

    if (shouldIncludeNode) {
      const clonedNode = _.cloneDeep(node) // 仅在当前节点不是目标节点时才进行子节点过滤
      if (!menuIdsSet.has(node.menuId)) {
        clonedNode.children = extractMenuItemsWithAncestors(menuIds, clonedNode.children)
      }
      acc.push(clonedNode)
    }

    return acc
  }, [] as IMenuTreeDTO[])

  return filteredTree
}

function hasDescendantWithId(node: IMenuTreeDTO, menuIdsSet: Set<number>): boolean {
  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      if (menuIdsSet.has(child.menuId) || hasDescendantWithId(child, menuIdsSet)) {
        return true
      }
    }
  }
  return false
}
export const useManageRouteMenu = (): {
  menuData: IMenuTreeDTO[]
  extractMenu: IMenuTreeDTO[]
  authorizedMenu: number[]
  refetchMenu: () => Promise<QueryObserverResult<number[], Error>>
} => {
  const { setRoutePath } = useRoutePathStore()
  const { setNewBieGuideList } = useNewBieGuide()

  const { pathname } = useLocation()

  const { data: menuData } = useQuery({
    queryKey: [fetchAllMenuSource.name],
    queryFn: async () => {
      if (pathname.indexOf('manager') < 0) return []
      const res = await fetchAllMenuSource()
      if (res?.code === RESPONSE_CODE.success) {
        return res?.data
      } else {
        return []
      }
    }
  })

  const { data: authorizedMenu, refetch } = useQuery({
    queryKey: [fetchUserAuthorizedMenu.name],
    queryFn: async () => {
      if (pathname.indexOf('manager') < 0) return []
      const res = await fetchUserAuthorizedMenu()
      if (res?.code === RESPONSE_CODE.success) {
        return res?.data
      } else {
        return []
      }
    }
  })

  const getUserPermissions = useCallback(
    (menu: IMenuTreeDTO[]) =>
      _.flattenDeep(
        _.map(menu, (item) => (item.path ? item.path : getUserPermissions(item?.children)))
      ),
    []
  )

  const extractMenu = useMemo(() => {
    if (!menuData?.length || !authorizedMenu?.length) return
    return extractMenuItemsWithAncestors(authorizedMenu, menuData)
  }, [menuData, authorizedMenu])

  useEffect(() => {
    setNewBieGuideList(extractMenu)
    setRoutePath(getUserPermissions(extractMenu))
  }, [extractMenu])

  return {
    menuData,
    authorizedMenu,
    extractMenu,
    refetchMenu: refetch
  }
}
