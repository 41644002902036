import { create } from 'zustand'
import { concat, findIndex } from 'lodash'
import { IChatList } from '@brisk/api'
export interface Idata {
  total: number
  size: number
  current: number
  pages: number
  [key: string]: number | string | unknown // 添加任何其他属性
}

export type StateAssigned = {
  allConversations: IChatList[]
  assignedData: Idata
  assignedList: IChatList[]
  unAssignedData: Idata
  unAssignedList: IChatList[]
  finishedData: Idata
  finishedList: IChatList[]
  // 回复消息
  reCallMessage: IChatList[]
  shouldUpdate: boolean // 是否需要更新【所有的】(会话删除\开启的情况)
}

export type ActionAssigned = {
  setAssignedList: (assignedList: IChatList[]) => void
  setAssignedData: (assignedData: StateAssigned['assignedData']) => void

  setUnAssignedList: (unAssignedList: IChatList[]) => void
  setUnAssignedData: (unAssignedData: StateAssigned['unAssignedData']) => void

  setFinishedList: (finishedList: IChatList[]) => void
  setfinishedData: (finishedData: StateAssigned['finishedData']) => void

  setAllConversations: (allConversations: IChatList[], type?: 'add' | 'all' | 'del') => void

  setReCallMessage: (message: IChatList) => void
  delReCallMessage: (reCallMessage: IChatList[]) => void
  clearChatListInfo: () => void
  setShouldUpdate: () => void
}

const defautData = {
  total: 0,
  size: 10,
  current: 1,
  pages: 1
}

export const useAssignedStore = create<StateAssigned & ActionAssigned>((set, get) => ({
  assignedData: defautData,
  unAssignedData: defautData,
  finishedData: defautData,
  allConversations: [],
  assignedList: [],
  unAssignedList: [],
  finishedList: [],
  reCallMessage: [],
  shouldUpdate: false,
  setAssignedList: (assignedList: IChatList[]): void => set({ assignedList }),
  setAssignedData: (assignedData: StateAssigned['assignedData']): void => {
    set({ assignedData })
  },
  setUnAssignedList: (unAssignedList: IChatList[]): void => set({ unAssignedList }),
  setAllConversations: (allConversations: IChatList[], type?: 'add' | 'all' | 'del'): void => {
    if (type === 'add') {
      const list = get().allConversations
      set({ allConversations: concat(allConversations, list) })
    } else if (type === 'del') {
      const list = get().allConversations
      set({
        allConversations: list.filter(
          (it) => !allConversations.find((it2) => it2.conversationId === it.conversationId)
        )
      })
    } else {
      set({ allConversations })
    }
  },
  setUnAssignedData: (unAssignedData: StateAssigned['unAssignedData']): void =>
    set({ unAssignedData }),
  setFinishedList: (finishedList: IChatList[]): void => {
    set({ finishedList })
  },
  setfinishedData: (finishedData: StateAssigned['finishedData']): void => set({ finishedData }),

  // add
  setReCallMessage: (message: IChatList): void => {
    const data = get().reCallMessage
    const i = findIndex(data, (item) => item?.conversationId === message?.conversationId)
    if (i < 0) {
      set({ reCallMessage: [message, ...data] })
    } else {
      const newList = data?.splice(i, 1)
      set({ reCallMessage: [message, ...newList] })
    }
  },
  delReCallMessage: (reCallMessage: IChatList[]): void => set({ reCallMessage }),
  clearChatListInfo: () => {
    set({
      assignedData: defautData,
      unAssignedData: defautData,
      finishedData: defautData,
      assignedList: [],
      allConversations: [],
      unAssignedList: [],
      finishedList: [],
      reCallMessage: []
    })
  },
  setShouldUpdate: () => set({ shouldUpdate: !get().shouldUpdate })
}))
