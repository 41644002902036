import AliOSS, { SignatureUrlOptions } from 'ali-oss'
import { MultipartOptions, PutOptions, UploadConfig } from './type'
import { DEFAULT_PARALLEL, DEFAULT_PART_SIZE } from './util'

export class OSS {
  options: UploadConfig
  client: AliOSS
  constructor(options: UploadConfig) {
    this.options = options
    const { accessKeyId, accessKeySecret, bucket, refreshSTSToken, securityToken, endpoint } =
      this.options
    this.client = new AliOSS({
      accessKeyId,
      accessKeySecret,
      secure: true,
      stsToken: securityToken,
      bucket,
      endpoint,
      refreshSTSToken
    })
  }
  getSignedUrl(filePath: string, options: SignatureUrlOptions) {
    return this.client.signatureUrl(filePath, options)
  }
  getObjectUrl(filePath: string) {
    return this.client.generateObjectUrl(filePath)
  }
  async put(filePath: string, file: File, options?: PutOptions) {
    await this.client.put(filePath, file, options)
    return this.getObjectUrl(filePath)
  }
  async multipartUpload(filePath: string, file: File, options: MultipartOptions) {
    const { parallel, partSize, ...rest } = options || {}
    let abortCheckpoint: any

    options?.client?.({
      abort: () => this.client.abortMultipartUpload(abortCheckpoint.name, abortCheckpoint.uploadId)
    })

    await this.client.multipartUpload(filePath, file, {
      parallel: parallel || DEFAULT_PARALLEL,
      partSize: partSize || DEFAULT_PART_SIZE,
      ...rest,
      progress: (p, cpt) => {
        abortCheckpoint = cpt
        options?.progress?.(p)
      }
    })
    return this.getObjectUrl(filePath)
  }
}
