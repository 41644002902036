import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sheet,
  SheetDescription,
  SheetTitle,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn
} from '@brisk/ui'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { MySheetContent } from './MySheetContent'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { User, X } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { CustomerPageResultVO } from '@renderer/api'
import { ChannelData, SourceType } from '../CustomerManage'
import { concat, map, some } from 'lodash'
import { PhoneInput } from '@renderer/components/phone-input-manage'
import { ITagList } from '@renderer/api/manage/fetchTagList'
import { useMutation } from '@tanstack/react-query'
import { fetchEditUserDetail } from '@renderer/api/manage/fetchEditUserDetail'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import { useUploadFileManageAvatar } from '@renderer/hooks/manager/useUploadFileManageAvatar'
import delWarning from '../../../assets/svg/delWarning.svg'
import { CustomerDetailResponse } from '@brisk/api'
import { useTranslation } from 'react-i18next'
import { countries } from '../../../components/phone-input-manage/countries'
import { CountryCode } from 'libphonenumber-js'

interface IUserDetailSheetProps {
  tagList: ITagList[]
  channelData: ChannelData
  title?: string
  open: boolean
  data: CustomerDetailResponse
  onClose: () => void
  updateList: () => void
}

const defaltFormData = {
  customerId: '',
  name: '',
  phone: '',
  email: '',
  avatar: '',
  address: '',
  company: '',
  source: 0,
  customerTag: '',
  remark: ''
} as unknown as CustomerPageResultVO

let testPhone = ''

const UserDetailSheet: FC<IUserDetailSheetProps> = ({
  title = '客户信息',
  data,
  open,
  onClose,
  tagList,
  updateList
}) => {
  const [submitType, setSubmitType] = useState<'save' | 'saveAndClose'>('save')
  const [isEdit, setIsEdit] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [filepath, setFilepath] = useState('')
  const [country, setCountry] = useState('US')

  const subButtonRef = useRef<HTMLButtonElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)

  const { handleUploadFileManageAvatar } = useUploadFileManageAvatar()
  const { t } = useTranslation()

  const { handleSubmit, control, reset, setValue } = useForm<CustomerPageResultVO>({
    defaultValues: defaltFormData
  })

  const { mutateAsync: updateDetail } = useMutation({
    mutationFn: fetchEditUserDetail
  })

  const onSubmit = async (formData): Promise<void> => {
    const phone = testPhone.trim()
    const param = {
      ...formData,
      customerTagIds: map(formData?.customerTag, (it) => it?.tagId),
      // name: data?.name,
      source: data?.source,
      // phone: trim(formData?.phone)
      phone: phone && phone.length > 4 ? phone : '',
      avatar: filepath ? filepath : ''
    }

    const res = await updateDetail(param)
    if (res?.code === RESPONSE_CODE.success) {
      setIsEdit(false)
      message(t('businessManagement.modifiedsuccessfully'), 'success')
      updateList && updateList()
      if (submitType === 'saveAndClose') {
        setOpenCancel(false)
        onClose && onClose()
      }
    }
  }
  useEffect(() => {
    if (data) {
      console.log('data', data)
      const {
        address,
        avatar,
        company,
        customerId,
        customerTag,
        email,
        name,
        phone,
        remark,
        source,
        areaCode
      } = data
      reset({
        address: address || '',
        avatar: import.meta.env.VITE_IMAGE_URL + avatar || '',
        company: company || '',
        customerId: customerId || '',
        email: email || '',
        name: name || '',
        customerTag: customerTag ? customerTag : [],
        // phone: trim(phone || ''),
        phone: '',
        remark: remark || '',
        source: source || 0
      })
      setValue('phone', areaCode && phone ? phone : '')
      if (avatar) setFilepath(avatar)
      if (areaCode) {
        const country = countries.find((country) => country.phone_code === areaCode)?.iso2
        setCountry(country || 'US')
      }
    }
  }, [data])

  useEffect(() => {
    if (!data.avatar) setFilepath('')
  }, [open, data])

  return (
    <Sheet open={open}>
      <MySheetContent
        className="p-0 overflow-y-auto"
        style={{
          width: 520,
          maxWidth: 520
        }}
      >
        <form className="w-full h-full flex flex-col text-base" onSubmit={handleSubmit(onSubmit)}>
          <SheetTitle className="h-[54px] shrink-0 border-b pl-3 pr-3 flex items-center relative">
            {title}
            <ReactSVG
              src={close}
              className="cursor-pointer absolute top-4 right-4"
              onClick={() => {
                if (isEdit) {
                  setOpenCancel(true)
                } else {
                  setOpenCancel(false)
                  onClose && onClose()
                }
              }}
            />
          </SheetTitle>
          <SheetDescription />

          <div className="flex-1 shrink-0 p-3 overflow-y-ayto text-sm">
            <div
              className={cn(
                'flex flex-col gap-4 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 [&_input]:h-9',
                !isEdit && '[&_input]:bg-lingheTalkingBg'
              )}
            >
              <Controller
                name="avatar"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="itemWrap">
                    <span>{t('clientManagement.Avatar')}</span>
                    <Avatar className="hidden h-8 w-8 sm:flex relative">
                      <AvatarImage
                        alt="Avatar"
                        src={value}
                        className="w-full h-full rounded-full"
                      />
                      <AvatarFallback>
                        <User />
                      </AvatarFallback>
                      {isEdit && (
                        <span
                          onClick={() => fileRef?.current?.click()}
                          className="absolute bottom-0 left-[50%] -translate-x-1/2 text-white bg-[#00000020] text-xs cursor-pointer w-full text-center"
                        >
                          {t('clientManagement.replacements')}
                        </span>
                      )}
                    </Avatar>
                    <input
                      ref={fileRef}
                      type="file"
                      accept=".png, .jpg, .jpeg, .webp"
                      className=" hidden"
                      onChange={async (event) => {
                        const file = event?.target?.files[0]
                        const { filepath, url } = await handleUploadFileManageAvatar(file)
                        onChange(url)
                        setFilepath(filepath)
                      }}
                    />
                  </div>
                )}
              />
              <Controller
                name="name"
                disabled={!isEdit}
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('clientManagement.CustomerNickname')}</span>
                    <Input
                      maxLength={60}
                      placeholder={t('clientManagement.Pleaseenteracustomernickname')}
                      {...field}
                    />
                  </div>
                )}
              />
              <Controller
                name="source"
                disabled
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('clientManagement.Channel')}</span>
                    <Input value={SourceType[field?.value]} disabled />
                  </div>
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('editInformation.mailbox')}</span>
                    {!isEdit ? (
                      <TooltipProvider>
                        <Tooltip delayDuration={200}>
                          <TooltipTrigger disabled={!isEdit}>
                            <Input
                              {...field}
                              placeholder={t('editInformation.pleaseenteremail')}
                              disabled={!isEdit}
                            />
                          </TooltipTrigger>
                          <TooltipContent>{field?.value || ''}</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <Input
                        {...field}
                        placeholder={t('editInformation.pleaseenteremail')}
                        disabled={!isEdit}
                      />
                    )}
                  </div>
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('common.telephone')}</span>
                    <PhoneInput
                      key={country}
                      required={false}
                      defaultCountry={country as CountryCode}
                      onPhoneInput={(value) => {
                        setValue('phone', value)
                        testPhone = value
                      }}
                      disabled={!isEdit}
                      {...field}
                    />
                  </div>
                )}
              />
              <Controller
                name="address"
                disabled={!isEdit}
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('common.address')}</span>
                    {!isEdit ? (
                      <TooltipProvider>
                        <Tooltip delayDuration={200}>
                          <TooltipTrigger disabled={!isEdit}>
                            <Input
                              placeholder={t('editInformation.pleaseentertheaddress')}
                              {...field}
                            />
                          </TooltipTrigger>
                          <TooltipContent>{field?.value || ''}</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <Input placeholder={t('editInformation.pleaseentertheaddress')} {...field} />
                    )}
                  </div>
                )}
              />
              <Controller
                name="company"
                disabled={!isEdit}
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('editInformation.corporation')}</span>
                    <Input placeholder={t('editInformation.pleaseentercompany')} {...field} />
                  </div>
                )}
              />
              <Controller
                name="customerTag"
                control={control}
                render={({ field }) => (
                  <div className="itemWrap">
                    <span>{t('editInformation.tag')}</span>
                    <Popover>
                      <PopoverTrigger disabled={!isEdit}>
                        <div
                          className={cn(
                            'flex flex-1 items-center gap-1 relative min-h-9 flex-wrap pt-3 pb-3 border rounded-md pl-3 pr-3 w-full',
                            !isEdit && 'bg-lingheTalkingBg text-lingheTextGray'
                          )}
                        >
                          {map(field?.value, (item, i) => {
                            return (
                              <TooltipProvider key={item?.tagId + i + item?.colorCode}>
                                <Tooltip>
                                  <TooltipTrigger type="reset">
                                    <span
                                      className={cn(
                                        'h-6 rounded-md pl-2 pr-8 relative text-xs flex items-center'
                                      )}
                                      style={{
                                        backgroundColor: `${item?.colorCode}50`,
                                        color: item?.colorCode
                                      }}
                                    >
                                      <span className="max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                                        {item?.tagName}
                                      </span>

                                      <X
                                        size={14}
                                        className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          field.onChange(
                                            field.value.filter((it) => it?.tagId !== item?.tagId)
                                          )
                                        }}
                                      />
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipContent className="max-w-[300px]">
                                    {item?.tagName}
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            )
                          })}
                        </div>
                      </PopoverTrigger>
                      <PopoverContent side="bottom" className="p-0 max-h-[300px] overflow-y-auto">
                        <div className="flex flex-col gap-1 p-2">
                          {map(tagList, (it) => (
                            <TooltipProvider key={it?.tagName + it?.colorCode}>
                              <Tooltip>
                                <TooltipTrigger type="reset">
                                  <span
                                    className={cn(
                                      'h-8 pl-3 pr-3 w-full overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer flex items-center justify-center rounded-md'
                                    )}
                                    style={{
                                      backgroundColor: `${it?.colorCode}50`,
                                      color: it?.colorCode
                                    }}
                                    onClick={() => {
                                      if (some(field.value, (item) => item?.tagId === it?.tagId))
                                        return
                                      field.onChange(concat(field.value, it))
                                    }}
                                  >
                                    {it?.tagName}
                                  </span>
                                </TooltipTrigger>
                                <TooltipContent className="max-w-[300px]">
                                  {it?.tagName}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ))}
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                )}
              />
              <Controller
                name="remark"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="itemWrap">
                    <span>{t('clientManagement.Remarks')}</span>
                    <Textarea
                      disabled={!isEdit}
                      placeholder={t('clientManagement.Pleaseenteracomment')}
                      className={cn(
                        'min-h-[120px]',
                        !isEdit && 'bg-lingheTalkingBg text-lingheTextGray'
                      )}
                      value={value}
                      onChange={(e) => {
                        const val = e?.target?.value
                        if (val?.length >= 800) {
                          message(t('clientManagement.Maximumwordlimitexceeded'), 'error')
                          onChange(val.slice(0, 800))
                          return
                        }
                        onChange(val)
                      }}
                      style={{
                        resize: 'none'
                      }}
                    />
                    <div className="relative h-0">
                      <span className="text-lingheTextGray text-xs absolute right-4 bottom-3">
                        {value?.length || 0}/800
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          <div className="shrink-0 h-[54px] gap-2 flex items-center justify-end pl-3 pr-3 border-t [&_button]:h-8">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                if (!isEdit) onClose && onClose()
                else {
                  setIsEdit(false)
                  reset(data)
                }
              }}
            >
              {t('common.cancel')}
            </Button>
            {!isEdit ? (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setIsEdit(true)
                }}
              >
                {t('login.Edit')}
              </Button>
            ) : (
              <>
                <Button
                  type="submit"
                  variant="outline"
                  className="w-[102px] border-primary text-primary"
                  onClick={() => setSubmitType('save')}
                >
                  {t('common.save')}
                </Button>
                <Button
                  ref={subButtonRef}
                  type="submit"
                  onClick={() => setSubmitType('saveAndClose')}
                >
                  {t('clientManagement.Saveandclose')}
                </Button>
              </>
            )}
          </div>
        </form>

        <AlertDialog open={openCancel} onOpenChange={() => {}}>
          <AlertDialogContent className="p-0 focus:outline-none">
            <div className="relative">
              <ReactSVG
                src={close}
                className="cursor-pointer absolute right-4 top-4 "
                onClick={(e) => {
                  e.preventDefault()
                  setOpenCancel(false)
                }}
              />
              <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
                {title}
              </AlertDialogTitle>
              <AlertDialogDescription />
              <div className="flex flex-col items-center justify-center pt-8 pb-8">
                <ReactSVG src={delWarning} />
                <div className="text-lingheTextGray text-sm whitespace-pre-line">
                  {t('clientManagement.messagesimplyclosed')}
                </div>
              </div>
            </div>
            <AlertDialogFooter
              style={{ flexDirection: 'row' }}
              className="flex items-center gap-2 border-t h-[54px] pr-3"
            >
              <Button
                variant="outline"
                onClick={(e) => {
                  e.preventDefault()
                  setOpenCancel(false)
                  onClose && onClose()
                }}
              >
                {t('clientManagement.Directclose')}
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  subButtonRef?.current?.click()
                  setOpenCancel(false)
                  onClose && onClose()
                }}
              >
                {t('clientManagement.Saveandclose')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </MySheetContent>
    </Sheet>
  )
}

export default memo(UserDetailSheet)
