import axios from 'axios'
import { forEach, map } from 'lodash'

export const sendFastestRequest = async (
  urls: { domainAddress: string; addressType: number }[]
): Promise<string> => {
  const sources = map(urls, () => axios.CancelToken.source()) // 为每个请求创建一个CancelToken源
  const promises = map(urls, (url, index) => {
    return axios
      .get(`http://${url.domainAddress}/tenant/velocity`, {
        timeout: 5000,
        cancelToken: sources[index].token // 使用CancelToken
      })
      .then((response) => {
        forEach(urls, (_, i) => {
          if (i !== index) {
            sources[i].cancel('请求被更快的响应中断') // 取消其他所有请求
          }
        })
        return { response, url }
      })
      .catch(() => ({
        url,
        time: Infinity
      }))
  })

  const data = await Promise.race(promises)
  return data.url.domainAddress
}
