import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { trimAll } from '../../utils'

interface RequestParams {
  // 类型 SMS:短信 EMAIL:邮箱
  type: string
  // 账号,可以是用户名，邮箱，手机号
  account: string
  token: string
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  // TODO: 处理data

  return resp
}
// 未选择租户时，发送临时登录验证码
export const fetchEditPhoneCaptcha = async (payload: RequestParams): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>('bg/account/bind/code', {
    type: payload.type,
    account: trimAll(payload.account),
    token: payload.token
  })

  return serialize(data)
}
