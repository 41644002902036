import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * TransDto
 */
export interface ITranslateRequest {
  /**
   * 待翻译文本
   */
  contentList?: string[];
  /**
   * 目标语言 如果是访客消息，则传配置的customerLanguageCode 如果是自己发的消息 则传配置的userLanguageCode
   */
  toLanguageCode?: string;
  /**
   * 翻译线路 ChatGPT、deepl、google
   */
  translateEngine?: string;
}

interface ApiResp extends ClientResponse {
  data: string[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 批量翻译
 * @param params
 * @returns
 */
export const fetchTranslate = async (
  params: ITranslateRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/translate`, params);

  return serialize(data);
};
