import {apiClient, ApiRespSerializeFn, ClientResponse} from "../../apiClient";

/**
 * 添加标签
 */
export interface IAddTagReq {
  /**
   * 颜色码
   */
  colorCode?: string
  /**
   * 备注
   */
  remark?: string
  /**
   * 标签名
   */
  tagName: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 添加标签
 * @param params
 * @returns
 */
export const fetchAddTag = async (params: IAddTagReq): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/tag`, params)

  return serialize(data)
}
