import { PhoneInput } from '@renderer/components/phone-input'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input
} from '@brisk/ui'
import { FC, memo, useCallback, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import radioUnCheck from '../../../assets/svg/radioUnCheck.svg'
import radioCheck from '../../../assets/svg/radioChech.svg'
import { AccountType } from '@renderer/api/model'
import { IAddCustomerTableData } from '@renderer/api'
import { IRoleList } from '@renderer/api/manage/fetchRoleList'
import { ITeamListResponse } from '@renderer/api/team/fetchTeamList'
import { useTranslation } from 'react-i18next'
import TeamSelectCom from './TeamSelectCom'
import RoleSelectCom from './RoleSelectCom'

interface IAddCustomerModalProps {
  data?: IAddCustomerTableData | null
  title: string
  open: boolean
  onClose: () => void
  onConfirm: (val, type: string) => void
  roleList?: IRoleList[]
  teamData?: ITeamListResponse[]
}

interface IFormData {
  userName: string
  userAccount: string
  roleIds: string[]
  teamIds?: string[]
}

const AddCustomerModal: FC<IAddCustomerModalProps> = ({
  data,
  title,
  open,
  onClose,
  onConfirm,
  roleList = [],
  teamData = []
}) => {
  const [accountType, setAccountType] = useState<string>(AccountType.EMAIL)
  const { t } = useTranslation()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    reset
  } = useForm<IFormData>({
    defaultValues: {
      userName: '',
      userAccount: '',
      roleIds: ['2'],
      teamIds: []
    }
  })

  const onSubmit: SubmitHandler<IFormData> = (formData): void => {
    const { userName, userAccount, roleIds, teamIds } = formData
    if (!roleIds?.length) {
      setError('roleIds', { message: t('businessManagement.Pleaseselectrole') })
      return
    }
    const params = {
      userName,
      account: userAccount,
      accountType,
      roleIds: roleIds.length ? roleIds : null,
      teamIds: teamIds?.length ? teamIds : null
    }
    onConfirm && onConfirm(params, data ? 'reInvite' : 'create')
  }

  const handleKeyDown = useCallback((e) => {
    if (e?.code === 'Enter') {
      e.preventDefault()
    }
  }, [])

  useEffect(() => {
    if (data) {
      const roleIds = data.preRoleIds && Array.isArray(data.preRoleIds) ? data.preRoleIds : []
      const teamIds = data.preTeamIds && Array.isArray(data.preTeamIds) ? data.preTeamIds : []
      setValue('userName', data?.userName || data?.nickname)
      setValue('userAccount', data?.accountType === 'EMAIL' ? data?.email : data?.phone)
      setValue('roleIds', roleIds as Array<string>)
      setValue('teamIds', teamIds as Array<string>)
      setAccountType(data?.accountType || AccountType.EMAIL)
    } else {
      reset()
    }
  }, [data, open])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />

          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription />
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="p-3 flex flex-col gap-4  [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 text-sm">
                <div className="flex flex-col gap-1">
                  <p>
                    <span className="text-lingheError mr-1">*</span>
                    {t('businessManagement.MemberName')}
                  </p>
                  <Controller
                    control={control}
                    name="userName"
                    rules={{
                      required: t('businessManagement.Pleaseenternameofthemember'),
                      maxLength: {
                        value: 20,
                        message: t(
                          'businessManagement.Thenamemembermustnotexceedcharacterspleasereenterit'
                        )
                      }
                    }}
                    render={({ field }) => (
                      <Input
                        maxLength={20}
                        placeholder={t('businessManagement.Pleaseenternameofthemember')}
                        {...field}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                  {errors.userName && (
                    <p className="text-lingheError text-sm">{errors?.userName?.message}</p>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex items-center">
                    <span className="text-lingheError mr-1">*</span>
                    {t('businessManagement.MemberAccount')}
                    <div className="flex items-center gap-3 ml-2 text-lingheTextGray">
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setAccountType(AccountType.EMAIL)
                          setValue('userAccount', '')
                        }}
                      >
                        <ReactSVG
                          src={accountType === AccountType.EMAIL ? radioCheck : radioUnCheck}
                        />
                        {t('common.mailbox')}
                      </div>
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() => {
                          setAccountType(AccountType.PHONE)
                          setValue('userAccount', '')
                        }}
                      >
                        <ReactSVG
                          src={accountType === AccountType.PHONE ? radioCheck : radioUnCheck}
                        />
                        {t('common.mobilePhoneNumber')}
                      </div>
                    </div>
                  </div>
                  {accountType === AccountType.PHONE && (
                    <Controller
                      name="userAccount"
                      control={control}
                      rules={{
                        required: t('login.pleaseenteryourmobilephonenumber')
                      }}
                      render={({ field }) => (
                        <PhoneInput maxLength={20} {...field} required className="h-9" />
                      )}
                    />
                  )}
                  {accountType === AccountType.EMAIL && (
                    <Controller
                      name="userAccount"
                      control={control}
                      rules={{
                        required: t('editInformation.pleaseenteremail')
                      }}
                      render={({ field }) => (
                        <Input
                          maxLength={50}
                          placeholder={t('editInformation.pleaseenteremail')}
                          type="email"
                          {...field}
                          className="h-9"
                          onKeyDown={handleKeyDown}
                        />
                      )}
                    />
                  )}
                  {errors.userAccount && (
                    <p className="text-lingheError text-sm">{errors?.userAccount?.message}</p>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p>
                    <span className="text-lingheError mr-1">*</span>
                    {t('businessManagement.RoleSetting')}
                  </p>
                  <p className="text-lingheTextGray">{t('autoReception.customerTips')}</p>
                  <Controller
                    name="roleIds"
                    control={control}
                    render={({ field }) => (
                      <div className="itemWrap">
                        <RoleSelectCom<IFormData, 'roleIds'> roleList={roleList} field={field} />
                      </div>
                    )}
                  />
                  {errors.roleIds && (
                    <p className="text-lingheError py-1 text-sm">{errors?.roleIds?.message}</p>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <Controller
                    name="teamIds"
                    control={control}
                    render={({ field }) => (
                      <div className="itemWrap">
                        <span>{t('businessManagement.GroupSettings')}</span>
                        <TeamSelectCom<IFormData, 'teamIds'> teamData={teamData} field={field} />
                      </div>
                    )}
                  />
                </div>
              </div>
              <AlertDialogFooter
                style={{ flexDirection: 'row' }}
                className="flex items-center gap-2 border-t h-[54px] pr-3"
              >
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    onClose && onClose()
                  }}
                >
                  {t('common.cancel')}
                </Button>
                <Button type="submit">
                  {data ? t('common.verify') : t('businessManagement.Add')}
                </Button>
              </AlertDialogFooter>
            </form>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddCustomerModal)
