import { useRoutePathStore } from '@renderer/store/manageStore/useRoutePathStore'
import { includes } from 'lodash'
import { FC, memo } from 'react'
import { useLocation } from 'react-router-dom'

const ProtectedRoute: FC<{ element: React.ReactElement }> = ({ element }) => {
  const { pathname } = useLocation()

  // if (!includes(useRoutePathStore().routePath, pathname)) {
  //   return <h1 className="text-center mt-80 font-extrabold">暂无权限~~~</h1>
  // }

  return element
}

export default memo(ProtectedRoute)
