import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 批量删除渠道配置
 * @param param
 * @returns
 */
export const fetchDelChannelConfigs = async (param: Array<number>): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/batch-delete`, param)

  return serialize(data)
}
