import { Button, Checkbox, Input, Sheet, SheetDescription, SheetTitle, Textarea } from '@brisk/ui'
import { FC, memo, useEffect, useMemo } from 'react'
import { MySheetContent } from './MySheetContent'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { Controller, useForm } from 'react-hook-form'
import { IAddOrEditRoleDTO, IMenuTreeDTO, IRoleDetails } from '@renderer/api'
import { difference, every, filter, includes, map, union } from 'lodash'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

export interface IAddRolesData {
  open: boolean
  type: 'add' | 'edit' | 'check'
  roleDetail: IRoleDetails | null
}

interface IAddRolesSheetProps {
  roleData: IAddRolesData
  menuData: IMenuTreeDTO[]
  onClose: () => void
  onComfirn: (data: IAddOrEditRoleDTO) => void
}

const defaultVal = {
  roleName: '',
  remark: '',
  menuIdList: []
}

const AddRolesSheet: FC<IAddRolesSheetProps> = ({ roleData, menuData, onClose, onComfirn }) => {
  const { t } = useTranslation()
  const title = useMemo(() => {
    switch (roleData?.type) {
      case 'add':
        return t('businessManagement.AddRole')
      case 'edit':
        return t('businessManagement.editorialrole')
      case 'check':
        return t('businessManagement.RoleDetails')
      default:
        return t('businessManagement.Role')
    }
  }, [roleData?.type])

  const { handleSubmit, control, reset } = useForm<IAddOrEditRoleDTO>({
    defaultValues: defaultVal
  })

  const onSubmit = (formData): void => {
    if (!formData?.menuIdList?.length) {
      message(t('businessManagement.Pleaseselectrolepermissions'), 'error')
      return
    }
    onComfirn && onComfirn(formData)
  }

  useEffect(() => {
    if (roleData?.roleDetail) reset(roleData?.roleDetail)
    else reset(defaultVal)
  }, [roleData])

  return (
    <Sheet open={roleData?.open}>
      <MySheetContent
        className="p-0"
        style={{
          width: 520,
          maxWidth: 520
        }}
      >
        <form
          className="w-full h-full flex flex-col text-base overflow-y-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SheetTitle className="h-[54px] shrink-0 border-b pl-3 pr-3 flex items-center relative">
            {title}
            <ReactSVG
              src={close}
              className="cursor-pointer absolute top-4 right-4"
              onClick={() => {
                onClose && onClose()
              }}
            />
          </SheetTitle>
          <SheetDescription />

          <div className="flex-1 shrink-0 text-sm p-3 flex flex-col gap-4 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2">
            <Controller
              name="roleName"
              disabled={roleData?.type === 'check'}
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    <span className="text-lingheError">*</span>
                    {t('businessManagement.RoleName')}
                  </span>
                  <Input placeholder={t('common.pleaseEnter')} {...field} required />
                </div>
              )}
            />
            <Controller
              name="remark"
              control={control}
              disabled={roleData?.type === 'check'}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('businessManagement.RoleDescription')}</span>
                  <Textarea
                    className="min-h-[90px]"
                    placeholder={t('common.pleaseEnter')}
                    required
                    {...field}
                    style={{
                      resize: 'none'
                    }}
                  />
                </div>
              )}
            />
            <Controller
              name="menuIdList"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  {map(menuData, (item, index) => {
                    const childMenuIds = item?.children?.length
                      ? map(item?.children, (child) => {
                          return child?.menuId
                        })
                      : []
                    /** 子路由是否全选了 */
                    const isSubset = childMenuIds?.length
                      ? every(childMenuIds, (item) => includes(value, item))
                      : false
                    return (
                      <div className="itemWrap" key={item?.menuId + index}>
                        <span>{item?.menuName}</span>
                        <div className="flex items-center gap-1 text-lingheTextGray">
                          <Checkbox
                            disabled={roleData?.type === 'check'}
                            id={item?.menuId + ''}
                            checked={
                              item?.children?.length ? isSubset : includes(value, item?.menuId)
                            }
                            onCheckedChange={(checked) => {
                              if (checked)
                                onChange(
                                  union(value, childMenuIds?.length ? childMenuIds : [item?.menuId])
                                )
                              else
                                onChange(
                                  difference(
                                    value,
                                    childMenuIds?.length ? childMenuIds : [item?.menuId]
                                  )
                                )
                            }}
                          />
                          <label htmlFor={item?.menuId + ''}>
                            {t('businessManagement.SelectAll')}
                          </label>
                        </div>
                        {item?.children?.length ? (
                          <div className="flex items-center gap-8 flex-wrap text-lingheTextGray">
                            {map(item?.children, (child, childIndex) => (
                              <div
                                key={child?.menuId + childIndex}
                                className="flex items-center gap-1"
                              >
                                <Checkbox
                                  disabled={roleData?.type === 'check'}
                                  id={child?.menuId + ''}
                                  checked={includes(value || [], child?.menuId)}
                                  onCheckedChange={(checked) => {
                                    if (checked) onChange([...(value || []), child?.menuId])
                                    else onChange(filter(value || [], (it) => it !== child?.menuId))
                                  }}
                                />
                                <label htmlFor={child?.menuId + ''}>{child?.menuName}</label>
                              </div>
                            ))}
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    )
                  })}
                </>
              )}
            />
          </div>

          <div className="shrink-0 h-[54px] gap-2 flex items-center justify-end pl-3 pr-3 border-t [&_button]:h-8">
            {roleData?.type !== 'check' && (
              <>
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    onClose && onClose()
                  }}
                >
                  {t('common.cancel')}
                </Button>
                <Button type="submit">{t('common.save')}</Button>
              </>
            )}
            {roleData?.type === 'check' && (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  onClose && onClose()
                }}
              >
                {t('common.cloture')}
              </Button>
            )}
          </div>
        </form>
      </MySheetContent>
    </Sheet>
  )
}

export default memo(AddRolesSheet)
