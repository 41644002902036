import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * ConsultingCollectionVO
 */
export interface IVisitLine {
  /**
   * 访客咨询数
   */
  consultingHourNum?: IVisitLineItem[];
  /**
   * 新访客数
   */
  newCustomerHourNum?: IVisitLineItem[];
  /**
   * 接待访客数
   */
  receptionHourNum?: IVisitLineItem[];
  /**
   * 排队会话数
   */
  waitConsultingHourNum?: IVisitLineItem[];
}

/**
 * ChartNumVO
 */
export interface IVisitLineItem {
  /**
   * x轴数据
   */
  keyStr?: string;
  /**
   * 数量
   */
  num?: number;
}

interface ApiResp extends ClientResponse {
  data: IVisitLine;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 访客趋势图
 * @returns
 */
export const fetchVisiteLinesData = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/bg/realtime/statistics/chart`,
  );

  return serialize(data);
};
