import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  cn,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@brisk/ui'
import { User, X } from 'lucide-react'
import {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { ReactSVG } from 'react-svg'
import edit from '../../../assets/svg/edit.svg'
// import copy from '../../../assets/svg/copy.svg'
import time from '../../../assets/svg/time.svg'
import detailEmail from '../../../assets/svg/detailEmail.svg'
import turnOutDetail from '../../../assets/svg/turnOutDetail.svg'
// import copyGray from '../../../assets/svg/copyGray.svg'
import phone from '../../../assets/svg/phone.svg'
import adress from '../../../assets/svg/adress.svg'
import company from '../../../assets/svg/company.svg'
import pickUp from '../../../assets/svg/pickUp.svg'
import UserTags, { CheckTag } from './UserTags'
import DetailCardItem from '@renderer/components/DetailCardItem'
import close from '../../../assets/svg/close.svg'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PhoneInput } from '@renderer/components/phone-input-manage'
import { filter, map } from 'lodash'
import { useMutation } from '@tanstack/react-query'
import { fetchSaveUserDetail, ReferralInfoVO, UserDetailResponse } from '@renderer/api/chatList'
// import { copyTextToClipboard } from '@renderer/utils'
import { asyncChangeUserDetail } from '@renderer/utils/juggleHandles'
import { message } from '../../../components/message'
import { ITagList } from '@renderer/api/manage/fetchTagList'
import { MessageInfoContext } from '../Home'
import { useTranslation } from 'react-i18next'
import userDetailChannel from '../../../assets/svg/userDetailChannel.svg'
import './style.css'
import { countries } from '../../../components/phone-input-manage/countries'
import { CountryCode } from 'libphonenumber-js'
import groupTagIcon from '@renderer/assets/svg/groupTagIcon.svg'
import channleIcon from '@renderer/assets/svg/channleIcon.svg'
import translateConvRemark from '@renderer/assets/svg/translateConvRemark.svg'
import { SESSION_TYPE } from '@brisk/utils'
import dayjs from 'dayjs'
import TooltipComp from './components/TooltipComp'

export interface UserDetailRef {
  detailData: UserDetailResponse
}

interface IDetailProps {
  setShow: () => void
  conversationId: string
  onChangeInfo: () => void
  userDetailData: UserDetailResponse
}

interface IFormInput {
  name: string
  tagIds: ITagList[]
  email: string
  phone: string
  address: string
  companyName: string
}

const defaultFormValue = {
  name: '',
  tagIds: [],
  email: '',
  phone: '',
  address: '',
  companyName: ''
}

let testPhone = ''

const UserDetail = forwardRef(
  ({ userDetailData, setShow, conversationId, onChangeInfo }: IDetailProps, ref) => {
    const editPopoverRef = useRef<HTMLButtonElement>(null)
    const { tagList, refetchTagList } = useContext(MessageInfoContext)
    const [style_language, setStyleLanguage] = useState('userDetail-PopoverContent-en')
    const [country, setCountry] = useState('US')
    const { handleSubmit, control, setValue, getValues } = useForm<IFormInput>({
      defaultValues: defaultFormValue
    })

    useImperativeHandle(ref, () => ({}))
    const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (formData.email && !emailPattern.test(formData.email)) {
        message(t('login.pleaseenterthecorrectemailaddress'), 'error')
        return
      }
      const phone = testPhone.trim()
      await saveUserDetail({
        ...formData,
        // phone: formData.phone === '+' ? '' : formData.phone,
        phone: phone === '+' || phone.length < 5 ? '' : phone,
        tagIds: getValues('tagIds')?.length ? map(getValues('tagIds'), (it) => it?.tagId) : null,
        sessionId: userDetailData?.sessionId
      })

      asyncChangeUserDetail({ conversationId, conversationTitle: formData.name })
      onChangeInfo && onChangeInfo()
      if (editPopoverRef?.current) editPopoverRef?.current?.click()
    }

    const { mutateAsync: saveUserDetail } = useMutation({
      mutationFn: fetchSaveUserDetail
    })
    const { t, i18n } = useTranslation()
    useEffect(() => {
      setStyleLanguage('userDetail-PopoverContent-' + i18n.language)
      // en zh tc ar
    }, [i18n.language])
    useEffect(() => {
      if (userDetailData) {
        const { name, address, companyName, email, areaCode, phone, tag } =
          userDetailData?.customerInfo || {}
        setValue('name', name || '')
        setValue('address', address || '')
        setValue('companyName', companyName || '')
        setValue('email', email || '')
        // setValue('phone', areaCode && phone ? '+' + areaCode + phone : '')
        setValue('phone', areaCode && phone ? phone : '')
        setValue('tagIds', tag || [])

        if (areaCode) {
          const country = countries.find((country) => country.phone_code === areaCode)?.iso2
          setCountry(country || 'US')
        }
      }
    }, [userDetailData])

    return (
      <div className={cn('w-full h-full relative')}>
        <div className="flex flex-col items-center gap-2 p-6 pb-1 relative">
          <Avatar className="hidden h-[52px] w-[52px] sm:flex rounded-full shrink-0">
            <AvatarImage
              alt="Avatar"
              src={userDetailData?.customerInfo?.avatar}
              className="w-full h-full rounded-full"
            />
            <AvatarFallback>
              <User />
            </AvatarFallback>
          </Avatar>
          <div className="text-base flex items-center gap-1">
            {userDetailData?.customerInfo?.name}

            {userDetailData?.sessionType === SESSION_TYPE.TG_GROUP && (
              <TooltipComp content="群组">
                <div>
                  <ReactSVG src={groupTagIcon} />
                </div>
              </TooltipComp>
            )}
            {userDetailData?.sessionType === SESSION_TYPE.TG_CHANNEL && (
              <TooltipComp content="频道">
                <div>
                  <ReactSVG src={channleIcon} />
                </div>
              </TooltipComp>
            )}

            <Popover>
              <PopoverTrigger ref={editPopoverRef}>
                <ReactSVG src={edit} className="cursor-pointer" />
              </PopoverTrigger>
              <PopoverContent className="w-full p-0" side="bottom">
                <div className="text-sm flex items-center justify-between pl-3 pr-3 h-[52px] border-b">
                  <span className="font-bold text-right">
                    {t('editInformation.editinformation')}
                  </span>
                  <ReactSVG
                    src={close}
                    className="cursor-pointer"
                    onClick={() => {
                      if (editPopoverRef?.current) editPopoverRef?.current?.click()
                    }}
                  />
                </div>
                <form
                  className={cn('flex flex-col', style_language)}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="grid gap-3 p-8 border-b">
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">
                        {t('editInformation.nickname')}
                      </span>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <Input
                            maxLength={20}
                            className="h-9"
                            placeholder={t('editInformation.pleaseenteranickname')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">{t('editInformation.tag')}</span>
                      <Controller
                        name="tagIds"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Popover onOpenChange={(e) => e && refetchTagList && refetchTagList()}>
                              <PopoverTrigger>
                                <div className="flex flex-1 items-center gap-1 relative min-h-9 flex-wrap border rounded-md p-3 w-[276px]">
                                  {map(getValues('tagIds'), (item) => (
                                    <span
                                      key={item?.tagId + item?.colorCode}
                                      className="min-h-6 relative text-xs flex items-center gap-1 pr-6 pt-0.5 pb-0.5 pl-1 pr-2 rounded text-xs bg-lingheInpBg text-lingheTextGray"
                                      style={{
                                        borderRadius: 3,
                                        overflowWrap: 'anywhere'
                                      }}
                                    >
                                      <span
                                        className="w-2.5 h-2.5 shrink-0 border"
                                        style={{
                                          borderRadius: 2,
                                          backgroundColor: `${item?.colorCode}`,
                                          borderColor: item?.colorCode
                                        }}
                                      />
                                      <span className={cn('flex-1 shrink-0')}>{item?.tagName}</span>
                                      <X
                                        size={16}
                                        className="absolute right-1 top-1/2 -translate-y-1/2 cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          setValue(
                                            'tagIds',
                                            filter(field.value, (it) => it?.tagId !== item?.tagId)
                                          )
                                        }}
                                      />
                                    </span>
                                  ))}
                                </div>
                              </PopoverTrigger>
                              <PopoverContent
                                side="bottom"
                                className="p-0 max-h-[300px] overflow-y-auto"
                              >
                                {tagList.length > 0 ? (
                                  <CheckTag
                                    list={tagList}
                                    selectedList={getValues('tagIds')}
                                    onclick={(item) => setValue('tagIds', [...field.value, item])}
                                  />
                                ) : (
                                  <p className="pt-2 pl-2 pb-2">{t('accountInformation.noTags')}</p>
                                )}
                              </PopoverContent>
                            </Popover>
                          </>
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">
                        {t('editInformation.mailbox')}
                      </span>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <Input
                            maxLength={40}
                            className="h-9"
                            placeholder={t('editInformation.pleaseenteremail')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">
                        {t('editInformation.telephone')}
                      </span>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => {
                          return (
                            <PhoneInput
                              required={false}
                              defaultCountry={country as CountryCode}
                              onPhoneInput={(value) => {
                                setValue('phone', value)
                                testPhone = value
                              }}
                              {...field}
                              className="h-9 flex-1"
                            />
                          )
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">
                        {t('editInformation.address')}
                      </span>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <Input
                            maxLength={100}
                            className="h-9"
                            placeholder={t('editInformation.pleaseentertheaddress')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="flex items-center gap-2 [&>span]:shrink-0">
                      <span className="text-right controller-span">
                        {t('editInformation.corporation')}
                      </span>
                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <Input
                            maxLength={50}
                            className="h-9"
                            placeholder={t('editInformation.pleaseentercompany')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-2 p-3">
                    <Button
                      variant="outline"
                      type="reset"
                      onClick={() => {
                        if (editPopoverRef?.current) editPopoverRef?.current?.click()
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                    <Button type="submit">{t('editInformation.save')}</Button>
                  </div>
                </form>
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex items-center gap-1 text-xs text-primary">
            {/* <ReactSVG src={userDetailChannel} /> */}
            <img src={userDetailData?.channelIconUrl} className="w-3.5 h-3.5" alt="" />
            <span>{userDetailData?.channelName}</span>
            {/* <ReactSVG
              src={copy}
              className="cursor-pointer"
              onClick={async () => {
                await copyTextToClipboard(userDetailData?.channelName)
              }}
            /> */}
          </div>
          <div
            className="cursor-pointer w-4 flex items-center absolute right-3 top-3 rotate-180"
            onClick={setShow}
          >
            <ReactSVG src={pickUp} />
          </div>
        </div>
        <div className="flex flex-col gap-3 p-3 text-xs">
          <UserTags
            sessionId={userDetailData?.sessionId}
            tags={userDetailData?.customerInfo?.tag || []}
            list={tagList}
            onChange={onChangeInfo}
          />
          <div className="flex flex-col gap-3 [&>div]:flex [&>div]:items-center [&>div]:gap-2 [&>div>div]:flex [&>div>div]:items-center [&>div>div]:gap-2">
            <div>
              <ReactSVG src={detailEmail} />
              {userDetailData?.customerInfo?.email}
              {/* <ReactSVG
                src={copyGray}
                className="cursor-pointer"
                onClick={async () => {
                  await copyTextToClipboard(userDetailData?.customerInfo?.email)
                }}
              /> */}
            </div>
            <div>
              <ReactSVG src={phone} />
              {userDetailData?.customerInfo?.phone}
            </div>
            <div>
              <ReactSVG src={adress} />
              {userDetailData?.customerInfo?.address}
            </div>
            <div>
              <ReactSVG src={company} />
              {userDetailData?.customerInfo?.companyName}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full border-t">
          <DetailCardItem title={t('chatFrame.session_information')} />
          <DetailCardItem
            title={t('chatFrame.transfer_record')}
            content={((): ReactElement => (
              <div className="flex flex-col gap-2 text-xs">
                {map(userDetailData?.referralInfoList, (item: ReferralInfoVO, i) => (
                  <div
                    key={item?.outUserId + i}
                    className="border-b flex flex-col p-3 pt-1 pb-1 gap-1 [&>div]:flex [&>div]:gap-2 [&>div]:items-center"
                  >
                    <div>
                      <ReactSVG src={turnOutDetail} />
                      {item?.outUserName}
                    </div>
                    <div>
                      <ReactSVG src={time} />
                      {dayjs(item?.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    {item?.remark && (
                      <div>
                        <ReactSVG src={translateConvRemark} />
                        {item?.remark}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))()}
          />
        </div>
      </div>
    )
  }
)

UserDetail.displayName = 'UserDetail'

export default UserDetail
