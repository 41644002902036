import {MD5} from 'crypto-js';

export const DEFAULT_PARALLEL = 5;
export const DEFAULT_PART_SIZE = 5 * 1024 * 1024;
// default file min shard size
export const DEFAULT_MIN_SHARD_SIZE = 1024 * 1024 * 10;

export const getFileName = ({
  file,
  isOrigin = true,
  isThumbnail = false,
  isNormal = false,
  isMD5Name = true,
}: {
  file: File;
  isOrigin?: boolean;
  isThumbnail?: boolean;
  isNormal?: boolean;
  isMD5Name?: boolean;
}) => {
  const lastIndex = file.name.lastIndexOf('.');
  const name = file.name.slice(0, lastIndex);
  const ext = file.name.slice(lastIndex + 1);
  const filename = isMD5Name ? MD5(name).toString() : name;

  if (isNormal) {
    return `${filename}.${ext}`;
  } else if (/^image\//i.test(file.type)) {
    return `${filename}_${isOrigin ? 'origin' : 'low'}${isThumbnail ? '_thumbnail' : ''}.${ext}`;
  }
  return `${filename}.${ext}`;
};
