import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * UserSaveDTO
 */
export interface IUpdateUserDetailInfoDTO {
  /**
   * 客服名称
   */
  nickname?: string
  /**
   * 所属角色
   */
  roleIdList?: number[]
  /**
   * 所属团队
   */
  teamIdList?: number[]
  /**
   * 用户编号
   */
  userId?: number
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 保存人员
 * @param param 
 * @returns 
 */
export const fetchUpdateUserDetailInfo = async (
  param: IUpdateUserDetailInfoDTO
): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/user`, param)

  return serialize(data)
}
