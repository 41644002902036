import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import { FC, memo, useMemo, useState } from 'react'
import talkingWarning from '../../../assets/svg/talkingWarning.svg'
// import talkingRight from '../../../assets/svg/talkingRight.svg'
// import unRead from '../../../assets/svg/unRead.svg'
import groupTagIcon from '@renderer/assets/svg/groupTagIcon.svg'
import channleIcon from '@renderer/assets/svg/channleIcon.svg'
import talkingLoad from '../../../assets/svg/talkingLoad.svg'
import talkingWrite from '../../../assets/svg/talkingWrite.svg'
import { ReactSVG } from 'react-svg'
import { IChatList } from '@renderer/api/chatList'
import dayjs from 'dayjs'
import { replaceHtmlElement } from '@renderer/utils'
import TimeCount from '@renderer/components/TimeCount'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { ChatListStatus } from '@renderer/api/model'
import { filter, find, includes, toLower } from 'lodash'
import { MessageData } from '@renderer/store/useSingleChatStore'
import { MESSAGE_TYPE, SESSION_TYPE } from '../../../../../../../../packages/utils/src/types'
import userSvg from '../../../assets/svg/user.svg'
import AvatarSpin from '@renderer/components/AvatarSpin'
import TooltipComp from '../Chatting/components/TooltipComp'

const startOfToday = dayjs().startOf('day')
const endOfToday = dayjs().endOf('day')

/**
 * 搜索关键字高亮
 * @param text
 * @param searchKeyword
 * @returns
 */
export const highlightText = (text, searchKeyword): string | JSX.Element[] => {
  if (!searchKeyword || !text) return text

  const regex = new RegExp(`(${escapeRegExp(searchKeyword)})`, 'gi') // 使用正则表达式全局匹配关键词，并转义关键词中的特殊字符
  return text.split(regex).map((part, index) =>
    index % 2 === 1 ? (
      <span key={index} style={{ backgroundColor: 'rgba(255, 184, 0, .6)' }}>
        {part}
      </span>
    ) : (
      part
    )
  )
}

const escapeRegExp = (string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& 表示匹配的字符串本身
}

const MessageItem: FC<IChatList> = ({
  conversationTitle,
  customerAvatar,
  latestMessage,
  startTime,
  conversationId,
  hasTalkHistory = [],
  channelIconUrl,
  channelName,
  lastTalkTime,
  unreadCount,
  sessionType,
  ...others
}) => {
  const [loadFinished, setLoadFinished] = useState(false)

  const { searchInputRef } = others
  const searchTxt = (searchInputRef as React.RefObject<HTMLInputElement>).current?.value || ''
  const { currentTab } = useCurrentAndSearch()
  const isWithinRange =
    dayjs(lastTalkTime).isAfter(startOfToday) && dayjs(lastTalkTime).isBefore(endOfToday)
  const content =
    latestMessage?.content?.content ||
    latestMessage?.content?.notice?.content ||
    ((typeof latestMessage?.content === 'string' || typeof latestMessage?.content === 'number') &&
      latestMessage?.content + '')

  /** 聊天历史记录内容 */
  const talkRes = useMemo((): string => {
    if ((hasTalkHistory as MessageData[]).length) {
      const list = find(
        hasTalkHistory as MessageData[],
        (item) => item?.conversationId === conversationId
      )?.messages
      const talkContent = filter(list, (it) =>
        includes(toLower(it?.content?.content), toLower(searchTxt))
      )[0]?.content?.content
      return talkContent
    } else {
      return ''
    }
  }, [hasTalkHistory, conversationId, searchTxt])

  return (
    <div className="w-full h-[84px] flex items-center pl-3 gap-3 cursor-pointer hover:bg-lingheTalkingBg">
      <Avatar className="hidden h-11 w-11 sm:flex rounded-full shrink-0 relative">
        <AvatarImage
          alt="Avatar"
          src={customerAvatar}
          onLoad={() => setLoadFinished(true)}
          onError={() => setLoadFinished(true)}
          className="w-full h-full rounded-full"
        />
        <AvatarFallback className="flex items-center justify-center">
          <ReactSVG src={userSvg} />
        </AvatarFallback>
        {!loadFinished && (
          <AvatarSpin loadFinished={loadFinished} calssName="w-full h-full absolute left-0 top-0" />
        )}
      </Avatar>
      <div className="h-full border-b pr-3 flex flex-1 flex-col justify-center shrink-0">
        <div className="flex items-center justify-between text-xs mb-1 text-lingheTextGray overflow-hidden text-ellipsis whitespace-nowrap">
          <div className="flex items center gap-1">
            <img src={channelIconUrl} className="w-3.5 h-3.5" alt="" />
            <span className="truncate max-w-[50px] lg:max-w-[100px]">{channelName}</span>
          </div>
          <div>
            {isWithinRange
              ? dayjs(lastTalkTime).format('HH:mm')
              : dayjs(lastTalkTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
        <div className="text-sm font-[500] max-w-[120px] lg:max-w-[200px] flex items-center gap-[2px]">
          <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
            {highlightText(conversationTitle, searchTxt)}
          </div>

          {sessionType === SESSION_TYPE.TG_GROUP && (
            <TooltipComp content="群组">
              <div>
                <ReactSVG src={groupTagIcon} />
              </div>
            </TooltipComp>
          )}
          {sessionType === SESSION_TYPE.TG_CHANNEL && (
            <TooltipComp content="频道">
              <div>
                <ReactSVG src={channleIcon} />
              </div>
            </TooltipComp>
          )}
        </div>
        <div className="flex gap-[3px] items-center text-sm text-lingheTextGray relative">
          {/* 发送失败 */}
          {latestMessage?.sentState === 3 && <ReactSVG src={talkingWarning} />}

          {/* 发送成功 已读*/}
          {/* {content && latestMessage?.sentState === 2 && latestMessage?.isRead && (
            <ReactSVG src={talkingRight} />
          )} */}

          {/* {content && latestMessage?.sentState === 2 && !latestMessage?.isRead && (
            <ReactSVG src={unRead} />
          )} */}

          {/* 发送中 */}
          {content && latestMessage?.sentState === 1 && <ReactSVG src={talkingLoad} />}

          {/* 草稿 */}
          {content && latestMessage?.draft && <ReactSVG src={talkingWrite} />}
          <div className="max-w-[120px] lg:max-w-[200px] overflow-hidden text-xs text-ellipsis whitespace-nowrap">
            {latestMessage?.name === 'cs:notice' &&
              replaceHtmlElement(latestMessage?.content?.notice?.content || content || '')}

            {latestMessage?.name === 'cs:welcome' &&
              replaceHtmlElement(
                typeof latestMessage?.content === 'string' ||
                  typeof latestMessage?.content === 'number'
                  ? latestMessage?.content + ''
                  : content + '' || ''
              )}

            {(latestMessage?.name === MESSAGE_TYPE.TEXT ||
              latestMessage?.name === MESSAGE_TYPE.GENERIC_TEXT ||
              latestMessage?.name === MESSAGE_TYPE.GENERIC_IMAGE ||
              latestMessage?.name === 'text' ||
              latestMessage?.name === 'cs:robot' ||
              latestMessage?.name === 'cs:sessionNotice') &&
              (talkRes && searchTxt
                ? highlightText(replaceHtmlElement(talkRes), searchTxt)
                : highlightText(
                    replaceHtmlElement(latestMessage?.draft || content || ''),
                    searchTxt
                  ))}
            {latestMessage?.name === MESSAGE_TYPE.IMAGE && '[图片]'}
            {latestMessage?.name === MESSAGE_TYPE.GENERIC_IMAGE && '[图片]'}
            {latestMessage?.name === MESSAGE_TYPE.GENERIC_FILE && '[文件]'}
            {latestMessage?.name === MESSAGE_TYPE.GENERIC_VIDEO && '[视频]'}
            {latestMessage?.name === MESSAGE_TYPE.GENERIC_VOICE && '[音频]'}
            {latestMessage?.name === MESSAGE_TYPE.RECALL_INFO && '[撤回了一条消息]'}
          </div>
          {currentTab === ChatListStatus.UNASSIGNED && startTime ? (
            <div className="absolute right-0 top-0 translate-y-[20%]">
              <TimeCount time={startTime} />
            </div>
          ) : (
            false
          )}
          {(currentTab === ChatListStatus.ASSIGNED || currentTab === ChatListStatus.FINISHED) &&
          unreadCount &&
          unreadCount > 0 ? (
            <div className="absolute right-0 top-0 translate-y-[20%] rounded-full bg-lingheError text-white pl-1 pr-1 text-xs">
              {unreadCount > 99 ? '99+' : unreadCount}
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(MessageItem)
