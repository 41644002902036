import { forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import { ReactSVG } from 'react-svg'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import { Button, Calendar, cn, Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import dayjs from 'dayjs'
import { DateRange } from 'react-day-picker'
import { CalendarIcon } from 'lucide-react'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface IDateSearchProps {
  title: string
  content: string
  handleSearch: (param: DateRange | undefined) => void
}

// 计算默认的日期范围
const today = dayjs()
const sevenDaysAgo = today.subtract(7, 'day').startOf('day')
const oneYearAgo = today.subtract(1, 'year').startOf('day')
const DataPageHeaderDateSearch = forwardRef(
  ({ title, content, handleSearch }: IDateSearchProps, ref) => {
    const { t } = useTranslation()
    const [active, setActive] = useState(0)
    const [date, setDate] = useState<DateRange | undefined>({
      from: sevenDaysAgo.toDate(),
      to: today.subtract(1, 'day').toDate()
    })

    useImperativeHandle(ref, () => ({
      date
    }))

    const setLastSevenDays = useCallback((): void => {
      const lastSevenDaysFrom = today.subtract(7, 'day').startOf('day').toDate() // 设置为00:00:00
      const lastSevenDaysTo = today.subtract(1, 'day').endOf('day').toDate() // 设置为23:59:59
      setDate({ from: lastSevenDaysFrom, to: lastSevenDaysTo })
      setActive(0)
    }, [])

    const setLastThirtyDays = useCallback((): void => {
      const lastThirtyDaysFrom = today.subtract(30, 'day').startOf('day').toDate() // 设置为00:00:00
      const lastThirtyDaysTo = today.subtract(1, 'day').endOf('day').toDate() // 设置为23:59:59
      setDate({ from: lastThirtyDaysFrom, to: lastThirtyDaysTo })
      setActive(1)
    }, [])

    // 定义一个函数来限制可选日期范围
    const disabledDays = useCallback((day: Date): boolean => {
      const dayToCheck = dayjs(day)
      return dayToCheck.isAfter(today) || dayToCheck.isBefore(oneYearAgo)
    }, [])

    // 在onSelect中检查日期跨度
    const handleSelect = useCallback((newDate: DateRange | undefined): void => {
      if (newDate && newDate.to && newDate.from) {
        const diffInMonths = Math.abs(dayjs(newDate.to).diff(dayjs(newDate.from), 'month'))
        if (diffInMonths > 3) {
          message(t('statistics.canselectdaterangethreeconsecutivemonthstime'), 'error')
          const threeMonthsAgo = dayjs(newDate.to).subtract(3, 'month')
          setDate({
            from: threeMonthsAgo.startOf('day').toDate(),
            to: dayjs(newDate.to).endOf('day').toDate()
          })
        } else {
          setDate({
            from: dayjs(newDate.from).startOf('day').toDate(), // 设置为00:00:00
            to: dayjs(newDate.to).endOf('day').toDate() // 设置为23:59:59
          })
        }
      } else {
        setDate(newDate)
      }
    }, [])

    return (
      <div className="flex shrink-0 items-center justify-between p-3 h-[54px] border-b">
        <div className="h-full flex items-center gap-3">
          <span className="text-base font-medium whitespace-nowrap">{title}</span>
          <div className="flex h-full items-center gap-1.5">
            <ReactSVG src={wordsTips} />
            <span className="text-sm text-lingheTextTips">{content}</span>
          </div>
        </div>

        <div className="flex h-full items-center gap-2">
          <div
            className="flex items-center border rounded-md [&_span]:px-2 [&_span]:whitespace-nowrap [&_span]:h-8 [&_span]:cursor-pointer
            [&_span]:flex [&_span]:items-center [&_span]:justify-center [&_.active]:bg-primary [&_.active]:text-white"
          >
            <span
              className={cn('rounded-l-md', active === 0 ? 'active' : '')}
              onClick={setLastSevenDays}
            >
              {t('statistics.Lastdays', { msg: 7 })}
            </span>
            <span
              className={cn('rounded-r-md', active === 1 ? 'active' : '')}
              onClick={setLastThirtyDays}
            >
              {t('statistics.Lastdays', { msg: 30 })}
            </span>
          </div>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={'outline'}
                className={cn(
                  'w-60 justify-start text-left font-normal',
                  !date && 'text-muted-foreground'
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date?.from ? (
                  date.to ? (
                    <>
                      {dayjs(date.from).format('YYYY-MM-DD')} -{' '}
                      {dayjs(date.to).format('YYYY-MM-DD')}
                    </>
                  ) : (
                    dayjs(date.from).format('YYYY-MM-DD')
                  )
                ) : (
                  <span>{t('statistics.Pleaseselectstartandendtime')}</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                disabled={disabledDays}
                onSelect={handleSelect}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>

          <Button onClick={() => handleSearch && handleSearch(date)}>
            {t('statistics.Query')}
          </Button>
        </div>
      </div>
    )
  }
)

DataPageHeaderDateSearch.displayName = 'DataPageHeaderDateSearch'

export default memo(DataPageHeaderDateSearch)
