import { getImgOption } from '@brisk/utils'
import { uploadFile } from '@renderer/api'
import { Upload } from '@renderer/misc/upload'
import { useUserStore } from '@renderer/store/useUserStore'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useState } from 'react'
import { v4 } from 'uuid'

const getFormattedDate = () => {
  return dayjs().format('YYYYMMDD') // 使用 dayjs 格式化日期为 yyyyMMdd
}

export const useUploadFileManageOptions = () => {
  const [pending, setPending] = useState(false)

  const { mutateAsync: getS3Data, isPending } = useMutation({
    mutationFn: uploadFile
  })

  const { user } = useUserStore.getState()
  /**
   * 上传文件
   * @param file 文件
   * @param file 文件目录地址
   * @param conversationId 组id
   * @param name 上传类型
   */
  const uploadFileOption = async (file, path = 'im/message/') => {
    try {
      setPending(true)
      const { data: ossPassport } = await getS3Data()

      const filepath =
        path === 'tenant/'
          ? `${path}${user?.tenantId}/kw/${v4()}/${file?.name}`
          : `${path}${user?.tenantId}/${user.userId}/${getFormattedDate()}/${v4()}/${file?.name}`
      const uploader = new Upload({
        expirationTime: (ossPassport?.expirationTime || '').toString(),
        storageType: 'minio',
        accessKeyId: ossPassport?.accessKey || '',
        accessKeySecret: ossPassport?.secretKey || '',
        securityToken: ossPassport?.sessionToken || '',
        bucket: ossPassport?.bucket || '',
        endpoint: ossPassport?.endpoint || '',
        region: ossPassport?.region
      })
      const { type, name, size } = file
      const isImage = type.startsWith('image/')
      const url = await uploader.put(filepath, file) // 已上传到minIO
      const imgOption = isImage ? await getImgOption(file) : { type, name, size }
      setPending(false)
      return { url, ...imgOption }
    } catch (error) {
      setPending(false)
      console.log('error', error)
      return { state: 'fail', errFile: file }
    }
  }

  return {
    uploadFileOption,
    isPending: pending || isPending
  }
}
