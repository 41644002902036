import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 删除邀请
 * @param inviteId
 * @returns
 */
export const fetchDelInvite = async (inviteId: string): Promise<ApiResp> => {
  const { data } = await httpClient.delete<ApiResp>(`/bg/user/invite/delete/${inviteId}`)

  return serialize(data)
}
