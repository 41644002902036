import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * ReceptionStatisticsVO
 */
export interface IVisitedes {
  /**
   * 新访客数
   */
  countNewNum?: number;
  /**
   * 数量
   */
  countNum?: number;
  /**
   * 环比数
   */
  countNumRatio?: number;
  /**
   * 常访客数
   */
  countOldNum?: number;
}

interface ApiResp extends ClientResponse {
  data: IVisitedes;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 接待访客数
 * @returns
 */
export const fetchVisitedes = async (): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/realtime/statistics/reception`,
  );

  return serialize(data);
};
