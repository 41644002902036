import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {
  data: number[]
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取当前用户有权访问的菜单资源编号
 * @returns
 */
export const fetchUserAuthorizedMenu = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/resource/authorized-menu`)

  return serialize(data)
}
