import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import DataPageHeaderDateSearch from '../Components/DataPageHeaderDateSearch'
import { IPageationRef } from '../AddCustomer'
import ReactECharts from 'echarts-for-react'
import { pubblicOption } from '../DataSync'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@brisk/ui'
import { forEach, map } from 'lodash'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  fetchChannelList,
  fetchDataChannelBar,
  fetchDataChannelPage,
  fetchDataChannelPie,
  IChannelPieData,
  IDataChannelBar,
  IDataChannelList,
  IDataChannelPageRequest,
  IDataChannelPageResponse,
  IDataCustomerRequestBody,
  RESPONSE_CODE
} from '@brisk/api'
import dayjs from 'dayjs'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { ReactSVG } from 'react-svg'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

const PIECOLOR = ['#7DEFFF', '#7CDF6F', '#BBA0FF', '#94A8F1', '#57CCFB']

const index: FC = () => {
  const { t } = useTranslation()
  const optionPie = (param: IChannelPieData[]): { [key: string]: unknown } => {
    let count = 0
    const seriesData = map(param, (item, index) => {
      count += Number(item?.num)
      return { value: item?.num, name: item?.channelName, itemStyle: { color: PIECOLOR[index] } }
    })
    return {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        bottom: '0%'
      },
      series: [
        {
          name: t('statistics.ChannelVisitorData'),
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: true, // 改为显示
            formatter: '{b}: {d}%' // 自动显示百分比
          },
          labelLine: {
            show: true, // 显示标签线
            smooth: 0.2, // 标签线平滑度
            length: 10, // 标签线长度
            length2: 20 // 标签线延伸长度
          },
          data: seriesData
        }
      ],
      graphic: {
        elements: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: `${t('statistics.Total')}：${count}`, // 计算并显示总和
              fill: '#000',
              font: 'bold 20px Arial'
            }
          }
        ]
      }
    }
  }
  const optionBar = (data: IDataChannelBar): { [key: string]: unknown } => {
    const legendData = []
    const seriesData = []
    let xAxisData = []

    forEach(Object.keys(data), (channel, i) => {
      if (!xAxisData.length)
        xAxisData = map(data[channel], (d) => dayjs(d.dateTimes).format('MM-DD'))
      legendData.push({
        name: channel
      })
      seriesData.push({
        name: channel,
        type: 'line',
        smooth: true,
        data: map(data[channel], (d) => parseInt(d.num, 10)),
        areaStyle: {
          opacity: 0
        },
        lineStyle: {
          width: 3,
          color: PIECOLOR[i]
        }
      })
    })

    return {
      ...pubblicOption,
      legend: {
        data: legendData,
        bottom: '0px'
      },
      xAxis: {
        type: 'category',
        data: xAxisData
      },
      yAxis: {
        type: 'value'
      },
      series: seriesData
    }
  }
  const [option, setOption] = useState(optionPie([]))
  const [optionLine, setOptionLine] = useState(optionBar({}))
  const [listData, setListData] = useState<IDataChannelPageResponse>()
  const [channelId, setChannelId] = useState('-1')

  const dateSearchRef = useRef(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { data: channelList, isPending: channelListLoading } = useQuery({
    queryKey: [fetchChannelList.name],
    queryFn: async () => {
      const res = await fetchChannelList()
      if (res?.code === RESPONSE_CODE.success) {
        return res?.data
      }
    }
  })

  const {
    data: pieData,
    mutateAsync: getPie,
    isPending: pieLoading
  } = useMutation({
    mutationFn: fetchDataChannelPie
  })

  const {
    data: barData,
    mutateAsync: getBar,
    isPending: barLoading
  } = useMutation({
    mutationFn: fetchDataChannelBar
  })

  const { mutateAsync: getList, isPending: pageLoading } = useMutation({
    mutationFn: fetchDataChannelPage
  })

  const handleGetList = async (param: IDataChannelPageRequest): Promise<void> => {
    try {
      const res = await getList(param)
      if (res?.code === RESPONSE_CODE.success) {
        setListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleFetch = useCallback(
    (param: IDataCustomerRequestBody): void => {
      const newParams = { ...param, channelId: channelId === '-1' ? undefined : channelId }
      getPie(newParams)
      getBar(newParams)
      handleGetList({
        ...newParams,
        pageNum: pageationRef?.current?.currentPage || 1,
        pageSize: pageationRef?.current?.itemsPerPage || 10
      })
    },
    [dateSearchRef?.current?.date, channelId, pageationRef?.current]
  )

  const columns: ColumnDef<IDataChannelList>[] = useMemo(
    () => [
      {
        id: 'name',
        header: () => <div>{t('statistics.ChannelName/Account')}</div>,
        cell: ({ row: { original } }) => <div>{original?.name || '-'}</div>
      },
      {
        id: 'channelType',
        header: () => <div>{t('statistics.ChannelType')}</div>,
        cell: ({ row: { original } }) => <div>{original?.channelType || '-'}</div>
      },
      {
        id: 'customerNum',
        header: () => <div>{t('statistics.NumberofVisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.customerNum || '-'}</div>
      },
      {
        id: 'newCustomerNum',
        header: () => <div>{t('statistics.NumberofNewVisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.newCustomerNum || '-'}</div>
      },
      {
        id: 'oldCustomerNum',
        header: () => <div>{t('statistics.NumberofFrequentVisitors')}</div>,
        cell: ({ row: { original } }) => <div>{original?.oldCustomerNum || '-'}</div>
      },
      {
        id: 'sessionNum',
        header: () => <div>{t('statistics.NumberofSessions')}</div>,
        cell: ({ row: { original } }) => <div>{original?.sessionNum || '-'}</div>
      }
    ],
    []
  )

  useEffect(() => {
    handleFetch({
      startDate: dateSearchRef?.current?.date?.from,
      endDate: dateSearchRef?.current?.date?.to
    })
  }, [])

  useEffect(() => {
    if (pieData?.data) setOption(optionPie(pieData?.data || []))
  }, [pieData])

  useEffect(() => {
    if (barData?.data) setOptionLine(optionBar(barData?.data || {}))
  }, [barData])

  return (
    <NavBodyLayout
      title={[t('statistics.statisticsTitle'), t('statistics.ChannelVisitorData')]}
      loading={[channelListLoading, pieLoading, barLoading, pageLoading]}
    >
      <div className="w-full h-full min-w-[896px] flex flex-col relative overflow-x-hidden overflow-y-auto">
        <DataPageHeaderDateSearch
          ref={dateSearchRef}
          title={t('statistics.ChannelVisitorData')}
          content={t('statistics.Supportfilteringcycle')}
          handleSearch={(date) => {
            handleFetch({
              startDate: date?.from,
              endDate: date?.to
            })
          }}
        />

        <div className="w-full h-[432px] mt-3 shrink-0 pl-3 pr-3">
          <div className="h-full w-full flex items-center gap-1">
            <div className="h-full  min-w-[50%]">
              <ReactECharts
                option={option}
                className="h-full"
                lazyUpdate
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </div>
            <div className="h-full min-w-[50%]">
              <ReactECharts
                option={optionLine}
                className="h-full"
                lazyUpdate
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex shrink-0 items-center p-3 h-[54px] border-b">
          <span className="text-base font-semibold">{t('statistics.ChannelEnquiryDetail')}</span>
        </div>

        <div className="h-16 shrink-0 pl-3 pr-3 flex items-center">
          <Select
            onValueChange={(channelId) => {
              setChannelId(channelId)
              handleGetList({
                startDate: dateSearchRef?.current?.date?.from,
                endDate: dateSearchRef?.current?.date?.to,
                pageNum: 1,
                pageSize: 10,
                channelId: channelId === '-1' ? undefined : channelId
              })
            }}
          >
            <SelectTrigger className="w-[200px] h-8">
              <SelectValue defaultValue={'-1'} placeholder={t('statistics.Pleaseselectachannel')} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem key="allChannelType" value={'-1'}>
                  {t('clientManagement.Allchannels')}
                </SelectItem>
                {map(channelList, (item) => (
                  <SelectItem
                    key={item.channelType + item?.name + item?.channelId}
                    value={String(item.channelId)}
                  >
                    {item.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="shrink-0 h-[528px] overflow-hidden">
          <ManagerTable<IDataChannelList>
            pageationRef={pageationRef}
            data={listData?.records || []}
            rowCount={listData?.total || 0}
            columns={columns}
            onPageChange={(pageNum, pageSize) => {
              const userParams = channelId === '-1' ? {} : { channelId }
              handleGetList({
                startDate: dateSearchRef?.current?.date?.from,
                endDate: dateSearchRef?.current?.date?.to,
                pageNum,
                pageSize,
                ...userParams
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={noSearchResult} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('common.Nodataavailable')}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </NavBodyLayout>
  )
}

export default memo(index)
