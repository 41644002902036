import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 删除话术
 * @param param
 * @returns
 */
export const fetchDelWelcomWords = async (param: { id: string | number }): Promise<ApiResp> => {
  const { data } = await httpClient.delete<ApiResp>(`/bg/welcome/config/content/${param.id}`)

  return serialize(data)
}
