import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

// interface RequestParams {
//
// }

interface ApiResp extends ClientResponse {
  data: string;
}
interface Data extends ClientResponse {
  data: { IMToken: string };
}
const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  // TODO: 处理data

  return {
    ...resp,
    data: {
      IMToken: resp.data,
    },
  };
};
// 获取Im会话token
export const fetchIMToken = async (): Promise<Data> => {
  const { data } = await apiClient.get<ApiResp>(`/session/im-token`);

  return serialize(data);
};
