import { FC } from 'react'
import { CardContent, cn } from '@brisk/ui'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string, ZodType } from 'zod'
import MyInputComps from '../../../pages/sign/components/MyInputComps'
import emai from '../../../assets/svg/emai.svg'
import GetCaptchaInp from '../../../pages/sign/components/GetCaptchaInp'
import { AccountType } from '../../../api/model'
import { useErrorHinter } from '../../../hooks/useErrorHinter'
// import { TagsTitle } from '../index'
import { useTranslation } from 'react-i18next'

type IFormInput = {
  email: string
  captcha: string
}

const defaultFormValues: IFormInput = {
  email: '',
  captcha: ''
}

export const EmailEdit: FC<{ onsubmit: (any) => void; onCancel: () => void }> = ({
  onsubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  const emailSchemaByCaptcha = object({
    email: string().email().min(1, t('accountInformation.pleaseenteremail')),
    captcha: string().min(6, t('login.pleaseenterverificationcode'))
  })
  const getSchema = (): ZodType => {
    return emailSchemaByCaptcha
  }
  // form
  //-----------------------------------------------------------------------
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(getSchema())
  })
  const emailCount = watch('email')
  useErrorHinter([errors.email, errors.captcha])
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    onsubmit({ type: 'email', value: formData.email, captcha: formData.captcha })
  }
  return (
    <>
      Enteremailaddressverificationcodebebound
      <CardContent className="flex-1 justify-start p-0 flex flex-col">
        {/*<TagsTitle title={t('accountInformation.Enteremailaddressverificationcodebebound')} />*/}
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <div className="grid gap-2">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <MyInputComps
                  field={field}
                  placeholder="请输入邮箱"
                  required
                  errors={errors.email}
                  svg={emai}
                />
              )}
            />
          </div>
          <div className="grid gap-2">
            <Controller
              name="captcha"
              control={control}
              render={({ field }) => (
                <GetCaptchaInp
                  field={field}
                  type={'edit'}
                  required
                  errors={errors.captcha}
                  account={emailCount}
                  accountType={AccountType.EMAIL}
                />
              )}
            />
          </div>
          <footer className="w-full pt-3 inline-flex gap-2 footer-button">
            <button
              onClick={onCancel}
              type="button"
              className="rounded text-sm border-[#ECECE] cursor-pointer border ml-auto text-[#777A8A]"
            >
              {t('common.cancel')}
            </button>
            <button
              type="submit"
              className={cn(
                'rounded text-[#FFFFFF] text-sm',
                !emailCount ? 'pointer-events-none bg-[#A7BFFF]' : 'cursor-pointer bg-[#247CFF]'
              )}
            >
              {t('common.complete')}
            </button>
          </footer>
        </form>
      </CardContent>
    </>
  )
}
