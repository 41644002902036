import { FC, useContext, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import noMsgPerson from '../../../assets/svg/noMsgPerson.svg'
// import TalkGroup from '../TalkGroup'
import MessageMenu from '../MessageMenu'
import { Outlet, useLocation } from 'react-router-dom'
// import { cn } from '@brisk/ui'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useTranslation } from 'react-i18next'
import { MessageInfoContext } from '../Home'
import { useUserStore } from '../../../store/useUserStore'

const Chatting: FC = () => {
  const [hiddenTalk, setHiddenTalk] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const { chatItemActive, itemData } = useContext(MessageInfoContext)
  const { setOutLogin } = useUserStore()
  const isMainRout =
    location?.pathname === APP_ROUTER_PATH.HOME_CHATTING || chatItemActive === '-99' || !itemData
  useEffect(() => {
    setOutLogin(false)
  }, [])

  return (
    <div className="w-full h-full overflow-hidden flex relative">
      {/* 暂时隐藏 */}
      {/* <div className={cn('flex flex-col w-52 min-w-[135px]', hiddenTalk && 'hidden')}>
        <TalkGroup />
      </div> */}
      <>
        <div className="flex flex-col shrink-0 w-[244px] lg:w-[312px] hidden sm:block">
          <MessageMenu
            talkGroupShow={hiddenTalk}
            handleIconClikc={() => setHiddenTalk(!hiddenTalk)}
          />
        </div>
        <div className="flex-1 shrink-0  bg-lingheTalkingBg">
          {isMainRout ? (
            <div className="w-full h-full flex flex-col justify-center items-center font-medium text-sm">
              <ReactSVG src={noMsgPerson} />
              {t('common.selectSessionFromLeftPane')}
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </>
    </div>
  )
}

export default Chatting
