import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { trimAll } from "@brisk/utils";

interface RequestParams {
    // 类型 SMS:短信 EMAIL:邮箱
    type: string;
    // 账号,可以是用户名，邮箱，手机号
    account: string;
    // 滑动验证码token
    token: string;
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
    return resp;
};
// 发送验证码
export const fetchLoginTemporaryCaptcha = async ({type, account, token}: RequestParams): Promise<ApiResp> => {
    const { data } = await apiClient.post<ApiResp>(
        `/login/temporary/captcha`,
        {type, account: trimAll(account), token},
    );

    return serialize(data);
};
