/**
 * useCheckAppUp
 *
 * @desc 描述：App 检查更新
 */

/* =================================================== */

import { useCheckUpdate } from './useCheckUpdate'
import { useAppUpStore } from '@renderer/store/useAppUpStore'
import { isElectron } from '@renderer/utils'

export function useCheckAppUp() {
  const { isOpen, openDialog, setDialog, setAppUp, newVersion, setVersion } = useAppUpStore()

  // 检查更新
  useCheckUpdate({
    manual: !isElectron,
    isTip: false,
    onSuccess: ({ isNewVersion, version }) => {
      if (!isNewVersion) return

      setAppUp(true)
      setVersion(version)
      openDialog()
    }
  })

  return {
    open: isOpen,
    setOpen: setDialog,
    version: newVersion
  }
}
