/**
 * putReceiveStatus
 *
 * @desc 描述：接收消息翻译启用、禁用
 * @desc 描述：接口文档名称 - 接收消息翻译启用禁用配置
 * @desc 描述：/translate/config/{status}/customer
 */

/* =================================================== */

import type { ITranslateConfig } from './types'
import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

/**
 * 接收消息翻译启用、禁用
 */
export async function putReceiveStatus(status: string): Promise<ITranslateConfig> {
  const res = await httpClient.put(`/translate/config/${status}/customer`)

  return get(res, 'data.data')
}
