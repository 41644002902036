import { uploadFile } from '@renderer/api'
import { Upload } from '@renderer/misc/upload'
import { useUserStore } from '@renderer/store/useUserStore'
import { getFileExtension } from '@renderer/utils'
import { useMutation } from '@tanstack/react-query'
import { v4 } from 'uuid'

export const useUploadFileManage = () => {
  const { mutateAsync: getS3Data, isPending } = useMutation({
    mutationFn: uploadFile
  })

  const { user } = useUserStore.getState()
  /**
   * 上传文件
   * @param file 文件
   * @param file 文件目录地址
   * @param conversationId 组id
   * @param name 上传类型
   */
  const handleUploadFileManage = async (file, path = 'im/message/'): Promise<any> => {
    const fileExtension = getFileExtension(file?.name)
    const { data: ossPassport } = await getS3Data()
    // 另外聊天文件的objectkey定成 im/message/{tenantId}/{yyyyMMdd}/{uuid}.{文件名后缀}
    // File to upload
    const filepath =
      path === 'tenant/user/'
        ? `${path}${user?.tenantId}/${user.userId}/${v4()}${fileExtension}`
        : `${path}${user?.tenantId}/${v4()}${fileExtension}`
    const uploader = new Upload({
      expirationTime: (ossPassport?.expirationTime || '').toString(),
      storageType: 'minio',
      accessKeyId: ossPassport?.accessKey || '',
      accessKeySecret: ossPassport?.secretKey || '',
      securityToken: ossPassport?.sessionToken || '',
      bucket: ossPassport?.bucket || '',
      endpoint: ossPassport?.endpoint || '',
      region: ossPassport?.region
      // 分片上传 size
      // minShardSize?: number;
      // oss 刷新临时访问凭证。
      // refreshSTSToken?: () => Promise<OssConfig>;
    })
    const url = await uploader.put(filepath, file) // 已上传到minIO
    return url
  }

  return {
    handleUploadFileManage,
    isPending
  }
}
