import {
  ApiReqFn,
  ApiRespSerializeFn,
  ClientResponse,
  httpClient
} from '../../http-client/httpClient'
import { AccountType } from '../model'
import { trimAll } from '../../utils'

interface RequestBody {
  type: AccountType
  account: string
  captcha: string
}

interface ApiResp extends ClientResponse {
  data: string
}
interface Data extends ClientResponse {
  data: { tempToken: string }
}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return {
    ...resp,
    data: {
      tempToken: resp.data
    }
  }
}
// 验证重置密码验证码
export const mutationValidResetPasswordCaptcha: ApiReqFn<RequestBody, Data> = async (payload) => {
  const { data } = await httpClient.post<ApiResp>('/account/check/reset-pwd-captcha', {
    ...payload,
    account: trimAll(payload.account)
  })

  return serialize(data)
}
