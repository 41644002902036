import type { UploadConfig, PutOptions, MultipartOptions, SignedUrlOptions } from './type'
import { S3 } from './s3'
import { OSS } from './oss'
import { DEFAULT_MIN_SHARD_SIZE } from './util'

export class Upload {
  private readonly options: UploadConfig = {} as UploadConfig
  private readonly ossClient!: OSS
  private readonly s3Client!: S3

  constructor(options: UploadConfig) {
    this.options = {
      minShardSize: DEFAULT_MIN_SHARD_SIZE,
      ...options
    }

    if (!this.options.accessKeyId) {
      throw new Error('Please provide accessKeyId')
    }

    if (this.options.storageType === 'oss') {
      this.ossClient = new OSS(this.options)
    } else {
      this.s3Client = new S3(this.options)
    }
  }

  get client(): OSS | S3 {
    if (this.options.storageType === 'oss') {
      return this.ossClient
    }
    return this.s3Client
  }

  async getSignedUrl(
    filePath: string,
    { unhoistableHeaders, expires = 3600, ...options }: SignedUrlOptions = {}
  ) {
    if (this.options.storageType === 'oss') {
      return this.ossClient.getSignedUrl(filePath, {
        expires,
        ...options
      })
    } else {
      return await this.s3Client.getSignedUrl(filePath, {
        expires,
        unhoistableHeaders
      })
    }
  }

  getObjectUrl(filePath: string) {
    return this.client.getObjectUrl(filePath)
  }

  async put(filePath: string, file: File, options?: PutOptions) {
    try {
      const res = await this.client.put(filePath, file, options)
      return res
    } catch (error) {
      console.log('error', error)
    }
  }

  async multipartUpload(filePath: string, file: File, options: MultipartOptions) {
    return await this.client.multipartUpload(filePath, file, options)
  }

  async send(filePath: string, file: File, options?: PutOptions | MultipartOptions) {
    if (file.size < (this.options.minShardSize as number)) {
      return await this.put(filePath, file, options)
    }
    return await this.multipartUpload(filePath, file, options as MultipartOptions)
  }
}
