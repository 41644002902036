import React, { FC, useEffect, useRef, useState } from 'react'
import TextInput from '../../../components/TextInput'
import { httpClient } from '../../../http-client/httpClient'
import SettingDialog from '../../../components/SettingDialog'
import { fetchNickName } from '../../../api/setting/fetchNickName'
import { useMutation } from '@tanstack/react-query'
import { message } from 'css-sdk/src/components/message'
import { fetchPhoneOrEmail } from '../../../api/setting/fetchPhoneOrEmail'
import { AccountType } from '../../../api/model'
import { fetchResetPassword } from '../../../api/setting/editResetPassword'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@brisk/ui'
import { useUploadFileManage } from '../../../hooks/manager/useUploadFileManage'
import { fetchAvatar } from '../../../api/setting/fetchAvatar'
import '../Settings/style/index.css'
import { InputInterface } from './type'
import WithRegard from './components/withRegard'
import { disconnectIM } from '../../../lib/sdk/juggle'
import { isElectron } from '../../../utils'
import { APP_ROUTER_PATH } from '../../../router'
import { useUserStore } from '../../../store/useUserStore'
import { useAssignedStore } from '../../../store/useAssignedStore'
import { useNavigate } from 'react-router-dom'
import AvatarSettings from '../../../components/Avatar-settings'
import { User } from '../../../model/data/user'
import LanguageSetting from './components/languageSetting'
import { useTranslation } from 'react-i18next'
import TranslationSettings from './components/translationSettings'

const Settings: FC = () => {
  const [avatarLoading, setAvatarLoading] = useState(false) // 头像上传loading
  const [useData, setData] = useState({
    avatar: '',
    nickname: '',
    companies: '',
    phone: '',
    email: '',
    password: '············'
  }) // 更新信息数据
  const user = useUserStore.getState().user
  const { setUser } = useUserStore()
  const refType = useRef('') // 弹窗类型
  const [open, setOpen] = useState(false) // 弹窗开启状态
  const { setOutLogin } = useUserStore()
  // 昵称修改
  const { mutateAsync: fetchAsyncNickName } = useMutation({
    mutationFn: fetchNickName
  })
  // 头像修改
  const { mutateAsync: fetchAsyncAvatar } = useMutation({
    mutationFn: fetchAvatar
  })
  // 手机绑定or邮箱绑定
  const { mutateAsync: fetchAsyncPhoneOrEmail } = useMutation({
    mutationFn: fetchPhoneOrEmail
  })
  // 修改密码
  const { mutateAsync: fetchAsyncResetPassword } = useMutation({
    mutationFn: fetchResetPassword
  })
  const { clear } = useUserStore()
  const { clearChatListInfo } = useAssignedStore()
  const navigate = useNavigate()
  const { handleUploadFileManage } = useUploadFileManage()
  const { t } = useTranslation()

  useEffect(() => {
    initUserDetails()
    return () => {}
  }, [])

  // 初始化用户详情
  async function initUserDetails() {
    try {
      const { data } = await httpClient.get<InputInterface>(`/bg/account`)
      setData({
        avatar: data.data.avatarUrl,
        nickname: data.data.nickname,
        companies: data.data.company,
        phone: data.data.phone,
        email: data.data.email,
        password: '············'
      })
    } catch (e) {
      console.info(e)
    }
  }

  /**
   * 提交
   * @param dataObj
   */
  async function handleSubmit(dataObj) {
    switch (dataObj.type) {
      case 'nickname':
        fetchAsync(fetchAsyncNickName, { avatarUrl: '', nickname: dataObj.value })
        setData({ ...useData, nickname: dataObj.value })
        break
      case 'phone':
        await fetchAsync(fetchAsyncPhoneOrEmail, {
          type: AccountType.SMS,
          account: dataObj.value,
          code: dataObj.captcha
        })
        setData({ ...useData, phone: dataObj.value })
        break
      case 'email':
        await fetchAsync(fetchAsyncPhoneOrEmail, {
          type: AccountType.EMAIL,
          account: dataObj.value,
          code: dataObj.captcha
        })
        setData({ ...useData, email: dataObj.value })
        break
      case 'password':
        await fetchAsync(fetchAsyncResetPassword, {
          oldPassword: dataObj.originalPassword,
          newPassword: dataObj.newPassword
        })
        break
      default:
        break
    }
    message(t('accountInformation.modifiedsuccessfully'), 'success')
    handleCancel()
  }
  // 统一接口管理
  async function fetchAsync(fetchAsyncFun, params) {
    await fetchAsyncFun(params)
  }

  /**
   * 点击获取当前弹窗类型
   * @param type
   */
  function handleClick(type: string) {
    refType.current = type
    setOpen(true)
  }

  /**
   * 弹窗动态变化
   * @param e
   */
  function onOpenChange(e) {
    setOpen(e)
    setData(useData)
  }

  // 弹窗关闭
  function handleCancel() {
    setOpen(false)
  }

  /**
   * 图片上传成功回调
   * @param event
   */
  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAvatarLoading(true)
    const file = event?.target?.files[0]
    const res = await handleUploadFileManage(file, 'tenant/user/')
    // const suffix = /.[^.]+$/.exec(res)[0] // 获取图片后缀名
    const path = 'tenant/user/' + res.split('tenant/user/')[1]
    try {
      setData({ ...useData, avatar: useData.avatar })
      const datas = await fetchAsyncAvatar({ avatarUrl: path, nickname: '' })
      setAvatarLoading(false)
      setUser({ ...user, avatarUrl: datas.data } as User)
      setData({ ...useData, avatar: datas.data })
      message(t('accountInformation.modifiedsuccessfully'), 'success')
    } catch (e) {
      message(e.data.msg, 'error')
      setData({ ...useData })
    }
  }
  const signOut = async (): Promise<void> => {
    disconnectIM()
    await setOutLogin(true)
    await clear()
    clearChatListInfo()
    isElectron ? navigate(APP_ROUTER_PATH.INPUT_ACCOUNT) : window.location.replace('/')
  }
  return (
    <div className="w-full h-full overflow-y-auto">
      <section>
        <h1 className="ml-4 mt-5 text-lg font-medium">{t('common.accountinformation')}</h1>

        <article className="ml-3 mt-4 mr-3 pt-6 pl-6 bg-[#F8FAFC] rounded-xl">
          <AvatarSettings
            avatar={useData.avatar}
            avatarLoading={avatarLoading}
            onChange={onChange}
          />
          <TextInput
            modalities="editable"
            label={t('accountInformation.nickname')}
            value={useData.nickname}
            editStatus={true}
            onClick={handleClick}
            type="nickname"
          />
          <TextInput
            label={t('accountInformation.affiliated_enterprise')}
            value={useData.companies}
            onClick={handleClick}
            type="companies"
          />
          <TextInput
            label={t('accountInformation.phone')}
            value={useData.phone}
            editStatus={!useData.phone}
            onClick={handleClick}
            type="phone"
          />
          <TextInput
            label={t('accountInformation.mailbox')}
            value={useData.email}
            editStatus={!useData.email}
            onClick={handleClick}
            type="email"
          />
          <TextInput
            modalities="editable"
            label={t('accountInformation.login_password')}
            value={useData.password}
            editStatus={true}
            onClick={handleClick}
            type="password"
          />
        </article>

        <SettingDialog
          value={useData[refType.current]}
          type={refType.current}
          open={open}
          onsubmit={handleSubmit}
          onCancel={handleCancel}
          onOpenChange={onOpenChange}
        />
      </section>

      <section>
        <h1 className="ml-4 mt-5 text-lg font-medium">{t('common.systemsetting')}</h1>

        <article className="ml-3 mt-4 mr-3 pt-6 pl-6 pb-6 bg-[#F8FAFC] rounded-xl">
          <LanguageSetting />

          {/* 翻译设置 */}
          <TranslationSettings />

          <WithRegard />
        </article>
      </section>

      <AlertDialog>
        <AlertDialogTrigger>
          <div className="ml-3 mt-3">
            <span className="text-sm rounded border-[#ECECE] cursor-pointer border py-1.5 px-1.5 text-[#777A8A]">
              {t('common.quit')}
            </span>
          </div>
        </AlertDialogTrigger>

        <AlertDialogContent className="dialogContent">
          <AlertDialogHeader>
            <AlertDialogTitle>{t('common.log_out')}</AlertDialogTitle>
          </AlertDialogHeader>

          <AlertDialogDescription>
            <span className="text-lingheTextGray">{t('common.logout_content')}</span>
          </AlertDialogDescription>

          <AlertDialogFooter>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
            <AlertDialogAction onClick={signOut}>{t('common.verify')}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default Settings
