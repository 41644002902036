import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { TerminalType } from '../model'

interface RequestParams {
  terminalType: TerminalType
}

interface ApiResp extends ClientResponse {
  data: {
    base64: string
    tempToken: string
  }
}
// interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  // TODO: 处理data
  return resp
}

// 获取扫码登录二维码
export const fetchSignInQRCode = async (payload: RequestParams): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/login/qr-code/${payload.terminalType}`, {})

  return serialize(data)
}
