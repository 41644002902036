import { FC, useId, useState } from 'react'
import {
  Button,
  CardContent,
  CardFooter,
  Checkbox,
  Input,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@brisk/ui'
import { useNavigate } from 'react-router-dom'
import { SignInSwitcher } from './components/SignInSwitcher'
import { SignInHeader } from './components/SignInHeader'
import { AccountType } from '@renderer/api/model'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { PhoneInput } from '@renderer/components/phone-input'
import { defaultFormValues, IFormInput } from './InputAccount'
import { APP_ROUTER_PATH } from '@renderer/router'
import { Mail } from 'lucide-react'
import { inputIconClassName } from '@renderer/utils/consts'
import { useSignStore } from '@renderer/store/useSignStore'

export const RegistrationAccount: FC = () => {
  const readId = useId()
  const autoSiginId = useId()
  const navigate = useNavigate()
  const [currentTabValue, setCurrentTabValue] = useState(AccountType.EMAIL)
  const [autoSigin, setAutoSigin] = useState(false)
  const { setFifteenAutoSingin } = useSignStore()
  // const { data, isPending, mutate, mutateAsync } = useMutation({
  //   mutationFn: fetchTenantList
  // })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    const account = currentTabValue === AccountType.SMS ? formData.phone : formData.email
    // const { data } = await mutateAsync({
    //   account: account.replace(/\s/g, '')
    // })
    console.log('account--->', account)
    navigate(APP_ROUTER_PATH.CREATE_COMPANY)
  }

  return (
    <>
      <SignInSwitcher />
      <SignInHeader />
      <CardContent className="">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            defaultValue={currentTabValue}
            className=""
            onValueChange={(value) => {
              setCurrentTabValue(value as AccountType)
            }}
          >
            <TabsList className="mb-5">
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.EMAIL}
              >
                邮箱
              </TabsTrigger>
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.SMS}
              >
                手机号
              </TabsTrigger>
            </TabsList>
            <TabsContent value={AccountType.SMS}>
              <div className="grid gap-4 h-12">
                <div className="grid gap-2">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => <PhoneInput {...field} required className="h-full" />}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value={AccountType.EMAIL}>
              <div className="grid gap-4 h-12">
                <div className="grid gap-2">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <>
                        <div className="relative">
                          <Input
                            placeholder="m@example.com"
                            className="h-full pl-9 w-full"
                            {...field}
                          />
                          <Mail strokeWidth={1.15} size={20} className={inputIconClassName} />
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </TabsContent>
          </Tabs>
          <Button type="submit" className="w-full mt-4 h-12">
            下一步
          </Button>
        </form>
      </CardContent>

      <CardFooter>
        <div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id={autoSiginId}
              checked={autoSigin}
              onCheckedChange={(e: boolean) => {
                setFifteenAutoSingin(e)
                setAutoSigin(e)
              }}
            />
            <Label
              htmlFor={autoSiginId}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              15天内自动登录
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox id={readId} />
            <Label
              htmlFor={readId}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              我已阅读并同意
              <Button
                variant="link"
                className="p-0 m-0"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  window.open('https://www.aiochat.im/privacy_policy', '_blank')
                }}
              >
                <span>服务协议、隐私权政策</span>
              </Button>
            </Label>
          </div>
        </div>
      </CardFooter>
    </>
  )
}
