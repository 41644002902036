import {apiClient, ApiRespSerializeFn, ClientResponse} from "../../apiClient";

/**
 * RoleUpdateStatusDTO
 */
export interface params {
    /**
     * 离职用户id
     */
    userId: number | string
    /**
     * 转交用户id
     */
    transferUserId: number | string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
    return resp
}

/**
 * 人员转交离职
 * @param param
 * @returns
 */
export const fetchBgUserDepart = async (param: params): Promise<ApiResp> => {
    const { data } = await apiClient.put<ApiResp>(`/bg/user/depart`, param)

    return serialize(data)
}
