import axios from 'axios'
import { useUserStore } from '../store/useUserStore'
import { message } from '@renderer/components/message'
import { RESPONSE_CODE } from './httpClient'
import { APP_ROUTER_PATH, router } from '../router'
import { useSignStore } from '../store/useSignStore'
import { isLoginPath } from '../utils/isLoginPath'
import i18n from 'i18next'

let hasShownTokenExpiredMessage = false

const language_http = {
  'zh-CN': 'zh_CN',
  zh: 'zh_CN',
  en: 'en_US'
  // tc: 'zh_TW',
  // ar: 'ar_SA'
}
export type ClientResponse = {
  code: number
  msg: string
  traceId: string
}
export type ApiReqFn<Payload, Data extends ClientResponse> = (payload: Payload) => Promise<Data>

export interface ApiRespSerializeFn<ApiResp extends ClientResponse, Data extends ClientResponse> {
  (resp: ApiResp): Data
}

// const navigate = useNavigate()
console.log(import.meta.env.VITE_API_URL)

export const httpTenantClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,

  timeout: 58_000,
  adapter: 'fetch',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng')
      ? language_http[localStorage.getItem('i18nextLng')]
      : 'en_US'
  }
})

httpTenantClient.interceptors.request.use((config) => {
  console.info('tenant', localStorage.getItem('i18nextLng'))
  config.headers['Accept-Language'] = localStorage.getItem('i18nextLng')
    ? language_http[localStorage.getItem('i18nextLng')]
    : 'en_US'
  const { user } = useUserStore.getState()
  const token = user?.token
  if (token) {
    config.headers.Authorization = `${token}`
  }
  return config
})

const ignoreCodes = [10016]
httpTenantClient.interceptors.response.use((response) => {
  if (ignoreCodes.includes(response.data.code)) {
    return response
  }
  // 登录过期
  if (response?.data?.code === RESPONSE_CODE.token_overdue) {
    const { user, clear } = useUserStore.getState()
    clear()
    const { setRedirectUrl } = useSignStore.getState()
    if (!isLoginPath(router.state.location.pathname)) {
      setRedirectUrl(router.state.location.pathname)
      router.navigate(APP_ROUTER_PATH.INPUT_ACCOUNT)
    }
    if (!hasShownTokenExpiredMessage) {
      message(i18n.t('common.loginhasexpiredpleaseloginagain'), 'error')
      hasShownTokenExpiredMessage = true
    }
  } else {
    if (response?.data?.code !== RESPONSE_CODE.success) {
      message(response?.data?.msg, 'error')
      throw new Error(response.data.msg)
    } else {
      hasShownTokenExpiredMessage = false
    }
  }

  return response
})
