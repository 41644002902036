import { cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@brisk/ui'
import { FC, memo } from 'react'

interface TooltipCompProps {
  content?: string
  className?: string
  children: React.ReactNode
}

const TooltipComp: FC<TooltipCompProps> = ({ children, content, className }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent
          className={cn(
            'bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md',
            className
          )}
        >
          {content}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default memo(TooltipComp)
