import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input
} from '@brisk/ui'
import { FC, memo, useContext, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { IUpdateUserDetailInfoDTO, IUserDtailInfoVO } from '@renderer/api'
import { Controller, useForm } from 'react-hook-form'
import RoleSelectCom from './RoleSelectCom'
import { UserInfoContext } from '../PersonManage'
import { message } from '@renderer/components/message'
import TeamSelectCom from './TeamSelectCom'
import { useTranslation } from 'react-i18next'

interface IEditUserDetailProps {
  open: boolean
  onClose: () => void
  onConfirm: (data: IUpdateUserDetailInfoDTO) => void
  data: IUserDtailInfoVO
}

const defaultValues = {
  nickname: '',
  account: '',
  teamIdList: [],
  roleIdList: []
}

const EditUserDetail: FC<IEditUserDetailProps> = ({ open, onClose, onConfirm, data }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<IUserDtailInfoVO>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })
  const { roleList, teamData } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const onSubmit = (formData): void => {
    if (!formData?.roleIdList) {
      message(t('businessManagement.Pleaseselectrole'), 'error')
      return
    }
    onConfirm && onConfirm(formData)
    onClose && onClose()
  }

  useEffect(() => {
    if (data) reset(data)
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogDescription />
      <AlertDialogContent className="p-0 focus:outline-none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogTitle className="h-[54px] pl-3 pr-3 relative flex items-center justify-between border-b">
            <span>{t('businessManagement.EditPersonnelInformation')}</span>
            <ReactSVG
              src={close}
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultValues)
                onClose && onClose()
              }}
            />
          </AlertDialogTitle>

          <div className="p-4 flex flex-col gap-3 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 text-sm">
            <Controller
              name="nickname"
              control={control}
              rules={{
                required: t('businessManagement.Pleaseentercustomerservicename'),
                maxLength: {
                  value: 20,
                  message: t(
                    'businessManagement.Customerservicenamemustexceedcharacterspleasereenter'
                  )
                }
              }}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    <span className="text-lingheError">*</span>
                    {t('businessManagement.CustomerServiceName')}
                  </span>
                  <Input
                    placeholder={t('businessManagement.Pleaseentercustomerservicename')}
                    {...field}
                    required
                  />
                  {errors?.nickname && (
                    <p className="text-lingheError">{errors?.nickname?.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="account"
              control={control}
              disabled
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('businessManagement.CustomerServiceAccount')}</span>
                  <Input
                    placeholder={t(
                      'businessManagement.Pleaseenteryourcustomerserviceaccountnumber'
                    )}
                    {...field}
                  />
                </div>
              )}
            />
            <Controller
              name="roleIdList"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    <span className="text-lingheError">*</span>
                    {t('businessManagement.RoleSetting')}
                  </span>
                  <RoleSelectCom<IUserDtailInfoVO, 'roleIdList'>
                    roleList={roleList}
                    field={field}
                  />
                </div>
              )}
            />
            <Controller
              name="teamIdList"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('businessManagement.GroupSettings')}</span>
                  <TeamSelectCom<IUserDtailInfoVO, 'teamIdList'>
                    teamData={teamData}
                    field={field}
                  />
                </div>
              )}
            />
          </div>

          <AlertDialogFooter className="h-[54px] pl-3 pr-3 flex items-center border-t">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultValues)
                onClose && onClose()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.verify')}</Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(EditUserDetail)
