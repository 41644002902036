import { CustomerTagVO } from '@brisk/api'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * 分页搜索DTO
 */
export interface ICustomerReq {
  /**
   * 搜索条件 昵称 邮箱 电话 地址 公司
   */
  keyWord?: string
  pageNum?: number
  pageSize?: number
  /**
   * 0 webSite，1 Telegram
   */
  source?: number
  /**
   * 标签id
   */
  tagId?: number
}
/**
 * PageCustomerPageResultVO
 */
export interface ICustomerResp {
  countId?: string
  current?: number
  maxLimit?: number
  optimizeCountSql?: Response
  optimizeJoinOfCountSql?: boolean
  orders?: OrderItem[]
  pages?: number
  records?: CustomerPageResultVO[]
  searchCount?: Response
  size?: number
  total?: number
}

/**
 * OrderItem
 */
export interface OrderItem {
  asc?: boolean
  column?: string
}

/**
 * 客户page
 */
export interface CustomerPageResultVO {
  id: string | number
  /**
   * 地址
   */
  address?: string
  /**
   * 头像地址
   */
  avatar?: string
  /**
   * 公司名称
   */
  company?: string
  /**
   * 添加日期
   */
  createTime?: Date
  /**
   * 客户id
   */
  customerId?: string
  /**
   * 标签信息
   */
  customerTag?: CustomerTagVO[]
  /**
   * 邮箱
   */
  email?: string
  /**
   * 最后咨询日期
   */
  lastConsultingTime?: Date
  /**
   * 用户姓名
   */
  name?: string
  /**
   * 手机号
   */
  phone?: string
  /**
   * 备注
   */
  remark?: string
  /**
   * 来源：0-webSite，1-Telegram
   */
  source?: number
  /**
   * 标签id
   */
  tagIds?: number[]
  /**
   * 租户id
   */
  tenantId?: number
}

interface ApiResp extends ClientResponse {
  data: ICustomerResp
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取客户列表
 * @param params
 * @returns
 */
export const fetchCustomerList = async (params?: ICustomerReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/customer/page`, params)

  return serialize(data)
}
