import { create } from 'zustand'

type State = {
  unreadCount: number
}

type Action = {
  setUnreadCount: (unreadCount: number) => void
}

export const useUnreadCountStore = create<State & Action>((set) => ({
  unreadCount: 0,
  setUnreadCount: (unreadCount: number): void => {
    set({ unreadCount })
  }
}))
