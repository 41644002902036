import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { WelcomeContentsVO } from './fetchWelcomConfig'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 添加欢迎语话术
 * @param param
 * @returns
 */
export const fetchAddWelcomWords = async (
  param: WelcomeContentsVO & { welcomeConfId: string | number }
): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/welcome/config/content`, param)

  return serialize(data)
}
