import { FC, useEffect, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import {
  Button,
  Checkbox,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@brisk/ui'
import { map } from 'lodash'
import { ReactSVG } from 'react-svg'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import AddWelcomeWords from '../Components/AddWelcomeWords'
import AddWordsModal, { IAddWordsData } from '../Components/AddWordsModal'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchWelcomConfig } from '@renderer/api/manage/fetchWelcomConfig'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import {
  IChangeWelcomeConfigRequestBody,
  fetchChangeWelcomConfig
} from '@renderer/api/manage/fetchChangeWelcomConfig'
import { message } from '@renderer/components/message'
import { fetchWelcomWordsState } from '@renderer/api/manage/fetchWelcomWordsState'
import { fetchDelWelcomWords } from '@renderer/api/manage/fetchDelWelcomWords'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const TitleComp: FC<{ title: string }> = ({ title }) => (
  <div className="relative text-base mb-3 pl-5 after:block after:h-[80%] after:w-0.5 after:rounded after:left-3 after:top-[50%] after:-translate-y-1/2 after:absolute after:bg-primary">
    {title}
  </div>
)

export const TipsIcon: FC<{ content: string; side?: 'bottom' | 'top' | 'right' | 'left' }> = ({
  content,
  side = 'bottom'
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <button className="IconButton" type="reset">
          <ReactSVG src={wordsTips} />
        </button>
      </TooltipTrigger>
      <TooltipContent className="bg-[#3F444C] text-xs w-[282px]" side={side} sideOffset={10}>
        <div>{content}</div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
)

let saveDefaultVal

const index: FC = () => {
  const { t } = useTranslation()
  const [addWordsData, setAddWordsData] = useState<IAddWordsData>({
    title: '',
    open: false,
    type: 'firstChat'
  })

  const { handleSubmit, control, setValue, watch } = useForm<IChangeWelcomeConfigRequestBody>({
    defaultValues: {
      welcomeState: 0,
      firstState: 0,
      firstIntervalTime: 0,
      referralState: 0
    }
  })

  const welcomeStateWatch = watch('welcomeState')

  const { mutateAsync: handleUpdateConig } = useMutation({
    mutationFn: fetchChangeWelcomConfig
  })

  const { mutateAsync: changeWordsState } = useMutation({
    mutationFn: fetchWelcomWordsState
  })

  const { mutateAsync: delWelcomWords } = useMutation({
    mutationFn: fetchDelWelcomWords
  })

  /** 删除欢迎语 */
  const handleDelWelcomWords = async (data: { id: string | number }): Promise<void> => {
    try {
      const res = await delWelcomWords(data)
      if (res.code === RESPONSE_CODE.success) {
        message(t('businessManagement.Deletedsuccessfully'), 'success')
        refetch()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  /** 开启状态改变 */
  const handleStateChange = async (data: {
    id: string | number
    state: number | string
  }): Promise<void> => {
    try {
      const res = await changeWordsState(data)
      if (res.code === RESPONSE_CODE.success) {
        message(t('businessManagement.modifiedsuccessfully'), 'success')
        refetch()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const { data: welcomConfig, refetch } = useQuery({
    queryKey: [fetchWelcomConfig.name],
    queryFn: async () => {
      const res = await fetchWelcomConfig()
      return res.data
    }
  })

  const onSubmit = async (formData): Promise<void> => {
    console.log('formData', formData)
    try {
      const res = await handleUpdateConig(formData)
      if (res.code === RESPONSE_CODE.success) {
        message(t('common.successfulUpdate'), 'success')
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSetDefaultForm = (data: IChangeWelcomeConfigRequestBody): void => {
    const { welcomeState, firstState, firstIntervalTime, referralState } = data
    setValue('welcomeState', welcomeState)
    setValue('firstState', firstState)
    setValue('firstIntervalTime', firstIntervalTime)
    setValue('referralState', referralState)
  }

  useEffect(() => {
    if (welcomConfig) {
      handleSetDefaultForm(welcomConfig)
      saveDefaultVal = { ...welcomConfig }
    }
  }, [welcomConfig])

  return (
    <NavBodyLayout
      title={[t('autoReception.receptionSettings'), t('autoReception.autoReceptionTitle')]}
    >
      <form className="w-full h-full text-sm" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full h-full">
          <div className="h-[54px] shrink-0 border-b">
            <div className="flex items-center pl-3 h-full font-medium">
              {t('autoReception.autoReceptionTitle')}
            </div>
          </div>
          <div className="flex-1 shrink-0 overflow-y-auto pt-4">
            <TitleComp title={t('autoReception.setUp')} />
            <div className="pl-3 pr-3 mb-6">
              <Controller
                name="welcomeState"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-start gap-2">
                    <Switch
                      id="airplane-mode"
                      checked={Boolean(value)}
                      onCheckedChange={(e) => onChange(Number(e))}
                    />
                    <div className="flex flex-col gap-2 [&_p]:text-sm">
                      <p>{t('autoReception.weRecommendEndblingExperience')}</p>
                      <p className="text-lingheTextGray text-xs">
                        {t('autoReception.automaticGreetingsarefirststepproactiveMarketing')}
                      </p>
                    </div>
                  </div>
                )}
              />
            </div>

            {Boolean(welcomeStateWatch) && (
              <>
                <Controller
                  name="firstState"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <div className="mb-6">
                      <TitleComp title={t('autoReception.triggerScenario')} />
                      <div className="flex items-start gap-2 pl-3">
                        <Checkbox
                          id="terms"
                          className="mt-0.5"
                          checked={Boolean(value)}
                          onCheckedChange={(e) => onChange(Number(e))}
                        />
                        <div className="flex flex-col gap-2 [&_p]:text-sm">
                          <p>{t('autoReception.sendwhennewsessioninitiatedfirsttime')}</p>
                          <p className="text-lingheTextGray">
                            {t(
                              'autoReception.sentwhenvisitorinitiatesanonlineconsultationfirsttime'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="firstIntervalTime"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <div className="mb-6 pl-9 text-sm">
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center">
                            {t('autoReception.sendingFrequency')}
                            <Select
                              value={String(value)}
                              onValueChange={(e) => onChange(Number(e))}
                            >
                              <SelectTrigger className="w-16 h-6 mr-2">
                                <SelectValue defaultValue={String(value)}>
                                  {value !== undefined ? value : ''}
                                </SelectValue>
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {map(
                                    Array.from({ length: 25 }, (_, index) => index),
                                    (item) => (
                                      <SelectItem key={item} value={item.toString()}>
                                        {item}
                                      </SelectItem>
                                    )
                                  )}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                            {t('autoReception.hoursafter')}
                            {t(
                              'autoReception.initiatenewconsultationsessionsendautomaticgreetingmessage'
                            )}
                          </div>
                          <div className="text-lingheTextGray text-xs">
                            {t(
                              'autoReception.multipleconsultationsessionsreceivedfromsamevisitorwithinshorttimereceived'
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
                <div className="mb-6 pl-9 text-sm">
                  <div className="flex items-center gap-1 mb-3">
                    {t('autoReception.autoReceptionMessage')}
                    <TipsIcon
                      content={t(
                        'autoReception.Multiplemessagescanenabledsimultaneouslysystemwilltriggerthemrandomly'
                      )}
                    />
                  </div>

                  {/* 首次发起会话时的欢迎语 */}
                  <div className="flex gap-3 flex-wrap">
                    {map(welcomConfig?.firstContents, (item, index) => (
                      <AddWelcomeWords
                        type="firstChat"
                        originList={welcomConfig?.firstContents || []}
                        data={item}
                        index={index}
                        key={item.welcomeContentId}
                        addClick={() =>
                          setAddWordsData({
                            title: t('autoReception.add'),
                            open: true,
                            type: 'firstChat'
                          })
                        }
                        editClick={(data) => {
                          setAddWordsData({
                            title: t('autoReception.add'),
                            open: true,
                            type: 'firstChat',
                            oldData: data
                          })
                        }}
                        delClick={(data) => {
                          handleDelWelcomWords({ id: data?.welcomeContentId || '' })
                        }}
                        onStateChange={(params) => handleStateChange(params)}
                      />
                    ))}
                  </div>
                </div>

                {/*<Controller*/}
                {/*  name="referralState"*/}
                {/*  control={control}*/}
                {/*  render={({ field: { value, onChange } }) => (*/}
                {/*    <div className="flex items-start gap-2 pl-3 mb-6">*/}
                {/*      <Checkbox*/}
                {/*        id="terms"*/}
                {/*        className="mt-0.5"*/}
                {/*        checked={Boolean(value)}*/}
                {/*        onCheckedChange={(e) => onChange(Number(e))}*/}
                {/*      />*/}
                {/*      <div className="flex flex-col gap-2 [&_p]:text-sm">*/}
                {/*        <p>转接后发送欢迎语</p>*/}
                {/*        <p className="text-lingheTextGray">*/}
                {/*          客服转接成功后，优先发送欢迎语。不受频次限制；*/}
                {/*        </p>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*/>*/}
                {/*<div className="mb-6 pl-9 text-sm">*/}
                {/*  <div className="flex items-center gap-1 mb-3">*/}
                {/*    欢迎语话术*/}
                {/*    <TipsIcon content="话术支持添加多个，开启的话术生效若存在多个开启话术，系统将随机发送请至少保持一个话术开启，避免话术无法正常发送" />*/}
                {/*  </div>*/}

                {/* 转接后欢迎语 */}
                {/*<div className="flex gap-3 flex-wrap">*/}
                {/*  {map(welcomConfig?.referralContents, (item, index) => (*/}
                {/*    <AddWelcomeWords*/}
                {/*      type="turnChat"*/}
                {/*      originList={welcomConfig?.referralContents || []}*/}
                {/*      data={item}*/}
                {/*      index={index}*/}
                {/*      key={item.welcomeContentId}*/}
                {/*      addClick={() =>*/}
                {/*        setAddWordsData({ title: '添加欢迎语话术', open: true, type: 'turnChat' })*/}
                {/*      }*/}
                {/*      editClick={(data) => {*/}
                {/*        setAddWordsData({*/}
                {/*          title: '编辑欢迎语话术',*/}
                {/*          open: true,*/}
                {/*          type: 'turnChat',*/}
                {/*          oldData: data*/}
                {/*        })*/}
                {/*      }}*/}
                {/*      delClick={(data) => {*/}
                {/*        handleDelWelcomWords({ id: data?.welcomeContentId || '' })*/}
                {/*      }}*/}
                {/*      onStateChange={(params) => handleStateChange(params)}*/}
                {/*    />*/}
                {/*  ))}*/}
                {/*</div>*/}
                {/*</div>*/}
              </>
            )}
          </div>
          <div className="h-16 shrink-0 border-t drop-shadow">
            <div className="flex items-center gap-2 pl-3 h-full">
              <Button type="submit">{t('common.save')}</Button>
              <Button
                variant="outline"
                onClick={() => {
                  handleSetDefaultForm(saveDefaultVal)
                }}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>

      <AddWordsModal
        welcomeConfId={welcomConfig?.welcomeConfId || ''}
        data={addWordsData}
        onClose={() => setAddWordsData({ ...addWordsData, open: false, oldData: null })}
        onConfirm={() => refetch()}
      />
    </NavBodyLayout>
  )
}

export default index
