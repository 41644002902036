import React, { FC } from 'react'
import loading from '../../src/assets/imgs/loading.png'
import loadingBg from '@renderer/assets/imgs/loadingBg.png'

// 预先计算颜色和位置
// const colors = [
//   'bg-red-500',
//   'bg-yellow-500',
//   'bg-green-500',
//   'bg-blue-500',
//   'bg-indigo-500',
//   'bg-purple-500'
// ]
//
// const positions = Array.from({ length: 6 }, (_, index) => {
//   const radius = 25 // 单位是px
//   const angle = (index / 6) * Math.PI * 2
//   const x = radius * Math.cos(angle)
//   const y = radius * Math.sin(angle)
//   return {
//     top: `${50 + y}%`,
//     left: `${50 + x}%`,
//     transform: 'translate(-50%, -50%)'
//   }
// })

const Loading: FC = () => {
  return (
    <div
      className="fixed top-0 left-0 w-[100vw] h-[100vh]"
      style={{
        zIndex: 999999
      }}
    >
      <style>{`
        @keyframes spinAroundCircle {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .spinner {
          animation: spinAroundCircle 1s linear infinite;
        }
      `}</style>
      <div
        className="absolute left-[50%] -translate-x-1/2 top-20 w-[68px] h-[68px]"
        style={{
          background: `url(${loadingBg}) no-repeat`,
          backgroundSize: 'contain'
        }}
      >
        <img src={loading} alt="loading" className="w-[68px] h-[68px] spinner" />
        {/*{[...Array(6)].map((_, index) => (*/}
        {/*  <span*/}
        {/*    key={index}*/}
        {/*    className={`absolute left-[50%] top-[50%] block w-2 h-2 rounded-full shadow-inner ${colors[index % colors.length]}`}*/}
        {/*    style={positions[index]}*/}
        {/*  />*/}
        {/*))}*/}
      </div>
    </div>
  )
}

export default React.memo(Loading)
