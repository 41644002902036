import { FC, memo, useEffect, useState } from 'react'
import InvitePc from './components/InvitePc'
import InviteMobile from './components/InviteMobile'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { fetchInviteInfoByToken, IInviteInfo } from '@brisk/api'
import { detectDeviceType } from '@renderer/utils'

const InviteAuth: FC = () => {
  const location = useLocation()
  const token = new URLSearchParams(location?.search).get('token')
  const [inviteInfo, setInviteInfo] = useState<IInviteInfo & { token: string }>({
    token,
    account: '',
    accountType: '',
    tenantName: '',
    joined: false
  })
  const isPc = detectDeviceType() === 'pc'

  const { mutateAsync: getInfo } = useMutation({
    mutationFn: fetchInviteInfoByToken
  })

  useEffect(() => {
    getInfo(token).then((res) => {
      setInviteInfo({ ...inviteInfo, ...res?.data })
    })
    document.title = '加入租户验证'
  }, [token])

  return (
    <div className="w-screen">
      {isPc ? <InvitePc inviteInfo={inviteInfo} /> : <InviteMobile inviteInfo={inviteInfo} />}
    </div>
  )
}

export default memo(InviteAuth)
