import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageGroupSaveDTO
 */
export interface AddPersonWordsGroupRequest {
  /**
   * 分组id 新增时不传
   */
  groupId?: number;
  /**
   * 分组名
   */
  groupName: string;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 新增 快捷短语分组
 * @returns
 */
export const fetchAddPersonWordsGroup = async (
  param: AddPersonWordsGroupRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/preset/message/group`,
    param,
  );

  return serialize(data);
};
