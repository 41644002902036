import { Popover, PopoverContent, PopoverTrigger, cn } from '@brisk/ui'
import { IRoleList } from '@renderer/api'
import { find, includes, map, some, union, without } from 'lodash'
import { ControllerRenderProps, Path } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import selectIconRight from '../../../assets/svg/selectIconRight.svg'
import roleSelected from '../../../assets/svg/roleSelected.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type RoleSelectComProps<T, K extends Path<T>> = {
  roleList: IRoleList[]
  field: ControllerRenderProps<T, K>
}

const RoleSelectCom = <T, K extends Path<T>>({
  roleList,
  field
}: RoleSelectComProps<T, K>): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <Popover
      onOpenChange={(e) => {
        setIsOpen(e)
      }}
    >
      <PopoverTrigger asChild>
        <div className="w-full h-9 border rounded-md relative flex items-center gap-2 pl-3 pr-3">
          {(field?.value as K[])?.length
            ? map(field?.value as K[], (valueId) => (
                <span key={valueId}>
                  {find(roleList, (it) => it?.roleId + '' === valueId + '')?.roleName ||
                    t('businessManagement.Pleaseselectrole')}
                </span>
              ))
            : t('businessManagement.Pleaseselectrole')}
          <ReactSVG
            src={selectIconRight}
            className={cn(
              'absolute top-1/2 right-3 -translate-y-1/2 transition',
              isOpen ? 'rotate-90' : ''
            )}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] max-h-[300px] overflow-y-auto flex flex-col gap-1 p-0 -translate-x-1/2 text-sm">
        <div className="w-full h-full p-3 flex flex-col gap-1">
          {map(roleList, (item) => {
            const selected = some(field?.value as K[], (it) => it + '' === item?.roleId + '')
            return (
              <div
                key={item?.roleId + item?.roleName}
                className={cn(
                  'h-8 pl-2 pr-2 flex items-center relative rounded-md cursor-pointer hover:bg-lingheBtnHover',
                  selected ? 'bg-lingheBtnHover' : ''
                )}
                onClick={() => {
                  const values = field?.value as K[]
                  if (includes(values, item?.roleId + ''))
                    field?.onChange(without(values, item?.roleId + ''))
                  else field?.onChange(union(values, [item?.roleId + '']))
                }}
              >
                {item?.roleName}

                {selected && (
                  <ReactSVG
                    src={roleSelected}
                    className="absolute right-2 top-[50%] translate-y-[-50%]"
                  />
                )}
              </div>
            )
          })}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default RoleSelectCom
