import { cn } from '@brisk/ui'
import { forwardRef, InputHTMLAttributes } from 'react'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const NoFocusInput = forwardRef<HTMLInputElement, InputProps>(
  ({ maxLength = 100, className, type, ...props }, ref) => {
    return (
      <input
        maxLength={maxLength}
        type={type}
        className={cn(
          'flex h-full w-full rounded-md border border-[#ECECEC] border-input bg-transparent px-3 py-2 text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-[#A5A7B1] focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
NoFocusInput.displayName = 'NoFocusInput'

export { NoFocusInput }
