import { forwardRef, useState } from "react";
import { EyeIcon } from "lucide-react";
import d_password from '../../../assets/svg/d_password.svg'
import { Button, Input, InputProps } from "@brisk/ui";
import { cn } from "@brisk/ui";
import {ReactSVG} from "react-svg";
const PasswordEditInput = forwardRef<HTMLInputElement, InputProps>(
    ({ className, onInput, ...props }, ref) => {
        const [showPassword, setShowPassword] = useState(false);
        const disabled = props.disabled;

        return (
            <div className="relative">
                <Input
                    maxLength={16}
                    onInput={onInput}
                    type={showPassword ? "text" : "password"}
                    className={cn("hide-password-toggle pr-4 py-4", className)}
                    ref={ref}
                    {...props}
                />
                <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                    onClick={() => setShowPassword((prev) => !prev)}
                    disabled={disabled}
                >
                    {showPassword ? (
                        <EyeIcon className="h-4 w-4" aria-hidden="true" />
                    ) : (
                        <ReactSVG src={d_password} className="h-4 w-4" aria-hidden="true"/>
                    )}
                    <span className="sr-only">
            {showPassword ? "Hide password" : "Show password"}
          </span>
                </Button>

                {/* hides browsers password toggles */}
                <style>{`
					.hide-password-toggle::-ms-reveal,
					.hide-password-toggle::-ms-clear {
						visibility: hidden;
						pointer-events: none;
						display: none;
					}
				`}</style>
            </div>
        );
    },
);
PasswordEditInput.displayName = "PasswordEditInput";

export { PasswordEditInput };
