import { createContext, FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { Button, cn } from '@brisk/ui'
import { ReactSVG } from 'react-svg'
import { Plus } from 'lucide-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ITeamResponse, TeamUser } from '@renderer/api/team'
import TeamTreeComp from '../Components/TeamTreeComp'
import { debounce, forEach, map, reduce } from 'lodash'
import {
  IUserDtailInfoVO,
  IUserPageDetail,
  IUserPageRes,
  fetchAddTeamGroup,
  fetchRoleList,
  fetchUpdateUserDetailInfo,
  fetchUserDetailInfo,
  fetchUserPage,
  IAddCustomerTableData,
  IAddInviteRequestBody,
  fetchAddInvite,
  fetchReInvite
} from '@renderer/api'
import noCustomer from '../../../assets/svg/noCustomer.svg'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import EditUserDetail from '../Components/EditUserDetail'
import { fetchTeamList } from '@renderer/api/team/fetchTeamList'
import { message } from '@renderer/components/message'
import DelCustolerModal from '../Components/DelCustolerModal'
import AddGroup from '../Components/AddGroup'
import ChooseTeamUserList from '../Components/ChooseTeamUserList'
import OutWork from './OutWork'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { useUserStore } from '@renderer/store/useUserStore'
import { fetchDelTeam, fetchTeamTree, fetchBgUserSessionCount, fetchBgUserDepart } from '@brisk/api'
import { useTranslation } from 'react-i18next'
import NavBarTools from '../../sign/components/navBarTools'
import AddCustomerModal from '../Components/AddCustomerModal'
import { PersonPageAtionRef, IUserInfoContext } from './type'
import SelectGroupPerson from '../../../components/SelectGroupPerson'

export const UserInfoContext = createContext<IUserInfoContext>({
  roleList: [],
  teamData: [],
  teamTree: [],
  indexedTeamTree: [],
  userList: [],
  setAddGroupUserList: () => {}
})
const index: FC = () => {
  const [active, setActive] = useState(0)
  const [searchVal, setSearchVal] = useState('')
  // const [searchList, setSearchList] = useState<ITeamResponse[]>([])
  const [userListData, setUserListData] = useState<IUserPageRes>({})
  const [teamList, setTeamList] = useState([])
  const [inviteStatus, setInviteStatus] = useState(-1)
  const [transferUserId, setTransferUserId] = useState(-1 || '-1')
  const [roleData, setRoleData] = useState<IAddCustomerTableData | null>(null)
  const [open, setOpen] = useState(false)
  const delStatus = useRef({ state: false })
  const { t } = useTranslation()
  const defaultTeam = {
    teamId: '-1',
    teamName: t('common.full'),
    userList: []
  }
  const [selectTeam, setSelectTeam] = useState<TeamUser & ITeamResponse>(defaultTeam)

  const [showAddGroupModal, setShowAddGroupModal] = useState<{
    open: boolean
    data: ITeamResponse | null
  }>({
    open: false,
    data: null
  })
  const [showGroupSelectModal, setGroupSelectModal] = useState<boolean>(false)

  const [addGroupUserList, setAddGroupUserList] = useState<TeamUser[]>([])

  const [editUserData, setEditUserData] = useState<{
    open: boolean
    data: IUserDtailInfoVO | null
  }>({
    open: false,
    data: null
  })
  const [delData, setDelData] = useState<{
    open: boolean
    userData: IUserPageDetail | null
  }>({
    open: false,
    userData: null
  })
  const [delTeamData, setDelTeamData] = useState<{
    open: boolean
    data: ITeamResponse | null
  }>({
    open: false,
    data: null
  })

  const { user } = useUserStore()

  const pageationRef = useRef<PersonPageAtionRef>({
    currentPage: 1,
    itemsPerPage: 10,
    resetPaging: () => {}
  })
  // const treeRef = useRef<{ handleSetOpendList: (v: (string | number)[]) => void }>(null)

  const { data: teamTree } = useQuery({
    queryKey: [fetchTeamTree.name],
    queryFn: async () => {
      const res = await fetchTeamTree()
      return res?.data
    }
  })

  // 创建一个userId到nickname的映射
  const indexedTeamTree = useMemo(
    () =>
      reduce(
        teamTree,
        (acc, team) => {
          forEach(team?.userList, (user) => {
            acc[user?.userId] = user?.nickname
          })
          return acc
        },
        {}
      ),
    [teamTree]
  ) as []
  const { mutateAsync: putBgUserDepart } = useMutation({
    mutationFn: fetchBgUserDepart
  })
  const { mutateAsync: getBgUserSessionCount } = useMutation({
    mutationFn: fetchBgUserSessionCount
  })
  const { mutateAsync: getUserPageList, isPending } = useMutation({
    mutationFn: fetchUserPage
  })
  const { mutateAsync: reInvite, isPending: reinviteLoading } = useMutation({
    mutationFn: fetchReInvite
  })
  const { mutateAsync: addInvite, isPending: addLoading } = useMutation({
    mutationFn: fetchAddInvite
  })
  const { mutateAsync: getUserInfo } = useMutation({
    mutationFn: fetchUserDetailInfo
  })

  /** 添加小组 | 保存团队 */
  const { mutateAsync: saveGroup } = useMutation({
    mutationFn: fetchAddTeamGroup
  })

  /** 删除团队 */
  const { mutateAsync: deleteGroup } = useMutation({
    mutationFn: fetchDelTeam
  })

  const { mutateAsync: updateUserInfo } = useMutation({
    mutationFn: fetchUpdateUserDetailInfo
  })

  /** 组 | 团队 */
  const { data: teamData, refetch: refetchTeamList } = useQuery({
    queryKey: [fetchTeamList.name],
    queryFn: fetchTeamList
  })

  useEffect(() => {
    if (teamData) setTeamList([...(teamData?.data || [])])
  }, [teamData])

  /** 角色 */
  const { data: roleList } = useQuery({
    queryKey: [fetchRoleList.name],
    queryFn: fetchRoleList
  })

  // const handleSearch = useCallback(
  //   debounce(<T extends ITeamResponse>(val: string, targetArr: T[]): void => {
  //     const res = map(targetArr, (item) => {
  //       const listRes = filter(
  //         item?.userList,
  //         (user) =>
  //           includes(user?.nickname, val) ||
  //           includes(user?.phone, val) ||
  //           includes(user?.email, val)
  //       )
  //       if (listRes?.length) {
  //         // treeRef?.current?.handleSetOpendList([item?.teamId + ''])
  //         return {
  //           ...item,
  //           userList: listRes
  //         }
  //       } else {
  //         return null
  //       }
  //     })
  //     const filteredRes = res.filter(Boolean) as T[]
  //     setSearchList(filteredRes)
  //   }, 500),
  //   [teamTree]
  // )

  const handleUserList = debounce(async (param) => {
    try {
      const p = { ...param }
      if (selectTeam?.teamId !== '-1') {
        p['teamId'] = selectTeam?.teamId
      }
      const res = await getUserPageList(p)
      if (res?.code === RESPONSE_CODE.success) {
        setUserListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }, 500)
  const columns: ColumnDef<IUserPageDetail>[] = useMemo(
    () => [
      {
        id: 'nickname',
        header: (): JSX.Element => <div>{t('businessManagement.Name')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.nickname}</div>
        }
      },
      {
        id: 'account',
        header: (): JSX.Element => <div>{t('businessManagement.AccountNumber')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div>
              <p>{original?.phone}</p>
              <p>{original?.email}</p>
            </div>
          )
        }
      },
      {
        id: 'roleName',
        header: (): JSX.Element => <div>{t('businessManagement.Role')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.roleName}</div>
        }
      },
      {
        id: 'teamName',
        header: (): JSX.Element => <div>{t('businessManagement.Group')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.teamName}</div>
        }
      },
      {
        id: 'inviteStatus',
        header: (): JSX.Element => <div>{t('chatFrame.Status')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className={cn(original?.inviteStatus > 0 ? 'text-[#23D881]' : 'text-[#FF4821]')}>
              {original?.inviteStatus > 0 ? t('chatFrame.Joined') : t('chatFrame.NotJoined')}
            </div>
          )
        }
      },
      {
        id: 'hireDate',
        header: (): JSX.Element => <div>{t('businessManagement.JoinTime')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div>
              {original?.hireDate ? dayjs(original?.hireDate).format('YYYY-MM-DD HH:mm:ss') : ''}
            </div>
          )
        }
      },
      {
        id: 'edit',
        header: (): JSX.Element => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-2 [&_button]:p-0">
              <Button
                variant="link"
                className="hover:no-underline"
                onClick={async () => {
                  if (original?.inviteStatus === 1) {
                    try {
                      const res = await getUserInfo(original?.userId)
                      if (res?.code === RESPONSE_CODE.success) {
                        setEditUserData({
                          open: true,
                          data: res?.data
                        })
                      }
                    } catch (error) {
                      console.log('error', error)
                    }
                  } else {
                    setRoleData(original)
                    setOpen(true)
                  }
                }}
              >
                {original?.inviteStatus === 1 ? t('login.Edit') : t('businessManagement.Reinvite')}
              </Button>
              {original.userId === user.userId ? (
                ''
              ) : (
                <>
                  <span className="text-[#ECECEC]">|</span>
                  <Button
                    variant="link"
                    className="text-lingheError hover:no-underline"
                    onClick={async () => {
                      let sessionCount
                      if (original?.inviteStatus === 1) {
                        sessionCount = await getBgUserSessionCount(original?.userId)
                        setDelData({
                          open: true,
                          userData: { ...original, sessionCount: sessionCount.data }
                        })
                      } else {
                        setDelData({
                          open: true,
                          userData: original
                        })
                      }
                    }}
                  >
                    {t('chatFrame.removing')}
                  </Button>
                </>
              )}
            </div>
          )
        }
      }
    ],
    []
  )
  // 搜索
  const handleInput = (value: string) => {
    setSearchVal(value)
  }
  // 查询状态
  const handleChange = (val: number) => {
    setInviteStatus(val)
  }
  // 点击按钮弹窗
  const handleOpen = (val: boolean) => setOpen(val)
  // 重置
  const handleReset = async () => {
    setSearchVal('')
    setInviteStatus(-1)
    setSelectTeam({ teamId: '-1', teamName: '全部' })
  }
  // 添加成员
  const handleAddInvite = (param: IAddInviteRequestBody): void => {
    try {
      addInvite(param).then((res) => {
        if (res?.code === RESPONSE_CODE.success)
          message(t('businessManagement.Memberaddedsuccessfully'), 'success')
        setOpen(false)
        initHandleUserList()
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  // 人员转交离职-转交用户id
  const handleGroupPersonChange = (value: string) => {
    setTransferUserId(value)
  }
  // 重新邀请
  const handleReInvite = (params: IAddInviteRequestBody): void => {
    try {
      reInvite(params).then((res) => {
        if (res?.code === RESPONSE_CODE.success)
          message(t('businessManagement.ReInviteSuccessful'), 'success')
        setOpen(false)
        initHandleUserList()
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    // handleSearch(teamTree)
    initHandleUserList()
  }, [selectTeam])
  useEffect(() => {
    initHandleUserList()
  }, [searchVal, inviteStatus])

  /**
   *  初始化分页查询
   */
  async function initHandleUserList() {
    pageationRef.current.resetPaging()
    const param = {
      pageNum: 1,
      pageSize: 10,
      keyWord: searchVal,
      jobStatus: 1,
      inviteStatus: inviteStatus
    }
    inviteStatus === -1 && delete param.inviteStatus
    handleUserList(param)
  }
  return (
    <UserInfoContext.Provider
      value={{
        roleList: roleList?.data || [],
        teamData: teamList || [],
        teamTree: teamTree || [],
        indexedTeamTree: indexedTeamTree || [],
        userList: addGroupUserList,
        setAddGroupUserList
      }}
    >
      <NavBodyLayout title={[t('login.agents')]} loading={[isPending, addLoading, reinviteLoading]}>
        <div className="flex flex-col h-full w-full relative">
          <div className="flex items-center gap-8 shrink-0 p-3 h-[54px] mb-[-1px] [&_span]:text-[#1D223C] border-b [&_span]:cursor-pointer [&_span]:relative [&_.active]:text-[#247CFF] [&_.active]:font-semibold [&_.active:after]:content-[''] [&_.active:after]:w-full [&_.active:after]:h-[3px] [&_.active:after]:rounded-md [&_.active:after]:absolute [&_.active:after]:left-[50%] [&_.active:after]:-bottom-4 [&_.active:after]:-translate-x-1/2 [&_.active:after]:bg-primary">
            <span
              className={cn('mb-[-1px] text-sm font-normal', [active === 0 ? 'active' : ''])}
              onClick={() => setActive(0)}
            >
              {t('businessManagement.incumbency')}
            </span>
            <span
              className={cn('mb-[-1px] text-sm font-normal', [active === 1 ? 'active' : ''])}
              onClick={() => setActive(1)}
            >
              {t('chatFrame.Separated')}
            </span>
          </div>

          {active === 0 ? (
            <div className="flex-1 w-full h-full shrink-0 overflow-hidden flex text-sm">
              <div className="h-full w-[252px] shrink-0 border-r flex flex-col">
                <div className="shrink-0 pt-4 pl-3 pr-3">
                  <div className="w-full flex items-center pl-3 rounded-md h-12 text-sm flex items-center justify-between">
                    <span className="font-[500]">{t('businessManagement.Organization')}</span>
                    <span
                      className="flex items-center gap-1 text-lingheTextGray hover:text-primary cursor-pointer"
                      onClick={() =>
                        setShowAddGroupModal({
                          open: true,
                          data: null
                        })
                      }
                    >
                      <Plus size={14} />
                      {t('businessManagement.Add')}
                    </span>
                  </div>
                </div>

                {/*<div className="p-3 relative shrink-0">*/}
                {/*  <Input*/}
                {/*    value={searchVal}*/}
                {/*    onChange={(e) => setSearchVal(e?.target?.value)}*/}
                {/*    className="h-10 pl-6"*/}
                {/*    placeholder={t(*/}
                {/*      'businessManagement.Pleaseenteryournameandaccountnumbertosearch'*/}
                {/*    )}*/}
                {/*  />*/}
                {/*  <ReactSVG src={search} className={cn('ml-3', inputIconClassName)} />*/}
                {/*</div>*/}

                <div className={cn('overflow-y-auto flex-1 shrink-0')}>
                  <TeamTreeComp
                    // ref={treeRef}
                    data={teamList}
                    // searchVal={searchVal}
                    selectTeam={selectTeam}
                    handleUserSelect={(item) => {
                      setSelectTeam(item)
                    }}
                    handleEdit={(data) => {
                      setShowAddGroupModal({
                        open: true,
                        data: {
                          ...data,
                          userList: map(data?.userList, (it) => it?.userId)
                        } as ITeamResponse
                      })
                    }}
                    handleDelete={(data) => {
                      setDelTeamData({
                        open: true,
                        data
                      })
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 shrink-0 w-full h-full overflow-hidden flex flex-col">
                <div className="h-[54px] shrink-0 w-full flex items-center pl-3 pr-3 text-base [&_span]:text-sm">
                  <div className="font-semibold">{user?.tenantName}</div>
                  <span className="ml-3 mr-2 text-lingheTextGray">|</span>
                  <span className="text-lingheTextGray">
                    {t('businessManagement.Totalnumberofpeople')}
                  </span>
                  <span className="text-primary ml-1">{userListData?.total || 0}</span>
                </div>
                <NavBarTools
                  handleChange={handleChange}
                  handleInput={handleInput}
                  handleOpen={handleOpen}
                  handleReset={handleReset}
                />
                <div className="shrink-0 flex-1 w-full overflow-hidden">
                  <ManagerTable<IUserPageDetail>
                    pageationRef={pageationRef}
                    data={userListData?.records || []}
                    columns={columns}
                    rowCount={userListData?.total || 1}
                    onPageChange={(pageNum, pageSize) => {
                      const user_param = {
                        pageNum,
                        pageSize,
                        keyWord: searchVal,
                        jobStatus: 1,
                        inviteStatus: inviteStatus
                      }
                      inviteStatus === -1 && delete user_param.inviteStatus
                      handleUserList(user_param)
                    }}
                    emptyElement={() => (
                      <div className="flex items-center justify-center pt-20">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <ReactSVG src={noCustomer} />
                          <span className="mb-2 text-sm text-lingheTextGray">
                            {t('businessManagement.Nocurrentemployees')}
                          </span>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1 w-full h-full shrink-0 overflow-hidden flex text-sm">
              <OutWork />
            </div>
          )}
        </div>
        <AddCustomerModal
          roleList={roleList?.data}
          teamData={teamData?.data}
          data={roleData}
          title={
            roleData
              ? t('businessManagement.accounttojoinyourorganization')
              : t('businessManagement.Addmemberstoyourbusiness')
          }
          open={open}
          onClose={() => {
            setRoleData(null)
            setOpen(false)
          }}
          onConfirm={(val, type) => {
            type === 'create'
              ? handleAddInvite(val)
              : handleReInvite({ ...val, inviteId: roleData.inviteId })
          }}
        />
        <EditUserDetail
          open={editUserData?.open}
          data={editUserData?.data}
          onClose={() => setEditUserData({ open: false, data: null })}
          onConfirm={async (data) => {
            const res = await updateUserInfo(data)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Personneleditingsuccess'), 'success')
              initHandleUserList()
            }
          }}
        />

        <DelCustolerModal
          title={t('businessManagement.DeleteTeam')}
          open={delTeamData.open}
          onClose={() => {
            setDelTeamData({
              open: false,
              data: null
            })
          }}
          onConfirm={async () => {
            try {
              const res = await deleteGroup({
                teamId: delTeamData?.data?.teamId
              })
              if (res?.code === RESPONSE_CODE.success)
                message(t('businessManagement.TeamDeleteSuccess'), 'success')
              setDelTeamData({
                open: false,
                data: null
              })
              setSelectTeam(defaultTeam)
              refetchTeamList()
            } catch (error) {
              console.log('error', error)
            }
          }}
          content={
            <section className="text-lingheTextGray text-sm text-center">
              {t('businessManagement.Whetherteamdetermineddeleted')}
            </section>
          }
        />

        <DelCustolerModal
          title={t('chatFrame.removing')}
          buttonText={delData?.userData?.sessionCount > 0 ? t('chatFrame.DeleteForward') : ''}
          open={delData.open}
          sessionCount={delData?.userData?.sessionCount}
          onClose={() => {
            setDelData({
              open: false,
              userData: null
            })
          }}
          onConfirm={async () => {
            if (delStatus.current.state) return // 防止重复点击
            delStatus.current.state = true
            const params = {
              userId: delData?.userData?.userId,
              transferUserId: transferUserId
            }
            if (delData?.userData?.sessionCount > 0) {
              if (transferUserId === -1)
                return message(t('chatFrame.Pleaseselectforwardinguser'), 'error')
            } else {
              delete params.transferUserId
            }
            const res = await putBgUserDepart(params)
            delStatus.current.state = false
            message(res.msg, 'success')
            setTransferUserId(-1)
            setDelData({
              open: false,
              userData: null
            })
            initHandleUserList()
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center">
              {`${t('chatFrame.Areyousureyouwantdelete')}${delData?.userData?.nickname}？`}
              {delData?.userData?.sessionCount > 0 && (
                <div className="text-left pt-5">
                  <p className="pb-3">{t('chatFrame.SessionForwarding')}</p>
                  <SelectGroupPerson
                    userId={delData?.userData?.userId}
                    handleChange={handleGroupPersonChange}
                  />
                </div>
              )}
            </div>
          }
        />

        <AddGroup
          open={showAddGroupModal.open}
          data={showAddGroupModal.data}
          onClose={() =>
            setShowAddGroupModal({
              open: false,
              data: null
            })
          }
          onConfirm={async (param, callback) => {
            try {
              const res = await saveGroup(param)
              if (res?.code === RESPONSE_CODE.success) {
                message(t('businessManagement.Groupaddedsuccessfully'), 'success')
                refetchTeamList()
                setSelectTeam(defaultTeam)
                setShowAddGroupModal({
                  open: false,
                  data: null
                })
                callback && callback()
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
          selectClick={() => setGroupSelectModal(true)}
        />

        <ChooseTeamUserList
          open={showGroupSelectModal}
          onClose={() => setGroupSelectModal(false)}
          onConfirm={(list) => {
            setAddGroupUserList(list)
          }}
        />
      </NavBodyLayout>
    </UserInfoContext.Provider>
  )
}

export default memo(index)
