import i18n from 'aio-chat-ow/src/i18n/i18n'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { resources } from '../react-i18next/locales/resources'

const savedLng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'

// 将 i18n 实例传递给 react-i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // 初始化 i18next
  // 所有配置选项: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    // fallbackLng: 'en',
    // lng: navigator.language,
    lng: savedLng,
    debug: false,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
