import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageSearchPageDTO
 */
export interface TeamGroupPageRequest {
  /**
   * 分组id
   */
  groupId?: number;
  /**
   * 快捷语或话术内容
   */
  keyword?: string;
  pageNum?: number;
  pageSize?: number;
}

/**
 * PagePresetMessagePageVO
 */
export interface TeamGroupPageResponse {
  countId?: string;
  current?: number;
  pages?: number;
  records?: TeamGroupPageData[];
  searchCount?: Response;
  size?: number;
  total?: number;
}

/**
 * PresetMessagePageVO
 */
export interface TeamGroupPageData {
  id: number;
  /**
   * 分组id
   */
  groupId?: number;
  /**
   * 分组Name
   */
  groupName?: string;
  /**
   * 图片
   */
  imageContent?: string[];
  /**
   * ID
   */
  preMessageId?: number;
  /**
   * 快捷语
   */
  shortcutKey?: string;
  /**
   * 内容
   */
  textContent?: string;
}

interface ApiResp extends ClientResponse {
  data: TeamGroupPageResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术分页查询
 * @returns
 */
export const fetchTeamGroupPage = async (
  params: TeamGroupPageRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/kb/team/phrasing/page`,
    params,
  );

  return serialize(data);
};
