import { create } from 'zustand'
import { Tenant } from '../model'
import { createJSONStorage, persist } from 'zustand/middleware'
import { AccountType } from '../api/model'

type State = {
  account: string
  fifteenAutoSingin: boolean
  accountType: AccountType
  tenantList: Tenant[]
  currentTenant?: Tenant
  tempToken?: string
  imageCaptcha: string
  serverRead: boolean // 是否勾选已读服务协议
  redirectUrl: string

  inputAccountToSign: boolean // 是否输入账号登录
}

type Action = {
  setAccount: (account: string) => void
  setFifteenAutoSingin: (fifteenAutoSingin: boolean) => void
  setAccountType: (accountType: AccountType) => void
  setTenantList: (tenantList: Tenant[]) => void
  setCurrentTenant: (currentTenant: Tenant) => void
  setTempToken: (tempToken: string) => void
  setServerRead: (serverRead: boolean) => void
  setImageCaptcha: (imageCaptcha: string) => void

  setRedirectUrl: (url: string) => void

  setInputAccountToSign: (inputAccountToSign: boolean) => void
  // signOut: () => void
}

export const useSignStore = create(
  persist<State & Action>(
    (set, get) => ({
      account: '',
      imageCaptcha: '',
      tempToken: '',
      fifteenAutoSingin: true,
      accountType: AccountType.SMS,
      tenantList: [],
      serverRead: false,
      redirectUrl: '',
      inputAccountToSign: false,

      setAccount: (account: string): void => set({ account }),
      setTempToken: (tempToken: string): void => set({ tempToken }),
      setImageCaptcha: (imageCaptcha: string): void => set({ imageCaptcha }),
      setFifteenAutoSingin: (fifteenAutoSingin: boolean): void => set({ fifteenAutoSingin }),
      setAccountType: (accountType: AccountType): void => set({ accountType }),
      setTenantList: (tenantList: Tenant[]): void => set({ tenantList }),
      setCurrentTenant: (currentTenant: Tenant): void => set({ currentTenant }),
      setServerRead: (serverRead: boolean): void => set({ serverRead }),
      setRedirectUrl: (url): void => set({ redirectUrl: url }),

      setInputAccountToSign: (inputAccountToSign) => set({ inputAccountToSign })
    }),
    {
      name: 'sign',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
