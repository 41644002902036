import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input,
  Textarea,
  cn
} from '@brisk/ui'
import { FC, memo, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { Controller, useForm } from 'react-hook-form'
import { IAddTagReq, IEditTagReq, ITagPageVO } from '@renderer/api'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string } from 'zod'

interface IAddTagProps {
  data: ITagPageVO | null
  open: boolean
  onClose: () => void
  onConfirm: (val: IAddTagReq, callBack?: () => void) => void
  onEditConfirm: (val: IEditTagReq) => void
}

const defaultVal = {
  tagName: '',
  remark: '',
  colorCode: '#247CFF'
}

const AddTag: FC<IAddTagProps> = ({ data, open, onClose, onConfirm, onEditConfirm }) => {
  const { t } = useTranslation()
  const schema = object({
    tagName: string()
      .min(1, t('clientManagement.Pleaseenterlabelname'))
      .max(20, t('clientManagement.Maximumwordlimitexceeded')),
    remark: string()
      .min(1, t('clientManagement.Pleaseenteracomment'))
      .max(100, t('clientManagement.Maximumwordlimitexceeded')),
    colorCode: string()
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<IAddTagReq>({
    defaultValues: defaultVal,
    resolver: zodResolver(schema)
  })
  const onSubmit = (formData: IAddTagReq): void => {
    if (data) {
      onEditConfirm && onEditConfirm({ ...formData, tagId: data.tagId })
    } else onConfirm && onConfirm(formData, () => reset(defaultVal))
  }

  useEffect(() => {
    if (data) reset(data)
    else reset(defaultVal)
  }, [data, open])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogDescription />
      <AlertDialogContent className="p-0 focus:outline-none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogTitle className="h-[54px] pl-3 pr-3 relative flex items-center justify-between border-b">
            <span>
              {data ? t('clientManagement.EditorialTags') : t('clientManagement.AddTags')}
            </span>
            <ReactSVG
              src={close}
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                onClose && onClose()
              }}
            />
          </AlertDialogTitle>

          <div className="p-4 flex flex-col gap-3 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 text-sm">
            <Controller
              name="tagName"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('clientManagement.labelname')}</span>
                  <Input placeholder={t('clientManagement.Pleaseenterlabelname')} {...field} />
                  {errors.tagName && (
                    <p className="text-lingheError text-sm ">{errors?.tagName?.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="remark"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="itemWrap">
                  <span>{t('clientManagement.Remarks')}</span>
                  <Textarea
                    placeholder={t('clientManagement.Pleaseenteracomment')}
                    className={cn('min-h-[120px] resize-none')}
                    value={value}
                    onChange={(e) => {
                      const val = e?.target?.value
                      if (val?.length >= 100) {
                        onChange(val.slice(0, 100))
                        return
                      }
                      onChange(val)
                    }}
                    style={{
                      resize: 'none'
                    }}
                  />
                  {value?.length >= 100 && (
                    <p className="text-lingheError text-sm ">
                      {t('clientManagement.Maximumwordlimitexceeded')}
                    </p>
                  )}
                  {errors.remark && (
                    <p className="text-lingheError text-sm ">{errors?.remark?.message}</p>
                  )}
                  <div className="relative h-0">
                    <span className="text-lingheTextGray text-xs absolute right-4 bottom-3">
                      {value?.length || 0}/100
                    </span>
                  </div>
                </div>
              )}
            />
            <Controller
              name="colorCode"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>{t('clientManagement.colour')}</span>
                  <div className="relative">
                    <Input
                      id="colorInput"
                      type="color"
                      {...field}
                      className={cn('w-9 h-9 rounded-full')}
                    />
                    <span
                      onClick={() => document.getElementById('colorInput')?.click()}
                      className="cursor-pointer border border-[rgba(0,0,0,0.06)] box-border w-9 h-9 z-2 rounded-full absolute left-0 top-0"
                      style={{
                        backgroundColor: field.value
                      }}
                    />
                  </div>
                </div>
              )}
            />
          </div>

          <AlertDialogFooter className="h-[54px] pl-3 pr-3 flex items-center border-t">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                onClose && onClose()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.save')}</Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddTag)
