import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input,
  cn
} from '@brisk/ui'
import { FC, memo, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { IAddTeamGroupReq, ITeamResponse } from '@renderer/api'
import selectIconRight from '../../../assets/svg/selectIconRight.svg'
import { UserInfoContext } from '../PersonManage'
import { map, pull } from 'lodash'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface IAddGroupProps {
  open: boolean
  data: ITeamResponse
  onClose: () => void
  onConfirm: (param: IAddTeamGroupReq, callback?: () => void) => void
  selectClick?: () => void
}

const defaultValues = {
  teamId: '',
  teamName: ''
}

const AddGroup: FC<IAddGroupProps> = ({ open, data, onClose, onConfirm, selectClick }) => {
  const { indexedTeamTree, userList, setAddGroupUserList } = useContext(UserInfoContext)
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm<IAddTeamGroupReq>({
    defaultValues: defaultValues,
    mode: 'onChange'
  })

  const onSubmit = (formData): void => {
    // if (!userList?.length) {
    //   message(t('businessManagement.Pleaseselectpanellists'), 'error')
    //   return
    // }
    onConfirm && onConfirm(formData, () => reset(defaultValues))

    setAddGroupUserList([])
  }

  useEffect(() => {
    if (userList && userList?.length) setValue('userList', userList)
    else setValue('userList', [])
  }, [userList])

  useEffect(() => {
    if (data) {
      reset(data as IAddTeamGroupReq)
      setAddGroupUserList(data?.userList || [])
    }
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogDescription />
      <AlertDialogContent className="p-0 focus:outline-none">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogTitle className="h-[54px] pl-3 pr-3 relative flex items-center justify-between border-b">
            <span>
              {data ? t('businessManagement.editorialgroup') : t('businessManagement.AddGroup')}
            </span>
            <ReactSVG
              src={close}
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultValues)
                onClose && onClose()
              }}
            />
          </AlertDialogTitle>

          <div className="p-4 flex flex-col gap-3 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2 text-sm">
            <Controller
              name="teamName"
              control={control}
              rules={{
                required: t('businessManagement.Pleaseenternameofgroup'),
                maxLength: {
                  value: 20,
                  message: t('businessManagement.groupmustexceedcharacterspleaseretypeit')
                }
              }}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    <span className="text-lingheError">*</span>
                    {t('businessManagement.Groupname')}
                  </span>
                  <Input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.stopPropagation() // 添加 stopPropagation 防止事件冒泡
                      }
                    }}
                    placeholder={t('businessManagement.Pleaseentercustomerservicename')}
                    {...field}
                  />
                  {errors.teamName && (
                    <p className="text-lingheError text-sm">{errors?.teamName?.message}</p>
                  )}
                </div>
              )}
            />
            <Controller
              name="userList"
              control={control}
              // rules={{
              //   required: t('businessManagement.Pleaseselectpanellists')
              // }}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    {/* <span className="text-lingheError">*</span> */}
                    {t('businessManagement.Panelists')}
                  </span>

                  <div
                    className="w-full min-h-9 max-h-[550px] overflow-y-auto border rounded-md relative flex items-center gap-3 pl-3 pr-3 flex-wrap"
                    onClick={(e) => {
                      e.preventDefault()
                      selectClick && selectClick()
                    }}
                  >
                    {map(field?.value || [], (it, i) => (
                      <div
                        key={'' + it + i}
                        className="relative h-[80%] flex items-center justify-center pl-2 pr-8 bg-[#F2F3F4] rounded-md"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        {indexedTeamTree[it + '']}
                        <ReactSVG
                          src={close}
                          className="absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            setAddGroupUserList([...pull(userList, it)])
                          }}
                        />
                      </div>
                    ))}

                    <ReactSVG
                      src={selectIconRight}
                      className={cn(
                        'absolute top-1/2 right-3 -translate-y-1/2 transition cursor-pointer'
                      )}
                    />
                  </div>
                </div>
              )}
            />
          </div>

          <AlertDialogFooter className="h-[54px] pl-3 pr-3 flex items-center border-t">
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                reset(defaultValues)
                onClose && onClose()
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.verify')}</Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddGroup)
