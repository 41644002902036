/**
 * useAppUpStore
 *
 * @desc 描述：app 更新仓库
 */

/* =================================================== */

import { create } from 'zustand'
import { combine } from 'zustand/middleware'

export const useAppUpStore = create(
  combine(
    {
      // 是否显示弹窗
      isOpen: false,
      // 软件是否有更新
      isAppUp: false,
      // 新版本号
      newVersion: ''
    },
    (set) => {
      return {
        // 打开弹窗
        openDialog: () => set({ isOpen: true }),
        // 关闭弹窗
        closeDialog: () => set({ isOpen: false }),
        // 设置弹窗显示
        setDialog: (is: boolean) => set({ isOpen: is }),
        // 设置 app 是否有更新
        setAppUp: (is: boolean) => set({ isAppUp: is }),
        // 设置新版本号
        setVersion: (version: string) => set({ newVersion: version })
      }
    }
  )
)
