import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 转入会话
 * @param params
 * @returns
 */
export const fetchTranslateIn = async (params: {
  sessionId: string | number;
}): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/session/transfer-in`,
    params,
  );

  return serialize(data);
};
