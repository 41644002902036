import {
  cn
  // Popover, PopoverContent, PopoverTrigger
} from '@brisk/ui'
import {
  //  filter,
  map,
  some
} from 'lodash'
// import { Plus, X } from 'lucide-react'
import { FC, forwardRef, useState } from 'react'
import userTags from '../../../assets/svg/tags.svg'
import { ReactSVG } from 'react-svg'
import checked from '../../../assets/svg/checked.svg'
import moreTagsIcon from '../../../assets/svg/moreTagsIcon.svg'
import { ITagList } from '@renderer/api/manage/fetchTagList'
// import { useMutation } from '@tanstack/react-query'
// import { fetchSaveUserDetail } from '@renderer/api/chatList'
// import { RESPONSE_CODE } from '@brisk/api'

interface Iprops {
  tags: ITagList[]
  list: ITagList[]
  sessionId: string
  onChange?: () => void
  ellipsis?: boolean
}

interface ICheckTag {
  selectedList?: Iprops['tags']
  onclick?: (item: Iprops['tags'][0]) => void
  list: ITagList[]
}

export const CheckTag: FC<ICheckTag> = ({ list, selectedList, onclick }) => {
  return (
    <div className="p-3 [&>div]:flex [&>div]:items-center [&>div]:relative [&>div>span]:w-2.5 [&>div>span]:h-2.5">
      {map(list, (item) => (
        <div
          key={item?.tagId + item?.colorCode}
          className="min-h-8 w-full pl-3 pr-9 cursor-pointer rounded-md hover:bg-[#EDF6FF]"
          onClick={() => {
            if (some(selectedList, (it) => it?.tagId === item?.tagId)) {
              return
            }
            onclick && onclick(item)
          }}
          style={{
            overflowWrap: 'anywhere'
          }}
        >
          <span className={'mr-2 shrink-0'} style={{ backgroundColor: item?.colorCode }} />
          {item?.tagName}
          <ReactSVG
            src={checked}
            className={cn(
              'absolute right-3 top-1/2 -translate-y-1/2',
              !some(selectedList, (it) => it?.tagId === item?.tagId) && 'hidden'
            )}
          />
        </div>
      ))}
    </div>
  )
}

const UserTags = forwardRef(
  (
    {
      tags = [],
      // list,
      //  sessionId,
      // onChange,
      ellipsis
    }: Iprops
    //  ref
  ) => {
    const [showDropdown, setShowDropdown] = useState(false)

    // const { mutateAsync: saveUserDetail } = useMutation({
    //   mutationFn: fetchSaveUserDetail
    // })

    // const handleUpdateTags = async (tags): Promise<void> => {
    //   try {
    //     const res = await saveUserDetail({
    //       tagIds: tags,
    //       sessionId
    //     })
    //     if (res?.code === RESPONSE_CODE.success) onChange && onChange()
    //   } catch (error) {
    //     console.log('error', error)
    //   }
    // }

    return (
      <>
        <div
          className={cn('flex items-center gap-2 w-full', ellipsis ? 'flex-nowrap' : ' flex-wrap')}
        >
          <ReactSVG src={userTags} />
          {map(tags, (item, index) => {
            if (ellipsis && index > 2) return false
            return (
              <div
                key={index + item?.tagId + item?.colorCode}
                className={cn(
                  'flex items-center gap-1 pt-0.5 pb-0.5 pl-1 pr-2 rounded text-xs bg-lingheInpBg text-lingheTextGray',
                  ellipsis ? 'max-w-[15%] lg:max-w-[20%]' : ''
                )}
                style={{
                  borderRadius: 3,
                  overflowWrap: 'anywhere'
                }}
              >
                <span
                  className="w-2.5 h-2.5 border shrink-0"
                  style={{
                    borderRadius: 2,
                    backgroundColor: `${item?.colorCode}`,
                    borderColor: 'rgba(0,0,0,0.06)'
                  }}
                />
                <span
                  className={cn(
                    'flex-1 shrink-0 hidden xl:block',
                    ellipsis && ' overflow-hidden text-ellipsis whitespace-nowrap'
                  )}
                >
                  {item?.tagName}
                </span>
                {/* <X
                size={16}
                className="cursor-pointer shrink-0"
                onClick={() => {
                  const t = filter(tags, (it) => it?.tagId !== item?.tagId)
                  handleUpdateTags(map(t, (it) => it?.tagId))
                }}
              /> */}
              </div>
            )
          })}
          {ellipsis && tags?.length > 3 && (
            <div className="relative">
              <ReactSVG
                src={moreTagsIcon}
                className="cursor-pointer"
                onMouseEnter={() => setShowDropdown(true)}
              />

              {showDropdown && (
                <div
                  onMouseLeave={() => setShowDropdown(false)}
                  className="p-0 max-h-[300px] w-[258px] overflow-y-auto absolute right-0 top-6 z-10 bg-white rounded-md shadow-lg"
                >
                  <CheckTag list={tags} />
                </div>
              )}
            </div>
          )}
          {/* <Popover>
          <PopoverTrigger>
            <div className="flex items-center gap-1 rounded cursor-pointer border text-xs pt-1 pb-1 pl-1 pr-2 text-lingheGray hover:bg-lingheHoverBg">
              <Plus size={16} />
              添加标签
            </div>
          </PopoverTrigger>
          <PopoverContent className="p-0">
            <CheckTag
              list={list}
              selectedList={tags}
              onclick={(item) => {
                const t = [...tags, item]
                handleUpdateTags(map(t, (it) => it?.tagId))
              }}
            />
          </PopoverContent>
        </Popover> */}
        </div>
      </>
    )
  }
)

UserTags.displayName = 'UserTags'

export default UserTags
