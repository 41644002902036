import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * RoleDetailVO
 */
export interface IRoleDetails {
  isDefault?: number
  menuIdList?: number[]
  remark?: string
  roleId?: number
  roleKey?: string
  roleName?: string
  status?: number
}

interface ApiResp extends ClientResponse {
  data: IRoleDetails
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 查询角色详情
 * @param param
 * @returns
 */
export const fetchRoleDetail = async (roleId: string | number): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/role/detail/${roleId}`)

  return serialize(data)
}
