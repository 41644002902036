import queryString from "query-string";
import heic2any from "heic2any";
// import { message } from "@renderer/components/message";
// import { t } from 'i18next'

export const trimAll = (str: string): string => {
  return str.replace(/\s+/g, "");
};

export const generateUrlWithQueryString = (
  path: string,
  queryParams: Record<string, unknown>,
): string => {
  const query = queryString.stringify(queryParams);

  return `${path}?${query}`;
};
// export const isElectron = !!window.electron;

export const isDevLocal = import.meta.env.DEV // 本地开发

export const isDev = import.meta.env.VITE_MODE === "development";
export const isTest = import.meta.env.VITE_MODE === "test";
export const isProd = import.meta.env.VITE_MODE === "production";
export const isDevOrTest =
  import.meta.env.VITE_MODE === "development" ||
  import.meta.env.VITE_MODE === "test";

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// 判断图片是否为heic类型
export const onJudgeImageIsHeic = (heicFile: File): boolean => {
  return (
    heicFile.type === "image/heic" ||
    heicFile.name.split(".").pop()?.toLocaleLowerCase() === "heic"
  );
};

// 将HEIC文件转换为PNG文件
export async function convertHEICtoPNG(heicFile: File) {
  // 判断文件是否为heic文件，否则直接返回
  if (!onJudgeImageIsHeic(heicFile)) return heicFile;

  try {
    const pngData = await heic2any({
      blob: new Blob([heicFile], { type: "image/heic" }),
      toType: "image/png",
    });
    const name =
      heicFile.name.split(".").shift() || new Date().getTime().toString();

    // 返回转换后的PNG文件
    return new File([pngData as BlobPart], name + ".png", {
      type: "image/png",
    });
  } catch (error) {
    console.error("转换失败:", error);

    return Promise.reject(error);
  }
}

/**
 * 复制文本
 * @param text
 */
export const copyTextToClipboard = async (text): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
    // message(t('editInformation.contentcopiedclipboard'), 'success')
  } catch (err) {
    console.error("复制到剪贴板失败", err);
  }
};

// 过滤字符串中特殊符号，防止渲染为 html 节点
export const filterHtmlEntities = (str: string): string => {
  return str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
};

// 获取目标节点纯文本内容
export const getNodeTextContent = (ele: HTMLElement): string => {
  let res = "";
  let nodeArr = Array.from(ele.childNodes) as Node[];

  if (
    nodeArr[nodeArr.length - 1] &&
    nodeArr[nodeArr.length - 1].nodeName === "BR" &&
    nodeArr.length > 1
  ) {
    nodeArr = nodeArr.splice(0, nodeArr.length - 1);
  }

  nodeArr.forEach((child) => {
    if (child.nodeName === "#text") {
      res += child.nodeValue;
    } else if (child.nodeName === "BR") {
      res += "\n";
    } else if (child.nodeName === "P") {
      res += "\n" + getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "SPAN") {
      res += getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "BUTTON") {
      res += getNodeTextContent(child as HTMLElement);
    } else if (child.nodeName === "IMG") {
      res += (child as HTMLImageElement).alt;
    } else if (child.nodeName === "DIV") {
      const childNodes = Array.from(child.childNodes);

      if (
        (childNodes.length === 1 && childNodes[0].nodeName === "BR") ||
        (child.previousSibling && child.previousSibling.nodeName === "BR")
      ) {
        // 处理复制粘贴内容多换行问题
        res += getNodeTextContent(child as HTMLElement);
      } else {
        res += "\n" + getNodeTextContent(child as HTMLElement);
      }
    }
  });
  return filterHtmlEntities(res);
};

/**
 * 过滤文本中的 html 标签
 * @param str
 * @returns
 */
export const replaceHtmlElement = (str: string): string => {
  if (!str) return "";
  const regex = /<[^>]+>/g;
  return str.replace(regex, "");
};

/**
 * 定义读取文件为Base64的箭头函数
 * @param file
 * @returns
 */
export const readFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const getFileExtension = (fileName: string): string => {
  const parts = fileName.split(".");
  const fileExt = parts.pop();
  if (!fileExt) {
    return "";
  }
  return "." + fileExt;
};
export const formatDurationHDMin = (seconds: number): string => {
  if (seconds <= 0) return "0min";

  const days = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  const result: string[] = [];
  if (days > 0) result.push(`${days}d`);
  if (hours > 0 || result.length > 0) result.push(`${hours}h`);
  if (minutes > 0 || result.length === 0) result.push(`${minutes}min`);

  return result.join("");
};

export const replaceImgWithAlt = (text): string => {
  // 正则表达式匹配<img>标签
  const imgTagPattern = /<img[^>]*alt\s*=\s*["']([^"']*)["'][^>]*>/g;

  // 使用正则表达式替换<img>标签为alt属性的值
  const result = text.replace(imgTagPattern, "$1");

  return result;
};
