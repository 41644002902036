import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { AccountType } from '../model'
import { trimAll } from '../../utils'

interface RequestParams {
  // 类型 SMS:短信 EMAIL:邮箱
  type: AccountType | string
  // 账号,可以是用户名，邮箱，手机号
  account: string
  // 滑块验证token
  token: string
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return resp
}
// 发送验证码
export const fetchSendCaptcha = async ({
  type,
  account,
  token
}: RequestParams): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/login/captcha`, {
    type,
    account: trimAll(account),
    token
  })
  return serialize(data)
}
