import { ApiReqFn, httpClient, ApiRespSerializeFn, ClientResponse,} from '../../http-client/httpClient'

interface RequestBody {
  avatarUrl: string
  nickname: string
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return {
    ...resp
  }
}

// 修改昵称
export const fetchNickName: ApiReqFn<RequestBody, Data> = async (payload) => {
  const { data } = await httpClient.put<ApiResp>('/bg/account/nickname', { ...payload })

  return serialize(data)
}
