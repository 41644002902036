import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@brisk/ui'
import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import translatePreview from '@renderer/assets/svg/translatePreview.svg'

const TranslateIconComp: FC = () => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <ReactSVG src={translatePreview} />
        </TooltipTrigger>
        <TooltipContent className="bg-[rgba(0,0,0,0.6)] text-xs text-white p-0 pt-1 pb-1 pl-[6px] pr-[6px] rounded-md">
          翻译预览
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default memo(TranslateIconComp)
