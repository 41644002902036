import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input
} from '@brisk/ui'
import { FC, memo, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { message } from '@renderer/components/message'
import { KbTeamGroupListResponse } from '@brisk/api'
import { useTranslation } from 'react-i18next'

interface IAddTeamWordsGroup {
  data: KbTeamGroupListResponse | null
  open: boolean
  onClose?: () => void
  onConfirm?: (val: string) => void
}
const AddTeamWordsGroup: FC<IAddTeamWordsGroup> = ({ data, open, onClose, onConfirm }) => {
  const [val, setVal] = useState('')
  const { t } = useTranslation()
  useEffect(() => {
    if (data) setVal(data?.groupName)
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[520px] focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              setVal('')
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {data ? t('chatFrame.edit') : t('businessManagement.Add')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <div className="w-full h-full pl-4 pr-4 pt-6 pb-6 flex flex-col gap-2">
          <span>{t('infoCollection.Name')}</span>
          <Input
            value={val}
            onChange={(e) => setVal(e.target.value)}
            placeholder={t('common.pleaseEnter')}
          />
        </div>

        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              setVal('')
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              if (!val) {
                message(t('common.pleaseEnter'), 'error')
                return
              }
              onConfirm && onConfirm(val)
              setVal('')
              onClose && onClose()
            }}
          >
            {t('common.save')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddTeamWordsGroup)
