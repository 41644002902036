import { FC, useState } from 'react'
import { SignInHeader } from './components/SignInHeader'
import {
  Button,
  CardContent,
  CardFooter,
  Checkbox,
  Label,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@brisk/ui'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string, ZodType } from 'zod'
import { AccountType } from '@renderer/api/model'
import { Link, useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '@renderer/router'
import enterprise from '../../assets/svg/enterprise.svg'
import ipAddress from '../../assets/svg/ipAddress.svg'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'
import MyInputComps from './components/MyInputComps'
import { useMutation } from '@tanstack/react-query'
import { fetchPublicSign } from '@renderer/api'
import { generateSignature } from '@renderer/utils/handleSignature'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { sendFastestRequest } from '@renderer/utils/handleServerAddressPromise'
import { ChevronDown } from 'lucide-react'

type IFormInput = {
  enterprise: string
  ipAddress: string
  port: string
}

const defaultFormValues: IFormInput = {
  enterprise: '',
  ipAddress: '',
  port: ''
}

const enterpriseSchema = object({
  enterprise: string().min(1, '请输入企业号')
})
const idAddressSchema = object({
  ipAddress: string().ip('请输入正确的ip地址').min(1, '请输入ip地址'),
  port: string().min(1, '请输入端口号')
})
const getSchema = (tab: AccountType): ZodType =>
  tab === AccountType.ENTERPRISE ? enterpriseSchema : idAddressSchema

const EnterpriseSign: FC = () => {
  const navigate = useNavigate()
  const [currentTabValue, setCurrentTabValue] = useState(AccountType.ENTERPRISE)
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(getSchema(currentTabValue))
  })

  const { mutateAsync: fetchFindTenant } = useMutation({
    mutationFn: fetchPublicSign
  })

  useErrorHinter([errors.enterprise, errors.ipAddress, errors.port])

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    console.log('formData', formData)
    if (currentTabValue === AccountType.ENTERPRISE) {
      const time = Date.now()
      const tenantCode = formData.enterprise
      const signature = generateSignature(time, tenantCode)
      const res = await fetchFindTenant({
        tenantCode,
        productCode: 'kftest',
        signature,
        timestamp: time
      })
      if (res.code === RESPONSE_CODE.success) {
        const tenantAddressList = res?.data?.tenantAddressList
        // test
        try {
          // 竞速
          const fastUrl = await sendFastestRequest(tenantAddressList)
          console.log('fastUrl--->', fastUrl)
          // httpClient.defaults.baseURL = `http://${fastUrl}`
          navigate(APP_ROUTER_PATH.CHECKINFO_LOGIN, {
            state: {
              tenantId: res?.data?.tenantId
            }
          })
        } catch (error) {
          // continue
          navigate(APP_ROUTER_PATH.CHECKINFO_LOGIN, {
            state: {
              tenantId: res?.data?.tenantId
            }
          })
        }
      }
    } else {
      // ip登录
      navigate(APP_ROUTER_PATH.CHECKINFO_LOGIN, {
        state: {
          ipAddress: `${formData.ipAddress}:${formData.port}`
        }
      })
    }
  }
  return (
    <>
      <SignInHeader showBack={false} />
      <CardContent className="flex-1 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            defaultValue={currentTabValue}
            className=""
            onValueChange={(value) => {
              setCurrentTabValue(value as AccountType)
            }}
          >
            <TabsList className="mb-5">
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.ENTERPRISE}
              >
                企业号
              </TabsTrigger>
              <TabsTrigger
                className=" data-[state=active]:bg-[none !important]"
                value={AccountType.IPADRESS}
              >
                IP/域名
              </TabsTrigger>
            </TabsList>
            <TabsContent value={AccountType.ENTERPRISE}>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Controller
                    name="enterprise"
                    control={control}
                    render={({ field }) => (
                      <MyInputComps
                        field={field}
                        placeholder="请输入企业号"
                        required
                        errors={errors.enterprise}
                        errorMsg="企业号错误，请重新输入"
                        svg={enterprise}
                      />
                    )}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value={AccountType.IPADRESS}>
              <div className="grid gap-4">
                <div className="flex items-center gap-2 ">
                  <div className="flex-1 h-12">
                    <Controller
                      name="ipAddress"
                      control={control}
                      render={({ field }) => (
                        <MyInputComps
                          field={field}
                          placeholder="请输入IP/域名"
                          required
                          errors={errors.ipAddress}
                          svg={ipAddress}
                        />
                      )}
                    />
                  </div>
                  <span className="text-lingheGray">:</span>
                  <div className="w-[85px] h-full h-12 shrink-0">
                    <Controller
                      name="port"
                      control={control}
                      render={({ field }) => (
                        <MyInputComps
                          field={field}
                          placeholder="端口号"
                          className="pl-3"
                          required
                          errors={errors.port}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {errors.ipAddress && (
                <p className="mt-2 text-lingheError text-sm">IP/域名错误，请重新输入</p>
              )}
              {errors.port && <p className="mt-2 text-lingheError text-sm">端口错误，请重新输入</p>}
            </TabsContent>
          </Tabs>
          <Button type="submit" className="w-full mt-8 h-12">
            加入
          </Button>
        </form>
      </CardContent>
      <CardFooter>
        <div className="w-full text-gray-500 text-sm font-normal">
          <div className="flex justify-between">
            <div className="cursor-pointer flex">
              简体中文 <ChevronDown size={16} />{' '}
            </div>
            <div className="text-right">
              <Link to={APP_ROUTER_PATH.INPUT_ACCOUNT}>手机/邮箱登录</Link>
            </div>
          </div>
        </div>
      </CardFooter>
    </>
  )
}

export default EnterpriseSign
