/**
 * TranslationSettings
 *
 * @desc 描述：翻译设置
 */

/* =================================================== */

import type { FC } from 'react'
import { memo, useContext, useEffect } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  AIOSelectTrigger,
  SelectValue,
  Switch,
  Separator
} from '@brisk/ui'
import { useCreation, useRequest } from 'ahooks'
import { parseAsync } from '@brisk/utils'
import { find, map } from 'lodash'
import { useTranslation } from 'react-i18next'

import LabelLayout from '../labelLayout'
import { MessageInfoContext } from '../../../Home'
import { message } from '@renderer/components/message'
import { getEngineList } from '@renderer/api/setting/getEngineList'
import { getTranslateConfig } from '@renderer/api/setting/getTranslateConfig'
import { getEngineDetails } from '@renderer/api/setting/getEngineDetails'
import { putEngine } from '@renderer/api/setting/putEngine'
import { putReceiveStatus } from '@renderer/api/setting/putReceiveStatus'
import { putReceiveConfig } from '@renderer/api/setting/putReceiveConfig'
import { putDispatchStatus } from '@renderer/api/setting/putDispatchStatus'
import { putDispatchConfig } from '@renderer/api/setting/putDispatchConfig'

type TPutApiMap = typeof PUT_API_MAP

const PUT_API_MAP = {
  putEngine,
  putReceiveStatus,
  putReceiveConfig,
  putDispatchStatus,
  putDispatchConfig
}

const TranslationSettings: FC = () => {
  const { t, i18n } = useTranslation()
  const { setLoading } = useContext(MessageInfoContext)

  /* ======================= 获取用户设置 ======================= */
  const { data: config, mutate } = useRequest(getTranslateConfig, {
    onSuccess: ({ translateEngine }) => {
      // 获取翻译渠道对应的语言列表
      engineDetailsR(translateEngine)
    }
  })

  // 更新翻译设置
  async function upConfig<K extends keyof TPutApiMap>(key: K, params: Parameters<TPutApiMap[K]>) {
    const fn = PUT_API_MAP[key]
    if (!fn) throw new Error(`策略集中没有匹配传入的 key - ${key}`)

    setLoading(true)
    // @ts-expect-error 无法正确推导
    const [err, data] = await parseAsync(fn(...params))
    setLoading(false)
    if (err) {
      message(t('systemSetting.操作失败'), 'error')
      throw err
    }

    message(t('systemSetting.操作成功'), 'success')
    // 修改翻译设置状态
    mutate(data)
  }

  // 翻译线路
  function onTransLinesChange(val: string) {
    upConfig('putEngine', [
      {
        engine: val,
        customerUniversalLanguage: getUniversalLanguage(config?.customerLanguageCode),
        userUniversalLanguage: getUniversalLanguage(config?.userLanguageCode)
      }
    ])

    engineDetailsR(val)
  }

  function getUniversalLanguage(code?: string): string | undefined {
    if (code === undefined) return undefined

    return find(engineDetails, ['languageCode', code])?.universalLanguage
  }

  /* ======================= 获取引擎列表 ======================= */
  const { data: engineArr } = useRequest(getEngineList)

  // 引擎列表下拉集合
  const circuitEls = useCreation(
    () =>
      map(engineArr, (val) => (
        <SelectItem key={val} value={val}>
          {val}
        </SelectItem>
      )),
    [engineArr]
  )

  /* ======================= 获取引擎语言列表 ======================= */
  const {
    data: engineDetails,
    run: engineDetailsR,
    refresh
  } = useRequest(getEngineDetails, {
    manual: true
  })

  // 修改语言刷新列表
  useEffect(() => {
    refresh()
  }, [i18n.language])

  // 下拉成员
  const translatedEls = useCreation(
    () =>
      map(engineDetails, ({ languageId, language, languageCode }) => (
        <SelectItem key={languageId} value={languageCode}>
          {language}
        </SelectItem>
      )),
    [engineDetails]
  )

  return (
    <article className="mb-[1rem]">
      <h1 className="text-base font-[500] pb-2">{t('systemSetting.翻译设置')}</h1>

      {/* 翻译路线 */}
      <LabelLayout labelText={t('systemSetting.翻译线路')}>
        <Select value={config?.translateEngine ?? ''} onValueChange={onTransLinesChange}>
          <AIOSelectTrigger className="w-[180px] h-8">
            <SelectValue placeholder={t('systemSetting.请选择')} />
          </AIOSelectTrigger>

          <SelectContent id="airplane-mode1">
            <SelectGroup>{circuitEls}</SelectGroup>
          </SelectContent>
        </Select>
      </LabelLayout>

      <Separator className="my-4" />

      {/* 接收 */}
      <section className="flex flex-col gap-[30px]">
        {/* 开关 */}
        <LabelLayout labelText={t('systemSetting.接收消息自动翻译')}>
          <Switch
            checked={!!config?.customerEnableStatus}
            onCheckedChange={(checked) => {
              upConfig('putReceiveStatus', [checked ? '1' : '0'])
            }}
          />
        </LabelLayout>

        {/* 翻译语言选择 */}
        <LabelLayout
          labelClass="text-[#A5A7B1]"
          labelText={t('systemSetting.翻译为')}
          disabled={!config?.customerEnableStatus}
        >
          <Select
            disabled={!config?.customerEnableStatus}
            value={config?.customerLanguageCode ?? ''}
            onValueChange={(code) => {
              upConfig('putReceiveConfig', [code])
            }}
          >
            <AIOSelectTrigger className="w-[180px] h-8">
              <SelectValue placeholder={t('systemSetting.请选择')} />
            </AIOSelectTrigger>

            <SelectContent id="airplane-mode1">
              <SelectGroup>{translatedEls}</SelectGroup>
            </SelectContent>
          </Select>
        </LabelLayout>
      </section>

      <Separator className="my-4" />

      {/* 发送 */}
      <section className="flex flex-col gap-[30px]">
        {/* 开关 */}
        <LabelLayout labelText={t('systemSetting.发送消息自动翻译')}>
          <Switch
            checked={!!config?.userEnableStatus}
            onCheckedChange={(checked) => {
              upConfig('putDispatchStatus', [checked ? '1' : '0'])
            }}
          />
        </LabelLayout>

        {/* 翻译语言选择 */}
        <LabelLayout
          labelClass="text-[#A5A7B1]"
          labelText={t('systemSetting.翻译为')}
          disabled={!config?.userEnableStatus}
        >
          <Select
            disabled={!config?.userEnableStatus}
            value={config?.userLanguageCode ?? ''}
            onValueChange={(code) => {
              upConfig('putDispatchConfig', [code])
            }}
          >
            <AIOSelectTrigger className="w-[180px] h-8">
              <SelectValue placeholder={t('systemSetting.请选择')} />
            </AIOSelectTrigger>

            <SelectContent id="airplane-mode1">
              <SelectGroup>{translatedEls}</SelectGroup>
            </SelectContent>
          </Select>
        </LabelLayout>
      </section>
    </article>
  )
}

export default memo(TranslationSettings)
