import React, { FC, useEffect, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { InputInterface } from '../../home/Settings/type'
import TextInput from '../../../components/TextInput'
import SettingDialog from '../../../components/SettingDialog'
import { useMutation } from '@tanstack/react-query'
import { fetchNickName } from '../../../api/setting/fetchNickName'
import { fetchAvatar } from '../../../api/setting/fetchAvatar'
import { fetchPhoneOrEmail } from '../../../api/setting/fetchPhoneOrEmail'
import { fetchResetPassword } from '../../../api/setting/editResetPassword'
import { useUploadFileManage } from '../../../hooks/manager/useUploadFileManage'
import { httpClient } from '../../../http-client/httpClient'
import { AccountType } from '../../../api/model'
import { message } from 'css-sdk/src/components/message'
import AvatarSettings from '../../../components/Avatar-settings'
import { useUserStore } from '@renderer/store/useUserStore'
import { User } from '../../../model/data/user'
import { useTranslation } from 'react-i18next'
import LanguageSetting from '../../home/Settings/components/languageSetting'

const Settings: FC = () => {
  const [avatarLoading, setAvatarLoading] = useState(false) // 头像上传loading
  const { t } = useTranslation()
  const [useData, setData] = useState({
    avatar: '',
    nickname: '',
    companies: '',
    phone: '',
    email: '',
    password: '············'
  }) // 更新信息数据
  const refType = useRef('') // 弹窗类型
  const [open, setOpen] = useState(false) // 弹窗开启状态
  const [isPending, setIsPending] = useState(true)
  const { user, setUser } = useUserStore()
  // 昵称修改
  const { mutateAsync: fetchAsyncNickName } = useMutation({
    mutationFn: fetchNickName
  })
  // 头像修改
  const { mutateAsync: fetchAsyncAvatar } = useMutation({
    mutationFn: fetchAvatar
  })
  // 手机绑定or邮箱绑定
  const { mutateAsync: fetchAsyncPhoneOrEmail } = useMutation({
    mutationFn: fetchPhoneOrEmail
  })
  // 修改密码
  const { mutateAsync: fetchAsyncResetPassword } = useMutation({
    mutationFn: fetchResetPassword
  })
  const { handleUploadFileManage } = useUploadFileManage()

  useEffect(() => {
    initUserDetails()
    return () => {}
  }, [])

  // 初始化用户详情
  async function initUserDetails() {
    setIsPending(true)
    try {
      const { data } = await httpClient.get<InputInterface>(`/bg/account`)
      setIsPending(false)
      setData({
        // avatar: data.data.avatarUrl,
        avatar: user?.avatarUrl || data?.data?.avatarUrl,
        nickname: data.data.nickname,
        companies: data.data.company,
        phone: data.data.phone,
        email: data.data.email,
        password: '············'
      })
    } catch (e) {
      console.info(e)
    }
  }

  /**
   * 提交
   * @param dataObj
   */
  async function handleSubmit(dataObj) {
    switch (dataObj.type) {
      case 'nickname':
        fetchAsync(fetchAsyncNickName, { avatarUrl: '', nickname: dataObj.value })
        setData({ ...useData, nickname: dataObj.value })
        break
      case 'phone':
        await fetchAsync(fetchAsyncPhoneOrEmail, {
          type: AccountType.SMS,
          account: dataObj.value,
          code: dataObj.captcha
        })
        setData({ ...useData, phone: dataObj.value })
        break
      case 'email':
        await fetchAsync(fetchAsyncPhoneOrEmail, {
          type: AccountType.EMAIL,
          account: dataObj.value,
          code: dataObj.captcha
        })
        setData({ ...useData, email: dataObj.value })
        break
      case 'password':
        await fetchAsync(fetchAsyncResetPassword, {
          oldPassword: dataObj.originalPassword,
          newPassword: dataObj.newPassword
        })
        break
      default:
        break
    }
    message(t('businessManagement.modifiedsuccessfully'), 'success')
    handleCancel()
  }

  async function fetchAsync(fetchAsyncFun, params) {
    try {
      await fetchAsyncFun(params)
      if (params.nickname) {
        setUser({ ...user, nickname: params.nickname } as User)
      }
    } catch (e) {
      message(e.data.msg, 'error')
      setData({ ...useData })
    }
  }

  /**
   * 点击获取当前弹窗类型
   * @param type
   */
  function handleClick(type: string) {
    refType.current = type
    setOpen(true)
  }

  /**
   * 弹窗动态变化
   * @param e
   */
  function onOpenChange(e) {
    setOpen(e)
    setData(useData)
  }

  // 弹窗关闭
  function handleCancel() {
    setOpen(false)
  }

  /**
   * 图片上传回调
   * @param event
   */
  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAvatarLoading(true)
    const file = event?.target?.files[0]
    const res = await handleUploadFileManage(file, 'tenant/user/')
    // const suffix = /.[^.]+$/.exec(res)[0] // 获取图片后缀名
    const path = 'tenant/user/' + res.split('tenant/user/')[1]
    try {
      setData({ ...useData, avatar: useData.avatar })
      const datas = await fetchAsyncAvatar({ avatarUrl: path, nickname: '' })
      setAvatarLoading(false)
      setUser({ ...user, avatarUrl: datas.data } as User)
      setData({ ...useData, avatar: datas.data })
      message(t('businessManagement.modifiedsuccessfully'), 'success')
    } catch (e) {
      message(e.data.msg, 'error')
      setData({ ...useData })
    }
  }

  return (
    <NavBodyLayout
      title={[t('businessManagement.HomePage'), t('accountInformation.AccountManagement')]}
      loading={[isPending]}
    >
      <div className="flex flex-col h-full w-full relative">
        <div className="flex items-center justify-between shrink-0 p-3 h-[54px] border-b text-base">
          <span>{t('accountInformation.AccountManagement')}</span>
        </div>
        <section>
          <article className="mt-4 pt-2 pl-6 rounded-xl">
            <AvatarSettings
              avatar={useData.avatar}
              avatarLoading={avatarLoading}
              onChange={onChange}
            />
            <TextInput
              modalities="editable"
              label={t('accountInformation.nickname')}
              value={useData.nickname}
              editStatus={true}
              onClick={handleClick}
              type="nickname"
            />
            <TextInput
              label={t('accountInformation.affiliated_enterprise')}
              value={useData.companies}
              onClick={handleClick}
              type="companies"
            />
            <TextInput
              label={t('accountInformation.phone')}
              value={useData.phone}
              editStatus={!useData.phone}
              onClick={handleClick}
              type="phone"
            />
            <TextInput
              label={t('accountInformation.mailbox')}
              value={useData.email}
              editStatus={!useData.email}
              onClick={handleClick}
              type="email"
            />
            <TextInput
              modalities="editable"
              label={t('accountInformation.login_password')}
              value={useData.password}
              editStatus={true}
              onClick={handleClick}
              type="password"
            />
            <LanguageSetting />
          </article>
          <SettingDialog
            value={useData[refType.current]}
            type={refType.current}
            open={open}
            onsubmit={handleSubmit}
            onCancel={handleCancel}
            onOpenChange={onOpenChange}
          />
        </section>
      </div>
    </NavBodyLayout>
  )
}

export default Settings
