import { FC, memo, useContext, useMemo, useState } from 'react'
import { ChattingWindowContext } from '../ChattingWindow'
import { filter, map } from 'lodash'
import { ILanguageList } from '@brisk/api'
import { cn, Input } from '@brisk/ui'
import translateSelectedIcon from '@renderer/assets/svg/translateSelectedIcon.svg'
import search from '@renderer/assets/svg/search.svg'
import close from '@renderer/assets/svg/close.svg'
import noSearchResult from '@renderer/assets/svg/noSearchResult.svg'
import { ReactSVG } from 'react-svg'
import { highlightText } from '../../MessageMenu/MessageItem'
import { useTranslation } from 'react-i18next'

const LanguageComp: FC<{
  onSelect?: (it: ILanguageList) => void
  className?: string
  selected?: string
}> = ({ onSelect, className, selected }) => {
  const [val, setVal] = useState('')

  const { languageList } = useContext(ChattingWindowContext)
  const { t } = useTranslation()

  const filteredList = useMemo(() => {
    return filter(languageList, (it) => it.language.includes(val))
  }, [val, languageList])

  return (
    <div>
      <div className="w-full relative rounded-md mb-2">
        <ReactSVG src={search} className="absolute top-1/2 -translate-y-1/2 left-3" />
        <ReactSVG
          src={close}
          className="absolute right-3 top-1/2 -translate-y-1/2"
          onClick={() => setVal('')}
        />
        <Input
          className="h-9 pl-9 pr-4 border-none focus:outline-none w-full bg-lingheTalkingBg rounded-md"
          placeholder={'请输入关键字'}
          value={val}
          onChange={(e) => setVal(e.target.value)}
        />
      </div>
      {map(filteredList, (it) => (
        <div
          key={it.languageCode}
          onClick={() => onSelect?.(it)}
          className={cn('item relative', className)}
        >
          {highlightText(it.language, val)}

          {selected === it.languageCode && (
            <ReactSVG
              src={translateSelectedIcon}
              className="absolute right-3 top-1/2 -translate-y-1/2"
            />
          )}
        </div>
      ))}
      {!filteredList.length && (
        <div className="w-full flex flex-col items-center justify-center min-h-[200px] text-lingheTextGray text-sm">
          <ReactSVG src={noSearchResult} />
          {t('common.noDataAvailable')}
        </div>
      )}
    </div>
  )
}

export default memo(LanguageComp)
