import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * UserDetailVO
 */
export interface IUserDtailInfoVO {
  /**
   * 账号
   */
  account?: string;
  /**
   * 客服名称
   */
  nickname?: string;
  /**
   * 所属角色
   */
  roleIdList?: string[];
  /**
   * 所属团队
   */
  teamIdList?: string[];
  /**
   * 用户编号
   */
  userId?: number;
}

interface ApiResp extends ClientResponse {
  data: IUserDtailInfoVO;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 获取人员详情
 * @param userId
 * @returns
 */
export const fetchUserDetailInfo = async (
  userId: string | number,
): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/user/detail/${userId}`);

  return serialize(data);
};
