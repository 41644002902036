/**
 * putEngine
 *
 * @desc 描述：修改翻译引擎
 * @desc 描述：接口文档名称 - 翻译线路配置
 * @desc 描述：/translate/config/engine
 */

/* =================================================== */

import type { ITranslateConfig } from './types'
import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

export const API_PATH = '/translate/config/engine'

/**
 * 获取全局翻译设置
 */
export async function putEngine(engine: IEngineParams): Promise<ITranslateConfig> {
  const res = await httpClient.put(API_PATH, engine)

  return get(res, 'data.data')
}

export interface IEngineParams {
  engine: string
  customerUniversalLanguage: string
  userUniversalLanguage: string
}
