import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageGroupSaveDTO
 */
export interface AddKbTeamGroupRequest {
  /**
   * 分组id 新增时不传
   */
  groupId?: number;
  /**
   * 分组名
   */
  groupName: string;
}
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术组新增
 * @returns
 */
export const fetchAddKbTeamGroup = async (
  params: AddKbTeamGroupRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/kb/team/group`, params);

  return serialize(data);
};
