import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * RoleVO
 */
export interface IRoleList {
  createBy?: number
  createTime?: Date
  dataScope?: number
  deletedAt?: number
  isDefault?: number
  remark?: string
  roleId?: number
  roleKey?: string
  roleName?: string
  roleSort?: number
  status?: number
  updateBy?: number
  updateTime?: Date
}

interface ApiResp extends ClientResponse {
  data: IRoleList[]
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取所有角色
 * @returns
 */
export const fetchRoleList = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/role/list`)

  return serialize(data)
}
