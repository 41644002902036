import { FC, useRef } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from '@brisk/ui'
import { Loader, User } from 'lucide-react'
import { ReactSVG } from 'react-svg'
import { AvatarSettingsInterface } from './type'
import updateAvatarButton from '../../assets/svg/updateAvatarButton.svg'
import { useTranslation } from 'react-i18next'

const AvatarSettings: FC<AvatarSettingsInterface> = ({ avatar, avatarLoading, onChange }) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  return (
    <div className="pb-6">
      <p className="pb-2 text-sm text-[rgba(29,34,60,1)]">{t('accountInformation.avatar')}</p>
      <section className="relative w-[3.25rem]" onClick={() => fileRef?.current?.click()}>
        <Avatar className="h-[3.25rem] w-[3.25rem] sm:flex">
          <AvatarImage alt="Avatar" src={avatar} className="w-full h-full rounded-full" />
          <AvatarFallback>
            {avatar != '' ? (
              <AvatarImage alt="Avatar" src={avatar} className="w-full h-full rounded-full" />
            ) : (
              <User />
            )}
          </AvatarFallback>
        </Avatar>
        <ReactSVG
          className="absolute z-10 w-5 h-5 flex items-center justify-center rounded-full right-0 bottom-0 bg-[#FFFFFF]"
          src={updateAvatarButton}
        />
        {avatarLoading && (
          <section className="h-[3.25rem] w-[3.25rem] top-0 left-0 absolute sm:flex">
            <div className="h-[3.25rem] w-[3.25rem] bg-slate-950/60 z-4 absolute rounded-full"></div>
            <Loader
              size={18}
              color={'#ffffff'}
              className="absolute settings-loading top-4 left-4 z-9"
            />
          </section>
        )}
      </section>
      <input
        ref={fileRef}
        type="file"
        accept=".png, .jpg, .jpeg, .webp"
        className=" hidden"
        onChange={async (event) => onChange(event)}
      />
    </div>
  )
}

export default AvatarSettings
