/**
 * parseAsync
 *
 * @desc 描述：解析 Promise
 * @author 作者：常国强
 */

/* =================================================== */

/**
 * 解析 Promise
 * @desc 便于 async/await 结构接参，错误处理
 */
export const parseAsync = <Res = any, Err = Error>(promise: Promise<Res>) =>
  promise
    .then((data) => [undefined, data] as [undefined, Res])
    .catch((err: Err) => {
      const _err =
        err ??
        new Error(
          "传入 parseAsync 的 Promise reject 状态没有错误对象，因此 parseAsync 自己构建一个错误对象返回，便于消费时判断",
        );

      return [_err, undefined] as [Err, undefined];
    });
