import {
  Button,
  CardContent,
  CardFooter,
  Input,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  PasswordInput
} from '@brisk/ui'
import { FC } from 'react'
import { SignInHeader } from './components/SignInHeader'
import { useParams } from 'react-router-dom'
import { Building2, LockKeyhole, ShieldCheck } from 'lucide-react'
import { inputIconClassName } from '@renderer/utils/consts'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { inconLeftClassName } from './CompanyInfo'
import { zodResolver } from '@hookform/resolvers/zod'
import { schemaPassword } from './schemaPassword/schemaPassword'
import { object, string } from 'zod'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'

interface IFormInput {
  companyName: string
  account: string
  checkCode: string
  password: string
  confirmPassword: string
}

const defaultFormValues: IFormInput = {
  companyName: 'TODO:显示租户的名称',
  account: 'TODO: 15713XXXX59',
  checkCode: '',
  password: '',
  confirmPassword: ''
}

const schema = object({
  companyName: string().min(1, '请输入公司名称'),
  account: string().min(1, '请输入公司名称'),
  checkCode: string().min(6, '请输入验证码'),
  ...schemaPassword
})

export const SignInByInvite: FC = () => {
  const { tenantId } = useParams()

  console.log(tenantId)
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema)
  })

  useErrorHinter([
    errors?.companyName,
    errors?.account,
    errors?.checkCode,
    errors?.password,
    errors?.confirmPassword
  ])
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    if (formData?.password !== formData?.confirmPassword) {
      control.setError('confirmPassword', { message: '两次输入的密码不一致' })
      return
    }
    console.log('formData', formData)
  }

  return (
    <>
      <SignInHeader />
      <CardContent className="grid gap-8">
        {`您的账号 + 8615713XXXX59,正在加入字符云助, 请您完成以下信息`}
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4">
            <div className="relative">
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <Input readOnly className="pl-10 h-12" required {...field} />
                )}
              />
              <Building2 strokeWidth={1.15} size={20} className={inconLeftClassName} />
            </div>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <div className="border w-full h-12 radios rounded-sm flex relative">
                  <Controller
                    name="account"
                    control={control}
                    render={({ field }) => (
                      <>
                        <ShieldCheck strokeWidth={1.15} className={inputIconClassName} />
                        <Input
                          readOnly
                          type="text"
                          {...field}
                          className="flex-1 h-full pl-9 text-sm outline-none border-none focus:outline-none"
                        />
                      </>
                    )}
                  />

                  <Button
                    type="button"
                    variant="link"
                    className="h-full relative"
                    onClick={() => {}}
                  >
                    <span className="absolute left-0 text-border">|</span>
                    <span>获取验证码</span>
                  </Button>
                </div>
                <Controller
                  name="checkCode"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputOTP maxLength={6} className="col-span-1" {...field}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </>
                  )}
                />
              </div>
            </div>
            <div className="relative">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    placeholder="请设置密码"
                    className="pl-10 pr-10 h-12"
                    required
                    {...field}
                  />
                )}
              />
              <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
            </div>
            <div className="relative">
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    placeholder="再次确认密码"
                    className="pl-10 pr-10 h-12"
                    required
                    {...field}
                  />
                )}
              />
              <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
            </div>
            <div>
              <Button type="submit" className="w-full mt-4 h-12">
                确认加入
              </Button>
            </div>
          </div>
        </form>
      </CardContent>

      <CardFooter className="flex justify-between"></CardFooter>
    </>
  )
}
