/**
 * 后台管理
 */
import { createContext, FC, useEffect, useLayoutEffect, useState } from 'react'
import NavComp from './NavComp'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ManageHeader from './ManageHeader'
import { useUserStore } from '@renderer/store/useUserStore'
import manageHeaderHelp from '@renderer/assets/svg/manageHeaderHelp.svg'
import manageHeaderHelp3 from '@renderer/assets/svg/manageHeaderHelp3.svg'
import downloadDesktop from '@renderer/assets/svg/downloadDesktop.svg'
import downloadDesktop2 from '@renderer/assets/svg/downloadDesktop2.svg'
import serverOnline from '@renderer/assets/svg/serverOnline.svg'
import serverOnline2 from '@renderer/assets/svg/serverOnline2.svg'
import joyridearrow from '@renderer/assets/svg/joyridearrow.svg'
import { ReactSVG } from 'react-svg'
import { handleSetUserInfo, showServiceChat } from '@renderer/utils'
import Joyride, { CallBackProps } from 'react-joyride'
import { cn } from '@brisk/ui'
import { useNewBieGuide } from '../../store/manageStore/useNewBieGuide'
import { APP_ROUTER_PATH } from '../../router'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { fetchUserDetails } from '@brisk/api'
import { useSignStore } from '@renderer/store/useSignStore'

interface IManageInfo {
  userHandle: boolean
  setUserHandle: (value: boolean) => void
}

export const ManageInfoContext = createContext<IManageInfo>({
  userHandle: false,
  setUserHandle: () => {}
})

const manager: FC = () => {
  const [userHandle, setUserHandle] = useState(true) // true: 新用户引导，false: 不显示
  const [showTips, setShowTips] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { clear, user, manageNewUserHandle, setManageNewUserHandle } = useUserStore()
  const { setInputAccountToSign } = useSignStore()
  const { t } = useTranslation()
  const [stepIndex, setStepIndex] = useState(0)
  const { mutateAsync: onFetchUserDetail } = useMutation({
    mutationFn: fetchUserDetails
  })

  const [steps, setState] = useState([
    {
      title: t('managerBeginnerGuide.InviteMembers'),
      target: '.manangerItem-3',
      placement: 'right-end',
      placementBeacon: 'right-end',
      disableBeacon: true,
      content: t('managerBeginnerGuide.AddCustomerServicefromyourteamAIOChat')
    },
    {
      title: t('managerBeginnerGuide.Addmessagingchannel'),
      target: '.manangerItem-10',
      content: t('managerBeginnerGuide.Connectyourchannelscommunicationwithyourcustomers'),
      placement: 'right-start'
    },
    {
      title: t('managerBeginnerGuide.Setthenumbersimultaneouscommunications'),
      target: '.manangerItem-19',
      content: t(
        'managerBeginnerGuide.Thenumbercustomersyouragentscancommunicatewithatthesametime'
      ),
      placement: 'right-start'
    },
    {
      title: t('managerBeginnerGuide.DownloadCustomerServiceWorkbench'),
      target: '.download-Workbench',
      content: (
        <div>
          <p>
            {t(
              'managerBeginnerGuide.Viewworkbenchreceivemessagesfromallyourcustomerscommunicatewiththem'
            )}
          </p>
          <a
            className="text-[#FAF126] underline text-sm"
            href="https://test.cd.goodim.cc:8182/downloads"
            target="_blank"
            rel="noreferrer"
          >
            {t('common.Download')}
          </a>
        </div>
      ),
      placement: 'bottom'
    },
    {
      title: t('managerBeginnerGuide.ContactAIOChatCustomerService'),
      target: '.online-CustomerService',
      content: t('managerBeginnerGuide.AskAIOChatanyquestionsandshareyourfeedback'),
      placement: 'bottom'
    }
  ])
  const { list } = useNewBieGuide()
  const [open, setOpen] = useState(false)
  const placement = {
    '1': {
      tooltipTop: 'top-[-14px] left-[30px]',
      reactSVG: 'left-[-67px] top-[31px]'
    },
    '2': {
      tooltipTop: 'top-[-62px] left-[30px]',
      reactSVG: 'left-[-67px] top-[31px]'
    },
    '3': {
      tooltipTop: 'top-[95px] left-[30px]',
      reactSVG: 'left-[-67px] top-[30px]'
    },
    '4': {
      tooltipTop: 'top-[48px] left-[-84px]',
      reactSVG: 'top-[-37px] rotate-90 left-[54px]'
    },
    '5': {
      tooltipTop: 'top-[48px] left-[-80px]',
      reactSVG: 'top-[-37px] rotate-90 left-[54px]'
    }
  }
  async function onFetchUserDetailFUn() {
    const data = await onFetchUserDetail(user.userId)
    const roleIdList = data.data.roleIdList
    if (roleIdList.indexOf('1') > -1) {
      navigate(APP_ROUTER_PATH.MANAGER_ADDCUSTOMER)
      setOpen(true)
    }
  }
  useEffect(() => {
    if (list && list.length && !localStorage.getItem('newbieGuide')) onFetchUserDetailFUn()
  }, [list])
  useEffect(() => {
    let timer
    if (!userHandle && !manageNewUserHandle) {
      setShowTips(true)
      setManageNewUserHandle(true)
      timer = setTimeout(() => setShowTips(false), 3000)
    } else {
      setShowTips(false)
    }

    return () => clearTimeout(timer)
  }, [userHandle])

  /** 从桌面端到后台 自动登录 */
  useLayoutEffect(() => {
    const from = new URLSearchParams(location?.search).get('from')
    const userInfo = new URLSearchParams(location?.search).get('userInfo')
    const user = JSON.parse(decodeURIComponent(userInfo))

    if (from === 'desktop' && user) useUserStore.getState().setUser(user)
  }, [location])

  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps
  }) => (
    <div
      className={cn(
        'tooltip-body w-[414px] p-6 min-h-[192px] absolute rounded-lg bg-[#247CFF] top-[-62px] left-[30px]',
        placement[index + 1].tooltipTop
      )}
      {...tooltipProps}
    >
      <ReactSVG src={joyridearrow} className={cn('absolute', placement[index + 1].reactSVG)} />
      <p className="text-base text-[#FFFFFF] pb-3">
        {index + 1}
        <span className="text-[rgba(255,255,255,0.50)]">/{steps.length}</span>
      </p>
      <h1 className="text-xl text-[#FFFFFF] pb-2">{step.title}</h1>
      <p className="text-sm text-[#FFFFFF] font-normal pb-6">{step.content}</p>
      <div className="tooltip-footer">
        {index + 1 === steps.length ? (
          <div
            className="guide-next float-right w-[88px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
            {...closeProps}
          >
            {t('managerBeginnerGuide.IgotIt')}
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <span className="text-base text-[#FFFFFF]" {...skipProps}>
              {t('managerBeginnerGuide.Skip')}
            </span>
            <div className="flex items-center gap-[12px]">
              {index > 0 ? (
                <div
                  className="guide-prev w-[74px] text-base text-[#FFFFFF] leading-8 h-8 li rounded bg-[rgba(255,255,255,0.20)] text-center"
                  {...backProps}
                >
                  {t('managerBeginnerGuide.Previous')}
                </div>
              ) : (
                ''
              )}
              <div
                className="guide-next w-[74px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
                {...primaryProps}
              >
                {t('managerBeginnerGuide.Next')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  /**
   * 新手引导回调
   * @param data
   */
  function callback(data: CallBackProps) {
    const { action, index, origin, status, type } = data
    const next_router = {
      1: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE,
      2: APP_ROUTER_PATH.MANAGER_AGENT
    }
    const prev_router = {
      0: APP_ROUTER_PATH.MANAGER_ADDCUSTOMER,
      1: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE,
      2: APP_ROUTER_PATH.MANAGER_AGENT
    }
    switch (action) {
      case 'prev':
        if (type === 'step:before') {
          if (prev_router[index]) {
            navigate(prev_router[index])
          }
        }
        break
      case 'next':
        if (type === 'step:before') {
          if (next_router[index]) {
            navigate(next_router[index])
          }
        }
        break
      case 'reset':
        navigate(APP_ROUTER_PATH.MANAGER_HOME)
        localStorage.setItem('newbieGuide', 'fulfil')
        break
    }
  }

  useEffect(() => {
    setInputAccountToSign(false)
    window.addEventListener('focus', () => {
      handleSetUserInfo()
    })
    return () => {
      window.removeEventListener('focus', () => {
        handleSetUserInfo()
      })
    }
  }, [])

  return (
    <ManageInfoContext.Provider value={{ userHandle, setUserHandle }}>
      <Joyride
        callback={callback}
        run={open}
        tooltipComponent={Tooltip}
        steps={steps}
        disableCloseOnEsc
        disableOverlayClose
        continuous={true}
        showSkipButton
        styles={{ options: { arrowColor: 'transparent' } }}
      />
      <div className="w-[100vw] h-[100vh] min-w-[800px] min-h-[800px] overflow-auto bg-lingheTalkingBg nou flex">
        <div className="bg-white h-full shrink-0 overflow-x-hidden">
          <NavComp />
        </div>

        <div className="h-full w-full flex-1 shrink-0 flex flex-col overflow-hidden">
          <div className="w-full h-14 bg-white flex items-center justify-end">
            <div className="w-full h-full flex items-center gap-2">
              <div
                className="text-sm w-full h-full flex items-center justify-start gap-8 px-3 [&_.item]:cursor-pointer [&_.item]:flex 
              [&_.item]:gap-2 [&_.item]:items-center [&_.item:hover]:text-primary"
              >
                <div
                  className="item group download-Workbench"
                  onClick={() => {
                    window.open(import.meta.env.VITE_DOWNLOAD_URL, '_blank')
                  }}
                >
                  <ReactSVG className="group-hover:hidden" src={downloadDesktop} />
                  <ReactSVG className="hidden group-hover:block " src={downloadDesktop2} />
                  <span>{t('managerBeginnerGuide.DownloadCustomerServiceWorkbench')}</span>
                </div>
                <div
                  className="item group online-CustomerService"
                  onClick={() => {
                    showServiceChat()
                  }}
                >
                  <ReactSVG className="group-hover:hidden" src={serverOnline} />
                  <ReactSVG className="hidden group-hover:block " src={serverOnline2} />
                  <span>{t('common.LiveChat')}</span>
                </div>
                <div
                  className="item group"
                  onClick={() => {
                    window.open(import.meta.env.VITE_HELP_URL, '_blank')
                  }}
                >
                  <ReactSVG className="group-hover:hidden" src={manageHeaderHelp} />
                  <ReactSVG className="hidden group-hover:block" src={manageHeaderHelp3} />
                  <span>{t('common.HelpCenter')}</span>
                </div>
              </div>
            </div>
            <ManageHeader singOut={clear} avatar={user?.avatarUrl} name={user?.nickname} />
          </div>

          <div
            className={cn(
              'p-4 pt-0 flex-1 shrink-0 relative',
              location.pathname === '/manager/home'
                ? 'overflow-y-auto overflow-x-hidden'
                : 'overflow-hidden'
            )}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </ManageInfoContext.Provider>
  )
}

export default manager
