import { FC, useState } from 'react'
import * as Select from '@radix-ui/react-select'
import { ChevronDownIcon } from 'lucide-react'
import '../languageSetting/style/index.css'
import select_arrow from '../../../../../assets/svg/selectarrow.svg'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import checked_tick from '../../../../../assets/svg/checked_tick.svg'
import { cn } from '@brisk/ui'
import { isElectron } from '../../../../../utils'

const list = [
  {
    id: '001',
    value: 'zh',
    text: '简体中文'
  },
  {
    id: '002',
    value: 'en',
    text: 'English'
  }
  // {
  //   id: '003',
  //   value: 'tc',
  //   text: '繁體中文'
  // },
  // {
  //   id: '004',
  //   value: 'ar',
  //   text: 'العربية'
  // }
]
const LanguageSetting: FC = () => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const index = list.findIndex((item) => item.value == i18n.language.slice(0, 2))
  function onValueChange(res) {
    i18n.changeLanguage(res)
    localStorage.setItem('activeMenuIconCode', '4') // 切换语言是选中的侧边栏icon
    !isElectron && location.reload()
  }
  function onOpenChange(isOpen: boolean) {
    setIsOpen(isOpen)
  }
  return (
    <div className="language-settings pb-[1rem]">
      <h1 className="text-base font-[500] pb-2">{t('systemSetting.language_setting')}</h1>
      <Select.Root onValueChange={onValueChange} onOpenChange={onOpenChange}>
        <Select.Trigger className="SelectTrigger" aria-label="Food">
          <Select.Value placeholder={list[index < 0 ? 0 : index].text} />
          <Select.Icon className="SelectIcon">
            <ReactSVG
              className={cn('transition-all duration-300', isOpen ? 'rotate-180' : 'rotate-0')}
              src={select_arrow}
            />
          </Select.Icon>
        </Select.Trigger>

        <Select.Portal>
          <Select.Content position={'popper'} className="SelectContent">
            <Select.ScrollUpButton className="SelectScrollButton" />
            <Select.Viewport className="SelectViewport">
              <Select.Group>
                {list.map((item) => {
                  return (
                    <Select.Item className="SelectItem relative" key={item.id} value={item.value}>
                      <Select.ItemText>{item.text}</Select.ItemText>
                      {item.value === i18n.language.slice(0, 2) && (
                        <ReactSVG className="absolute top-3.5 right-2.5" src={checked_tick} />
                      )}
                    </Select.Item>
                  )
                })}
              </Select.Group>
              <Select.Separator />
            </Select.Viewport>
            <Select.ScrollDownButton className="SelectScrollButton">
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  )
}

export default LanguageSetting
