import { createContext, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import NavBodyLayout from '../Components/NavBodyLayout'
import wordsTips from '../../../assets/svg/wordsTips.svg'
import messageMore from '../../../assets/svg/messageMore.svg'
import search from '../../../assets/svg/search.svg'
import reset from '../../../assets/svg/reset.svg'
import emptyTagList from '../../../assets/svg/emptyTagList.svg'
import { debounce, map } from 'lodash'
import { Button, cn, Input, Popover, PopoverContent, PopoverTrigger } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { inputIconClassName } from '@renderer/utils/consts'
import { IPageationRef } from '../AddCustomer'
import { ColumnDef } from '@tanstack/react-table'
import AddTeamWordsGroup from './AddTeamWordsGroup'
import DelCustolerModal from '../Components/DelCustolerModal'
import { ManagerTable } from '@renderer/components/ManagerTable'
import AddWords from './AddWords'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  AddKbTeamGroupRequest,
  AddTeamWordsRequest,
  ClientResponse,
  fetchAddKbTeamGroup,
  fetchAddTeamWords,
  fetchDelTeamGroup,
  fetchDelTeamWords,
  fetchEditTeamGroup,
  fetchEditTeamWords,
  fetchKbTeamGroupList,
  fetchTeamGroupPage,
  KbTeamGroupListResponse,
  RESPONSE_CODE,
  TeamGroupPageData,
  TeamGroupPageResponse
} from '@brisk/api'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'
import ImageViewManage from '@renderer/components/ImageViewManage'

export const TeamWordsContext = createContext<{
  list: KbTeamGroupListResponse[]
  refetch: () => void
  addGroup: (param: AddKbTeamGroupRequest) => Promise<ClientResponse>
  handleAddWords: (param: AddTeamWordsRequest) => Promise<void>
}>({
  list: [],
  addGroup: null,
  refetch: null,
  handleAddWords: null
})

const index: FC = () => {
  const { t } = useTranslation()
  const defaultGroup = {
    groupId: -1,
    groupName: t('knowledgeBase.AllGroups')
  }
  const [activeGroup, setActiveGroup] = useState<KbTeamGroupListResponse>(defaultGroup)
  const [teamWordsData, setTeamWordsData] = useState<TeamGroupPageResponse>()
  const [addWordsData, setAddWordsData] = useState<{
    open: boolean
    data: TeamGroupPageData | null
  }>({
    open: false,
    data: null
  })

  const [addGroupData, setAddGroupData] = useState<{
    open: boolean
    data: KbTeamGroupListResponse | null
  }>({
    open: false,
    data: null
  })

  const [delData, setDelData] = useState<{
    open: boolean
    data: KbTeamGroupListResponse | null
  }>({
    open: false,
    data: null
  })

  const [delWordsData, setDelWordsData] = useState<{
    open: boolean
    data: TeamGroupPageData | null
  }>({
    open: false,
    data: null
  })

  const searchInpuRef = useRef<HTMLInputElement>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  /** 团队话术组列表 */
  const {
    refetch,
    data,
    isPending: listLoading
  } = useQuery({
    queryKey: [fetchKbTeamGroupList.name + 'fetchKbTeamGroupList'],
    queryFn: async () => {
      const res = await fetchKbTeamGroupList()
      return res?.data
    }
  })

  /** 团队话术组新增 */
  const { mutateAsync: addGroup, isPending: addLoading } = useMutation({
    mutationFn: fetchAddKbTeamGroup
  })

  /** 团队话术组 编辑 */
  const { mutateAsync: editGroup, isPending: editLoading } = useMutation({
    mutationFn: fetchEditTeamGroup
  })

  /** 团队话术组 删除 */
  const { mutateAsync: delGroup, isPending: delGroupLoading } = useMutation({
    mutationFn: fetchDelTeamGroup
  })

  /** 团队话术 新增 */
  const { mutateAsync: addTeamWords, isPending: addWordsLoading } = useMutation({
    mutationFn: fetchAddTeamWords
  })

  /** 团队话术 编辑 */
  const { mutateAsync: editTeamWords, isPending: editWordsLoading } = useMutation({
    mutationFn: fetchEditTeamWords
  })

  /** 团队话术 删除 */
  const { mutateAsync: delTeamWords, isPending: delWordsLoading } = useMutation({
    mutationFn: fetchDelTeamWords
  })

  /** 团队话术分页查询 */
  const { mutateAsync: getTeamWordsPage, isPending: teamWordsLoading } = useMutation({
    mutationFn: fetchTeamGroupPage
  })

  const handleGetTeamWordsPage = async (groupId?: number, pageNum?: number, pageSize?: number) => {
    try {
      const res = await getTeamWordsPage({
        groupId: groupId === -1 ? null : groupId,
        keyword: searchInpuRef?.current?.value || null,
        pageNum: pageNum || pageationRef?.current?.currentPage || 1,
        pageSize: pageSize || pageationRef?.current?.itemsPerPage || 10
      })
      if (res?.code === RESPONSE_CODE.success) setTeamWordsData(res?.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleAddWords = useCallback(
    async (param) => {
      try {
        const res = await addTeamWords(param)
        if (res?.code === RESPONSE_CODE.success) {
          message(t('knowledgeBase.Addedwordsofsuccess'), 'success')
          handleGetTeamWordsPage(activeGroup?.groupId, 1, 10)
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    [activeGroup]
  )

  const searchInput = debounce(() => handleGetTeamWordsPage(activeGroup?.groupId), 500)

  const columns: ColumnDef<TeamGroupPageData>[] = useMemo(
    () => [
      {
        id: 'shortcutKey',
        className: 'w-[120px]',
        header: () => <div>{t('knowledgeBase.Shortcuts')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
            {original?.shortcutKey}
          </div>
        )
      },
      {
        id: 'textContent',
        header: () => <div>{t('knowledgeBase.Tacticcontent')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[740px] overflow-hidden text-ellipsis whitespace-nowrap">
            {original?.textContent}
          </div>
        )
      },
      {
        id: 'imageContent',
        className: 'w-[70px]',
        header: () => <div>{t('knowledgeBase.Picture')}</div>,
        cell: ({
          row: {
            original: { imageContent }
          }
        }) => (
          <div className="flex items-center justify-center">
            {!imageContent || imageContent?.length === 0 ? (
              <span> </span>
            ) : (
              <ImageViewManage
                src={imageContent && imageContent?.length && imageContent[0]}
                className="w-7 h-7 rounded-md cursor-pointer"
              />
            )}
          </div>
        )
      },
      {
        id: 'groupName',
        header: () => <div>{t('knowledgeBase.Grouping')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[500px] overflow-hidden text-ellipsis whitespace-nowrap">
            {original?.groupName}
          </div>
        )
      },
      {
        id: 'edit',
        className: 'w-[120px]',
        header: () => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }) => (
          <div className="flex items-center gap-2 [&_button]:p-0">
            <Button
              variant="link"
              className="hover:no-underline"
              onClick={() =>
                setAddWordsData({
                  open: true,
                  data: original
                })
              }
            >
              {t('login.Edit')}
            </Button>
            <span className="text-[#ECECEC]">|</span>
            <Button
              variant="link"
              className="text-lingheError hover:no-underline"
              onClick={() =>
                setDelWordsData({
                  open: true,
                  data: original
                })
              }
            >
              {t('chatFrame.removing')}
            </Button>
          </div>
        )
      }
    ],
    []
  )

  useEffect(() => {
    if (activeGroup?.groupId) handleGetTeamWordsPage(activeGroup?.groupId)
  }, [activeGroup])

  return (
    <TeamWordsContext.Provider
      value={{
        list: data,
        addGroup,
        refetch,
        handleAddWords
      }}
    >
      <NavBodyLayout
        title={[t('knowledgeBase.knowledgeBaseTitle'), t('knowledgeBase.Teamtalk')]}
        loading={[
          listLoading,
          addLoading,
          teamWordsLoading,
          addWordsLoading,
          editLoading,
          editWordsLoading,
          delWordsLoading,
          delGroupLoading
        ]}
      >
        <div className="flex flex-col h-full w-full relative">
          <div className="flex items-center gap-3 shrink-0 p-3 h-[54px] border-b">
            <span className="text-base font-medium">{t('knowledgeBase.Teamtalk')}</span>
            <div className="flex h-full items-center gap-1.5">
              <ReactSVG src={wordsTips} />
              <span className="text-sm text-lingheTextTips">
                {t('knowledgeBase.Thisiswhereconfigureyourteamsvisitors')}
              </span>
            </div>
          </div>
          <div className="flex-1 shrink-0 overflow-hidden flex">
            <div className="w-[252px] shrink-0 h-full overflow-hidden border-r">
              <div className="w-full h-full flex flex-col gap-2 overflow-y-auto p-2 pb-4">
                <div className="flex flex-col gap-0.5">
                  <div
                    key={-1}
                    className={cn(
                      'h-12 flex items-center justify-between pl-4 pr-3 rounded-md hover:bg-lingheBtnHover cursor-pointer',
                      activeGroup?.groupId === -1 ? 'font-semibold bg-lingheBtnHover' : ''
                    )}
                    onClick={() => setActiveGroup(defaultGroup)}
                  >
                    <span className="max-w-[178px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {defaultGroup?.groupName}
                    </span>
                  </div>
                  {map(data, (item, index) => (
                    <div
                      key={item?.groupId + index + ''}
                      className={cn(
                        'h-12 flex items-center justify-between pl-4 pr-3 rounded-md hover:bg-lingheBtnHover cursor-pointer',
                        activeGroup?.groupId === item?.groupId
                          ? 'bg-lingheBtnHover font-semibold'
                          : ''
                      )}
                      onClick={() => setActiveGroup(item)}
                    >
                      <span className="max-w-[178px] overflow-hidden text-ellipsis whitespace-nowrap">
                        {item?.groupName}
                      </span>
                      <Popover>
                        <PopoverTrigger>
                          <ReactSVG src={messageMore} className="cursor-pointer rotate-90" />
                        </PopoverTrigger>
                        <PopoverContent className="p-2 w-[68px] -translate-x-[20%]" side="bottom">
                          <div className="text-sm flex flex-col [&_span]:cursor-pointer [&_span:hover]:bg-lingheBtnHover [&_span]:w-full [&_span]:h-8 [&_span]:rounded-md [&_span]:flex [&_span]:items-center [&_span]:justify-center">
                            <span
                              onClick={() =>
                                setAddGroupData({
                                  open: true,
                                  data: item
                                })
                              }
                            >
                              {t('login.Edit')}
                            </span>
                            <span
                              className="text-lingheError"
                              onClick={() =>
                                setDelData({
                                  open: true,
                                  data: item
                                })
                              }
                            >
                              {t('chatFrame.removing')}
                            </span>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                  ))}
                </div>
                <div
                  className="w-full h-10 shrink-0 border rounded-md flex items-center justify-center cursor-pointer gap-1"
                  onClick={() =>
                    setAddGroupData({
                      open: true,
                      data: null
                    })
                  }
                >
                  <Plus size={14} />
                  {t('knowledgeBase.AddGrouping')}
                </div>
              </div>
            </div>
            <div className="flex-1 h-full shrink-0 overflow-hidden flex flex-col">
              <div className="w-full h-[54px] flex items-center pl-3 pr-3 ">
                <div className="h-full flex items-center gap-1">
                  <span className="mr-2 text-base font-semibold">{activeGroup?.groupName}</span>
                  <span className="text-lingheTextGray">|</span>
                  <span className="text-lingheTextGray">{t('knowledgeBase.Number')}</span>
                  <span className="text-primary">{teamWordsData?.total || 0}</span>
                </div>
              </div>
              <div className="flex w-full h-8 shrink-0 items-center justify-between pl-3 pr-3">
                <div className="relative">
                  <Input
                    ref={searchInpuRef}
                    placeholder={t('knowledgeBase.Searchforshortcutsorphrases')}
                    className="pl-6 w-[200px] h-9"
                    onInput={searchInput}
                  />
                  <ReactSVG src={search} className={cn(inputIconClassName)} />
                </div>
                <div className="flex items-center gap-3 [&_button]:flex [&_button]:items-center [&_button]:h-8 [&_button]:gap-1">
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (searchInpuRef.current) searchInpuRef.current.value = ''
                    }}
                  >
                    <ReactSVG src={reset} />
                    {t('clientManagement.Reset')}
                  </Button>
                  <Button onClick={() => setAddWordsData({ open: true, data: null })}>
                    <Plus />
                    {t('knowledgeBase.Addaphrase')}
                  </Button>
                </div>
              </div>
              <div className="flex-1 shrink-0 overflow-hidden mt-4">
                <ManagerTable<TeamGroupPageData>
                  pageationRef={pageationRef}
                  data={teamWordsData?.records || []}
                  columns={columns}
                  rowCount={teamWordsData?.total || 1}
                  onPageChange={(pageNum, pageSize) => {
                    handleGetTeamWordsPage(activeGroup?.groupId, pageNum, pageSize)
                  }}
                  emptyElement={() => (
                    <div className="flex items-center justify-center pt-20">
                      <div className="flex flex-col items-center justify-center gap-2">
                        <ReactSVG src={emptyTagList} />
                        <span className="mb-2 text-sm text-lingheTextGray">
                          {t('common.noDataAvailable')}
                        </span>
                        <Button onClick={() => setAddWordsData({ open: true, data: null })}>
                          <Plus />
                          {t('knowledgeBase.Addaphrase')}
                        </Button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <AddTeamWordsGroup
          data={addGroupData?.data}
          open={addGroupData?.open}
          onClose={() => setAddGroupData({ open: false, data: null })}
          onConfirm={async (val) => {
            try {
              addGroupData?.data?.groupId
                ? await editGroup({
                    groupName: val,
                    groupId: addGroupData?.data?.groupId || undefined
                  })
                : await addGroup({
                    groupName: val
                  })
              refetch()
            } catch (error) {
              console.log('error', error)
            }
          }}
        />

        <DelCustolerModal
          title={t('businessManagement.removing')}
          open={delData.open}
          onClose={() => {
            setDelData({
              open: false,
              data: null
            })
          }}
          onConfirm={async () => {
            try {
              const res = await delGroup(delData?.data?.groupId + '')
              if (res?.code === RESPONSE_CODE.success) {
                message(t('businessManagement.Deletedsuccessfully'), 'success')
                setDelData({
                  open: false,
                  data: null
                })
                refetch()
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center">
              {t('knowledgeBase.Informationwithingroupwillbedeletedsynchronously')}
            </div>
          }
        />

        <DelCustolerModal
          title={t('businessManagement.removing')}
          open={delWordsData.open}
          onClose={() => {
            setDelWordsData({
              open: false,
              data: null
            })
          }}
          onConfirm={async () => {
            try {
              const res = await delTeamWords(delWordsData?.data?.preMessageId + '')
              if (res?.code === RESPONSE_CODE.success) {
                message(t('businessManagement.Deletedsuccessfully'), 'success')
                handleGetTeamWordsPage(activeGroup?.groupId)
                setDelWordsData({
                  open: false,
                  data: null
                })
              }
            } catch (error) {
              console.log('error', error)
            }
          }}
          content={
            <div className="text-lingheTextGray text-sm text-center">
              {t('knowledgeBase.Oncedeletedagentswilllongerabletouseit')}
            </div>
          }
        />

        <AddWords
          open={addWordsData?.open}
          data={addWordsData?.data}
          onClose={() => setAddWordsData({ open: false, data: null })}
          onConfirm={async (data, type = 'add') => {
            if (type === 'add') handleAddWords(data)
            else {
              try {
                const res = await editTeamWords(data)
                if (res?.code === RESPONSE_CODE.success) {
                  message(t('common.EditSuccess'), 'success')
                  handleGetTeamWordsPage(activeGroup?.groupId)
                }
              } catch (error) {
                console.log('error', error)
              }
            }
          }}
        />
      </NavBodyLayout>
    </TeamWordsContext.Provider>
  )
}

export default memo(index)
