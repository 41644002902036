import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Checkbox,
  Input,
  cn
} from '@brisk/ui'
import { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import openSvg from '../../../assets/svg/open.svg'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { concat, debounce, every, filter, forEach, includes, map, pull, uniq } from 'lodash'
import { UserInfoContext } from '../PersonManage'
import { EmployeeStatus } from '@renderer/api/model'
import { User } from 'lucide-react'
import { highlightText } from '@renderer/pages/home/MessageMenu/MessageItem'
import { ITeamResponse, TeamUser } from '@renderer/api'
import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'
import { Mcheckbox } from './Mcheckbox'
import { useTranslation } from 'react-i18next'

export const findMatchingUsersOptimized = (teamTree, idArr): TeamUser[] => {
  // 创建一个userId到user的映射
  const userIdToUser = {}
  forEach(teamTree, (team) => {
    forEach(team?.userList, (user) => {
      userIdToUser[user?.userId] = user
    })
  })

  // 存储找到的匹配项
  const matchingUsers = map(idArr, (userId) => userIdToUser[userId])

  // 过滤掉未找到的用户
  return filter(matchingUsers, Boolean)
}

interface ITeamUserListProps {
  data: TeamUser[]
  searchVal?: string
  userList: Array<number | string>
  setUserList: (val: Array<number | string>) => void
}

const TeamUserList: FC<ITeamUserListProps> = ({ data, searchVal, userList, setUserList }) => {
  return (
    <div className="w-full flex flex-col gap-1">
      {map(data, (item) => (
        <div
          key={'' + item?.userId}
          className={cn(
            'w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-10 rounded-md hover:bg-lingheBtnHover'
          )}
        >
          <Checkbox
            id={item?.userId + ''}
            className="ml-6"
            checked={includes(userList, item?.userId)}
            onCheckedChange={(e) => {
              e
                ? setUserList(uniq(concat(userList, item?.userId)))
                : setUserList([...pull(userList, item?.userId)])
            }}
          />
          <label htmlFor={item?.userId + ''} className="flex-1 flex items-center gap-2">
            <div className="relative">
              <Avatar className="hidden h-6 w-6 sm:flex flex items-center">
                <AvatarImage alt="Avatar" src={item?.avatarUrl} className="rounded-full" />
                <AvatarFallback>
                  <User className="" />
                </AvatarFallback>
              </Avatar>
              <div
                className={cn(
                  'size-[6px] border-white border rounded-full absolute bottom-0 right-0',
                  statusColor[EmployeeStatus[item?.status || '']] || ''
                )}
              />
            </div>
            <span>{highlightText(item?.nickname, searchVal)}</span>
          </label>
        </div>
      ))}
    </div>
  )
}

interface IChooseTeamUserList {
  open: boolean
  onClose: () => void
  onConfirm: (param: TeamUser[]) => void
}

const ChooseTeamUserList: FC<IChooseTeamUserList> = ({ open, onClose, onConfirm }) => {
  const { teamTree, indexedTeamTree, userList, setAddGroupUserList } = useContext(UserInfoContext)
  const [openedList, setOpenedList] = useState<(string | number)[]>([])
  const [searchVal, setSearchVal] = useState('')
  const [searchList, setSearchList] = useState<ITeamResponse[]>([])
  const { t } = useTranslation()

  const handleSearch = useCallback(
    debounce(<T extends ITeamResponse>(val: string, targetArr: T[]): void => {
      const res = map(targetArr, (item) => {
        const listRes = filter(
          item?.userList,
          (user) =>
            includes(user?.nickname, val) ||
            includes(user?.phone, val) ||
            includes(user?.email, val)
        )
        if (listRes?.length) {
          setOpenedList(uniq([...openedList, item?.teamId + '']))
          return {
            ...item,
            userList: listRes
          }
        } else {
          return null
        }
      })
      const filteredRes = res.filter(Boolean) as T[]
      setSearchList(filteredRes)
    }, 500),
    [teamTree, searchVal]
  )

  const alreadySelect = useMemo(() => {
    if (!teamTree || !userList) return []
    return findMatchingUsersOptimized(teamTree, userList)
  }, [userList, teamTree])

  useEffect(() => {
    handleSearch(searchVal, teamTree)
  }, [searchVal])

  useEffect(() => setOpenedList(map(teamTree, (it) => it?.teamId + '')), [teamTree])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogDescription />
      <AlertDialogContent className="p-0 max-w-[780px] focus:outline-none">
        <AlertDialogTitle className="h-[54px] pl-3 pr-3 relative flex items-center justify-between border-b">
          <span>{t('businessManagement.Pleaseselectpanellists')}</span>
          <ReactSVG
            src={close}
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />
        </AlertDialogTitle>

        <div className="p-4">
          <div className="text-sm flex items-center h-[486px] rounded-md border">
            <div className="flex-1 shrink-0 border-r h-full flex flex-col">
              <div className="relative p-3">
                <Input
                  value={searchVal}
                  placeholder={t('businessManagement.Pleaseenteryournameandaccountnumbertosearch')}
                  className="h-10 shrink-0"
                  onChange={(e) => setSearchVal(e?.target?.value)}
                />
              </div>
              <div className="flex-1 shrink-0 overflow-y-auto text-sm ">
                <div className="flex flex-col gap-1 mb-2 pl-3 pr-3">
                  <div className="w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-10 rounded-md hover:bg-lingheBtnHover">
                    <Mcheckbox
                      id="all"
                      checked={userList?.length === Object.keys(indexedTeamTree)?.length}
                      onCheckedChange={(e) =>
                        e
                          ? setAddGroupUserList(Object.keys(indexedTeamTree) as TeamUser[])
                          : setAddGroupUserList([])
                      }
                    />
                    <label htmlFor="all">{t('common.full')}</label>
                  </div>
                </div>

                {map(searchVal ? searchList : teamTree, (item) => {
                  const children = item?.userList
                  const teamId = '' + item?.teamId || ''
                  const onlineCount =
                    filter(children, (child) => child.status === EmployeeStatus.ONLINE).length || 0
                  const idsArr = map(children, (it) => it?.userId) as TeamUser[]
                  return (
                    <div key={'' + teamId} className="flex flex-col gap-1 mb-2 pl-3 pr-3">
                      <div
                        className={cn(
                          'w-full pl-3 pr-3 flex items-center gap-2 cursor-pointer h-10 rounded-md hover:bg-lingheBtnHover'
                        )}
                        onClick={() => {
                          if (children?.length) {
                            if (!includes(openedList, teamId))
                              setOpenedList(uniq([...openedList, teamId]))
                            else setOpenedList(filter(openedList, (it) => it !== teamId))
                          }
                        }}
                      >
                        <Mcheckbox
                          id={item?.teamId + ''}
                          checked={every(idsArr, (it) => includes(userList, it))}
                          onCheckedChange={(e) => {
                            e
                              ? setAddGroupUserList(uniq(concat(userList, idsArr)))
                              : setAddGroupUserList(filter(userList, (it) => !includes(idsArr, it)))
                          }}
                        />
                        <label htmlFor={item?.teamId + ''} className="flex items-center gap-2">
                          <ReactSVG
                            src={openSvg}
                            className={cn(
                              'transition',
                              !includes(openedList, teamId) && 'rotate-[30deg]',
                              !children?.length && 'opacity-0'
                            )}
                          />
                          {/*<div className="relative">*/}
                          {/*  <Avatar className="hidden h-6 w-6 sm:flex flex items-center">*/}
                          {/*    <AvatarImage*/}
                          {/*      alt="Avatar"*/}
                          {/*      src={item?.groupAvatar}*/}
                          {/*      className="rounded-full"*/}
                          {/*    />*/}
                          {/*    <AvatarFallback>*/}
                          {/*      <User className="" />*/}
                          {/*    </AvatarFallback>*/}
                          {/*  </Avatar>*/}
                          {/*</div>*/}
                          <span>
                            {highlightText(item?.teamName, searchVal)}
                            {`(${onlineCount}/${children?.length || 0})`}
                          </span>
                        </label>
                      </div>
                      {children?.length ? (
                        <div
                          className={cn(
                            'transition-all duration-300 ease-in-out mb-0',
                            includes(openedList, teamId) ? 'block' : 'hidden'
                          )}
                        >
                          <TeamUserList
                            searchVal={searchVal}
                            data={children}
                            userList={userList}
                            setUserList={(val) => setAddGroupUserList(val)}
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  )
                })}
                {searchVal && !searchList?.length ? (
                  <div className="w-full flex flex-col items-center justify-center min-h-[200px]">
                    <ReactSVG src={noSearchResult} />
                    {t('common.noDataAvailable')}
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>

            <div className="flex-1 shrink-0 h-full flex flex-col text-sm">
              <div className="pl-3 pr-3 pt-5 pb-5 flex items-center justify-between shrink-0">
                <div className="text-lingheGray">
                  <span>{t('businessManagement.elected')}：</span>
                  <span className="text-primary">{userList?.length || 0}</span>
                  <span>{t('businessManagement.members')}</span>
                </div>
                <span
                  className="cursor-pointer text-primary"
                  onClick={() => setAddGroupUserList([])}
                >
                  {t('businessManagement.Clear')}
                </span>
              </div>
              <div className="flex flex-col pl-3 pr-3 pb-3 gap-3 overflow-y-auto">
                {map(alreadySelect, (item, i) => (
                  <div
                    key={item?.userId + item?.nickname + i}
                    className={cn(
                      'w-full flex items-center justify-between h-10 rounded-md shrink-0'
                    )}
                  >
                    <div className="h-full flex items-center gap-2">
                      <div className="relative">
                        <Avatar className="hidden h-6 w-6 sm:flex flex items-center">
                          <AvatarImage
                            alt="Avatar"
                            src={item?.avatarUrl}
                            className="rounded-full"
                          />
                          <AvatarFallback>
                            <User className="" />
                          </AvatarFallback>
                        </Avatar>
                        <div
                          className={cn(
                            'size-[6px] border-white border rounded-full absolute bottom-0 right-0',
                            statusColor[EmployeeStatus[item?.status || '']] || ''
                          )}
                        />
                      </div>
                      <span>{item?.nickname}</span>
                    </div>
                    <ReactSVG
                      src={close}
                      className="cursor-pointer"
                      onClick={() => setAddGroupUserList([...pull(userList, item?.userId)])}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <AlertDialogFooter className="h-[54px] pl-3 pr-3 flex items-center border-t">
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              onConfirm && onConfirm(userList)
              onClose && onClose()
            }}
          >
            {t('common.verify')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(ChooseTeamUserList)
