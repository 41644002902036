import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * 标签信息
 */
export interface TagInfoResponse {
  /**
   * 标签颜色码
   */
  colorCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 标签id
   */
  tagId?: number;
  /**
   * 标签名
   */
  tagName?: string;
}

interface ApiResp extends ClientResponse {
  data: TagInfoResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 标签详情
 * @param params
 * @returns
 */
export const fetchTagInfo = async (id: number | string): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/tag/${id}`);

  return serialize(data);
};
