import { toast } from 'sonner'
import { X } from 'lucide-react'
import { ReactNode } from 'react'
import { v1 } from 'uuid'

const closeAction = (): { action: ReactNode; id: string | number } => {
  const id = v1()

  return {
    id,
    action: (
      <button
        className="absolute top-0 right-2 h-[24px] w-24[px] flex justify-center items-center "
        onClick={() => toast.dismiss(id)}
      >
        <X width="14" height="14" />
      </button>
    )
  }
}

type MessageType = 'error' | 'success' | 'info'

export const message = (
  message: string,
  type: MessageType = 'info'
): string | number | undefined => {
  if (!message) {
    return
  }
  switch (type) {
    case 'error':
      return toast.error(message, {
        duration: 1500
        // ...closeAction()
      })
    case 'success':
      return toast.success(message, {
        duration: 1500
        // ...closeAction()
      })
    case 'info':
      return toast.info(message, {
        duration: 1500
        // ...closeAction()
      })
    default:
      return toast.info(message, {
        duration: 1500
        // ...closeAction()
      })
  }
}
