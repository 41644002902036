import { cn } from '@brisk/ui'
import { FC, memo } from 'react'

const AvatarSpin: FC<{ loadFinished: boolean; calssName?: string }> = ({
  loadFinished,
  calssName
}) => {
  return (
    <div
      className={cn(
        'w-10 h-10 rounded-full bg-lingheBtnHover',
        loadFinished ? '' : 'border',
        calssName
      )}
    />
  )
}

export default memo(AvatarSpin)
