import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * ChannelWebsiteDetailVO
 */
export interface IScriptDetail {
  /**
   * 渠道编号
   */
  channelId?: number
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string
  /**
   * 创建时间
   */
  createTime?: Date
  /**
   * 是否启用 0-否 1-是
   */
  enable?: number
  /**
   * 渠道的icon图片地址
   */
  iconUrl?: string
  /**
   * 语言 zh-CN en-US
   */
  language: string
  /**
   * 渠道名称
   */
  name?: string
  /**
   * 位置 BOTTOM-LEFT,BOTTOM-RIGHT
   */
  position: string
  /**
   * 备注
   */
  remark: string
  /**
   * 租户编号
   */
  tenantId?: number
  /**
   * 唯一token
   */
  token: string
}

interface ApiResp extends ClientResponse {
  data: IScriptDetail
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 聊天插件-渠道详情
 * @param channelId
 * @returns
 */
export const fetchScriptDetail = async (channelId: string): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/channel/website/${channelId}`)

  return serialize(data)
}
