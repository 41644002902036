import {
  ChannelWebsiteVO,
  fetchAddOrEditScript,
  fetchChannelConfigList,
  fetchChannelEnable,
  fetchDelChannelConfigs,
  fetchScriptDetail,
  IChannelConfigList,
  IScriptDetail
} from '@renderer/api'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { ReactSVG } from 'react-svg'
import { useMutation } from '@tanstack/react-query'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { IPageationRef } from '../AddCustomer'
import { ColumnDef } from '@tanstack/react-table'
import {
  Button,
  // Checkbox,
  Switch
} from '@brisk/ui'
import { Plus } from 'lucide-react'
import dayjs from 'dayjs'
import { message } from '@renderer/components/message'
import AddOrEditScript from './AddOrEditScript'
// import { find, map } from 'lodash'
// import { LANGUAGES } from '@renderer/utils/consts'
// import DelCustolerModal from '../Components/DelCustolerModal'
import CreateScriptToWeb from './CreateScriptToWeb'
import { copyTextToClipboard } from '@renderer/utils'
import { ManagerTable, ManagerTableRef } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'

const ChatScript: FC = () => {
  const [channelConfig, setChannelConfig] = useState<IChannelConfigList>({})
  const [rowSelect, setRowSelect] = useState<ChannelWebsiteVO[]>([])
  const { t } = useTranslation()
  const [showToWebData, setShowToWebData] = useState<{
    open: boolean
    data: IScriptDetail | null
  }>({
    open: false,
    data: null
  })
  const [addOrEditData, setAddOrEditData] = useState<{
    title?: string
    open: boolean
    data: IScriptDetail | null
  }>({
    title: '',
    open: false,
    data: null
  })
  // const [delData, setDelData] = useState<{
  //   open: boolean
  //   data: ChannelWebsiteVO[] | null
  // }>({
  //   open: false,
  //   data: null
  // })

  const tableRef = useRef<ManagerTableRef>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getChannelConfig } = useMutation({
    mutationFn: fetchChannelConfigList
  })

  const { mutateAsync: changeEnable } = useMutation({
    mutationFn: fetchChannelEnable
  })

  const { mutateAsync: addOrEditScript } = useMutation({
    mutationFn: fetchAddOrEditScript
  })

  const { mutateAsync: getScriptDetail } = useMutation({
    mutationFn: fetchScriptDetail
  })

  const { mutateAsync: delArrScript } = useMutation({
    mutationFn: fetchDelChannelConfigs
  })

  const handleGetChannelConfig = async (param): Promise<void> => {
    try {
      const res = await getChannelConfig(param)
      if (res?.code === RESPONSE_CODE.success) {
        setChannelConfig(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const columns: ColumnDef<ChannelWebsiteVO>[] = useMemo(
    () => [
      {
        id: 'name',
        header: ({ table }) => (
          <div className="flex items-center gap-3">
            {/* <Checkbox
              id="Select all"
              checked={table.getIsAllPageRowsSelected()}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            /> */}
            <label htmlFor="Select all">{t('socialMediaAccount.Name')}</label>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-3 h-full max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">
            {/* <Checkbox
              id={row?.original?.channelId + ''}
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            /> */}
            <label htmlFor={row?.original?.channelId + ''}>{row?.original?.name}</label>
          </div>
        )
      },
      // {
      //   id: 'language',
      //   header: () => <div>语言</div>,
      //   cell: ({ row: { original } }) => (
      //     <div>{find(LANGUAGES, (it) => it.code === original?.language)?.name}</div>
      //   )
      // },
      {
        id: 'remark',
        header: () => <div>{t('socialMediaAccount.Notes')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[300px] line-clamp-2 break-words overflow-hidden text-ellipsis">
            {original?.remark}
          </div>
        )
      },
      {
        id: 'createTime',
        header: () => <div>{t('socialMediaAccount.CreationTime')}</div>,
        cell: ({ row: { original } }) => {
          return <div>{dayjs(original?.createTime).format('YY-MM-DD HH:mm:ss')}</div>
        }
      },
      {
        id: 'edit',
        style: {
          width: 318
        },
        header: () => (
          <div className="flex items-center justify-between">
            <span>{t('socialMediaAccount.Enable')}</span>
            <span>{t('socialMediaAccount.Actions')}</span>
          </div>
        ),
        cell: ({ row: { original } }) => (
          <div className=" flex items-center justify-between">
            <Switch
              checked={Boolean(original?.enable)}
              onCheckedChange={async (e) => {
                try {
                  const res = await changeEnable({
                    channelId: original?.channelId,
                    enable: Number(e)
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    message(
                      `${e ? t('socialMediaAccount.Done') : t('socialMediaAccount.disableDone')}`,
                      'success'
                    )
                    handleGetChannelConfig({
                      pageNum: pageationRef?.current?.currentPage || 1,
                      pageSize: pageationRef?.current?.itemsPerPage || 10
                    })
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            />
            <div className="flex items-center gap-2">
              <Button
                variant="link"
                className="hover:no-underline p-0"
                onClick={async () => {
                  try {
                    const res = await getScriptDetail(original?.channelId + '')
                    if (res?.code === RESPONSE_CODE.success) {
                      const url = `${import.meta.env.VITE_ADMIN_URL}/previewchat?token=${res?.data?.token}&hide=1`
                      window.open(url, '__blank')
                    }
                  } catch (error) {
                    console.log('error', error)
                  }
                }}
              >
                {t('socialMediaAccount.Preview')}
              </Button>
              <span>|</span>
              <Button
                variant="link"
                className="hover:no-underline p-0"
                onClick={async () => {
                  try {
                    const res = await getScriptDetail(original?.channelId + '')
                    if (res?.code === RESPONSE_CODE.success) {
                      setAddOrEditData({
                        title: t('socialMediaAccount.Edit'),
                        open: true,
                        data: res?.data
                      })
                    }
                  } catch (error) {
                    console.log('error', error)
                  }
                }}
              >
                {t('socialMediaAccount.Edit')}
              </Button>
              <span>|</span>
              <Button
                variant="link"
                className="hover:no-underline p-0"
                onClick={async () => {
                  try {
                    const res = await getScriptDetail(original?.channelId + '')
                    if (res?.code === RESPONSE_CODE.success) {
                      setShowToWebData({
                        open: true,
                        data: res?.data
                      })
                    }
                  } catch (error) {
                    console.log('error', error)
                  }
                }}
              >
                {t('socialMediaAccount.Install')}
              </Button>
            </div>
          </div>
        ),
        fixed: 'right'
      }
    ],
    []
  )

  useEffect(() => {
    handleGetChannelConfig({})
  }, [])

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      <div className="h-16 shrink-0 flex items-center justify-between pl-3 pr-3 [&_button]:h-8">
        <div className="flex items-center h-full gap-3">
          <Button
            onClick={() =>
              setAddOrEditData({ title: t('socialMediaAccount.Create'), open: true, data: null })
            }
          >
            <Plus size={16} />
            {t('socialMediaAccount.Create')}
          </Button>
          {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
            如何创建插件？
          </Button> */}
        </div>
        {/* {channelConfig?.records?.length ? (
          <Button
            disabled={!rowSelect?.length}
            variant="destructive"
            onClick={() => setDelData({ open: true, data: rowSelect })}
          >
            删除
          </Button>
        ) : (
          false
        )} */}
      </div>
      <div className="flex-1 w-full overflow-hidden shrink-0">
        <ManagerTable<ChannelWebsiteVO>
          ref={tableRef}
          pageationRef={pageationRef}
          data={channelConfig?.records || []}
          columns={columns}
          rowCount={channelConfig?.total || 0}
          onPageChange={(pageNum, pageSize) => handleGetChannelConfig({ pageNum, pageSize })}
          onRowSelectionChange={(rows) => {
            setRowSelect(rows)
          }}
          emptyElement={() => (
            <div className="flex items-center justify-center pt-20">
              <div className="flex flex-col items-center justify-center gap-2">
                <ReactSVG src={noSearchResult} />
                <span className="mb-2 text-sm text-lingheTextGray">
                  {t('socialMediaAccount.Pleasecreatewebsitechatplugin')}
                </span>
                <Button
                  onClick={() =>
                    setAddOrEditData({
                      title: t('socialMediaAccount.Create'),
                      open: true,
                      data: null
                    })
                  }
                >
                  <Plus size={16} />
                  {t('socialMediaAccount.Create')}
                </Button>
                {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
                  如何创建插件？
                </Button> */}
              </div>
            </div>
          )}
        />
      </div>

      <AddOrEditScript
        open={addOrEditData?.open}
        data={addOrEditData?.data}
        title={addOrEditData?.title}
        onClose={() => setAddOrEditData({ open: false, data: null })}
        onConfirm={async (param, type: string) => {
          try {
            const res = await addOrEditScript(param)
            if (res?.code === RESPONSE_CODE.success) {
              message(`${type}${t('common.success')}`, 'success')
              handleGetChannelConfig({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setAddOrEditData({ open: false, data: null })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      {/*<DelCustolerModal*/}
      {/*  title={t('common.Operation')}*/}
      {/*  open={delData.open}*/}
      {/*  onClose={() => {*/}
      {/*    setDelData({*/}
      {/*      open: false,*/}
      {/*      data: null*/}
      {/*    })*/}
      {/*  }}*/}
      {/*  onConfirm={async () => {*/}
      {/*    try {*/}
      {/*      const res = await delArrScript(map(delData?.data, (it) => it?.channelId))*/}
      {/*      if (res?.code === RESPONSE_CODE.success) {*/}
      {/*        message(t('businessManagement.Deletedsuccessfully'), 'success')*/}
      {/*        setDelData({*/}
      {/*          open: false,*/}
      {/*          data: null*/}
      {/*        })*/}
      {/*        handleGetChannelConfig({*/}
      {/*          pageNum: pageationRef?.current?.currentPage || 1,*/}
      {/*          pageSize: pageationRef?.current?.itemsPerPage || 10*/}
      {/*        })*/}
      {/*        tableRef?.current?.resetRowSelection()*/}
      {/*      }*/}
      {/*    } catch (error) {*/}
      {/*      console.log('error', error)*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  content={*/}
      {/*    <div className="text-lingheTextGray text-sm text-center">*/}
      {/*      删除后，您的网站将无法发起在线咨询*/}
      {/*      <br />*/}
      {/*      建议您可以*/}
      {/*      <Button*/}
      {/*        variant="link"*/}
      {/*        className="hover:no-underline p-0"*/}
      {/*        onClick={async () => {*/}
      {/*          try {*/}
      {/*            const res = await changeEnable({*/}
      {/*              channelId: delData?.data[0]?.channelId,*/}
      {/*              enable: 0*/}
      {/*            })*/}
      {/*            if (res?.code === RESPONSE_CODE.success) {*/}
      {/*              message(`禁用成功`, 'success')*/}
      {/*              handleGetChannelConfig({*/}
      {/*                pageNum: pageationRef?.current?.currentPage || 1,*/}
      {/*                pageSize: pageationRef?.current?.itemsPerPage || 10*/}
      {/*              })*/}
      {/*            }*/}
      {/*          } catch (error) {*/}
      {/*            console.log('error', error)*/}
      {/*          }*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        ”停用“*/}
      {/*      </Button>*/}
      {/*      此聊天插件*/}
      {/*      <br />*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}

      <CreateScriptToWeb
        open={showToWebData.open}
        data={showToWebData?.data}
        onClose={() =>
          setShowToWebData({
            open: false,
            data: null
          })
        }
        onConfirm={async (script) => {
          await copyTextToClipboard(script)
        }}
      />
    </div>
  )
}

export default memo(ChatScript)
