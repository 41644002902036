/**
 * LabelLayout
 *
 * @desc 描述：有 Label 的表单布局
 */

/* =================================================== */

import type { FC, PropsWithChildren } from 'react'
import { memo } from 'react'
import { cn } from '@brisk/ui'

export interface ILabelLayoutProps {
  className?: string
  labelText: string
  labelClass?: string
  disabled?: boolean
}

/**
 * 有 Label 的表单布局
 * @desc 业务组件。封装度很低，不要通用
 */
const LabelLayout: FC<PropsWithChildren<ILabelLayoutProps>> = ({
  className,
  labelText,
  labelClass,
  disabled,
  children
}) => {
  return (
    <div className={cn('flex items-center gap-6', className)}>
      <label className={cn('text-sm', labelClass, { 'opacity-30': disabled })}>{labelText}</label>

      {children}
    </div>
  )
}

export default memo(LabelLayout)
