import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * 标签搜索
 */
export interface ITagsPageReq {
  pageNum?: number
  pageSize?: number
  /**
   * 标签名
   */
  tagName?: string
}

/**
 * PageTagPageVO
 */
export interface ITagsPageRes {
  countId?: string
  current?: number
  maxLimit?: number
  pages?: number
  records?: ITagPageVO[]
  searchCount?: Response
  size?: number
  total?: number
}

/**
 * 标签page
 */
export interface ITagPageVO {
  id: string | number
  /**
   * 标签颜色码
   */
  colorCode?: string
  /**
   * 已关联数量
   */
  joinNum?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 标签id
   */
  tagId?: number
  /**
   * 标签name
   */
  tagName?: string
}

interface ApiResp extends ClientResponse {
  data: ITagsPageRes
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 分页获取标签
 * @param params
 * @returns
 */
export const fetchTagListPage = async (params: ITagsPageReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/tag/page`, params)

  return serialize(data)
}
