import { FC } from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './lib/sdk/juggle'
import './react-i18next/i18n'
import UpdateDialog from '@renderer/components/UpdateDialog'
import { useCheckAppUp } from './hooks/appUpdate/useCheckAppUp'
import { isElectron } from '@renderer/utils'

console.log(import.meta.env)
console.log(import.meta.env.VITE_API_URL)
const queryClient = new QueryClient()
const App: FC = () => {
  // const ipcHandle = (): void => window.electron.ipcRenderer.send('ping')
  const updateDialogProps = useCheckAppUp()

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />

      {/* qpp 更新弹窗 */}
      {isElectron && <UpdateDialog {...updateDialogProps} />}
    </QueryClientProvider>
  )
}

export default App
