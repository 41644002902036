import { string } from 'zod'
import i18next from 'i18next'

export const schemaPassword = {
  password: string()
    .min(8, i18next.t('common.passwordcontainscharacters'))
    .max(16, i18next.t('common.passwordcontainscharacters')),
  confirmPassword: string()
    .min(8, i18next.t('common.passwordcontainscharacters'))
    .max(16, i18next.t('common.passwordcontainscharacters'))
}
