import { create } from 'zustand'

type State = {
  routePath: string[]
}

type Action = {
  setRoutePath: (route: string[]) => void
}

export const useRoutePathStore = create<State & Action>((set) => ({
  routePath: [],
  setRoutePath: (route: string[]): void => set({ routePath: route })
}))
