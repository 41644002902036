import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { CustomerTagVO } from "./fetchCustomerList";

/**
 * 客户详情
 */
export interface CustomerDetailResponse {
  /**
   * 地址
   */
  address?: string;
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 公司名称
   */
  company?: string;
  /**
   * 客户id
   */
  customerId?: string;
  /**
   * 标签信息
   */
  customerTag?: CustomerTagVO[];
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 用户姓名
   */
  name?: string;
  /**
   * 手机号
   */
  phone?: string;
  areaCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 来源：0-webSite，1-Telegram
   */
  source?: number;
}

interface ApiResp extends ClientResponse {
  data: CustomerDetailResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 客服详情
 * @param id
 * @returns
 */
export const fetchCustomerDetail = async (id: number): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/customer/${id}`);

  return serialize(data);
};
