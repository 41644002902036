import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {
  data: string[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 翻译引擎列表
 * @param engine
 * @returns
 */
export const fetchLanguageEngine = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/translate/config/engine`);

  return serialize(data);
};
