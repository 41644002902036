import { ClientResponse, httpClient } from '../../http-client/httpClient'
/**
 * UserTeamDetailVO
 */
export interface ITeamListResponse {
  /**
   * 团队编号
   */
  teamId?: number | string
  /**
   * 团队名称
   */
  teamName?: string

}

interface ApiResp extends ClientResponse {
  // TODO:
  data: ITeamListResponse[]
}
interface Serialize {
  (resp: ApiResp): ApiResp
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data
  return resp
}

// 获取团队结构
export const fetchTeamList = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/user/team/list`)

  return serialize(data)
}
