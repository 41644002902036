import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { AccountType } from '../model'

interface RequestParams {
    // 账号,可以是用户名，邮箱，手机号
    phone: string
    // 滑块验证参数
    captchaId?: string
    captchaTrack?: object
    }

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
    return resp;
};
// 校验滑块验证码
export const fetchLoginCaptchaCheck = async ({phone, captchaId, captchaTrack}: RequestParams): Promise<ApiResp> => {
    const { data } = await apiClient.post<ApiResp>(`/login/captcha/check`, {phone, captchaId, captchaTrack})
    return serialize(data)
}
