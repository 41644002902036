import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术 删除
 * @returns
 */
export const fetchDelTeamWords = async (
  teamPhrasingId: string,
): Promise<ApiResp> => {
  const { data } = await apiClient.delete<ApiResp>(
    `/bg/kb/team/phrasing/${teamPhrasingId}`,
  );

  return serialize(data);
};
