import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerPageListRequest } from "./fetchDataCustomerPageList";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * ChartChannelNumVO
 */
export interface IDataChannelBar {
  /**
   * 类型
   */
  channelType?: string;
  /**
   * 数量
   */
  num?: number;
  dateTimes?: number;
}

interface ApiResp extends ClientResponse {
  data: IDataChannelBar
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 每日渠道访客量柱状图
 * @param param
 * @returns
 */
export const fetchDataChannelBar = async (
  param: IDataCustomerPageListRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/channel/statistics/chart/bar`,
    param,
  );

  return serialize(data);
};
