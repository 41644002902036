import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  cn
} from '@brisk/ui'
import {
  //  ChevronDown,
  X
} from 'lucide-react'
import {
  ClipboardEvent as ClipboardEventType,
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
// import voice from '@renderer/assets/svg/voice.svg'
import fontBook from '@renderer/assets/svg/fontBook.svg'
import fastWordsHoverIcon from '@renderer/assets/svg/fastWordsHoverIcon.svg'
import shadownTalk from '@renderer/assets/svg/shadownTalk.svg'
import shadowTalkHover from '@renderer/assets/svg/shadowTalkHover.svg'
import send from '@renderer/assets/svg/send.svg'
import { ReactSVG } from 'react-svg'
import EmojiPopover from './EmojiPopover'
import { sendMsgParams, setDraftContent } from '@renderer/utils/juggleHandles'
import ImagePicker from '@renderer/components/ImagePicker'
import { convertHEICtoPNG, getNodeTextContent, onJudgeImageIsHeic } from '@renderer/utils'
import { emojiToIcon, replaceEmojiMark } from '@renderer/utils/emojiData'
import { ChatListStatus, EmployeeStatus } from '@renderer/api/model'
import { useUploadFile } from '@renderer/hooks/useUploadFile'
import { useMutation } from '@tanstack/react-query'
import { fetchTranslateIn, IChatList, RESPONSE_CODE } from '@brisk/api'
import { MessageInfoContext } from '../Home'
import { debounce, filter, find, includes } from 'lodash'
import { ChattingWindowContext, defaltFastModalData, whiteSpaceStyle } from './ChattingWindow'
import { useTranslation } from 'react-i18next'
import { sendMsg, sendTranslateMsg } from '@renderer/utils/im-sdk/ipcTimSdk'
import { TIMConvType } from 'im_electron_sdk/dist/enumbers'
import ChooseFileChat from './components/ChooseFileChat'
import { MAX_INP_LENGTH, maxImageSize } from '@renderer/utils/consts'
import { message as messageTips } from '@renderer/components/message'
import TranslateErrorModal, { ITranslateErrorModal } from './components/TranslateErrorModal'
import { MESSAGE_TYPE, SEND_ROLE } from '@brisk/utils'
import TooltipComp from './components/TooltipComp'

export const typeOfMessage = (data: IChatList): string => {
  let content
  switch (data?.name) {
    case MESSAGE_TYPE.IMAGE:
      content = '[图片]'
      break
    case MESSAGE_TYPE.FILE:
      content = '[文件]'
      break
    case MESSAGE_TYPE.VIDEO:
      content = '[视频]'
      break
    case MESSAGE_TYPE.AUDIO:
      content = '[音频]'
      break
    case MESSAGE_TYPE.CSNOTICE:
    case MESSAGE_TYPE.CSWELCOM:
    case MESSAGE_TYPE.CSROBOT:
    case MESSAGE_TYPE.CSSESSION:
      content = '[系统消息]'
      break

    default:
      content =
        data?.referMsg?.content?.content ||
        data?.referMsg?.content?.notice?.content ||
        data?.content?.content ||
        ''
      break
  }
  return content
}

export interface ChatTextArealRef {
  arealRef: React.MutableRefObject<HTMLPreElement | null>
  getRichTextValue: () => { content: string; imgSrc: string }
  getInnerHTML: () => string
  triggerPaste: (text: string) => void
  rangeInsert: (text: string, type?: string) => void
  setMessage: (val: string) => void
  message: string
}
interface TypeProps {
  changeShowKnowledgeBase: () => void
  inpEnd: boolean
}
const ChatTextAreal = forwardRef(
  ({ changeShowKnowledgeBase, inpEnd }: TypeProps, ref: Ref<ChatTextArealRef>) => {
    const arealRef = useRef<HTMLPreElement>(null)
    const contentHtml = useRef('')

    const [selection, setSelection] = useState<Selection | null>(null)
    const [lastRange, setLastRange] = useState<Range | null | undefined>(null)
    const [line, setLine] = useState(1)
    const [message, setMessage] = useState('')
    const [translateErrorData, setTranslateErrorData] = useState<ITranslateErrorModal>({
      open: false,
      type: 2,
      data: null
    })

    const {
      itemData,
      currentTab,
      handleTranslateIn,
      reCallMessage,
      delReCallMessage,
      chatItemActive: active,
      userDetailData,
      user,
      TALKSTATUS,
      setLoading,
      isManager,
      searchResult,
      setSearchResult
    } = useContext(MessageInfoContext)

    const {
      setFastWordsModalData,
      fastWordsModalData,
      handleRestartChat,
      handleShutdownChat,
      handleTranslateInp,
      shouldTransLate,
      translateOnTimeData,
      setTranslateOnTimeData
    } = useContext(ChattingWindowContext)

    const { t } = useTranslation()

    const hasReCallMsg = useMemo(() => {
      const data = find(reCallMessage, (item) => item?.conversationId === active)
      const content = typeOfMessage(data as IChatList)
      return {
        data,
        content
      }
    }, [active, reCallMessage])

    useImperativeHandle(ref, () => ({
      arealRef,
      getRichTextValue,
      getInnerHTML,
      triggerPaste,
      rangeInsert,
      message,
      setMessage
    }))

    const { mutateAsync: translateIn } = useMutation({
      mutationFn: fetchTranslateIn
    })

    const triggerPaste = (text): void => {
      const pasteEvent = new ClipboardEvent('paste', {
        bubbles: true,
        cancelable: true,
        clipboardData: new DataTransfer()
      })

      pasteEvent.clipboardData.setData('text', text)
      arealRef.current.dispatchEvent(pasteEvent)
    }
    /**
     * 上传文件
     */
    const { handleUploadFile } = useUploadFile()

    // 插入内容到当前位置
    const rangeInsert = useCallback((content: string, type = 'content') => {
      if (!arealRef.current) return

      // 获取当前选中的范围
      const selection = window.getSelection()
      if (!selection || !selection.getRangeAt || selection.rangeCount <= 0) return

      // 当前选区
      const range = selection.getRangeAt(0)
      if (!isEditorArea(range)) return

      range.deleteContents()

      // 创建一个新的DocumentFragment，并使用innerHTML将HTML字符串转换为节点
      const fragment = range.createContextualFragment(content)
      // 插入DocumentFragment到选中的范围
      range.insertNode(fragment)

      // 移动光标到插入内容的末尾
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)

      // 触发输入区域 onInput
      if (type !== 'member') {
        arealRef.current?.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true
          })
        )
      } else {
        isOutOfLength()
      }
    }, [])

    // 设置输入框 InnerHTML
    const setInnerHTML = useCallback(
      (content: string) => {
        if (!arealRef.current) return

        arealRef.current.innerHTML = ''
        rangeInsert(content)
      },
      [rangeInsert]
    )

    // 记录内容变化，超出长度不允许输入
    const isOutOfLength = (): void => {
      if (!arealRef.current) return

      const contentVal = getNodeTextContent(arealRef.current)
        ?.content?.replace(/^\n/, '')
        .replace(/\n$/, '')

      if (contentVal) {
        if (contentVal.length > 2000) {
          setInnerHTML(contentHtml.current)
        } else {
          contentHtml.current = getInnerHTML()
        }
      }
    }

    // 插入内容方法
    const insertContent = useCallback(
      (content: string, type: string) => {
        if (!arealRef.current) return

        arealRef.current.scrollIntoView({ block: 'end', inline: 'nearest' })
        if (lastRange) {
          selection?.removeAllRanges()
          selection?.addRange(lastRange)
        }
        const range = selection?.getRangeAt(0)
        setLastRange(range)
        rangeInsert(content, type)
      },
      [rangeInsert]
    )

    // 插入表情
    const insertEmoji = useCallback(
      (key: string) => {
        insertContent(
          `<img style="vertical-align: middle; width: 24px; height: 24px; display: inline;" data-type='emoji' src="${emojiToIcon(key)}" alt="${key}" />`,
          'emoji'
        )
      },
      [insertContent]
    )

    // 获取输入框 InnerHTML
    const getInnerHTML = (): string => {
      return arealRef.current?.innerHTML || ''
    }

    // 是否输入区域
    const isEditorArea = (range: Range): boolean => {
      const startNode = range.startContainer
      if (startNode instanceof Text) {
        // 若选区起始位置为文本节点，则需要进一步判断其父节点类型
        return startNode.parentNode === arealRef.current
      } else {
        // 若选区起始位置不是文本节点（比如是元素节点），直接输出其父节点
        return startNode === arealRef.current
      }
    }

    // 获取当前富文本框纯文本内容
    const getRichTextValue = useCallback(() => {
      if (!arealRef.current)
        return {
          content: '',
          imgSrc: ''
        }
      return getNodeTextContent(arealRef.current)
    }, [getNodeTextContent])

    // 触发粘贴
    const handlePaste = async (event: ClipboardEventType<HTMLPreElement>): Promise<void> => {
      event.preventDefault()
      event.stopPropagation()

      const clipboard = event.clipboardData

      // 检查并处理 HEIC 图片
      if (clipboard.files.length > 0 && onJudgeImageIsHeic(clipboard.files[0])) {
        const pastedFile = await convertHEICtoPNG(clipboard.files[0])
        console.log('first', pastedFile)
        return
      }

      const clipboardItems = Array.from(clipboard.items)
      for (const item of clipboardItems) {
        if (!item) return

        // 处理文本粘贴
        if (item.type.match('text')) {
          const text = await navigator.clipboard.readText()

          if (typeof text === 'string') {
            let finalText = replaceEmojiMark(text)

            // 如果内容超出500字符，则截断
            if (finalText.length > MAX_INP_LENGTH) {
              finalText = finalText.slice(0, MAX_INP_LENGTH)
            }

            // 插入到光标位置
            rangeInsert(finalText)
            return
          }
        }

        // 处理图片粘贴
        if (item.type.match('image')) {
          event.preventDefault()
          const pastedFile = item.getAsFile()
          handleUploadFile(pastedFile, active, MESSAGE_TYPE.GENERIC_IMAGE)
        }
      }
    }

    const getLastRange = (e): void => {
      // 定义最后光标位置
      setSelection(window?.getSelection())
      const content = (e.target as HTMLElement).innerHTML
      shouldTransLate && handleTranslateInp(getRichTextValue().content)
      setMessage(content)
      if (selection && selection.rangeCount > 0) {
        setLastRange(selection?.getRangeAt(0))
      } else {
        setLastRange(null)
      }
      if (hasReCallMsg?.data) setLine(getInputLine())
    }

    const handleMatchContent = useCallback(() => {
      const range = selection?.getRangeAt(0)
      const rect = range?.getBoundingClientRect()
      const cursorX = rect?.left // 光标的 X 位置
      const cursorY = rect?.top - 300
      const screenWidth = window.innerWidth
      const threshold = screenWidth * 0.7
      if (cursorX > threshold) {
        setFastWordsModalData({
          visible: true,
          position: {
            left: cursorX - 450,
            top: cursorY
          }
        })
      } else {
        setFastWordsModalData({
          visible: true,
          position: {
            left: cursorX + 100,
            top: cursorY
          }
        })
      }
    }, [selection])

    /** 获取光标处于第几行 */
    const getInputLine = useCallback(() => {
      const range = selection?.getRangeAt(0)
      const rect = range?.getBoundingClientRect()
      const lineHeight = parseInt(window.getComputedStyle(arealRef?.current)?.lineHeight)
      const cursorTop = rect?.top - arealRef?.current?.getBoundingClientRect()?.top
      const cursorLineNumber = Math.floor(cursorTop / lineHeight) + 1
      return cursorLineNumber <= 0 ? 1 : cursorLineNumber
    }, [hasReCallMsg?.data, selection, arealRef])

    const handleSend = debounce(() => {
      const { content, imgSrc } = getRichTextValue()

      if (!content || content.trim() === '') return

      const sendParams = {
        conversationType: TIMConvType.kTIMConv_Group,
        conversationId: active,
        name: MESSAGE_TYPE.TEXT,
        content: {
          content,
          senderRole: SEND_ROLE.USER
        },
        sender: {
          portrait: user?.avatarUrl,
          id: user?.userId
        },
        referMsg: hasReCallMsg?.data
          ? { ...hasReCallMsg?.data, conversationTitle: itemData?.conversationTitle }
          : undefined
      }

      // 清空消息输入框内容
      const clearMessageInput = () => {
        if (searchResult.value) {
          const inputElement = document.querySelector(
            '#searchInputRefTargetDom'
          ) as HTMLInputElement
          if (inputElement) inputElement.value = ''
          setSearchResult({ resultList: [], value: '' })
        }
        setMessage('')
        if (arealRef?.current) arealRef.current.innerHTML = ''
      }

      // 处理翻译逻辑
      const handleTranslation = () => {
        if (translateOnTimeData.state === 1) {
          // 翻译中
          setTranslateErrorData({
            open: true,
            type: 1,
            data: sendMsgParams(sendParams)
          })
          return false
        }
        if (translateOnTimeData.state === 3) {
          // 翻译失败
          setTranslateErrorData({
            open: true,
            type: 2,
            data: sendMsgParams(sendParams)
          })
          return false
        }
        if (isManager) customerLiveToConv()
        if (translateOnTimeData.state === 2 && translateOnTimeData.data) {
          sendTranslateMsg(
            sendMsgParams({
              ...sendParams,
              content: {
                ...sendParams.content,
                sendContent: translateOnTimeData.data,
                senderRole: SEND_ROLE.USER,
                translateRes: translateOnTimeData.data
              },
              translateState: 2
            }),
            translateOnTimeData.data
          )
          clearMessageInput()
        }
        setTranslateOnTimeData({ state: 2, data: null })
        return true
      }

      // 发送消息函数
      const sendMessage = (params, type = 'text') => {
        if (type === 'image') {
          sendMsg(
            sendMsgParams({
              ...params,
              name: MESSAGE_TYPE.IMAGE,
              content: { url: imgSrc, senderRole: SEND_ROLE.USER }
            }),
            'image'
          )
        } else {
          sendMsg(sendMsgParams(params))
        }
      }

      // 主发送逻辑
      if (shouldTransLate) {
        if (!handleTranslation()) return
      } else {
        if (isManager) customerLiveToConv()
        clearMessageInput()
        sendMessage(sendParams)
      }

      // 发送图片消息（如果有图片）
      if (imgSrc) {
        sendMessage(sendParams, 'image')
      }
    }, 100)

    /** 管理员激活会话 */
    const customerLiveToConv = async () => {
      if (user?.status === EmployeeStatus.OFFLINE) return
      if (currentTab === ChatListStatus.FINISHED) {
        if (TALKSTATUS.isEnd) await handleRestartChat(itemData?.sessionId) // 所有的-已结束
        if (TALKSTATUS.isWaiting) await handleTransLateInConv() // 所有的-未分配
      }

      if (currentTab === ChatListStatus.UNASSIGNED) await handleTransLateInConv()
    }

    const handleTransLateInConv = async () => {
      const { sessionId } = itemData || {}

      if (!sessionId || !active) {
        return
      }
      setLoading(true)

      try {
        const res = await translateIn({ sessionId })
        if (res?.code === RESPONSE_CODE.success) {
          handleTranslateIn(active + '')
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error translating in:', error)
      }
    }

    const handleDeleteRecall = useCallback(() => {
      const newList = filter(
        reCallMessage,
        (item) => item?.conversationId !== hasReCallMsg?.data?.conversationId
      )

      delReCallMessage(newList)
    }, [reCallMessage, hasReCallMsg])

    useEffect(() => {
      if (includes(getRichTextValue()?.content, '/')) handleMatchContent()
      else setFastWordsModalData(defaltFastModalData)
    }, [selection, message])

    useEffect(() => {
      const t = arealRef?.current
      if (t) {
        const draft = setDraftContent(active)
        if (draft) {
          setMessage(draft)
          t.innerHTML = replaceEmojiMark(draft)
        } else {
          setMessage('')
          t.innerHTML = ''
        }
      }
    }, [active])

    useEffect(() => {
      if (hasReCallMsg?.data) arealRef?.current?.focus()
    }, [hasReCallMsg?.data])

    return (
      <div className="flex flex-col w-full h-full relative text-sm">
        {!isManager && currentTab === ChatListStatus.FINISHED && TALKSTATUS.isEnd && (
          <div
            className="w-full z-10 h-full absolute left-0 top-0 text-sm"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)'
            }}
          >
            {TALKSTATUS.isEnd && (
              <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
                <p>{t('chatFrame.chatshutdown')}</p>
                <Button onClick={() => handleRestartChat(itemData?.sessionId)}>
                  {t('chatFrame.openchat')}
                </Button>
              </div>
            )}
          </div>
        )}
        {!isManager &&
          currentTab === ChatListStatus.FINISHED &&
          !TALKSTATUS.isEnd &&
          !TALKSTATUS.isSelf &&
          !TALKSTATUS.isWaiting && (
            <div
              className="w-full z-10 h-full absolute left-0 top-0 text-sm"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.4)'
              }}
            >
              <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
                <p>{t('chatFrame.alreadychat')}</p>
              </div>
            </div>
          )}
        {((currentTab === ChatListStatus.UNASSIGNED && !isManager) ||
          (currentTab === ChatListStatus.FINISHED &&
            !isManager &&
            !TALKSTATUS.isEnd &&
            TALKSTATUS.isWaiting)) && (
          <div
            className="w-full z-10 h-full absolute left-0 top-0 text-sm"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.8)'
            }}
          >
            <div className="w-full h-full flex items-center justify-center">
              <Button className="w-[120px] h-[46px]" onClick={handleTransLateInConv}>
                {t('chatFrame.translatein')}
              </Button>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between bg-white pl-3 pr-3 border-t h-11 relative shrink-0">
          <div className="flex items-center gap-2 cursor-pointer">
            {/* temporarily delete */}
            {/* <ReactSVG src={fontB} /> */}
            {/* <ReactSVG src={fontI} />
            <ReactSVG src={fontBack} /> */}

            <TooltipComp content={t('common.emoji')}>
              <div onClick={() => arealRef?.current?.focus()}>
                <EmojiPopover
                  onClick={(emoji) => {
                    if ((emoji + getRichTextValue().content).length > MAX_INP_LENGTH) return
                    console.log('emoji', emoji)
                    insertEmoji(emoji)
                  }}
                />
              </div>
            </TooltipComp>

            <TooltipComp content={t('knowledgeBase.Picture')}>
              <div>
                <ImagePicker
                  onPick={async (file) => {
                    if (file) {
                      console.log('file>---->>', file, file.size)
                      if (file.size > maxImageSize) {
                        messageTips(t('knowledgeBase.uploadImgLimit'), 'info')
                        return
                      }
                      if (isManager) customerLiveToConv()
                      handleUploadFile(file, active, MESSAGE_TYPE.GENERIC_IMAGE)
                    }
                  }}
                />
              </div>
            </TooltipComp>

            <TooltipComp content={t('common.File')}>
              <div>
                <ChooseFileChat
                  params={sendMsgParams({
                    conversationType: TIMConvType.kTIMConv_Group,
                    conversationId: active,
                    name: MESSAGE_TYPE.GENERIC_FILE,
                    sender: {
                      portrait: user?.avatarUrl,
                      id: user?.userId
                    },
                    referMsg: hasReCallMsg?.data
                      ? { ...hasReCallMsg?.data, conversationTitle: itemData?.conversationTitle }
                      : undefined
                  })}
                  handleSendImage={(file) => {
                    if (isManager) customerLiveToConv()
                    handleUploadFile(file, active, MESSAGE_TYPE.GENERIC_IMAGE)
                  }}
                />
              </div>
            </TooltipComp>

            {/* <ReactSVG src={voice} /> */}

            <TooltipComp content={t('common.FastWords')}>
              <div className="flex w-8 h-8 items-center justify-center rounded-md hover:bg-lingheBtnHover group">
                <ReactSVG
                  src={fastWordsHoverIcon}
                  onClick={changeShowKnowledgeBase}
                  className="hidden group-hover:block"
                />
                <ReactSVG src={fontBook} className="group-hover:hidden" />
              </div>
            </TooltipComp>
          </div>
          <div className="cursor-pointer">
            {/* 管理员-未分配栏 所有的-已结束 不显示关闭按钮 */}
            {(currentTab === ChatListStatus.UNASSIGNED && isManager) ||
            (currentTab === ChatListStatus.FINISHED &&
              isManager &&
              (TALKSTATUS.isEnd || TALKSTATUS.isWaiting)) ? (
              false
            ) : (
              <TooltipComp content="结束会话">
                <div>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <div className="flex w-8 h-8 items-center justify-center rounded-md hover:bg-lingheBtnHover group">
                        <ReactSVG src={shadownTalk} className="group-hover:hidden" />
                        <ReactSVG src={shadowTalkHover} className="hidden group-hover:block" />
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogContent className="p-6">
                      <AlertDialogHeader>
                        <AlertDialogTitle>{t('common.Prompt')}</AlertDialogTitle>
                        <AlertDialogDescription className="pt-6 pb-6">
                          {t('autoReception.endCurrentSession')}
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                        <Button
                          onClick={() => {
                            handleShutdownChat(itemData?.sessionId)
                          }}
                        >
                          {t('chatFrame.oK')}
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </TooltipComp>
            )}
          </div>
        </div>

        <TranslateErrorModal
          {...translateErrorData}
          setModalData={() => {
            setTranslateErrorData({ data: null, open: false, type: 1 })
            setTimeout(() => {
              arealRef?.current?.focus()
            }, 0)
          }}
          customerLiveToConv={customerLiveToConv}
        />

        <div
          className="w-full bg-white shrink-0 relative overflow-y-auto text-sm"
          style={{
            minHeight: 128,
            maxHeight: 167
          }}
        >
          <pre
            ref={arealRef}
            className={cn(
              'p-3 w-full h-full outline-none bg-white focus:after:hidden after:absolute after:top-3 after:left-3 after:text-lingheGray [&>img]:w-[300px]] pb-[50px] overflow-x-hidden overflow-y-auto',
              !message?.trim() && 'after:content-[attr(data-placehodler)]'
            )}
            data-placehodler={t('chatFrame.inpttext')}
            contentEditable
            suppressContentEditableWarning
            onClick={getLastRange}
            onInput={(e) => {
              const target = e.target as HTMLElement

              const content = getRichTextValue().content
              // 判断字符数是否超过限制
              if (content.length > MAX_INP_LENGTH) {
                // 截断超出的字符
                target.innerText = content.slice(0, MAX_INP_LENGTH)

                // 将光标移动到文本末尾
                const range = document.createRange()
                const selection = window.getSelection()
                range.selectNodeContents(target)
                range.collapse(false)
                selection?.removeAllRanges()
                selection?.addRange(range)
              } else {
                // 未超出限制时，继续调用 getLastRange 函数处理其他逻辑
                getLastRange(e)
              }
            }}
            onPaste={handlePaste}
            onKeyDown={(e) => {
              if (fastWordsModalData?.visible || !inpEnd || translateErrorData.open) {
                e.preventDefault()
                return
              }
              const key = e.key // 使用 e.key 来获取按下的键

              if (key === 'Enter' && e.shiftKey) {
                e.preventDefault()
                document.execCommand('InsertLineBreak')
                return
              }

              // 检查是否是回车键（包括主键盘和数字键盘的回车）
              if (key === 'Enter' || key === 'NumpadEnter') {
                e.preventDefault()
                handleSend()
                if (hasReCallMsg?.data) {
                  setLine(1)
                  handleDeleteRecall()
                }
              }
            }}
            style={whiteSpaceStyle}
          />

          {/* 回复消息 */}
          {hasReCallMsg?.data && (
            <div
              className={cn(
                'absolute left-3 bg-lingheTalkingBg transition-all rounded-md max-w-[80%] overflow-hidden h-[46px] flex items-center gap-[1px] pl-3 pr-3 text-lingheTextGray'
              )}
              style={{
                top: line < 4 ? line * 35 : 10
              }}
            >
              <X
                size={16}
                className="shrink-0 mr-1 mt-1 cursor-pointer"
                onClick={handleDeleteRecall}
              />
              <span className="w-4 shrink-0">|</span>
              <div className="flex-1 shrink-0 overflow-hidden flex items-center gap-1">
                <span className="shrink-0">回复</span>
                <span className="shrink-0">
                  {hasReCallMsg?.data?.sender?.name ||
                    userDetailData?.customerInfo?.name ||
                    hasReCallMsg?.data?.conversationTitle ||
                    ''}
                </span>
                <div className="flex-1 shrink-0 overflow-hidden text-ellipsis whitespace-nowrap">
                  {hasReCallMsg?.content}
                </div>
              </div>
            </div>
          )}
        </div>

        <Button
          disabled={
            !message?.trim() ||
            currentTab === ChatListStatus.UNASSIGNED ||
            (currentTab === ChatListStatus.FINISHED && !TALKSTATUS.isSelf && TALKSTATUS.isEnd)
          }
          type="submit"
          className="flex gap-1 absolute right-5 bottom-3 w-[68px] h-10"
          onClick={debounce((e) => {
            e.preventDefault()
            e.stopPropagation()
            handleSend()
            if (hasReCallMsg?.data) {
              setLine(1)
              handleDeleteRecall()
            }
          }, 60)}
        >
          <ReactSVG src={send} />
          {/* {t('chatFrame.send')} */}
          {/* <span>|</span>
          <ChevronDown size={13} /> */}
        </Button>
      </div>
    )
  }
)

ChatTextAreal.displayName = 'ChatTextAreal'

export default ChatTextAreal
