import { ClientResponse, httpClient } from '../../http-client/httpClient'

interface RequestBody {
  // 必需 临时token <验证重置密码验证码>获取
  tempToken: string
  // 必需 租户编号
  tenantId?: number
  // 必需 密码
  password: string
}

interface ApiResp extends ClientResponse {}
interface Serialize {
  (resp: ApiResp): ApiResp
}

const serialize: Serialize = (resp) => {
  return resp
}
// 重置密码
export const mutationResetPassword = async (payload: RequestBody): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>('/account/reset-pwd', {
    ...payload
  })

  return serialize(data)
}
