import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  Input,
  // Select,
  // SelectContent,
  // SelectItem,
  // SelectTrigger,
  // SelectValue,
  Textarea,
  cn
} from '@brisk/ui'
import { FC, memo, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { Controller, useForm } from 'react-hook-form'
import { IAddOrEditScriptReq, IScriptDetail } from '@renderer/api'
// import { map } from 'lodash'
// import { LANGUAGES, POSITION } from '@renderer/utils/consts'
// import { TipsIcon } from '../ReceptionWelcomeWords'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface IAddOrEditScriptProps {
  data?: IScriptDetail
  open: boolean
  title: string
  onClose: () => void
  onConfirm: (data: IAddOrEditScriptReq, type: '添加' | '编辑') => void
}

const defaultValues: IAddOrEditScriptReq = {
  language: 'zh-CN',
  name: '',
  position: 'BOTTOM-RIGHT',
  remark: ''
}
const AddOrEditScript: FC<IAddOrEditScriptProps> = ({ data, open, title, onClose, onConfirm }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<IAddOrEditScriptReq>({
    defaultValues: defaultValues
  })
  const { t } = useTranslation()
  const onSubmit = (formData: IAddOrEditScriptReq): void => {
    onConfirm && onConfirm(formData, data ? t('socialMediaAccount.Edit') : t('chatFrame.append'))
    reset(defaultValues)
  }

  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[432px] focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
              reset(defaultValues)
            }}
          />

          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-2 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_.itemWrap]:gap-2"
        >
          <div className="pl-4 pr-4 pb-8  flex flex-col gap-6">
            <Controller
              name="name"
              control={control}
              rules={{
                maxLength: {
                  value: 30,
                  message: t('socialMediaAccount.namecannotexceedcharacters')
                }
              }}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>
                    <span className="text-lingheError">*</span>
                    {t('socialMediaAccount.Name')}
                  </span>
                  <Input {...field} placeholder={t('socialMediaAccount.Pleaseenter')} required />
                  {errors?.name && (
                    <p className="text-xs text-lingheError">{errors?.name?.message}</p>
                  )}
                </div>
              )}
            />
            {/* 暂时隐藏语言 和位置配置 */}
            {/* <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <span>语言</span>
                  <Select onValueChange={field.onChange} defaultValue={field?.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="请选择" />
                    </SelectTrigger>
                    <SelectContent>
                      {map(LANGUAGES, (item) => (
                        <SelectItem key={item.code} value={item.code}>
                          {item.name}({item.region})
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            /> */}
            <Controller
              name="remark"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="itemWrap">
                  <span>{t('socialMediaAccount.Notes')}</span>
                  <Textarea
                    placeholder={t('socialMediaAccount.Pleaseenter')}
                    className={cn('min-h-[120px]')}
                    value={value}
                    onChange={(e) => {
                      const val = e?.target?.value
                      if (val?.length >= 800) {
                        message(t('clientManagement.Maximumwordlimitexceeded'), 'error')
                        onChange(val.slice(0, 800))
                        return
                      }
                      onChange(val)
                    }}
                    style={{
                      resize: 'none'
                    }}
                  />
                  <div className="relative h-0">
                    <span className="text-lingheTextGray text-xs absolute right-4 bottom-3">
                      {value?.length || 0}/800
                    </span>
                  </div>
                </div>
              )}
            />
            {/* <Controller
              name="position"
              control={control}
              render={({ field }) => (
                <div className="itemWrap">
                  <div className="flex items-center gap-1">
                    <span>咨询入口及聊天位置</span>
                    <TipsIcon content="左右边距24像素，下边距24像素" />
                  </div>
                  <Select onValueChange={field.onChange} defaultValue={field?.value}>
                    <SelectTrigger>
                      <SelectValue placeholder="请选择" />
                    </SelectTrigger>
                    <SelectContent>
                      {map(POSITION, (item) => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            /> */}
          </div>
          <AlertDialogFooter
            style={{ flexDirection: 'row' }}
            className="flex items-center gap-2 border-t h-[54px] pr-3"
          >
            <Button
              variant="outline"
              onClick={(e) => {
                e.preventDefault()
                onClose && onClose()
                reset(defaultValues)
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.verify')}</Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddOrEditScript)
