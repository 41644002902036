import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * RoleUpdateStatusDTO
 */
export interface IChangeRoleStatusReq {
  /**
   * 角色编号
   */
  roleId: number | string
  /**
   * 状态 （0停用 1正常）
   */
  status: number
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 修改角色状态
 * @param param
 * @returns
 */
export const fetchChangeRoleStatus = async (param: IChangeRoleStatusReq): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(`/bg/role/update-status`, param)

  return serialize(data)
}
