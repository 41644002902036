import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '@brisk/ui'
import selectAllActive from '../../../assets/svg/selectAllActive.svg'
import { ReactSVG } from 'react-svg'

interface ICheckboxPrimitive extends React.ComponentProps<typeof CheckboxPrimitive.Root> {}

const Mcheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  ICheckboxPrimitive
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      {/* <CheckIcon className="h-4 w-4" /> */}
      <ReactSVG src={selectAllActive} />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Mcheckbox.displayName = CheckboxPrimitive.Root.displayName

export { Mcheckbox }
