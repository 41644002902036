import { IUserDtailInfoVO } from '@brisk/api'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type State = {
  role: IUserDtailInfoVO
}

type Action = {
  setRole: (role: IUserDtailInfoVO) => void
}

export const useUserRoleStore = create(
  persist<State & Action>(
    (set) => ({
      role: null,
      setRole: (role) => {
        set({ role })
      }
    }),
    {
      name: 'userRole',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
