import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { User } from '../model/data/user'
import { EmployeeStatus } from '../api/model'

type State = {
  user?: Partial<User>
  outLogin: boolean
  manageNewUserHandle: boolean // 后台 是否显示过新手引导
}

type Action = {
  setUser: (user: User) => void
  setStatus: (status: EmployeeStatus) => void
  setManageNewUserHandle: (manageNewUserHandle: boolean) => void
  clear: () => void
  setOutLogin: (state: boolean) => void
}

export const useUserStore = create(
  persist<State & Action>(
    (set, get) => ({
      user: undefined,
      outLogin: false,
      setUser: (user: User): void => {
        set({ user })
      },
      setStatus: (status: EmployeeStatus): void => {
        set({ user: { ...get().user, status: status } })
      },
      clear: (): void => {
        set({ user: undefined, manageNewUserHandle: false })
      },
      setOutLogin: (state: boolean): void => {
        set({ outLogin: state })
      },
      manageNewUserHandle: false,
      setManageNewUserHandle: (manageNewUserHandle: boolean): void => set({ manageNewUserHandle })
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
