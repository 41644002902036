import axios from 'axios'
import { useUserStore } from '../store/useUserStore'
import { message } from '@renderer/components/message'
import { APP_ROUTER_PATH, router } from '@renderer/router'
import { useSignStore } from '../store/useSignStore'
import { isLoginPath } from '../utils/isLoginPath'
import i18n from 'i18next'

let hasShownTokenExpiredMessage = false

const language_http = {
  zh: 'zh_CN',
  'zh-CN': 'zh_CN',
  en: 'en_US'
  // tc: 'zh_TW',
  // ar: 'ar_SA'
}
export enum RESPONSE_CODE {
  success = 10000,
  password_error = 10009, // 密码错误
  token_overdue = 9000, // token过期
  imgCaptcha_error = 10012 // 图片验证码错误
}

export type ClientResponse = {
  code: number
  msg: string
  traceId: string
}
export type ApiReqFn<Payload, Data extends ClientResponse> = (payload: Payload) => Promise<Data>

export interface ApiRespSerializeFn<ApiResp extends ClientResponse, Data extends ClientResponse> {
  (resp: ApiResp): Data
}
console.log(import.meta.env.VITE_API_URL)
export const httpClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 58_000,
  adapter: 'fetch',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': ''
  }
})

// const requestFreshToken = axios.create()

httpClient.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = localStorage.getItem('i18nextLng')
    ? language_http[localStorage.getItem('i18nextLng')]
    : 'en_US'
  const { user } = useUserStore.getState()
  const token = user?.token
  if (token) {
    config.headers.Authorization = `${token}`
  }
  return config
})

const ignoreCodes = [10016, 10010, 10009, 10012]
httpClient.interceptors.response.use((response) => {
  if (ignoreCodes.includes(response.data.code)) {
    return response
  }
  // 登录过期
  if (response?.data?.code === RESPONSE_CODE.token_overdue) {
    const { clear } = useUserStore.getState()
    const { setRedirectUrl } = useSignStore.getState()

    clear()

    if (!isLoginPath(router.state.location.pathname)) {
      setRedirectUrl(router.state.location.pathname)
      router.navigate(APP_ROUTER_PATH.INPUT_ACCOUNT)
    }
    if (!hasShownTokenExpiredMessage) {
      message(i18n.t('common.loginhasexpiredpleaseloginagain'), 'error')
      hasShownTokenExpiredMessage = true
    }
  } else {
    if (response?.data?.code !== RESPONSE_CODE.success) {
      message(response?.data?.msg, 'error')
      throw new Error(response.data.msg)
    } else {
      hasShownTokenExpiredMessage = false
    }
  }

  return response
})
