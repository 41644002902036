import {
  AddPersonWordsGroupRequest,
  fetchAddPersonWordsGroup,
  fetchDeletePersonWords,
  fetchDeletePersonWordsGroup,
  fetchPersonWordsFromPc,
  fetchPersonWordsGroup,
  fetchSavePersonWords,
  fetchTeamWordsFromPc,
  RESPONSE_CODE,
  SavePersonWordsRequest,
  TeamGroupPageData,
  TeamWordsFromPcRequest,
  TeamWordsFromPcResponse
} from '@brisk/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface IWordsDataList {
  groupId: number
  groupName: string
  child: TeamGroupPageData[]
}
export const useTeamWordsHandle = () => {
  const [wordsDataList, setWordsDataList] = useState<TeamWordsFromPcResponse[]>([])
  const [personWordsDataList, setPersonWordsDataList] = useState<TeamWordsFromPcResponse[]>([])

  /** 团队话术 */
  const { isPending: isFetch, mutateAsync: getTeamWords } = useMutation({
    mutationFn: fetchTeamWordsFromPc
  })

  const {
    refetch: refetchPersonGroup,
    data: personGroupList,
    isPending: personGroupLoading
  } = useQuery({
    queryKey: [fetchPersonWordsGroup.name],
    queryFn: async () => {
      try {
        const res = await fetchPersonWordsGroup()
        if (res?.code === RESPONSE_CODE.success) return res?.data
      } catch (error) {
        console.log('error', error)
      }
    },
    staleTime: Infinity
  })

  const { mutateAsync: addPersonGroup, isPending: addPersonGroupLoading } = useMutation({
    mutationFn: fetchAddPersonWordsGroup
  })

  /** 个人话术 */
  const { mutateAsync: getPersonWords, isPending: getPersonWordsLoading } = useMutation({
    mutationFn: fetchPersonWordsFromPc
  })

  /** 保存/修改个人话术 */
  const { mutateAsync: savePersonWords, isPending: savePersonWordsLoading } = useMutation({
    mutationFn: fetchSavePersonWords
  })

  /** 删除 个人话术 */
  const { mutateAsync: delPersonWords, isPending: deletePersonWordsLoading } = useMutation({
    mutationFn: fetchDeletePersonWords
  })

  /** 删除 个人话术 分组 */
  const { mutateAsync: delPersonWordsGroup, isPending: deletePersonWordsGroupLoading } =
    useMutation({
      mutationFn: fetchDeletePersonWordsGroup
    })

  const handleGetTeamWordsData = useCallback(async (params?: TeamWordsFromPcRequest) => {
    try {
      const res = await getTeamWords(params || {})
      if (res?.code === RESPONSE_CODE.success) {
        setWordsDataList(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const handleGetPersonWordsData = useCallback(async (params?: TeamWordsFromPcRequest) => {
    try {
      const res = await getPersonWords(params || {})
      if (res?.code === RESPONSE_CODE.success) {
        setPersonWordsDataList(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const handleAddPersonGroup = useCallback(
    async (param: AddPersonWordsGroupRequest, callBack?: () => void) => {
      try {
        await addPersonGroup(param)
        refetchPersonGroup()
        callBack && callBack()
      } catch (error) {
        console.log('error', error)
      }
    },
    []
  )
  const handleSavePersonWords = useCallback(
    async (param: SavePersonWordsRequest, callBack?: () => void) => {
      try {
        await savePersonWords(param)
        handleGetPersonWordsData()
        callBack && callBack()
      } catch (error) {
        console.log('error', error)
      }
    },
    []
  )

  const handleDelPersonWords = useCallback(async (id: number) => {
    try {
      await delPersonWords(id)
      handleGetPersonWordsData()
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const handleDelPersonWordsGroup = useCallback(async (id: number) => {
    try {
      await delPersonWordsGroup(id)
      refetchPersonGroup()
      handleGetPersonWordsData()
    } catch (error) {
      console.log('error', error)
    }
  }, [])

  const loading = useMemo(() => {
    return (
      isFetch ||
      getPersonWordsLoading ||
      savePersonWordsLoading ||
      deletePersonWordsLoading ||
      addPersonGroupLoading ||
      deletePersonWordsGroupLoading ||
      personGroupLoading
    )
  }, [
    isFetch,
    getPersonWordsLoading,
    savePersonWordsLoading,
    addPersonGroupLoading,
    deletePersonWordsLoading,
    deletePersonWordsGroupLoading,
    personGroupLoading
  ])

  useEffect(() => {
    handleGetTeamWordsData()
    handleGetPersonWordsData()
  }, [])

  return {
    wordsDataList,
    personWordsDataList,
    isFetch,
    handleGetTeamWordsData,
    personGroupList,
    handleAddPersonGroup,
    handleSavePersonWords,
    handleDelPersonWords,
    handleDelPersonWordsGroup,
    wordsHandleLoading: loading
  }
}
