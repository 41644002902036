import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * RoleSearchDTO
 */
export interface IRolePageListReq {
  pageNum?: number
  pageSize?: number
  /**
   * 角色名
   */
  roleName?: string
}

/**
 * PageRoleVO
 */
export interface IRolePageListRes {
  countId?: string
  current?: number
  maxLimit?: number
  pages?: number
  records?: IRoleRecords[]
  size?: number
  total?: number
}

/**
 * RoleVO
 */
export interface IRoleRecords {
  id: string | number
  createBy?: number
  createTime?: string
  dataScope?: number
  deletedAt?: number
  isDefault?: number
  remark?: string
  roleId?: number
  roleKey?: string
  roleName?: string
  roleSort?: number
  status?: number
  updateBy?: number
  updateTime?: string
}

interface ApiResp extends ClientResponse {
  data: IRolePageListRes
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 分页搜索角色
 * @param param
 * @returns
 */
export const fetchRoleListPage = async (param: IRolePageListReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/role/page`, param)

  return serialize(data)
}
