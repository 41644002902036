import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

export interface IDataSyncPageRequest {
  /**
   * 结束时间
   */
  endDate?: Date;
  pageNum?: number;
  pageSize?: number;
  /**
   * 开始时间
   */
  startDate?: Date;
  /**
   * 用户id，page搜索时才需要
   */
  userId?: number | string;
}

/**
 * PageReceptionPageVO
 */
export interface IDataSyncPageResponse {
  countId?: string;
  current?: number;
  pages?: number;
  records?: IDataSyncPageRecords[];
  searchCount?: Response;
  size?: number;
  total?: number;
}

/**
 * ReceptionPageVO
 */
export interface IDataSyncPageRecords {
  id: string | number;
  /**
   * 坐席头像
   */
  avatarUrl?: string;
  /**
   * 坐席名称
   */
  nickname?: string;
  /**
   * 接待访客数
   */
  receptionNum?: number;
  /**
   * 接待会话数
   */
  sessionNum?: number;
  /**
   * 坐席状态
   */
  status?: string;
  /**
   * userId
   */
  userId?: number | string;
}

interface ApiResp extends ClientResponse {
  data: IDataSyncPageResponse;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 坐席明细分页查询
 * @param param
 * @returns
 */
export const fetchDataSyncPage = async (
  param: IDataSyncPageRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/realtime/statistics/reception/user/page`,
    param,
  );

  return serialize(data);
};
