import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * RoleSaveDTO
 */
export interface IAddOrEditRoleDTO {
  /**
   * 权限
   */
  menuIdList: number[]
  /**
   * 说明
   */
  remark: string
  /**
   * 角色编号 新增时可不传
   */
  roleId?: number
  /**
   * 角色名称
   */
  roleName: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 新增或编辑角色
 * @returns
 */
export const fetchAddOrEditRole = async (param: IAddOrEditRoleDTO): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/role`, param)

  return serialize(data)
}
