import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

export interface TeamWordsFromPcRequest {
  wordsKey?: string;
}

/**
 * PresetMessageVO
 */
export interface TeamWordsFromPcResponse {
  /**
   * 搜索出条数
   */
  filterTotal?: number;
  /**
   * 分组内容阈值
   */
  groupCountThreshold?: number;
  /**
   * 分组编号
   */
  groupId?: number;
  /**
   * 分组名
   */
  groupName?: string;
  /**
   * 短语详情
   */
  itemList?: PresetMessageItem[];
  /**
   * 分组下一共有多少条快捷语
   */
  total?: number;
}

/**
 * PresetMessageItem，短语详情
 */
export interface PresetMessageItem {
  /**
   * 分组编号
   */
  groupId?: number;
  /**
   * 图片内容
   */
  imageContent?: string[];
  /**
   * 短语编号
   */
  preMessageId?: number;
  /**
   * 快捷键
   */
  shortcutKey?: string;
  /**
   * 文本内容
   */
  textContent?: string;
}

interface ApiResp extends ClientResponse {
  data: TeamWordsFromPcResponse[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术分组内容列表
 * @returns
 */
export const fetchTeamWordsFromPc = async (
  params?: TeamWordsFromPcRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/preset/message/team/phrasing/list`,
    params,
  );

  return serialize(data);
};
