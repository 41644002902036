import { FC, memo, useState } from 'react'
import managerLogo from '../../../assets/svg/managerLogo.svg'
import pickUp from '../../../assets/svg/pickUp.svg'
import { ReactSVG } from 'react-svg'
import NavItem from './NavItem'
import logo from '@renderer/assets/svg/logo.svg'
import { cn } from '@brisk/ui'
import { useManageRouteMenu } from '@renderer/hooks/manager/useManageRouteMenu'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NAVWIDTH = '236px'
export const NAVWIDTHSM = '64px'
const NavComp: FC = () => {
  const [navWidth, setNavWidth] = useState<string>(NAVWIDTH)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { extractMenu } = useManageRouteMenu()

  return (
    <div
      className={cn('h-full flex flex-col text-sm')}
      style={{
        width: navWidth
      }}
    >
      <div
        className={cn(
          'h-14 flex items-center gap-2 pl-3',
          navWidth === NAVWIDTHSM && 'justify-center p-0'
        )}
      >
        {navWidth === NAVWIDTHSM ? <ReactSVG src={logo} /> : <ReactSVG src={managerLogo} />}
      </div>

      <div className={cn('flex-1 shrink-0 w-full pl-2 pr-2 overflow-y-auto')}>
        <NavItem
          navData={extractMenu}
          navWidth={navWidth}
          handleClick={(el) => {
            console.log('el>---->>', el)
            el?.path && navigate(el?.path)
          }}
        />
      </div>

      <div
        onClick={() => setNavWidth(navWidth === NAVWIDTH ? NAVWIDTHSM : NAVWIDTH)}
        className={cn(
          'h-14 flex items-center gap-2 pl-4 cursor-pointer relative',
          'after:w-[90%] after:h-[1px] after:absolute after:left-[50%] after:top-0 after:bg-[#ECECEC] after:-translate-x-1/2',
          navWidth === NAVWIDTHSM && 'justify-center pl-0'
        )}
      >
        <ReactSVG
          className={cn('transition-all', navWidth === NAVWIDTHSM && 'rotate-180')}
          src={pickUp}
        />
        <div className={cn(navWidth === NAVWIDTHSM && 'hidden')}>
          {t('common.Wrapupnavigation')}
        </div>
      </div>
    </div>
  )
}

export default memo(NavComp)
