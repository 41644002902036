import { FC, memo, useEffect, useRef } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { Controller, useForm } from 'react-hook-form'
import {
  Button,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@brisk/ui'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IAgentConfig, fetchAgentConfig, fetchSaveAgentConfig } from '@renderer/api'
import { map } from 'lodash'
import { TipsIcon } from '../ReceptionWelcomeWords'
import TextareaWithEmoji, { ITextareaWithEmojiRef } from '../Components/TextareaWithEmoji'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import { replaceEmojiMark } from '@renderer/utils/emojiData'
import { useTranslation } from 'react-i18next'

let saveDefault

const index: FC = () => {
  const textareaRef = useRef<ITextareaWithEmojiRef>(null)
  const { t } = useTranslation()
  const { handleSubmit, control, setValue } = useForm<IAgentConfig>({
    defaultValues: {
      maxReception: 0,
      queuingPrompt: ''
    }
  })

  const { data: agentData, refetch } = useQuery({
    queryKey: [fetchAgentConfig.name],
    queryFn: async () => {
      const res = await fetchAgentConfig()
      return res.data
    }
  })

  const { mutateAsync: saveAgentconfig } = useMutation({
    mutationFn: fetchSaveAgentConfig
  })

  const handleSetDefaultForm = (data): void => {
    setValue('maxReception', data?.maxReception || 0)
    setValue('queuingPrompt', data?.queuingPrompt || '')
    if (textareaRef?.current && saveDefault) {
      textareaRef.current.preCurrent.innerHTML = replaceEmojiMark(saveDefault.queuingPrompt)
      textareaRef?.current?.setContent(saveDefault.queuingPrompt)
    }
  }

  const onSubmit = async (formData): Promise<void> => {
    console.log('formData', formData)
    const res = await saveAgentconfig(formData)
    if (res?.code === RESPONSE_CODE.success) {
      saveDefault = formData
      message(t('knowledgeBase.SaveSuccessful'), 'success')
      refetch()
    }
  }

  useEffect(() => {
    if (agentData) {
      handleSetDefaultForm(agentData)
      saveDefault = agentData
    }
  }, [agentData])

  return (
    <NavBodyLayout
      title={[t('autoReception.receptionSettings'), t('maxCapacity.maxCapacityTitle')]}
    >
      <form className="w-full h-full text-sm" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full h-full">
          <div className="h-[54px] shrink-0 border-b">
            <div className="flex items-center pl-3 h-full font-medium text-base">
              {t('maxCapacity.maxCapacityTitle')}
            </div>
          </div>

          <div className="flex-1 shrink-0 overflow-y-auto pt-4">
            <Controller
              name="maxReception"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <div className="flex flex-col gap-3 pl-3 mb-4">
                      <div className="flex items-center">
                        <span>{t('maxCapacity.minimumsettingis')}</span>
                        <Select
                          value={field.value + ''}
                          onValueChange={(e) => field.onChange(Number(e))}
                        >
                          <SelectTrigger className="w-16 h-6 mr-2">
                            <SelectValue
                              defaultValue={field.value + ''}
                              placeholder={`${field.value || t('infoCollection.PleaseSelect')}`}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {map(
                                Array.from({ length: 100 }, (_, index) => index + 1),
                                (item) => (
                                  <SelectItem key={item} value={item.toString()}>
                                    {item}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <span>{t('maxCapacity.maximumNumberVisitorsperAgent')}</span>
                      </div>
                      <p className="text-xs text-lingheTextGray">
                        {t('maxCapacity.onceanagentsInProgress')}
                      </p>
                    </div>
                  </>
                )
              }}
            />
            <Controller
              name="queuingPrompt"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <>
                    <div className="flex flex-col gap-3 pl-3 mb-4 w-[472px]">
                      <div className="flex items-center gap-1">
                        {t('maxCapacity.queueReminderMessage')}
                        <TipsIcon
                          content={t('maxCapacity.Whennumberconsultationsexceedsreceptioncapacity')}
                        />
                      </div>
                      <TextareaWithEmoji
                        ref={textareaRef}
                        contentVal={saveDefault?.queuingPrompt || ''}
                        onChange={(val) => onChange(val)}
                      />
                    </div>
                  </>
                )
              }}
            />
          </div>

          <div className="h-16 shrink-0 border-t drop-shadow">
            <div className="flex items-center gap-2 pl-3 h-full">
              <Button type="submit">{t('common.save')}</Button>
              <Button
                variant="outline"
                onClick={(e) => {
                  e.preventDefault()
                  handleSetDefaultForm(saveDefault)
                }}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </NavBodyLayout>
  )
}

export default memo(index)
