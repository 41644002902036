import { IContentContent } from '@brisk/api'
import { cn } from '@brisk/ui'
import ImageView from '@renderer/components/ImageView'
import { FC, memo } from 'react'

const ChatListImage: FC<{ imagSrc: string; data: IContentContent }> = ({ imagSrc, data }) => {
  const { width, height } = data
  return (
    <div className={cn('cursor-pointer', `h-[${height}px]`)}>
      <ImageView
        src={imagSrc}
        className={cn(
          `w-[${width}px] h-[${height}px]`,
          'max-w-72 max-h-[600px] rounded-md cursor-pointer'
        )}
        showEdit
      />
    </div>
  )
}

export default memo(ChatListImage)
