import { FC, memo, useContext } from 'react'
import { Button } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { TeamWordsFromPcResponse } from '@brisk/api'
import WordsItemWrap from './WordsItemWrap'
import { WordsContext } from './KnowledgeBase'
import AddPersonWords from './AddPersonWords'
import { useTranslation } from 'react-i18next'

const SelfWords: FC<{ list: TeamWordsFromPcResponse[] }> = ({ list }) => {
  const { searchList, searchInputRef, addPersonWordsData, setAddPersonWordsData } =
    useContext(WordsContext)
  const { t } = useTranslation()

  return (
    <div className="w-full h-full relative">
      <div className="w-full h-full overflow-y-auto p-3">
        <WordsItemWrap data={searchInputRef?.current?.value ? searchList : list} />
      </div>

      <div className="w-full h-16 flex items-center justify-end pl-3 pr-3 absolute bottom-0 left-0 border-t bg-white">
        {/* <p className="cursor-pointer hover:text-lingheChatItemBg">导入</p>
        <p className="cursor-pointer hover:text-lingheChatItemBg">导出</p>
        <p className="cursor-pointer hover:text-lingheChatItemBg">下载模板</p> */}
        <Button
          onClick={() => {
            setAddPersonWordsData({
              open: true,
              data: null
            })
          }}
        >
          <Plus size={14} className="mr-1" />
          {t('chatFrame.new')}
        </Button>
      </div>
      <AddPersonWords
        open={addPersonWordsData?.open}
        data={addPersonWordsData?.data}
        onClose={() => setAddPersonWordsData({ open: false, data: null })}
      />
    </div>
  )
}

export default memo(SelfWords)
