import { inputIconClassName } from '@renderer/utils/consts'
import { FC } from 'react'
import { NoFocusInput } from './NoFocusInput'
import { Button, cn } from '@brisk/ui'
import { useCaptchaCountdown } from '@renderer/hooks/useCaptchaCountdown'
import { ReloadIcon } from '@radix-ui/react-icons'
import { useMutation } from '@tanstack/react-query'
import { fetchSendCaptcha } from '@renderer/api'
import { message } from '@renderer/components/message'
import { AccountType } from '@renderer/api/model'
import { ReactSVG } from 'react-svg'
import captacha from '../../../assets/svg/captacha.svg'
import { FieldError } from 'react-hook-form'
import {fetchEditEmailCaptcha} from "../../../api/setting/editEmailCaptcha";

export type Field = {
  name: string
  onBlur: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  ref: (e: HTMLInputElement) => void
  value: string
}

interface IProps {
  type?: string
  field: Field
  errors: FieldError | undefined
  className?: string
  account: string
  accountType: AccountType
  [key: string]: boolean | string | number | undefined | null | object | []
}

const GetCaptchaInp: FC<IProps> = ({
  type= 'default',
  field,
  errors,
  className,
  account,
  accountType,
  ...others
}) => {
  const { isCounting, startCount, count, reset, isSentCaptcha } = useCaptchaCountdown()
  const handleClickSendCaptcha = async (): Promise<void> => {
    if (isCounting) {
      return
    }

    const res = await mutateAsyncSendCaptcha({
      account: account,
      type: accountType
    })

    if (res?.code !== 10000) {
      message(res?.msg, 'error')
      reset()
      return
    }

    startCount()
  }
  const { mutateAsync: mutateAsyncSendCaptcha, isPending } = useMutation({
    mutationFn: type === 'default' ? fetchSendCaptcha : fetchEditEmailCaptcha
  })

  return (
    <>
      <div className="border w-full h-12 radios rounded-sm flex relative">
        <ReactSVG src={captacha} className={inputIconClassName} />
        <NoFocusInput
          maxLength={6}
          placeholder="请输入验证码"
          className={cn(
            'flex-1 h-full pl-9 text-sm border-none outline-none',
            errors ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring',
            className
          )}
          {...field}
          {...others}
        />
        <Button
          variant="link"
          className="h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline"
          onClick={(e) => {
            e.preventDefault()
            handleClickSendCaptcha()
          }}
          disabled={isPending}
        >
          {isPending && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
          <span className="absolute left-0 text-border">|</span>
          {isCounting ? `${count}s` : isSentCaptcha ? '重新发送' : '发送验证码'}
        </Button>
      </div>
      {errors && <p className="bottom-8 text-lingheError text-sm">验证码错误，请重新输入</p>}
    </>
  )
}

export default GetCaptchaInp
