import { Button, Checkbox, Switch, cn } from '@brisk/ui'
import {
  ChannelTelegramVO,
  ITelegramResp,
  fetchAddTelegramConfig,
  fetchChannelEnable,
  fetchDelChannelConfigs,
  fetchTelegramConfig,
  fetchUpdateTelegramRemark
} from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { useMutation } from '@tanstack/react-query'
import { Plus } from 'lucide-react'
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import { IPageationRef } from '../AddCustomer'
import { ReactSVG } from 'react-svg'
import { ColumnDef } from '@tanstack/react-table'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import dayjs from 'dayjs'
import AddTelegramBot from './AddTelegramBot'
import { message } from '@renderer/components/message'
import DelCustolerModal from '../Components/DelCustolerModal'
import { map } from 'lodash'
import UpdateRemark from './UpdateRemark'
import { IParamForPage } from '@renderer/utils/consts'
import { ManagerTable, ManagerTableRef } from '@renderer/components/ManagerTable'
import {useTranslation} from "react-i18next";

const TelegramBot: FC = () => {
  const [telegramData, setTelegramData] = useState<ITelegramResp>()
  const [rowSelect, setRowSelect] = useState<ChannelTelegramVO[]>([])
  const [showAddTelegramBot, setShowAddTelegramBot] = useState<boolean>(false)
  const { t } = useTranslation()
  const [delData, setDelData] = useState<{
    open: boolean
    data: ChannelTelegramVO[] | null
  }>({
    open: false,
    data: null
  })
  const [updateData, setUpdateData] = useState<{
    open: boolean
    data: ChannelTelegramVO | null
  }>({
    open: false,
    data: null
  })

  const tableRef = useRef<ManagerTableRef>(null)
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })

  const { mutateAsync: getTelegramList } = useMutation({
    mutationFn: fetchTelegramConfig
  })

  const { mutateAsync: addTelegramConfig } = useMutation({
    mutationFn: fetchAddTelegramConfig
  })

  const { mutateAsync: changeEnable } = useMutation({
    mutationFn: fetchChannelEnable
  })

  const { mutateAsync: delArrScript } = useMutation({
    mutationFn: fetchDelChannelConfigs
  })

  const { mutateAsync: updateTelegramRemark } = useMutation({
    mutationFn: fetchUpdateTelegramRemark
  })

  const handleGetList = async (param: IParamForPage): Promise<void> => {
    try {
      const res = await getTelegramList(param)
      if (res.code === RESPONSE_CODE.success) {
        console.log('res', res?.data)
        setTelegramData(res.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const columns: ColumnDef<ChannelTelegramVO>[] = useMemo(
    () => [
      {
        id: 'name',
        header: ({ table }) => (
          <div className="flex items-center gap-3">
            <Checkbox
              id="Select all"
              checked={table.getIsAllPageRowsSelected()}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
            <label htmlFor="Select all">{t('socialMediaAccount.Name')}</label>
          </div>
        ),
        cell: ({ row }) => (
          <div className="flex items-center gap-3 h-full max-w-[150px] text-ellipsis overflow-hidden whitespace-nowrap">
            <Checkbox
              id={row?.original?.channelId + ''}
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              aria-label="Select row"
            />
            <label htmlFor={row?.original?.channelId + ''} className="flex flex-col gap-0.5">
              <span>{row?.original?.name}</span>
              <span className="text-lingheGray">{row?.original?.userName}</span>
            </label>
          </div>
        )
      },
      {
        id: 'tokenEffective',
        header: () => <div>{t('socialMediaAccount.TokenStatus')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          const effective = +original?.tokenEffective === 1
          return (
            <div className="flex flex-col gap-0.5">
              <span className={cn(effective ? 'text-[#23D881]' : 'text-lingheError')}>
                {effective ? t('socialMediaAccount.Valid') : t('socialMediaAccount.Invalid')}
              </span>
              <span className="text-lingheGray">
                {dayjs(original?.tokenCheckDate).format('YY-MM-DD HH:mm:ss')}
              </span>
            </div>
          )
        }
      },
      {
        id: 'remark',
        header: () => <div>{t('socialMediaAccount.Notes')}</div>,
        cell: ({ row: { original } }) => (
          <div className="max-w-[300px] line-clamp-2 break-words overflow-hidden text-ellipsis">
            {original?.remark}
          </div>
        )
      },
      {
        id: 'createTime',
        header: () => <div>{t('socialMediaAccount.AddTime')}</div>,
        cell: ({ row: { original } }) => (
          <div>{dayjs(original?.createTime).format('YY-MM-DD HH:mm:ss')}</div>
        )
      },
      {
        id: 'enable',
        header: () => <div>{t('socialMediaAccount.Enable')}</div>,
        cell: ({ row: { original } }) => (
          <Switch
            checked={Boolean(original?.enable)}
            onCheckedChange={async (e) => {
              try {
                const res = await changeEnable({
                  channelId: original?.channelId,
                  enable: Number(e)
                })
                if (res?.code === RESPONSE_CODE.success) {
                  message(`${e ? t('socialMediaAccount.Done') : t('socialMediaAccount.disableDone')}`, 'success')
                  handleGetList({
                    pageNum: pageationRef?.current?.currentPage || 1,
                    pageSize: pageationRef?.current?.itemsPerPage || 10
                  })
                }
              } catch (error) {
                console.log('error', error)
              }
            }}
          />
        )
      },
      {
        id: 'edit',
        header: () => <div>{t('socialMediaAccount.Actions')}</div>,
        cell: ({ row: { original } }) => (
          <Button
            variant="link"
            className="hover:no-underline p-0"
            onClick={() => setUpdateData({ open: true, data: original })}
          >
              {t('socialMediaAccount.Notes')}
          </Button>
        )
      }
    ],
    []
  )

  useEffect(() => {
    handleGetList({
      pageNum: 1,
      pageSize: 10
    })
  }, [])

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      <div className="h-16 shrink-0 flex items-center justify-between pl-3 pr-3 [&_button]:h-8">
        <div className="flex items-center h-full gap-3">
          <Button onClick={() => setShowAddTelegramBot(true)}>
            <Plus size={16} />
            {t('socialMediaAccount.AddBot')}
          </Button>
          {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
            如何添加 Telegram Bot?
          </Button> */}
        </div>
        {telegramData?.records?.length ? (
          <Button
            disabled={!rowSelect?.length}
            variant="destructive"
            onClick={() => setDelData({ open: true, data: rowSelect })}
          >
              {t('socialMediaAccount.Delete')}
          </Button>
        ) : (
          false
        )}
      </div>
      <div className="flex-1 w-full overflow-hidden shrink-0">
        <ManagerTable<ChannelTelegramVO>
          ref={tableRef}
          pageationRef={pageationRef}
          data={telegramData?.records || []}
          columns={columns}
          rowCount={telegramData?.total || 0}
          onPageChange={(pageNum, pageSize) => handleGetList({ pageNum, pageSize })}
          onRowSelectionChange={(rows) => setRowSelect(rows)}
          emptyElement={() => (
            <div className="flex items-center justify-center pt-20">
              <div className="flex flex-col items-center justify-center gap-2">
                <ReactSVG src={noSearchResult} />
                <span className="mb-2 text-sm text-lingheTextGray">
                    {t('socialMediaAccount.NoTelegramBotyetpleaseaddonefirst')}
                </span>
                <Button onClick={() => setShowAddTelegramBot(true)}>
                  <Plus size={16} />
                    {t('socialMediaAccount.AddBot')}
                </Button>
                {/* <Button variant="link" className="hover:no-underline p-0" onClick={() => {}}>
                  如何添加 Telegram Bot?
                </Button> */}
              </div>
            </div>
          )}
        />
      </div>

      <AddTelegramBot
        open={showAddTelegramBot}
        onClose={() => setShowAddTelegramBot(false)}
        onConfirm={async (token) => {
          try {
            const res = await addTelegramConfig(token)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('common.successAdd'), 'success')
              handleGetList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setShowAddTelegramBot(false)
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      <DelCustolerModal
        title={t('common.Prompt')}
        open={delData.open}
        onClose={() => {
          setDelData({
            open: false,
            data: null
          })
        }}
        onConfirm={async () => {
          try {
            const res = await delArrScript(map(delData?.data, (it) => it?.channelId))
            if (res?.code === RESPONSE_CODE.success) {
              message(t('businessManagement.Deletedsuccessfully'), 'success')
              setDelData({
                open: false,
                data: null
              })
              handleGetList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              tableRef?.current?.resetRowSelection()
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center">
              {t('socialMediaAccount.Afterdeletionyourwebsitewilllongerbeableinitiateonlineconsultations')}
            <br />
              {t('socialMediaAccount.Werecommendthatyou')}
            <Button
              variant="link"
              className="hover:no-underline p-0"
              onClick={async () => {
                try {
                  const res = await changeEnable({
                    channelId: delData?.data[0]?.channelId,
                    enable: 0
                  })
                  if (res?.code === RESPONSE_CODE.success) {
                    message(t('socialMediaAccount.disableDone'), 'success')
                    handleGetList({
                      pageNum: pageationRef?.current?.currentPage || 1,
                      pageSize: pageationRef?.current?.itemsPerPage || 10
                    })
                  }
                } catch (error) {
                  console.log('error', error)
                }
              }}
            >
              ”{t('socialMediaAccount.Deactivate')}“
            </Button>
              ”{t('socialMediaAccount.Deactivate')}“
            <br />
          </div>
        }
      />

      <UpdateRemark
        open={updateData?.open}
        data={updateData?.data}
        onClose={() => setUpdateData({ open: false, data: null })}
        onConfirm={async (remark) => {
          try {
            const res = await updateTelegramRemark({
              channelId: updateData?.data?.channelId,
              remark
            })
            if (res?.code === RESPONSE_CODE.success) {
              message(t('accountInformation.modifiedsuccessfully'), 'success')
              handleGetList({
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
              setUpdateData({ open: false, data: null })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />
    </div>
  )
}

export default memo(TelegramBot)
