import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * RealtimeStatisticVO
 */
export interface ICustomerStatus {
  /**
   * 值
   */
  num?: number;
  /**
   * 类型：1：进行中，2：排队中，3：已结束，4：在线坐席数，5：忙碌坐席数，6：离线坐席数，
   */
  statisticKey?: number;
}

interface ApiResp extends ClientResponse {
  data: ICustomerStatus[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 坐席状态
 * @returns
 */
export const fetchCustomerStatus = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/bg/realtime/statistics/state/user`,
  );

  return serialize(data);
};
