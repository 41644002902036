import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type State = {
  loginTime: number
}

type Action = {
  setLoginTime: (loginTime: number) => void
}

export const useSaveLoginTime = create(
  persist<State & Action>(
    (set, get) => ({
      loginTime: 0,
      setLoginTime: (loginTime: number): void => {
        set({ loginTime })
      }
    }),
    {
      name: 'loginTime',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
