import { fetchCustomerUserList } from '@brisk/api'
import {
  Accordion,
  cn,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@brisk/ui'
import { useQuery } from '@tanstack/react-query'
import { includes, map } from 'lodash'
import { ChevronRight } from 'lucide-react'
import { FC, memo, useState } from 'react'
import {useTranslation} from "react-i18next";

interface ICustomerSelectProps {
  onValueChange: (val: string) => void
  className?: string
}

const CustomerSelectComp: FC<ICustomerSelectProps> = ({ onValueChange, className }) => {
  const [openList, setOpenList] = useState([])
  const { t } = useTranslation()
  /** 全部坐席 */
  const { data: teamTree } = useQuery({
    queryKey: [fetchCustomerUserList.name],
    queryFn: async () => {
      const res = await fetchCustomerUserList()
      return res?.data
    }
  })

  return (
    <Select
      onValueChange={(value) => {
        onValueChange && onValueChange(value)
      }}
    >
      <SelectTrigger className={cn('w-[200px] h-8', className)}>
        <SelectValue defaultValue="-1" placeholder={t('statistics.SeatInformation')} />
      </SelectTrigger>
      <SelectContent className="text-sm">
        <SelectGroup>
          <Accordion type="single" collapsible className="w-full">
            <SelectItem key="allType" value={'-1'}>
                {t('statistics.SeatInformation')}
            </SelectItem>
            {map(teamTree, (item) => (
              <SelectItem key={item?.userId + String(item?.teamId) + ''} value={item?.userId + ''}>
                {item?.nickname}
              </SelectItem>
              // <div
              //   key={item?.teamId + '' + item?.teamName + index}
              //   className="w-full p-2 pl-0 pr-0 border-0 rounded-md cursor-pointer relative"
              //   onClick={() => {
              //     includes(openList, item?.teamId + '')
              //       ? setOpenList([])
              //       : setOpenList([item?.teamId + ''])
              //   }}
              // >
              //   <div className="flex pr-2 items-center justify-between">
              //     <span className="pl-2 pr-2">{item?.teamName}</span>
              //     {item?.userList?.length ? (
              //       <ChevronRight
              //         size={14}
              //         className={cn(
              //           'transition-all',
              //           includes(openList, item?.teamId + '') && 'rotate-90'
              //         )}
              //       />
              //     ) : (
              //       ''
              //     )}
              //   </div>
              //   {includes(openList, item?.teamId + '') ? (
              //     <div className="w-full pt-2 pl-2">
              //       {map(item?.userList, (it) => (
              //         <SelectItem
              //           key={it?.userId + String(item?.teamId) + ''}
              //           value={it?.userId + ''}
              //         >
              //           {it?.nickname}
              //         </SelectItem>
              //       ))}
              //     </div>
              //   ) : (
              //     false
              //   )}
              // </div>
            ))}
          </Accordion>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default memo(CustomerSelectComp)
