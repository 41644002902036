import { Monitor, QrCode } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '../../../router'

export const SignInSwitcher: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const isQRCodePage = useMemo((): boolean => {
    return location.pathname === APP_ROUTER_PATH.SIGNIN_BY_QRCODE
  }, [location])

  return (
    <div
      className="absolute right-0 top-0 cursor-pointer w-20 h-20"
      onClick={() => {
        if (isQRCodePage) {
          navigate(-1)
          return
        }
        navigate(APP_ROUTER_PATH.SIGNIN_BY_QRCODE)
      }}
    >
      <div className="absolute right-0 top-0 bg-[#FCFCFC] w-[100px] h-[100px] translate-x-1/2 -translate-y-1/2 rotate-45"></div>
      {isQRCodePage ? (
        <Monitor className="absolute right-2 top-2 text-[#247CFF]" />
      ) : (
        <QrCode className="absolute right-2 top-2 text-[#247CFF]" />
      )}
    </div>
  )
}
