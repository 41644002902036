/**
 * types
 *
 * @desc 描述：类型、枚举
 */

/* =================================================== */

import { ReactElement } from 'react'

/**
 * 弹窗状态
 */
export enum DialogStateEnum {
  /** 发现 */
  Discover,
  /** 更新 */
  Update,
  /** 重启 */
  Reboot
}

/**
 * 更新按钮
 * @desc 多语言的 key
 */
export enum UpdateBtnEnum {
  Update = 'systemSetting.更新',
  RstUpdate = 'systemSetting.重启更新'
}

/**
 * 弹窗衍生状态
 * @desc 基于 DialogStateEnum 的衍生状态
 */
export interface IDialogDerive {
  // 按钮文字
  textBtn: string
  // 确定点击事件
  onClick: () => void
  // 内容
  details?: ReactElement
}
