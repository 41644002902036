import { forwardRef, useImperativeHandle, useState } from 'react'
import _, { map } from 'lodash' // 引入lodash
import {
  cn,
  Input,
  Pagination,
  PaginationContent,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@brisk/ui'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface PaginationProps {
  totalItems: number
  onPageChange: (pageNumber: number, itemsPerPage?: number) => void
  perPageOptions?: number[]
  defaultPerPage?: number
  defaultPage?: number
  className?: string
  showPageSelect?: boolean
  showQuikTurn?: boolean
}

const PaginationComp = forwardRef(
  (
    {
      totalItems,
      onPageChange,
      perPageOptions = [10, 20, 50],
      defaultPerPage = 10,
      defaultPage = 1,
      className,
      showPageSelect = true,
      showQuikTurn = true
    }: PaginationProps,
    ref
  ) => {
    const [currentPage, setCurrentPage] = useState<number>(defaultPage)
    const [itemsPerPage, setItemsPerPage] = useState<number>(defaultPerPage)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => {
      return {
        currentPage,
        itemsPerPage,
        resetPaging() {
          initialisationResetPaging()
        }
      }
    })
    // 重设分页
    const initialisationResetPaging = () => {
      setCurrentPage(defaultPage)
      setItemsPerPage(defaultPerPage)
    }
    // 计算总页数，使用lodash的ceil方法向上取整
    const totalPages = _.ceil(totalItems / itemsPerPage)

    // 使用lodash的clamp方法确保页码在有效范围内
    const safeCurrentPage = _.clamp(currentPage, 1, totalPages)

    // 更新每页显示条数，这里直接使用state更新，因为不需要额外逻辑
    const handlePerPageChange = (newPerPage: number): void => {
      setItemsPerPage(newPerPage)
      // 当每页显示条数改变时，重置回第一页
      setCurrentPage(1)
      onPageChange(1, newPerPage)
    }

    // 跳转到指定页，确保页码有效
    const goToPage = (pageNumber: number): void => {
      const validPage = _.clamp(pageNumber, 1, totalPages)
      setCurrentPage(validPage)
      onPageChange(validPage, itemsPerPage)
    }

    // 分页项生成器，优化以最多显示6个页码，其余使用省略号
    const renderPaginationItems = (): JSX.Element[] => {
      // 始终显示首页和末页
      const paginationItems: JSX.Element[] = []

      if (totalPages > 6) {
        // 首页
        paginationItems.push(
          <li
            key="first"
            onClick={() => goToPage(1)}
            className={currentPage === 1 ? 'bg-primary text-white' : ''}
          >
            1
          </li>
        )

        // 计算中间页码的展示区间
        const midStart = Math.max(2, Math.min(safeCurrentPage - 1, totalPages - 4))
        const midEnd = Math.min(totalPages - 1, midStart + 4)
        // 首页之后的省略号
        if (midStart > 2) paginationItems.push(<li key="ellipsis-start">...</li>)
        // 生成中间页码
        _.range(midStart, midEnd + 1).forEach((page) => {
          paginationItems.push(
            <li
              key={page}
              onClick={() => goToPage(page)}
              className={currentPage === page ? 'bg-primary text-white' : ''}
            >
              {page}
            </li>
          )
        })

        // 如果当前页不是接近末尾，添加末尾省略号
        if (safeCurrentPage < totalPages - 2) {
          paginationItems.push(<li key="ellipsis-end">...</li>)
        }

        // 末页
        paginationItems.push(
          <li
            key="last"
            onClick={() => goToPage(totalPages)}
            className={currentPage === totalPages ? 'bg-primary text-white' : ''}
          >
            {totalPages}
          </li>
        )
      } else {
        // 如果总页数不大于6，直接显示所有页码
        _.range(1, totalPages + 1).forEach((page) => {
          paginationItems.push(
            <li
              key={page}
              onClick={() => goToPage(page)}
              className={currentPage === page ? 'bg-primary text-white' : ''}
            >
              {page}
            </li>
          )
        })
      }

      return paginationItems
    }

    return (
      <div className={className}>
        <Pagination
          className={cn(
            `text-sm [&_li]:w-8 [&_li]:h-8 [&_li]:border [&_li]:flex [&_li]:items-center [&_li]:justify-center
             [&_li]:cursor-pointer [&_li:hover]:bg-primary [&_li:hover]:text-white [&_li]:rounded [&_span]:whitespace-nowrap`
          )}
        >
          <span className="mr-2 flex items-center">
            {t('common.Total')}
            {totalItems}
            {t('common.Items')}
          </span>
          {showPageSelect && (
            <Select
              onValueChange={(perPage) => {
                handlePerPageChange(Number(perPage))
              }}
            >
              <SelectTrigger className=" mr-4 h-8">
                <SelectValue placeholder={`${defaultPerPage}${t('common.ItemsPage')}`} />
              </SelectTrigger>
              <SelectContent
                style={{
                  width: 'fit-content'
                }}
              >
                <SelectGroup>
                  {map(perPageOptions, (item) => (
                    <SelectItem key={item + 'perPageOption'} value={`${item}`}>
                      {`${item}${t('common.ItemsPage')}`}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
          <PaginationContent className="[&_ul]:gap-3">
            <li
              style={{
                cursor: safeCurrentPage === 1 ? 'not-allowed' : 'pointer'
              }}
            >
              <ChevronLeft
                size={20}
                className=""
                onClick={() => {
                  if (safeCurrentPage === 1) return
                  goToPage(safeCurrentPage - 1)
                }}
              />
            </li>
            {renderPaginationItems()}
            <li
              style={{
                cursor: safeCurrentPage === totalPages ? 'not-allowed' : 'pointer'
              }}
            >
              <ChevronRight
                size={20}
                onClick={() => {
                  if (safeCurrentPage === totalPages) return
                  goToPage(safeCurrentPage + 1)
                }}
              />
            </li>
          </PaginationContent>
          {showQuikTurn && (
            <div className="flex items-center gap-2 h-8 ml-4">
              <span>{t('common.Redirectto')}</span>
              <Input
                className="w-12 h-8"
                onBlur={(e) => {
                  const value = Number(e.target.value)
                  if (!value || value === currentPage) return
                  goToPage(value)
                }}
                onKeyDown={(e) => {
                  const key = e.code
                  if (key === 'Enter') goToPage(Number((e.target as HTMLInputElement).value))
                }}
              />
              <span>{t('common.Page')}</span>
            </div>
          )}
        </Pagination>
      </div>
    )
  }
)

PaginationComp.displayName = 'PaginationComp'

export default React.memo(PaginationComp)
