import { ClientResponse, httpClient } from '../../http-client/httpClient'
import { Tenant } from '../../model'

interface RequestParams {
  /**
   * AccessKeyId
   */
  accessKey?: string
  /**
   * 桶名称
   */
  bucket?: string
  /**
   * 服务的端点
   */
  endpoint?: string
  /**
   * 失效时间
   */
  expirationTime?: number
  /**
   * 区域
   */
  region?: string
  /**
   * SecretAccessKey
   */
  secretKey?: string
  /**
   * SessionToken
   */
  sessionToken?: string
  [property: string]: any
}

interface ApiResp extends ClientResponse {
  // TODO:
  data: RequestParams
}
interface Serialize {
  (resp: ApiResp): ApiResp
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data
  return resp
}

// 文件上传
export const uploadFile = async (): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/file/credentials`)

  return serialize(data)
}
