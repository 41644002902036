import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { ISessionTranslateConfig } from "./fetchSessionTranslateConfig";
import { ISessionTranslateEngine } from "./fetchSessionTranslateEngine";

interface ApiResp extends ClientResponse {
  data: ISessionTranslateConfig;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 接收消息翻译语言配置
 * @param sessionId
 * @returns
 */
export const fetchSessionTranslateEngineFrom = async (params: {
  sessionId: string;
  data: ISessionTranslateEngine;
}): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(
    `/translate/session/config/${params.sessionId}/engine/language/customer`,
    { ...params.data },
  );

  return serialize(data);
};
