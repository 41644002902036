import { apiClient, ClientResponse } from "../../apiClient";

interface RequestParams {
    exist: boolean
    tenantName: string
}

interface ApiResp extends ClientResponse {
    data: RequestParams;
}
interface Serialize {
    (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
    // TODO: 处理data

    return resp;
};

// 用户是否存在
export const fetchUserExist = async (res): Promise<ApiResp> => {
    const { data } = await apiClient.post<ApiResp>(`/user/exist`, res);

    return serialize(data);
};
