import { FC, useState } from 'react'
import { Button, CardContent, cn } from '@brisk/ui'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string } from 'zod'
import { useMutation } from '@tanstack/react-query'
import { ReloadIcon } from '@radix-ui/react-icons'
import { ReactSVG } from 'react-svg'
import captacha from '../../../assets/svg/captacha.svg'
import { message } from '../../message'
import { inputIconClassName } from '../../../utils/consts'
import { useCaptchaCountdown } from '../../../hooks/useCaptchaCountdown'
import { NoFocusInput } from '../../../pages/sign/components/NoFocusInput'
import { ClientResponse } from '../../../http-client/httpClient'
import { PhoneInput } from '../../phone-input'
import { fetchEditPhoneCaptcha } from '../../../api/setting/editPhoneCaptcha'
import { AccountType } from '../../../api/model'
import { TagsTitle } from '../index'
import { useTranslation } from 'react-i18next'
import GraphicalVerification from '../../../pages/sign/components/graphicalVerification/graphicalVerification'
import { useGraphicalVerification } from '../../../pages/sign/hooks/graphicalVerification'
import { trackDataType } from '../../../pages/sign/InputAccount'
import { fetchLoginCaptchaCheck } from '@brisk/api'
import { trimAll } from 'css-sdk/src/utils'

type IFormInput = {
  captcha: string
}

const defaultFormValues: IFormInput = {
  captcha: ''
}

export const PhoneEdit: FC<{ onsubmit: (any) => void; onCancel: () => void }> = ({
  onsubmit,
  onCancel
}) => {
  // const redirect = searchParams.get('redirect')
  const [phoneCode, setPhoneCode] = useState('1')
  const [account, setAccount] = useState({ value: '', len: 1 })
  const { isCounting, startCount, count, isSentCaptcha, reset } = useCaptchaCountdown()
  const { t } = useTranslation()
  const {
    captchaStatus,
    setCaptchaStatus,
    status,
    setStatus,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    callOpen,
    initFetchSliderCaptcha
  } = useGraphicalVerification()
  const schema = object({
    captcha: string().min(6, t('login.pleaseenterverificationcode'))
  })
  const { mutateAsync: fetchAsyncLoginCaptchaCheck } = useMutation({
    mutationFn: fetchLoginCaptchaCheck
  })
  const { mutateAsync: maEditPhoneCaptchaCaptcha, isPending: isEditPhoneCaptcha } = useMutation({
    mutationFn: fetchEditPhoneCaptcha
  })
  // 提交
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema)
  })
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    onsubmit({ type: 'phone', value: trimAll(account.value), captcha: formData.captcha })
    // let data: Resp
    // if (!data.data.tempToken) {
    //     message('验证码错误，请重新输入', 'error')
    //     return
    // }
    //
    // setTempToken(data.data.tempToken)
  }
  // 刷新
  const trackRefresh = async () => {
    if (callOpen) {
      await initFetchSliderCaptcha()
      return
    }
    setCallOpen(true)
    await initFetchSliderCaptcha()
  }
  // 手机验证码调用
  const onCaptchaSuccess = (tackData: trackDataType) => {
    handleClickSendCaptcha(tackData)
  }
  // 获取验证码
  const handleClickSendCaptcha = async (tackData?: trackDataType): Promise<void> => {
    if (isCounting) {
      return
    }
    let tokens
    let resp: ClientResponse
    if (!captchaStatus) {
      setStatus('initialise')
      trackRefresh()
      setCaptchaStatus(true)
    } else {
      try {
        const data = await fetchAsyncLoginCaptchaCheck({
          phone: '+' + phoneCode + trimAll(account.value),
          ...tackData
        })
        if (data?.code === 10012) {
          setStatus('refreshError')
          message(data?.msg, 'error')
        }
        if (data?.code === 10000) {
          setStatus('success')
          tokens = data?.data
          setTimeout(() => {
            setCallOpen(false)
          }, 500)
          // eslint-disable-next-line prefer-const
          resp = await maEditPhoneCaptchaCaptcha({
            account: '+' + phoneCode + trimAll(account.value),
            type: AccountType.SMS,
            token: tokens
          })
          if (resp.code === 10012) {
            setStatus('initialise')
            setCaptchaStatus(false)
          }
          if (resp.code !== 10000) {
            message(resp.msg, 'error')
            reset()
            return
          }
          startCount()
        }
      } catch (e) {
        setCaptchaStatus(false)
      }
    }
  }

  /**
   * onPhoneInput 监听输入框
   * @param value
   */
  function onPhoneInput(value: string) {
    setAccount({
      value: value,
      len: value.length
    })
  }
  // 关闭滑动弹窗
  const handleFail = () => {
    setCallOpen(false)
    setCaptchaStatus(false)
  }
  return (
    <>
      <GraphicalVerification
        captchaStatus={true}
        onCaptchaSuccess={onCaptchaSuccess}
        onFail={handleFail}
        onRefresh={trackRefresh}
        callOpen={callOpen}
        {...backgroundImage}
        {...jigsawImage}
        status={status}
      />
      <CardContent className="flex-1 p-0 justify-start flex flex-col">
        {/*<TagsTitle title={t('accountInformation.mobilephonenumbernotentered')} />*/}
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <div className="grid gap-2 h-[3.25rem]">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  selectedCountryFun={(value) => setPhoneCode(value)}
                  onPhoneInput={onPhoneInput}
                  {...field}
                  required
                  className="h-full"
                />
              )}
            />
          </div>
          <Controller
            name="captcha"
            control={control}
            render={({ field }) => (
              <div className="border w-full radios rounded-sm flex relative h-[3.25rem]">
                <ReactSVG src={captacha} className={inputIconClassName} />
                <NoFocusInput
                  maxLength={6}
                  placeholder={t('login.pleaseenterverificationcode')}
                  type="text"
                  pattern="^(\+)?[0-9\s]*$"
                  className={cn(
                    'flex-1 h-full pl-9 text-sm border-none outline-none rounded-md',
                    errors.captcha ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring'
                  )}
                  {...field}
                />
                <Button
                  variant="link"
                  type="button"
                  className={cn(
                    'h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline',
                    account.len > 1 ? '' : 'text-[#2460FF] opacity-40 pointer-events-none'
                  )}
                  onClick={handleClickSendCaptcha}
                  disabled={isEditPhoneCaptcha}
                >
                  {isEditPhoneCaptcha && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}

                  <span className="absolute left-0 text-border">|</span>
                  <span>
                    {isCounting
                      ? `${count}s`
                      : isSentCaptcha
                        ? t('login.resend')
                        : t('login.sendverificationcod')}
                  </span>
                </Button>
              </div>
            )}
          />
          {errors.captcha && (
            <p className="bottom-8 text-lingheError text-sm">
              {t('login.verificationcodeincorrect')}
            </p>
          )}
          <footer className="w-full pt-3 inline-flex gap-2 footer-button">
            <button
              onClick={onCancel}
              type="button"
              className="text-sm rounded border-[#ECECE] cursor-pointer border ml-auto text-[#777A8A]"
            >
              {t('common.cancel')}
            </button>
            <button
              type="submit"
              className={cn(
                'rounded text-[#FFFFFF] text-sm',
                account.len <= 1
                  ? 'pointer-events-none bg-[#A7BFFF]'
                  : 'cursor-pointer bg-[#247CFF]'
              )}
            >
              {t('common.complete')}
            </button>
          </footer>
        </form>
      </CardContent>
    </>
  )
}
