import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { ISaveSourceConfig } from './fetchSaveSourceConfig'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 保存留资配置
 * @param param
 * @returns
 */
export const fetchSaveSource = async (param: ISaveSourceConfig): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/config/collect-info`, param)

  return serialize(data)
}
