import { create } from 'zustand'
import { ISessionTranslateConfig } from '@brisk/api'

export type State = {
  sessionTranslateConfig: ISessionTranslateConfig
}

export type Action = {
  setSessionTranslateConfig: (sessionTranslateConfig: ISessionTranslateConfig) => void
}

export const useSessionTranslateConfigStore = create<State & Action>((set) => ({
  sessionTranslateConfig: {},
  setSessionTranslateConfig: (sessionTranslateConfig: ISessionTranslateConfig): void => {
    set({ sessionTranslateConfig })
  }
}))
