import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * TranslateSessionConfigVO
 */
export interface ISessionTranslateConfig {
  /**
   * 翻译用户消息语言启用状态 0：关闭，1:启用
   */
  customerEnableStatus?: number;
  /**
   * 翻译用户语言
   */
  customerLanguage?: string;
  /**
   * 翻译用户语言code
   */
  customerLanguageCode?: string;
  /**
   * 接收消息通用语言码
   */
  customerUniversalLanguage?: string;
  /**
   * 会话id
   */
  sessionId?: string;
  /**
   * id
   */
  sessionTranslateConfigId?: number;
  /**
   * 翻译引擎
   */
  translateEngine?: string;
  /**
   * 翻译用户语言状态 0：关闭，1:启用
   */
  userEnableStatus?: number;
  /**
   * 翻译用户端语言
   */
  userLanguage?: string;
  /**
   * 翻译用户端语言code
   */
  userLanguageCode?: string;
  /**
   * 发送消息通用语言码
   */
  userUniversalLanguage?: string;
}

interface ApiResp extends ClientResponse {
  data: ISessionTranslateConfig;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 获取会话配置
 * @param sessionId
 * @returns
 */
export const fetchSessionTranslateConfig = async (
  sessionId: string,
): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(
    `/translate/session/config/${sessionId}`,
  );

  return serialize(data);
};
