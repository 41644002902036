import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * CollectInfoConfigVO，留资配置
 */
export interface ISaveSourceConfig {
  /**
   * 留资配置开关，1：开启状态，0：关闭状态
   */
  enable?: number
  /**
   * 表单设置
   */
  formList?: ISaveSourceForm[]
  /**
   * 引导语
   */
  guiding?: string
  /**
   * 是否强制，1：强制，0：非强制
   */
  mandatory?: number
}

/**
 * Form，表单设置
 */
export interface ISaveSourceForm {
  /**
   * 是否必填
   */
  required?: boolean
  /**
   * 类型: NAME:名称，PHONE:手机号，EMAIL:邮箱，COMPANY_NAME:公司名称
   */
  type?: string
}

interface ApiResp extends ClientResponse {
  data: ISaveSourceConfig
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取留资配置
 * @returns
 */
export const fetchSaveSourceConfig = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/config/collect-info`)

  return serialize(data)
}
