/**
 * utils
 *
 * @desc 描述：工具方法
 */

/* =================================================== */

/**
 * 提取元组结构中的值
 * @desc [err, result]，提取 result 的类型
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ExtractedResults<T> = T extends [infer _E, infer U]
  ? U extends null
    ? never
    : U
  : never

/**
 * 定义 ipcRenderer.invoke 返回数据结构
 * @desc 约定类型
 */
export type InvokeResult<T = unknown> =
  | [invokeError: IAppUpdateError, invokeResult: null]
  | [invokeError: null, invokeResult: T]

/**
 * ipcRenderer.invoke 函数类型
 * @desc 约定类型
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InvokeFn = (...args: any) => Promise<InvokeResult<any>>

/**
 * ipcRenderer.invoke 函数类型转换
 * @desc invoke 值：Promise<[err, result]> 元组类型，转换成 Promise<result>
 */
type TransformInvoke<T extends InvokeFn> = (
  ...args: Parameters<T>
) => Promise<ExtractedResults<Awaited<ReturnType<T>>>>

/**
 * 转换 invoke 的 HOF
 */
export function transformInvoke<T extends InvokeFn>(promiseFn: T): TransformInvoke<T> {
  return async (...args) => {
    const [err, result] = await promiseFn(...args)
    if (err) throw new AppUpdateError(err)

    return result
  }
}

/**
 * app 更新自定义错误
 * @desc Preload 还有一份拷贝
 * @desc 因为 Preload 和 渲染线程 Ts 隔离，所以需要重新定义
 */
export interface IAppUpdateError {
  message: string

  /**
   * 错误类型
   * @desc 应该是 main/appUpdate/appUpdateError 中的 AppUpdateErrEnum
   * @desc 但是 Ts 无法正确解析，所以这里使用 string
   */
  type: string
}

/**
 * app 更新错误
 * @desc 基于主线程加工的错误
 */
export class AppUpdateError extends Error {
  name = 'AppUpdateError'
  type: string

  constructor({ message, type }: IAppUpdateError) {
    super(message)

    this.type = type
  }
}

/**
 * 判断是否是取消错误
 * @desc 应该是 main/appUpdate/appUpdateError 中的 AppUpdateErrEnum
 */
export function isAbortError(val: unknown): val is AppUpdateError {
  // @ts-expect-error 但是 Ts 无法正确解析，所以这里使用 string
  return val?.type === 'DownloadCancel'
}
