import { FC } from 'react'
import { homeCardInterface } from './type'
import { useNavigate } from 'react-router-dom'
import './style/index.less'

const HomeCard: FC<homeCardInterface> = ({ icon, iconTitle, path, router, title, message }) => {
  const navigate = useNavigate()
  function onNavigate() {
    if (path) {
      window.open(path, '_blank')
    } else {
      navigate(router)
    }
  }
  return (
    <section className="border-2 homeCard-hover border-[#FFF] medium-height rounded-2xl p-8 relative sm:w-[calc(50%-0.5rem)] smXl:w-[calc(25%-0.8rem)]">
      <img src={icon} alt="images" className="w-10 h-10" />
      <h1 className="text-xl pt-8 pb-1">{title}</h1>
      <p className="text-sm w-[60%] text-[#777A8A]">{message}</p>
      <div
        className="absolute navigate bottom-8 cursor-pointer flex rounded border-[1px] border-[#247CFF] left-8 py-2.5 px-3"
        onClick={onNavigate}
      >
        <span className="text-sm pr-1 text-[#247CFF]">{iconTitle}</span>
        <div className="goinot"></div>
      </div>
    </section>
  )
}

export default HomeCard
