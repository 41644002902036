import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { message } from '../components/message'

interface ErrorMessage {
  message?: string
}
export const useErrorHinter = (errors: (ErrorMessage | undefined)[]): void => {
  useEffect(() => {
    if (isEmpty(errors)) {
      return
    }

    for (const error of errors) {
      if (!error?.message) {
        continue
      }

      message(error.message!, 'error')
      break
    }
  }, [...errors])
}
