import { EmployeeStatus } from "../model";
import {
  apiClient,
  ApiReqFn,
  ApiRespSerializeFn,
  ClientResponse,
} from "../../apiClient";

interface RequestBody {
  status: EmployeeStatus;
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return resp;
};
// 确认加入租户
export const mutationChangeStatus: ApiReqFn<RequestBody, Data> = async (
  payload,
): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(`/user/switch-status`, {
    ...payload,
  });

  return serialize(data);
};
