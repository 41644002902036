import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import enTranslation from "./locales/en.json";
import zhCNTranslation from "./locales/zh-CN.json";
import zhTWTranslation from "./locales/zh-TW.json";
import arTranslation from "./locales/ar.json";
interface CustomLanguageDetector {
  type: "languageDetector";
  async: boolean;
  detect: (callback: (lng: string) => void) => void;
  init: () => void;
  cacheUserLanguage: (lng: string) => void;
}

// 自定义语言检测器
const customLanguageDetector: CustomLanguageDetector = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    const savedLng = localStorage.getItem("i18nextLng");

    if (savedLng) {
      if (savedLng === "zh" || savedLng === "zh-CN") {
        callback("zh-CN");
      } else if (savedLng === "zh-TW") {
        callback("zh-TW");
      } else {
        callback(savedLng);
      }
    } else {
      // 默认语言
      callback("en");
    }
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem("i18nextLng", lng);
  },
};

// 初始化 i18n
i18n
  .use(HttpBackend) // 使用 HttpBackend 加载翻译文件
  .use(customLanguageDetector) // 使用自定义语言检测器
  .use(initReactI18next) // 将 i18n 绑定到 react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      "zh-CN": {
        translation: zhCNTranslation,
      },
      "zh-TW": {
        translation: zhTWTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    fallbackLng: "en", // 默认语言
    debug: true, // 是否开启调试模式
    interpolation: {
      escapeValue: false, // React 已经默认进行了转义处理
    },
    preload: ["en", "zh-CN", "zh-TW"], // 预加载的语言
  });

export default i18n;
