import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 删除标签
 * @param params
 * @returns
 */
export const fetchDelTag = async (id: number | string): Promise<ApiResp> => {
  const { data } = await httpClient.delete<ApiResp>(`/bg/tag/${id}`)

  return serialize(data)
}
