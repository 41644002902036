// 终端类型
export enum TerminalType {
  //安卓，
  ANDROID = 'ANDROID',
  // 苹果
  IOS = 'IOS',
  // 浏览器
  BROWSER = 'BROWSER',
  // pc桌面应用
  PC = 'PC'
}

export enum AccountType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  /** 企业号 */
  ENTERPRISE = 'ENTERPRISE',
  /** IP地址 */
  IPADRESS = 'IPADRESS',
  PHONE = 'PHONE',
  IMAGESCAPTCHA = 'IMAGESCAPTCHA'
}

export enum SignInType {
  // 短信验证码登录
  SMS = 'SMS',
  // 邮箱验证码登录
  PASSWORD = 'PASSWORD',
  // 邀请码登录
  INVITE = 'INVITE',
  // 扫码登录
  QRCODE = 'QRCODE'
}

export enum SessionStatus {
  // TODO:
  // 状态：0-待分配(未分配) 1-已分配(我的) 10-完结(历史会话)
  UNASSIGNED = 0,
  ASSIGNED = 1,
  FINISHED = 10
}

export interface SessionInfo {
  sessionId: number
  channelId: number
  channelName: string
  imGroupId: number
  customerId: number
  customerName: string
  customerAvatar: string
  startTime: string
  status: SessionStatus
  orgId: number
  orgName: string
  userId: number
  lastTalkTime: string
}

export interface Pagination {
  // list: T[]
  total: number
  size: number
  current: number
  // optimizeCountSql: {}
  // searchCount: {}
  pages: number
}
export enum EmployeeStatus {
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE'
}

/**
 * 0-待分配(未分配) 1-已分配(我的) 10-完结(历史会话)
 */
export enum ChatListStatus {
  UNASSIGNED = 0,
  ASSIGNED = 1,
  FINISHED = 10
}

/**
 * 文件用处 快捷短语:PRESET_MESSAGE,聊天文件:IM_MESSAGE
 */
export enum FileType {
  PRESET_MESSAGE = 'PRESET_MESSAGE',
  IM_MESSAGE = 'IM_MESSAGE'
}

/**
 * 聊天允许发送的文件类型。 "doc", "docx", "docm", "dotx", "dotm", "xls", "xlsx", "xlsm", "xltx", "xltm", "csv", "pdf", "ppt", "pptx", "pptm", "potx", "potm", "mp3", "txt", "xml", "jpg", "png", "webp", "bmp", "jpeg"
 */
export enum FileExt {
  DOC = 'doc',
  DOCX = 'docx',
  DOCM = 'docm',
  DOTX = 'dotx',
  DOTM = 'dotm',
  XLS = 'xls',
  XLSX = 'xlsx',
  XLSM = 'xlsm',
  XLTX = 'xltx',
  XLTM = 'xltm',
  CSV = 'csv',
  PDF = 'pdf',
  PPT = 'ppt',
  PPTX = 'pptx',
  PPTM = 'pptm',
  POTX = 'potx',
  POTM = 'potm',
  MP3 = 'mp3',
  TXT = 'txt',
  XML = 'xml',
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
  BMP = 'bmp',
  JPEG = 'jpeg'
}
