import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { User } from '../../model/data/user'

interface RequestParams {
  tempToken: string
}

interface ApiResp extends ClientResponse {
  data: User
}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  // TODO: 处理data
  return resp
}

// 获取图片验证码
export const fetchScanResult = async ({ tempToken }: RequestParams): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/login/qr-code/result/${tempToken}`, {})

  return serialize(data)
}
