import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { MultipartOptions, PutOptions, SignedUrlOptions, UploadConfig } from './type'
import { Upload } from '@aws-sdk/lib-storage'
import { DEFAULT_PARALLEL, DEFAULT_PART_SIZE } from './util'
import { message } from '@renderer/components/message'

export class S3 {
  private options: UploadConfig
  client: S3Client
  constructor(options: UploadConfig) {
    this.options = options
    this.createClient()
  }
  private createClient(): void {
    this.client = new S3Client({
      endpoint: this.options.endpoint,
      region: this.options.region,
      bucketEndpoint: true,
      credentials: {
        sessionToken: this.options.securityToken,
        accessKeyId: this.options.accessKeyId,
        secretAccessKey: this.options.accessKeySecret
      }
    })
  }

  private async refreshToken<T = unknown>(error: Error, callback: () => Promise<T>): Promise<T> {
    if (error.name === 'InvalidTokenId') {
      try {
        const result = await this.options.refreshSTSToken?.()

        if (result) {
          this.options = { ...this.options, ...result }

          this.destroy()
          this.createClient()
          return await callback?.()
        }
      } catch (error) {
        console.log('s3Client refresh token fail', error)
      }
    }
    return Promise.reject(error)
  }
  getObjectUrl(filePath: string): string {
    const { endpoint, bucket } = this.options
    return `${endpoint.replace(/(.*)\/$/, '$1')}/${bucket}/${filePath.replace(/^\/(.*)/, '$1')}`
  }
  async getSignedUrl(
    filePath: string,
    { unhoistableHeaders, expires = 3600 }: SignedUrlOptions = {}
  ): Promise<string> {
    return await getSignedUrl(
      this.client,
      new GetObjectCommand({
        Bucket: this.options.bucket,
        Key: filePath
      }),
      {
        expiresIn: expires,
        unhoistableHeaders
      }
    )
  }
  async multipartUpload(
    filePath: string,
    file: File,
    options: MultipartOptions,
    noRefreshToken = false
  ): Promise<string> {
    try {
      const upload = new Upload({
        client: this.client,
        params: {
          Bucket: this.options.bucket,
          Key: filePath,
          Body: file
        },
        queueSize: options?.parallel || DEFAULT_PARALLEL,
        partSize: options?.partSize || DEFAULT_PART_SIZE
      })

      upload.on('httpUploadProgress', ({ loaded = 0, total = 0 }) => {
        options?.progress?.(loaded / total)
      })
      // export abort function
      options?.client?.(upload)

      const { Location } = await upload.done()
      return Location
    } catch (error) {
      if (noRefreshToken) {
        return Promise.reject(error)
      }
      return await this.refreshToken<string>(error, () =>
        this.multipartUpload(filePath, file, options, true)
      )
    }
  }
  async put(
    filePath: string,
    file: File,
    options?: PutOptions,
    noRefreshToken = false
  ): Promise<string> {
    try {
      await this.client.send(
        new PutObjectCommand({
          ContentType: file.type,
          Bucket: this.options.endpoint + '/' + this.options.bucket,
          BucketKeyEnabled: true,
          Key: filePath,
          Body: file
        })
      )
      options?.progress?.(1)
      return this.getObjectUrl(filePath)
    } catch (error) {
      message('图片发送失败', 'error')
      if (noRefreshToken) {
        return Promise.reject(error)
      }
      return await this.refreshToken<string>(error, () => this.put(filePath, file, options, true))
    }
  }
  destroy(): void {
    this.client?.destroy()
  }
}
