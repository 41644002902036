/**
 * putReceiveConfig
 *
 * @desc 描述：接收消息翻译语言配置
 * @desc 描述：接口文档名称 - 接收消息翻译语言配置
 * @desc 描述：/translate/config/engine/language/customer
 */

/* =================================================== */

import type { ITranslateConfig } from './types'
import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

export const API_PATH = '/translate/config/engine/language/customer'

/**
 * 接收消息翻译启用、禁用
 */
export async function putReceiveConfig(code: string): Promise<ITranslateConfig> {
  const res = await httpClient.put(API_PATH, { customerLanguageCode: code })

  return get(res, 'data.data')
}
