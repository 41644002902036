import { FC, memo } from 'react'
import { ReactSVG } from 'react-svg'
import keyWordsTips from '../../../assets/svg/keyWordsTips.svg'
import closeCustomerTips from '../../../assets/svg/closeCustomerTips.svg'
import {useTranslation} from "react-i18next";

interface KeyWordsTipsTipsProps {
  onClose?: () => void
}

const KeyWordsTips: FC<KeyWordsTipsTipsProps> = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full bg-[#F8F9FA] relative p-8 rounded-lg flex items-center gap-20">
      <div className="flex flex-col gap-2 [&_p]:text-sm [&_p]:text-lingheTextGray">
        <div className="mb-2 text-xl font-semibold">{t('common.WarmTips')}</div>
        <p>{t('knowledgeBase.Afterconfiguringkeywordresponses')}</p>
        <p>{t('knowledgeBase.examplekeyword')}</p>
        <p>{t('knowledgeBase.Visitormessagematchkeyword')}</p>
        <p>{t('knowledgeBase.Visitormessageshirsmatchkeyword')}</p>
      </div>
      <div className="flex-1 shrink-0 flex items-center justify-end pr-40">
        <ReactSVG src={keyWordsTips} />
      </div>

      <ReactSVG
        src={closeCustomerTips}
        className="absolute cursor-pointer top-8 right-8"
        onClick={() => {
          onClose && onClose()
        }}
      />
    </div>
  )
}

export default memo(KeyWordsTips)
