import { IParamForPage } from '@renderer/utils/consts'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * PageFaceBookBusinessVO
 */
export interface IFaceboockAccountResp {
  countId?: string
  current?: number
  pages?: number
  records?: FaceBookAccountBusinessVO[]
  searchCount?: Response
  size?: number
  total?: number
}

/**
 * FaceBookBusinessVO
 */
export interface FaceBookAccountBusinessVO {
  id: string | number
  /**
   * 账号id
   */
  accountId?: string
  /**
   * 账号名称
   */
  accountName?: string
  /**
   * 授权状态 0：无效 1有效
   */
  authState?: number
  /**
   * 授权时间
   */
  authTime?: Date
  /**
   * 已添加主页数量
   */
  businessPageNum?: number
  /**
   * 渠道编号
   */
  channelId?: number
  /**
   * configId
   */
  configId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 租户编号
   */
  tenantId?: number
  avatar?: string
}

interface ApiResp extends ClientResponse {
  data: IFaceboockAccountResp
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * facebook账号列表
 * @param param
 * @returns
 */
export const fetchFacebookAccountList = async (param: IParamForPage): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/facebook/page`, param)

  return serialize(data)
}
