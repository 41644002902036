import { FC, useRef, useState } from 'react'
import { CardContent, cn } from '@brisk/ui'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PasswordEditInput } from './passwordEditInput'
import { object, string } from 'zod'
import { passwordRules } from '../../../utils/consts'
import { useTranslation } from 'react-i18next'

type IFormData = {
  originalPassword: string
  newPassword: string
  confirmPassword: string
}
const defaultFormValues: IFormData = {
  originalPassword: '',
  newPassword: '',
  confirmPassword: ''
}
/**
 * 当前页rem适配
 * @param pxValue
 * @param baseFontSize
 * @returns
 */
const pxToRem = (pxValue: number, baseFontSize: number = 14): string => {
  return `${(pxValue / baseFontSize).toFixed(2)}rem`
}
export const PasswordEdit: FC<{ onsubmit: (any) => void; onCancel: () => void }> = ({
  onsubmit,
  onCancel
}) => {
  const { t } = useTranslation()
  const schemaPassword = {
    originalPassword: string()
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters')),
    newPassword: string()
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters')),
    confirmPassword: string()
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters'))
  }
  const schema = object({
    ...schemaPassword
  })
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormData>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    shouldFocusError: true
  })
  const [inputData, setInputData] = useState(true) // 控制完成按钮状态
  const inputVal = useRef({ original: '', newp: '', confirm: '' }) // 记录输入框值
  /**
   * 提交完成
   * @param formData
   */
  const onSubmit: SubmitHandler<IFormData> = async (formData: IFormData) => {
    if (formData?.newPassword !== formData?.confirmPassword) {
      control.setError('confirmPassword', { message: t('login.twopasswordsaredifferent') })
      return
    }
    onsubmit({
      type: 'password',
      originalPassword: formData.originalPassword,
      newPassword: formData.newPassword
    })
  }

  /**
   * handleInput 监听输入框
   * @param event
   * @param type
   */
  function handleInput(event, type: string) {
    const val = event.target.value
    if (type === 'original') {
      recordsVal(val, inputVal.current.newp, inputVal.current.confirm)
      inputVal.current.original = val
    }
    if (type === 'new') {
      recordsVal(val, inputVal.current.original, inputVal.current.confirm)
      inputVal.current.newp = val
    }
    if (type === 'confirm') {
      recordsVal(val, inputVal.current.original, inputVal.current.newp)
      inputVal.current.confirm = val
    }
  }

  /**
   * recordsVal 判断当前输入框是否有值，输入框不能为空
   * @param val
   * @param f_val
   * @param t_val
   */
  function recordsVal(val: string, f_val: string, t_val: string) {
    if (val && f_val && t_val) {
      return setInputData(false)
    }
    setInputData(true)
  }
  return (
    <>
      <CardContent className="flex-1 justify-start flex flex-col p-0">
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
          <div>
            <Controller
              name="originalPassword"
              control={control}
              render={({ field }) => (
                <div className="relative" style={{ marginTop: pxToRem(18) }}>
                  <PasswordEditInput
                    onInput={(event) => handleInput(event, 'original')}
                    placeholder={t('accountInformation.pleaseenteroriginalpassword')}
                    className="pl-4 pr-4"
                    style={{ height: pxToRem(52) }}
                    required
                    {...field}
                  />
                </div>
              )}
            />
            {errors.originalPassword && (
              <p className="mt-2 text-lingheError text-sm">{errors.originalPassword.message}</p>
            )}
          </div>
          <div>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <div className="relative">
                  <PasswordEditInput
                    onInput={(event) => handleInput(event, 'new')}
                    placeholder={t('accountInformation.pleaseenteryournewpassword')}
                    className="pl-4 pr-4"
                    style={{ height: pxToRem(52) }}
                    required
                    {...field}
                  />
                </div>
              )}
            />
            {errors.newPassword && (
              <p className="mt-2 text-lingheError text-sm">{errors.newPassword.message}</p>
            )}
          </div>
          <div>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <div className="relative">
                  <PasswordEditInput
                    onInput={(event) => handleInput(event, 'confirm')}
                    placeholder={t('accountInformation.pleaseconfirmpassword')}
                    className="pl-4 pr-4"
                    style={{ height: pxToRem(52) }}
                    required
                    {...field}
                  />
                </div>
              )}
            />
            {errors.confirmPassword && (
              <p className="mt-2 text-lingheError text-sm">{errors.confirmPassword.message}</p>
            )}
          </div>
          <footer className="w-full pt-3 inline-flex gap-2 footer-button">
            <button
              onClick={onCancel}
              type="button"
              className="text-sm rounded border-[#ECECE] cursor-pointer border ml-auto text-[#777A8A]"
            >
              {t('common.cancel')}
            </button>
            <button
              className={cn(
                'rounded text-[#FFFFFF] text-sm',
                inputData ? 'pointer-events-none bg-[#A7BFFF]' : 'cursor-pointer bg-[#247CFF]'
              )}
              type="submit"
            >
              {t('common.complete')}
            </button>
          </footer>
        </form>
      </CardContent>
    </>
  )
}
