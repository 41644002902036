import {
  ApiReqFn,
  ApiRespSerializeFn,
  ClientResponse,
  httpClient
} from '../../http-client/httpClient'
import { AccountType, TerminalType } from '../model'
import { trimAll } from '../../utils'

interface RequestBody {
  /**
   * 账号,可以是用户名，邮箱，手机号
   */
  account: string
  /**
   * 手机or邮箱验证码
   */
  captcha: string
  /**
   * 登录终端类型 ANDROID:安卓，IOS:苹果，BROWSER:浏览器，PC:pc桌面应用
   */
  terminalType: TerminalType
  /**
   * 类型 SMS:短信 EMAIL:邮箱
   */
  type: AccountType
}

interface ApiResp extends ClientResponse {
  data: string
}
interface Data extends ClientResponse {
  data: {
    tempToken: string
  }
}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
  return {
    ...resp,
    data: {
      tempToken: resp.data
    }
  }
}
// 未选择租户时，通过验证码临时登录，返回的token可用于创建租户
export const mutationTempSignInByCaptcha: ApiReqFn<RequestBody, Data> = async (payload) => {
  const { data } = await httpClient.post<ApiResp>(`/login/temporary/login-captcha`, {
    ...payload,
    account: trimAll(payload.account)
  })

  return serialize(data)
}
