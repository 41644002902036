import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * ReceptionCollectionVO
 */
export interface IReceptionEchartsData {
  /**
   * 新访客统计日数据
   */
  countNewNumCollection?: IReceptionEcharts[];
  /**
   * 访客统计日数据
   */
  countNumCollection?: IReceptionEcharts[];
  /**
   * 常客统计日数据
   */
  countOldNumCollection?: IReceptionEcharts[];
}

/**
 * ChartNumVO
 */
export interface IReceptionEcharts {
  /**
   * x轴数据
   */
  keyStr?: string;
  /**
   * 数量
   */
  num?: number;
  dateTimes?: number;
}

interface ApiResp extends ClientResponse {
  data: IReceptionEchartsData;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 接待访客数柱状图
 * @param param
 * @returns
 */
export const fetchReceptionEcharts = async (
  param: IDataCustomerRequestBody,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/customer/statistics/reception/chart`,
    param,
  );

  return serialize(data);
};
