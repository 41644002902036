import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * UserDetailVO
 */
export interface IUserDtailInfoVO {
  /**
   * 账号
   */
  account?: string
  /**
   * 客服名称
   */
  nickname?: string
  /**
   * 所属角色
   */
  roleIdList?: number[]
  /**
   * 所属团队
   */
  teamIdList?: number[]
  /**
   * 用户编号
   */
  userId?: number
}

interface ApiResp extends ClientResponse {
  data: IUserDtailInfoVO
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取人员详情
 * @param userId
 * @returns
 */
export const fetchUserDetailInfo = async (userId: string | number): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/user/detail/${userId}`)

  return serialize(data)
}
