import { FC } from 'react'
import HeaderNav from './components/HeaderNav'
import { Button, CardContent, PasswordInput } from '@brisk/ui'
import { useNavigate } from 'react-router-dom'
import { LockKeyhole } from 'lucide-react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { inconLeftClassName } from './CompanyInfo'
import { object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSignStore } from '../../store/useSignStore'
import { mutationResetPassword } from '../../api'
import { useMutation } from '@tanstack/react-query'
import { APP_ROUTER_PATH } from '@renderer/router'
import { message } from '../../components/message'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

interface IFormInput {
  password: string
  confirmPassword: string
}

const defaultFormValues: IFormInput = {
  password: '',
  confirmPassword: ''
}
export const ResetPassword: FC = () => {
  const navigate = useNavigate()
  const { tempToken, currentTenant } = useSignStore()
  const { t } = useTranslation()
  const schemaPassword = {
    password: string()
      .min(8, i18next.t('common.passwordcontainscharacters'))
      .max(16, i18next.t('common.passwordcontainscharacters')),
    confirmPassword: string()
      .min(8, i18next.t('common.passwordcontainscharacters'))
      .max(16, i18next.t('common.passwordcontainscharacters'))
  }

  const schema = object({
    ...schemaPassword
  })

  const { mutateAsync: mutateAsyncResetPassword } = useMutation({
    mutationFn: mutationResetPassword
  })
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    shouldFocusError: true
  })

  // useErrorHinter([errors?.password, errors?.confirmPassword])

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    if (formData?.password !== formData?.confirmPassword) {
      control.setError('confirmPassword', {
        message: t('login.inconsistentpasswordsenteredtwice')
      })
      return
    }
    const resp = await mutateAsyncResetPassword({
      tempToken: tempToken!,
      password: formData.password
    })

    if (resp.code !== 10000) {
      message(t('common.failedchangepassword'), 'error')
      return
    }
    navigate(APP_ROUTER_PATH.SIGNIN_BY_PASSPORT)
  }

  return (
    <>
      <HeaderNav title={t('login.resetPassword')} />
      <CardContent className="flex-1 justify-start flex flex-col">
        <p className="text-sm text-lingheGray text-center mb-4">
          {t('common.passwordcontainscharacters')}
        </p>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 mb-10">
            <div className="">
              <div className="relative">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <>
                      <PasswordInput
                        placeholder={t('login.pleaseenterpassword')}
                        className="pl-10 pr-10 h-12"
                        {...field}
                      />
                    </>
                  )}
                />
                <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
              </div>
              {errors.password && (
                <p className="mt-2 text-lingheError text-sm">{errors.password.message}</p>
              )}
            </div>
            <div>
              <div className="relative">
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <PasswordInput
                      placeholder={t('accountInformation.pleaseconfirmpassword')}
                      className="pl-10 pr-10 h-12"
                      {...field}
                    />
                  )}
                />

                <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
              </div>
              {errors.confirmPassword && (
                <p className="mt-2 text-lingheError text-sm">{errors.confirmPassword.message}</p>
              )}
            </div>
          </div>
          <Button type="submit" className="w-full mt-4 h-12">
            {t('login.next')}
          </Button>
        </form>
      </CardContent>
    </>
  )
}
