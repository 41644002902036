import { FC, memo, useEffect } from 'react'
// import NavBodyLayout from '../Components/NavBodyLayout'
// import newUserSteps from '@renderer/assets/svg/newUserSteps.svg'
// import userStep01 from '@renderer/assets/svg/userStep01.svg'
// import userStep02 from '@renderer/assets/svg/userStep02.svg'
// import userStep03 from '@renderer/assets/svg/userStep03.svg'
// import userStep04 from '@renderer/assets/svg/userStep04.svg'
// import iconWin from '@renderer/assets/svg/iconWin.svg'
// import iconMac from '@renderer/assets/svg/iconMac.svg'
// import iconAndroid from '@renderer/assets/svg/iconAndroid.svg'
// import iconCheckQr from '@renderer/assets/svg/iconCheckQr.svg'
// import androidDowload from '@renderer/assets/imgs/androidDowload.png'
// import iOSDownload from '@renderer/assets/imgs/iOSDownload.png'
import left_homePage from '../../../assets/imgs/left_homePage.png'
import right_homePage from '../../../assets/svg/right_homePage.svg'
import members from '../../../assets/imgs/maskgroup1.png'
import homeFooter from '../../../assets/svg/homeFooter.svg'
import informationchannel from '../../../assets/imgs/maskgroup2.png'
import dialogueChatPlace from '../../../assets/imgs/maskgroup3.png'
import conversationlimit from '../../../assets/imgs/maskgroup4.png'

import { ReactSVG } from 'react-svg'
// import { useNavigate } from 'react-router-dom'
// import { APP_ROUTER_PATH } from '@renderer/router'
// import { ManageInfoContext } from '../manager'
import { useTranslation } from 'react-i18next'
import HomeCard from './components/homeCard'
import { APP_ROUTER_PATH } from '../../../router'
import Loading from '../../../components/Loading'

// const linearStyle = `
//         linear-gradient(0deg, rgba(162, 205, 255, 0.4), rgba(162, 205, 255, 0.4)),
//         radial-gradient(47.58% 98.29% at 68.32% -29.49%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
//         radial-gradient(35.35% 87.71% at 4.58% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
//         radial-gradient(30.85% 34.19% at 0% 0%, rgba(55, 135, 255, 0.24) 0%, rgba(55, 135, 255, 0) 100%)
//       `

const index: FC = () => {
  // const { userHandle, setUserHandle } = useContext(ManageInfoContext)
  const { t } = useTranslation()

  return (
    <section className="w-full h-full">
      <div
        className="header-linear-gradient flex justify-between absolute left-0 top-0 w-full"
        style={{ background: 'linear-gradient(0deg, rgba(247, 248, 250, 0.00) 0%, #DCEAFF 100%)' }}
      >
        <img src={left_homePage} alt="background" />
        <ReactSVG src={right_homePage}></ReactSVG>
      </div>
      <div className="content-circle">
        <article className="absolute z-[1] top-12 sm:w-[calc(100%-0.8rem)] smXl:w-[calc(100%-0.8rem)]">
          <div className="pb-[2.5rem] pl-8">
            <h1 className="text-4xl font-normal pb-6">👋</h1>
            <h2 className="text-[1.75rem] pb-3">{t('managerHomePage.Hellowelcome')}</h2>
            <p className="text-base">{t('managerHomePage.signingmakestheexperienceenjoyable')}</p>
            <p className="text-base">{t('managerHomePage.Inviteyourteammembers')}</p>
          </div>
          <div
            className="flex gap-[0.8rem] pr-4"
            style={{
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignContent: 'space-between'
            }}
          >
            <HomeCard
              iconTitle={t('managerHomePage.Invitedmembers')}
              router={APP_ROUTER_PATH.MANAGER_PERSONMANAGE}
              icon={members}
              title={t('managerHomePage.readytotalktoyourcustomers')}
              message={t('managerHomePage.AddMessagingChannels')}
            />
            <HomeCard
              iconTitle={t('managerHomePage.AddChannel')}
              router={APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_CHAT}
              icon={informationchannel}
              title={t('managerHomePage.Connectthevariouschannelsthrough')}
              message={t('managerHomePage.Setlimitonhowmanymembers')}
            />
            <HomeCard
              iconTitle={t('managerHomePage.SettingCaps')}
              router={APP_ROUTER_PATH.MANAGER_AGENT}
              icon={conversationlimit}
              title={t('managerHomePage.Setlimithowmanymembers')}
              message={t('managerHomePage.Whenyourteammembers')}
            />
            <HomeCard
              iconTitle={t('managerHomePage.DownloadApps')}
              path={'https://www.aiochat.im/downloads'}
              icon={dialogueChatPlace}
              title={t('managerHomePage.Allyourconversationalchatsinoneplace')}
              message={t('managerHomePage.Receiveandviewallofyourcustomer')}
            />
          </div>
        </article>
      </div>
      <div className="footer-linear-gradient w-full h-[22rem] absolute bottom-0">
        <img src={homeFooter} alt="footer" />
      </div>
    </section>
  )
}

export default memo(index)
