import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  Button,
  Input,
  Switch
} from '@brisk/ui'
import { FC, memo, useEffect, useRef } from 'react'
import close from '../../../assets/svg/close.svg'
import { ReactSVG } from 'react-svg'
import { Controller, useForm } from 'react-hook-form'
import { getNodeTextContent, replaceImgWithAlt } from '@renderer/utils'
import { WelcomeContentsVO } from '@renderer/api/manage/fetchWelcomConfig'
import { fetchAddWelcomWords } from '@renderer/api/manage/fetchAddWelcomWords'
import { useMutation } from '@tanstack/react-query'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import { fetchEditWelcomWords } from '@renderer/api/manage/fetchEditWelcomWords'
import TextareaWithEmoji, { ITextareaWithEmojiRef } from './TextareaWithEmoji'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { string, object } from 'zod'

export interface IAddWordsData {
  title: string
  open: boolean
  type: 'firstChat' | 'turnChat'
  oldData?: WelcomeContentsVO | null
}

interface IAddWordsModalProps {
  data: IAddWordsData
  onClose: () => void
  welcomeConfId: string | number
  onConfirm: () => void
}

interface IFormAddWords {
  title: string
  content: string
  state: 0 | 1 // 0:关闭 1:启用
}

const defaultValues: IFormAddWords = {
  title: '',
  content: '',
  state: 1
}

const AddWordsModal: FC<IAddWordsModalProps> = ({ data, onClose, welcomeConfId, onConfirm }) => {
  const textareaRef = useRef<ITextareaWithEmojiRef>(null)
  const { t } = useTranslation()
  const getSchema = () => {
    return object({
      title: string().min(1, t('autoReception.pleaseEnter'))
    })
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    setError,
    reset
  } = useForm<IFormAddWords>({
    defaultValues: defaultValues,
    resolver: zodResolver(getSchema())
  })

  const { mutateAsync: handleAddWelcomWords } = useMutation({
    mutationFn: fetchAddWelcomWords
  })

  const { mutateAsync: handleEditWelcomWords } = useMutation({
    mutationFn: fetchEditWelcomWords
  })

  const onSubmit = async (formData: IFormAddWords): Promise<void> => {
    const content = getNodeTextContent(textareaRef?.current?.preCurrent as HTMLElement)?.content
    if (!content) {
      setError('content', { message: t('autoReception.pleaseEnter') })
      return
    }
    const type = data.type === 'firstChat' ? 0 : 1
    const params: WelcomeContentsVO = {
      ...formData,
      content: replaceImgWithAlt(content),
      type,
      state: getValues('state')
    }
    if (data.oldData) {
      // 编辑
      try {
        const res = await handleEditWelcomWords({
          ...params,
          welcomeContentId: data?.oldData?.welcomeContentId || 0
        })
        if (res?.code === RESPONSE_CODE.success) {
          message(t('accountInformation.modifiedsuccessfully'), 'success')
          onConfirm && onConfirm()
        }
      } catch (error) {
        console.log('error', error)
      }
    } else {
      // 新增
      try {
        const res = await handleAddWelcomWords({ ...params, welcomeConfId })
        if (res.code === RESPONSE_CODE.success) {
          message(t('common.successAdd'), 'success')
          onConfirm && onConfirm()
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    handleClose()
  }

  const handleClose = (): void => {
    reset()
    if (textareaRef.current.preCurrent) textareaRef.current.preCurrent.innerHTML = ''
    onClose && onClose()
  }

  useEffect(() => {
    if (data.oldData) {
      setValue('title', data.oldData.title || '')
      textareaRef?.current?.setContent(data.oldData.content || '')
      setValue('state', data.oldData.state as 0 | 1)
    }
  }, [data])

  return (
    <AlertDialog open={data.open} onOpenChange={() => {}}>
      <AlertDialogTitle />
      <AlertDialogDescription />

      <AlertDialogContent className="p-0 w-[520px] bg-white rounded-md focus:outline-none">
        <div className="text-base h-[54px] pl-3 pr-3 border-b flex items-center relative">
          {data.title}
          <ReactSVG
            src={close}
            className="absolute cursor-pointer top-4 right-4"
            onClick={handleClose}
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="text-sm">
          <div className="p-4 flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <p>{t('autoReception.title')}</p>
              <Controller
                control={control}
                name="title"
                rules={{
                  required: t('autoReception.pleaseEnter')
                }}
                render={({ field }) => (
                  <Input placeholder={t('autoReception.pleaseEnter')} {...field} className="h-9" />
                )}
              />
              {errors?.title && (
                <p className="text-xs text-lingheError">{errors?.title?.message}</p>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <p>{t('autoReception.receptionMessage')}</p>
              <TextareaWithEmoji ref={textareaRef} contentVal={data?.oldData?.content || ''} />
              {errors?.content && (
                <p className="text-xs text-lingheError">{errors?.content?.message}</p>
              )}
            </div>

            <div className="flex items-center gap-2">
              <span>{t('autoReception.enable')}</span>
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <Switch
                    checked={Boolean(field.value)}
                    onCheckedChange={(e) => field.onChange(Number(e))}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex h-[54px] border-t items-center justify-end gap-2 pl-3 pr-3">
            <Button variant="outline" onClick={handleClose}>
              {t('common.cancel')}
            </Button>
            <Button type="submit">{t('common.save')}</Button>
          </div>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddWordsModal)
