import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { Button, Input, cn } from '@brisk/ui'
import { Plus } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { inputIconClassName } from '@renderer/utils/consts'
import search from '../../../assets/svg/search.svg'
import { ReactSVG } from 'react-svg'
import {
  ITagPageVO,
  ITagsPageReq,
  fetchAddTag,
  fetchDelTag,
  fetchEditTag,
  fetchTagListPage
} from '@renderer/api'
import { useMutation } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import emptyTagList from '../../../assets/svg/emptyTagList.svg'
import AddTag from '../Components/AddTag'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import DelCustolerModal from '../Components/DelCustolerModal'
import { IPageationRef } from '../AddCustomer'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { useTranslation } from 'react-i18next'

type ModalData = {
  open: boolean
  data: ITagPageVO | null
}

const index: FC = () => {
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })
  const { t } = useTranslation()
  const [modalData, setModalData] = useState<ModalData>({
    open: false,
    data: null
  })
  const [delData, setDelData] = useState<ModalData>({
    open: false,
    data: null
  })
  const { control, handleSubmit, getValues } = useForm<ITagsPageReq>({
    defaultValues: {
      tagName: ''
    }
  })

  const { data, mutateAsync: fetchList } = useMutation({
    mutationFn: fetchTagListPage
  })

  const { mutateAsync: addTag } = useMutation({
    mutationFn: fetchAddTag
  })

  const { mutateAsync: editTag } = useMutation({
    mutationFn: fetchEditTag
  })

  const { mutateAsync: delTag } = useMutation({
    mutationFn: fetchDelTag
  })

  const onSubmit = async (formData: ITagsPageReq): Promise<void> => {
    fetchList({
      ...formData,
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10
    })
  }
  const columns: ColumnDef<ITagPageVO>[] = useMemo(
    () => [
      {
        id: 'colorCode',
        header: (): JSX.Element => <div>{t('clientManagement.colour')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div
              className={cn('w-3.5 h-3.5 rounded')}
              style={{
                backgroundColor: original?.colorCode
              }}
            />
          )
        }
      },
      {
        id: 'tagName',
        header: (): JSX.Element => <div>{t('clientManagement.labelname')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.tagName}</div>
        }
      },
      {
        id: 'remark',
        header: (): JSX.Element => <div>{t('clientManagement.Remarks')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div className="w-[520px]">{original?.remark}</div>
        }
      },
      {
        id: 'joinNum',
        header: (): JSX.Element => <div>{t('clientManagement.Numberassociations')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.joinNum}</div>
        }
      },
      {
        id: 'edit',
        header: (): JSX.Element => <div>{t('common.Operation')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div className="flex items-center gap-2 [&_button]:p-0">
              <Button
                variant="link"
                className="hover:no-underline"
                onClick={() => setModalData({ data: original, open: true })}
              >
                {t('login.Edit')}
              </Button>
              <span className="text-[#ECECEC]">|</span>
              <Button
                variant="link"
                className="hover:no-underline text-lingheError"
                onClick={() => setDelData({ data: original, open: true })}
              >
                {t('chatFrame.removing')}
              </Button>
            </div>
          )
        }
      }
    ],
    []
  )

  useEffect(() => {
    fetchList({
      pageNum: 1,
      pageSize: 10
    })
  }, [])

  return (
    <NavBodyLayout
      title={[t('clientManagement.clientManagementTitle'), t('clientManagement.LabelManagement')]}
    >
      <div className="flex flex-col w-full h-full">
        <div className="h-[54px] shrink-0 border-b">
          <div className="flex items-center gap-3 pl-3 h-full font-medium text-base justify-between">
            <span>{t('clientManagement.LabelManagement')}</span>
            <Button onClick={() => setModalData({ data: null, open: true })}>
              <Plus size={16} className="mr-2" />
              {t('clientManagement.AddTags')}
            </Button>
          </div>
        </div>

        <form
          className="h-16 shrink-0 flex items-center pl-3 pr-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="tagName"
            control={control}
            render={({ field }) => (
              <div className="relative">
                <Input
                  {...field}
                  placeholder={t('clientManagement.Pleaseenterlabelname')}
                  className={cn('pl-6 h-8 w-[200px]')}
                />
                <ReactSVG src={search} className={cn(inputIconClassName)} />
              </div>
            )}
          />
        </form>

        <div className="flex-1 shrink-0 overflow-hidden">
          <ManagerTable<ITagPageVO>
            pageationRef={pageationRef}
            data={data?.data?.records || []}
            columns={columns}
            rowCount={data?.data?.total || 0}
            onPageChange={(pageNum, pageSize) => {
              fetchList({
                pageNum,
                pageSize,
                ...getValues()
              })
            }}
            emptyElement={() => (
              <div className="flex items-center justify-center pt-20">
                <div className="flex flex-col items-center justify-center gap-2">
                  <ReactSVG src={emptyTagList} />
                  <span className="mb-2 text-sm text-lingheTextGray">
                    {t('clientManagement.Therearenotagsavailableatthistime')}
                  </span>
                  <Button onClick={() => setModalData({ data: null, open: true })}>
                    <Plus size={16} className="mr-2" />
                    {t('clientManagement.AddTags')}
                  </Button>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      <AddTag
        data={modalData.data}
        open={modalData.open}
        onClose={() => setModalData({ data: null, open: false })}
        onConfirm={async (param): Promise<void> => {
          try {
            const res = await addTag(param)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('clientManagement.AddTagSuccess'), 'success')
              setModalData({ data: null, open: false })
              fetchList({
                ...getValues()
              })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
        onEditConfirm={async (param): Promise<void> => {
          try {
            const res = await editTag(param)
            if (res?.code === RESPONSE_CODE.success) {
              message(t('clientManagement.EditTagsSucceed'), 'success')
              setModalData({ data: null, open: false })
              fetchList({
                ...getValues(),
                pageNum: pageationRef?.current?.currentPage || 1,
                pageSize: pageationRef?.current?.itemsPerPage || 10
              })
            }
          } catch (error) {
            console.log('error', error)
          }
        }}
      />

      <DelCustolerModal
        title={t('clientManagement.DeleteTags')}
        open={delData.open}
        onClose={() => {
          setDelData({
            open: false,
            data: null
          })
        }}
        onConfirm={async () => {
          const res = await delTag(delData?.data?.tagId || '')
          if (res?.code === RESPONSE_CODE.success) message(t('businessManagement.Deletedsuccessfully'), 'success')
          fetchList({
            ...getValues(),
            pageNum: pageationRef?.current?.currentPage || 1,
            pageSize: pageationRef?.current?.itemsPerPage || 10
          })
          setDelData({
            open: false,
            data: null
          })
        }}
        content={
          <div className="text-lingheTextGray text-sm text-center whitespace-pre-line">
            {t('clientManagement.Deletingdisappearcustomerinformation')}
          </div>
        }
      />
    </NavBodyLayout>
  )
}

export default memo(index)
