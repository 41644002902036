import { FC, useContext, useEffect } from 'react'
import HeaderNav from './components/HeaderNav'
import { Button, CardContent, CardFooter, cn, PasswordInput, Input } from '@brisk/ui'
import { fetchCreateUser, fetchLoginCaptchaCheck, fetchLoginTemporaryCaptcha } from '@brisk/api'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { LockKeyhole } from 'lucide-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string } from 'zod'
import { useErrorHinter } from '@renderer/hooks/useErrorHinter'
import { useMutation } from '@tanstack/react-query'
import { useSignStore } from '@renderer/store/useSignStore'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AccountReveal from './components/AccountReveal'
import { ReactSVG } from 'react-svg'
import { inputIconClassName } from '../../utils/consts'
import { NoFocusInput } from './components/NoFocusInput'
import captacha from '../../assets/svg/captacha.svg'
import { ReloadIcon } from '@radix-ui/react-icons'
import { message } from '../../components/message'
import { useCaptchaCountdown } from '../../hooks/useCaptchaCountdown'
import { mutationSignInByPassword } from '../../api'
import { SignInContext } from './index'
import { isElectron } from '../../utils'
import { TerminalType } from '../../api/model'
import { useUserStore } from '../../store/useUserStore'
import tenant from '../../assets/svg/tenant.svg'
import querystring from 'query-string'
import GraphicalVerification from './components/graphicalVerification/graphicalVerification'
import { useGraphicalVerification } from './hooks/graphicalVerification'
import { trackDataType } from './InputAccount'
import lockKeyhole from '../../assets/svg/lockKeyhole.svg'

export const inconLeftClassName = 'absolute left-3 top-1/2 -translate-y-1/2'

interface ICompanyInfo {
  fn?: () => void
}

interface IFormInput {
  account: string
  password: string
  confirmPassword: string
  tenantName: string
  type: string
  captcha: string
}

const defaultFormValues: IFormInput = {
  account: '',
  password: '',
  confirmPassword: '',
  tenantName: '',
  type: '',
  captcha: ''
}

export const CompanyInfo: FC<ICompanyInfo> = ({ fn }) => {
  const { search } = useLocation()
  const params = querystring.parse(search)
  const setUser = useUserStore((state) => state.setUser)
  const { setLoading } = useContext(SignInContext)
  const account = params.account?.toString()
  const type = params.type?.toString()
  const areaCode = params.areaCode?.toString()
  const { t } = useTranslation()
  const { tempToken } = useSignStore()
  const { isCounting, startCount, count, reset, isSentCaptcha } = useCaptchaCountdown()
  const {
    captchaStatus,
    setCaptchaStatus,
    status,
    setStatus,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    callOpen,
    initFetchSliderCaptcha
  } = useGraphicalVerification()
  const schemaPassword = {
    password: string()
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters')),
    confirmPassword: string()
      .min(8, t('common.passwordcontainscharacters'))
      .max(16, t('common.passwordcontainscharacters'))
  }
  const schema = object({
    captcha: string().min(1, t('login.pleaseenterlegal_person')),
    tenantName: string().min(1, t('login.pleaseentercompany_name')),
    ...schemaPassword
  })
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema)
  })
  const { mutateAsync: onFetchLoginTemporaryCaptcha, isPending } = useMutation({
    mutationFn: fetchLoginTemporaryCaptcha
  })
  const { mutateAsync: onFetchCreateUser } = useMutation({
    mutationFn: fetchCreateUser
  })
  const { mutateAsync: fetchAsyncLoginCaptchaCheck } = useMutation({
    mutationFn: fetchLoginCaptchaCheck
  })
  const { mutateAsync: mutateAsyncSignInByPassword, isPending: passwordLoading } = useMutation({
    mutationFn: mutationSignInByPassword
  })
  // 刷新
  const trackRefresh = async () => {
    if (callOpen) {
      await initFetchSliderCaptcha()
      return
    }
    setCallOpen(true)
    await initFetchSliderCaptcha()
  }
  useErrorHinter([errors.captcha, errors.password, errors.tenantName, errors.confirmPassword])
  const captcha = watch('captcha')
  const password = watch('password')
  const tenantName = watch('tenantName')
  const confirmPassword = watch('confirmPassword')
  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    if (formData?.password !== formData?.confirmPassword) {
      control.setError('confirmPassword', { message: t('login.twopasswordsaredifferent') })
      return
    }
    const accounts = areaCode ? areaCode + account : account
    const data = await onFetchCreateUser({
      password: formData.password,
      tempToken: tempToken!,
      account: accounts,
      type: type,
      captcha: formData.captcha,
      tenantName: formData.tenantName
    })
    if (data.code === 10049 || data.code === 10021 || data.code === 10020) return message(data.msg, 'error')
    const res = await mutateAsyncSignInByPassword({
      account: accounts,
      password: formData.password,
      terminalType: isElectron ? TerminalType.PC : TerminalType.BROWSER,
      tempToken: null,
      captchaCode: null
    })
    setUser(res?.data)
    message(t('login.loginsuccessful'), 'success')
  }
  // 手机验证码调用
  const onCaptchaSuccess = (tackData: trackDataType) => {
    handleClickSendCaptcha(tackData)
  }
  // 发送验证码
  const handleClickSendCaptcha = async (tackData?): Promise<void> => {
    if (isCounting) {
      return
    }
    const accounts = areaCode ? areaCode + account : account
    const temporaryCaptcha = {
      account: accounts,
      type: type,
      token: ''
    }
    if (type !== 'EMAIL') {
      setStatus('initialise')
      trackRefresh()
      setCaptchaStatus(true)
      const data = await fetchAsyncLoginCaptchaCheck({ phone: accounts, ...tackData })
      if (data?.code === 10012) {
        setStatus('refreshError')
        message(data?.msg, 'error')
      }
      if (data?.code === 10000) {
        setStatus('success')
        temporaryCaptcha.token = data?.data
        setTimeout(() => {
          setCallOpen(false)
        }, 500)
        loginTemporaryCaptcha(temporaryCaptcha)
      }
    } else {
      delete temporaryCaptcha.token
      loginTemporaryCaptcha(temporaryCaptcha)
    }
  }
  const loginTemporaryCaptcha = async (temporaryCaptcha) => {
    const res = await onFetchLoginTemporaryCaptcha(temporaryCaptcha)
    if (res?.code !== 10000) {
      message(res?.msg, 'error')
      reset()
      return
    }

    startCount()
  }
  // 关闭滑动弹窗
  const handleFail = () => {
    setCallOpen(false)
    setCaptchaStatus(false)
  }
  useEffect(() => setLoading(passwordLoading), [passwordLoading])
  return (
    <>
      <GraphicalVerification
        captchaStatus={true}
        onCaptchaSuccess={onCaptchaSuccess}
        onFail={handleFail}
        callOpen={callOpen}
        {...backgroundImage}
        onRefresh={trackRefresh}
        {...jigsawImage}
        status={status}
      />
      <HeaderNav fn={fn} title={t('login.welcomeCreateAccounts')} />
      <CardContent className="flex-1 justify-start flex flex-col">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <p className="text-sm text-[#1D223C] pb-3">{t('login.yourAccount')}</p>
          <div className="grid gap-5 mb-3">
            <AccountReveal areaCode={areaCode} type={type} val={account} />
          </div>
          <div className="grid gap-5">
            <Controller
              name="captcha"
              control={control}
              render={({ field }) => (
                <div className="border w-full h-12 radios rounded-sm flex relative">
                  <ReactSVG
                    src={captacha}
                    className="h-5 w-5 absolute top-4 left-4"
                    aria-hidden="true"
                  />
                  <NoFocusInput
                    placeholder={t('login.pleaseenterverificationcode')}
                    maxLength={6}
                    className={cn(
                      'flex-1 h-full pl-11 pr-10 text-sm border-none outline-none',
                      errors.captcha ? 'focus-visible:ring-lingheError' : 'focus-visible:ring-ring'
                    )}
                    {...field}
                  />
                  <Button
                    variant="link"
                    className="h-full absolute font-normal right-0 top-1/2 -translate-y-1/2 hover:no-underline"
                    onClick={(e) => {
                      e.preventDefault()
                      handleClickSendCaptcha()
                    }}
                    disabled={isPending}
                  >
                    {isPending && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
                    <span className="absolute left-0 text-border">|</span>
                    {isCounting
                      ? `${count}s`
                      : isSentCaptcha
                        ? t('common.resend')
                        : t('login.sendverificationcod')}
                  </Button>
                </div>
              )}
            />
            {errors.captcha && (
              <p className="bottom-8 text-lingheError text-sm">
                {t('login.verificationcodeincorrect')}
              </p>
            )}
            {isSentCaptcha && (
              <p className="text-sm text-lingheGray">
                {t('login.gonadvalidverificationcode', { msg: account })}
              </p>
            )}
          </div>

          <p className="text-sm py-3 text-[#1D223C]">{t('login.pleasesetpassword')}</p>
          <div className="grid gap-3">
            <div className="relative">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    placeholder={t('common.passwordcontainscharacters')}
                    className="pl-11 pr-10 h-12"
                    {...field}
                  />
                )}
              />
              <ReactSVG
                src={lockKeyhole}
                className="h-5 w-5 absolute top-4 left-4"
                aria-hidden="true"
              />
            </div>
            <div className="relative">
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <PasswordInput
                    placeholder={t('accountInformation.pleaseconfirmpassword')}
                    className="pl-11 pr-10 h-12"
                    {...field}
                  />
                )}
              />
              <ReactSVG
                src={lockKeyhole}
                className="h-5 w-5 absolute top-4 left-4"
                aria-hidden="true"
              />
            </div>
            <p className="text-sm py-3 text-[#1D223C]">{t('login.companyName')}</p>
            <div className="relative">
              <Controller
                name="tenantName"
                control={control}
                render={({ field }) => (
                  <Input
                    maxLength={50}
                    className="pl-11 h-12 focus:outline-none border-b border-[#ECECEC]"
                    placeholder={t('login.pleaseenterthecompanyabbreviation')}
                    required
                    {...field}
                  />
                )}
              />
              <ReactSVG src={tenant} className="absolute left-4 top-4" />
            </div>
          </div>
          <Button
            type="submit"
            className={cn('w-full mt-4 h-12 shadow-none', [
              captcha && password && tenantName && confirmPassword
                ? ''
                : 'bg-[#A7BFFF] pointer-events-none'
            ])}
          >
            {t('login.createAccount')}
          </Button>
        </form>
      </CardContent>
      <CardFooter className="flex justify-between"></CardFooter>
    </>
  )
}
