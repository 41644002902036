import { Popover, PopoverContent, PopoverTrigger, cn } from '@brisk/ui'
import { ITeamListResponse } from '@renderer/api/team/fetchTeamList'
import { find, map, some } from 'lodash'
import React, { useState } from 'react'
import { ControllerRenderProps, Path } from 'react-hook-form'
import { ReactSVG } from 'react-svg'
import selectIconRight from '../../../assets/svg/selectIconRight.svg'
import roleSelected from '../../../assets/svg/roleSelected.svg'
import close from '../../../assets/svg/close.svg'
import { useTranslation } from 'react-i18next'

const TeamSelectCom = <T, K extends Path<T>>({
  teamData,
  field
}: {
  teamData: ITeamListResponse[]
  field: ControllerRenderProps<T, K>
}): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <Popover
      onOpenChange={(e) => {
        setIsOpen(e)
      }}
    >
      <PopoverTrigger asChild>
        <div className="w-full min-h-9 border rounded-md relative flex items-center gap-3 pl-3 pr-3 flex-wrap">
          {(field?.value as K[])?.length
            ? map(field?.value as K[], (item, index) => (
                <div
                  key={item + index}
                  className="relative h-[80%] flex items-center justify-center pl-2 pr-8 bg-[#F2F3F4] rounded-md"
                >
                  {find(teamData, (v) => v?.teamId === item)?.teamName}
                  <ReactSVG
                    src={close}
                    className="absolute top-1/2 right-2 -translate-y-1/2 cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault()
                      field?.onChange((field?.value as K[]).filter((v) => v !== item))
                    }}
                  />
                </div>
              ))
            : t('businessManagement.Pleaseselectgroup')}

          <ReactSVG
            src={selectIconRight}
            className={cn(
              'absolute top-1/2 right-3 -translate-y-1/2 transition',
              isOpen ? 'rotate-90' : ''
            )}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] max-h-[300px] overflow-y-auto flex flex-col gap-1 p-0 -translate-x-1/2 text-sm">
        <div className="w-full h-full p-3 flex flex-col gap-1">
          {map(teamData, (item) => {
            const isSome = some(field?.value as K[], (v) => v === item?.teamId)
            const isAll = +item.teamId === -1
            return (
              <div
                key={item?.teamId + item?.teamName}
                className={cn(
                  'h-8 pl-2 pr-2 flex items-center rounded-md relative',
                  isSome ? 'bg-lingheBtnHover' : '',
                  !isAll ? 'cursor-pointer hover:bg-lingheBtnHover' : ''
                )}
                onClick={() => {
                  if (isAll) return
                  if (isSome)
                    field?.onChange((field?.value as K[]).filter((v) => v !== item?.teamId))
                  else field?.onChange([...(field?.value as unknown as K[]), item?.teamId])
                }}
              >
                {item?.teamName}

                {isSome && (
                  <ReactSVG
                    src={roleSelected}
                    className="absolute right-2 top-[50%] translate-y-[-50%]"
                  />
                )}
              </div>
            )
          })}
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default TeamSelectCom
