import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * MenuTreeDTO，菜单树
 */
export interface IMenuTreeDTO {
  /**
   * 子集
   */
  children: IMenuTreeDTO[]
  /**
   * 组件路径
   */
  component: string
  /**
   * 菜单图标
   */
  icon: string
  /**
   * 是否缓存（0缓存 1不缓存）
   */
  isCache: number
  /**
   * 是否为外链（0是 1否）
   */
  isFrame: number
  /**
   * 菜单编号
   */
  menuId: number
  /**
   * 菜单名称
   */
  menuName: string
  /**
   * 菜单类型（1目录 2菜单 3按钮）
   */
  menuType: number
  /**
   * 显示顺序
   */
  orderNum: number
  /**
   * 父菜单ID
   */
  parentId: number
  /**
   * 路由地址
   */
  path: string
  /**
   * 权限标识
   */
  perms: string
  /**
   * 路由参数
   */
  queryParam: string
  /**
   * 菜单状态（0正常 1停用）
   */
  status: number
  /**
   * 显示状态（0显示 1隐藏）
   */
  visible: number
}

interface ApiResp extends ClientResponse {
  data: IMenuTreeDTO[]
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取所有菜单资源
 * @param roleId
 * @returns
 */
export const fetchAllMenuSource = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/resource/menu-tree/all`)

  return serialize(data)
}
