const emojis_icon = [
  'e_01_smile',
  'e_02_joy',
  'e_03_heart_eyes',
  'e_04_sweat_smile',
  'e_05_laughing',
  'e_06_wink',
  'e_07_yum',
  'e_24_blush',
  'e_09_fearful',
  'e_10_ohyeah',
  'e_11_cold_sweat',
  'e_12_scream',
  'e_13_kissing_heart',
  'e_14_smirk',
  'e_15_angry',
  'e_16_sweat',
  'e_17_stuck',
  'e_18_rage',
  'e_19_etriumph',
  'e_20_mask',
  'e_27_flushed',
  'e_22_sunglasses',
  'e_23_sob',

  'e_08_relieved',
  'e_26_doubt',
  'e_21_confounded',
  'e_28_sleepy',
  'e_29_sleeping',
  'e_30_disappointed_relieved',
  'e_31_tire',
  'e_32_astonished',
  'e_33_buttonnose',
  'e_34_frowning',
  'e_35_shutup',
  'e_36_expressionless',
  'e_37_confused',
  'e_38_tired_face',
  'e_39_grin',
  'e_40_unamused',
  'e_41_persevere',
  'e_42_relaxed',
  'e_43_pensive',
  'e_44_no_mouth',
  'e_45_worried',
  'e_46_cry',
  'e_47_pill',

  'e_48_celebrate',
  'e_49_gift',
  'e_50_birthday',
  'e_51_pray',
  'e_52_ok_hand',
  'e_53_first',
  'e_54_v',
  'e_55_punch',
  'e_56_thumbsup',
  'e_57_thumbsdown',
  'e_58_muscle',
  'e_59_maleficeent',
  'e_60_broken_heart',
  'e_61_heart',
  'e_62_taxi',
  'e_63_eyes',
  'e_64_rose',
  'e_65_ghost',
  'e_66_lip',
  'e_67_fireworks',
  'e_68_balloon',
  'e_69_clasphands',
  'e_70_bye'
]

export const emojis = [
  '[微笑]',
  '[快乐]',
  '[色眯眯]',
  '[汗]',
  '[大笑]',
  '[眨眼]',
  '[百胜]',
  '[放松]',
  '[可怕]',
  '[欧耶]',
  '[冷汗]',
  '[尖叫]',
  '[亲亲]',
  '[得意]',
  '[害怕]',
  '[沮丧]',
  '[卡住]',
  '[愤怒]',
  '[生气]',
  '[面具]',
  '[羞愧]',
  '[太阳镜]',
  '[在]',

  '[脸红]',
  '[疑惑]',
  '[激动]',
  '[休息]',
  '[睡着]',
  '[失望]',
  '[累]',
  '[惊讶]',
  '[抠鼻]',
  '[皱眉头]',
  '[闭嘴]',
  '[面无表情]',
  '[困惑]',
  '[厌倦]',
  '[露齿而笑]',
  '[非娱乐]',
  '[坚持下去]',
  '[傻笑]',
  '[沉思]',
  '[无嘴]',
  '[担心]',
  '[哭]',
  '[药]',

  '[庆祝]',
  '[礼物]',
  '[生日]',
  '[祈祷]',
  '[好]',
  '[冠军]',
  '[耶]',
  '[拳头]',
  '[赞]',
  '[垃圾]',
  '[肌肉]',
  '[鼓励]',
  '[心碎]',
  '[心]',
  '[出租车]',
  '[眼睛]',
  '[玫瑰]',
  '[鬼]',
  '[嘴唇]',
  '[烟花]',
  '[气球]',
  '[握手]',
  '[抱拳]'
]

// 处理图片地址
const getAssetsFile = (url: string) => {
  return new URL(`../assets/emojis/${url}.png`, import.meta.url).href
}

// 转换 emoji 图片
export const emojiToIcon = (key: string) => {
  const iconIndex = emojis.indexOf(key)

  if (iconIndex !== -1) {
    return getAssetsFile(emojis_icon[iconIndex])
  }

  return key
}

/**
 * 把表情标记转换为emoji表情
 * @param content
 * @returns
 */
export const replaceEmojiMark = (content: string): string => {
  if (!content) return ''

  try {
    return content?.replace(/\[{1,2}(.*?)\]{1,2}/gi, (match, p1) => {
      return emojis.indexOf(match) !== -1
        ? `<img style="vertical-align: middle; width: 24px; height: 24px; display: inline;"  src="${emojiToIcon(match)}" alt="[${p1}]" />`
        : match
    })
  } catch (error) {
    return '未知消息'
  }
}
