/**
 * putDispatchConfig
 *
 * @desc 描述：发送消息翻译语言配置
 * @desc 描述：接口文档名称 - 发送消息翻译语言配置
 * @desc 描述：/translate/config/engine/language/user
 */

/* =================================================== */

import type { ITranslateConfig } from './types'
import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

export const API_PATH = '/translate/config/engine/language/user'

/**
 * 发送消息翻译启用、禁用
 */
export async function putDispatchConfig(code: string): Promise<ITranslateConfig> {
  const res = await httpClient.put(API_PATH, { userLanguageCode: code })

  return get(res, 'data.data')
}
