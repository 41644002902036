import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react'
import AccountMenu from './AccountMenu'
import { Outlet, useNavigate } from 'react-router-dom'
// import { connectIM } from '@renderer/lib/sdk/juggle'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { ChatListStatus, EmployeeStatus } from '@renderer/api/model'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { filter, find } from 'lodash'
import { APP_ROUTER_PATH } from '@renderer/router'
import { fetchSessionTranslateConfig, fetchUserDetail, IChatList, RESPONSE_CODE } from '@brisk/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchTagList } from '@renderer/api/manage/fetchTagList'
import { handleFormatUserDetailData } from '@renderer/utils'
import { useConnectStatus } from '@renderer/store/useConnectStatus'
import { CombinedMessage, defaultMessageInfoContext } from './type'
import { useTeamWordsHandle } from '@renderer/hooks/useTeamWordsHandle'
import Loading from '@renderer/components/Loading'
import { useUserStore } from '@renderer/store/useUserStore'
import Joyride, { CallBackProps } from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { cn } from '@brisk/ui'
import { ReactSVG } from 'react-svg'
import joyridearrow from '@renderer/assets/svg/joyridearrow.svg'
import { useNewBieGuide } from '../../store/manageStore/useNewBieGuide'
import {
  handleReportReadMsg,
  initSdk,
  logOutIm,
  timGetJoinedGroupList,
  timLogin
} from '@renderer/utils/im-sdk/ipcTimSdk'
import { useUserRoleStore } from '@renderer/store/useUserRoleStore'
import { useSessionTranslateConfigStore } from '@renderer/store/useSessionTranslateConfigStore'
import { useSignStore } from '@renderer/store/useSignStore'

export const MessageInfoContext = createContext<CombinedMessage>(defaultMessageInfoContext)

export const Home: FC = () => {
  const [userDetailData, setUserDetailData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { sessionTranslateConfig, setSessionTranslateConfig } = useSessionTranslateConfigStore()
  const { setInputAccountToSign } = useSignStore()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { user } = useUserStore()
  const { client, setClientStatus } = useNewBieGuide()
  const { role } = useUserRoleStore.getState()
  const isManager = role.roleIdList.includes('1') || role.roleIdList.includes('3')
  const {
    active,
    setActive,
    currentTab,
    setCurrentTab,
    searchResult,
    setSearchResult,
    clearCurrent,
    chatItemActive,
    setChatItemActive,
    shoudScrollWhenSearch,
    setShouldScrollWhenSearch
  } = useCurrentAndSearch()
  const {
    assignedData,
    unAssignedData,
    finishedData,
    assignedList,
    unAssignedList,
    finishedList,
    reCallMessage,
    allConversations,
    setAssignedData,
    setAssignedList,
    setUnAssignedList,
    setUnAssignedData,
    setfinishedData,
    setFinishedList,
    setAllConversations,
    setReCallMessage,
    delReCallMessage,
    clearChatListInfo,
    shouldUpdate,
    setShouldUpdate
  } = useAssignedStore()

  const { connectStatus, connectStatusCode } = useConnectStatus()
  const {
    wordsDataList,
    personWordsDataList,
    handleGetTeamWordsData,
    isFetch,
    personGroupList,
    handleAddPersonGroup,
    handleSavePersonWords,
    handleDelPersonWords,
    handleDelPersonWordsGroup,
    wordsHandleLoading
  } = useTeamWordsHandle()
  const [stepIndex, setStepIndex] = useState(0)

  const steps = [
    {
      title: t('managerBeginnerGuide.Communicatewithyourcustomers'),
      target: '.bar-header',
      placement: 'right-end',
      disableBeacon: true,
      content: t('managerBeginnerGuide.CommunicatewithyourcustomersMessage')
    },
    {
      title: t('managerBeginnerGuide.Customerserviceworkstatus'),
      target: '.popoverContent',
      content: t('managerBeginnerGuide.CustomerserviceworkstatusMessage'),
      placement: 'right-start'
    },
    {
      title: t('managerBeginnerGuide.ContactAIOChatCustomerServiceto'),
      target: '.onlineCustomer-service',
      content: t('managerBeginnerGuide.ContactAIOChatCustomerServicetoMessage'),
      placement: 'right-start'
    },
    {
      title: t('managerBeginnerGuide.ManagementBackground'),
      target: '.computer',
      content: t('managerBeginnerGuide.ManagementBackgroundMessage'),
      placement: 'right-start'
    }
  ]
  const placement = {
    '1': {
      tooltipTop: 'top-[-22px] left-[45px]',
      reactSVG: 'left-[-67px] top-[31px]'
    },
    '2': {
      tooltipTop: 'top-[-50px] left-[50px]',
      reactSVG: 'left-[-67px] top-[31px]'
    },
    '3': {
      tooltipTop: 'top-[-142px] left-[40px]',
      reactSVG: 'bottom-[80px] left-[-67px]'
    },
    '4': {
      tooltipTop: 'top-[-156px] left-[48px]',
      reactSVG: 'bottom-[46px] left-[-67px]'
    }
  }
  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps
  }) => (
    <div
      className={cn(
        'tooltip-body min-w-[414px] p-6 min-h-[192px] absolute rounded-lg bg-[#247CFF] top-[-62px] left-[30px] text-sm',
        placement[index + 1].tooltipTop
      )}
      {...tooltipProps}
    >
      <ReactSVG src={joyridearrow} className={cn('absolute', placement[index + 1].reactSVG)} />
      <p className="text-base text-[#FFFFFF] pb-3">
        {index + 1}
        <span className="text-[rgba(255,255,255,0.50)]">/{steps.length}</span>
      </p>
      <h1 className="text-xl text-[#FFFFFF] pb-2">{step.title}</h1>
      <p className="text-[#FFFFFF] font-normal text-sm pb-6 whitespace-pre">{step.content}</p>
      <div className="tooltip-footer">
        {index + 1 === steps.length ? (
          <div
            className="guide-next float-right w-[88px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
            {...closeProps}
          >
            {t('managerBeginnerGuide.IgotIt')}
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <span className="text-base text-[#FFFFFF]" {...skipProps}>
              {t('managerBeginnerGuide.Skip')}
            </span>
            <div className="flex items-center gap-[12px]">
              {index > 0 ? (
                <div
                  className="guide-prev w-[74px] text-base text-[#FFFFFF] leading-8 h-8 li rounded bg-[rgba(255,255,255,0.20)] text-center"
                  {...backProps}
                >
                  {t('managerBeginnerGuide.Previous')}
                </div>
              ) : (
                ''
              )}
              <div
                className="guide-next w-[74px] text-base text-[#247CFF] leading-8 h-8 bg-[#FFFFFF] rounded text-center"
                {...primaryProps}
              >
                {t('managerBeginnerGuide.Next')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
  function callback(data: CallBackProps) {
    const { action, index, origin, status, type } = data
    const next_router = {
      '0': 'customerStatus',
      '1': 'contactService'
    }
    const prev_router = {
      '1': 'customerStatus',
      '2': 'contactService'
    }
    if (type === 'tour:end') {
      tourEnd()
    }
    switch (action) {
      case 'prev':
        if (type === 'step:after') {
          if (prev_router[index - 1]) {
            client[prev_router[index - 1]] = 'open'
          }
          client[prev_router[index]] = 'close'
          setClientStatus(client)
          setTimeout(() => {
            setStepIndex(index - 1)
          }, 100)
        }
        break
      case 'next':
        if (type === 'step:after') {
          if (next_router[index]) {
            client[next_router[index]] = 'open'
          }
          client[next_router[index - 1]] = 'close'
          setClientStatus(client)
          setTimeout(() => {
            setStepIndex(index + 1)
          }, 100)
        }
        break
      case 'close':
        tourEnd()
        break
    }
  }
  // 跳过 结束
  function tourEnd() {
    client.customerStatus = 'close'
    client.contactService = 'close'
    setClientStatus(client)
    setOpen(false)
    localStorage.setItem('newbieGuide', 'fulfil')
  }
  const itemData =
    currentTab === ChatListStatus.ASSIGNED
      ? find(assignedList, (it) => it?.conversationId === chatItemActive)
      : currentTab === ChatListStatus.UNASSIGNED
        ? find(unAssignedList, (it) => it?.conversationId === chatItemActive)
        : find(finishedList, (it) => it?.conversationId === chatItemActive)

  const TALKSTATUS = useMemo(() => {
    return {
      // 会话是否是自己的
      isSelf: user?.userId === itemData?.userId,
      // 是否已结束
      isEnd: itemData?.status === ChatListStatus.FINISHED,
      // 是否在排队中
      isWaiting: itemData?.status === ChatListStatus.UNASSIGNED
    }
  }, [itemData])

  const { mutateAsync: getUserDetail } = useMutation({
    mutationFn: fetchUserDetail
  })

  const { mutateAsync: getTranslateConfig } = useMutation({
    mutationFn: fetchSessionTranslateConfig
  })

  const { data: tagList, refetch: refetchTagList } = useQuery({
    queryKey: [fetchTagList?.name],
    queryFn: async () => {
      try {
        const res = await fetchTagList()
        if (res?.code === RESPONSE_CODE.success) return res?.data
      } catch (error) {
        console.log('error', error)
      }
    },
    staleTime: Infinity
  })

  const handleTranslateIn = (id): void => {
    !isManager && setCurrentTab(ChatListStatus.ASSIGNED)
    setUnAssignedList(filter(unAssignedList, (item) => item?.conversationId !== id))
    navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${id}`, {
      state: { isTransIn: true }
    })
  }

  const handleGetUserDetail = async (id): Promise<void> => {
    try {
      const res = await getUserDetail(id)
      if (res?.code === RESPONSE_CODE.success) {
        setUserDetailData(res?.data)
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  /** 会话翻译配置 */
  const handleGetSessinTranslateConfig = useCallback(
    async (sessionId: string) => {
      setLoading(true)
      try {
        const res = await getTranslateConfig(sessionId)
        if (res?.code === RESPONSE_CODE.success) setSessionTranslateConfig(res?.data)
        else setSessionTranslateConfig({})
        setLoading(false)
      } catch (error) {
        console.log('error>---->>', error)
        setSessionTranslateConfig({})
        setLoading(false)
      }
    },
    [setSessionTranslateConfig, setLoading]
  )

  useEffect(() => {
    if (currentTab === ChatListStatus.UNASSIGNED) {
      setUserDetailData(handleFormatUserDetailData(itemData as IChatList))
      return
    }
    const id = itemData?.sessionId || itemData?.latestMessage?.sessionId
    if (id) handleGetUserDetail(id)
  }, [currentTab, itemData])

  useEffect(() => {
    currentTab === ChatListStatus.ASSIGNED && timGetJoinedGroupList()
  }, [currentTab])

  useEffect(() => {
    if (!chatItemActive) return
    handleReportReadMsg(chatItemActive)
  }, [chatItemActive])

  useEffect(() => {
    initSdk()
    setInputAccountToSign(false)
    if (!localStorage.getItem('newbieGuide')) {
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    setLoading(wordsHandleLoading)
  }, [wordsHandleLoading])

  useEffect(() => {
    if (!user) navigate(APP_ROUTER_PATH.INPUT_ACCOUNT)
  }, [user])

  useEffect(() => {
    console.log('user status change>---->>', user?.status)
    if (user?.status === EmployeeStatus.OFFLINE) logOutIm({ userData: user?.token })
    else {
      ;(async () => {
        await timLogin()
        useAssignedStore.getState().setShouldUpdate()
        timGetJoinedGroupList()
      })()
    }
  }, [user?.status])

  return (
    <MessageInfoContext.Provider
      value={{
        user,
        chatItemActive,
        setChatItemActive,
        connectStatus,
        connectStatusCode,
        itemData,
        userDetailData,
        active,
        currentTab,
        tagList,
        setActive,
        setCurrentTab,
        searchResult,
        setSearchResult,
        handleTranslateIn,
        assignedData,
        unAssignedData,
        finishedData,
        assignedList,
        allConversations,
        unAssignedList,
        finishedList,
        reCallMessage,
        setAssignedData,
        setAssignedList,
        setUnAssignedList,
        setUnAssignedData,
        setfinishedData,
        setFinishedList,
        setAllConversations,
        handleGetUserDetail,
        setReCallMessage,
        delReCallMessage,
        clearChatListInfo,
        clearCurrent,
        wordsDataList,
        personWordsDataList,
        handleGetTeamWordsData,
        isFetch,
        personGroupList,
        handleAddPersonGroup,
        handleSavePersonWords,
        handleDelPersonWords,
        handleDelPersonWordsGroup,
        setLoading,
        refetchTagList,
        shouldUpdate,
        setShouldUpdate,
        TALKSTATUS,
        isManager,
        sessionTranslateConfig,
        handleGetSessinTranslateConfig,
        shoudScrollWhenSearch,
        setShouldScrollWhenSearch
      }}
    >
      <Joyride
        callback={callback}
        run={open}
        stepIndex={stepIndex}
        tooltipComponent={Tooltip}
        steps={steps}
        disableCloseOnEsc
        disableOverlayClose
        continuous={true}
        showSkipButton
        styles={{ options: { arrowColor: 'transparent' } }}
      />
      <div className="w-full h-screen flex max-h-[100vh] overflow-y-hidden">
        <div className="flex flex-col w-16 pt-10 border-r">
          <AccountMenu onMenuActive={() => ''} />
        </div>
        <div className="flex-1 min-w-[520px] shrink-0">
          <Outlet />
        </div>
      </div>
      {loading && <Loading />}
    </MessageInfoContext.Provider>
  )
}
