import { trimAll } from "@brisk/utils";
import { apiClient, ClientResponse } from "../../apiClient";
import { User } from "../model";

interface ApiResp extends ClientResponse {
  data: Partial<User>;
}
interface Serialize {
  (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
  return resp;
};

/**
 * 获取用户信息详情
 * @returns
 */
export const fetchGetUserInfo = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/account`);

  return serialize(data);
};
