import { Button, cn, Input, Popover, PopoverContent, Textarea } from '@brisk/ui'
import { inputIconClassName } from '@renderer/utils/consts'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import search from '../../../assets/svg/search.svg'
import close from '../../../assets/svg/close.svg'
import { debounce, filter, includes, map } from 'lodash'
import LevelSelect from '@renderer/components/LevelSelect'
import { ITeamResponse, TeamUser } from '@renderer/api'
import { useMutation } from '@tanstack/react-query'
import { ITeamListResponse } from '@renderer/api/team/fetchTeamList'
import noSearchResult from '../../../assets/svg/noSearchResult.svg'
import { PopoverTrigger } from '@radix-ui/react-popover'
import { fetchTurnOutChat, ITurnOutChat } from '@renderer/api/team/fetchTurnOutChat'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'
import { language_state } from '../../../utils'
import { MessageInfoContext } from '../Home'
import { timGetJoinedGroupList } from '@renderer/utils/im-sdk/ipcTimSdk'

export interface TestList {
  groupName?: string
  groupId?: string
  groupAvatar?: string
  total?: number
  online?: number
  personList?: Array<TestList>
}

const activeClassName =
  'after:block after:w-[90%] after:h-[2px] after:bg-primary after:rounded after:absolute after:left-[50%] after:-bottom-3 after:-translate-x-1/2'

interface ITurnTalkProps {
  list?: ITeamResponse[]
  sessionId: string | number
  conversationId: string
}

interface ILeveSelect {
  handleSetOpendList: (v: (string | number)[]) => void
}

const TurnTalk: FC<ITurnTalkProps> = ({ list = [], sessionId }) => {
  const [tag, setTag] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [renderList, setRenderList] = useState<ITeamResponse[] | ITeamListResponse[]>([])
  const [textCount, setTextCount] = useState(0)
  const [selected, setSelected] = useState<TeamUser & ITeamResponse>()

  const { setShouldUpdate, setLoading } = useContext(MessageInfoContext)

  const searchInputRef = useRef<HTMLInputElement>(null)
  const levelSelectRef = useRef<ILeveSelect>(null)
  const turnRemakRef = useRef<HTMLTextAreaElement>(null)
  const { t, i18n } = useTranslation()

  const { mutateAsync: toFetchTurnOutChat } = useMutation({
    mutationFn: fetchTurnOutChat
  })

  const handleTurnOutChat = debounce(async (): Promise<void> => {
    try {
      const res = await toFetchTurnOutChat({
        sessionId,
        remark: turnRemakRef?.current?.value,
        targetUserId: selected?.userId,
        ...(() =>
          tag === 0 ? { targetUserId: selected?.userId } : { targetOrgId: selected?.teamId })()
      } as ITurnOutChat)
      if (res.code === RESPONSE_CODE.success) {
        message(t('rollOut.sessiontransfersucceeded'), 'success')
        setOpen(false)
        setShouldUpdate()

        timGetJoinedGroupList()
        setLoading(false)
      }
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }
  }, 100)

  const handleSearch = debounce((e) => {
    const value = e.target.value
    setSearchValue(value)
  }, 500)

  useEffect(() => {
    if (searchInputRef?.current) searchInputRef.current.value = ''
    setSearchValue('')
  }, [tag])

  useEffect(() => {
    if (searchValue) {
      const targetGroup = filter(
        map(list, (item) => {
          const groupHasSearched = includes(item?.teamName, searchValue)
          const hasSearched =
            tag === 0 ? filter(item?.userList, (it) => includes(it?.nickname, searchValue)) : []
          if (hasSearched.length) {
            return { ...item, userList: hasSearched }
          } else if (groupHasSearched && tag !== 0) {
            // 转接到组 搜索组
            return { ...item, userList: [] }
          } else {
            return null
          }
        }),
        (el) => el
      ) as ITeamResponse[] | ITeamListResponse[]
      setRenderList(targetGroup)
      const openedId = map(targetGroup, (el) => {
        return '' + el?.teamId
      })
      if (openedId && levelSelectRef && levelSelectRef?.current)
        levelSelectRef?.current?.handleSetOpendList(openedId)
      return
    } else {
      setRenderList(list)
    }
  }, [tag, list, searchValue])

  return (
    <div className="w-72 h-[490px] bg-white rounded-md drop-shadow flex flex-col overflow-hidden">
      <div className="w-full shrink-0 flex items-center [&>span]:relative [&>span]:cursor-pointer gap-4 [&>span]:text-sm p-3 border-b">
        <span
          className={cn(
            'text-lingheGray',
            tag === 0 && `text-primary font-medium ${activeClassName}`
          )}
          onClick={() => {
            setTag(0)
            setOpen(false)
          }}
        >
          {t('rollOut.forward_agent')}
        </span>
        <span
          className={cn(
            'text-lingheGray',
            tag === 1 && `text-primary font-medium ${activeClassName}`
          )}
          onClick={() => {
            setTag(1)
            setOpen(false)
          }}
        >
          {t('rollOut.transfer_group')}
        </span>
      </div>

      <div className="w-full pt-3 flex flex-col flex-1 shrink-0 overflow-hidden">
        <div className="pl-3 pr-3 h-12 relative shrink-0">
          <div className={cn(inputIconClassName, 'left-5')}>
            <ReactSVG src={search} />
          </div>
          <Input
            ref={searchInputRef}
            type="text"
            className="h-full w-full bg-lingheInpBg pl-7 placeholder:text-lingheGray focus:outline-none"
            placeholder={t('rollOut.enter_proxy_name_search')}
            onInput={handleSearch}
          />
          <div
            className={cn(
              'absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer',
              searchValue ? 'block' : 'hidden'
            )}
          >
            <ReactSVG
              src={close}
              onClick={() => {
                if (searchInputRef?.current) searchInputRef.current.value = ''
                setSearchValue('')
              }}
            />
          </div>
        </div>

        <div className="flex-1 shrink-0 overflow-y-auto overflow-x-hidden pt-3 pb-3">
          {renderList?.length ? (
            <Popover open={open}>
              <PopoverTrigger>
                <LevelSelect
                  ref={levelSelectRef}
                  searchVal={searchValue}
                  data={renderList}
                  tag={tag}
                  selected={selected}
                  handleSelect={(item) => {
                    console.log(item)
                    setSelected(item)
                    setOpen(true)
                  }}
                />
              </PopoverTrigger>
              <PopoverContent
                side="left"
                className="w-[416px] h-[332px] gb-white rounded-md shadow p-0"
              >
                <div className="w-full h-full flex flex-col">
                  <div className="w-full h-[52px] border-b pl-3 pr-3 flex items-center justify-between">
                    <span>{t('rollOut.transferprompt')}</span>
                    <ReactSVG
                      src={close}
                      className="cursor-pointer"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className="flex-1 shrink-0 w-full pl-5 pr-5 flex flex-col items-center justify-center gap-3">
                    <div className="w-full flex items-center gap-2">
                      <span className={cn('shrink-0', language_state[i18n.language])}>
                        {t('rollOut.selectivegrouping')}
                      </span>
                      <Input
                        className="h-9"
                        disabled
                        value={selected?.teamName || selected?.nickname}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="w-full flex items-center gap-2">
                      <div className="h-full flex flex-col items-start pt-2">
                        <span>{t('rollOut.transitremarks')}</span>
                      </div>
                      <div className="flex-1 shrink-0 relative">
                        <Textarea
                          ref={turnRemakRef}
                          placeholder={t('common.pleaseEnter')}
                          className="flex-1 shrink-0 h-[132px]"
                          maxLength={300}
                          style={{
                            resize: 'none'
                          }}
                          onChange={(e) => setTextCount(e.target.value.length)}
                        />
                        <span className="text-lingheGray absolute right-2 bottom-2">{`${textCount}/300`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-[52px] border-t pl-3 pr-3 flex items-center justify-end gap-3">
                    <Button
                      variant="outline"
                      className="w-[60px] h-[32px]"
                      onClick={() => setOpen(false)}
                    >
                      {t('common.cancel')}
                    </Button>
                    <Button
                      className="w-[6opx] h-[32px]"
                      onClick={() => {
                        setLoading(true)
                        handleTurnOutChat()
                      }}
                    >
                      {t('common.verify')}
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            <div className="w-full flex flex-col items-center justify-center min-h-[200px]">
              <ReactSVG src={noSearchResult} />
              {t('common.noDataAvailable')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TurnTalk
