import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * CustomerStatisticsQueryDTO
 */
export interface IDataCustomerRequestBody {
  /**
   * 结束时间
   */
  endDate: Date;
  pageNum?: number;
  pageSize?: number;
  /**
   * 开始时间
   */
  startDate: Date;
  /**
   * 用户id，page搜索时才需要
   */
  userId?: number | string;
  channelId?: number | string;
}

/**
 * ReceptionStatisticsVO
 */
export interface IReceptionData {
  /**
   * 新访客数
   */
  countNewNum?: number;
  /**
   * 数量
   */
  countNum?: number;
  /**
   * 环比数
   */
  countNumRatio?: number;
  /**
   * 常访客数
   */
  countOldNum?: number;
}

interface ApiResp extends ClientResponse {
  data: IReceptionData;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 接待访客数
 * @param param
 * @returns
 */
export const fetchDataCustomerReception = async (
  param: IDataCustomerRequestBody,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/customer/statistics/reception`,
    param,
  );

  return serialize(data);
};
