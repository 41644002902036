import {
  // Avatar,
  // AvatarFallback,
  // AvatarImage,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@brisk/ui'
import { map } from 'lodash'
import { forwardRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import group01 from '@renderer/assets/svg/group01.svg'
import noSearchResult from '@renderer/assets/svg/noSearchResult.svg'
// import teamAvatarAll from '@renderer/assets/svg/teamAvatarAll.svg'
// import { User } from 'lucide-react'
import { highlightText } from '@renderer/pages/home/MessageMenu/MessageItem'
import { ITeamResponse, TeamUser } from '@renderer/api'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import { statusColor } from '@renderer/pages/home/AccountMenu/AvatarPopover'

interface ILevelSelectProps {
  data: ITeamResponse[]
  searchVal?: string
  handleUserSelect: (item: TeamUser & ITeamResponse) => void
  handleEdit?: (data: ITeamResponse) => void
  handleDelete?: (data: ITeamResponse) => void
  selectTeam?: TeamUser & ITeamResponse
}

const TeamTreeComp = forwardRef(
  (
    { data, searchVal, handleUserSelect, handleEdit, handleDelete, selectTeam }: ILevelSelectProps,
    ref
  ) => {
    const [edit, setEdit] = useState<string | number>('')
    const { t } = useTranslation()
    if (!data || !data?.length) {
      return (
        <div className="w-full flex flex-col items-center justify-center min-h-[200px]">
          <ReactSVG src={noSearchResult} />
          {t('common.noDataAvailable')}
        </div>
      )
    }
    return (
      <div>
        <div key={'-1'} className="w-full h-full flex flex-col gap-1 text-sm mb-2 pl-3 pr-3">
          <div
            onClick={() => {
              handleUserSelect && handleUserSelect({ teamId: '-1', teamName: '全部' })
            }}
            className={cn(
              'w-full group pl-3 pr-3 flex font-[500] items-center justify-between gap-2 cursor-pointer relative h-12 rounded-md hover:bg-lingheBtnHover',
              selectTeam?.teamId === '-1' && 'bg-lingheBtnHover'
            )}
          >
            <span className="w-[160px] shrink-0 overflow-hidden text-ellipsis whitespace-nowrap">
              {t('common.full')}
            </span>
          </div>
        </div>
        {map(data, (item) => {
          const teamId = '' + item?.teamId || ''

          return (
            <div
              key={'' + teamId}
              className="w-full h-full flex flex-col gap-1 text-sm mb-2 pl-3 pr-3"
            >
              <div
                className={cn(
                  'w-full group pl-3 pr-3 flex items-center justify-between gap-2 cursor-pointer relative h-12 rounded-md hover:bg-lingheBtnHover',
                  selectTeam?.teamId === teamId && 'bg-lingheBtnHover'
                )}
                onClick={() => {
                  handleUserSelect && handleUserSelect(item)
                }}
              >
                {/*<div className="relative shrink-0">*/}
                {/*  <Avatar className="hidden h-6 w-6 sm:flex flex items-center">*/}
                {/*    {teamId === '-1' ? (*/}
                {/*      <ReactSVG src={teamAvatarAll} />*/}
                {/*    ) : (*/}
                {/*      <AvatarImage alt="Avatar" src={item?.groupAvatar} className="rounded-full" />*/}
                {/*    )}*/}
                {/*    <AvatarFallback>*/}
                {/*      <User className="" />*/}
                {/*    </AvatarFallback>*/}
                {/*  </Avatar>*/}
                {/*</div>*/}
                <span className="w-[160px] shrink-0 overflow-hidden text-ellipsis whitespace-nowrap">
                  {highlightText(item?.teamName, searchVal)}
                </span>
                <Popover>
                  <PopoverTrigger>
                    <ReactSVG
                      src={group01}
                      aria-label="Update dimensions"
                      className={cn(teamId === '-1' && 'hidden', 'shrink-0 cursor-pointer ')}
                      // onClick={(e) => {
                      //   e.stopPropagation()
                      //   e.preventDefault()
                      //   setEdit((pre) => {
                      //     if (pre === item?.teamId) return ''
                      //     return item?.teamId
                      //   })
                      // }}
                    />
                  </PopoverTrigger>
                  <PopoverContent className="w-0 p-0 border-none">
                    <div
                      className="w-[68px] p-2 rounded-md bg-white shadow-md text-sm [&_span]:flex [&_span]:items-center [&_span]:cursor-pointer
                      [&_span]:justify-center [&_span]:rounded-md [&_span:hover]:bg-lingheBtnHover [&_span]:block [&_span]:w-full [&_span]:h-8"
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleEdit && handleEdit(item)
                          setEdit('')
                        }}
                      >
                        {t('chatFrame.edit')}
                      </span>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleDelete && handleDelete(item)
                          setEdit('')
                        }}
                        className="text-lingheError"
                      >
                        {t('chatFrame.removing')}
                      </span>
                    </div>
                  </PopoverContent>
                </Popover>

                {/*  {edit === item?.teamId && (*/}
                {/*    <div*/}
                {/*      className="z-10 absolute w-[68px] p-2 rounded-md bg-white shadow-md text-sm right-1 top-9 [&_span]:flex [&_span]:items-center [&_span]:cursor-pointer*/}
                {/*[&_span]:justify-center [&_span]:rounded-md [&_span:hover]:bg-lingheBtnHover [&_span]:block [&_span]:w-full [&_span]:h-8"*/}
                {/*    >*/}
                {/*      <span*/}
                {/*        onClick={(e) => {*/}
                {/*          e.stopPropagation()*/}
                {/*          e.preventDefault()*/}
                {/*          handleEdit && handleEdit(item)*/}
                {/*          setEdit('')*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        {t('chatFrame.edit')}*/}
                {/*      </span>*/}
                {/*      <span*/}
                {/*        onClick={(e) => {*/}
                {/*          e.stopPropagation()*/}
                {/*          e.preventDefault()*/}
                {/*          handleDelete && handleDelete(item)*/}
                {/*          setEdit('')*/}
                {/*        }}*/}
                {/*        className="text-lingheError"*/}
                {/*      >*/}
                {/*        {t('chatFrame.removing')}*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  )}*/}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
)

TeamTreeComp.displayName = 'TeamTreeComp'

export default React.memo(TeamTreeComp, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.searchVal === nextProps.searchVal &&
    prevProps.handleUserSelect === nextProps.handleUserSelect &&
    prevProps.handleEdit === nextProps.handleEdit &&
    prevProps.handleDelete === nextProps.handleDelete
  )
})
