import { createBrowserRouter, createHashRouter, RouteObject } from 'react-router-dom'
import { InputAccount } from '../pages/sign/InputAccount'
import { LoginPage } from '../pages/sign'
import { SelectTenant } from '../pages/sign/SelectTenant'
import { SignInByPassport } from '../pages/sign/SignInByPassport'
import { CreateTenant } from '../pages/sign/CreateTenant'
import { SignInByInvite } from '../pages/sign/SignInByInvite'
import { SignInByQRCode } from '../pages/sign/SignInByQRCode'
import { Home } from '@renderer/pages/home/Home'
import { CaptchaValid } from '../pages/sign/CaptchaValid'
import { RegistrationAccount } from '../pages/sign/RegistrationAccount'
import CreateCompany from '@renderer/pages/sign/CreateCompany'
import { ResetPassword } from '../pages/sign/ResetPassword'
import { CompanyInfo } from '@renderer/pages/sign/CompanyInfo'
import { isElectron } from '../utils'
import type { Router as RemixRouter } from '@remix-run/router/dist/router'
import Chatting from '@renderer/pages/home/Chatting'
import ChattingWindow from '@renderer/pages/home/Chatting/ChattingWindow'
import EnterpriseSign from '@renderer/pages/sign/EnterpriseSign'
import CheckInfoToLogIn from '@renderer/pages/sign/CheckInfoToLogIn'
import Manager from '@renderer/pages/manager/manager'
import AddCustomer from '@renderer/pages/manager/AddCustomer'
import ReceptionWelcomeWords from '@renderer/pages/manager/ReceptionWelcomeWords'
import SaveSource from '@renderer/pages/manager/SaveSource'
import Agent from '@renderer/pages/manager/Agent'
import CustomerManage from '@renderer/pages/manager/CustomerManage'
import CustomerTags from '@renderer/pages/manager/CustomerTags'
import RoleManage from '@renderer/pages/manager/RoleManage'
import PersonManage from '@renderer/pages/manager/PersonManage'
import ChannelManage from '@renderer/pages/manager/ChannelManage'
import ChatScript from '@renderer/pages/manager/ChannelManage/ChatScript'
import TelegramBot from '@renderer/pages/manager/ChannelManage/TelegramBot'
import Messenger from '@renderer/pages/manager/ChannelManage/Messenger'
import WhatsApp from '@renderer/pages/manager/ChannelManage/WhatsApp'
import ManageHone from '@renderer/pages/manager/Home'
import KnowledgeTeamWords from '@renderer/pages/manager/KnowledgeTeamWords'
import KnowledgeKeyWords from '@renderer/pages/manager/KnowledgeKeyWords'
import DataSync from '@renderer/pages/manager/DataSync'
import DataCustomer from '@renderer/pages/manager/DataCustomer'
import DataChannel from '@renderer/pages/manager/DataChannel'
import Translation from '@renderer/pages/manager/Translation'
import Settings from '@renderer/pages/manager/settings'
import ProtectedRoute from '@renderer/components/ProtectedRoute'
import InviteAuth from '@renderer/pages/otherPages/InviteAuth'
import SysSetting from '@renderer/pages/home/Settings'
import PreviewChat from '@renderer/pages/otherPages/PreviewChat'

export enum APP_ROUTER_PATH {
  HOME = '/home',
  HOME_CHATTING = '/home/chatting',
  CHATTING_WINDOW = '/home/chatting/:id',
  HOME_SETTING = '/home/setting',
  //-----------------------------------------------------------------------
  // sign
  //-----------------------------------------------------------------------
  INPUT_ACCOUNT = '/',
  ENTERPRISE_SIGN = '/enterprise_sign',
  CHECKINFO_LOGIN = '/checkInfo-login',
  SELECT_TENANT = '/select-tenant',
  CREATE_TENANT = '/create-tenant',
  SIGNIN_BY_PASSPORT = '/signin-by-passport',
  SIGNIN_BY_INVITE = '/signin-by-invite',
  SIGNIN_BY_QRCODE = '/signin-by-qrcode',
  SIGNUP = '/signup',
  Captcha_Valid = '/captcha-valid',
  RESET_PASSWORD = '/reset-password',
  REGISTRATION_ACCOUNT = '/registration-account',
  CREATE_COMPANY = '/create-company',
  COMPANY_INFO = '/company-info',
  /** 后台管理 */
  MANAGER = '/manager',
  MANAGER_HOME = '/manager/home', // 首页
  MANAGER_SETTINGS = '/manager/settings', // 设置

  MANAGER_PERSONMANAGE = '/manager/person-manage', // 企业管理-人员管理
  MANAGER_ADDCUSTOMER = '/manager/add-customer', // 企业管理-添加客服（邀请成员）
  MANAGER_ROLEMANAGE = '/manager/role-manage', // 企业管理-角色管理

  MANAGER_KNOWLEDGE_TEAMWORDS = '/manager/knowledge-teamWords', // 知识库-团队话术
  MANAGER_KNOWLEDGE_KEYWORDS = '/manager/knowledge-keyWords', // 知识库-关键词应答

  MANAGER_CHANNELMANAGE = '/manager/channel-manage', // 渠道管理
  /** 渠道管理children */
  MANAGER_CHANNELMANAGE_CHAT = '/manager/channel-manage/chat', // 渠道管理-聊天插件
  MANAGER_CHANNELMANAGE_TELEGRAM = '/manager/channel-manage/telegram', // 渠道管理-telegram
  MANAGER_CHANNELMANAGE_MESSENGER = '/manager/channel-manage/messenger', // 渠道管理-messenger
  MANAGER_CHANNELMANAGE_WHATSAPP = '/manager/channel-manage/wahtsApp', // 渠道管理-wahtsApp

  MANAGER_CUSTOMERMANAGE = '/manager/customer-manage', // 客户管理-客户列表
  MANAGER_CUSTOMERTAGS = '/manager/customer-tags', // 客户管理-标签管理

  MANAGER_DATASYNC = '/manager/data-sync', // 数据统计-实时数据
  MANAGER_DATACUSTOMER = '/manager/data-customer', // 数据统计-坐席数据
  MANAGER_DATACHANNEl = '/manager/data-channel', // 数据统计-渠道数据

  MANAGER_RECEPTIONWELCOMEWORDS = '/manager/reception-welcome-words', // 接待设置-欢迎语
  MANAGER_SAVESOURCE = '/manager/save-source', // 接待设置-留资设置
  MANAGER_AGENT = '/manager/agent', // 接待设置-坐席接待设置
  MANAGER_TRANSLATION = '/manager/translation', // 接待设置-翻译设置

  /** 邀请认证 */
  INVITE_AUTH = '/invite-auth',
  /** 访客插件预览 */
  PREVIEWCHAT = '/previewchat'
}

export const routes: Array<RouteObject> = [
  {
    path: '/',
    element: <LoginPage />,
    children: [
      {
        path: APP_ROUTER_PATH.ENTERPRISE_SIGN,
        element: <EnterpriseSign />
      },
      {
        path: APP_ROUTER_PATH.CHECKINFO_LOGIN,
        element: <CheckInfoToLogIn />
      },
      {
        path: APP_ROUTER_PATH.INPUT_ACCOUNT,
        element: <InputAccount />
      },
      {
        path: APP_ROUTER_PATH.SELECT_TENANT,
        element: <SelectTenant />
      },
      {
        path: APP_ROUTER_PATH.CREATE_TENANT,
        element: <CreateTenant />
      },
      {
        path: APP_ROUTER_PATH.SIGNIN_BY_PASSPORT,
        element: <SignInByPassport />
      },
      {
        path: APP_ROUTER_PATH.SIGNIN_BY_INVITE,
        element: <SignInByInvite />
      },
      {
        path: APP_ROUTER_PATH.SIGNIN_BY_QRCODE,
        element: <SignInByQRCode />
      },
      {
        path: APP_ROUTER_PATH.Captcha_Valid,
        element: <CaptchaValid />
      },
      {
        path: APP_ROUTER_PATH.RESET_PASSWORD,
        element: <ResetPassword />
      },
      {
        path: APP_ROUTER_PATH.REGISTRATION_ACCOUNT,
        element: <RegistrationAccount />
      },
      {
        path: APP_ROUTER_PATH.CREATE_COMPANY,
        element: <CreateCompany />
      },
      {
        path: APP_ROUTER_PATH.COMPANY_INFO,
        element: <CompanyInfo />
      }
    ]
  },
  {
    path: APP_ROUTER_PATH.HOME,
    element: <Home />,
    children: [
      {
        path: APP_ROUTER_PATH.HOME_CHATTING,
        element: <Chatting />,
        children: [
          {
            path: APP_ROUTER_PATH.CHATTING_WINDOW,
            element: <ChattingWindow />
          }
        ]
      },
      {
        path: APP_ROUTER_PATH.HOME_SETTING,
        element: <SysSetting />
      }
    ]
  },
  {
    path: APP_ROUTER_PATH.MANAGER,
    element: <Manager />,
    children: [
      {
        path: APP_ROUTER_PATH.MANAGER_HOME,
        element: <ProtectedRoute element={<ManageHone />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_SETTINGS,
        element: <ProtectedRoute element={<Settings />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_DATASYNC,
        element: <ProtectedRoute element={<DataSync />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_DATACUSTOMER,
        element: <ProtectedRoute element={<DataCustomer />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_DATACHANNEl,
        element: <ProtectedRoute element={<DataChannel />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_TRANSLATION,
        element: <ProtectedRoute element={<Translation />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_KNOWLEDGE_TEAMWORDS,
        element: <ProtectedRoute element={<KnowledgeTeamWords />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_KNOWLEDGE_KEYWORDS,
        element: <ProtectedRoute element={<KnowledgeKeyWords />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_ADDCUSTOMER,
        element: <ProtectedRoute element={<AddCustomer />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_RECEPTIONWELCOMEWORDS,
        element: <ProtectedRoute element={<ReceptionWelcomeWords />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_SAVESOURCE,
        element: <ProtectedRoute element={<SaveSource />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_AGENT,
        element: <ProtectedRoute element={<Agent />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_CUSTOMERMANAGE,
        element: <ProtectedRoute element={<CustomerManage />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_CUSTOMERTAGS,
        element: <ProtectedRoute element={<CustomerTags />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_ROLEMANAGE,
        element: <ProtectedRoute element={<RoleManage />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_PERSONMANAGE,
        element: <ProtectedRoute element={<PersonManage />} />
      },
      {
        path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE,
        element: <ProtectedRoute element={<ChannelManage />} />,
        children: [
          {
            path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_CHAT,
            element: <ProtectedRoute element={<ChatScript />} />
          },
          {
            path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_TELEGRAM,
            element: <ProtectedRoute element={<TelegramBot />} />
          },
          {
            path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_MESSENGER,
            element: <ProtectedRoute element={<Messenger />} />
          },
          {
            path: APP_ROUTER_PATH.MANAGER_CHANNELMANAGE_WHATSAPP,
            element: <ProtectedRoute element={<WhatsApp />} />
          }
        ]
      }
    ]
  },
  {
    path: APP_ROUTER_PATH.INVITE_AUTH,
    element: <InviteAuth />
  },
  {
    path: APP_ROUTER_PATH.PREVIEWCHAT,
    element: <PreviewChat />
  }
]

const makeRouter = (): RemixRouter => {
  if (isElectron) {
    return createHashRouter(routes)
  } else {
    return createBrowserRouter(routes)
  }
}
export const router = makeRouter()
