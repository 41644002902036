import { IContent } from '@brisk/api'
import { concat, findIndex, set as lodashSet } from 'lodash'
import { create } from 'zustand'

export type MessageData = {
  conversationId: string
  messages: IContent[]
}

type State = {
  uploadingFileMsgs: MessageData[]
}

type Action = {
  setUploadingFileMsgs: (covId: string, msg: IContent, type: 'add' | 'del') => void
}

/**
 * 文件发送上传中 切换页面或tab 临时存储
 */
export const useUploadingFileMsgStore = create<State & Action>((set, get) => ({
  uploadingFileMsgs: [],
  setUploadingFileMsgs: (covId, msg, type = 'add') => {
    const { uploadingFileMsgs } = get()
    const index = findIndex(uploadingFileMsgs, { conversationId: covId })
    console.log('index>---->>', index)
    if (index < 0) {
      set({
        uploadingFileMsgs: concat(uploadingFileMsgs, {
          conversationId: covId,
          messages: [msg]
        })
      })
    } else {
      const { messages } = uploadingFileMsgs[index]

      if (type === 'add') {
        set({
          uploadingFileMsgs: lodashSet(
            uploadingFileMsgs,
            `[${index}].messages`,
            concat(messages, msg)
          )
        })
      } else {
        set({
          uploadingFileMsgs: lodashSet(
            uploadingFileMsgs,
            `[${index}].messages`,
            messages.filter((item) => item.id !== msg.id)
          )
        })
      }
    }
  }
}))
