import { IParamForPage } from '@renderer/utils/consts'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * PageChannelWebsiteVO
 */
export interface IChannelConfigList {
  countId?: string
  current?: number
  pages?: number
  records?: ChannelWebsiteVO[]
  size?: number
  total?: number
}

/**
 * ChannelWebsiteVO
 */
export interface ChannelWebsiteVO {
  id: string | number
  /**
   * 渠道编号
   */
  channelId?: number
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string
  /**
   * 创建时间
   */
  createTime?: Date
  /**
   * 是否启用 0-否 1-是
   */
  enable?: number
  /**
   * 渠道的icon图片地址
   */
  iconUrl?: string
  /**
   * 语言 zh-CN en-US
   */
  language: string
  /**
   * 渠道名称
   */
  name?: string
  /**
   * 位置 BOTTOM-LEFT,BOTTOM-RIGHT
   */
  position: string
  /**
   * 备注
   */
  remark: string
  /**
   * 租户编号
   */
  tenantId?: number
}

interface ApiResp extends ClientResponse {
  data: IChannelConfigList
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 聊天插件-分页搜索渠道配置
 * @param param
 * @returns
 */
export const fetchChannelConfigList = async (param: IParamForPage): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/website/page`, param)

  return serialize(data)
}
