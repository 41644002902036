import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * UserVO
 */
export interface CustomerUserList {
  /**
   * 账号
   */
  account?: string;
  /**
   * 加入时间
   */
  createTime?: Date;
  /**
   * 用户姓名
   */
  nickname?: string;
  /**
   * 角色
   */
  roleName?: string;
  /**
   * 小组
   */
  teamName?: string;
  /**
   * 用户编号
   */
  userId?: number;
  [property: string]: any;
}

interface ApiResp extends ClientResponse {
  data: CustomerUserList[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 用户列表
 * @returns 
 */
export const fetchCustomerUserList = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/user/list`);

  return serialize(data);
};
