import { FC, memo, useState } from 'react'
import closeWhite from '../assets/svg/closeWhite.svg'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'
import imgDownload from '../assets/svg/imgDownload.svg'
import imgToBig from '../assets/svg/imgToBig.svg'
import imgToSmall from '../assets/svg/imgToSmall.svg'

// import imgToMove from '../assets/svg/imgToMove.svg'

interface IImageViewProps {
  src: string
  className?: string
  showEdit?: boolean
}
const ImageViewManage: FC<IImageViewProps> = ({ src, className, showEdit, ...props }) => {
  const [showView, setShowView] = useState<boolean>(false)
  const [zoom, setZoom] = useState(1)
  // const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  // useEffect(() => {
  //   const img = new Image()
  //   img.src = src
  //   img.onload = () => setImageLoaded(true)
  //   img.onerror = () => setImageLoaded(false) // 处理加载错误
  // }, [src])

  return (
    <div>
      {/* {!imageLoaded && (
        <div className="relative">
          <img
            src={defoaultImg}
            className={cn(' object-cover rounded-md', className)}
            style={{
              filter: 'blur(1px)'
            }}
            alt=""
            {...props}
          />
          <style>{`@keyframes loading {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }`}</style>

          <Loader
            className="absolute left-[47%] top-[48%]"
            style={{
              animation: 'loading 1s linear infinite'
            }}
          />
        </div>
      )}
      {imageLoaded && (
        <img
          src={src}
          className={cn(' object-cover rounded-md', className)}
          alt=""
          onClick={() => setShowView(true)}
          style={{ objectFit: 'cover' }}
          {...props}
        />
      )} */}
      <img
        src={src}
        className={cn('object-contain rounded-md', className)}
        alt=""
        onClick={() => setShowView(true)}
        style={{ objectFit: 'contain' }}
        {...props}
      />
      {showView ? (
        <div
          className="w-[100vw] h-[100vh] fixed left-0 top-0 flex items-center justify-center bg-[#00000060]"
          style={{
            zIndex: 9996
          }}
        >
          <style>{`@keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }`}</style>
          <img
            src={src}
            alt="chat-app-image"
            className=" w-[70%] h-[70%] object-contain"
            style={{
              zIndex: 9999,
              animation: 'fadeIn 0.3s ease-in-out',
              transform: `scale(${zoom})`
            }}
          />
          <div
            className="w-full h-full absolute left-0 top-0"
            onClick={() => setShowView(false)}
            style={{ zIndex: 9997 }}
          />
          <div
            style={{
              zIndex: 99999
            }}
            onClick={() => setShowView(false)}
            className="w-12 h-12 rounded-full bg-[#00000070] hover:bg-[#00000090] absolute right-3 top-3 flex items-center justify-center"
          >
            <ReactSVG src={closeWhite} className="cursor-pointer" />
          </div>
          {showEdit && (
            <div
              style={{
                zIndex: 99999
              }}
              className="absolute left-1/2 bottom-9 gap-6 pl-3 pr-3 -tanslate-x-1/2 h-12 rounded-md bg-[#00000070] flex items-center justify-center"
            >
              <ReactSVG
                src={imgDownload}
                className="cursor-pointer"
                onClick={() => {
                  const link = document.createElement('a')
                  link.href = src
                  link.click()
                }}
              />
              <ReactSVG
                src={imgToBig}
                className="cursor-pointer"
                onClick={() => setZoom(zoom + 0.1)}
              />
              <ReactSVG
                src={imgToSmall}
                className="cursor-pointer"
                onClick={() => setZoom(zoom - 0.1)}
              />
              {/* <ReactSVG src={imgToMove} className="cursor-pointer" /> */}
              <span className="text-white">{Math.round(zoom * 100)}%</span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default memo(ImageViewManage)
