import {apiClient, ApiRespSerializeFn, ClientResponse} from "../../apiClient";

/**
 * ChannelVO
 */
export interface IChannelList {
  /**
   * 渠道编号
   */
  channelId?: number
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string
  /**
   * 渠道的icon图片地址
   */
  iconUrl?: string
  /**
   * 渠道名称
   */
  name?: string
  /**
   * 租户编号
   */
  tenantId?: number
}

interface ApiResp extends ClientResponse {
  data: IChannelList[]
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取渠道列表
 * @returns
 */
export const fetchChannelList = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/channel/list`)

  return serialize(data)
}
