import { create } from 'zustand'

export type clientStatus = {
  customerStatus: string // 客服工作状态
  contactService: string // 联系AIOChat客服
}

type State = {
  list: any[] // 列表数据,
  client: clientStatus // 状态
}

type Action = {
  setNewBieGuideList: (guideList: any[]) => void
  setClientStatus: (client: clientStatus) => void
}

export const useNewBieGuide = create<State & Action>((set) => ({
  list: [],
  client: {
    customerStatus: 'close',
    contactService: 'close'
  },
  setNewBieGuideList: (guideList): void => set(() => ({ list: guideList })), // 监听一级菜单数据加载
  setClientStatus: (client: clientStatus): void => set(() => ({ client: client })) // 新手引导 监听客服工作状态，联系AIOChat客服的开启/关闭状态
}))
