import { Button, cn, Dialog, DialogContent, DialogDescription, DialogTitle } from '@brisk/ui'
import { inputIconClassName } from '@renderer/utils/consts'
import { FC, useEffect } from 'react'
import { NoFocusInput } from './NoFocusInput'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string } from 'zod'
import { useQuery } from '@tanstack/react-query'
import { fetchImgCaptcha } from '@renderer/api'
import captacha from '../../../assets/svg/captacha.svg'
import { ReactSVG } from 'react-svg'
// import tac from '@ta'

export interface IImgCaptcha {
  tempToken: string | undefined
  imageCaptcha: string
}

interface IProps {
  open: boolean
  onOpenChange: (e: boolean) => void
  onConfirm?: (data: IImgCaptcha) => void
}

type IFormInput = {
  pictureCode: string
}

const defaultFormValues: IFormInput = {
  pictureCode: ''
}

const schema = object({
  pictureCode: string().length(4, '请输入图形验证码')
})

const ImageCaptcha: FC<IProps> = ({ open, onOpenChange, onConfirm }) => {
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema)
  })

  const { data: imgCodeData, refetch } = useQuery({
    queryKey: [fetchImgCaptcha.name],
    queryFn: () => fetchImgCaptcha()
  })

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    onConfirm &&
      onConfirm({ tempToken: imgCodeData?.data?.tempToken, imageCaptcha: formData.pictureCode })
  }

  useEffect(() => {
    if (open) refetch()
  }, [open])

  return (
    <Dialog
      open={open}
      onOpenChange={(e) => {
        onOpenChange && onOpenChange(e)
      }}
    >
      <DialogTitle className="hidden" />
      <DialogDescription className="hidden" />
      <DialogContent className="sm:max-w-[425px] p-0 pb-3">
        <div className="font-[600] p-3 border-b">图形验证码</div>
        <div className="pt-6">
          <p className="pl-6 text-lingheGray pb-3">请输入图形验证码</p>
          <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
            <div className="pl-6 pr-6 pb-6">
              <div className="h-12">
                <Controller
                  name="pictureCode"
                  control={control}
                  render={({ field }) => (
                    <div
                      className="relative h-full"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    >
                      <ReactSVG src={captacha} className={inputIconClassName} />
                      <NoFocusInput
                        placeholder="请输入图形验证码"
                        className={cn(
                          'flex-1 h-full pl-10 pr-10 w-full text-sm outline-none',
                          errors.pictureCode
                            ? 'focus-visible:ring-lingheError'
                            : 'focus-visible:ring-ring'
                        )}
                        {...field}
                      />
                      <img
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          refetch()
                        }}
                        className="w-20 h-[95%] absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                        src={`data:image/png;base64,${imgCodeData?.data?.base64}`}
                        alt=""
                      />
                      <Button
                        type="reset"
                        variant="link"
                        className="absolute -right-3 -bottom-10"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          refetch()
                        }}
                      >
                        点击刷新
                      </Button>
                    </div>
                  )}
                />
              </div>
              {errors.pictureCode && (
                <p className="mt-3 text-lingheError text-sm">图形验证码错误，请重新输入</p>
              )}
            </div>
            <div className="flex justify-end items-start gap-3 border-t">
              <Button
                variant="outline"
                type="reset"
                className="w-16 mt-4 h-8"
                onClick={() => {
                  onOpenChange && onOpenChange(false)
                }}
              >
                取消
              </Button>
              <Button type="submit" className="w-16 mt-4 h-8 mr-6">
                保存
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ImageCaptcha
