import { createContext, forwardRef, memo, useContext, useEffect, useRef, useState } from 'react'

import { inputIconClassName } from '@renderer/utils/consts'
import { debounce, filter, includes, map } from 'lodash'
import pickUp from '../../../../assets/svg/pickUp.svg'

import search from '../../../../assets/svg/search.svg'
import { ReactSVG } from 'react-svg'
import SelfWords from './SelfWords'
import TeamWords from './TeamWords'
import KnowlegeLib from './KnowlegeLib'
import { SavePersonWordsRequest, TeamWordsFromPcResponse } from '@brisk/api'
import { MessageInfoContext } from '../../Home'
import { cn, Input } from '@brisk/ui'
import close from '@renderer/assets/svg/close.svg'
import { useTranslation } from 'react-i18next'

interface IDetailProps {
  id: string | number
  onClose: () => void
}

type AddPersonWordsData = {
  open: boolean
  data: SavePersonWordsRequest | null
}

export const WordsContext = createContext<{
  searchList: TeamWordsFromPcResponse[]
  searchInputRef: React.RefObject<HTMLInputElement>
  addPersonWordsData: AddPersonWordsData
  setAddPersonWordsData: (param: AddPersonWordsData) => void
  currentTab: number
}>({
  searchList: [],
  searchInputRef: null,
  addPersonWordsData: { open: false, data: null },
  setAddPersonWordsData: () => {},
  currentTab: 1
})

const tabStyle =
  'text-primary after:content-[""] after:block after:w-full after:h-0.5 after:bg-primary after:rounded-full after:absolute after:-bottom-3 after:left-0'

const KnowledgeBase = forwardRef(({ onClose }: IDetailProps) => {
  const [currentTab, setCurrentTab] = useState<number>(1)
  const [searchList, setSearchList] = useState<TeamWordsFromPcResponse[]>([])
  const [addPersonWordsData, setAddPersonWordsData] = useState<AddPersonWordsData>({
    open: false,
    data: null
  })

  const { wordsDataList, personWordsDataList } = useContext(MessageInfoContext)

  const searchInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  /** 本地过滤 */
  const handleSearch = debounce((e) => {
    const val = e?.target?.value
    if (!val) {
      setSearchList([])
      return
    }
    let list = []
    if (currentTab === 1) list = personWordsDataList
    if (currentTab === 2) list = wordsDataList

    const searchRes = filter(
      map(list, (item) => {
        const res = filter(
          item?.itemList,
          (child) => includes(child?.textContent, val) || includes(child?.shortcutKey, val)
        )
        if (res?.length) return { ...item, itemList: res }
        else return null
      }),
      (it) => it !== null
    )

    setSearchList(searchRes)
  }, 500)

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
      setSearchList([])
    }
  }, [currentTab])

  return (
    <WordsContext.Provider
      value={{
        currentTab,
        searchList,
        searchInputRef,
        addPersonWordsData,
        setAddPersonWordsData
      }}
    >
      <div className="w-full h-full relative flex flex-col">
        <div className="w-full border-b p-3 shrink-0">
          <div className="pl-3 pr-3 flex justify-between items-center text-lingheGray text-base [&>div]:cursor-pointer [&>div]:relative">
            <div
              className={cn('hover:text-primary', currentTab === 1 && tabStyle)}
              onClick={() => {
                setCurrentTab(1)
              }}
            >
              {/* currentTab === ChatListStatus.ASSIGNED && tabStyle */}
              {t('chatFrame.Personal_speech')}
            </div>
            <div
              className={cn('hover:text-primary', currentTab === 2 && tabStyle)}
              onClick={() => {
                setCurrentTab(2)
              }}
            >
              {/* currentTab === ChatListStatus.ASSIGNED && tabStyle */}
              {t('chatFrame.teamwork')}
            </div>
            {/* <div
              className={cn('hover:text-primary', currentTab === 3 && tabStyle)}
              onClick={() => {
                setCurrentTab(3)
              }}
            >
              知识库
            </div> */}
            <div
              className={cn('cursor-pointer w-4 flex items-center transition-all rotate-180')}
              onClick={onClose}
            >
              {/* talkGroupShow && 'rotate-180' */}
              <ReactSVG src={pickUp} />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col shrink-0 overflow-hidden">
          <div className={cn('p-3  relative shrink-0')}>
            <div className={cn(inputIconClassName, 'left-5')}>
              <ReactSVG src={search} />
            </div>
            <Input
              ref={searchInputRef}
              type="text"
              className="h-9 w-full bg-lingheInpBg pl-7 pr-9 placeholder:text-lingheGray focus:outline-none"
              placeholder={t('common.searchKeyWords')}
              onInput={handleSearch}
            />
            {searchInputRef?.current?.value && (
              <ReactSVG
                src={close}
                className="absolute top-1/2 right-6 -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  if (searchInputRef.current) {
                    searchInputRef.current.value = ''
                    setSearchList([])
                  }
                }}
              />
            )}
          </div>

          <div className="flex-1 w-full h-full overflow-hidden">
            {currentTab === 1 && <SelfWords list={personWordsDataList} />}
            {currentTab === 2 && <TeamWords list={wordsDataList} />}
            {currentTab === 3 && <KnowlegeLib />}
          </div>
        </div>
      </div>
    </WordsContext.Provider>
  )
})
KnowledgeBase.displayName = 'KnowledgeBase'

export default memo(KnowledgeBase)
