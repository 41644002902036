import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * WelcomeConfigVO
 */
export interface IWelcomResponse {
  welcomeConfId?: string | number
  /**
   * 新会话欢迎语
   */
  firstContents?: WelcomeContentsVO[]
  /**
   * 发送频次 单位：小时
   */
  firstIntervalTime?: number
  /**
   * 触发场景 欢迎语配置开关，1：开启状态，0：关闭状态
   */
  firstState?: number
  /**
   * 转接后发送欢迎语
   */
  referralContents?: WelcomeContentsVO[]
  /**
   * 转接后发送发送欢迎语配置，1：开启状态，0：关闭状态
   */
  referralState?: number
  /**
   * 欢迎语配置开关，1：开启状态，0：关闭状态
   */
  welcomeState?: number
}

/**
 * WelcomeContentsVO，转接后发送欢迎语
 */
export interface WelcomeContentsVO {
  /**
   * 默认会话、转接话术内容
   */
  content?: string
  /**
   * 默认会话、转接话术是否启用配置 0：关闭，1：开启
   */
  state?: number
  /**
   * 欢迎语标题
   */
  title?: string
  /**
   * 0：新会话，1：转接会话
   */
  type?: number
  /**
   * id
   */
  welcomeContentId?: number
}

interface ApiResp extends ClientResponse {
  data: IWelcomResponse
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 查询欢迎语配置
 * @returns
 */
export const fetchWelcomConfig = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/welcome/config`)

  return serialize(data)
}
