import { FC, useState } from 'react'
import * as Select from '@radix-ui/react-select'
import { ReactSVG } from 'react-svg'
import select_arrow from '../../../../assets/svg/selectarrow.svg'
import { ChevronDownIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import checked_tick from '../../../../assets/svg/checked_tick.svg'
import './style/index.css'
import { cn } from '@brisk/ui'

const list = [
  {
    id: '001',
    value: 'zh',
    text: '简体中文'
  },
  {
    id: '002',
    value: 'en',
    text: 'English'
  }
  // {
  //   id: '003',
  //   value: 'tc',
  //   text: '繁體中文'
  // },
  // {
  //   id: '004',
  //   value: 'ar',
  //   text: 'العربية'
  // }
]
const LoginSwitchLanguage: FC = () => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const index = list.findIndex((item) => item.value === i18n.language.slice(0, 2))
  function onValueChange(res) {
    i18n.changeLanguage(res)
  }
  function onOpenChange(isOpen: boolean) {
    setIsOpen(isOpen)
  }

  return (
    <div className="login-language-settings pb-6">
      <Select.Root onValueChange={onValueChange} onOpenChange={onOpenChange}>
        <Select.Trigger className="login-SelectTrigger" aria-label="Food">
          <Select.Value placeholder={list[index < 0 ? 0 : index].text} />
          <Select.Icon className="login-SelectIcon">
            <ReactSVG
              className={cn('transition-all duration-300', isOpen ? 'rotate-180' : 'rotate-0')}
              src={select_arrow}
            />
          </Select.Icon>
        </Select.Trigger>

        <Select.Portal>
          <Select.Content position={'popper'} className="login-SelectContent">
            <Select.ScrollUpButton className="login-SelectScrollButton" />
            <Select.Viewport className="login-SelectViewport">
              <Select.Group>
                {list.map((item) => {
                  return (
                    <Select.Item className="SelectItem" key={item.id} value={item.value}>
                      <Select.ItemText>{item.text}</Select.ItemText>
                      {item.value === i18n.language.slice(0, 2) && (
                        <ReactSVG className="absolute top-3.5 right-2.5" src={checked_tick} />
                      )}
                    </Select.Item>
                  )
                })}
              </Select.Group>
              <Select.Separator />
            </Select.Viewport>
            <Select.ScrollDownButton className="login-SelectScrollButton">
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  )
}

export default LoginSwitchLanguage
