import { ClientResponse, httpClient } from '../../http-client/httpClient'
import { trimAll } from '../../utils'

interface RequestParams {
  // 类型 SMS:短信 EMAIL:邮箱
  type: string
  // 账号,可以是用户名，邮箱，手机号
  account: string
  // 滑块验证token
  token: string
}

interface ApiResp extends ClientResponse {}
interface Serialize {
  (resp: ApiResp): ApiResp
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data

  return resp
}
// 重置密码前获取验证码
export const fetchSendResetPasswordCaptcha = async (payload: RequestParams): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/account/reset-pwd-captcha`, {
    account: trimAll(payload.account),
    type: payload.type,
    token: payload.token
  })

  return serialize(data)
}
