import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { ILanguageList } from "./fetchLanguageList";

interface ApiResp extends ClientResponse {
  data: ILanguageList[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 用户消息翻译启用禁用配置
 * @param sessionId
 * @param status
 * @returns
 */
export const fetchUserTranslateSwitch = async (param: {
  sessionId: string;
  status: number;
}): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(
    `/translate/session/config/${param.sessionId}/${param.status}/customer`,
  );

  return serialize(data);
};
