import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 删除快捷短语
 * @returns
 */
export const fetchDeletePersonWords = async (
  preMessageId,
): Promise<ApiResp> => {
  const { data } = await apiClient.delete<ApiResp>(
    `/preset/message/delete/${preMessageId}`,
  );

  return serialize(data);
};
