import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'
import { IAgentConfig } from './fetchAgentConfig'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 保存坐席接待配置
 * @returns
 */
export const fetchSaveAgentConfig = async (params: IAgentConfig): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/config/reception`, params)

  return serialize(data)
}
