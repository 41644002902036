import { FC, useEffect, useRef, useState } from 'react'
import play from '../assets/svg/play.svg'
import video from '../assets/svg/video.svg'
import { ReactSVG } from 'react-svg'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

const VideoComp: FC<{ src: string }> = ({
  src = 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
}) => {
  const [time, setTime] = useState<number | undefined>()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          videoRef?.current?.pause()
        }
      })
    }
    return () => videoRef?.current?.removeEventListener('fullscreenchange', () => {})
  }, [videoRef])

  return (
    <div className="relative w-64 h-36">
      <video
        ref={videoRef}
        className="w-64 h-36 rounded-md"
        src={src}
        onCanPlay={() => {
          setTime(videoRef?.current?.duration || 0)
        }}
      />
      <div
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
        onClick={() => {
          if (videoRef.current) {
            videoRef.current?.requestFullscreen()
            videoRef.current?.play()
          }
        }}
      >
        <ReactSVG src={play} />
      </div>
      <div className="rounded-md text-xs bg-lingheGrayBg p-2 min-w-14 text-white absolute bottom-2 right-2 flex items-center justify-between gap-2">
        <ReactSVG src={video} />
        <span>{dayjs.duration(time || 0, 'seconds').format(`mm:ss`)}</span>
      </div>
    </div>
  )
}

export default VideoComp
