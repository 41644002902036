import { FC } from 'react'
import e_default from '../../../assets/svg/e_default.svg'
import { AccountType } from '../../../api/model'
import { ReactSVG } from 'react-svg'
import perspectives from '../../../assets/svg/perspectives.svg'

type Props = {
  val: string
  type: string
  areaCode: string
}
const AccountReveal: FC<Props> = ({ val, type, areaCode }) => {
  return (
    <div className="border flex items-center border-[#ECECEC] bg-[#F8FAFC] pl-4 py-4 rounded-md">
      {type === AccountType.SMS ? (
        <>
          <span className="text-[#A5A7B1] text-sm pr-1.5">{areaCode}</span>
          <ReactSVG src={perspectives} />
        </>
      ) : (
        <ReactSVG src={e_default} />
      )}
      <span className="text-[#A5A7B1] text-sm pl-3">{val}</span>
    </div>
  )
}

export default AccountReveal
