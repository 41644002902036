import { FC, ReactElement, useState } from 'react'
import detailShow from '../assets/svg/detailShow.svg'
import { ReactSVG } from 'react-svg'
import { cn } from '@brisk/ui'

interface IDetailCardItemProps {
  title: string
  content?: ReactElement
}

const DetailCardItem: FC<IDetailCardItemProps> = ({ title = '', content = <></> }) => {
  const [show, setShow] = useState(false)
  return (
    <div className="border-b">
      <div
        className="h-11 flex items-center pl-3 pr-3 cursor-pointer relative"
        onClick={() => setShow(!show)}
      >
        {title}
        <ReactSVG
          src={detailShow}
          className={cn('absolute right-3 top-1/2 -translate-y-1/2', show && 'rotate-180')}
        />
      </div>
      <div className={cn(show ? 'block' : 'hidden')}>{content}</div>
    </div>
  )
}

export default DetailCardItem
