import '@brisk/ui/styles.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Toaster } from '@brisk/ui'
import 'react-photo-view/dist/react-photo-view.css'
import { initApiClient } from './http-client/initApiClient'

initApiClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
    <Toaster position="top-center" richColors theme="light" />
  </React.StrictMode>
)
