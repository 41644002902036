import {
  apiClient,
  ApiRespSerializeFn,
  ClientResponse,
} from "../../apiClient";

interface RequestBody {
  groupIdList: Array<number | string>;
}
/**
 * CustomerSessionVO
 */
export interface SessionListResponseBody {
  /**
   * 渠道的icon图片地址
   */
  channelIconUrl?: string;
  /**
   * 渠道编号
   */
  channelId?: number;
  /**
   * 渠道名称
   */
  channelName?: string;
  /**
   * 渠道类型 独立站：WEBSITE
   */
  channelType?: string;
  /**
   * 客户头像
   */
  customerAvatar?: string;
  /**
   * 客户编号
   */
  customerId?: number;
  /**
   * 客户名称
   */
  customerName?: string;
  /**
   * 群编号
   */
  imGroupId?: number;
  /**
   * 最后一次消息内容
   */
  lastTalkContent?: string;
  /**
   * 最后一次消息时间
   */
  lastTalkTime?: Date;
  /**
   * 分配给某个小组
   */
  orgId?: number;
  /**
   * 组织架构名称
   */
  orgName?: string;
  /**
   * 会话编号
   */
  sessionId?: number;
  /**
   * 会话发起时间
   */
  startTime?: Date;
  /**
   * 状态：0-待分配 1-已分配 10-完结
   */
  status?: number;
  /**
   * 当前接待的客服编号
   */
  userId?: number;
  [property: string]: number | string | Date | undefined | object | [];
}

interface ApiResp extends ClientResponse {
  data: SessionListResponseBody[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 通过群编号批量查询正在进行中的会话
 * @param payload
 * @returns
 */
export const fetchSessionListByGroupId = async (
  payload: RequestBody,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/session/list`, {
    ...payload,
  });

  return serialize(data);
};
