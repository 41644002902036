import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

export interface paramsList {
    /**
     * 账号类型 PHONE:手机 EMAIL:邮箱
     */
    accountType?: string;
    /**
     * 加入时间
     */
    createTime?: Date;
    /**
     * 离职时间
     */
    departDate?: Date;
    /**
     * 邮箱
     */
    email?: string;
    /**
     * 加入时间
     */
    hireDate?: Date;
    /**
     * 邀请id
     */
    inviteId?: number;
    /**
     * 邀请状态  0-待加入 1-已加入
     */
    inviteStatus?: number;
    /**
     * 用户姓名
     */
    nickname?: string;
    /**
     * 手机号
     */
    phone?: string;
    /**
     * 预设角色
     */
    preRoleIds?: number[];
    /**
     * 预设角色名
     */
    preRoleName?: string;
    /**
     * 预设团队
     */
    preTeamIds?: number[];
    /**
     * 预设团队名
     */
    preTeamName?: string;
    roleIds?:     string;
    /**
     * 角色
     */
    roleName?: string;
    /**
     * 小组
     */
    teamName?: string;
    /**
     * 离职交接用户id
     */
    transferUserId?: number;
    /**
     * 离职交接用户Name
     */
    transferUserName?: string;
    /**
     * 用户编号
     */
    userId?: number | string;
}

interface ApiResp extends ClientResponse {
    data: paramsList[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
    return resp;
};

/**
 * 会话数据
 * @returns
 */
export const fetchBgUserOnJobList = async (userId: string): Promise<ApiResp> => {
    const { data } = await apiClient.get<ApiResp>(`/bg/user/onjob/list/${userId}`);

    return serialize(data);
};
