import axios from "axios";

const language_http = {
    'zh-CN': 'zh_CN',
    zh: 'zh_CN',
    en: 'en_US'
    // tc: 'zh_TW',
    // ar: 'ar_SA'
}

export enum RESPONSE_CODE {
    success = 10000,
    password_error = 10009, // 密码错误
    token_overdue = 9000, // token过期
    imgCaptcha_error = 10012, // 图片验证码错误
}

export type ClientResponse = {
    code: number;
    msg: string;
    traceId: string;
};
export type ApiReqFn<Payload, Data extends ClientResponse> = (
    payload: Payload,
) => Promise<Data>;

export interface ApiRespSerializeFn<
    ApiResp extends ClientResponse,
    Data extends ClientResponse,
> {
    (resp: ApiResp): Data;
}

// const navigate = useNavigate()

export const apiClient = axios.create({
    // baseURL: import.meta.env.VITE_BASE_URL,
    baseURL: "/api",
    timeout: 58_000,
    adapter: "fetch",
    headers: {
        "Content-Type": "application/json",
        "Accept-Language": localStorage.getItem('i18nextLng') ? language_http[localStorage.getItem('i18nextLng')] : 'en_US'
    },
});

apiClient.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng') ? language_http[localStorage.getItem('i18nextLng')] : 'en_US'
    return config
})