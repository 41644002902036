import { useEffect, useRef, useState } from 'react'

export const useCaptchaCountdown = (initialTime = 60) => {
  const [isSentCaptcha, setIsSentCaptcha] = useState(false)
  const [isCounting, setIsCounting] = useState(false)
  const [count, setCount] = useState(initialTime)
  const timerRef = useRef<NodeJS.Timeout>()

  const reset = (): void => {
    clearInterval(timerRef.current)
    setIsCounting(false)
    setCount(initialTime)
  }
  useEffect(() => {
    reset()
    setIsSentCaptcha(false)
  }, [])

  const startCount = (): void => {
    setIsSentCaptcha(true)
    setIsCounting(true)

    timerRef.current = setInterval(() => {
      setCount((count) => {
        if (count <= 0) {
          reset()
        }
        return count - 1
      })
    }, 1_000)
  }

  return { isCounting, startCount, count, reset, isSentCaptcha }
}
