import { ApiReqFn, httpClient, ApiRespSerializeFn, ClientResponse,} from '../../http-client/httpClient'

interface RequestBody {
    type: string
    account: string
    code: string
}

interface ApiResp extends ClientResponse {}
interface Data extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, Data> = (resp) => {
    return {
        ...resp
    }
}

// 修改昵称
export const fetchPhoneOrEmail: ApiReqFn<RequestBody, Data> = async (payload) => {
    const { data } = await httpClient.put<ApiResp>(`/bg/account/bind/account`, { ...payload })

    return serialize(data)
}
