import { FC, useEffect } from 'react'
import NavBodyLayout from '../Components/NavBodyLayout'
import { TipsIcon, TitleComp } from '../ReceptionWelcomeWords'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'

import AddSourceFormList from '../Components/AddSourceFormList'
import { Button, Switch, Textarea } from '@brisk/ui'

import { some } from 'lodash'
import { message } from '@renderer/components/message'
import { ISaveSourceConfig, fetchSaveSource, fetchSaveSourceConfig } from '@renderer/api'
import { RESPONSE_CODE } from '@renderer/http-client/httpClient'
import { useTranslation } from 'react-i18next'

let saveDefault

const index: FC = () => {
  const { handleSubmit, control, setValue } = useForm<ISaveSourceConfig>({
    defaultValues: {
      enable: 0,
      guiding: '',
      formList: [],
      mandatory: 0
    }
  })
  const { t } = useTranslation()
  const { data: saveSourceConfig, refetch } = useQuery({
    queryKey: [fetchSaveSourceConfig.name],
    queryFn: async () => {
      const res = await fetchSaveSourceConfig()
      return res?.data
    }
  })

  const { mutateAsync: handleSaveSource } = useMutation({
    mutationFn: fetchSaveSource
  })

  const onSubmit = async (formData): Promise<void> => {
    const listNoType = some(
      formData.formList,
      (it) => it.type === '' || it.type === undefined || it.type === null
    )
    if (listNoType) {
      message(t('login.pleasecompleteforminformation'), 'error')
      return
    }
    const res = await handleSaveSource(formData)
    if (res?.code === RESPONSE_CODE.success) {
      message(t('knowledgeBase.SaveSuccessful'), 'success')
      refetch()
    }
  }

  const handleSetFormValue = (data: ISaveSourceConfig): void => {
    const { enable, guiding, formList, mandatory } = data
    setValue('enable', enable)
    setValue('guiding', guiding)
    setValue('formList', formList)
    setValue('mandatory', mandatory)
  }

  useEffect(() => {
    if (saveSourceConfig) {
      handleSetFormValue(saveSourceConfig)
      saveDefault = { ...saveSourceConfig }
    }
  }, [saveSourceConfig])

  return (
    <NavBodyLayout
      title={[t('autoReception.receptionSettings'), t('infoCollection.infoCollectionTitle')]}
    >
      <form className="w-full h-full text-sm" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col w-full h-full">
          <div className="h-[54px] shrink-0 border-b">
            <div className="flex items-center pl-3 h-full font-medium text-base">
              {t('infoCollection.infoCollectionTitle')}
            </div>
          </div>
          <div className="flex-1 shrink-0 overflow-y-auto pt-4">
            <TitleComp title={t('infoCollection.Setup')} />
            <Controller
              name="enable"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center gap-2 pl-3 mb-4">
                    <Switch
                      checked={Boolean(field.value)}
                      onCheckedChange={(e) => field.onChange(Number(e))}
                    />
                    <span>
                      {t(
                        'infoCollection.Innewchatsessioncollectinformationwillappearwhenvisitorsendsfirstmessage'
                      )}
                    </span>
                  </div>
                </>
              )}
            />
            <Controller
              name="guiding"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex flex-col pl-3 mb-4 gap-2">
                    <span>{t('infoCollection.Prompt')}</span>
                    <Textarea
                      {...field}
                      className="w-[472px] h-[136px] border rounded-md"
                      placeholder={t('infoCollection.Pleaseenter')}
                      value={field.value || ''}
                      maxLength={400}
                      style={{
                        resize: 'none'
                      }}
                    />
                  </div>
                </>
              )}
            />
            <Controller
              name="formList"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex flex-col pl-3 mb-4 gap-2">
                    <span className="flex items-center gap-1">
                      {t('infoCollection.Form')}
                      <TipsIcon
                        content={t(
                          'autoReception.ItisrecommendedselectbothPhoneEmailconsultationswithoutsubmittingthisinformation'
                        )}
                      />
                    </span>
                    <AddSourceFormList
                      listData={
                        field?.value?.length ? field?.value : [{ type: '', required: false }]
                      }
                      field={field}
                    />
                  </div>
                </>
              )}
            />
            <Controller
              name="mandatory"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-start pl-3 gap-2">
                    <Switch
                      checked={Boolean(field.value)}
                      onCheckedChange={(e) => field.onChange(Number(e))}
                    />
                    <div className="flex flex-col gap-2">
                      <span>{t('infoCollection.MandatoryCollection')}</span>
                      <span className="text-lingheGray text-xs">
                        {t('infoCollection.Whenenabledvisitorsmustsubmitformbefore')}
                      </span>
                    </div>
                  </div>
                </>
              )}
            />
          </div>

          <div className="h-16 shrink-0 border-t drop-shadow">
            <div className="flex items-center gap-2 pl-3 h-full">
              <Button type="submit">{t('common.save')}</Button>
              <Button
                variant="outline"
                onClick={() => {
                  handleSetFormValue(saveDefault)
                }}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </NavBodyLayout>
  )
}

export default index
