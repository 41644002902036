import type { FC, ReactNode } from 'react'
// import pkg from '../../../../../../../../package.json'
import { useTranslation } from 'react-i18next'
import type { ButtonProps } from '@brisk/ui'
import { Button } from '@brisk/ui'
import { useCreation } from 'ahooks'
import AppUploadLoading from '@renderer/assets/svg/appUploadLoading.svg?react'
import { useAppUpStore } from '@renderer/store/useAppUpStore'
import { useCheckUpdate } from '@renderer/hooks/appUpdate/useCheckUpdate'

const WithRegard: FC = () => {
  const { t } = useTranslation()
  const path = 'www.aiochat.im'

  const { isAppUp, openDialog, setVersion } = useAppUpStore(
    ({ isAppUp, openDialog, setVersion }) => ({
      isAppUp,
      openDialog,
      setVersion
    })
  )

  // 检查更新
  const { loading: checkUpdateL, run: checkUpdateR } = useCheckUpdate({
    manual: true,
    onSuccess: ({ isNewVersion, version }) => {
      if (!isNewVersion) return

      setVersion(version)
      openDialog()
    }
  })

  const upBtnProps = useCreation<ButtonProps>(
    () =>
      isAppUp
        ? {
            variant: 'destructive',
            children: loadingRender(t('systemSetting.发现更新'), checkUpdateL)
          }
        : {
            variant: 'default',
            children: loadingRender(t('systemSetting.检测更新'), checkUpdateL)
          },
    [isAppUp, checkUpdateL, t]
  )

  return (
    <div className="with_regard">
      <h1 className="text-base font-[500] pb-2">{t('common.Withregard_useAIOChat')}</h1>

      <p className="flex items-center">
        {/* <span className="pr-2 text-sm text-[#A5A7B1]">{pkg.version}</span> */}
        <span className="pr-2 text-sm text-[#A5A7B1]">1.4.0</span>

        <a
          className="font-[500] text-sm text-[#247CFF]"
          href="https://www.aiochat.im"
          target="_blank"
          rel="noreferrer"
        >
          {path}
        </a>

        {/* 更新检查按钮 */}
        <Button className="ml-6" size="sm" onClick={checkUpdateR} {...upBtnProps} />
      </p>
    </div>
  )
}

/**
 * 条件渲染 loading
 */
function loadingRender(node: ReactNode, loading?: boolean): ReactNode {
  return loading ? <AppUploadLoading width={16} className="animate-spin" /> : node
}

export default WithRegard
