import { FC, memo, useEffect, useState } from 'react'

const PreviewChat: FC = () => {
  const [token, setToken] = useState('')

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search.split('?')[1])
    const token = params.get('token')
    setToken(token)
  }, [])

  return (
    <div className="w-[100vw] h-[100vh] bg-[#F3F8FE] flex items-center justify-center">
      <iframe
        src={`${import.meta.env.VITE_PREVIEW_URL}?token=${token}&hide=1`}
        style={{
          border: 'none',
          width: '40%',
          height: '90%',
          maxWidth: '1320px',
          maxHeight: '1776px'
        }}
      ></iframe>
    </div>
  )
}

export default memo(PreviewChat)
