import { FC, memo } from 'react'

const KnowlegeLib: FC = () => {
  return (
    <div className="w-full h-full overflow-hidden relative">
      <div className="w-full h-full overflow-y-aut p-3">KnowlegeLib</div>
    </div>
  )
}

export default memo(KnowlegeLib)
