import Juggle from 'jugglechat-websdk'
import { useUserStore } from '../../store/useUserStore'
import {
  // ChatListStatus,
  EmployeeStatus
} from '../../api/model'
import { useConnectStatus } from '@renderer/store/useConnectStatus'
import { findIndex } from 'lodash'
import {
  handleLocalReadMessage,
  messageHandler,
  publicSingOut,
  saveTalkingMsg,
  sendMessageThen
} from '@renderer/utils/juggleHandles.js'
import { fetchIMToken } from '@brisk/api'
import * as qiniu from 'qiniu-js'
import { useAssignedStore } from '@renderer/store/useAssignedStore.js'
// import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { message as tipsMessage } from '@renderer/components/message'
import { getConversations } from '@renderer/hooks/useJuggle'
import { MESSAGE_TYPE } from '@brisk/utils'
import { showCustomNotification } from '@renderer/utils/ipcHandler'
const appkey = import.meta.env.VITE_APP_KEY
const navList = [import.meta.env.VITE_SDK_URL]

export const juggle = Juggle.init({
  appkey,
  navList,
  upload: qiniu
})

const { Event, ConnectionState } = juggle
export const DIT_MSG_NAME = {
  OTHER_SIGIN: 'cs:notice'
}

const diyMsgs = [
  {
    name: DIT_MSG_NAME.OTHER_SIGIN,
    isCount: false,
    isStorage: false,
    messageType: DIT_MSG_NAME.OTHER_SIGIN,
    contentType: DIT_MSG_NAME.OTHER_SIGIN
  }
]

juggle.registerMessage(diyMsgs)

juggle.on(Event.STATE_CHANGED, ({ state }) => {
  const userStore = useUserStore.getState()
  const connectStatusStore = useConnectStatus.getState()

  console.log(`state==>${state}`)
  if (state === ConnectionState.CONNECTING) {
    console.log('im is connecting')
  }
  if (state === ConnectionState.CONNECTED) {
    userStore.setStatus(userStore.user?.status || EmployeeStatus.ONLINE)
    connectStatusStore.setConnectStatus(true)
    console.log('im is connected')
  }
  if (state === ConnectionState.DISCONNECTED) {
    if (userStore.outLogin) return
    userStore.setStatus(userStore.user?.status || EmployeeStatus.OFFLINE)
    connectStatusStore.setConnectStatus(false)
    console.log('im is disconnected')
  }
})

juggle.on(Event.MESSAGE_RECEIVED, async (message) => {
  console.log('消息监听message--->', message)

  if (!message) return

  const userStore = useUserStore.getState()

  const { name: msgName, content, conversationId } = message
  const { messageType, notice } = content || {}
  const { noticeType, content: noticeContent } = notice || {}

  if (
    msgName === DIT_MSG_NAME.OTHER_SIGIN &&
    messageType === 'CUSTOMIZE_SYSTEM_NOTICE' &&
    noticeType === 'SQUEEZE_OUT_OF_LINE' &&
    noticeContent === userStore.user?.token
  ) {
    tipsMessage('登录被挤下线，请重新登录')
    publicSingOut()
    return
  }

  if (conversationId === '-1' && notice) {
    const { status } = noticeContent || {}

    if (noticeType === 'USER_STATUS_SWITCH') {
      userStore.setStatus(status)

      if (status === EmployeeStatus.ONLINE) {
        try {
          const { conversations } = await getConversations()
          useAssignedStore.getState().setAllConversations(conversations)
        } catch (error) {
          console.error('获取会话列表失败:', error)
        }
      }
    }
    return
  }

  // 处理会话开启/删除
  if (
    msgName === MESSAGE_TYPE.CSSESSION &&
    (noticeType === 'SESSION_USER_RESTART' ||
      noticeType === 'SESSION_USER_COMPLETE' ||
      noticeType === 'SESSION_NOTICE_IN' ||
      noticeType === 'USER_ALLOCATION')
  ) {
    useAssignedStore.getState().setShouldUpdate()
  }
  if (content.content || content.url) {
    const lg = localStorage.getItem('i18nextLng') || 'en'
    const imageNotify = lg.toLowerCase().indexOf('en') !== -1 ? '[Image]' : '[图片]'
    showCustomNotification(
      message.conversationTitle,
      msgName === MESSAGE_TYPE.IMAGE ? imageNotify : content.content,
      message.conversationPortrait,
      message.conversationId
    )
  }

  saveTalkingMsg(message)
  sendMessageThen(message)

  const assignedList = useAssignedStore.getState().assignedList
  const i = findIndex(assignedList, (item) => item?.conversationId === conversationId)
  if (i >= 0) {
    messageHandler(i, [message])
  }
})

juggle.on(Event.CONVERSATION_ADDED, async ({ conversations }) => {
  console.log('新增conversations===>', conversations)
  useAssignedStore.getState().setAllConversations(conversations, 'add')
})

juggle.on(Event.MESSAGE_READ, (notify) => {
  console.log('消息已读监听-------------------->', notify)
  handleLocalReadMessage(notify.conversationId)
})

juggle.on(Event.MESSAGE_RECALLED, (notify) => {
  console.log('消息撤回监听', notify)
})

juggle.on(Event.CONVERSATION_REMOVED, ({ conversations }) => {
  console.log('会话删除监听==>', conversations)

  useAssignedStore.getState().setAllConversations(conversations, 'del')
  const list = useAssignedStore.getState().assignedList
  const newList = list.filter((item) => item.conversationId !== conversations[0].conversationId)
  useAssignedStore.getState().setAssignedList(newList)
  /** 客服主动结束会话 跳转全部一栏 */
  // if (+localStorage.getItem('shutdownBySelf') === 1)
  //   useCurrentAndSearch.getState().setCurrentTab(ChatListStatus.FINISHED)

  setTimeout(() => {
    localStorage.setItem('shutdownBySelf', '0')
  }, 0)
})

export const connectIM = async () => {
  const user = useUserStore.getState().user!
  const data = await fetchIMToken()
  const token = data.data.IMToken
  juggle.connect({ token: token, userId: user?.userId }).then(
    async (result) => {
      console.log('---->', result)
    },
    (error) => {
      console.log('---->', error)
    }
  )
}

export const disconnectIM = () => {
  juggle.disconnect()
}
