import {
  Button,
  CardContent,
  CardFooter,
  cn,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@brisk/ui'
import { FC, useContext, useEffect, useState } from 'react'
import { SignInHeader } from './components/SignInHeader'
// import { SignInSwitcher } from './components/SignInSwitcher'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SignInType, TerminalType } from '../../api/model'
import { APP_ROUTER_PATH } from '../../router'
import { object, string, ZodType } from 'zod'
import { useSignStore } from '../../store/useSignStore'
import { useMutation } from '@tanstack/react-query'
import { fetchSendCaptcha, mutationSignInByCaptcha, mutationSignInByPassword } from '../../api'
import { useUserStore } from '../../store/useUserStore'
import { fetchLoginCaptchaCheck } from '@brisk/api'
import { LockKeyhole } from 'lucide-react'
import { inputIconClassName } from '@renderer/utils/consts'
import { inconLeftClassName } from './CompanyInfo'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCaptchaCountdown } from '../../hooks/useCaptchaCountdown'
import { generateUrlWithQueryString, isElectron, regEmail } from '../../utils'
import { TCaptchaValidType } from './CaptchaValid'
import { NoFocusInput } from './components/NoFocusInput'
import { NoFocusPasswordInput } from './components/NoFocusPasswordInput'
import { message } from '@renderer/components/message'
import { ReloadIcon } from '@radix-ui/react-icons'
import { ReactSVG } from 'react-svg'
import captacha from '../../assets/svg/captacha.svg'
// import ImageCaptcha from './components/ImageCaptcha'
// import { usePasswordErrorCount } from '@renderer/store/usePasswordErrorCount'
import { SignInContext } from '.'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import querystring from 'query-string'
import GraphicalVerification from './components/graphicalVerification/graphicalVerification'
import { useGraphicalVerification } from './hooks/graphicalVerification'
import { trackDataType } from './InputAccount'
import { useSaveLoginTime } from '@renderer/store/useSaveLoginTime'

type IFormInput = {
  // pictureCode: string
  captcha: string
  password: string
}

const defaultFormValues: IFormInput = {
  // pictureCode: '',
  captcha: '',
  password: ''
}

let useFormInput: IFormInput = defaultFormValues

export const SignInByPassport: FC = () => {
  // const [showImageCaptcha, setShowImageCaptcha] = useState(false) // 图形验证码弹框
  const [currentTabValue, setCurrentTabValue] = useState(SignInType.PASSWORD)
  const { search } = useLocation()
  const navigate = useNavigate()
  const params = querystring.parse(search)
  const tenantName = params.tenantName?.toString()
  const { setLoading } = useContext(SignInContext)
  const { setLoginTime } = useSaveLoginTime()

  const { account, accountType, currentTenant, setInputAccountToSign } = useSignStore()
  const setUser = useUserStore((state) => state.setUser)
  const { isCounting, startCount, count, reset, isSentCaptcha } = useCaptchaCountdown()
  const {
    captchaStatus,
    setCaptchaStatus,
    status,
    setStatus,
    setCallOpen,
    backgroundImage,
    jigsawImage,
    callOpen,
    initFetchSliderCaptcha
  } = useGraphicalVerification()
  // const { errorCount, setErrorCount } = usePasswordErrorCount()
  //-----------------------------------------------------------------------
  // define query
  //-----------------------------------------------------------------------
  const { mutateAsync: mutateAsyncSendCaptcha, isPending } = useMutation({
    mutationFn: fetchSendCaptcha
  })
  const { mutateAsync: fetchAsyncLoginCaptchaCheck } = useMutation({
    mutationFn: fetchLoginCaptchaCheck
  })
  const { mutateAsync: mutateAsyncSignInByCaptcha, isPending: captachaLoading } = useMutation({
    mutationFn: mutationSignInByCaptcha
  })
  const { mutateAsync: mutateAsyncSignInByPassword, isPending: passwordLoading } = useMutation({
    mutationFn: mutationSignInByPassword
  })
  const { t } = useTranslation()
  //-----------------------------------------------------------------------
  // valid
  //-----------------------------------------------------------------------
  const captchaSchema = object({
    captcha: string().min(1, t('login.pleaseenterverificationcode'))
  })
  const passwordSchema = object({
    // pictureCode: string().min(1, '请输入图形验证码'),
    password: string().min(1, t('login.pleaseenterpassword'))
  })
  const getSchema = (tab: SignInType): ZodType =>
    tab === SignInType.SMS ? captchaSchema : passwordSchema
  // const { data: imgCodeData } = useQuery({
  //   queryKey: [fetchImgCaptcha.name],
  //   queryFn: () => fetchImgCaptcha()
  // })

  //-----------------------------------------------------------------------
  // form
  //-----------------------------------------------------------------------
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(getSchema(currentTabValue))
  })
  // 刷新
  const trackRefresh = async () => {
    if (callOpen) {
      await initFetchSliderCaptcha()
      return
    }
    setCallOpen(true)
    await initFetchSliderCaptcha()
  }
  const handleRequest = async (
    formData,
    captchaData?: { imageCaptcha: string; tempToken: string } | trackDataType,
    status = false
  ): Promise<void> => {
    if (currentTabValue === SignInType.SMS) {
      const { data } = await mutateAsyncSignInByCaptcha({
        // tenantId: currentTenant!.tenantId,
        account: account,
        captcha: formData.captcha,
        terminalType: isElectron ? TerminalType.PC : TerminalType.BROWSER
      })
      if (!data) {
        control.setError('captcha', { message: t('login.verificationcodeincorrect') })
        return
      }
      setInputAccountToSign(true)
      setUser(data)
      /** 存储登录时间 */
      setLoginTime(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
      // setErrorCount(0)
      message(t('login.loginsuccessful'), 'success')
    } else {
      try {
        const res = await mutateAsyncSignInByPassword({
          account: account,
          password: formData.password,
          terminalType: isElectron ? TerminalType.PC : TerminalType.BROWSER,
          // tempToken: captchaData?.tempToken ? captchaData.tempToken : null,
          ...captchaData
          // captchaCode: captchaData?.imageCaptcha ? captchaData.imageCaptcha : null
        })
        setInputAccountToSign(true)
        /** 存储登录时间 */
        setLoginTime(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
        setUser(res?.data)
        if (res?.code === 10009) {
          setStatus('success')
          setTimeout(() => {
            setCallOpen(false)
          }, 500)
          message(res?.msg, 'error')
          return
        }
        if (res?.code === 10012) {
          setStatus('refreshError')
          message(res?.msg, 'error')
          return
        }
        if (res?.code === 10010 && !status) {
          setStatus('initialise')
          trackRefresh()
          return
        }
        // setShowImageCaptcha(false)
        message(t('login.loginsuccessful'), 'success')
      } catch (error) {
        // setErrorCount(error?.code)
        console.log('error', error)
      }
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (formData: IFormInput) => {
    useFormInput = formData
    handleRequest(formData)
  }
  // 关闭滑动弹窗
  const handleFail = () => {
    setCallOpen(false)
    setCaptchaStatus(false)
  }
  // 手机验证码调用
  const onCaptchaSuccess = (tackData: trackDataType) => {
    handleClickSendCaptcha(tackData)
  }
  // 手机验证码
  const handleClickSendCaptcha = async (tackData?: trackDataType): Promise<void> => {
    const sendCaptcha = {
      account: account,
      type: accountType,
      token: ''
    }
    if (isCounting) {
      return
    }
    if (!regEmail.test(account)) {
      if (!captchaStatus) {
        setStatus('initialise')
        trackRefresh()
        setCaptchaStatus(true)
      } else {
        const data = await fetchAsyncLoginCaptchaCheck({ phone: account, ...tackData })
        if (data?.code === 10012) {
          setStatus('refreshError')
          message(data?.msg, 'error')
        }
        if (data?.code === 10000) {
          setStatus('success')
          sendCaptcha.token = data?.data
          setTimeout(() => {
            setCallOpen(false)
          }, 500)
          asyncSendCaptcha(sendCaptcha)
        }
      }
    } else {
      delete sendCaptcha.token
      asyncSendCaptcha(sendCaptcha)
    }
  }
  const asyncSendCaptcha = async (sendCaptcha) => {
    try {
      const res = await mutateAsyncSendCaptcha(sendCaptcha)
      if (res?.code !== 10000) {
        message(res?.msg, 'error')
        reset()
        return
      }
      setCaptchaStatus(false)
      startCount()
    } catch (e) {
      setCaptchaStatus(false)
    }
  }
  useEffect(
    () => setLoading(captachaLoading || passwordLoading),
    [captachaLoading, passwordLoading]
  )

  return (
    <>
      {/*<SignInSwitcher />*/}
      <SignInHeader
        showBack={true}
        title={() => (
          <div>
            <p>{t('login.beentering')}</p>
            {tenantName}
            <div
              style={{
                lineHeight: '33px',
                marginTop: '3px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2
              }}
            >
              {currentTenant?.tenantName}
            </div>
          </div>
        )}
      />
      <GraphicalVerification
        captchaStatus={captchaStatus}
        onSuccess={(trackData) => handleRequest(useFormInput, trackData)}
        onCaptchaSuccess={onCaptchaSuccess}
        onFail={handleFail}
        onRefresh={trackRefresh}
        callOpen={callOpen}
        {...backgroundImage}
        {...jigsawImage}
        status={status}
      />
      {/*<ImageCaptcha*/}
      {/*  open={showImageCaptcha}*/}
      {/*  onOpenChange={() => setShowImageCaptcha(false)}*/}
      {/*  onConfirm={(data) => {*/}
      {/*    setShowImageCaptcha(false)*/}
      {/*    handleRequest(saveFormData, data, true)*/}
      {/*  }}*/}
      {/*/>*/}
      <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4">
        <CardContent className="grid gap-8">
          <Tabs
            defaultValue={SignInType.PASSWORD}
            onValueChange={(value) => {
              setCurrentTabValue(value as SignInType)
            }}
            className=""
          >
            <TabsList className="mb-4 shadow-none gap-[2rem] w-full bg-inherit">
              <TabsTrigger
                className="TabsTrigger data-[state=active]:bg-[none !important]"
                value={SignInType.PASSWORD}
              >
                <span
                  data-state={currentTabValue === SignInType.PASSWORD ? 'active' : 'inactive'}
                  className="flex pb-[0.5rem] font-medium cursor-pointer TabsTrigger-span px-1 text-xl"
                >
                  {t('login.cipher')}
                </span>
              </TabsTrigger>
              <TabsTrigger
                className="TabsTrigger data-[state=active]:bg-[none !important]"
                value={SignInType.SMS}
              >
                <span
                  data-state={currentTabValue === SignInType.SMS ? 'active' : 'inactive'}
                  className="flex pb-[0.5rem] font-medium cursor-pointer TabsTrigger-span px-1 text-xl"
                >
                  {t('login.verificationcode')}
                </span>
              </TabsTrigger>
            </TabsList>
            <TabsContent value={SignInType.SMS}>
              <div className="grid gap-4">
                <div>
                  <Input value={account} disabled className="h-12 w-full text-lingheTextGray" />
                </div>
                <div className="grid gap-3">
                  <Controller
                    name="captcha"
                    control={control}
                    render={({ field }) => (
                      <div className="border w-full h-12 radios rounded-sm flex relative">
                        <ReactSVG src={captacha} className={inputIconClassName} />
                        <NoFocusInput
                          placeholder={t('login.pleaseenterverificationcode')}
                          maxLength={6}
                          className={cn(
                            'flex-1 h-full pl-9 text-sm border-none outline-none',
                            errors.captcha
                              ? 'focus-visible:ring-lingheError'
                              : 'focus-visible:ring-ring'
                          )}
                          {...field}
                        />
                        <Button
                          variant="link"
                          className="h-full absolute right-0 top-1/2 -translate-y-1/2 hover:no-underline"
                          onClick={(e) => {
                            e.preventDefault()
                            handleClickSendCaptcha()
                          }}
                          disabled={isPending}
                        >
                          {isPending && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}
                          <span className="absolute left-0 text-border">|</span>
                          {isCounting
                            ? `${count}s`
                            : isSentCaptcha
                              ? t('common.resend')
                              : t('login.sendverificationcod')}
                        </Button>
                      </div>
                    )}
                  />
                  {errors.captcha && (
                    <p className="bottom-8 text-lingheError text-sm">
                      {t('login.verificationcodeincorrect')}
                    </p>
                  )}
                  {isSentCaptcha && (
                    <p className="text-sm text-lingheGray">
                      {t('login.gonadvalidverificationcode', { msg: account })}
                    </p>
                  )}
                </div>
              </div>
            </TabsContent>

            <TabsContent value={SignInType.PASSWORD}>
              <div className="grid gap-4  ">
                {/* <div className="border w-full h-12 radios rounded-sm flex relative">
                  {errors.pictureCode && (
                    <p className="absolute -top-6 text-lingheError text-sm">
                      图形验证码错误，请重新输入
                    </p>
                  )}
                  <Controller
                    name="pictureCode"
                    control={control}
                    render={({ field }) => (
                      <>
                        <ReactSVG src={captacha} className={inputIconClassName} />
                        <NoFocusInput
                          placeholder="请输入图形验证码"
                          className={cn(
                            'flex-1 h-full pl-10 pr-10 w-full text-sm border-none outline-none',
                            errors.pictureCode
                              ? 'focus-visible:ring-lingheError'
                              : 'focus-visible:ring-ring'
                          )}
                          {...field}
                        />
                        <img
                          onClick={() => refetch()}
                          className="w-20 h-[95%] absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                          src={`data:image/png;base64,${imgCodeData?.data?.base64}`}
                          alt=""
                        />
                      </>
                    )}
                  />
                </div> */}

                <div>
                  <Input value={account} disabled className="h-12 w-full text-lingheTextGray" />
                </div>
                <div className="grid gap-3 relative">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <>
                        <NoFocusPasswordInput
                          placeholder={t('login.pleaseenterpassword')}
                          className={cn(
                            'pl-10 pr-10 h-12',
                            errors.password
                              ? 'focus-visible:ring-lingheError'
                              : 'focus-visible:ring-ring'
                          )}
                          {...field}
                        />
                        {errors.password && (
                          <p className="absolute -bottom-8 text-lingheError text-sm">
                            {t('login.passwordincorrectpleasereenter')}
                          </p>
                        )}
                      </>
                    )}
                  />
                  <LockKeyhole strokeWidth={1.15} size={20} className={inconLeftClassName} />
                </div>
              </div>
            </TabsContent>
          </Tabs>
          <Button onClick={handleSubmit(onSubmit)} className="w-full h-12">
            {t('login.log_in')}
          </Button>
          <div className="h-full relative">
            <span
              className="text-sm text-gray-500 cursor-pointer"
              onClick={() => {
                const path = generateUrlWithQueryString(APP_ROUTER_PATH.Captcha_Valid, {
                  redirect: APP_ROUTER_PATH.RESET_PASSWORD,
                  captchaValidType: TCaptchaValidType.RESET_PASSWORD
                })
                navigate(path)
              }}
            >
              {t('login.forgot_password')}
            </span>
          </div>
        </CardContent>
      </form>

      <CardFooter className="flex justify-between"></CardFooter>
    </>
  )
}
