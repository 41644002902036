/**
 * putDispatchStatus
 *
 * @desc 描述：发送消息翻译启用、禁用
 * @desc 描述：接口文档名称 - 发送消息翻译启用禁用配置
 * @desc 描述：/translate/config/{status}/user
 */

/* =================================================== */

import type { ITranslateConfig } from './types'
import { get } from 'lodash'

import { httpClient } from '../../http-client/httpClient'

/**
 * 发送消息翻译启用、禁用
 */
export async function putDispatchStatus(status: string): Promise<ITranslateConfig> {
  const res = await httpClient.put(`/translate/config/${status}/user`)

  return get(res, 'data.data')
}
