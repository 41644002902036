import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * LanguageListVO
 */
export interface ILanguageList {
  /**
   * 展示语言
   */
  language?: string;
  /**
   * 引擎翻译使用语言代码
   */
  languageCode?: string;
  /**
   * id
   */
  languageId?: string;
  /**
   * 翻译引擎
   */
  translateEngine?: string;
  /**
   * 通用语言码
   */
  universalLanguage?: string;
}

interface ApiResp extends ClientResponse {
  data: ILanguageList[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

export const fetchLanguageList = async (engine: string): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/language/${engine}/list`);

  return serialize(data);
};
