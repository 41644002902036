import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * TagEditDTO，编辑
 */
export interface IEditTagReq {
  /**
   * 颜色码
   */
  colorCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 标签ID
   */
  tagId: number;
  /**
   * 标签名
   */
  tagName: string;
}
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 编辑标签
 * @param params
 * @returns
 */
export const fetchEditTag = async (params: IEditTagReq): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(`/bg/tag`, params);

  return serialize(data);
};
