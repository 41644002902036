import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ColumnDef, flexRender } from '@tanstack/react-table'

import { TableBody, TableCell, TableHead, TableHeader, TableRow, cn } from '@brisk/ui'
import { useCustomTable } from '@renderer/hooks/useCustomTable'
import './ManagerTable.css'
import { IPageationRef } from '@renderer/pages/manager/AddCustomer'
import PaginationComp from '../PaginationComp'
import {useTranslation} from "react-i18next";

interface ExtendedColumnDef {
  fixed?: 'left' | 'right'
  style?: React.CSSProperties
  className?: string
}

export type DataWithId = { id: React.Key } // 确保数据对象具有唯一的 id 属性

export type CustomTableProps<T extends DataWithId> = {
  data: T[]
  columns: ColumnDef<T>[]
  rowCount?: number
  onRowSelectionChange?: (selectedRows: T[]) => void
  emptyElement?: () => React.ReactNode
  onPageChange?: (pageNum: number, pageSize: number) => void
  headerStyle?: string
  bodyStyle?: string
  pageationRef?: React.RefObject<IPageationRef>
  showPagination?: boolean
}
/**
 * 自定义表格组件
 *
 * @param data - 表格数据
 * @param columns - 列定义
 * @param rowCount - 总页数
 *
 * @returns JSX.Element 表格组件
 */
const ManagerTableInternal = <T extends DataWithId>(
  {
    data,
    columns,
    rowCount = 0,
    onRowSelectionChange = (): void => {},
    emptyElement,
    onPageChange,
    headerStyle,
    bodyStyle,
    pageationRef,
    showPagination = true
  }: CustomTableProps<T>,
  ref
): React.ReactElement => {
  const table = useCustomTable({
    data,
    columns,
    rowCount,
    onRowSelectionChange
  })
  const [isScrollAtLeftEnd, setIsScrollAtLeftEnd] = useState(true)
  const [isScrollAtRightEnd, setIsScrollAtRightEnd] = useState(true)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [hoveredRowId, setHoveredRowId] = useState(null)
  const { t } = useTranslation()

  useImperativeHandle(
    ref,
    () => ({
      resetRowSelection: table.resetRowSelection
      // 其他你希望暴露的方法
    }),
    [data]
  )

  useEffect(() => {
    if (scrollContainerRef.current) {
      setIsScrollAtLeftEnd(scrollContainerRef.current?.scrollLeft === 0)
      setIsScrollAtRightEnd(
        scrollContainerRef.current?.scrollWidth - scrollContainerRef.current?.scrollLeft <=
          scrollContainerRef.current?.clientWidth
      )
    }
    const handleScroll = (): void => {
      const scrollLeft = scrollContainerRef.current?.scrollLeft || 0
      const scrollWidth = scrollContainerRef.current?.scrollWidth || 0
      const clientWidth = scrollContainerRef.current?.clientWidth || 0

      setIsScrollAtLeftEnd(scrollLeft === 0)
      setIsScrollAtRightEnd(scrollWidth - scrollLeft <= clientWidth)
    }
    if (scrollContainerRef.current)
      scrollContainerRef.current.addEventListener('scroll', handleScroll)
    return (): void => {
      if (scrollContainerRef.current)
        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
    }
  }, [scrollContainerRef])

  return (
    <div className="w-full h-full flex flex-col overflow-hidden">
      <div
        ref={scrollContainerRef}
        className="w-full h-full flex-1 shrink-0 overflow-auto  pl-3 pr-3"
      >
        <table className="min-w-full text-sm">
          <TableHeader
            className={cn(
              'h-[52px] bg-[#F6F7F9] first:[&_tr]:border-0 [&_tr]:[h-52px] [&_th]:font-[600] [&_th]:text-foreground sticky z-2 top-0 [&_td]:text-sm ',
              headerStyle
            )}
            style={{
              zIndex: 10
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup?.id}>
                {headerGroup.headers.map((header) => {
                  const isFixedLeft =
                    (header.column.columnDef as ExtendedColumnDef)?.fixed === 'left'
                  const isFixedRight =
                    (header.column.columnDef as ExtendedColumnDef)?.fixed === 'right'
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        (header.column.columnDef as ExtendedColumnDef)?.className,
                        isFixedLeft &&
                          `${'sticky left-0 z-1 bg-[#F6F7F9]'} ${!isScrollAtLeftEnd ? 'table-cell-shadow-left' : ''}`,
                        isFixedRight &&
                          `${'sticky right-0 z-1 bg-[#F6F7F9]'} ${!isScrollAtRightEnd ? 'table-cell-shadow-right' : ''}`
                      )}
                      style={{ ...(header.column.columnDef as ExtendedColumnDef)?.style }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className={cn('[&_td]:text-lingheTextGray relative', bodyStyle)}>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onMouseEnter={() => setHoveredRowId(row.id)}
                  onMouseLeave={() => setHoveredRowId(null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isFixedLeft =
                      (cell.column.columnDef as ExtendedColumnDef)?.fixed === 'left'
                    const isFixedRight =
                      (cell.column.columnDef as ExtendedColumnDef)?.fixed === 'right'
                    return (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          (cell.column.columnDef as ExtendedColumnDef)?.className,
                          isFixedLeft &&
                            `${'sticky left-0 z-1 bg-white'} ${!isScrollAtLeftEnd ? 'table-cell-shadow-left' : ''} `,
                          isFixedRight &&
                            `${'sticky right-0 z-1 bg-white'} ${!isScrollAtRightEnd ? 'table-cell-shadow-right' : ''}`,
                          (hoveredRowId === row.id || row.getIsSelected()) && 'tr-hover-class'
                        )}
                        style={{ ...(cell.column.columnDef as ExtendedColumnDef)?.style }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))
            ) : (
              <div className="h-24 w-full text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                {emptyElement ? emptyElement() : t('common.Nodataavailable')}
              </div>
            )}
          </TableBody>
        </table>
      </div>
      {showPagination ? (
        <div className="h-16 w-full shrink-0 flex items-center justify-end relative">
          {/* 代替阴影 */}
          <div
            className="absolute inset-x-0 top-0 h-2 bg-white shadow-md"
            style={{
              transform: 'rotateX(120deg)'
            }}
          />
          <PaginationComp
            ref={pageationRef}
            totalItems={rowCount}
            onPageChange={(pageNum, pageSize) => {
              onPageChange && onPageChange(pageNum, pageSize)
            }}
            className="flex items-center justify-end pr-3"
          />
        </div>
      ) : (
        false
      )}
    </div>
  )
}

export type ManagerTableRef = {
  resetRowSelection: () => void
}

export const ManagerTable = memo(forwardRef(ManagerTableInternal)) as <T extends DataWithId>(
  props: CustomTableProps<T> & { ref?: React.Ref<ManagerTableRef> }
) => React.ReactElement
