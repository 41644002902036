import { filter, includes, map } from 'lodash'
import { FC, memo, useContext, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import WordsSlid from '@renderer/assets/svg/wordsSlid.svg?react'
import noSearchResult from '@renderer/assets/svg/noSearchResult.svg'
import { WordsContext } from './KnowledgeBase'
import { cn, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@brisk/ui'
import { highlightText } from '../../MessageMenu/MessageItem'
import { TeamWordsFromPcResponse } from '@brisk/api'
import { MessageInfoContext } from '../../Home'
import { ChattingWindowContext } from '../ChattingWindow'
import { useTranslation } from 'react-i18next'

interface IWordsItemWrapProps {
  data: TeamWordsFromPcResponse[]
}

const defaultPopover = {
  isGroup: false, // 是否是快捷短语分组
  visible: false,
  position: { top: 0, left: 0 },
  content: null
}
const WordsItemWrap: FC<IWordsItemWrapProps> = ({ data = [] }) => {
  const [openList, setOpenList] = useState([])
  const [popover, setPopover] = useState(defaultPopover)

  const popoverRef = useRef<HTMLDivElement>(null)
  const { currentTab, searchInputRef, setAddPersonWordsData } = useContext(WordsContext)
  const { handleDelPersonWords, handleDelPersonWordsGroup } = useContext(MessageInfoContext)
  const { handleFastWords } = useContext(ChattingWindowContext)
  const { t } = useTranslation()
  const handleRightClick = (e, child, type?: 'words' | 'group') => {
    if (currentTab !== 1) return // 目前仅个人话术支持操作
    const { clientX: mouseX, clientY: mouseY } = e

    // 计算弹框的位置
    const popoverHeight = 100 // 假设弹框的高度为100px
    const bottomSpace = window.innerHeight - mouseY // 底部剩余空间

    const position = {
      top: bottomSpace < popoverHeight ? mouseY - popoverHeight : mouseY,
      left: mouseX - 50
    }

    setPopover({
      isGroup: type === 'group',
      visible: true,
      position,
      content: child // 可以根据需要设置弹框的内容
    })
  }

  /** 有搜索内容 展开 */
  useEffect(() => {
    if (!searchInputRef?.current?.value) return
    setOpenList(data.map((item) => item.groupId))
  }, [data, searchInputRef?.current?.value])

  // 添加事件监听器以关闭 popover
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target))
        setPopover(defaultPopover)
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  return (
    <div
      className="text-sm w-full h-full flex flex-col gap-0.5 [&_.item]:rounded-md [&_.item]:cursor-pointer
       [&_.item:hover]:bg-lingheBtnHover [&_.item]:pl-3 [&_.item>span]:shrink-0
    [&_.item]:flex [&_.item]:h-9 [&_.item]:items-center [&_.item]:gap-1 [&_.item]:pr-3 [&_.item]:max-w-full
    whitespace-nowrap [&_.item]:overflow-hidden [&_.item]:text-ellipsis"
    >
      {data?.length ? (
        map(data, (item) => {
          const hasOpened = includes(openList, item?.groupId)
          const searchVal = searchInputRef?.current?.value
          return (
            <div
              key={item?.groupId}
              onClick={() => {
                if (hasOpened) setOpenList(filter(openList, (o) => o !== item?.groupId))
                else setOpenList([...openList, item?.groupId])
              }}
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleRightClick(e, item, 'group')
              }}
            >
              <div className="item">
                <WordsSlid
                  className={cn(
                    'transition-all duration-300',
                    hasOpened ? 'rotate-0' : '-rotate-90'
                  )}
                />
                <span className="flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
                  {`${item?.groupName}(${searchVal ? item?.itemList?.length || 0 : item?.filterTotal}/${item?.groupCountThreshold})`}
                </span>
              </div>
              <div className={cn('overflow-hidden', hasOpened ? 'h-auto' : 'h-0')}>
                {item?.itemList?.length
                  ? map(item?.itemList, (child, i) => (
                      <TooltipProvider key={child?.preMessageId + i + ''}>
                        <Tooltip>
                          <TooltipTrigger
                            asChild
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              handleFastWords(child)
                            }}
                            onContextMenu={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handleRightClick(e, child, 'words')
                            }}
                          >
                            <div className="item">
                              <span className="ml-4 text-primary flex items-center">
                                {'['}
                                <span className="inline-block max-w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
                                  {highlightText(child?.shortcutKey, searchVal)}
                                </span>
                                {']'}
                              </span>
                              <span className="flex-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                {highlightText(child?.textContent, searchVal)}
                              </span>
                              {child?.imageContent && child?.imageContent?.length ? (
                                <img src={child?.imageContent[0]} className="w-5 h-5 rounded" />
                              ) : (
                                false
                              )}
                            </div>
                          </TooltipTrigger>
                          <TooltipContent
                            className={cn(
                              'w-[302px] h-[368px] p-3 bg-white rounded  shadow-[0_2px_10px_0px_rgba(0,0,0,0.1)] overflow-y-auto text-sm text-black'
                            )}
                          >
                            <div className="mb-3 max-w-full break-words overflow-wrap whitespace-pre-wrap">
                              {child?.textContent}
                            </div>
                            <img
                              src={
                                child?.imageContent && child?.imageContent?.length > 0
                                  ? child?.imageContent[0]
                                  : ''
                              }
                              className="w-full rounded-md"
                            />
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ))
                  : false}
              </div>
            </div>
          )
        })
      ) : (
        <div className="w-full flex flex-col items-center justify-center min-h-[400px]">
          <ReactSVG src={noSearchResult} />
          {t('common.noDataAvailable')}
        </div>
      )}
      {popover.visible && (
        <div
          ref={popoverRef}
          className="fixed bg-white z-1000 rounded-md shadow-md p-2 w-[100px] flex flex-col items-center justify-center [&_span]:flex [&_span]:items-center [&_span]:justify-center [&_span]:w-full [&_span]:h-9 [&_span]:rounded-md [&_span:hover]:bg-lingheBtnHover [&_span]:text-sm"
          style={{
            top: popover.position.top - 10,
            left: popover.position.left
          }}
        >
          <span
            // className={cn(popover?.isGroup ? 'cursor-not-allowed' : 'cursor-pointer')}
            onClick={() => {
              // if (popover?.isGroup) return
              setAddPersonWordsData({
                open: true,
                data: popover.content
              })
            }}
          >
            {t('chatFrame.edit')}
          </span>
          <span
            className="text-lingheError cursor-pointer"
            onClick={() => {
              if (popover?.isGroup) handleDelPersonWordsGroup(popover?.content?.groupId)
              else handleDelPersonWords(popover?.content?.preMessageId)
              setPopover(defaultPopover)
            }}
          >
            {t('chatFrame.removing')}
          </span>
        </div>
      )}
    </div>
  )
}

export default memo(WordsItemWrap)
