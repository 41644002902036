import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 话术启用状态配置
 * @param param
 * @returns
 */
export const fetchWelcomWordsState = async (param: {
  id: string | number
  state: string | number
}): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(
    `/bg/welcome/config/content/${param.id}/${param.state}`
  )

  return serialize(data)
}
