import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * UserInviteSaveDTO
 */
export interface IAddInviteRequestBody {
  /**
   * 账号(邮箱或手机号)
   */
  account: string
  /**
   * 账号类型 PHONE:手机 EMAIL:邮箱
   */
  accountType: string
  /**
   * 邀请编号
   */
  inviteId?: number
  /**
   * 预设角色编号
   */
  roleIds?: string[]
  /**
   * 预设组织编号
   */
  teamIds?: string[]
  /**
   * 客服姓名
   */
  userName: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

export const fetchAddInvite = async (param: IAddInviteRequestBody): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/user/invite`, param)

  return serialize(data)
}
