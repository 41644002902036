/**
 * getEngineList
 *
 * @desc 描述：获取翻译引擎列表
 * @desc 描述：/translate/config/engine
 */

/* =================================================== */

import { get } from 'lodash'
import { httpClient } from '../../http-client/httpClient'

export const API_PATH = '/translate/config/engine'

/**
 * 获取翻译引擎列表
 */
export async function getEngineList(): Promise<string[]> {
  const res = await httpClient.get(API_PATH)

  return get(res, 'data.data')
}
