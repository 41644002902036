import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface RequestBody {
  /**
   * 地址
   */
  address?: string
  /**
   * 公司名称
   */
  companyName?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 访客名称
   */
  name?: string
  /**
   * 手机号
   */
  phone?: string
  /**
   * 会话编号
   */
  sessionId: number | string
  /**
   * 访客标签
   */
  tagIds?: number[]
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 访客留资
 * @param param
 * @returns
 */
export const fetchSaveUserDetail = async (param: RequestBody): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/session/collect-info`, {
    ...param
  })

  return serialize(data)
}
