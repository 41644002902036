import { Avatar, AvatarFallback, AvatarImage, Button, CardContent, CardFooter } from '@brisk/ui'
import { FC } from 'react'
import { APP_ROUTER_PATH } from '../../router'
import { useNavigate } from 'react-router-dom'
import { useSignStore } from '../../store/useSignStore'
import { generateUrlWithQueryString } from '../../utils'
import HeaderNav from './components/HeaderNav'
import { Plus, User } from 'lucide-react'
import { TCaptchaValidType } from './CaptchaValid'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import selectTenantList from '@renderer/assets/svg/selectTenantList.svg'
import { ReactSVG } from 'react-svg'
import emptyTenanlist from '@renderer/assets/svg/emptyTenanlist.svg'

export const SelectTenant: FC = () => {
  const navigate = useNavigate()
  const { setCurrentTenant, tenantList } = useSignStore()
  const { t } = useTranslation()
  return (
    <>
      <HeaderNav
        len={tenantList?.length}
        title={
          !tenantList?.length ? t('common.welcome_useAIOChat') : t('login.pleaseSelectBusiness')
        }
        customClass="text-left"
      />
      <div className="text-center mb-5">
        {tenantList?.length ? (
          <p className="text-base text-lingheGray mb-4 text-left pl-6">
            {t('login.Youcanchooseanyfollowingbusinessesentercreatebusiness')}
          </p>
        ) : (
          false
        )}
      </div>
      <CardContent className="flex-1 justify-start flex flex-col p-0">
        {!tenantList?.length ? (
          <div className="flex flex-col items-center gap-4">
            <ReactSVG src={emptyTenanlist} />
            <span>{t('login.haveNoTenant')}</span>
          </div>
        ) : (
          false
        )}
        <div className="max-h-[300px] overflow-y-auto pl-6 pr-6">
          {map(tenantList, (it) => (
            <div
              key={it?.tenantId}
              className="cursor-pointer border rounded-md h-16 mb-3 pl-3 pr-3 hover:bg-[#EDF6FF]"
              onClick={() => {
                setCurrentTenant(it)
                navigate(APP_ROUTER_PATH.SIGNIN_BY_PASSPORT)
              }}
            >
              <div className="flex items-center gap-4 h-full border-none">
                <Avatar className="hidden h-10 w-10 sm:flex ">
                  <AvatarImage alt="Avatar" src={it?.logoUrl} />
                  <AvatarFallback>
                    <User />
                  </AvatarFallback>
                </Avatar>
                <div className="grid gap-1 flex-1">
                  <p className="text-sm font-medium leading-none overflow-hidden text-ellipsis whitespace-nowrap">
                    {it?.tenantName}
                  </p>
                  <p className="text-xs text-muted-foreground text-lingheGray overflow-hidden text-ellipsis whitespace-nowrap">
                    {it?.userNickname}
                  </p>
                </div>
                <ReactSVG src={selectTenantList} />
              </div>
            </div>
          ))}
        </div>

        <div className="pl-6 pr-6">
          <Button
            type="submit"
            className="w-full mt-6 h-12 mb-5"
            onClick={() => {
              const url = generateUrlWithQueryString(APP_ROUTER_PATH.Captcha_Valid, {
                redirect: APP_ROUTER_PATH.CREATE_TENANT,
                captchaValidType: TCaptchaValidType.TEMP_SIGN_IN
              })
              navigate(url)
            }}
          >
            <Plus strokeWidth={1.5} />
            {t('login.createnewbusiness')}
          </Button>
        </div>
      </CardContent>

      <CardFooter className="flex justify-between"></CardFooter>
    </>
  )
}
