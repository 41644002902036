import { apiClient, RESPONSE_CODE } from '@brisk/api'
import { useUserStore } from '../store/useUserStore'
import { message } from '../components/message'
import { APP_ROUTER_PATH, router } from '../router'
import { useSignStore } from '../store/useSignStore'
import { isLoginPath } from '../utils/isLoginPath'
import i18n from 'i18next'

let hasShownTokenExpiredMessage = false

// 创建实例时配置默认值
// const instance = axios.create({
//     baseURL: 'https://api.example.com'
// });

// 创建实例后修改默认值
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN;
export const initApiClient = (): void => {
  // const {t} = useTranslation()
  console.log(import.meta.env.VITE_API_URL)
  apiClient.defaults.baseURL = import.meta.env.VITE_API_URL
  apiClient.interceptors.request.use((config) => {
    const { user } = useUserStore.getState()
    const token = user?.token
    if (token) {
      config.headers.Authorization = `${token}`
    }
    return config
  })

  const ignoreCodes = [10016, 10010]
  apiClient.interceptors.response.use((response) => {
    if (ignoreCodes.includes(response.data.code)) {
      return response
    }
    // 登录过期
    if (response?.data?.code === RESPONSE_CODE.token_overdue) {
      const { clear } = useUserStore.getState()
      clear()
      const { setRedirectUrl } = useSignStore.getState()
      if (!isLoginPath(router.state.location.pathname)) {
        setRedirectUrl(router.state.location.pathname)
        router.navigate(APP_ROUTER_PATH.INPUT_ACCOUNT)
      }
      if (!hasShownTokenExpiredMessage) {
        message(i18n.t('common.loginhasexpiredpleaseloginagain'), 'error')
        hasShownTokenExpiredMessage = true
      }
    } else {
      // if (response?.data?.code !== RESPONSE_CODE.success) {
      //   message(response?.data?.msg, 'error')
      //   throw new Error(response.data.msg)
      // } else {
      //   hasShownTokenExpiredMessage = false
      // }
    }

    return response
  })
}
