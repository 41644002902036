import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * PresetMessageGroupSaveDTO
 */
export interface EditTeamGroupRequest {
  /**
   * 分组id 新增时不传
   */
  groupId?: number;
  /**
   * 分组名
   */
  groupName: string;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 团队话术 組編輯
 * @returns
 */
export const fetchEditTeamGroup = async (
  params: EditTeamGroupRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.put<ApiResp>(`/bg/kb/team/group`, params);

  return serialize(data);
};
