import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * FaceBookMessengerRemakeDTO
 */
export interface IChangeFacebookRemarkReq {
  /**
   * id信息
   */
  id?: string
  /**
   * 备注
   */
  remake?: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * facebook主页备注
 * @param param
 * @returns
 */
export const fetchChangeFacebookRemark = async (
  param: IChangeFacebookRemarkReq
): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(`/bg/messenger/business/remark`, param)

  return serialize(data)
}
