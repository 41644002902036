import { filter, map, set, some } from 'lodash'
import { forwardRef, memo } from 'react'
import addSource from '../../../assets/svg/addSource.svg'
import delSource from '../../../assets/svg/delSource.svg'
import { ReactSVG } from 'react-svg'
import { ControllerRenderProps } from 'react-hook-form'
import {
  Checkbox,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  cn
} from '@brisk/ui'
import { ISaveSourceConfig, ISaveSourceForm } from '@renderer/api/manage/fetchSaveSourceConfig'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface IAddSourceFormListProps {
  listData: ISaveSourceForm[]
  field: ControllerRenderProps<ISaveSourceConfig, 'formList'>
}

const AddSourceFormList = forwardRef(({ listData, field }: IAddSourceFormListProps) => {
  const { t } = useTranslation()
  const SaveSourceEnum = {
    NAME: t('infoCollection.Name'),
    EMAIL: t('infoCollection.Email'),
    PHONE: t('infoCollection.PhoneNumber'),
    COMPANY_NAME: t('infoCollection.CompanyName')
  }

  /**
   * 访客留资类型
   */
  const SaveSourceType = [
    {
      label: SaveSourceEnum.NAME,
      value: 'NAME'
    },
    {
      label: SaveSourceEnum.EMAIL,
      value: 'EMAIL'
    },
    {
      label: SaveSourceEnum.PHONE,
      value: 'PHONE'
    },
    {
      label: SaveSourceEnum.COMPANY_NAME,
      value: 'COMPANY_NAME'
    }
  ]
  return (
    <div className="flex flex-col gap-1">
      {map(listData, (item, index) => (
        <div
          key={item?.type || '' + index}
          className={cn(
            'flex items-center justify-between pl-3 pr-3 gap-8 pt-2 pb-2 max-w-[472px]',
            index % 2 === 0 ? 'bg-lingheTalkingBg border-t border-b' : ''
          )}
        >
          <span>{index + 1}</span>

          <Select onValueChange={(e) => field.onChange(set(listData, index, { ...item, type: e }))}>
            <SelectTrigger className="w-[172px]">
              <SelectValue
                placeholder={SaveSourceEnum[item?.type] || t('infoCollection.PleaseSelect')}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {map(SaveSourceType, (item) => (
                  <SelectItem
                    disabled={some(listData, (it) => it.type === item.value)}
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>

          <div className="flex items-center gap-2">
            <Checkbox
              id={`isRequire${index}`}
              checked={item.required}
              onCheckedChange={(e) =>
                field.onChange(set(listData, index, { ...item, required: e }))
              }
            />
            <label htmlFor={`isRequire${index}`}>{t('infoCollection.RequiredFields')}</label>
          </div>

          <div className="flex items-center gap-4">
            <ReactSVG
              src={addSource}
              className="cursor-pointer"
              onClick={() => {
                field.onChange([...(field.value || []), { type: '', required: false }])
              }}
            />
            <ReactSVG
              src={delSource}
              className="cursor-pointer"
              onClick={() => {
                if (field.value?.length <= 1) {
                  message(t('infoCollection.AtLeastOneFieldRequired'), 'info')
                  return
                }
                field.onChange(filter(field.value, (it) => it.type !== item.type))
              }}
            />
          </div>
        </div>
      ))}
    </div>
  )
})

AddSourceFormList.displayName = 'AddSourceFormList'

export default memo(AddSourceFormList)
