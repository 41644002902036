import CryptoJS from 'crypto-js'

// 示例参数
const productSecret = '1f0ac785c9e148c0f327bd0b9f8068bd'
const slat = 'R1b@nD'

// 生成8位随机字符串
function generateNonce(): string {
  const array = new Uint32Array(10)
  window.crypto.getRandomValues(array)
  return window.crypto.getRandomValues(array).toString().slice(0, 8)
}

// 拼接签名参数
function getSignatureParams(time, tenantCode): string {
  return time + productSecret + tenantCode
}

// 计算MD5串
function getMD5String(slat, signatureParams, nonce): string {
  const strToHash = `${slat}-${signatureParams}_${nonce}`
  const hash = CryptoJS.MD5(strToHash)
  return hash.toString()
}

// 生成签名
export function generateSignature(time, tenantCode): string {
  const nonce = generateNonce()
  const signatureParams = getSignatureParams(time, tenantCode)
  const md5String = getMD5String(slat, signatureParams, nonce)
  const signature = nonce.substring(0, 4) + md5String + nonce.substring(4, 8)
  return signature
}
