import { IContent } from '@brisk/api'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  Button
} from '@brisk/ui'
import { sendMsg } from '@renderer/utils/im-sdk/ipcTimSdk'
import { FC, memo, useContext } from 'react'
import { ChattingWindowContext } from '../ChattingWindow'
import { MessageInfoContext } from '../../Home'

export interface ITranslateErrorModal {
  open: boolean
  type: number // 1: 翻译中 3：翻译失败
  data: IContent
}

const TranslateErrorModal: FC<
  ITranslateErrorModal & {
    setModalData: () => void
    customerLiveToConv: () => void
  }
> = ({ data, open, type, setModalData, customerLiveToConv }) => {
  const { isManager } = useContext(MessageInfoContext)
  const { setTranslateOnTimeData, textAreaRef } = useContext(ChattingWindowContext)

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogDescription />
      <AlertDialogContent className="p-6 focus:outline-none">
        <AlertDialogTitle className="relative">提示</AlertDialogTitle>
        <span className="text-sm text-lingheTextGray">
          {type === 1 ? '翻译中，是否只发送原文？' : '翻译失败，是否只发送原文？'}
        </span>

        <div className="w-full flex items-center justify-end gap-3">
          <Button
            variant="outline"
            onClick={() => {
              setModalData && setModalData()
            }}
          >
            取消发送
          </Button>
          <Button
            onClick={() => {
              try {
                if (textAreaRef?.current?.arealRef?.current)
                  textAreaRef.current.arealRef.current.innerHTML = ''
                sendMsg(data)
                isManager && customerLiveToConv && customerLiveToConv()
                setTranslateOnTimeData({
                  state: 2,
                  data: null
                })
                setModalData && setModalData()
              } catch (error) {
                console.log('error>---->>', error)
              }
            }}
          >
            发送原文
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(TranslateErrorModal)
