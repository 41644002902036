import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  Button,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  cn
} from '@brisk/ui'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import close from '../../../assets/svg/close.svg'
import { message } from '@renderer/components/message'
import { useTranslation } from 'react-i18next'

interface IAddTelegramBotProps {
  open: boolean
  onClose: () => void
  onConfirm: (token: { token: string }) => void
}
const AddTelegramBot: FC<IAddTelegramBotProps> = ({ open, onClose, onConfirm }) => {
  const [active, setActive] = useState<0 | 1>(0)
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = ''
  }, [active])

  return (
    <AlertDialog open={open} onOpenChange={() => {}}>
      <AlertDialogContent className="p-0 text-sm w-[432px] focus:outline-none">
        <div className="relative">
          <ReactSVG
            src={close}
            className="cursor-pointer absolute right-4 top-4 "
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          />
          <AlertDialogTitle className="h-[54px] border-b flex items-center pl-3 pr-3">
            {t('socialMediaAccount.AddBot')}
          </AlertDialogTitle>
          <AlertDialogDescription />
        </div>

        <div className="pl-6 pr-6 ">
          <div className="mt-6 relative mb-6 h-10 text-lingheTextGray [&_span]:cursor-pointer [&_span]:z-10 [&_span]:flex-1 [&_span]:block [&_span]:shrink-0 [&_span]:h-full [&_span]:flex [&_span]:items-center [&_span]:justify-center bg-lingheTalkingBg rounded-md w-full flex items-center [&_.active]:text-primary [&_.active]:font-semibold">
            <span className={cn(active === 0 ? 'active' : '')} onClick={() => setActive(0)}>
              {t('socialMediaAccount.CreateNewBot')}
            </span>
            <span className={cn(active === 1 ? 'active' : '')} onClick={() => setActive(1)}>
              {t('socialMediaAccount.Connectingexistingrobots')}
            </span>
            <div
              className={cn(
                'absolute w-1/2 h-[95%] rounded-md shadow top-1/2 -translate-y-1/2 left-0 transition bg-white z-1',
                active === 0 ? 'translate-x-0' : 'translate-x-[100%]'
              )}
            />
          </div>

          <div className="[&_.step]:text-lingheTextGray mb-2 [&_span]:text-black [&_span]:font-semibold">
            <p className="mb-2">{t('socialMediaAccount.ToconnectTelegramBotfollowthesesteps')}</p>
            <p className="step">{t('socialMediaAccount.searchBotFatherTelegramSendStart')}</p>
            {active === 0 ? (
              <div>
                <p className="step">
                  {t('socialMediaAccount.searchBotFatherTelegramSendStartnewbot')}
                </p>
                <p className="step">
                  {t('socialMediaAccount.searchBotFatherTelegramSendStartnewbotthree')}
                </p>
              </div>
            ) : (
              <div>
                <p className="step">
                  {t('socialMediaAccount.searchBotFatherTelegramSendStartmybots')}
                </p>
                <p className="step">
                  {t('socialMediaAccount.searchBotFatherTelegramSendStartmybotsthree')}
                </p>
              </div>
            )}
          </div>

          <HoverCard>
            <HoverCardTrigger asChild>
              {/* <Button variant="link" className="p-0">
                查看示例
              </Button> */}
            </HoverCardTrigger>
            <HoverCardContent
              side="right"
              sideOffset={20}
              className="p-0 w-[520px] max-h-[779px] overflow-hidden"
            >
              <div className="w-full h-full overflow-hidden text-sm flex flex-col">
                <div className="h-[54px] flex items-center shrink-0 pl-3 pr-3 border-b text-base font-semibold">
                  示例步骤图
                </div>
                <div className="mt-3 pl-4 pr-4 pb-4 flex-1 shrink-0 overflow-y-auto flex flex-col gap-3 [&_.itemWrap]:flex [&_.itemWrap]:flex-col [&_span]:text-black gap-3">
                  <div className="itemWrap">
                    <p>步骤一、</p>
                    <p className="text-lingheTextGray">
                      在 Telegram 中搜索 <span>@BotFather</span> 并发送 <span>/start</span>。
                    </p>
                    <img src="" alt="" />
                  </div>
                  <div className="itemWrap">
                    <p>步骤二、</p>
                    <p className="text-lingheTextGray">
                      再发送 <span>/newbot</span> 并按照回复的说明进行操作。
                    </p>
                    <img src="" alt="" />
                  </div>
                  <div className="itemWrap">
                    <p>步骤三、</p>
                    <p className="text-lingheTextGray">
                      创建机器人后，您将收到带有令牌的消息，复制API令牌并将其粘贴到下方输入框内。
                    </p>
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>

          <div className="flex flex-col gap-2 mb-4">
            <p>
              <span className="text-lingheError">*</span>
              {t('socialMediaAccount.TelegramBotToken')}
            </p>
            <Input ref={inputRef} placeholder={t('socialMediaAccount.PleaseEntetheToken')} />
          </div>

          <div className="text-lingheGray mb-6">
            <p>{t('socialMediaAccount.Westronglyrecommend')}</p>
            <p>{t('socialMediaAccount.Donotusethesametokendifferentservices')}</p>
          </div>
        </div>
        <AlertDialogFooter
          style={{ flexDirection: 'row' }}
          className="flex items-center gap-2 border-t h-[54px] pr-3"
        >
          <Button
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onClose && onClose()
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={() => {
              const val = inputRef?.current?.value
              if (!val) {
                message(t('socialMediaAccount.PleaseEntetheToken'), 'error')
                return
              }
              onConfirm && onConfirm({ token: val })
            }}
          >
            {t('common.verify')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default memo(AddTelegramBot)
