import { FC, useEffect, useState } from 'react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator
} from '@brisk/ui'
import { Avatar, AvatarFallback, AvatarImage } from '@radix-ui/react-avatar'
import { ReactSVG } from 'react-svg'
import defauteUser from '../../../assets/svg/defauteUser.svg'
import { Assets } from '../../../helpers'
import { PowerIcon } from 'lucide-react'
import { useUserStore } from '../../../store/useUserStore'
import { useMutation } from '@tanstack/react-query'
import { EmployeeStatus } from '../../../api/model'
import { message } from '../../../components/message'
import { mutationChangeStatus } from '@brisk/api'
import { isElectron } from '@renderer/utils'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTER_PATH } from '@renderer/router'
import { disconnectIM } from '@renderer/lib/sdk/juggle'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { useCurrentAndSearch } from '@renderer/store/useCurrentAndSearch'
import { useTranslation } from 'react-i18next'
import { useNewBieGuide } from '../../../store/manageStore/useNewBieGuide'
import AvatarSpin from '@renderer/components/AvatarSpin'

export const statusColor = {
  [EmployeeStatus.ONLINE]: 'bg-[#21d881]',
  [EmployeeStatus.BUSY]: 'bg-[#ff4822]',
  [EmployeeStatus.OFFLINE]: 'bg-[#ececec]'
}

export const AvatarPopover: FC = () => {
  const [opens, setOpens] = useState(false)
  const [loadFinished, setLoadFinished] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clear, setStatus, user, setOutLogin } = useUserStore()
  const { clearChatListInfo } = useAssignedStore()
  const { clearCurrent } = useCurrentAndSearch()
  const { client } = useNewBieGuide()

  const { isPending, mutateAsync } = useMutation({
    mutationFn: mutationChangeStatus
  })
  useEffect(() => {
    if (client.customerStatus === 'open') {
      setOpens(true)
    } else {
      setOpens(false)
    }
  }, [client.customerStatus])
  const signOut = async (): Promise<void> => {
    disconnectIM()
    await setOutLogin(true)
    await clear()
    clearChatListInfo()
    clearCurrent()
    isElectron ? navigate(APP_ROUTER_PATH.INPUT_ACCOUNT) : window.location.replace('/')
  }
  const handleChangeStatus = async (status: EmployeeStatus): Promise<void> => {
    if (isPending) return
    await mutateAsync({
      status
    })
    setStatus(status)
    message(t('common.succeeded_switching_status'), 'success')

    const event = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Escape'
    })
    document.body.dispatchEvent(event)
  }

  /**
   * 监控窗口开启/关闭状态
   * @param open
   */
  const onOpenChange = (open: boolean) => {
    if (client.customerStatus === 'open') {
      return
    } else {
      setOpens(open)
    }
  }
  return (
    <Popover open={opens} onOpenChange={onOpenChange}>
      <PopoverTrigger>
        <Avatar className="hidden h-10 w-10 sm:flex relative">
          <AvatarImage
            className="rounded-full w-full h-full"
            alt="Avatar"
            src={user?.avatarUrl}
            onLoad={() => setLoadFinished(true)}
            onError={() => setLoadFinished(true)}
          />
          <AvatarFallback>
            {user?.avatarUrl ? (
              <AvatarImage
                className="rounded-full w-full h-full"
                alt="Avatar"
                src={user?.avatarUrl}
              />
            ) : (
              <ReactSVG src={defauteUser} />
            )}
          </AvatarFallback>
          {!loadFinished && (
            <AvatarSpin
              loadFinished={loadFinished}
              calssName="w-full h-full absolute left-0 top-0"
            />
          )}
          <div
            className={cn(
              'size-[12px] border-white border rounded-full absolute bottom-0 right-0',
              statusColor[user?.status] || ''
            )}
          />
        </Avatar>
      </PopoverTrigger>
      <PopoverContent className="w-[338px] p-2" side="right" align="start" hideWhenDetached>
        <div className="grid w-full items-center gap-2 popoverContent">
          <h4 className="scroll-m-20 text-base mt-2 mb-3 font-semibold text-sm pl-3 tracking-tight">
            {t('sideMenuBar.switchMenu')}
          </h4>

          <div className="flex flex-col space-y-1.5 p-0">
            <ul className="list-none gap-2 grid [&_li]:pl-3 [&_li]:pr-3">
              <li
                className="flex items-center gap-2 hover:bg-[#edf6ff] h-[36px] rounded cursor-pointer"
                onClick={() => {
                  handleChangeStatus(EmployeeStatus.ONLINE)
                }}
              >
                <img src={Assets.online} alt="" className="size-[14px]" />
                <span className="text-base">{t('sideMenuBar.online')}</span>
                <span className="text-sm text-muted-foreground">{t('sideMenuBar.onlineTags')}</span>
              </li>
              <li
                className="flex items-center gap-2 hover:bg-[#edf6ff] h-[36px] rounded cursor-pointer"
                onClick={() => {
                  handleChangeStatus(EmployeeStatus.BUSY)
                }}
              >
                <img src={Assets.busy} alt="" className="size-[14px]" />
                <span className="text-base">{t('sideMenuBar.busy')}</span>
                <span className="text-sm text-muted-foreground">{t('sideMenuBar.busyTags')}</span>
              </li>
              <li
                className="flex items-center gap-2 hover:bg-[#edf6ff] h-[36px] rounded cursor-pointer"
                onClick={() => {
                  handleChangeStatus(EmployeeStatus.OFFLINE)
                }}
              >
                <img src={Assets.offline} alt="" className="size-[14px]" />
                <span className="text-base">{t('sideMenuBar.Offline')}</span>
                <span className="text-sm text-muted-foreground">
                  {t('sideMenuBar.OfflineTags')}
                </span>
              </li>
            </ul>
          </div>

          <Separator />
          <AlertDialog>
            <AlertDialogTrigger>
              <div className="flex items-center gap-2 hover:bg-[#edf6ff] h-[36px] rounded cursor-pointer pl-3 pr-3">
                <PowerIcon size="14" />
                <span className="text-base">{t('sideMenuBar.LogOut')}</span>
              </div>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>{t('sideMenuBar.LogOut')}</AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogDescription>
                <span className="text-lingheTextGray">{t('common.logout_content')}</span>
              </AlertDialogDescription>
              <AlertDialogFooter>
                <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                <AlertDialogAction onClick={signOut}>{t('common.verify')}</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </PopoverContent>
    </Popover>
  )
}
