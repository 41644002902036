import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * UserInviteSaveDTO
 */
export interface IDelTeam {
  teamId: string | number;
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 删除团队
 * @param param
 * @returns
 */
export const fetchDelTeam = async (
  param: IDelTeam,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(`/bg/user/delete/team`, param);

  return serialize(data);
};
