import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { ManagerTable } from '@renderer/components/ManagerTable'
import { fetchUserPage, IUserPageDetail, IUserPageRes } from '../../../api'
import { IPageationRef } from '../AddCustomer'
import { ColumnDef } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { RESPONSE_CODE } from '../../../http-client/httpClient'
import { useMutation } from '@tanstack/react-query'
import Loading from '../../../components/Loading'

const OutWork: FC = () => {
  const pageationRef = useRef<IPageationRef>({ currentPage: 1, itemsPerPage: 10 })
  const [userListData, setUserListData] = useState<IUserPageRes>({})
  const { t } = useTranslation()
  const { mutateAsync: getUserPageList, isPending } = useMutation({
    mutationFn: fetchUserPage
  })
  const columns: ColumnDef<IUserPageDetail>[] = useMemo(
    () => [
      {
        id: 'nickname',
        header: (): JSX.Element => <div>{t('businessManagement.Name')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.nickname}</div>
        }
      },
      {
        id: 'account',
        header: (): JSX.Element => <div>{t('businessManagement.AccountNumber')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div>
              <p>{original?.phone}</p>
              <p>{original?.email}</p>
            </div>
          )
        }
      },
      {
        id: 'roleName',
        header: (): JSX.Element => <div>{t('businessManagement.Role')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.roleName}</div>
        }
      },
      {
        id: 'teamName',
        header: (): JSX.Element => <div>{t('businessManagement.Group')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.teamName}</div>
        }
      },
      {
        id: 'transferUser',
        header: (): JSX.Element => <div>{t('chatFrame.recipientOfSession')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return <div>{original?.transferUser}</div>
        }
      },
      {
        id: 'hireDate',
        header: (): JSX.Element => <div>{t('businessManagement.JoinTime')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div>
              {original?.hireDate ? dayjs(original?.hireDate).format('YYYY-MM-DD HH:mm:ss') : ''}
            </div>
          )
        }
      },
      {
        id: 'departDate',
        header: (): JSX.Element => <div>{t('chatFrame.departureTime')}</div>,
        cell: ({ row: { original } }): JSX.Element => {
          return (
            <div>
              {original?.departDate
                ? dayjs(original?.departDate).format('YYYY-MM-DD HH:mm:ss')
                : '-'}
            </div>
          )
        }
      }
    ],
    []
  )
  const handleUserList = debounce(async (param) => {
    try {
      const p = { ...param }
      const res = await getUserPageList(p)
      if (res?.code === RESPONSE_CODE.success) {
        setUserListData(res?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }, 500)
  useEffect(() => {
    const param = {
      pageNum: pageationRef?.current?.currentPage || 1,
      pageSize: pageationRef?.current?.itemsPerPage || 10,
      jobStatus: 4
    }
    handleUserList(param)
  }, [])
  return (
    <div className="w-full h-full pt-4">
      {isPending && <Loading />}
      <ManagerTable<IUserPageDetail>
        pageationRef={pageationRef}
        data={userListData?.records || []}
        columns={columns}
        rowCount={userListData?.total || 1}
        onPageChange={(pageNum, pageSize) => {
          handleUserList({
            pageNum,
            pageSize,
            jobStatus: 4
          })
        }}
      />
    </div>
  )
}

export default OutWork
