import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

/**
 * UserTeamDetailVO
 */
export interface ITeamResponse {
  /**
   * 团队编号
   */
  teamId?: number | string;
  /**
   * 团队名称
   */
  teamName?: string;
  /**
   * 团队下的人员
   */
  userList?: TeamUser[];
}

/**
 * 团队下用户信息，团队下的人员
 */
export interface TeamUser {
  /**
   * 用户头像
   */
  avatarUrl?: string;
  /**
   * 用户名称
   */
  nickname?: string;
  /**
   * 在离线状态 在线:ONLINE 忙碌：BUSY 离线OFFLINE
   */
  status?: string;
  /**
   * 用户编号
   */
  userId?: number | string;
  phone?: string;
  email?: string;
}

interface ApiResp extends ClientResponse {
  // TODO:
  data: ITeamResponse[];
}
interface Serialize {
  (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
  // TODO: 处理data
  return resp;
};

// 获取团队结构
export const fetchTeamTree = async (): Promise<ApiResp> => {
  const { data } = await apiClient.get<ApiResp>(`/bg/user/team/tree`);

  return serialize(data);
};
