import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 启用禁用facebook主页
 * @param param
 * @returns
 */
export const fetchFacebookEnable = async (param: {
  state: string
  id: string
}): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(
    `/bg/messenger/business/enable/${param.state}/${param.id}`
  )

  return serialize(data)
}
