import { cn, Input } from '@brisk/ui'
import { inputIconClassName } from '@renderer/utils/consts'
import {
  FC,
  memo,
  //  Ref, useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import search from '../../../assets/svg/search.svg'
import close from '../../../assets/svg/close.svg'
import { ReactSVG } from 'react-svg'
import { fetchChatList, IChatList, IContentByApi } from '../../../api/chatList'
import { useMutation } from '@tanstack/react-query'
import { ChatListStatus } from '@renderer/api/model'
import { useAssignedStore } from '@renderer/store/useAssignedStore'
import { ScrollWrap } from './ScrollWrap'
import { concat, debounce, filter, includes, intersectionBy, some, toLower, uniqBy } from 'lodash'
import { handleFormatTalkListByApi } from '@renderer/utils/handleFormatTalkListByApi'
import { APP_ROUTER_PATH } from '@renderer/router'
import { useNavigate } from 'react-router-dom'
import { MessageData, useSingleChatStore } from '@renderer/store/useSingleChatStore'
import { MessageInfoContext } from '../Home'
import { useTranslation } from 'react-i18next'
import { hideSysReadDot, setSysReadDot } from '@renderer/utils/ipcHandler'
import { useUnreadCountStore } from '@renderer/store/useUnreadCountStore'

import { formatListInfomation } from '@renderer/utils/im-sdk/formateData'

const tabStyle =
  'text-primary font-[500] after:content-[""] after:block after:w-full after:h-0.5 after:bg-primary after:rounded-full after:absolute after:-bottom-3 after:left-0'

interface IMessageMenuProps {
  talkGroupShow?: boolean
  handleIconClikc?: () => void
}

// const listScrollTop = {
//   assigned: 0,
//   unAssigned: 0,
//   finished: 0
// }

const MessageMenu: FC<IMessageMenuProps> = () => {
  const [hasTalkHistory, setHasTalkHistory] = useState<MessageData[]>([])
  const navigate = useNavigate()
  const {
    chatItemActive: active,
    setChatItemActive,
    active: talkGroupActive,
    currentTab,
    setCurrentTab,
    searchResult,
    setSearchResult,
    unAssignedData,
    finishedData,
    assignedList = [],
    // allConversations = [],
    unAssignedList = [],
    finishedList = [],
    setUnAssignedList,
    setUnAssignedData,
    setfinishedData,
    setFinishedList,
    setLoading,
    shouldUpdate
  } = useContext(MessageInfoContext)
  const { unreadCount } = useUnreadCountStore()

  const searchInputRef = useRef<HTMLInputElement>(null)
  // const assignedRef = useRef<{ listRef: Ref<HTMLDivElement> }>()
  // const unAssignedRef = useRef<{ listRef: Ref<HTMLDivElement> }>()
  // const finishedRef = useRef<{ listRef: Ref<HTMLDivElement> }>()
  const { t } = useTranslation()

  const queryParams = useMemo(() => {
    return {
      status: currentTab !== ChatListStatus.FINISHED ? currentTab : null,
      pageSize: currentTab !== ChatListStatus.FINISHED ? 40 : 20,
      pageNum:
        currentTab === ChatListStatus.UNASSIGNED ? unAssignedData.current : finishedData.current
    }
  }, [unAssignedData, finishedData, currentTab])

  const { mutateAsync, isPending } = useMutation({
    mutationFn: fetchChatList,
    onSuccess: async (res) => {
      const { records, ...others } = res?.data || {}
      // 接口返回数据格式与sdk不一致
      const formatRecords = handleFormatTalkListByApi(records as unknown as IContentByApi[])

      if (currentTab === ChatListStatus.UNASSIGNED) {
        setUnAssignedList(uniqBy(concat(unAssignedList, formatRecords), 'sessionId'))
        setUnAssignedData({ ...unAssignedData, ...others })
      }
      if (currentTab === ChatListStatus.FINISHED) {
        const formatConvList = await formatListInfomation(formatRecords)
        setFinishedList(uniqBy(concat(finishedList, formatConvList), 'sessionId'))
        setfinishedData({ ...finishedData, ...others })
      }
    }
  })

  const handleSearch = debounce((e) => {
    const value = toLower(e.target.value)
    let filterList: IChatList[] = []
    if (currentTab === ChatListStatus.FINISHED) filterList = finishedList
    if (currentTab === ChatListStatus.ASSIGNED) filterList = assignedList
    let res: IChatList[] = []
    res = filter(
      filterList,
      (item) =>
        includes(toLower(item?.conversationTitle), value) ||
        includes(toLower(item?.latestMessage?.content?.content), value)
    )
    /** 聊天历史记录内容 */
    const talkRes = filter(useSingleChatStore.getState().messageData, (it) => {
      return some(it?.messages, (el) => includes(toLower(el?.content?.content), value))
    })
    if (talkRes.length) {
      setHasTalkHistory(talkRes)
      res = uniqBy(
        concat(intersectionBy(filterList, talkRes, 'conversationId'), res),
        'conversationId'
      )
    } else {
      setHasTalkHistory([])
    }
    setSearchResult({
      resultList: res,
      value: value
    })
  }, 500)

  const handleFetchMoe = debounce((): void => {
    let pageNum = 1
    if (currentTab === ChatListStatus.UNASSIGNED) {
      const { current, pages } = useAssignedStore.getState().unAssignedData
      if (current >= pages) return
      pageNum = current + 1
    }
    if (currentTab === ChatListStatus.FINISHED && finishedData?.current <= finishedData?.pages) {
      const { current, pages } = useAssignedStore.getState().finishedData
      if (current >= pages) return
      pageNum = current + 1
    }
    mutateAsync({ ...queryParams, pageNum })
  }, 500)

  // /**
  //  * 记录会话列表滚动条高度
  //  */
  // const handleSetScrolTop = useCallback(() => {
  //   const assignedTarget = assignedRef?.current?.listRef as React.MutableRefObject<HTMLDivElement>
  //   const unAssignedTarget = unAssignedRef?.current
  //     ?.listRef as React.MutableRefObject<HTMLDivElement>
  //   const finishedTarget = finishedRef?.current?.listRef as React.MutableRefObject<HTMLDivElement>
  //   if (assignedTarget) {
  //     listScrollTop.assigned = assignedTarget.current?.scrollTop || 0
  //   }
  //   if (unAssignedTarget) {
  //     listScrollTop.unAssigned = unAssignedTarget.current?.scrollTop || 0
  //   }
  //   if (finishedTarget) {
  //     listScrollTop.finished = finishedTarget.current?.scrollTop || 0
  //   }
  // }, [assignedRef, unAssignedRef, finishedRef])

  // /**
  //  * 设置会话列表滚动条高度
  //  */
  // useEffect(() => {
  //   if (currentTab === ChatListStatus.ASSIGNED) {
  //     const target = (assignedRef?.current?.listRef as React.MutableRefObject<HTMLDivElement>)
  //       ?.current
  //     if (target) target.scrollTop = listScrollTop.assigned
  //   }
  //   if (currentTab === ChatListStatus.UNASSIGNED) {
  //     const target = (unAssignedRef?.current?.listRef as React.MutableRefObject<HTMLDivElement>)
  //       ?.current
  //     if (target) target.scrollTop = listScrollTop.unAssigned
  //   }
  //   if (currentTab === ChatListStatus.FINISHED) {
  //     const target = (finishedRef?.current?.listRef as React.MutableRefObject<HTMLDivElement>)
  //       ?.current
  //     if (target) target.scrollTop = listScrollTop.finished
  //   }
  // }, [currentTab])

  useEffect(() => {
    if (talkGroupActive !== 1) {
      if (searchInputRef.current) searchInputRef.current.value = ''
      setSearchResult({ resultList: [], value: '' })
    }
    if (currentTab === ChatListStatus.ASSIGNED) return
    const data = {
      total: 0,
      size: 10,
      current: 1,
      pages: 1
    }
    setUnAssignedData(data)
    setUnAssignedList([])
    setfinishedData(data)
    setFinishedList([])
    mutateAsync({ ...queryParams, pageNum: 1 })
  }, [currentTab, shouldUpdate])

  /** 会话管理tab切换 清空搜索框的值 */
  useEffect(() => {
    if (searchInputRef.current) searchInputRef.current.value = ''
  }, [talkGroupActive])

  useEffect(() => setLoading(isPending), [isPending])

  useEffect(() => {
    if (active === '-99') navigate(APP_ROUTER_PATH.HOME_CHATTING)
  }, [active])

  useEffect(() => {
    console.log('unreadCount', unreadCount)

    if (unreadCount && Number(unreadCount) > 0) {
      if (Number(unreadCount) > 99) setSysReadDot('99+')
      else setSysReadDot(unreadCount + '')
    } else hideSysReadDot()
  }, [unreadCount])

  return (
    <div className="flex flex-col w-full h-full border-r">
      <div className="shrink-0 ">
        <div className="flex justify-between pt-6 pl-6 pr-3 mb-5">
          <span className="text-lg font-[600]">{t('threeLevelMenu.message')}</span>
          {/* <div
            className={cn(
              'cursor-pointer w-4 flex items-center transition-all',
              talkGroupShow && 'rotate-180'
            )}
            onClick={() => {
              handleIconClikc && handleIconClikc()
            }}
          >
            <ReactSVG src={pickUp} />
          </div> */}
        </div>
        <div className="h-9">
          <div className={cn('pl-3 pr-3 h-full relative')}>
            <div className={cn(inputIconClassName, 'left-5')}>
              <ReactSVG src={search} />
            </div>
            <Input
              disabled={currentTab === ChatListStatus.UNASSIGNED}
              ref={searchInputRef}
              id="searchInputRefTargetDom"
              type="text"
              style={{ borderRadius: '6px', boxShadow: 'none' }}
              className={cn(
                'h-full w-full bg-lingheInpBg pl-7 placeholder:text-lingheGray focus:outline-none focus-visible:border focus:border-[#247CFF] focus-visible:border-solid focus-visible:border-[#247CFF]',
                searchInputRef?.current?.value ? '' : 'border-none'
              )}
              placeholder={
                currentTab === ChatListStatus.UNASSIGNED
                  ? t('threeLevelMenu.thecurrentcategorydoesnotsupportsearch')
                  : t('threeLevelMenu.searchguestnamessessionmessages')
              }
              onInput={handleSearch}
            />
            {searchInputRef?.current?.value && (
              <ReactSVG
                src={close}
                className="absolute right-6 top-1/2 -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  if (searchInputRef?.current) {
                    searchInputRef.current.value = ''
                    setSearchResult({ resultList: [], value: '' })
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="w-full border-b h-11 flex items-center bar-header">
          <div className="w-full h-full pl-3 pr-3 flex justify-between items-center text-lingheGray text-base [&>div]:cursor-pointer [&>div]:relative text-sm">
            <div
              id="assignedBtn"
              className={cn(
                'hover:text-primary',
                currentTab === ChatListStatus.ASSIGNED && tabStyle
              )}
              onClick={() => {
                if (currentTab === ChatListStatus.ASSIGNED) return
                // handleSetScrolTop()
                if (talkGroupActive === 1) {
                  // 未读会话栏
                  const list = filter(assignedList, (item) => item?.latestMessage?.isRead === false)
                  setSearchResult({ resultList: list, value: '-1' })
                }
                setCurrentTab(ChatListStatus.ASSIGNED)
                if (some(assignedList, (it) => it.conversationId === active)) {
                  navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${active}`)
                } else {
                  navigate(APP_ROUTER_PATH.HOME_CHATTING)
                }
              }}
            >
              {t('threeLevelMenu.mine')}
            </div>
            {talkGroupActive !== 1 && (
              <div
                className={cn(
                  'hover:text-primary',
                  currentTab === ChatListStatus.UNASSIGNED && tabStyle
                )}
                onClick={() => {
                  if (currentTab === ChatListStatus.UNASSIGNED) return
                  // handleSetScrolTop()
                  setCurrentTab(ChatListStatus.UNASSIGNED)
                  if (some(unAssignedList, (it) => it.conversationId === active)) {
                    navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${active}`)
                  } else {
                    navigate(APP_ROUTER_PATH.HOME_CHATTING)
                  }
                }}
              >
                {t('threeLevelMenu.undistributed')}
              </div>
            )}

            <div
              className={cn(
                'hover:text-primary',
                currentTab === ChatListStatus.FINISHED && tabStyle
              )}
              onClick={() => {
                if (currentTab === ChatListStatus.FINISHED) return
                // handleSetScrolTop()
                if (talkGroupActive === 1) {
                  // 未读会话栏
                  const list = filter(finishedList, (item) => item?.latestMessage?.isRead === false)
                  setSearchResult({ resultList: list, value: '-1' })
                }
                setCurrentTab(ChatListStatus.FINISHED)
                if (some(finishedList, (it) => it.conversationId === active)) {
                  navigate(`${APP_ROUTER_PATH.HOME_CHATTING}/${active}`)
                } else {
                  navigate(APP_ROUTER_PATH.HOME_CHATTING)
                }
              }}
            >
              {/* {t('threeLevelMenu.history_conversation')} */}
              {t('common.all')}
            </div>
          </div>
        </div>
      </div>
      {currentTab === ChatListStatus.ASSIGNED && (
        <ScrollWrap
          list={assignedList}
          searchResultList={searchResult}
          active={active}
          handleItemClick={(id) => setChatItemActive(id)}
          searchInputRef={searchInputRef}
          hasTalkHistory={hasTalkHistory}
        />
      )}
      {currentTab === ChatListStatus.UNASSIGNED && (
        <ScrollWrap
          list={unAssignedList}
          active={active}
          handleItemClick={(id) => setChatItemActive(id)}
          handleToFetch={handleFetchMoe}
          searchInputRef={searchInputRef}
          hasTalkHistory={hasTalkHistory}
        />
      )}
      {currentTab === ChatListStatus.FINISHED && (
        <ScrollWrap
          list={finishedList}
          // list={finishedList}
          searchResultList={searchResult}
          active={active}
          handleItemClick={(id) => setChatItemActive(id)}
          handleToFetch={() => handleFetchMoe()}
          searchInputRef={searchInputRef}
          hasTalkHistory={hasTalkHistory}
        />
      )}
    </div>
  )
}
export default memo(MessageMenu)
