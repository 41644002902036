import { CardContent, CardFooter, Checkbox, Label } from '@brisk/ui'
import { FC, useId, useState } from 'react'
import { SignInHeader } from './components/SignInHeader'
import QRCodeWithRefresh from '../../components/QRCodeWithRefresh'
import { SignInSwitcher } from './components/SignInSwitcher'
import { useSignStore } from '@renderer/store/useSignStore'

export const SignInByQRCode: FC = () => {
  const id1 = useId()
  const [autoSigin, setAutoSigin] = useState(false)
  const { setFifteenAutoSingin } = useSignStore()

  return (
    <>
      <SignInSwitcher />
      <SignInHeader />
      <CardContent className="flex-1 justify-center flex">
        <QRCodeWithRefresh />
      </CardContent>

      <CardFooter className="flex justify-center pb-12  ">
        <div className="mt-4 flex justify-center items-center ">
          <div className="flex items-center space-x-2">
            <Checkbox
              id={id1}
              checked={autoSigin}
              onCheckedChange={(e: boolean) => {
                setFifteenAutoSingin(e)
                setAutoSigin(e)
              }}
            />
            <Label
              htmlFor={id1}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
            >
              15天内自动登录
            </Label>
          </div>
        </div>
      </CardFooter>
    </>
  )
}
