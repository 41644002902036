import { Card } from '@brisk/ui'
import { createContext, FC, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import { isEmpty } from 'lodash'
import { APP_ROUTER_PATH } from '../../router'
import { useSaveLoginTime } from '@renderer/store/useSaveLoginTime'
import { useSignStore } from '@renderer/store/useSignStore'
import Loading from '@renderer/components/Loading'
import { isElectron } from '@renderer/utils'
import login_bg from '@renderer/assets/imgs/login_bg.png'
import { fetchUserDetailInfo, RESPONSE_CODE } from '@brisk/api'
import { useUserRoleStore } from '@renderer/store/useUserRoleStore'
import { hideSysReadDot } from '@renderer/utils/ipcHandler'
import { useManageRouteMenu } from '@renderer/hooks/manager/useManageRouteMenu'

interface SignInContextProps {
  loading: boolean
  setLoading: (loading: boolean) => void
}

export const SignInContext = createContext<SignInContextProps>({
  loading: false,
  setLoading: () => {}
})

export const LoginPage: FC = () => {
  const [loading, setLoading] = useState(false)

  const { user } = useUserStore()
  const { loginTime } = useSaveLoginTime()
  const { fifteenAutoSingin, inputAccountToSign } = useSignStore()
  const navigate = useNavigate()
  const { refetchMenu } = useManageRouteMenu()

  useEffect(() => {
    if (isEmpty(user)) {
      hideSysReadDot()
      return
    }

    const shouldSign = inputAccountToSign ? !isEmpty(user) : fifteenAutoSingin && !isEmpty(user)
    /** 15天免登录 */
    if (shouldSign) {
      try {
        fetchUserDetailInfo(user.userId).then((res) => {
          if (res?.code === RESPONSE_CODE.success) {
            refetchMenu()
            useUserRoleStore.getState().setRole(res.data)
            const list = (res?.data?.roleIdList as unknown as string[]) || []
            const isManager = list.includes('1') || list.includes('3') // 超管或管理员
            isElectron
              ? navigate(APP_ROUTER_PATH.HOME_CHATTING, { replace: true })
              : isManager
                ? navigate(APP_ROUTER_PATH.MANAGER_HOME, { replace: true })
                : navigate(APP_ROUTER_PATH.MANAGER_CUSTOMERMANAGE, { replace: true })
          }
        })
      } catch (error) {
        console.log('error', error)
      }
    }
  }, [user, inputAccountToSign, loginTime])

  return (
    <SignInContext.Provider value={{ loading, setLoading }}>
      <div
        style={{
          background: `url(${login_bg}) no-repeat`,
          backgroundSize: '100% 100%',
          width: '100%',
          height: '100%'
        }}
      >
        <div className="w-full h-screen sm:justify-center overflow-auto lg:justify-stretch p-5 flex items-center">
          {loading && <Loading />}
          {/*<div className="hidden lg:block h-full">*/}
          {/*  <div className="w-full h-full">*/}
          {/*    <img*/}
          {/*      src={Assets.illustration_login}*/}
          {/*      alt="Image"*/}
          {/*      className="w-full h-full object-fill dark:brightness-[0.2] dark:grayscale"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="flex items-center m-auto mr-[237px] justify-center">
            <Card
              className="w-[486px] rounded-[1.2rem] min-h-[574px] pt-[42px] pr-[42px] pl-[42px] flex flex-col relative overflow-hidden"
              style={{
                boxShadow: '0px 12px 36px 0px rgba(0,0,0,0.)',
                border: '2px solid #FFFFFF',
                background:
                  'linear-gradient(rgba(255, 255, 255, 0.8) 100%, rgba(255, 255, 255, 0) 0%)'
              }}
            >
              <Outlet />
            </Card>
          </div>
        </div>
      </div>
    </SignInContext.Provider>
  )
}
