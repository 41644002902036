import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * CustomerEditDTO，编辑客户
 */
export interface ICustomerEditDTO {
  /**
   * 地址
   */
  address?: string
  /**
   * 头像地址
   */
  avatar?: string
  /**
   * 公司名称
   */
  company?: string
  /**
   * 客户id
   */
  customerId: number
  /**
   * 标签Ids
   */
  customerTagIds?: number[]
  /**
   * 邮箱
   */
  email?: string
  /**
   * 用户姓名
   */
  name?: string
  /**
   * 手机号
   */
  phone?: string
  /**
   * 备注
   */
  remark?: string
}

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 编辑客户详情
 * @param param
 * @returns
 */
export const fetchEditUserDetail = async (param: ICustomerEditDTO): Promise<ApiResp> => {
  const { data } = await httpClient.put<ApiResp>(`/bg/customer`, param)

  return serialize(data)
}
