import { IParamForPage } from '@renderer/utils/consts'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * PageUserInviteVO
 */
export interface IInviteResponse {
  countId?: string
  current?: number
  maxLimit?: number
  optimizeCountSql?: IInviteResponse
  optimizeJoinOfCountSql?: boolean
  orders?: OrderItem[]
  pages?: number
  records?: IAddCustomerTableData[]
  searchCount?: IInviteResponse
  size?: number
  total?: number
}

/**
 * OrderItem
 */
interface OrderItem {
  asc?: boolean
  column?: string
}

/**
 * IAddCustomerTableData
 */
export interface IAddCustomerTableData {
  id: string
  /**
   * 账号类型 PHONE:手机 EMAIL:邮箱
   */
  accountType: string
  /**
   * 邀请时长 分钟
   */
  duration?: number
  inviteId?: string
  /**
   * 邀请时间
   */
  inviteTime?: number
  /**
   * 预设角色
   */
  preRoleIds?: number
  /**
   * 预设角色
   */
  preRoleName?: string
  /**
   * 预设团队
   */
  preTeamIds?: number
  /**
   * 预设团队
   */
  preTeamName?: string
  /**
   * 0：待确定 1：已确定
   */
  status?: number
  confirmTime?: number
  tenantId?: number
  /**
   * 账号
   */
  userAccount?: string
  account?: string
  userName?: string
  nickname?: string
  /** 邀请是否超时 */
  timeout?: boolean
  /** 邮箱 */
  email?: string
  /** 手机 */
  phone?: string
}

interface ApiResp extends ClientResponse {
  data: IInviteResponse
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 分页查询邀请列表
 * @param param
 * @returns
 */
export const fetchInvitePage = async (param: IParamForPage): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/user/invite/page`, {
    ...param
  })

  return serialize(data)
}
