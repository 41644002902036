import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import { IDataCustomerPageListRequest } from "./fetchDataCustomerPageList";
import { IDataCustomerRequestBody } from "./fetchDataCustomerReception";

/**
 * ChannelStatisticsVO
 */
export interface IChannelPieData {
  /**
   * 渠道id
   */
  channelId?: number;
  /**
   * 渠道Name
   */
  channelName?: string;
  /**
   * 数量
   */
  num?: number;
}

interface ApiResp extends ClientResponse {
  data: IChannelPieData[];
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 渠道数据饼图
 * @param param
 * @returns
 */
export const fetchDataChannelPie = async (
  param: IDataCustomerPageListRequest,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/channel/statistics/chart/pie`,
    param,
  );

  return serialize(data);
};
