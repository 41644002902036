import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * telegram渠道 保存配置
 * @param token
 * @returns
 */
export const fetchAddTelegramConfig = async (token: { token: string }): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/channel/telegram/save`, token)

  return serialize(data)
}
