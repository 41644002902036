import { useEffect, useState } from 'react'

export const useCurrentOrigin = (): {
  origin: string
  host: string
} => {
  const [origin, setOrigin] = useState('')
  const [host, setHost] = useState('')

  useEffect(() => {
    const w = window.location
    setOrigin(w?.origin)
    setHost(w?.protocol + '//' + w?.hostname)
  }, [])

  return { origin, host }
}
