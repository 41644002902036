import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";
import {
  IDataCustomerRequestBody,
  IReceptionData,
} from "./fetchDataCustomerReception";

interface ApiResp extends ClientResponse {
  data: IReceptionData;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 会话统计
 * @param param
 * @returns
 */
export const fetchDataCustomerChat = async (
  param: IDataCustomerRequestBody,
): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/bg/customer/statistics/session`,
    param,
  );

  return serialize(data);
};
