import { TeamUser } from '@brisk/api'
import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * UserTeamSaveDTO
 */
export interface IAddTeamGroupReq {
  /**
   * 团队编号
   */
  teamId?: number | string
  /**
   * 团队名称
   */
  teamName?: string
  /**
   * 团队下的人员
   */
  userList?: TeamUser[]
}
interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 保存团队
 * @param param
 * @returns
 */
export const fetchAddTeamGroup = async (param: IAddTeamGroupReq): Promise<ApiResp> => {
  const { data } = await httpClient.post<ApiResp>(`/bg/user/team`, param)

  return serialize(data)
}
