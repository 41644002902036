import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp;
};

/**
 * 客服重启会话
 * @param params 
 * @returns 
 */
export const fetchRestartChat = async (params: {
  sessionId: number;
}): Promise<ApiResp> => {
  const { data } = await apiClient.post<ApiResp>(
    `/session/restart`,
    params,
  );

  return serialize(data);
};
