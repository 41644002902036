import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import image from '../assets/svg/image.svg'
import imageHoverIcon from '../assets/svg/imageHoverIcon.svg'
import { ReactSVG } from 'react-svg'

interface ImagePickerProps {
  onPick: (url: Blob) => void
}

const ImagePicker = forwardRef(({ onPick }: ImagePickerProps, ref) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState()

  useImperativeHandle(ref, () => ({
    selectedFile
  }))

  const onFileChange = (event): void => {
    const file = event.target.files[0]
    setSelectedFile(file)
    onPick && onPick(file)

    // 选择文件后重置输入框的值
    if (fileRef.current) {
      fileRef.current.value = ''
    }
  }

  return (
    <>
      <input
        ref={fileRef}
        type="file"
        accept=".png, .jpg, .jpeg, .webp"
        className="hidden"
        onChange={onFileChange}
      />
      <div className="flex w-8 h-8 items-center justify-center rounded-md hover:bg-lingheBtnHover group">
        <ReactSVG
          src={imageHoverIcon}
          onClick={() => fileRef.current?.click()}
          className="hidden group-hover:block"
        />
        <ReactSVG src={image} className="group-hover:hidden" />
      </div>
    </>
  )
})

ImagePicker.displayName = 'ImagePicker'
export default ImagePicker
