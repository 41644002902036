/**
 * 从 URL 解析查询参数并返回键值对对象
 * @param {string} url - 要解析的 URL
 * @returns {object} - 查询参数的键值对对象
 */
export function parseUrlParams(url) {
  const urlObj = new URL(url)
  const searchParams = new URLSearchParams(urlObj.search)
  const params = {}

  // 将查询参数存储为键值对对象
  searchParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
