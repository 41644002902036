import { ApiRespSerializeFn, ClientResponse, httpClient } from '../../http-client/httpClient'

/**
 * 标签列表
 */
export interface ITagList {
  /**
   * 标签id
   */
  tagId?: number
  /**
   * 标签名
   */
  tagName?: string
  colorCode?: string
}

interface ApiResp extends ClientResponse {
  data: ITagList[]
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
  return resp
}

/**
 * 获取标签列表
 * @returns
 */
export const fetchTagList = async (): Promise<ApiResp> => {
  const { data } = await httpClient.get<ApiResp>(`/bg/tag/list`)

  return serialize(data)
}
