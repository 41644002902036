import { apiClient, ApiRespSerializeFn, ClientResponse } from "../../apiClient";

interface ApiResp extends ClientResponse {
    data: any;
}

const serialize: ApiRespSerializeFn<ApiResp, ApiResp> = (resp) => {
    return resp;
};

/**
 * 客服详情
 * @param id
 * @returns
 */
export const fetchBgUserSessionCount = async (userId: string | number): Promise<ApiResp> => {
    const { data } = await apiClient.get<ApiResp>(`/bg/user/${userId}/session/count`);

    return serialize(data);
};
