/**
 * useCheckUpdate
 *
 * @desc 描述：检查更新
 */

/* =================================================== */

import type { Options } from 'ahooks/lib/useRequest/src/types'
import { useRequest } from 'ahooks'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'

import type { IUpdateInfo } from '../../../../main/appUpdate/checkUpdate'
import { transformInvoke } from '@renderer/utils/transformInvoke'
import { message } from '@renderer/components/message'

export interface ICheckUpdateOp extends Options<IUpdateInfo, []> {
  // 是否显示提示信息，默认值：true
  isTip?: boolean
}

/**
 * 检查更新
 * @param op 基于 useRequest.options 扩展 isTip
 */
export function useCheckUpdate(op?: ICheckUpdateOp) {
  const { t } = useTranslation()

  const { onSuccess, onError, isTip, ...other } = {
    isTip: true,
    ...op
  }

  return useRequest(transformInvoke(window.appUpdate?.checkUpdate), {
    onSuccess: (...args) => {
      onSuccess?.(...args)

      if (isTip && get(args, '[0].isNewVersion') === false)
        message(t('systemSetting.您已是最新版本'), 'success')
    },
    onError: (...args) => {
      onError?.(...args)
      isTip && message(t('systemSetting.检测更新失败'), 'error')
    },
    ...other
  })
}
