import { apiClient, ClientResponse } from "../../apiClient";

// interface RequestParams {}

interface ApiResp extends ClientResponse {
    // data: boolean
}
interface Serialize {
    (resp: ApiResp): ApiResp;
}

const serialize: Serialize = (resp) => {
    // TODO: 处理data

    return resp;
};

// 注销所有登录用户
export const fetchSliderCaptcha = async (): Promise<ApiResp> => {
    const { data } = await apiClient.get<ApiResp>(`/login/slider/captcha`, {});

    return serialize(data);
};
